import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import * as fromActions from './service-type.actions';
import * as fromGraphql from './service-type.graphql';
import { map, switchMap } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification.service';
import { ResponseCodeService } from 'src/app/services/response-code.service';
import { AppState } from '../../reducers';
import { PaginationService } from 'src/app/services/pagination.service';



@Injectable()
export class ServiceTypeEffects {

  // getAllInstitutionServiceTypes
  getAllInstitutionServiceTypes$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.getAllInstitutionServiceTypes),
    switchMap((action) => {
      return this.apollo.query({
        query: fromGraphql.LIST_ALL_SERVICE_TYPES,
        fetchPolicy: 'network-only',
      }).pipe(
        this.notificationService.catchError('Problem occurred while fetching Services'),
        map(({ data }: any) => {
          
          if (data) {
            this.store.dispatch(fromActions.loadServiceTypes({ serviceTypes: data.getAllInstitutionServiceTypes }));
          }
        })
      );
    })
  ), {dispatch: false});



  
  
  // getAllServiceTypesPageable
  getAllServiceTypesPageable$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.getAllServiceTypesPageable),
    switchMap((action) => {
      return this.apollo.query({
        query: fromGraphql.LIST_ALL_PAGED_SERVICE_TYPES,
        fetchPolicy: 'network-only',
        variables: {
          pageableParam: action.pageableParam
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while fetching Service'),
        map(({ data }: any) => {
          if (data) {
            if (data.getAllServiceTypesPageable) {
              const result = data?.getAllServiceTypesPageable;
              if (result?.content?.length > 0) {
                this.paginationService.setPage(result);
              }
              this.store.dispatch(fromActions.loadServiceTypes({ serviceTypes: data.getAllServiceTypesPageable.content }));;
            } else {
              // const error = this.responseCodesService.getCodeDescription(data.getServiceById);
              this.notificationService.errorSnackbar('Failed to load service');
            }
          }
        })
      );
    })
  ), {dispatch: false});

  // createUpdateServiceType
  createUpdateServiceType$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.createUpdateServiceType),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: fromGraphql.CREATE_SERVICE_TYPES,
        variables: {
          serviceType: action.serviceType
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while saving service'),
        map(({ data }: any) => {
          if (data) {
            const response = this.responseCodesService?.getCodeDescription(data.createUpdateServiceType.code);
            if (data.createUpdateServiceType.code === 9000) {
              this.store.dispatch(fromActions.upsertServiceType({ serviceType: data.createUpdateServiceType.data }));
              this.notificationService.successMessage('Operation successful');
            } else {
              // this.notificationService.errorMessage('Failed to save service');
              this.notificationService.determineSnackbar(response[0]);
            }
          }
        })
      );
    })
  ), {dispatch: false});

  // deactivateServiceType
  deactivateServiceType$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.deactivateServiceType),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: fromGraphql.DEACTIVATE_SERVICE_TYPES,
        variables: {
          serviceTypeUuid: action.serviceTypeUuid
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while saving service'),
        map(({ data }: any) => {
          if (data) {
            const response = this.responseCodesService?.getCodeDescription(data.deactivateServiceType.code);
            if (data.deactivateServiceType.code === 9000) {
              this.store.dispatch(fromActions.deleteServiceType({ id: data.deactivateServiceType.data.id }));
              this.notificationService.successMessage('Deleted successful');
            } else {
              // this.notificationService.errorMessage('Failed to save service');
              this.notificationService.determineSnackbar(response[0]);
            }
          }
        })
      );
    })
  ), {dispatch: false});



  constructor(
    private actions$: Actions,
    private apollo: Apollo,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private paginationService: PaginationService,
    private responseCodesService: ResponseCodeService,
  ) {}

}
