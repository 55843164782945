import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { RequestCategory } from './request-category.model';
import * as RequestCategoryActions from './request-category.actions';

export const RequestCategorysFeatureKey = 'RequestCategorys';

export interface State extends EntityState<RequestCategory> {
  // additional entities state properties
}

export const adapter: EntityAdapter<RequestCategory> = createEntityAdapter<RequestCategory>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(RequestCategoryActions.addRequestCategory,
    (state, action) => adapter.addOne(action.requestCategory, state)
  ),
  on(RequestCategoryActions.upsertRequestCategory,
    (state, action) => adapter.upsertOne(action.requestCategory, state)
  ),
  on(RequestCategoryActions.addRequestCategorys,
    (state, action) => adapter.addMany(action.requestCategorys, state)
  ),
  on(RequestCategoryActions.upsertRequestCategorys,
    (state, action) => adapter.upsertMany(action.requestCategorys, state)
  ),
  on(RequestCategoryActions.updateRequestCategory,
    (state, action) => adapter.updateOne(action.requestCategory, state)
  ),
  on(RequestCategoryActions.updateRequestCategorys,
    (state, action) => adapter.updateMany(action.requestCategorys, state)
  ),
  on(RequestCategoryActions.deleteRequestCategory,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(RequestCategoryActions.deleteRequestCategorys,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(RequestCategoryActions.loadRequestCategorys,
    (state, action) => adapter.setAll(action.requestCategorys, state)
  ),
  on(RequestCategoryActions.clearRequestCategorys,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
