import {NotificationService} from './../../../services/notification.service';
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {Apollo} from 'apollo-angular';
import {AppState} from '../../reducers';
import {ResponseCodeService} from 'src/app/services/response-code.service';
import {Router} from '@angular/router';
import * as fromActions from './roster.actions';
import * as fromGraphql from './roster.graphql';
import {map, switchMap} from 'rxjs/operators';
import { initializedPageableParameter } from 'src/app/interfaces/global.interface';
import { PaginationService } from 'src/app/services/pagination.service';


@Injectable()
export class RosterEffects {


    // List INSTITUTION Rosters
    listDepartmentRosters$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.listDepartmentRosters),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.MY_INSTITUTION_ROSTER_LIST,
                fetchPolicy: 'network-only',
            }).pipe(
                map(({data}: any) => {

                    if (data) {

                        return fromActions.loadRosters({rosters: data?.myInstRosterListCurrent?.content || []});
                    }
                })
            );
        })
    ));


    // Add Many Roster Members
    addManyRosterMembers$ = createEffect(() => this.actions$.pipe(
            ofType(fromActions.addManyRosterMembers),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: fromGraphql.CREATE_NEW_ROASTER,
                    variables: {
                        roster: action.rosterChanges
                    }
                }).pipe(
                    this.notificationService.catchError('Problem occurred while Saving Roster'),
                    map(({data}: any) => {
                        if (data) {
                            if (data.createNewRoster.code === 9000) {
                                this.store.dispatch(fromActions.upsertRoster({roster: data.createNewRoster?.data}));
                                return this.notificationService.successMessageWithRedirect('Created Successfully', '/roster/department-roster-list');
                            }
                        }
                        return '';
                    })
                );
            }),
        ), {
            dispatch: false
        }
    );
// DELETE ROSTER
    delateRoster$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.removeRoster),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.DELETE_ROSTER,
                variables: {
                    id: action.id,
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while updating User details'),
                map(({data}: any) => {
                    if (data) {
                        if (data.deleteRoster.code === 9000) {
                            this.store.dispatch(fromActions.deleteRoster({id: action.id}));
                            this.notificationService.successMessage('Roster Deleted Successfully');
                        } else {
                            this.notificationService.errorMessage('Failed to Delete Roster');
                        }
                    }
                })
            );
        })
    ), {dispatch: false});


    // List INSTITUTION Rosters
    getMyRosterList$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getMyRosterList),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.MY_ROSTER_LIST,
                variables: { 
                    pageParam: action?.pageaParam ? action.pageaParam : initializedPageableParameter,
                    active: action?.active
                },
                fetchPolicy: 'network-only',
            }).pipe(
                map(({data}: any) => {
                    if (data) {
                    const result = data?.myRosterList;
                    this.paginationService.setPage(result);
                    this.store.dispatch(fromActions.loadRosters({ rosters: data?.myRosterList?.content || [] }));
                  }
                })
            );
        })
    ),{dispatch: false});


    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private store: Store<AppState>,
        private notificationService: NotificationService,
        private responseCodesService: ResponseCodeService, 
        private paginationService: PaginationService,
        private router: Router
    ) {
    }

}


