import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Test } from './test.model';

export const loadTests = createAction(
  '[Test/API] Load Tests', 
  props<{ tests: Test[] }>()
);

export const addTest = createAction(
  '[Test/API] Add Test',
  props<{ test: Test }>()
);

export const upsertTest = createAction(
  '[Test/API] Upsert Test',
  props<{ test: Test }>()
);

export const addTests = createAction(
  '[Test/API] Add Tests',
  props<{ tests: Test[] }>()
);

export const upsertTests = createAction(
  '[Test/API] Upsert Tests',
  props<{ tests: Test[] }>()
);

export const updateTest = createAction(
  '[Test/API] Update Test',
  props<{ test: Update<Test> }>()
);

export const updateTests = createAction(
  '[Test/API] Update Tests',
  props<{ tests: Update<Test>[] }>()
);

export const deleteTest = createAction(
  '[Test/API] Delete Test',
  props<{ id: string }>()
);

export const deleteTests = createAction(
  '[Test/API] Delete Tests',
  props<{ ids: string[] }>()
);

export const clearTests = createAction(
  '[Test/API] Clear Tests'
);

// custom

export const getMyQuestionAnswersByAptitudeTest2 = createAction(
  '[Test/API] Get My Question Answers By Aptitude Test',
  props<{ aptitudeTestUid: string }>()
);