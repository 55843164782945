import { Component, Injectable } from "@angular/core";
import Swal from "sweetalert2";
import { Router } from "@angular/router";
import { catchError, map } from "rxjs/operators";
import { of, pipe } from "rxjs";
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from "@angular/material/snack-bar";
import { ResponseCodeService } from "./response-code.service";

@Injectable({
  providedIn: "root",
})
// successSnackbar(arg0: string) {
//   throw new Error('Method not implemented.');
// }
// errorSnackbar(description: any) {
//   throw new Error('Method not implemented.');
// }
export class NotificationService {
  constructor(
    private router: Router,
    private snackbar: MatSnackBar,
    private responseCodeService: ResponseCodeService
  ) {}

  determineSnackbar(data) {
    if (data.code === 9000) {
      this.successSnackbar(data?.description);
    } else if (
      data.code === 9002 ||
      data.code === 9003 ||
      data.code === 9004 ||
      data.code === 9005 ||
      data.code === 9006 ||
      data.code === 9007 ||
      data.code === 9008 ||
      data.code === 9009 ||
      data.code === 9010 ||
      data.code === 9011 ||
      data.code === 9012 ||
      data.code === 9013 ||
      data.code === 9012 ||
      data.code === 9014 ||
      data.code === 9015 ||
      data.code === 9016 ||
      data.code === 9017 ||
      data.code === 9018 ||
      data.code === 9019 ||
      data.code === 9020 ||
      data.code === 9021 ||
      data.code === 9022 ||
      data.code === 9023 ||
      data.code===9031
      ) {
        this.errorSnackbar(this.getDescriptioin(data) + ": " + data?.code);
      } else {
        this.warningSnackbar(this.getDescriptioin(data)+ ": " + data?.code);
      }
    }
    
    getDescriptioin(data) {
      const responseCode = this.responseCodeService.getCodeDescription(data.code);
      const message = responseCode[0].description;
      return message;
    }

  confirmationSnackbar(message: string, action?: string) {
    return this.snackbar.open(message, action, {
      duration: 2500,
      horizontalPosition: "center",
      panelClass: "green-snackbar",
    });
  }
  successSnackbar(message: string, action?: string) {
    return this.snackbar.open(message, action, {
      duration: 4500,
      verticalPosition: "top",
      panelClass: "green-snackbar",
    });
  }
  errorSnackbar(message: string, action?: string) {
    return this.snackbar.open(message, action, {
      duration: 4500,
      verticalPosition: "top",
      panelClass: "red-snackbar",
    });
  }
  warningSnackbar(message: string, action?: string) {
    return this.snackbar.open(message, action, {
      duration: 2500,
      // verticalPosition: 'top',
      horizontalPosition: "center",
      panelClass: "warning-snackbar",
    });
  }
  successMessage(message: string, action?: string) {
    return this.snackbar.open(message, action, {
      duration: 2500,
      horizontalPosition: "center",
      panelClass: "green-snackbar",
    });
  }
  errorMessage(message: string, action?: string) {
    return this.snackbar.open(message, action, {
      duration: 3500,
      horizontalPosition: "center",
      panelClass: "red-snackbar",
    });
  }



  loaderSnackbar(loading: boolean = true) {
    if (loading) {
      return this.snackbar.openFromComponent(PizzaPartyComponent,{
        verticalPosition: "bottom",
        horizontalPosition: "right",
        duration: 5000,
        panelClass: "green-snackbar"
      })
    }
    else {
      return false
    }
  }

  successImportMessage(message: string) {
    return Swal.fire({
      title: message,
      text: "",
      icon: "success",
      allowOutsideClick: false,
      timer: 1500,
    });
  }

  importErrorMessage(message?: string) {
    return Swal.fire({
      title: message ? message : "<strong>The following failed to register, confirm and try again</strong>",
      icon: "info",
      html: "You can use <b>bold text</b>, " + '<a href="//sweetalert2.github.io">links</a> ' + "and other HTML tags",
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: '<i class="fa fa-thumbs-up"></i> Great!',
      confirmButtonAriaLabel: "Thumbs up, great!",
      cancelButtonText: '<i class="fa fa-thumbs-down"></i>',
      cancelButtonAriaLabel: "Thumbs down",
    });
  }

  noErrorCode() {
    return Swal.fire({
      title: "Unknown Error Code Returned",
      text: "",
      icon: "error",
      allowOutsideClick: false,
      timer: 1500,
    });
  }

  successMessageWithRedirect(message: string, route?: string) {
    const response = this.snackbar.open(message, "", {
      duration: 3000,
      horizontalPosition: "center",
      panelClass: "green-snackbar",
    });

    if (response) {
      return this.router.navigateByUrl(route);
    }
  }

  // catchError(message: string, action?: string) {
  //   return this.snackbar.open(message, action, {
  //     duration: 2500,
  //     verticalPosition: 'top',
  //     panelClass: 'red-snackbar'
  //   });
  // }
  catchError(message: string, action?: string) {
    return catchError((error) => {

      this.snackbar.open(message, action, {
        duration: 2500,
        verticalPosition: "top",
        panelClass: "red-snackbar",
      });
      // Swal.fire(
      //   {
      //     icon: 'error',
      //     text: message + ', please contact the system administrator',
      //     allowOutsideClick: false,
      //   timer: 1500
      //   }
      // );
      return of([]);
    });
  }

  confirmation(message: string = "Are you sure?", confirmButtonText: string = "YES") {
    return Swal.fire({
      title: message,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText,
      allowOutsideClick: false,
      // timer: 1500
    });
  }
  warningConfirmation(message: string = "Are you sure?", confirmButtonText: string = "YES") {
    return Swal.fire({
      title: message,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText,
      allowOutsideClick: false,
      // timer: 1500
    });
  }

  warningMessage(message: string) {
    return Swal.fire({
      title: message,
      text: "",
      icon: "warning",
      allowOutsideClick: false,
      timer: 2500,
    });
  }

  handleErrorMessage(data: any) {
    // const responseCode = this.responseCodeService.getCodeDescription(data?.code);
    // const message = responseCode[0]?.code + " : " + responseCode[0]?.message;
    const message = `${data?.code}: ${data?.message}`;
    return this.errorMessage(message);
  }




  warningSnackBar({
    message,
    closeText = "Dismiss",
    duration = 7000,
    verticalPosition = "bottom",
    horizontalPosition = "right",
  }: {
    message: string;
    closeText?: string;
    duration?: number;
    verticalPosition?: MatSnackBarVerticalPosition;
    horizontalPosition?: MatSnackBarHorizontalPosition;
  }): void {
    this.snackbar.open(message, closeText, {
      duration,
      verticalPosition,
      horizontalPosition,
      panelClass: ["warning-snackbar"],
    });
  }

  handleErrorCode(message?: string) {
    return pipe(
      map((response: any) => {
        if (response?.code && response?.code !== 9000) {
          if (message) {
            this.errorMessage(message);
          } else {
            this.handleErrorMessage(response);
          }
        }

        return response;
      })
    );
  }
}

@Component({
  selector: 'snack-bar-component-example-snack',
  template: `
    <div>Hello from wadudu</div>
  `,
  styles: [`
    .example-pizza-party {
      color: hotpink;
    }
  `],
})
export class PizzaPartyComponent {}
