import { gql } from "apollo-angular";

export const GET_MY_QUESTION_ANSWERS_BY_APTITUDE_TEST = gql`
  query getMyQuestionAnswersByAptitudeTest($aptitudeTestUid: String) {
    getMyQuestionAnswersByAptitudeTest(aptitudeTestUid: $aptitudeTestUid) {
      code
      message
      status
      dataList {
        uuid
        question {
          questionText
          questionAnswerChoices {
            uuid
            questionAnswerChoiceText
          }
        }
        questionAnswerChoice {
          uuid
          questionAnswerChoiceText
        }
        aptitudeTest{
          aptitudeTestDuration
          aptitudeTestDurationEnum
          aptitudeTestEndDate
        }
        testUser {
          remainingMinutes
          startTime
          endTime
          remainingTime
        }
      }
    }
  }
`;
