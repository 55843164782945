import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GetCountryService {

  constructor(private http: HttpClient) {}

  getCountries() {
    return this.http.get(`assets/country_and_codes.json`);
  }
  
}
