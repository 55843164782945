import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../../reducers';
import * as fromTascoIsceGroupReducer from './tasco-isce-group.reducer';
import { TascoIsceGroup } from './tasco-isce-group.model';
import { TascoGroupLevelEnum, TascoIsceCategoryEnum } from 'src/app/shared/enums/academics.enum';

export const currentState = (state: AppState) =>
  state[fromTascoIsceGroupReducer.tascoIsceGroupsFeatureKey];

export const selectTascoIsceGroups = createSelector(
  currentState,
  fromTascoIsceGroupReducer.selectAll
);

export const selectModifiedTascoIsceGroups = createSelector(
  selectTascoIsceGroups,
  (tascoIsceGroups) => {
    return tascoIsceGroups.map((ts) => {
      let categoryLevel = !!ts?.educationalLevel?.name ? 
           ts.educationalLevel.name:" ";

          let parentName = !!ts.parentUid?
          tascoIsceGroups.find(g => g.uid === ts?.parentUid)?.name :
          "MAJOR";

      return {
        ...ts, 
        levelName: ts?.educationalLevel?.name,
        parentName: parentName,
        categoryLevel: categoryLevel,
       
      };
    }).filter(object => !object?.deleted);
  }
);

export const selectUnitGroups = createSelector(
  selectTascoIsceGroups,
  (tascoIsceGroups) => {
    return tascoIsceGroups.filter(group => group?.groupLevel === TascoGroupLevelEnum.unit);
  }
);

export const selectTascoIsceGroupCategory = (category: string) =>
  createSelector(selectTascoIsceGroups, (tascoIsceGroup: TascoIsceGroup[]) => {
    return tascoIsceGroup.filter((tascoIsceGroup) => tascoIsceGroup?.groupLevel === category);
});



export const selectTascoIsceGroupById = (id: number) =>
  createSelector(selectModifiedTascoIsceGroups, (tascoIsceGroup: TascoIsceGroup[]) => {
    return tascoIsceGroup.find((tascoIsceGroup) => tascoIsceGroup?.id === id);
});
