import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import { NotificationService } from 'src/app/services/notification.service';
import * as fromActions from './change-request.actions';
import * as fromGraphql from './change-request.graphql';
import { map, switchMap } from 'rxjs/operators';
import { ResponseCodeService } from 'src/app/services/response-code.service';
import { AppState } from '../../reducers';



@Injectable()
export class ChangeRequestEffects {
 // List All Branchs 
 listMyInstitutionAllChangeRequests$ = createEffect(() => this.actions$.pipe(
  ofType(fromActions.listMyInstitutionAllChangeRequests),
  switchMap((action) => {
    return this.apollo.query({
      query: fromGraphql.GET_MY_INSTITUTION_ALL_CHANGE_REQUESTS,
      // variables: {
      //   institutionId:  action.institution.id
      // },
      fetchPolicy: 'network-only',
    }).pipe(
      this.notificationService.catchError('Problem occurred while fetching Change Request'),
      map(({ data }: any) => {
        if (data) {
          this.store.dispatch(fromActions.loadChangeRequests({ changeRequests: data.getAllInstitutionChangeRequest.content }));
        }
      })
    );
  })
), {dispatch: false});


listAllMyChangeRequests$ = createEffect(() => this.actions$.pipe(
  ofType(fromActions.listAllMyChangeRequests),
  switchMap((action) => {
    return this.apollo.query({
      query: fromGraphql.GET_ALL_MY_CHANGE_REQUEST,
      // variables: {
      //   institutionId:  action.institution.id
      // },
      fetchPolicy: 'network-only',
    }).pipe(
      this.notificationService.catchError('Problem occurred while fetching Change Request'),
      map(({ data }: any) => {
        if (data) {
          this.store.dispatch(fromActions.loadChangeRequests({ changeRequests: data.getAllMyChangeRequests.content }));
        }
      })
    );
  })
), {dispatch: false});


activateChangeRequest$ = createEffect(() => this.actions$.pipe(
  ofType(fromActions.activateChangeRequest),
  switchMap((action) => {
    return this.apollo.mutate({
      mutation: fromGraphql.ACTIVATE_CHANGE_REQUEST,
      variables: {
        id: action.id,
       
      }
    }).pipe(
      this.notificationService.catchError('Problem occurred while updating Change Request details'),
      map(({ data }: any) => {

        if (data) {

          this.notificationService.successMessage('Change Request details Activated Successfully');
          if (data.activateDeactivateChangeRequest.code === 9000) {
            this.store.dispatch(fromActions.upsertChangeRequest({ changeRequest: data.activateDeactivateChangeRequest.data }));
            this.notificationService.successMessage('Change Request details Activated Successfully');
          } else {
            this.notificationService.errorMessage('Failed to Activate Change Request detals');
          }
        }
      })
    );
  })
), {dispatch: false});


 // Create/Save Branch
 createChangeRequest$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.createChangeRequest),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: fromGraphql.SAVE_UPDATE_CHANGE_REQUEST,
        variables: {
          changeRequest: action.changeRequest
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while saving Change request'),
        map(({ data }: any) => {
          if (data) {
            if (data.createUpdateChangeRequest.code === 9000) {
              this.store.dispatch(fromActions.upsertChangeRequest({ changeRequest: data.createUpdateChangeRequest.data }));
              if (action.changeRequest?.id) {
                this.notificationService.successMessage('Edited Successfully');
              } else {
                this.notificationService.successMessage('Created Successfully');
              }
            } else {
              const error = this.responseCodesService.getCodeDescription(data.createUpdateChangeRequest.code);
              this.notificationService.errorMessage(error[0]?.description);
            }
          }
        })
      );
    })
  ), {dispatch: false});

  
  // Detele Branch
  removeBranch$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.removeChangeRequest),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: fromGraphql.DELETE_CHANGE_REQUEST,
        variables: {
          id: action.id
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while deleting Change Request'),
        map(({ data }: any) => {
          if (data) {
            if (data.deleteChangeRequest.code === 9000) {
              // this.store.dispatch(fromActions.deleteBranch({ id: action.id }));
              this.store.dispatch(fromActions.removeChangeRequest({ id: action.id}));
              // this.store.dispatch(fromActions.upsertChangeRequest({ ChangeRequest: data.deleteChangeRequest.data }));

              this.notificationService.successMessage('Deleted Successfully');
            } else {
              this.notificationService.errorMessage(data.deleteChangeRequest);
            }
          }
        })
      );
    })
  ), {dispatch: false});



  constructor(
    private actions$: Actions,
    private apollo: Apollo,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private responseCodesService: ResponseCodeService
  ) {
  }


}
