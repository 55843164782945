import { Injectable } from '@angular/core';
import { ResponseCode } from '../interfaces/global.interface';

@Injectable({
  providedIn: 'root'
})
export class ResponseCodeService {

  constructor() { }

  private responseCodes: ResponseCode[] = [
    {
      code: 9000,
      description: 'Operation Successful',
    },
    {
      code: 9001,
      description: 'Invalid Request'
    },
    {
      code: 9002,
      description: 'Record does not exist'
    },
    {
      code: 9003,
      description: 'Unauthorized'
    },
    {
      code: 9004,
      description: 'Duplicate record'
    },
    {
      code: 9005,
      description: 'Failure'
    },
    {
      code: 9006,
      description: 'Data in Use'
    },
    {
      code: 9007,
      description: 'Bad Request'
    },
    {
      code: 9008,
      description: 'Method Not Allowed'
    },
    {
      code: 9009,
      description: 'Restricted Access'
    },
    {
      code: 9010,
      description: 'Limit Reach'
    },
    {
      code: 9011,
      description: 'Null Argument'
    },
    {
      code: 9012,
      description: 'Email already exists'
    },
    {
      code: 9013,
      description: 'Phone number already exists'
    },
    {
      code: 9014,
      description: 'Mail wrong password'
    },
    {
      code: 9015,
      description: 'Mail try again'
    },
    {
      code: 9016,
      description: 'Concept Note Document is Required'
    },
    {
      code: 9017,
      description: 'Password do not match'
    },
    {
      code: 9018,
      description: 'Weak password'
    },
    {
      code: 9019,
      description: 'Check number already is required'
    },
    {
      code: 9020,
      description: 'Phone number is not valid'
    },
    {
      code:9031,
      description:"Examination time has passed!"
    },
    {
      code:9040,
      description:"Aptitude Test Start Date has already passed"
    },
    {
      code:9050,
      description:"Aptitude Test has already been submitted!"
    },
    {
      code:9032,
      description:"Aptitude is not published or Has not Started"
    }
  ];

  getCodeDescription(code) {
    const result = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.responseCodes.length; i++) {
      for (const data in this.responseCodes[i]) {
        if ((this.responseCodes[i][data]) === code) {
          result.push(this.responseCodes[i]);
        }
      }
    }
    return result;
  }

}
