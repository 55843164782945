import {HighchartsChartModule} from 'highcharts-angular';
import {NgxDocViewerModule} from 'ngx-doc-viewer';
import {DataTableHybridComponent} from './components/tables/data-table-hybrid/data-table-hybrid.component';
import {PageTitleComponent} from './components/page-title/page-title.component';
import {ModalHeaderComponent} from './components/modal-header/modal-header.component';
import {DataTableComponent} from './components/tables/data-table/data-table.component';
import {SearchPipe} from './pipes/search.pipe';
import {SafeResourcePipe} from './pipes/safe-resource-pipe';
import {DateAgoPipe} from './pipes/date-ago.pipe';
import {ViewAttachmentComponent} from './components/view-attachment/view-attachment.component';
import {RouterModule} from '@angular/router';
import {MaterialModule} from '../material.module';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DashLayoutComponent} from './components/dash-layout/dash-layout.component';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatCardModule} from '@angular/material/card';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {LayoutModule} from '@angular/cdk/layout';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {HeaderComponent} from './components/header/header.component';
import {SidebarComponent} from './components/sidebar/sidebar.component';
import {ContentComponent} from './components/content/content.component';
import {SpinnerComponent} from './components/spinner/spinner.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AttachmentComponent} from './components/dynamic-forms-components/attachment/attachment.component';
import {ButtonComponent} from './components/dynamic-forms-components/button/button.component';
import {CheckboxComponent} from './components/dynamic-forms-components/checkbox/checkbox.component';
import {DateComponent} from './components/dynamic-forms-components/date/date.component';
import {DynamicFieldDirective} from './components/dynamic-forms-components/dynamic-field/dynamic-field.directive';
import {InputComponent} from './components/dynamic-forms-components/input/input.component';
import {MainDynamicFormComponent} from './components/dynamic-forms-components/main-dynamic-form/main-dynamic-form.component';
import {RadiobuttonComponent} from './components/dynamic-forms-components/radiobutton/radiobutton.component';
import {SelectComponent} from './components/dynamic-forms-components/select/select.component';
import {TextareaComponent} from './components/dynamic-forms-components/textarea/textarea.component';
import {ReplacePipe} from './pipes/replace.pipe';
import {MatSelectSearchComponent} from './components/mat-select-search/mat-select-search.component';
import {PaginatedDataTableComponent} from './components/paginated-data-table/paginated-data-table.component';
import {ReviewChangeRequestComponent} from './components/review-change-request/review-change-request.component';
import {ViewTrailComponent} from './components/view-trail/view-trail.component';
import {NgxPermissionsModule} from 'ngx-permissions';
import {BarComponent} from './components/widgets/bar/bar.component';

/*****************/
import {FusionChartsModule} from 'angular-fusioncharts';
import {ChartsModule} from 'ng2-charts';
// import { NgChartsModule } from 'ng2-charts';
// Import FusionCharts library and chart modules
import {LineChartComponent} from './components/widgets/line-chart/line-chart.component';
import {PolarChartComponent} from './components/widgets/polar-chart/polar-chart.component';
import {DonoughtChartComponent} from './components/widgets/donought-chart/donought-chart.component';
import {HomePageComponent} from './components/home-page/home-page.component';
import {HomeHeaderComponent} from './components/home-page/header/header.component';
import {AllDetailsComponentComponent} from './all-details-component/all-details-component.component';
import {PaginationButtonsComponent} from './components/tables/pagination-buttons/pagination-buttons.component';
import {TablePageableComponent} from './components/tables/table-pageable/table-pageable.component';
import {ViewRoleComponent} from './components/view-role/view-role.component';
import {WelcomeLayoutComponent} from './components/welcome-layout/welcome-layout.component';
import {TopBarComponent} from './components/top-bar/top-bar.component';
import {FormatDatePipe} from './pipes/format-date.pipe';
import {TakeActionComponent} from './components/take-action/take-action.component';
import {InstitutionPipe} from './pipes/institution.pipe';
import {UserInfoPipe} from './pipes/user-by-id.pipe';
import {RemoveUnderScorePipe} from './pipes/remove-underscore.pipe';
import {ClientLayoutComponent} from './components/client-layout/client-layout/client-layout.component';
import {ClientContentComponent} from './components/client-layout/client-content/client-content.component';
import {CreatePasswordComponent} from '../guest/create-password/create-password.component';
import {IncidentNotificationComponent} from './components/incident-notification/incident-notification.component';
import {MultiColumnsComponent} from './components/multi-columns/multi-columns.component';
import {SafeHtmlPipe} from './pipes/safe-html.pipe';
// import {ReportIncidentGeneralComponent} from './components/report-incident-general/report-incident-general.component';
import {DateRangeComponent} from './components/dynamic-forms-components/date-range/dateRange.component';
import {
    PaginatedSelectTableComponent
} from './components/dynamic-forms-components/paginated-select/paginated-select-table/paginated-select-table.component';
import {WithLoadingPipe} from './pipes/with-loading.pipe';
import {TimeComponent} from './components/dynamic-forms-components/time/time.component';
import {SelectSearchComponent} from './components/dynamic-forms-components/select/select-search/select-search.component';
import {GroupSelectComponent} from './components/dynamic-forms-components/group-select/group-select.component';
import {LoaderComponent} from './components/loader/loader.component';
import {PaginatedSelectComponent} from './components/dynamic-forms-components/paginated-select/paginated-select.component';
import {DynamicPipe} from './pipes/dynamic.pipe';
import {OptionsObservablePipe} from './pipes/options-observable.pipe';
import {PasswordStrengthBarComponent} from './components/password-strength-bar/password-strength-bar.component';
import {AngularEditorModule} from '@kolkov/angular-editor';
import {UserUuidInfoPipe} from './pipes/user-by-uuid.pipe';
import {TextareaWithEditor} from './components/dynamic-forms-components/description-editor/description-editor';
import {RemoveNullPipe} from './pipes/remove-null.pipe';
import {ClientProviderHybridComponent} from './components/tables/client-provider-hybrid/client-provider-hybrid.component';
import {ArrayToStringPipe} from './pipes/array-to-string.pipe';
import {CamelToTitleCasePipe} from './pipes/camel-to-title-case.pipe';
import {IncidentNavComponent} from './components/incident-nav/incident-nav.component';
import {CommentDisplayComponent} from './components/comment-display/comment-display.component';
import {LockDialogComponent} from './components/lock-dialog/lock-dialog.component';
import {NgApexchartsModule} from 'ng-apexcharts';
import {
    IncidentNotificationDashboardComponent
} from './components/incident-notification-dashboard/incident-notification-dashboard.component';
import {ShortNumberPipe} from './pipes/short-number.pipe';
import {DisableDuplicatePipe} from './pipes/disable-duplicate.pipe';
import {ProfileComponent} from './components/profile/profile.component';
import {StepIndicatorComponent} from './components/step-indicator/step-indicator.component';
import {MoreDetailsComponent} from '../modules/settings/users/users-list/more-details/more-details.component';
import {ClientIdentificationComponent} from './components/institution-shared/client-identification/client-identification.component';
import {
    ClientIdentificationFormComponent
} from './components/institution-shared/client-identification/client-identification-form/client-identification-form.component';
import {InstitutionUserComponent} from './components/institution-shared/institution-user/institution-user.component';
import {
    AddInstitutionUserComponent
} from './components/institution-shared/institution-user/add-institution-user/add-institution-user.component';
import {InstitutionRoleComponent} from './components/institution-shared/institution-role/institution-role.component';
import {ServiceSubscribedComponent} from './components/institution-shared/service-subscribed/service-subscribed.component';
import {InstitutionalDashboardComponent} from './components/institution-shared/institutional-dashboard/institutional-dashboard.component';
import { UploadingClientsComponent } from './components/institution-shared/client-identification/uploading-clients/uploading-clients.component';
import { PercentagePrecision } from './pipes/percentage-precision.pipe';
import { PrimaryButtonComponent } from './components/buttons/primary-button/primary-button.component';
import { SecondaryButtonComponent } from './components/buttons/secondary-button/secondary-button.component';
import { RoundButtonComponent } from './components/buttons/round-button/round-button.component';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';


@NgModule({
    declarations: [
        HeaderComponent,
        DashLayoutComponent,
        HeaderComponent,
        SidebarComponent,
        ContentComponent,
        SpinnerComponent,
        ViewAttachmentComponent,
        SafeResourcePipe,
        SearchPipe,
        ReplacePipe,
        DateAgoPipe,
        ShortNumberPipe,
        DataTableComponent,
        DataTableHybridComponent,
        TablePageableComponent,
        PaginationButtonsComponent,
        ModalHeaderComponent,
        PageTitleComponent,
        InputComponent,
        ButtonComponent,
        SelectComponent,
        AttachmentComponent,
        DateComponent,
        RadiobuttonComponent,
        CheckboxComponent,
        TextareaComponent,
        DynamicFieldDirective,
        MainDynamicFormComponent,
        MatSelectSearchComponent,
        PaginatedDataTableComponent,
        ReviewChangeRequestComponent,
        ViewTrailComponent,
        BarComponent,
        LineChartComponent,
        PolarChartComponent,
        DonoughtChartComponent,
        HomePageComponent,
        HomeHeaderComponent,
        AllDetailsComponentComponent,
        ViewRoleComponent,
        WelcomeLayoutComponent,
        TopBarComponent,
        FormatDatePipe,
        TakeActionComponent,
        InstitutionPipe,
        UserInfoPipe,
        RemoveUnderScorePipe,
        ClientLayoutComponent,
        ClientContentComponent,
        CreatePasswordComponent,
        IncidentNotificationComponent,
        MultiColumnsComponent,
        SafeHtmlPipe,
        // ReportIncidentGeneralComponent,
        // DigitOnlyDirective,
        DateRangeComponent,
        PaginatedSelectTableComponent,
        WithLoadingPipe,
        TimeComponent,
        SelectSearchComponent,
        GroupSelectComponent,
        LoaderComponent,
        PaginatedSelectComponent,
        DynamicPipe,
        OptionsObservablePipe,
        PasswordStrengthBarComponent,
        UserUuidInfoPipe,
        TextareaWithEditor,
        RemoveNullPipe,
        ClientProviderHybridComponent,
        ArrayToStringPipe,
        CamelToTitleCasePipe,
        IncidentNavComponent,
        CommentDisplayComponent,
        LockDialogComponent,
        IncidentNotificationDashboardComponent,
        DisableDuplicatePipe,
        ProfileComponent,
        StepIndicatorComponent,
        MoreDetailsComponent,
        ClientIdentificationComponent,
        ClientIdentificationFormComponent,
        InstitutionUserComponent,
        AddInstitutionUserComponent,
        InstitutionRoleComponent,
        ServiceSubscribedComponent,
        InstitutionalDashboardComponent,
        UploadingClientsComponent,
        PercentagePrecision,
        PrimaryButtonComponent,
        SecondaryButtonComponent,
        RoundButtonComponent,
        AttachmentComponent
    ],
    imports: [
        CommonModule,
        MatGridListModule,
        MatCardModule,
        MatMenuModule,
        MatIconModule,
        MatButtonModule,
        LayoutModule,
        MaterialModule,
        MatToolbarModule,
        MatSidenavModule,
        MatListModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NgxPermissionsModule,
        ChartsModule,
        // NgChartsModule,
        FusionChartsModule,
        AngularEditorModule,
        NgxDocViewerModule,
        HighchartsChartModule,
        NgApexchartsModule,
        MatSelectInfiniteScrollModule
    ],
    exports: [
        BarComponent,
        LineChartComponent,
        PolarChartComponent,
        DonoughtChartComponent,
        MatIconModule,
        HeaderComponent,
        MaterialModule,
        DashLayoutComponent,
        RouterModule,
        SpinnerComponent,
        FormsModule,
        ReactiveFormsModule,
        ViewAttachmentComponent,
        SafeResourcePipe,
        SearchPipe,
        ReplacePipe,
        DateAgoPipe,
        DataTableComponent,
        DataTableHybridComponent,
        TablePageableComponent,
        PaginationButtonsComponent,
        ModalHeaderComponent,
        PageTitleComponent,
        DynamicFieldDirective,
        PaginatedDataTableComponent,
        MainDynamicFormComponent,
        MatSelectSearchComponent,
        ViewTrailComponent,
        NgxPermissionsModule,
        AllDetailsComponentComponent,
        HomeHeaderComponent,
        AllDetailsComponentComponent,
        WelcomeLayoutComponent,
        FormatDatePipe,
        TakeActionComponent,
        InstitutionPipe,
        UserInfoPipe,
        RemoveUnderScorePipe,
        ClientLayoutComponent,
        ClientContentComponent,
        IncidentNotificationComponent,
        MultiColumnsComponent,
        SafeHtmlPipe,
        // DigitOnlyDirective,
        DateRangeComponent,
        PaginatedSelectTableComponent,
        WithLoadingPipe,
        TimeComponent,
        SelectSearchComponent,
        GroupSelectComponent,
        LoaderComponent,
        PaginatedSelectComponent,
        DynamicPipe,
        OptionsObservablePipe,
        MatToolbarModule,
        MatGridListModule,
        MatCardModule,
        MatMenuModule,
        MatIconModule,
        MatButtonModule,
        LayoutModule,
        MatToolbarModule,
        MatSidenavModule,
        MatListModule,
        PasswordStrengthBarComponent,
        AngularEditorModule,
        NgxDocViewerModule,
        UserUuidInfoPipe,
        TextareaWithEditor,
        HighchartsChartModule,
        ClientProviderHybridComponent,
        IncidentNavComponent,
        CommentDisplayComponent,
        NgApexchartsModule,
        IncidentNotificationDashboardComponent,
        ShortNumberPipe,
        DisableDuplicatePipe,
        ProfileComponent,
        StepIndicatorComponent,
        MoreDetailsComponent,
        ClientIdentificationComponent,
        ClientIdentificationFormComponent,
        InstitutionUserComponent,
        AddInstitutionUserComponent,
        InstitutionRoleComponent,
        ServiceSubscribedComponent,
        InstitutionalDashboardComponent,
        PercentagePrecision,
        PrimaryButtonComponent,
        SecondaryButtonComponent,
        RoundButtonComponent,
        MatSelectInfiniteScrollModule,
        AttachmentComponent
    ]
})
export class SharedModule {
}
