import {Component, Input, OnInit} from '@angular/core';
import {Menu} from 'src/app/interfaces/global.interface';
import {SettingsService} from '../../../services/settings.service';

@Component({
    selector: 'app-top-bar',
    templateUrl: './top-bar.component.html',
    styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit {

    @Input() sidebarMenu: Menu;
    menuData: Menu;
    currentMenu: any;

    constructor(
        private settingService: SettingsService,
    ) {
    }

    ngOnInit(): void {
        this.menuData = this.sidebarMenu;
    }

    goToRoute(url) {
        // console.log(url);
        this.settingService.reloadCurrentRoute(url);
    }
}
