import gql from 'graphql-tag';

export const resourceCategorysFields = `
active
createdAt
createdBy
deleted
id
institutionId
description
institutionUuid
title
updatedAt
updatedBy
uuid
`;

// resources




export const GET_MY_INSTITUTION_ALL_RESOURCE_CATEGORYS = gql`
query getAllResourceCategory($pageparam: PageableParamInput){
  getAllResourceCategory(pageparam:$pageparam){
    content{
     ${resourceCategorysFields}
    }
    first
    hasContent
    hasNext
    hasPrevious
    last
    nextOrLastPageable{
      pageSize
      pageNumber
    }
    nextPageable{
      pageSize
      pageNumber
    }
    number
    numberOfElements
    pageable{
      pageSize
      pageNumber
    }
    previousPageable{
      pageSize
      pageNumber
    }
    previousOrFirstPageable{
      pageSize
      pageNumber
    }
    size
    totalElements
    totalPages
    
  }
}
`;

export const GET_MY_INSTITUTION_ALL_RESOURCE_CATEGORYS_FOR_LIST = gql`
  query getAllResourceCategory($pageparam: PageableParamInput){
    getAllResourceCategory(pageparam:$pageparam){
      content{
        id
        uuid
        title
      }
    }
  }
`;

export const ACTIVATE_AND_DEACTIVATE_RESOURCE_CATEGORY = gql`
mutation activateDeactivateResourceCategory($resourceCategoryUuid: String){
  activateDeactivateResourceCategory(resourceCategoryUuid:$resourceCategoryUuid){
    code
    data{
      ${resourceCategorysFields}
    }
    dataList{
      ${resourceCategorysFields}
    }
    message
    status
  }
}
`;

export const SAVE_UPDATE_RESOURCE_CATEGORY = gql`
mutation addUpdateResourceCategory($resourceCategory: ResourceCategoryDtoInput){
  addUpdateResourceCategory(resourceCategory:$resourceCategory){
    code
    data{
      ${resourceCategorysFields}
    }
    dataList{
      ${resourceCategorysFields}
    }
    message
    status
  }
}
`;


export const DELETE_RESOURCE_CATEGORY = gql`
mutation deleteResourceCategory($resourceCategoryUuid: String){
  deleteResourceCategory(resourceCategoryUuid:$resourceCategoryUuid){
    code
    message
    status
    data{
      ${resourceCategorysFields}
    }
    dataList{
      ${resourceCategorysFields}
    }
  }
}
`;




