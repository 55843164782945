<div *ngIf="notifications$ | async as notifications" class="row col-md-12">

    <!-- FOR SERVICE PROVIDERS ONLY STARTS -->
    <ng-container *ngxPermissionsOnly="['ROLE_HELPDESK_SERVICE_PROVIDER']">
        <ng-container *ngIf="incommingTickets$ | async as incommingTickets">
            <ng-container *ngxPermissionsOnly="['ROLE_WORK_AS_DEPT_SUPERVISOR','ROLE_WORK_AS_SECOND_LEVEL',
            'ROLE_WORK_AS_FIRST_LEVEL', 'ROLE_WORK_AS_HD_DEPT_SUPERVISOR']">

                <div class="col-lg-3 col-md-6 col-sm-12 px-2">
                    <div class="py-2">
                        <mat-card
                                class="mat-elevation-z1 rounded-medium shadow-sm right-card  d-flex flex-column justify-content-center align-items-center">

                            <div class="tag">
                                <div class="tag2">
                                    <h2 class="text-center p-2"> Assigned Tickets</h2>
                                </div>
                            </div>

                            <div class="col-md-12 p-bg px-3 py-1 my-2 rounded-slight shadow-sm d-flex align-items-center justify-content-between flex-wrap  "
                                 routerLink="/incidents/notifications/assigned-incidents/assignedASSIGNED">
                                <div class="d-flex justify-content-center  align-items-center py-1">
                                    <button class="mat-elevation-z2" color="white"
                                            mat-mini-fab>
                                        <mat-icon class="mb-1" svgIcon="dash_enty"></mat-icon>
                                    </button>
                                    <div class="p-title px-2"> Assigned</div>
                                </div>
                                <div class="border-left px-3">
                                    <div class="text-muted mt-2 p-subtitle">{{incommingTickets[0]?.myAssignedStatuses?.assigned || 0 | shortNumber}}</div>
                                </div>
                            </div>

                            <div class="col-md-12 p-bg px-3 py-1 my-2 rounded-slight shadow-sm d-flex align-items-center justify-content-between flex-wrap "
                                 routerLink="/incidents/notifications/assigned-incidents/assignedSUBMITTED">
                                <div class="d-flex justify-content-center align-items-center py-1">
                                    <button class="mat-elevation-z2 "
                                            color="white" mat-mini-fab>
                                        <mat-icon class="mb-1" svgIcon="dash_enty"></mat-icon>
                                    </button>
                                    <div class="p-title px-2"> Open</div>
                                </div>
                                <div class="border-left px-3">
                                    <div class="text-muted mt-2 p-subtitle">{{incommingTickets[0]?.myAssignedStatuses?.open || 0 | shortNumber}}</div>
                                </div>
                            </div>

                            <div class="col-md-12 p-bg px-3 py-1 my-2 rounded-slight shadow-sm d-flex align-items-center justify-content-between flex-wrap "
                                 routerLink="/incidents/notifications/assigned-incidents/assignedRESOLVED">
                                <div class="d-flex justify-content-center align-items-center py-1">
                                    <button class="mat-elevation-z2 "
                                            color="white" mat-mini-fab>
                                        <mat-icon class="mb-1" svgIcon="dash_enty"></mat-icon>
                                    </button>
                                    <div class="p-title px-2"> Resolved</div>
                                </div>
                                <div class="border-left px-3">
                                    <div class="text-muted mt-2 p-subtitle">{{incommingTickets[0]?.myAssignedStatuses?.resolved || 0 | shortNumber}}</div>
                                </div>
                            </div>
                            <div class="col-md-12 p-bg px-3 py-1 my-2 rounded-slight shadow-sm d-flex align-items-center justify-content-between flex-wrap "
                                 routerLink="/incidents/notifications/assigned-incidents/assignedCLOSED">
                                <div class="d-flex justify-content-center align-items-center py-1">
                                    <button class="mat-elevation-z2"
                                            color="white" mat-mini-fab>
                                        <mat-icon class="mb-1" svgIcon="dash_enty"></mat-icon>
                                    </button>
                                    <div class="p-title px-2"> Closed</div>
                                </div>
                                <div class="border-left px-3">
                                    <div class="text-muted mt-2 p-subtitle">{{incommingTickets[0]?.myAssignedStatuses?.closed || 0 | shortNumber}}</div>
                                </div>
                            </div>

                        </mat-card>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngxPermissionsOnly="['ROLE_WORK_ON_OUTGOING_INCIDENTS', 'ROLE_WORK_ON_ALL_INCIDENTS']">
                <div class="col-lg-3 col-md-6 col-sm-12 px-2">
                    <div class="py-2">
                        <mat-card
                                class="mat-elevation-z1 rounded-medium shadow-sm right-card  d-flex flex-column justify-content-center align-items-center">
                            <div class="tag">
                                <div class="tag2">
                                    <h2 class="text-center p-2"> Incoming Tickets</h2>
                                </div>
                            </div>
                            <div class="col-md-12 p-bg px-3 py-1 my-2 rounded-slight shadow-sm d-flex align-items-center justify-content-between flex-wrap "
                                 routerLink="/incidents/tickets/notifications/incomming/incomingASSIGNED">
                                <div class="d-flex justify-content-center align-items-center py-1">
                                    <button class="mat-elevation-z2" color="white"
                                            mat-mini-fab>
                                        <mat-icon class="mb-1" svgIcon="dash_enty"></mat-icon>
                                    </button>
                                    <div class="p-title px-2"> Assigned</div>
                                </div>
                                <div class="border-left px-3">
                                    <div class="text-muted mt-2 p-subtitle">{{incommingTickets[0]?.incommingStatuses?.assigned || 0 | shortNumber}}</div>
                                </div>
                            </div>

                            <div class="col-md-12 p-bg px-3 py-1 my-2 rounded-slight shadow-sm d-flex align-items-center justify-content-between flex-wrap "
                                 routerLink="/incidents/tickets/notifications/incomming/incomingSUBMITTED">
                                <div class="d-flex justify-content-center align-items-center py-1">
                                    <button class="mat-elevation-z2 "
                                            color="white" mat-mini-fab>
                                        <mat-icon class="mb-1" svgIcon="dash_enty"></mat-icon>
                                    </button>
                                    <div class="p-title px-2"> Open</div>
                                </div>
                                <div class="border-left px-3">
                                    <div class="text-muted mt-2 p-subtitle">{{incommingTickets[0]?.incommingStatuses?.open || 0 | shortNumber}}</div>
                                </div>
                            </div>

                            <div class="col-md-12 p-bg px-3 py-1 my-2 rounded-slight shadow-sm d-flex align-items-center justify-content-between flex-wrap "
                                 routerLink="/incidents/tickets/notifications/incomming/incomingRESOLVED">
                                <div class="d-flex justify-content-center align-items-center py-1">
                                    <button class="mat-elevation-z2 "
                                            color="white" mat-mini-fab>
                                        <mat-icon class="mb-1" svgIcon="dash_enty"></mat-icon>
                                    </button>
                                    <div class="p-title px-2"> Resolved</div>
                                </div>
                                <div class="border-left px-3">
                                    <div class="text-muted mt-2 p-subtitle">{{incommingTickets[0]?.incommingStatuses?.resolved || 0 | shortNumber}}</div>
                                </div>
                            </div>
                            <div class="col-md-12 p-bg px-3 py-1 my-2 rounded-slight shadow-sm d-flex align-items-center justify-content-between flex-wrap "
                                 routerLink="/incidents/tickets/notifications/incomming/incomingCLOSED">
                                <div class="d-flex justify-content-center align-items-center py-1">
                                    <button class="mat-elevation-z2"
                                            color="white" mat-mini-fab>
                                        <mat-icon class="mb-1" svgIcon="dash_enty"></mat-icon>
                                    </button>
                                    <div class="p-title px-2"> Closed</div>
                                </div>
                                <div class="border-left px-3">
                                    <div class="text-muted mt-2 p-subtitle">{{incommingTickets[0]?.incommingStatuses?.closed || 0 | shortNumber}}</div>
                                </div>
                            </div>

                        </mat-card>
                    </div>
                </div>
            </ng-container>


            <ng-container *ngxPermissionsOnly="['ROLE_WORK_ON_OUTGOING_INCIDENTS', 'ROLE_WORK_ON_ALL_INCIDENTS']">

                <ng-container *ngIf="outgoingTickets$ | async as outgoingTickets">
                    <div class="col-lg-3 col-md-6 col-sm-12 px-2">
                        <div class="py-2">
                            <mat-card
                                    class="mat-elevation-z1 rounded-medium shadow-sm right-card  d-flex flex-column justify-content-center align-items-center">
                                <div class="tag">
                                    <div class="tag2">
                                        <h2 class="text-center p-2"> Outgoing Tickets</h2>
                                    </div>
                                </div>
                                <div class="col-md-12 p-bg px-3 py-1 my-2 rounded-slight shadow-sm d-flex align-items-center justify-content-between flex-wrap "
                                     routerLink="/incidents/notifications/outgoing-tickets/outDRAFT">
                                    <div class="d-flex justify-content-center align-items-center py-1">
                                        <button class="mat-elevation-z2" color="white"
                                                mat-mini-fab>
                                            <mat-icon class="mb-1" svgIcon="dash_enty"></mat-icon>
                                        </button>
                                        <div class="p-title px-2"> Draft</div>
                                    </div>
                                    <div class="border-left px-3">
                                        <div class="text-muted mt-2 p-subtitle">{{outgoingTickets[0]?.outgoingStatuses?.draft || 0 | shortNumber}}</div>
                                    </div>
                                </div>

                                <div class="col-md-12 p-bg px-3 py-1 my-2 rounded-slight shadow-sm d-flex align-items-center justify-content-between flex-wrap "
                                     routerLink="/incidents/notifications/outgoing-tickets/outSUBMITTED">
                                    <div class="d-flex justify-content-center align-items-center py-1">
                                        <button class="mat-elevation-z2 "
                                                color="white" mat-mini-fab>
                                            <mat-icon class="mb-1" svgIcon="dash_enty"></mat-icon>
                                        </button>
                                        <div class="p-title px-2"> Open</div>
                                    </div>
                                    <div class="border-left px-3">
                                        <div class="text-muted mt-2 p-subtitle">{{outgoingTickets[0]?.outgoingStatuses?.open || 0 | shortNumber}}</div>
                                    </div>
                                </div>

                                <div class="col-md-12 p-bg px-3 py-1 my-2 rounded-slight shadow-sm d-flex align-items-center justify-content-between flex-wrap "
                                     routerLink="/incidents/notifications/outgoing-tickets/outRESOLVED">
                                    <div class="d-flex justify-content-center align-items-center py-1">
                                        <button class="mat-elevation-z2 "
                                                color="white" mat-mini-fab>
                                            <mat-icon class="mb-1" svgIcon="dash_enty"></mat-icon>
                                        </button>
                                        <div class="p-title px-2"> Resolved</div>
                                    </div>
                                    <div class="border-left px-3">
                                        <div class="text-muted mt-2 p-subtitle">{{outgoingTickets[0]?.outgoingStatuses?.resolved || 0 | shortNumber}}</div>
                                    </div>
                                </div>

                                <div class="col-md-12 p-bg px-3 py-1 my-2 rounded-slight shadow-sm d-flex align-items-center justify-content-between flex-wrap "
                                     routerLink="/incidents/notifications/outgoing-tickets/outCLOSED">
                                    <div class="d-flex justify-content-center align-items-center py-1">
                                        <button class="mat-elevation-z2"
                                                color="white" mat-mini-fab>
                                            <mat-icon class="mb-1" svgIcon="dash_enty"></mat-icon>
                                        </button>
                                        <div class="p-title px-2"> Closed</div>
                                    </div>
                                    <div class="border-left px-3">
                                        <div class="text-muted mt-2 p-subtitle">{{outgoingTickets[0]?.outgoingStatuses?.closed || 0 | shortNumber}}</div>
                                    </div>
                                </div>

                            </mat-card>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>

        <ng-container
                *ngxPermissionsOnly="['ROLE_LIST_SECTION_INCIDENT', 'ROLE_WORK_ON_ALL_INCIDENTS']">
            <ng-container *ngIf="departmentTickects$ |async as departmentTickects">
                <div class="col-lg-3 col-md-6 col-sm-12 px-2">
                    <div class="py-2">
                        <mat-card
                                class="mat-elevation-z1 rounded-medium shadow-sm right-card  d-flex flex-column justify-content-center align-items-center">
                            <div class="tag">
                                <div class="tag2">
                                    <h2 class="text-center p-2"> Department Tickets</h2>
                                </div>
                            </div>

                            <div class="col-md-12 p-bg px-3 py-1 my-2 rounded-slight shadow-sm d-flex align-items-center justify-content-between flex-wrap "
                                 routerLink="/incidents/directorate-tickets/notifications/tickets/deptINCOMMING">
                                <div class="d-flex justify-content-center align-items-center py-1">
                                    <button class="mat-elevation-z2" color="white"
                                            mat-mini-fab>
                                        <mat-icon class="mb-1" svgIcon="dash_enty"></mat-icon>
                                    </button>
                                    <div class="p-title px-2"> Incoming</div>
                                </div>
                                <div class="border-left px-3">
                                    <div class="text-muted mt-2 p-subtitle">{{departmentTickects[0]?.departmentStatus?.submitted || 0 | shortNumber}}</div>
                                </div>
                            </div>

                            <div class="col-md-12 p-bg px-3 py-1 my-2 rounded-slight shadow-sm d-flex align-items-center justify-content-between flex-wrap "
                                 routerLink="/incidents/directorate-tickets/notifications/tickets/deptSUBMITTED">
                                <div class="d-flex justify-content-center align-items-center py-1">
                                    <button class="mat-elevation-z2 "
                                            color="white" mat-mini-fab>
                                        <mat-icon class="mb-1" svgIcon="dash_enty"></mat-icon>
                                    </button>
                                    <div class="p-title px-2"> Open</div>
                                </div>
                                <div class="border-left px-3">
                                    <div class="text-muted mt-2 p-subtitle">{{departmentTickects[0]?.departmentStatus?.open || 0 | shortNumber}}</div>
                                </div>
                            </div>

                            <div class="col-md-12 p-bg px-3 py-1 my-2 rounded-slight shadow-sm d-flex align-items-center justify-content-between flex-wrap "
                                 routerLink="/incidents/directorate-tickets/notifications/tickets/deptASSIGNED">
                                <div class="d-flex justify-content-center align-items-center py-1">
                                    <button class="mat-elevation-z2 "
                                            color="white" mat-mini-fab>
                                        <mat-icon class="mb-1" svgIcon="dash_enty"></mat-icon>
                                    </button>
                                    <div class="p-title px-2"> Assigned</div>
                                </div>
                                <div class="border-left px-3">
                                    <div class="text-muted mt-2 p-subtitle">{{departmentTickects[0]?.departmentStatus?.assigned || 0 | shortNumber}}</div>
                                </div>
                            </div>
                            <div class="col-md-12 p-bg px-3 py-1 my-2 rounded-slight shadow-sm d-flex align-items-center justify-content-between flex-wrap "
                                 routerLink="/incidents/directorate-tickets/notifications/tickets/deptRESOLVED">
                                <div class="d-flex justify-content-center align-items-center py-1">
                                    <button class="mat-elevation-z2"
                                            color="white" mat-mini-fab>
                                        <mat-icon class="mb-1" svgIcon="dash_enty"></mat-icon>
                                    </button>
                                    <div class="p-title px-2"> Resolved</div>
                                </div>
                                <div class="border-left px-3">
                                    <div class="text-muted mt-2 p-subtitle">{{departmentTickects[0]?.departmentStatus?.resolved || 0 | shortNumber}}</div>
                                </div>
                            </div>

                        </mat-card>
                    </div>
                </div>
            </ng-container>
        </ng-container>
        <ng-container
                *ngxPermissionsOnly="['ROLE_WORK_AS_DEPT_SUPERVISOR', 'ROLE_WORK_AS_SECOND_LEVEL', 'ROLE_VIEW_DEPARTMENT_INCIDENT']">
            <ng-container *ngIf="sectionTickets$ |async as sectionTickets">
                <div class="col-lg-3 col-md-6 col-sm-12 px-2">
                    <div class="py-2">
                        <mat-card
                                class="mat-elevation-z1 rounded-medium shadow-sm right-card  d-flex flex-column justify-content-center align-items-center">
                            <div class="tag">
                                <div class="tag2">
                                    <h2 class="text-center p-2"> Section Tickets</h2>
                                </div>
                            </div>

                            <div class="col-md-12 p-bg px-3 py-1 my-2 rounded-slight shadow-sm d-flex align-items-center justify-content-between flex-wrap "
                                 routerLink="/incidents/section/notifications/tickets/secINCOMMING">
                                <div class="d-flex justify-content-center align-items-center py-1">
                                    <button class="mat-elevation-z2" color="white"
                                            mat-mini-fab>
                                        <mat-icon class="mb-1" svgIcon="dash_enty"></mat-icon>
                                    </button>
                                    <div class="p-title px-2"> Incoming</div>
                                </div>
                                <div class="border-left px-3">
                                    <div class="text-muted mt-2 p-subtitle">{{sectionTickets[0]?.sectionStatuses?.submitted || 0 | shortNumber}}</div>
                                </div>
                            </div>

                            <div class="col-md-12 p-bg px-3 py-1 my-2 rounded-slight shadow-sm d-flex align-items-center justify-content-between flex-wrap "
                                 routerLink="/incidents/section/notifications/tickets/secSUBMITTED">
                                <div class="d-flex justify-content-center align-items-center py-1">
                                    <button class="mat-elevation-z2 "
                                            color="white" mat-mini-fab>
                                        <mat-icon class="mb-1" svgIcon="dash_enty"></mat-icon>
                                    </button>
                                    <div class="p-title px-2"> Open</div>
                                </div>
                                <div class="border-left px-3">
                                    <div class="text-muted mt-2 p-subtitle">{{sectionTickets[0]?.sectionStatuses?.open || 0 | shortNumber}}</div>
                                </div>
                            </div>

                            <div class="col-md-12 p-bg px-3 py-1 my-2 rounded-slight shadow-sm d-flex align-items-center justify-content-between flex-wrap "
                                 routerLink="/incidents/section/notifications/tickets/secASSIGNED">
                                <div class="d-flex justify-content-center align-items-center py-1">
                                    <button class="mat-elevation-z2 "
                                            color="white" mat-mini-fab>
                                        <mat-icon class="mb-1" svgIcon="dash_enty"></mat-icon>
                                    </button>
                                    <div class="p-title px-2"> Assigned</div>
                                </div>
                                <div class="border-left px-3">
                                    <div class="text-muted mt-2 p-subtitle">{{sectionTickets[0]?.sectionStatuses?.assigned || 0 | shortNumber}}</div>
                                </div>
                            </div>
                            <div class="col-md-12 p-bg px-3 py-1 my-2 rounded-slight shadow-sm d-flex align-items-center justify-content-between flex-wrap "
                                 routerLink="/incidents/section/notifications/tickets/secRESOLVED">
                                <div class="d-flex justify-content-center align-items-center py-1">
                                    <button class="mat-elevation-z2"
                                            color="white" mat-mini-fab>
                                        <mat-icon class="mb-1" svgIcon="dash_enty"></mat-icon>
                                    </button>
                                    <div class="p-title px-2"> Resolved</div>
                                </div>
                                <div class="border-left px-3">
                                    <div class="text-muted mt-2 p-subtitle">{{sectionTickets[0]?.sectionStatuses?.resolved || 0 | shortNumber}}</div>
                                </div>
                            </div>

                        </mat-card>
                    </div>
                </div>
            </ng-container>
        </ng-container>
        <ng-container *ngxPermissionsOnly="['ROLE_WORK_ON_INTERNAL_INCIDENTS', 'ROLE_WORK_ON_ALL_INCIDENTS']"
        >
            <ng-container *ngIf="internalTickets$ | async as internalTickets ">

                <div class="col-lg-3 col-md-6 col-sm-12 px-2">
                    <div class="py-2">
                        <mat-card
                                class="mat-elevation-z1 rounded-medium shadow-sm right-card  d-flex flex-column justify-content-center align-items-center">
                            <div class="tag">
                                <div class="tag2">
                                    <h2 class="text-center p-2"> Internal Tickets</h2>
                                </div>
                            </div>

                            <div class="col-md-12 p-bg px-3 py-1 my-2 rounded-slight shadow-sm d-flex align-items-center justify-content-between flex-wrap "
                                 routerLink="/incidents/notifications/internal/internalASSIGNED">
                                <div class="d-flex justify-content-center align-items-center py-1">
                                    <button class="mat-elevation-z2" color="white"
                                            mat-mini-fab>
                                        <mat-icon class="mb-1" svgIcon="dash_enty"></mat-icon>
                                    </button>
                                    <div class="p-title px-2"> Assigned</div>
                                </div>
                                <div class="border-left px-3">
                                    <div class="text-muted mt-2 p-subtitle">{{internalTickets[0]?.internalStatuses?.assigned || 0 | shortNumber}}</div>
                                </div>
                            </div>

                            <div class="col-md-12 p-bg px-3 py-1 my-2 rounded-slight shadow-sm d-flex align-items-center justify-content-between flex-wrap "
                                 routerLink="/incidents/notifications/internal/internalSUBMITTED">
                                <div class="d-flex justify-content-center align-items-center py-1">
                                    <button class="mat-elevation-z2 "
                                            color="white" mat-mini-fab>
                                        <mat-icon class="mb-1" svgIcon="dash_enty"></mat-icon>
                                    </button>
                                    <div class="p-title px-2"> Open</div>
                                </div>
                                <div class="border-left px-3">
                                    <div class="text-muted mt-2 p-subtitle">{{internalTickets[0]?.internalStatuses?.open || 0 | shortNumber}}</div>
                                </div>
                            </div>

                            <div class="col-md-12 p-bg px-3 py-1 my-2 rounded-slight shadow-sm d-flex align-items-center justify-content-between flex-wrap "
                                 routerLink="/incidents/notifications/internal/internalRESOLVED">
                                <div class="d-flex justify-content-center align-items-center py-1">
                                    <button class="mat-elevation-z2 "
                                            color="white" mat-mini-fab>
                                        <mat-icon class="mb-1" svgIcon="dash_enty"></mat-icon>
                                    </button>
                                    <div class="p-title px-2"> Resolved</div>
                                </div>
                                <div class="border-left px-3">
                                    <div class="text-muted mt-2 p-subtitle">{{internalTickets[0]?.internalStatuses?.resolved || 0 | shortNumber}}</div>
                                </div>
                            </div>
                            <div class="col-md-12 p-bg px-3 py-1 my-2 rounded-slight shadow-sm d-flex align-items-center justify-content-between flex-wrap "
                                 routerLink="/incidents/notifications/internal/internalCLOSED">
                                <div class="d-flex justify-content-center align-items-center py-1">
                                    <button class="mat-elevation-z2"
                                            color="white" mat-mini-fab>
                                        <mat-icon class="mb-1" svgIcon="dash_enty"></mat-icon>
                                    </button>
                                    <div class="p-title px-2"> Closed</div>
                                </div>
                                <div class="border-left px-3">
                                    <div class="text-muted mt-2 p-subtitle">{{internalTickets[0]?.internalStatuses?.closed || 0 | shortNumber}}</div>
                                </div>
                            </div>

                        </mat-card>
                    </div>
                </div>

            </ng-container>
        </ng-container>

        <div class="col-lg-6 col-md-6 col-sm-12 px-2">
            <div class="py-2">
                <mat-card
                        class="mat-elevation-z1 rounded-medium shadow-sm right-card  d-flex flex-column justify-content-center align-items-center">
                    <div class="tag">
                        <div class="tag2">
                            <h2 class="text-center p-2"> Overall</h2>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center flex-wrap">
                        <ng-container *ngIf="chartOptions">
                            <apx-chart
                                    [chart]="chartOptions?.chart"
                                    [colors]="chartOptions?.colors"
                                    [labels]="chartOptions?.labels"
                                    [plotOptions]="chartOptions?.plotOptions"
                                    [series]="chartOptions?.series"
                            ></apx-chart>
                        </ng-container>
                        <div class="d-flex justify-content-center flex-column">
                            <span class="open my-1"> Open :<span class="mx-1 cnt"> {{totalOpen}} </span></span>
                            <span class="resolved my-1"> Resolved :<span
                                    class="mx-1 cnt"> {{totalResolved}} </span> </span>
                            <span class="closed my-1"> Closed :<span class="mx-1 cnt"> {{totalClosed}} </span></span>
                        </div>
                    </div>
                </mat-card>
            </div>
        </div>


        <!-- individual reported tickes -->
        <ng-container *ngIf="myIndividualTickets$ | async as myIndividualTickets">

            <div class="col-lg-3 col-md-6 col-sm-12 px-2">
                <div class="py-2">
                    <mat-card
                            class="mat-elevation-z1 rounded-medium shadow-sm right-card  d-flex flex-column justify-content-center align-items-center">
                        <div class="tag">
                            <div class="tag2">
                                <h2 class="text-center p-2"> My Reported Tickets</h2>
                            </div>
                        </div>

                        <div class="col-md-12 p-bg px-3 py-1 my-2 rounded-slight shadow-sm d-flex align-items-center justify-content-between flex-wrap "
                             routerLink="/incidents/notifications/myreported-incident/individualDRAFT">
                            <div class="d-flex justify-content-center align-items-center py-1">
                                <button class="mat-elevation-z2" color="white"
                                        mat-mini-fab>
                                    <mat-icon class="mb-1" svgIcon="dash_enty"></mat-icon>
                                </button>
                                <div class="p-title px-2"> Draft</div>
                            </div>
                            <div class="border-left px-3">
                                <div class="text-muted mt-2 p-subtitle">{{myIndividualTickets[0]?.myReportedStatuses?.draft || 0 | shortNumber}}</div>
                            </div>
                        </div>

                        <div class="col-md-12 p-bg px-3 py-1 my-2 rounded-slight shadow-sm d-flex align-items-center justify-content-between flex-wrap "
                             routerLink="/incidents/notifications/myreported-incident/individualSUBMITTED">
                            <div class="d-flex justify-content-center align-items-center py-1">
                                <button class="mat-elevation-z2 "
                                        color="white" mat-mini-fab>
                                    <mat-icon class="mb-1" svgIcon="dash_enty"></mat-icon>
                                </button>
                                <div class="p-title px-2"> Open</div>
                            </div>
                            <div class="border-left px-3">
                                <div class="text-muted mt-2 p-subtitle">{{myIndividualTickets[0]?.myReportedStatuses?.open || 0 | shortNumber}}</div>
                            </div>
                        </div>

                        <div class="col-md-12 p-bg px-3 py-1 my-2 rounded-slight shadow-sm d-flex align-items-center justify-content-between flex-wrap "
                             routerLink="/incidents/notifications/myreported-incident/individualRESOLVED">
                            <div class="d-flex justify-content-center align-items-center py-1">
                                <button class="mat-elevation-z2 "
                                        color="white" mat-mini-fab>
                                    <mat-icon class="mb-1" svgIcon="dash_enty"></mat-icon>
                                </button>
                                <div class="p-title px-2"> Resolved</div>
                            </div>
                            <div class="border-left px-3">
                                <div class="text-muted mt-2 p-subtitle">{{myIndividualTickets[0]?.myReportedStatuses?.resolved || 0 | shortNumber}}</div>
                            </div>
                        </div>
                        <div class="col-md-12 p-bg px-3 py-1 my-2 rounded-slight shadow-sm d-flex align-items-center justify-content-between flex-wrap "
                             routerLink="/incidents/notifications/myreported-incident/individualCLOSED">
                            <div class="d-flex justify-content-center align-items-center py-1">
                                <button class="mat-elevation-z2"
                                        color="white" mat-mini-fab>
                                    <mat-icon class="mb-1" svgIcon="dash_enty"></mat-icon>
                                </button>
                                <div class="p-title px-2"> Closed</div>
                            </div>
                            <div class="border-left px-3">
                                <div class="text-muted mt-2 p-subtitle">{{myIndividualTickets[0]?.myReportedStatuses?.closed || 0 | shortNumber}}</div>
                            </div>
                        </div>

                    </mat-card>
                </div>
            </div>


        </ng-container>

    </ng-container>
    <!-- FOR SERVICE PROVIDERS ONLY STARTS -->


    <!-- FOR CLIENTS ONLY STARTS -->
    <ng-container *ngxPermissionsExcept="['ROLE_HELPDESK_SERVICE_PROVIDER']">
        <ng-container *ngIf="outgoingTickets$ | async as outgoingTickets">
            <div class="col-md-3"></div>
            <div class="col-md-6 col-sm-12 px-2">
                <div class="py-2">
                    <mat-card
                            class="mat-elevation-z1 rounded-medium shadow-sm right-card  d-flex flex-column justify-content-center align-items-center">
                        <div class="tag">
                            <div class="tag2">
                                <h2 class="text-center p-2"> Outgoing Tickets</h2>
                            </div>
                        </div>

                        <div class="col-md-12 p-bg px-3 py-1 my-2 rounded-slight shadow-sm d-flex align-items-center justify-content-between flex-wrap "
                             routerLink="/individual/notifications/reported_issues/DRAFT">
                            <div class="d-flex justify-content-center align-items-center py-1">
                                <button class="mat-elevation-z2" color="white"
                                        mat-mini-fab>
                                    <mat-icon class="mb-1" svgIcon="dash_enty"></mat-icon>
                                </button>
                                <div class="p-title px-2"> Draft</div>
                            </div>
                            <div class="border-left px-3">
                                <div class="text-muted mt-2 p-subtitle">{{outgoingTickets[0]?.outgoingStatuses?.draft || 0 | shortNumber}}</div>
                            </div>
                        </div>

                        <div class="col-md-12 p-bg px-3 py-1 my-2 rounded-slight shadow-sm d-flex align-items-center justify-content-between flex-wrap "
                             routerLink="/individual/notifications/reported_issues/SUBMITTED">
                            <div class="d-flex justify-content-center align-items-center py-1">
                                <button class="mat-elevation-z2 "
                                        color="white" mat-mini-fab>
                                    <mat-icon class="mb-1" svgIcon="dash_enty"></mat-icon>
                                </button>
                                <div class="p-title px-2"> Open</div>
                            </div>
                            <div class="border-left px-3">
                                <div class="text-muted mt-2 p-subtitle">{{outgoingTickets[0]?.outgoingStatuses?.open || 0 | shortNumber}}</div>
                            </div>
                        </div>

                        <div class="col-md-12 p-bg px-3 py-1 my-2 rounded-slight shadow-sm d-flex align-items-center justify-content-between flex-wrap "
                             routerLink="/individual/notifications/reported_issues/RESOLVED">
                            <div class="d-flex justify-content-center align-items-center py-1">
                                <button class="mat-elevation-z2 "
                                        color="white" mat-mini-fab>
                                    <mat-icon class="mb-1" svgIcon="dash_enty"></mat-icon>
                                </button>
                                <div class="p-title px-2"> Resolved</div>
                            </div>
                            <div class="border-left px-3">
                                <div class="text-muted mt-2 p-subtitle">{{outgoingTickets[0]?.outgoingStatuses?.resolved || 0 | shortNumber}}</div>
                            </div>
                        </div>
                        <div class="col-md-12 p-bg px-3 py-1 my-2 rounded-slight shadow-sm d-flex align-items-center justify-content-between flex-wrap "
                             routerLink="/individual/notifications/reported_issues/CLOSED">
                            <div class="d-flex justify-content-center align-items-center py-1">
                                <button class="mat-elevation-z2"
                                        color="white" mat-mini-fab>
                                    <mat-icon class="mb-1" svgIcon="dash_enty"></mat-icon>
                                </button>
                                <div class="p-title px-2"> Closed</div>
                            </div>
                            <div class="border-left px-3">
                                <div class="text-muted mt-2 p-subtitle">{{outgoingTickets[0]?.outgoingStatuses?.closed || 0 | shortNumber}}</div>
                            </div>
                        </div>

                    </mat-card>
                </div>
            </div>
            <div class="col-md-3"></div>
        </ng-container>
    </ng-container>
    <!-- FOR CLIENTS ONLY ENDS -->
</div>


