import { SearchFieldsDtoInput, SearchOperationType } from 'src/app/interfaces/global.interface';
import { Institution } from '../settings/institution/institution.model';
import {  Service } from '../settings/service/service.model';

export interface ClientIdentification {
  id?: number;
  uuid?: string;
  localClientCode?: string;
  institutionService?: Service;
  institution?: Institution;
  clientInstitution?: Institution;
  serviceName?: string;
  clientName?: string;
}

export const IdentificationSearchFields: SearchFieldsDtoInput[] = [
  {
    fieldName: "localClientCode",
    searchType: SearchOperationType.Like,
  },
  {
    fieldName: "uuid",
    searchType: SearchOperationType.Like,
  },
];
