import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Branch } from './branch.model';
import * as BranchActions from './branch.actions';

export const branchsFeatureKey = 'branchs';

export interface State extends EntityState<Branch> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Branch> = createEntityAdapter<Branch>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(BranchActions.addBranch,
    (state, action) => adapter.addOne(action.branch, state)
  ),
  on(BranchActions.upsertBranch,
    (state, action) => adapter.upsertOne(action.branch, state)
  ),
  on(BranchActions.addBranchs,
    (state, action) => adapter.addMany(action.branchs, state)
  ),
  on(BranchActions.upsertBranchs,
    (state, action) => adapter.upsertMany(action.branchs, state)
  ),
  on(BranchActions.updateBranch,
    (state, action) => adapter.updateOne(action.branch, state)
  ),
  on(BranchActions.updateBranchs,
    (state, action) => adapter.updateMany(action.branchs, state)
  ),
  on(BranchActions.deleteBranch,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(BranchActions.deleteBranchs,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(BranchActions.loadBranchs,
    (state, action) => adapter.setAll(action.branchs, state)
  ),
  on(BranchActions.clearBranchs,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
