import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { MaterialFolder } from './material-folder.model';
import * as MaterialFolderActions from './material-folder.actions';

export const materialFoldersFeatureKey = 'materialFolders';

export interface State extends EntityState<MaterialFolder> {
  // additional entities state properties
}

export const adapter: EntityAdapter<MaterialFolder> = createEntityAdapter<MaterialFolder>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export const reducer = createReducer(
  initialState,
  on(MaterialFolderActions.addMaterialFolder,
    (state, action) => adapter.addOne(action.materialFolder, state)
  ),
  on(MaterialFolderActions.upsertMaterialFolder,
    (state, action) => adapter.upsertOne(action.materialFolder, state)
  ),
  on(MaterialFolderActions.addMaterialFolders,
    (state, action) => adapter.addMany(action.materialFolders, state)
  ),
  on(MaterialFolderActions.upsertMaterialFolders,
    (state, action) => adapter.upsertMany(action.materialFolders, state)
  ),
  on(MaterialFolderActions.updateMaterialFolder,
    (state, action) => adapter.updateOne(action.materialFolder, state)
  ),
  on(MaterialFolderActions.updateMaterialFolders,
    (state, action) => adapter.updateMany(action.materialFolders, state)
  ),
  on(MaterialFolderActions.deleteMaterialFolder,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(MaterialFolderActions.deleteMaterialFolders,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(MaterialFolderActions.loadMaterialFolders,
    (state, action) => adapter.setAll(action.materialFolders, state)
  ),
  on(MaterialFolderActions.clearMaterialFolders,
    state => adapter.removeAll(state)
  ),
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
