export interface Page {
    id?: number;
    totalElements?: number;
    totalPages?: number;
    size?: number;
    first?: boolean;
    last?: boolean;
    hasContent?: boolean;
    hasNext?: boolean;
    numberOfElements?: number;
    content?: any[];
}

export interface PageableParam {
    first?: number;
    size?: number;
    sortBy?: string;
    sortDirection?: string;
    searchCombinationType?: SearchCombinationType;
    searchFields?: SearchFieldsDtoInput[];
}


export const initializedPageableParameter = {
    first: 0, size: 10, sortBy: 'id', sortDirection: 'DESC',
};


export enum SearchCombinationType {
    And = "And", Or = "Or"
}

export interface SearchFieldsDtoInput {
    fieldName?: string;
    fieldValue?: string;
    fieldValues?: string[];
    searchType?: SearchOperationType;
}

export enum SearchOperationType {
    Between = "Between",
    Equals = "Equals",
    GreaterThan = "GreaterThan",
    In = "In",
    LessThan = "LessThan",
    Like = "Like",
    NotEquals = "NotEquals"
}