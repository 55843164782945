<app-modal-header [title]="'My Customers Form'"(closeModal)="closeThisModal($event)" ></app-modal-header>

<main-dynamic-form @fadeIn *ngIf="serviceFiels"  [form]="serviceForm" [fields]="serviceFiels" (fieldData)="fieldData($event)"></main-dynamic-form>
<main-dynamic-form @fadeIn *ngIf="fields"  [form]="form" [fields]="fields" (fieldData)="fieldData($event)" (submit)="addItem($event)"></main-dynamic-form>

<br> <br>

<div class="datagrid">
    <table *ngIf="itemList">
      <ng-container *ngIf="itemList?.length > 0">
         <thead><tr>
           <th>S/No</th>
           <th>Client</th>
           <th>Code</th>
           <th>Service</th>
           <th>Action</th>
         </tr></thead>
         <tbody>
           <tr *ngFor="let item of itemList; let i = index">
             <td class="no">{{ i + 1 }}</td>
             <td>{{ item?.clientName }}</td>
             <td>{{ item?.code }}</td>
             <td>{{ item?.serviceName }}</td>
             <td class="action">
               <span class="delete"> <mat-icon color="red" (click)="deleteItem(item)"  matTooltip="Remove">close</mat-icon> </span>
                </td>
           </tr>
        
         </tbody>
       </ng-container>
       <p *ngIf="itemList?.length === 0">NO CLIENTS ADDED</p>
     </table>
</div>

<div align="end">
  <br>
  <button [disabled]="(itemList || []).length == 0" mat-raised-button color="primary" (click)="submit()">
    Save
  </button>
</div>