import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Permission } from './permission.model';

export const loadPermissions = createAction(
  '[Permission/API] Load Permissions',
  props<{ permissions: Permission[] }>()
);

export const addPermission = createAction(
  '[Permission/API] Add Permission',
  props<{ permission: Permission }>()
);

export const upsertPermission = createAction(
  '[Permission/API] Upsert Permission',
  props<{ permission: Permission }>()
);

export const addPermissions = createAction(
  '[Permission/API] Add Permissions',
  props<{ permissions: Permission[] }>()
);

export const upsertPermissions = createAction(
  '[Permission/API] Upsert Permissions',
  props<{ permissions: Permission[] }>()
);

export const updatePermission = createAction(
  '[Permission/API] Update Permission',
  props<{ permission: Update<Permission> }>()
);

export const updatePermissions = createAction(
  '[Permission/API] Update Permissions',
  props<{ permissions: Update<Permission>[] }>()
);

export const deletePermission = createAction(
  '[Permission/API] Delete Permission',
  props<{ id: string }>()
);

export const deletePermissions = createAction(
  '[Permission/API] Delete Permissions',
  props<{ ids: string[] }>()
);

export const clearPermissions = createAction(
  '[Permission/API] Clear Permissions'
);

// Effect Actions
export const getAllPermissions = createAction(
  '[Permission/API] Get All Permissions'
);

export const getPermissionsByRoleId = createAction(
  '[Permission/API] Get Permissions By Role Id',
  props<{ roleId: number }>()
);

export const assignPermission = createAction(
  '[Permission/API] Assign Permission',
  props<{ permissions: number[], roleId: number }>()
);

export const getAllPermissionsGrouped = createAction(
  '[Permission/API] Get All Permissions Grouped'
);
