import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import { switchMap, map } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification.service';
import { AppState } from 'src/app/store/reducers';
import * as fromActions from './tasco-isce-group.actions';
import * as fromGraphql from './tasco-isce-group.graphql';

@Injectable()
export class TascoIsceGroupEffects {
  // List all TascoIsceGroups
  getAllTascoIsceGroups$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.getAllTascoIsceGroups),
        switchMap((action) => {
          return this.apollo
            .query({
              query: fromGraphql.GET_ALL_TASCO_ISCE_GROUPS,
              fetchPolicy: 'network-only',
            })
            .pipe(
              map(({ data }: any) => {
                this.store.dispatch(
                  fromActions.loadTascoIsceGroups({
                    tascoIsceGroups: data?.getAllGroupClassifications,
                  })
                );
              })
            );
        }),
        this.notificationService.catchError(
          'Problem occurred while listing all Tasco Groups'
        )
      ),
    { dispatch: false }
  );

  // List all TascoIsceGroups

  getAllTascoIsceGroupsPageable$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.getAllTascoIsceGroupsPageable),
        switchMap((action) => {
          return this.apollo
            .query({
              query: fromGraphql.GET_ALL_TASCO_ISCE_GROUPS_PAGEABLE,
              fetchPolicy: 'network-only',
              variables: {
                pageableParam: action.pageableParam,
              },
            })
            .pipe(
              map(({ data }: any) => {
                this.store.dispatch(
                  fromActions.loadTascoIsceGroups({
                    tascoIsceGroups:
                      data?.getAllTascoIsceGroupPageable?.content,
                  })
                );
              })
            );
        }),
        this.notificationService.catchError(
          'Problem occurred while listing all Tasco Groups'
        )
      ),
    { dispatch: false }
  );

  // List all TascoIsceGroups by status
  getAllActiveTascoIsceGroupsByStatus$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.getAllActiveTascoIsceGroupsByStatus),
        switchMap((action) => {
          return this.apollo
            .query({
              query: fromGraphql.GET_ALL_ACTIVE_TASCO_ISCE_GROUPS_BY_STATUS,
              fetchPolicy: 'network-only',
              variables: {
                active: action.active,
              },
            })
            .pipe(
              map(({ data }: any) => {
                this.store.dispatch(
                  fromActions.loadTascoIsceGroups({
                    tascoIsceGroups: data?.getAllActiveTascoIsceGroupsByStatus,
                  })
                );
              })
            );
        }),
        this.notificationService.catchError(
          'Problem occurred while listing all Tasco Groups'
        )
      ),
    { dispatch: false }
  );

  // Get TascoIsceGroup By Uid
  getTascoIsceGroupByUid$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.getTascoIsceGroupByUid),
        switchMap((action) => {
          return this.apollo
            .query({
              query: fromGraphql.GET_TASCO_ISCE_GROUP_OBJECT_BY_UID,
              fetchPolicy: 'network-only',
              variables: {
                uid: action.uid,
              },
            })
            .pipe(
              map(({ data }: any) => {
                this.store.dispatch(
                  fromActions.upsertTascoIsceGroup({
                    tascoIsceGroup: data.getTascoIsceGroupObjectByUid,
                  })
                );
              })
            );
        }),
        this.notificationService.catchError(
          'Problem occurred while getting Tasco Group by uid'
        )
      ),
    { dispatch: false }
  );

  // Create TascoIsceGroup // save // upadte
  saveTascoIsceGroup$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.saveTascoIsceGroup),
        switchMap((action) => {
          return this.apollo
            .mutate({
              mutation: fromGraphql.SAVE_TASCO_ISCE_GROUP,
              variables: {
                groupClassificationDto: action.tascoIsceGroupDto,
              },
            })
            .pipe(
              map(({ data }: any) => {
                if (data?.saveTascoIsceGroup?.code === 9000) {
                  this.store.dispatch(
                    fromActions.upsertTascoIsceGroup({
                      tascoIsceGroup: data.saveTascoIsceGroup.data,
                    })
                  );
                  this.notificationService.successMessage(
                    'TascoIsceGroup Saved Successfully'
                  );
                } else {
                  this.notificationService.handleErrorMessage(
                    data.saveTascoIsceGroup
                  );
                }
              })
            );
        }),
        this.notificationService.catchError(
          'Problem occurred while saving Tasco Group'
        )
      ),
    { dispatch: false }
  );

  getGroupsByCategoryAndGroup$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.getGroupsByCategoryAndGroup),
        switchMap((action) => {
          return this.apollo
            .query({
              query: fromGraphql.GET_GROUPS_BY_CATEGORY_AND_GROUP,
              fetchPolicy: 'network-only',
              variables: {
                group: action?.group,
                category: action?.category
              },
            })
            .pipe(
              map(({data}: any) => {

                this.store.dispatch(
                  fromActions.loadTascoIsceGroups({
                    tascoIsceGroups: data?.getGroupsByCategoryAndGroup
                  })
                )
                // this.store.dispatch(
                //   fromActions.loadTascoIsceGroups({
                //     tascoIsceGroups:
                //       data?.getAllTascoIsceGroupPageable?.content,
                //   })
                // );
              })
            );
        }),
        this.notificationService.catchError(
          'Problem occurred while listing all Tasco Groups'
        )
      ),
    { dispatch: false }
  );

  // Delete a TascoIsceGroup
  removeTascoIsceGroup$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.removeTascoIsceGroup),
        switchMap((action) => {
          return this.apollo
            .mutate({
              mutation: fromGraphql.DELETE_TASCO_ISCE_GROUP,
              variables: {
                uid: action.uid,
              },
            })
            .pipe(
              map(({ data }: any) => {
                if (data?.deleteTascoIsceGroup?.code === 9000) {
                  this.store.dispatch(
                    fromActions.deleteTascoIsceGroup({
                      id: action.id,
                    })
                  );
                  this.notificationService.successMessage(
                    'Tasco Group Deleted Successfully'
                  );
                } else {
                  this.notificationService.handleErrorMessage(
                    data.deleteTascoIsceGroup
                  );
                }
              })
            );
        }),
        this.notificationService.catchError(
          'Problem occurred while deleting a Tasco Group'
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private apollo: Apollo,
    private store: Store<AppState>,
    private notificationService: NotificationService
  ) {}
}
