import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { IncidentResolution } from './incident-resolution.model';
import * as IncidentResolutionActions from './incident-resolution.actions';

export const incidentResolutionsFeatureKey = 'incidentResolutions';

export interface State extends EntityState<IncidentResolution> {
  // additional entities state properties
}

export const adapter: EntityAdapter<IncidentResolution> = createEntityAdapter<IncidentResolution>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export const reducer = createReducer(
  initialState,
  on(IncidentResolutionActions.upsertIncidentResolution,
    (state, action) => adapter.upsertOne(action.incidentResolution, state)
  ),
  on(IncidentResolutionActions.addIncidentResolutions,
    (state, action) => adapter.addMany(action.incidentResolutions, state)
  ),
  on(IncidentResolutionActions.upsertIncidentResolutions,
    (state, action) => adapter.upsertMany(action.incidentResolutions, state)
  ),
  on(IncidentResolutionActions.updateIncidentResolution,
    (state, action) => adapter.updateOne(action.incidentResolution, state)
  ),
  on(IncidentResolutionActions.updateIncidentResolutions,
    (state, action) => adapter.updateMany(action.incidentResolutions, state)
  ),
  on(IncidentResolutionActions.deleteIncidentResolution,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(IncidentResolutionActions.deleteIncidentResolutions,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(IncidentResolutionActions.loadIncidentResolutions,
    (state, action) => adapter.setAll(action.incidentResolutions, state)
  ),
  on(IncidentResolutionActions.clearIncidentResolutions,
    state => adapter.removeAll(state)
  ),
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
