<app-modal-header
  (closeModal)="closeModal($event)"
  [title]="title"
></app-modal-header>
<ng-container *ngIf=" user$ | async as userData">
    <div class="text-muted px-3 px-md-3 px-lg-4 faded text-bold my-2 mt-3"> USER DETAILS </div>

    <div class="py-2 pb-4 px-3 px-md-3 px-lg-4 h-100 mb-3 w-100 position-relative content-item bg-primary-slightly-faded border-slightly-faded rounded-slight "style="--order:1" >
        <div class="row">
                <div class="col-md-6 col-lg-4 px-md-3 px-lg-4 d-flex flex-column justify-content-between py-2">
                    <div class="text-primary text-bold">First Name</div>
                    <div class="text-muted" >
                        <span >{{ userData.firstName }}</span>
                    </div> 
                    <mat-divider></mat-divider>                                                              
                </div> 
                <div class="col-md-6 col-lg-4 px-md-3 px-lg-4 d-flex flex-column justify-content-between py-2">
                    <div class="text-primary text-bold">Middle Name</div>
                    <div class="text-muted" >
                        <span >{{ userData.middleName }}</span>
                    </div> 
                    <mat-divider></mat-divider>                                                              
                </div> 
                <div class="col-md-6 col-lg-4 px-md-3 px-lg-4 d-flex flex-column justify-content-between py-2">
                    <div class="text-primary text-bold">Last Name</div>
                    <div class="text-muted" >
                        <span >{{ userData.lastName }}</span>
                    </div> 
                    <mat-divider></mat-divider>                                                              
                </div> 
        </div>  

        <div class="row">
            <div class="col-md-6 col-lg-4 px-md-3 px-lg-4 d-flex flex-column justify-content-between py-2">
                <div class="text-primary text-bold">Check Number</div>
                <div class="text-muted" >
                    <span >{{ userData.checkNumber }}</span>
                </div> 
                <mat-divider></mat-divider>                                                              
            </div> 
            <div class="col-md-6 col-lg-4 px-md-3 px-lg-4 d-flex flex-column justify-content-between py-2">
                <div class="text-primary text-bold">Email</div>
                <div class="text-muted" >
                    <span >{{ userData.email }}</span>
                </div> 
                <mat-divider></mat-divider>                                                              
            </div> 
            <div class="col-md-6 col-lg-4 px-md-3 px-lg-4 d-flex flex-column justify-content-between py-2">
                <div class="text-primary text-bold">Phone</div>
                <div class="text-muted" >
                    <span >{{ userData.phone }}</span>
                </div> 
                <mat-divider></mat-divider>                                                              
            </div> 

        <div class="text-muted px-3 px-md-3 px-lg-4 faded text-bold my-2 mt-3"> ROLES LIST ({{userData?.rolesList.length}} ) </div>

    <!-- ROLE LIST ARRAY -->
    <ng-container *ngIf="userData?.rolesList">
        <ng-container *ngFor="let arrayField of userData?.rolesList">
            <div class="text-muted text-uppercase faded text-bold my-2 mt-3 px-3 px-md-3 px-lg-4">
                {{arrayField?.displayName}}
            </div>
        </ng-container>
    </ng-container> 

    </div> 
    </div> 



</ng-container>