import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Attachment } from './attachment.model';

export const loadAttachments = createAction(
  '[Attachment/API] Load Attachments',
  props<{ attachments: Attachment[] }>()
);

export const addAttachment = createAction(
  '[Attachment/API] Add Attachment',
  props<{ attachment: Attachment }>()
);

export const upsertAttachment = createAction(
  '[Attachment/API] Upsert Attachment',
  props<{ attachment: Attachment }>()
);

export const addAttachments = createAction(
  '[Attachment/API] Add Attachments',
  props<{ attachments: Attachment[] }>()
);

export const upsertAttachments = createAction(
  '[Attachment/API] Upsert Attachments',
  props<{ attachments: Attachment[] }>()
);

export const updateAttachment = createAction(
  '[Attachment/API] Update Attachment',
  props<{ attachment: Update<Attachment> }>()
);

export const updateAttachments = createAction(
  '[Attachment/API] Update Attachments',
  props<{ attachments: Update<Attachment>[] }>()
);

export const deleteAttachment = createAction(
  '[Attachment/API] Delete Attachment',
  props<{ id: number }>()
);

export const deleteAttachments = createAction(
  '[Attachment/API] Delete Attachments',
  props<{ ids: number[] }>()
);

export const clearAttachments = createAction(
  '[Attachment/API] Clear Attachments'
);

export const listAllAttachmentByIncidentId = createAction(
  '[Attachment/API] List Attachment By Incident uid',
  props<{ incidentUId: string }>()
);

export const attachmentsByConversationUuid = createAction(
  '[Attachment/API] List Attachment By Conversation uid',
  props<{ conversationUuid: string }>()
);


export const uploadAttachment = createAction(
  '[Attachment/API] Upload Attachment',
  props<{ attachment: Attachment }>()
);


export const removeAttachment = createAction(
  '[Attachment/API] remove Attachment',
  props<{ attachmentUid: string, attachmentId: number }>()
);

export const uploadInstitutionLogo = createAction(
  '[Attachment/API] Create Institution Logo',
  props<{ logoFile: any }>()
);

