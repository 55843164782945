<!-- HEADER START HERE -->
<app-header></app-header>
<!-- HEADER END HERE -->
<!-- <div data-layout="horizontal"> -->

<app-top-bar [sidebarMenu]="sideMenu"></app-top-bar>

<div id="layout-wrapper">
    <!-- <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false">

        </mat-sidenav> -->

    <div class="main-content">

        <div class="page-content">
            <div class="container-fluid">
                <!-- CONTENT START HERE -->
                <router-outlet></router-outlet>
                <!-- <app-content></app-content> -->
                <!-- CONTENT END HERE -->
            </div>
            <!-- container-fluid -->
        </div>
        <!-- End Page-content -->
    </div>
    <!-- end main content-->
</div>
<!-- </div> -->
<div class="ega xs-hide">
    <div>
        <strong>&copy; {{currentYear}} </strong> - <strong>e - Government Authority</strong>
    </div>
    <div>
        <strong>The system is designed and developed by </strong>
        <a href="http://ega.go.tz" target="_blank" title="e-Government Authority">
            <img alt="eGA" src="/assets/images/ega.png">
        </a>
    </div>
</div>