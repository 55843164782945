import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ChangeRequest } from './change-request.model';
import * as ChangeRequestActions from './change-request.actions';

export const ChangeRequestsFeatureKey = 'ChangeRequests';

export interface State extends EntityState<ChangeRequest> {
  // additional entities state properties
}

export const adapter: EntityAdapter<ChangeRequest> = createEntityAdapter<ChangeRequest>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(ChangeRequestActions.addChangeRequest,
    (state, action) => adapter.addOne(action.changeRequest, state)
  ),
  on(ChangeRequestActions.upsertChangeRequest,
    (state, action) => adapter.upsertOne(action.changeRequest, state)
  ),
  on(ChangeRequestActions.addChangeRequests,
    (state, action) => adapter.addMany(action.changeRequests, state)
  ),
  on(ChangeRequestActions.upsertChangeRequests,
    (state, action) => adapter.upsertMany(action.changeRequests, state)
  ),
  on(ChangeRequestActions.updateChangeRequest,
    (state, action) => adapter.updateOne(action.changeRequest, state)
  ),
  on(ChangeRequestActions.updateChangeRequests,
    (state, action) => adapter.updateMany(action.changeRequests, state)
  ),
  on(ChangeRequestActions.deleteChangeRequest,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(ChangeRequestActions.deleteChangeRequests,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(ChangeRequestActions.loadChangeRequests,
    (state, action) => adapter.setAll(action.changeRequests, state)
  ),
  on(ChangeRequestActions.clearChangeRequests,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
