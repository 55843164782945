import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import { map, switchMap } from 'rxjs/operators';
import { initializedPageableParameter } from 'src/app/interfaces/global.interface';
import { NotificationService } from 'src/app/services/notification.service';
import { ResponseCodeService } from 'src/app/services/response-code.service';
import { AppState } from '../../reducers';
import * as fromActions from './solution-step.actions';
import * as fromGraphql from './solution-step.graphql';

@Injectable()
export class SolutionStepEffects {

  getAllSolutionStepsByIncident$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.getAllSolutionStepsByIncident),
    switchMap((action) => {
      return this.apollo.query({
        query: fromGraphql.GET_INCIDENT_SOLUTIONS_BY_INCIDENT,
        variables: {
          incidentUuid: action?.incidentUuid,   pageparam: action?.pageable ? action.pageable : initializedPageableParameter,
        },
        fetchPolicy: 'network-only',
      }).pipe(
        this.notificationService.catchError('Problem occurred'),
        map(({ data }: any) => {
          if (data) {
            this.store.dispatch(fromActions.loadSolutionSteps({ solutionSteps: data?.getAllSolutionStepsByIncident?.content }));
          } else {
            this.notificationService.errorMessage('Failed to fetch request');
          }
        })
      );
    })
  ), {dispatch: false});

  deleteKnowledgeBaseQuestionSolutionStep$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.deleteKnowledgeBaseQuestionSolutionStep),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: fromGraphql.REMOVE_INCIDENT_SOLUTION,
        variables: {
          incidentSolutionStepUuid: action?.incidentSolutionStepUuid
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred'),
        map(({ data }: any) => {
          if (data) {
            if (data.deleteKnowledgeBaseQuestionSolutionStep.code === 9000) {
              this.store.dispatch(fromActions.deleteSolutionStep({ id: data?.deleteKnowledgeBaseQuestionSolutionStep?.data?.id}) );
              this.notificationService.successMessage('Deleted Successfully');
            } else {
              this.notificationService.errorMessage(data.deleteKnowledgeBaseQuestionSolutionStep);
            }
          }
        })
      );
    })
  ), {dispatch: false});

  addSolutionStep$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.addSolutionStep),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: fromGraphql.ADD_INCIDENT_SOLUTIONS,
        variables: {
          incidentSolutionStep: action?.solutionStep
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while adding a solution step'),
        map(({ data }: any) => {
          if (data) {
            if (data.addUpdateKnowledgeBaseQuestionSolutionStep.code === 9000) {
              this.store.dispatch(fromActions.upsertSolutionStep({ solutionStep: data?.addUpdateKnowledgeBaseQuestionSolutionStep?.data}) );
              if(action.solutionStep?.uuid){              
                this.notificationService.successMessage('Updated Successfully');
              } else {
                this.notificationService.successMessage('Added Successfully');
              }
            } else {
              this.notificationService.errorMessage(data.addUpdateKnowledgeBaseQuestionSolutionStep);
            }
          }
        })
      );
    })
  ), {dispatch: false});






  constructor(
    private actions$: Actions,
    private apollo: Apollo,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private responseCodesService: ResponseCodeService
  ) {
  }

}
