import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { IncidentRootCause } from './incident-root-cause.model';
import * as IncidentRootCauseActions from './incident-root-cause.actions';

export const incidentRootCausesFeatureKey = 'incidentRootCauses';

export interface State extends EntityState<IncidentRootCause> {
  // additional entities state properties
}

export const adapter: EntityAdapter<IncidentRootCause> = createEntityAdapter<IncidentRootCause>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export const reducer = createReducer(
  initialState,
  on(IncidentRootCauseActions.upsertIncidentRootCause,
    (state, action) => adapter.upsertOne(action.incidentRootCause, state)
  ),
  on(IncidentRootCauseActions.addIncidentRootCauses,
    (state, action) => adapter.addMany(action.incidentRootCauses, state)
  ),
  on(IncidentRootCauseActions.upsertIncidentRootCauses,
    (state, action) => adapter.upsertMany(action.incidentRootCauses, state)
  ),
  on(IncidentRootCauseActions.updateIncidentRootCause,
    (state, action) => adapter.updateOne(action.incidentRootCause, state)
  ),
  on(IncidentRootCauseActions.updateIncidentRootCauses,
    (state, action) => adapter.updateMany(action.incidentRootCauses, state)
  ),
  on(IncidentRootCauseActions.deleteIncidentRootCause,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(IncidentRootCauseActions.deleteIncidentRootCauses,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(IncidentRootCauseActions.loadIncidentRootCauses,
    (state, action) => adapter.setAll(action.incidentRootCauses, state)
  ),
  on(IncidentRootCauseActions.clearIncidentRootCauses,
    state => adapter.removeAll(state)
  ),
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
