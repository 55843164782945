import gql from 'graphql-tag';

export const supplierFields = `
active
createdAt
createdBy
deleted
id
institutionId
location
name
phone
updatedAt
updatedBy
uuid
`;

// resources




export const GET_MY_INSTITUTION_ALL_SUPPLIERS = gql`
query getAllInstitutionSuppliers($pageParam: PageableParamInput){
  getAllInstitutionSuppliers(pageParam:$pageParam){
    content{
     ${supplierFields}
    }
    first
    hasContent
    hasNext
    hasPrevious
    last
    nextOrLastPageable{
      pageSize
      pageNumber
    }
    nextPageable{
      pageSize
      pageNumber
    }
    number
    numberOfElements
    pageable{
      pageSize
      pageNumber
    }
    previousPageable{
      pageSize
      pageNumber
    }
    previousOrFirstPageable{
      pageSize
      pageNumber
    }
    size
    totalElements
    totalPages
    
  }
}
`;

export const GET_MY_INSTITUTION_ALL_SUPPLIERS_FOR_LIST = gql`
query getAllInstitutionSuppliers($pageParam: PageableParamInput){
  getAllInstitutionSuppliers(pageParam:$pageParam){
    content{
      id
      uuid
      name
    }
  }
}
`;






export const ACTIVATE_AND_DEACTIVATE_SUPPLIER = gql`
mutation activateDeactivateSupplier($supplierId: Long){
  activateDeactivateSupplier(supplierId:$supplierId){
    code
    data{
      ${supplierFields}
    }
    dataList{
      ${supplierFields}
    }
    message
    status
  }
}
`;

export const SAVE_UPDATE_SUPPLIER = gql`
mutation createUpdateSupplier($supplier: SupplierDtoInput){
  createUpdateSupplier(supplier:$supplier){
    code
    data{
      ${supplierFields}
    }
    dataList{
      ${supplierFields}
    }
    message
    status
  }
}
`;


export const DELETE_SUPPLIER = gql`
mutation deleteSupplier($supplierId: Long){
  deleteSupplier(supplierId:$supplierId){
    code
    data{
      ${supplierFields}
    }
    dataList{
      ${supplierFields}
    }
    message
    status
  }
}
`;




