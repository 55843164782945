import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import { NotificationService } from 'src/app/services/notification.service';
import { ResponseCodeService } from 'src/app/services/response-code.service';
import { AppState } from 'src/app/store/reducers';
import * as fromActions from './severity.actions';
import * as fromGraphql from './severity.graphql';
import { map, switchMap } from 'rxjs/operators';
import { PaginationService } from 'src/app/services/pagination.service';



@Injectable()
export class SeverityEffects {

  // getAllInstitutionSeverities
  getAllInstitutionSeverities$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.getAllInstitutionSeverities),
    switchMap((action) => {
      return this.apollo.query({
        query: fromGraphql.LIST_ALL_SEVERITIES_PAGEABLE,
        fetchPolicy: 'network-only',
        variables: {
            pageparam: action.pageableParam
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while fetching Severities'),
        map(({ data }: any) => {
          if (data) {
            const result = data?.getAllInstitutionSeverities;
            if (result?.content?.length > 0) {
              this.paginationService.setPage(result);
            }
            this.store.dispatch(fromActions.loadSeveritys({ severitys: data.getAllInstitutionSeverities.content }));
          }
        })
      );
    })
  ), {dispatch: false});

  // getSeveritiesByInstitution
  getSeveritiesByInstitution$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.getSeveritiesByInstitution),
    switchMap((action) => {
      return this.apollo.query({
        query: fromGraphql.GET_SEVERITY_BY_INSTITUTION,
        fetchPolicy: 'network-only',
        variables: {
          pageparam: action.pageparam,
          institutionUuid: action.institutionUuid,
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while fetching Severities'),
        map(({ data }: any) => {
          if (data) {
            const result = data?.getSeveritiesByInstitution;
            if (result?.content?.length > 0) {
              this.paginationService.setPage(result);
              this.store.dispatch(fromActions.loadSeveritys({ severitys: data.getSeveritiesByInstitution.content || [] }));
            }
          }
        })
      );
    })
  ), {dispatch: false});

  // addUpdateSeverity
  addUpdateSeverity$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.addUpdateSeverity),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: fromGraphql.SAVE_SEVERITY,
        variables: {
          severity: action.severity
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while saving severity'),
        map(({ data }: any) => {
          if (data) {
            const response = this.responseCodesService?.getCodeDescription(data.addUpdateSeverity.code);
            if (data.addUpdateSeverity.code === 9000) {
              this.store.dispatch(fromActions.upsertSeverity({ severity: data.addUpdateSeverity.data }));
              this.notificationService.successMessage('Operation successful: '+ response[0].code);
            } else {
              this.notificationService.determineSnackbar(response[0]);
            }
          }
        })
      );
    })
  ), {dispatch: false});

  // Update Severity
  modifySeverity$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.modifySeverity),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: fromGraphql.UPDATE_SEVERITY,
        variables: {
          severityDto: action.severityDto,
          severityId: action.severityId
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while updating severity'),
        map(({ data }: any) => {
          if (data) {
            if (data.updateSeverityById.code === 9000) {
              this.store.dispatch(fromActions.upsertSeverity({ severity: data.updateSeverityById.data }));
              this.notificationService.successMessage('Edited Successfully');
            } else {
              const error = this.responseCodesService.getCodeDescription(data.updateSeverityById.code);
              this.notificationService.errorMessage(error[0]?.description);
            }
          }
        })
      );
    })
  ), {dispatch: false});

  // Delete Severity
  removeSeverity$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.removeSeverity),
    switchMap((action) => {
     
      return this.apollo.mutate({
        mutation: fromGraphql.DELETE_SEVERITY,
        variables: {
          severityId: action.severityId
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while deleting Severity'),
        map(({ data }: any) => {
          if (data) {
             
            if (data.deleteSeverityById === 'DELETED') {
              this.store.dispatch(fromActions.deleteSeverity({ id: action.severityId }));
              this.notificationService.successMessage('Deleted Successfully');
            } else {
              this.notificationService.errorMessage(data.deleteSeverityById);
            }
          }
        })
      );
    })
  ), {dispatch: false});

  constructor(
    private actions$: Actions,
    private apollo: Apollo,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private responseCodesService: ResponseCodeService,
    private paginationService: PaginationService,
  ) {
  }

}
