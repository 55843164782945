import {Injectable} from '@angular/core';
import {DatePipe} from '@angular/common';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ViewAttachmentComponent} from '../shared/components/view-attachment/view-attachment.component';
import {OpenDialog} from '../interfaces/global.interface';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class SettingsService {
    formatEndTimeToDate(counsellingEndDate: any, arg1: string): any {
      throw new Error('Method not implemented.');
    }
    transformDateTimeToTime(endDate: any): any {
      throw new Error('Method not implemented.');
    }
    formatDateTime(startDate: any) {
      throw new Error('Method not implemented.');
    }
    constructor(private datePipe: DatePipe,
                public dialog: MatDialog,
                private router: Router,
    ) {
    }

    formatDateWithForwardSlash(date: Date) {
        return this.datePipe.transform(date, 'dd/MM/yyyy');
    }

    decendingDateformatDate(date, format: string = 'yyyy-MM-dd HH:mm'): string {
        return this.datePipe.transform(date, format);
    }

    formatDate(date, format: string = 'dd/MM/yyyy'): string {
        return this.datePipe.transform(date, format);
    }

    formatDateStartWithYearAndDash(date, format: string = 'yyyy-MM-dd'): string {
        return this.datePipe.transform(date, format);
    }

    reloadCurrentRoute(currentUrl) {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        currentUrl = currentUrl === null ? this.router.url : currentUrl;
        this.router.navigate([currentUrl]);
    }

    bytesToMB(bytes: number): number {
        return bytes / (1024 * 1024);
    }

    convertBytesToGB(bytes: number): number {
        const respo = bytes / (1024 * 1024 * 1024);
        return Number(respo.toFixed(3));
    }

    getDateDiffInDays(param: { date1: Date, date2: Date }): number {
        const diff = param?.date1.getTime() - param?.date2.getTime();
        return Math.round(diff / 86400000);
    }

    formatDateToLocalDate(date) {
        if (!date) {
            return null;
        }
        let startDate = this.formatDateWithForwardSlash(date);
        const splittedStartDate = startDate?.replace(/\//ig, '-')?.split('-');
        return splittedStartDate[2] + '-' + splittedStartDate[1] + '-' + splittedStartDate[0];
    }

    async viewFile(base64string, fileFormat, name = null) {
        const dialogRef = this.dialog.open(ViewAttachmentComponent, {
            width: '80%',
            data: {
                file: base64string,
                format: fileFormat,
                name,
            },
        });

        return dialogRef.afterClosed().toPromise();
    }

    b64toBlob(b64Data, contentType, sliceSize = 512) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;
        const byteCharacters = atob(b64Data);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, {type: contentType});
        return URL.createObjectURL(blob);
    }

    encoder(data, loop = 2, key = 'secured'): string {
        let returnedData = btoa(data);
        const keyValue = btoa(key);
        returnedData = btoa(keyValue + returnedData);
        for (let i = 0; i < loop; i++) {
            returnedData = btoa(returnedData);
        }
        return returnedData;
    }

    decoder(data, loop = 2, key = 'secured') {
        let returnedData = data;

        for (let i = 0; i < loop; i++) {
            returnedData = atob(returnedData);
        }
        const keyValue = btoa(key);
        returnedData = atob(returnedData);

        return atob(returnedData.substr(keyValue.length));
    }

    openDialogModal(dialog: OpenDialog) {
        const dialogConfig = new MatDialogConfig();
        const classes: string[] = [];
        if (dialog?.panelClass?.length > 0) {
            classes.push(...dialog?.panelClass);
        }
        dialogConfig.panelClass = classes;
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = dialog?.width ? dialog?.width : '50%';
        dialogConfig.data = dialog?.data;
        if (dialog?.position != null) {
            dialogConfig.position = dialog?.position;
        }
        if (dialog?.height != null) {
            dialogConfig.height = dialog?.height;
        }
        this.dialog.open(dialog?.component, dialogConfig);
    }

    openDialogModal2(dialog: OpenDialog) {
        const dialogConfig = new MatDialogConfig();
        const classes: string[] = [];
        if (dialog?.panelClass?.length > 0) {
            classes.push(...dialog?.panelClass);
        }
        dialogConfig.panelClass = classes;
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = dialog?.width ? dialog?.width : '50%';
        dialogConfig.data = dialog?.data;
        if (dialog?.position != null) {
            dialogConfig.position = dialog?.position;
        }
        if (dialog?.height != null) {
            dialogConfig.height = dialog?.height;
        }
        // this.dialog.open(dialog?.component, dialogConfig);

        return this.dialog.open(dialog?.component, dialogConfig)?.afterClosed().toPromise();
    }

    generatePagination(current: number, lastPage: number) {
        // return  Array((totalPages === 1) ? 1 : totalPages).fill(0).map((x, i) => ({id: i}));
        const delta = 6;
        const range = [0];
        const rangeWithDots = [];
        let l;

        range.push(1);
        for (let i = current - delta; i <= current + delta; i++) {
            if (i < lastPage && i > 1) {
                range.push(i);
            }
        }
        range.push(lastPage);

        for (const i of range) {
            if (l) {
                if (i - l === 2) {
                    rangeWithDots.push(l + 1);
                } else if (i - l !== 1) {
                    rangeWithDots.push('...');
                }
            }
            rangeWithDots.push(i);
            l = i;
        }
        return rangeWithDots;
    }

    formatDateMonthName(date): string {
        return this.datePipe.transform(date, 'mediumDate');
    }

    checkPdfStatus(filePath) {
        let isPdf: boolean;
        let fileExtension = filePath.split('.').pop();

        if (fileExtension.startsWith('pdf')) {
            isPdf = true;

        } else {
            isPdf = false;
        }

        return isPdf;
    }

    isNullOrUndefined(value: any) {
        return value === 'undefined' || value === 'null' || value === undefined || typeof value === 'undefined' || value === null || value === NaN
            || (typeof value === 'object' && Object.keys(value).length === 0) || (typeof value === 'string' && value.trim().length === 0);

    }

    isArray = (val: any) => {
        if (val === null) {
            return false;
        }
        return Object.prototype.toString.call(val) === '[object Array]';
    };


    sortArray(array: any[], property: any, order: 'ASC' | 'DESC'): any[] {
        try {
            if (array === null || property === null) {
                return array;
            }
            const pos = order === 'ASC' ? 1 : -1;
            const neg = order === 'ASC' ? -1 : 1;
            return array.slice().sort((firstEl, secondEl) => {
                if (firstEl[property] < secondEl[property]) {
                    return neg;
                }
                if (firstEl[property] > secondEl[property]) {
                    return pos;
                }
                return 0;
            });
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    nullChecker(value: any) {
        return !value ? '_' : value;
    }

}
