import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ClientIdentification } from './client-identification.model';
import * as ClientIdentificationActions from './client-identification.actions';

export const clientIdentificationFeatureKey = 'clientIdentifications';


export interface State extends EntityState<ClientIdentification> {
  // additional entities state properties
}

export const adapter: EntityAdapter<ClientIdentification> = createEntityAdapter<ClientIdentification>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(ClientIdentificationActions.addClientIdentification,
    (state, action) => adapter.addOne(action.clientIdentification, state)
  ),
  on(ClientIdentificationActions.upsertClientIdentification,
    (state, action) => adapter.upsertOne(action.clientIdentification, state)
  ),
  on(ClientIdentificationActions.addClientIdentifications,
    (state, action) => adapter.addMany(action.clientIdentifications, state)
  ),
  on(ClientIdentificationActions.upsertClientIdentifications,
    (state, action) => adapter.upsertMany(action.clientIdentifications, state)
  ),
  on(ClientIdentificationActions.updateClientIdentification,
    (state, action) => adapter.updateOne(action.clientIdentification, state)
  ),
  on(ClientIdentificationActions.updateClientIdentifications,
    (state, action) => adapter.updateMany(action.clientIdentifications, state)
  ),
  on(ClientIdentificationActions.deleteClientIdentification,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(ClientIdentificationActions.deleteClientIdentifications,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(ClientIdentificationActions.loadClientIdentifications,
    (state, action) => adapter.setAll(action.clientIdentifications, state)
  ),
  on(ClientIdentificationActions.clearClientIdentifications,
    state => adapter.removeAll(state)
  ),
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
