import gql from 'graphql-tag';
import {basePageableGraphqlField} from '../../base.graphql';

export const assetsFields = `
EResourceType
active
assetCondition
assetModel
assetSerialNumber
branchUuid
createdAt
createdBy
deleted
departmentUuid
description
id
installedDate
institutionId
institutionUuid
ipAddresses
location
manufacture{
  id
  uuid
  name
}
name
operational
ownerEmail
ownerUuid
resourceCategory{
  id
  uuid
  title
}
resourceCriticality{
  id
  uuid
  title
}
resourceType{
  id
  uuid
  title
}
serviceUuid
supplier{
  id
  uuid
  name
}
updatedAt
updatedBy
url
uuid
serviceVisibility
consumerInstitutionUuid
`;

export const ALL_INSTITUTION_ASSETS_LIST = gql`
    query getAllInstitutionResources($pageparam: PageableParamInput){
        getAllInstitutionResources(pageparam:$pageparam){
            content{
                ${assetsFields}
            }
            ${basePageableGraphqlField}
        }
    }
`;

export const GET_ALL_INSTITUTION_ASSETS_LIST_BY_RESOURCE_CATEGORY = gql`
    query getAllInstitutionResourcesByResourceCategory($resourceCategoryUuid: String, $pageparam: PageableParamInput){
        getAllInstitutionResourcesByResourceCategory(resourceCategoryUuid:$resourceCategoryUuid, pageparam:$pageparam){
            content{
                ${assetsFields}
            }
            ${basePageableGraphqlField}
        }
    }
`;

export const GET_ALL_INSTITUTION_ASSETS_LIST_BY_RESOURCE_CRITICALITY = gql`
    query getAllInstitutionResourcesByResourceCriticality($resourceCriticalityUuid: String, $pageparam: PageableParamInput){
        getAllInstitutionResourcesByResourceCriticality(resourceCriticalityUuid:$resourceCriticalityUuid, pageparam:$pageparam){
            content{
                ${assetsFields}
            }
            ${basePageableGraphqlField}
        }
    }
`;


export const GET_ALL_INSTITUTION_ASSETS_LIST_BY_RESOURCE_TYPE = gql`
    query getAllInstitutionResourcesByResourceType($resourceTypeUuid: String, $pageparam: PageableParamInput){
        getAllInstitutionResourcesByResourceType(resourceTypeUuid:$resourceTypeUuid, pageparam:$pageparam){
            content{
                ${assetsFields}
            }
            ${basePageableGraphqlField}
        }
    }
`;

export const GET_ALL_INSTITUTION_ASSETS_LIST_BY_RESOURCE_MANUFACTURE = gql`
    query getAllInstitutionResourcesByManufacturer($manufacturerUuid: String, $pageparam: PageableParamInput){
        getAllInstitutionResourcesByManufacturer(manufacturerUuid:$manufacturerUuid, pageparam:$pageparam){
            content{
                ${assetsFields}
            }
            ${basePageableGraphqlField}
        }
    }
`;


export const GET_ALL_INSTITUTION_ASSETS_LIST_BY_RESOURCE_SUPPLIER = gql`
    query getAllInstitutionResourcesBySupplier($supplierUuid: String, $pageparam: PageableParamInput){
        getAllInstitutionResourcesBySupplier(supplierUuid:$supplierUuid, pageparam:$pageparam){
            content{
                ${assetsFields}
            }
            ${basePageableGraphqlField}
        }
    }
`;


export const ACTIVATE_INSTITUTION_ASSETS_LIST = gql`
    mutation activateDeactivateResource($resourceUuid: String){
        activateDeactivateResource(resourceUuid:$resourceUuid){
            code
            data{
                ${assetsFields}
            }
            message
            status
        }
    }`;


export const DELETE_INSTITUTION_ASSETS_LIST = gql`
    mutation deleteResource($resourceUuid: String){
        deleteResource(resourceUuid:$resourceUuid){
            code
            data{
                ${assetsFields}
            }
            message
            status
        }
    }
`;

export const CREATE_UPDATE_INSTITUTION_ASSETS_LIST = gql`
    mutation addUpdateNewResource($resource: ResourceDtoInput){
        addUpdateNewResource(resource:$resource){
            code
            data{
                ${assetsFields}
            }
            message
            status
        }
    }`;


export const GET_MY_BRANCH_ASSETS_BY_SERVICE_UUID = gql`
    query getAllBranchResourcesByService($serviceUuid: String){
        getAllBranchResourcesByService(serviceUuid:$serviceUuid){
            id
            uuid
            name
        }
    }`;


export const GET_ALL_ASSETS_BY_SERVICE_UUID = gql`
    query getAllInternalAndExternalResources($serviceUuid: String,$pageparam: PageableParamInput){
        getAllInternalAndExternalResources(serviceUuid:$serviceUuid,pageParam:$pageparam){
            content{
                id
                uuid
                name
            }
            ${basePageableGraphqlField}
        }
    }
`;

export const GET_ALL_ASSETS_BY_SERVICE_UUID_AND_INSTITUTION_UUID = gql`
    query getAllInternalAndExternalResourcesByConsumer($serviceUuid: String, $consumerInstitutionUuid: String, $pageparam: PageableParamInput){
        getAllInternalAndExternalResourcesByConsumer(serviceUuid:$serviceUuid, consumerInstitutionUuid: $consumerInstitutionUuid, pageParam:$pageparam){
            content{
                id
                uuid
                name
            }
            ${basePageableGraphqlField}
        }
    }
`;

export const GET_ASSETS_REGISTER_REPORT = gql`
    query getAssetRegisterReport($fromDate: LocalDate,$categoryUuid: String,$exportDocType: ExportDocType,$toDate: LocalDate){
        getAssetRegisterReport(fromDate: $fromDate,categoryUuid: $categoryUuid,exportDocType: $exportDocType,toDate: $toDate,){
            code
            status
            message
            data
        }
    }
`;


export const UPLOAD_ASSETS = gql`
    mutation uploadNewResources($input: ResourceUploaderDtoInput){
        uploadNewResources(resource: $input){
            code
            message
        }
    }
`;
export const GET_ASSET_BY_UID = gql`
    query getResourceByUuid($resourceUuid: String){
        getResourceByUuid(resourceUuid: $resourceUuid){
            code
            data{
                ${assetsFields}
            }
        }
    }
`;
export const GET_ASSET_STATISTICS_DASHBOARD = gql`
    query getResourceStatistics{
        getResourceStatistics{
            code
            data{
                totalAssetCategory
                totalAssetCriticality
                totalAssetManufacturer
                totalAssetType
                totalAssetSupplier
                totalAssets
                totalOnlineAssets
                totalPhysicalAssets
            }
        }
    }
`;


