<mat-progress-bar *ngIf="isLoading"
  mode="buffer"></mat-progress-bar>
<p *ngIf="isLoading">{{loadingMessage}}</p>
<!-- <h6 *ngIf="!isLoading"
  style="color: red;">* Required</h6> -->
<form class="dynamic-form"
@fadeIn
*ngIf="form"
  [formGroup]="form"
  (submit)="onSubmit($event)"
  autocomplete="off">
  <div class="row"
    [@listAnimation]="(sortedFields || []).length">
    <ng-container *ngFor="let field of sortedFields || []">
      <div [class]="field.rowClass || 'col6'"
        [ngClass]="{'spaced': spaced}"
        *ngIf="isVisible(field)">
        <ng-container appDynamicField
          [field]="field"
          [group]="form"
          [optionData]="(field.options ? (field.options | optionsObservable | async) : {loading: false, value: []}) || undefined"
          (cancelForm)="cancelForm()"
          (fieldValue)="fieldValue($event)"></ng-container>
      </div>
    </ng-container>
  </div>
</form>