import gql from "graphql-tag";
import { institutionFields } from "../institution/institution.graphql";

export const incidentTypeFields = `
  active
  description
  id
  institutionName
  institutionUuid
  title
  uuid
`;

export const CREATE_INCIDENT_TYPE = gql`
  mutation createdUpdateIncidenceCategory( $incidentCategory: IncidentCategoryDtoInput){
    createdUpdateIncidenceCategory( incidentCategory: $incidentCategory ){
      code
      data{
        ${incidentTypeFields}
      }
      message
    }
  }
`;

export const LIST_ALL_INCIDENT_TYPES = gql`
  query getAllIncidentCategories( $pageParam: PageableParamInput){
    getAllIncidentCategories( pageParam: $pageParam ){
      content{
        ${incidentTypeFields}
      }
      size
      first
      totalPages
      size
      totalElements
      numberOfElements
    }
  }
`;

export const GET_INCIDENT_TYPE_BY_UUID = gql`
  query getIncidentCategoryByUuid( $uuid: String ){
    getIncidentCategoryByUuid( uuid: $uuid ){
      code
      data{
        ${incidentTypeFields}
      }
      message
    }
  }
`;

export const DELETE_INCIDENT_TYPE = gql`
  mutation deleteIncidentCategoryByUuid( $uuid: String ){
    deleteIncidentCategoryByUuid( uuid: $uuid ){
      code
      data{
        ${incidentTypeFields}
      }
      message
    }
  }
`;

export const LIST_INCIDENT_TYPES_BY_INSTITUTION_UUID = gql`
  query getIncidentCategoryByInstitutionUuid( $institutionUid: String){
    getIncidentCategoryByInstitutionUuid( institutionUuid: $institutionUid ){
      dataList{
        ${incidentTypeFields}
      }

    }
  }
`;

export const LIST_INCIDENT_TYPES_TITLE_BY_INSTITUTION_UUID = gql`
  query getIncidentCategoryByInstitutionUuid($institutionUid: String) {
    getIncidentCategoryByInstitutionUuid(institutionUuid: $institutionUid) {
      dataList {
        title
        uuid
      }
    }
  }
`;
