import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ServiceUserType } from '../interfaces/global.interface';
import { StorageService } from '../services/storage.service';

@Injectable({
  providedIn: 'root'
})

export class ServiceProviderGuard implements CanActivate {
  
  constructor(
    private storageService: StorageService,
    private router: Router
  ){}


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (this.storageService.getItem('serviceUserType')){
        if ((this.storageService.getItem('serviceUserType')) === ServiceUserType.serviceProvider) {
          return true;
        } else{
          this.router.navigateByUrl('/individual');
        }
      }
  }
  
}
