import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {map, shareReplay} from 'rxjs/operators';
import {AuthUser} from '../../../store/entities/auth-user/auth-user.model';
import {IncidentNotificationType, Menu} from '../../../interfaces/global.interface';
import {AuthService} from '../../../services/auth.service';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../store/reducers';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {selectModifiedAuthUsers} from '../../../store/entities/auth-user/auth-user.selectors';
import {ChangePasswordComponent} from '../change-password/change-password.component';
import {StorageService} from '../../../services/storage.service';
import { clearUsers } from 'src/app/store/entities/settings/user/user.actions';
import { IncidentNotification } from 'src/app/store/entities/notification/notification.model';
import { selectNotificationByType } from 'src/app/store/entities/notification/notification.selectors';
import { ProfileComponent } from '../profile/profile.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
  user$: Observable<AuthUser>;
  token: string;
  gispUrl: string;
  @Input() menu: Menu;
  sideMenu: Menu;
  incommingTickets$: Observable<IncidentNotification[]>;
  institutionName: any;
  
  constructor(
    private breakpointObserver: BreakpointObserver,
    private authService: AuthService,
    private store: Store<AppState>,
    private dialog: MatDialog,
    private storageService: StorageService  ) {
    this.token = this.storageService.getItem('currentClient');
    this.institutionName = this.storageService.getItem('institutionName');

  }


  ngOnInit() {
    this.sideMenu = this.menu;
    this.user$ = this.store.pipe( select(selectModifiedAuthUsers), map(users => users[0]) );
    if(!this.token){
      this.store.dispatch(clearUsers());
      this.user$ = null;
    } 
    // this.incommingTickets$ = this.store.pipe(select(selectNotificationByType({id: IncidentNotificationType.external })));
  // this.incommingTickets$.subscribe(dt=>{
  //   console.log("JOKER==>",dt);
    
  // })
     
  }

  openChangePasswordDialog(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = '50%';
    dialogConfig.panelClass = 'incident-details';
    this.dialog.open(ChangePasswordComponent, dialogConfig);
  }

  profile(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = '80%';
    dialogConfig.panelClass = 'incident-details';
    this.dialog.open(ProfileComponent, dialogConfig);
  }
  
  logout() {
    this.authService.logout();
  }

  cleanStorage() {
    this.storageService.clearStorage();
  }
  


}
