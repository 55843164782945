import {createSelector} from '@ngrx/store';
import {AppState} from './../../reducers/index';
import * as fromIncidentReducer from './incident.reducer';
import {Incident, IncidentPageable} from './incident.model';
import {DatePipe} from '@angular/common';
import * as fromHelpers from 'src/app/shared/helpers/date-ago.helper';

const today = new Date();

export const currentState = (state: AppState) =>
    state[fromIncidentReducer.incidentsFeatureKey];

export const selectAllIncidents = createSelector(
    currentState,
    fromIncidentReducer.selectAll
);

export const selectTotalIncidents = createSelector(
    currentState,
    fromIncidentReducer.selectTotal
);
export const selectIncidentPageable = createSelector(
    currentState,
    fromIncidentReducer.getIncidentPageable
);

export const selectModifiedIncidents = createSelector(
    selectAllIncidents,
    (incidents: Incident[]) => {
        return incidents.map((incident) => {
            const dateSubmitted = new Date(incident?.dateSubmitted);
            return {
                ...incident,
                fromInstitutionName: incident?.institutionFrom?.name,
                incidentCategory: incident?.category?.title,
                age: incident?.dateSubmitted ? fromHelpers.getTikeckAge(incident) : 'N/A',
                createdAtFormatted: new DatePipe('en-GB').transform(incident.createdAt, 'dd MMM yyyy') +
                    ' at ' +
                    new DatePipe('en-GB').transform(incident.createdAt, 'HH:mm'),
                modifiedStatus: removeUnderscore(incident.incidentStatus),
                secondLevelDepartmentName: incident?.secondLevelDepartment?.departmentName,
                reportingUser: incident?.reportedBy?.fullName ?? '',
                overDue: fromHelpers.checkOverdue(incident),
                currentAssigned: incident?.assignments?.filter(e => e.currentAssigned),
                previousAssigned: incident?.assignments?.filter(e => !e.currentAssigned),
                clientInternalCode: incident?.clientInternalCode ? incident?.clientInternalCode : '',
                isReAssigned: fromHelpers.checkReassignement(incident?.assignments),
                currentTotalOnHoldDays: fromHelpers.getTotalOnHoldDays(incident?.incidentResponses),
                secondLevelAddedResponse: fromHelpers.check2ndLevelResponse(incident?.incidentResponses),
            };
        });
    }
);
// !currentAssigned && firstLevelAssignment  == first level re-assigned
// !currentAssigned && !firstLevelAssignment == 2nd level re-assigned


export const selectIncidentsByUuid = (params: { uuid: string }) => createSelector(
    selectModifiedIncidents,
    (incidents: Incident[]) => {
        return incidents.find((incident) => incident.uuid === params.uuid);
    }
);

export const selectIncidentsNonParent = (params: { parentUuid: string }) => createSelector(
    selectModifiedIncidents,
    (incidents: Incident[]) => {
        return incidents.filter((incident) => incident.uuid !== params.parentUuid && incident?.priority);
    }
);

export const selectIncidentPage = createSelector(
    selectIncidentPageable, (pageable: IncidentPageable) => {
        return pageable;
    }
);

export const selectIncidentsById = (params: { id: number }) =>
    createSelector(selectModifiedIncidents, (incidents: Incident[]) => {
        return incidents.find((incident) => incident.id === params.id);
    });

export const selectIncidentsByUuId = (params: { uuid: string }) =>
    createSelector(selectModifiedIncidents, (incidents: Incident[]) => {
        return incidents.find((incident) => incident?.uuid === params?.uuid);
    });

export const selectIncidentsByIncidentStatus = (params: { incidentStatus: string }) =>
    createSelector(selectModifiedIncidents, (incidents: Incident[]) => {
        return incidents.filter((incident) => incident?.incidentStatus === 'SUMBMITTED');

    });
export const selectIncidentsByIncidentOrigin = (params: { incidentOrigin: 'INTERNAL' | 'EXTERNAL' }) =>
    createSelector(selectModifiedIncidents, (incidents: Incident[]) => {
        return incidents.filter((incident) => incident?.incidentOrigin === params?.incidentOrigin);

    });

export const selectInternalIncidentsByStatusAndInstitution = (params: {
    status: string;
    institutionId: number;
    category: string;
}) => createSelector(selectModifiedIncidents, (incidents: Incident[]) => {
    return incidents.filter(
        (incident) =>
            incident.state === params.status &&
            incident.institution?.id === params.institutionId &&
            incident.category === params.category
    );
});

export const selectInternalIncidentsByStateAndInstitution = (params: {
    state: string;
    institutionId: number;
    category: string;
}) => createSelector(selectModifiedIncidents, (incidents: Incident[]) => {
    return incidents.filter(
        (incident) =>
            incident.state === params.state &&
            incident.institution?.id === params.institutionId &&
            incident.category === params.category
    );
});

export const selectUnassignedIncidentsToDepartment = createSelector(
    selectModifiedIncidents,
    (incidents: Incident[]) => {
        return incidents.filter((incident) => incident?.department === null);
    }
);

export const selectAssignedIncidentsToDepartment = createSelector(
    selectModifiedIncidents,
    (incidents: Incident[]) => {
        return incidents.filter((incident) => incident?.department != null);
    }
);

export const selectAssignedIncidentsToUser = createSelector(
    selectModifiedIncidents,
    (incidents: Incident[]) => {
        return incidents.filter((incident) => incident?.status === 'InProgress');
    }
);

export const selectUnassignedIncidentsToUser = createSelector(
    selectModifiedIncidents,
    (incidents: Incident[]) => incidents.filter((incident) => incident?.status === 'Assigned')
);

function removeUnderscore(value) {
    if (value) {
        const str = value?.toString()?.replace(/_/g, ' ');
        return str;
    } else {
        return value;
    }
}

