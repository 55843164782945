import {createUpdateMyInstitutionUser, registerUsersInBatch} from './../../../../store/entities/settings/user/user.actions';
import {listAllRolesPageable} from './../../../../store/entities/settings/role/role.actions';
import {selectModifiedDesignations} from './../../../../store/entities/settings/designation/designation.selectors';
import {getInstitutionDesignations} from './../../../../store/entities/settings/designation/designation.actions';
import {Designation} from './../../../../store/entities/settings/designation/designation.model';
import {Observable, of, Subscription} from 'rxjs';
import {User} from './../../../../store/entities/settings/user/user.model';
import {AppState} from './../../../../store/reducers/index';
import {Component, Inject, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder,FormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {clearDepartments, listAllDepartments,} from 'src/app/store/entities/settings/department/department.actions';
import {selectModifiedDepartments} from 'src/app/store/entities/settings/department/department.selectors';
import {Department} from 'src/app/store/entities/settings/department/department.model';
import {specialInitializedPageableParameter} from 'src/app/interfaces/global.interface';
import {selectModifiedBranchs} from 'src/app/store/entities/settings/branch/branch.selectors';
import {clearBranchs, listMyInstitutionAllBranchs} from 'src/app/store/entities/settings/branch/branch.actions';
import {Branch} from 'src/app/store/entities/settings/branch/branch.model';
import {GetCountryService} from 'src/app/services/get-country.service';
import * as XLSX from 'xlsx';
import {NotificationService} from 'src/app/services/notification.service';
import {phoneRegex} from '../../../../shared/utils/regex.util';
import {listAllServicesFewFields} from 'src/app/store/entities/settings/service/service.actions';
import {selectAllServices} from 'src/app/store/entities/settings/service/service.selectors';
import {Service} from 'src/app/store/entities/settings/service/service.model';
import { clearTascoIsceGroups, getAllTascoIsceGroups } from 'src/app/store/entities/tasco-isce-group/tasco-isce-group.actions';
import { TascoIsceGroup } from 'src/app/store/entities/tasco-isce-group/tasco-isce-group.model';
import { selectModifiedTascoIsceGroups,selectUnitGroups } from 'src/app/store/entities/tasco-isce-group/tasco-isce-group.selectors';
import { map, startWith } from 'rxjs/operators';

@Component({
    selector: 'app-user-form',
    templateUrl: './user-form.component.html',
    styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit {

    title: string;
    userAccountForm: UntypedFormGroup;
    user: User;
    designations$: Observable<Designation[]>;
    department$: Observable<Department[]>;
    services$: Observable<Service[]>;
    branch$: Observable<Branch[]>;
    searchFields = [];
    selectedValue: string;

    selectedCountry: any = 'Tanzania';
    selectedPhoneNumber: any;
    countryLists: any[];
    subscription = new Subscription();
    countryCode: any;
    numberChars = new RegExp('[^0-9]', 'g');
    hasPatternError: boolean = false;
    dialogType: string;
    importForm: UntypedFormGroup;

    file: File;
    fileSize: string;
    fileName: string;
    isExcelFile: any;
    arrayBuffer: any;
    uploadDto: any;
    @Output() UserFromOtherSystemDto = [];
    keys: string[];
    usersToImport$: Observable<any[]> = of([]);
    tableColumns: any;
    failedAccounts: any = [];
    reportingScopes: { id: number; value: string; name: string; }[];
    types = ['Client', 'Employee'];
    currentTabIndex: number;
    tascoGroups$: Observable<TascoIsceGroup[]>;
    myControl = new FormControl('');
    questionCategory:string;


    constructor(
        private notificationService: NotificationService,
        private fb: UntypedFormBuilder,
        private store: Store<AppState>,
        private dialogRef: MatDialogRef<UserFormComponent>,
        @Inject(MAT_DIALOG_DATA) data,
        private getCountryService: GetCountryService,
    ) {

        this.currentTabIndex = data?.currentTabIndex;

        if (data.type === 'importUserReview') {
            this.failedAccounts = data.payload;
            this.dialogType = data.type;
            this.title = 'The following failed to register, confirm and try again';
        } else if (data.type === 'editUser') {
            this.user = data.payload;
            this.dialogType = data.type;
            this.title = 'Edit User';
        } else if (data.type === 'importUser') {
            this.dialogType = data.type;
            this.title = 'Import Users';
        } else {
            // this.user = data.payload;
            this.dialogType = data.type;
            this.title = 'Add User';
        }

        this.store.dispatch(getInstitutionDesignations());
        this.store.dispatch(listAllRolesPageable({pageableParam: specialInitializedPageableParameter}));
        this.store.dispatch(clearDepartments());
        this.store.dispatch(listAllDepartments());
        this.store.dispatch(clearBranchs());
        this.store.dispatch(listMyInstitutionAllBranchs());
        this.store.dispatch(clearTascoIsceGroups());
        this.store.dispatch(getAllTascoIsceGroups())
    }
    options: {name: string, id: string}[];
    filteredOptions: Observable<TascoIsceGroup[]>;

    ngOnInit(): void {
        // this.title = this.user ? 'Edit User' : 'Add User';
        this.fetchCountryList();
        this.userAccountForm = this.fb.group({
            firstName: [null, Validators.required],
            middleName: [null],
            lastName: [null, Validators.required],
            email: [null, [Validators.required, Validators.email]],
            designationId: [null, (this.currentTabIndex === 0) ? Validators.required : null],
            departmentId: [null],
            branchUuid: [null],
            // reportingCapability: [null, Validators.required],
            phone: [null, [Validators.required, Validators.maxLength(9), Validators.pattern(phoneRegex)]],
            checkNumber: [null],
            countryCode: [null],
            occupationId: [null]
        });

        this.department$ = this.store.pipe(select(selectModifiedDepartments));
        this.designations$ = this.store.pipe(select(selectModifiedDesignations));
        this.branch$ = this.store.pipe(select(selectModifiedBranchs));
        this.tascoGroups$ = this.store.pipe(select(selectUnitGroups));
        // this.tascoGroups$.subscribe(groups => {
        //     this.options = groups.map(group => ({name: group.name, id: group.uuid.toString()}));
        //   });
        if (this.dialogType === 'editUser') {
            if (this.user) {
                this.userAccountForm.patchValue(this.user);
                const phone = this.user?.phone.toString();
                this.userAccountForm.get('phone').setValue(phone.includes('+') ? phone.substring(4) : phone.substring(3));
                this.userAccountForm.get('designationId').setValue(this.user?.designation?.id);
                this.userAccountForm.get('departmentId').setValue(this.user?.department?.id);
                this.userAccountForm.get('branchUuid').setValue(this.user?.branch?.uuid);
            }
        } else {
            this.importForm = this.fb.group({
                file: [null, Validators.required],
                serviceUuids: [null],
                batchNumber: [null],
                searchValue: [null],
                type: [null, Validators.required],
            });
        }
        this.tableColumns = {
            no: 'SN',
            email: 'EMAIL',
            firstName: 'FIRST NAME',
            middleName: 'MIDDLE NAME',
            lastName: 'LAST NAME',
            checkNumberOrId: 'CHECK_NUMBER/ID',
            phone: 'PHONE',
            designationCode: 'DESIGNATION CODE',
            departmentTrackingCode: 'DEPARTMENT TRACKING CODE',
            branchTrackingCode: 'DEPARTMENT TRACKING CODE',
        };
        this.reportingScopes = [
            {
                id: 1,
                value: 'All',
                name: 'ALL',
            },
            {
                id: 2,
                value: 'Internal',
                name: 'INTERNAL',
            },
            {
                id: 3,
                value: 'PublicInstitution',
                name: 'PUBLIC INSTITUTION',
            },
        ];


        this.filteredOptions = this.myControl.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value || '')),
          );
          
          
          this.myControl.valueChanges.subscribe((value: any) => {
            this.userAccountForm.get('occupationId').setValue(value.id);
          });
    }

    private _filter(value: string): any {
        const filterValue = value;
      
        return this.options.filter(option => option.name.toLowerCase().includes(filterValue));
      }
      
      displayFn(group: any): string {
        return group && group.name ? group.name : '';
      }
      
    onFileSelected(event) {
        let data;
        this.isExcelFile = !!event.target.files[0].name.match(/(.xls|.xlsx|.csv)/);
        if (this.isExcelFile) {

            this.file = (event.target.files[0] as File);
            this.fileName = this.file.name;
            this.fileSize = parseFloat(((this.file.size) / 1000000).toFixed(5)) + 'MB';
            const reader = new FileReader();
            reader.onload = (e: any) => {
                const bstr: string = e.target.result;
                const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});
                const wsname: string = wb.SheetNames[0];
                const ws: XLSX.WorkSheet = wb.Sheets[wsname];
                data = XLSX.utils.sheet_to_json(ws);
            };
            reader.readAsBinaryString(this.file);
            reader.onloadend = (e) => {
                this.importForm.patchValue({
                    file: this.file
                });
                this.keys = Object.keys(data[0]);
                for (let i = 0; i < data.length; ++i) {
                    this.UserFromOtherSystemDto.push({
                        email: data[i].EMAIL.trim(),
                        firstName: data[i].FIRST_NAME,
                        middleName: data[i].MIDDLE_NAME,
                        lastName: data[i].LAST_NAME,
                        checkNumberOrId: data[i].CHECK_NUMBER_OR_ID.toString(),
                        phone: data[i].PHONE.toString(),
                        designationCode: data[i].DESIGNATION_CODE,
                        departmentTrackingCode: data[i].DEPARTMENT_TRACKING_CODE,
                        branchTrackingCode: data[i].BRANCH_TRACKING_CODE,
                    });
                }
            };
        } else {
            this.importForm.reset();
            this.notificationService.warningSnackbar('Only Excel or CSV accepted!');
            this.dialogRef.close();
        }
    }

    uploadUserFile() {
        const payload = {usersDetails: this.UserFromOtherSystemDto, ...this.importForm.value};
        delete payload?.file;
        delete payload?.searchValue;
        this.store.dispatch(registerUsersInBatch({usersFromOtherSystem: payload}));
        this.dialogRef.close();
    }


    resetPhoneNumber(event: any): void {
        this.countryCode = event.value;
    }

    formatPhoneNumber(event: any): void {
        let inputValue: any = this.userAccountForm.get('phone').value;
        //
        let phoneNumber: any;
        // let phoneNumber: any = parsePhoneNumberFromString(inputValue, this.selectedCountry);

        if (phoneNumber) {
            this.selectedPhoneNumber = phoneNumber.number;
            this.userAccountForm.get('phone').setValue(phoneNumber.formatInternational());
        }
    }

    submitForm(formValues): void {

        const newUser: any = {
            firstName: formValues.firstName,
            middleName: formValues.middleName,
            lastName: formValues.lastName,
            email: formValues.email,
            phone: formValues.countryCode.toString() + formValues.phone.toString(),
            departmentId: formValues.departmentId,
            designationId: formValues.designationId,
            // branchUuid: formValues.branchUuid,
            checkNumber: formValues.checkNumber,
            occupationId: formValues.occupationId
            // reportingCapability:formValues.reportingCapability,
        };
        if (this.user) {
            // edit user
            const edituser: any = {
                ...newUser,
                uuid: this.user.uuid,
            };

            // newUser.uuid = this.user.uuid;
            this.store.dispatch(createUpdateMyInstitutionUser({userDto: edituser}));
        } else {
            // add user
            this.store.dispatch(createUpdateMyInstitutionUser({userDto: newUser}));
        }
        this.closeModal(true);
    }

    closeModal(close: boolean): void {
        if (close) {
            this.dialogRef.close();
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    selectedUserType(type) {
        if (type == 'Client') {
            this.store.dispatch(listAllServicesFewFields({pageableParam: specialInitializedPageableParameter}));
            this.services$ = this.store.pipe(select(selectAllServices));
            this.importForm?.controls['serviceUuids'].setValidators([Validators.required]);
            this.importForm?.controls['batchNumber'].setValidators([Validators.required]);

        } else {
            this.importForm?.controls['serviceUuids'].clearAsyncValidators();
            this.importForm?.controls['batchNumber'].clearAsyncValidators();
        }

        this.importForm?.controls['serviceUuids'].updateValueAndValidity();
        this.importForm?.controls['batchNumber'].updateValueAndValidity();
    }

    private fetchCountryList(): void {
        this.subscription.add(
            this.getCountryService.getCountries().subscribe((res: any) => {
                this.countryLists = res.countries;
                if (this.countryLists.find(c => {
                    if (c.name === this.selectedCountry) {
                        this.countryCode = c.code;
                        this.userAccountForm.get('countryCode').setValue(c.code);
                    }
                })) {
                }
            }, error => error)
        );
    }

}
