import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import { switchMap, map } from 'rxjs/operators';
import { initializedPageableParameter } from 'src/app/interfaces/global.interface';
import { NotificationService } from 'src/app/services/notification.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { ResponseCodeService } from 'src/app/services/response-code.service';
import { AppState } from 'src/app/store/reducers';
import * as fromActions from './approval-linkage.actions';
import * as fromGraphql from './approval-linkage.graphql';



@Injectable()
export class ApprovalLinkageEffects {

  listInstitutionApprovalLinkages$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.listInstitutionApprovalLinkages),
    switchMap((action) => {
      return this.apollo.query({
        query: fromGraphql.LIST_ALL_APPROVAL_STAGEL_INKAGES_BY_APPROVAL_STAGE,
        variables: {
            stageId:  action.approvalStageID,
            pageParam: action.pageableParam?.size ? action.pageableParam : initializedPageableParameter,
         },
        fetchPolicy: 'network-only',
      }).pipe(
        this.notificationService.catchError('Problem occurred while fetching Linkages'),
        map(({ data }: any) => {
          if (data) {
              const result = data?.getAllChangeRequestApprovalLinkageByApprovalStage;
              if (result?.content?.length > 0) {
                this.paginationService.setPage(result);
              }
              this.store.dispatch(fromActions.loadApprovalLinkages({ approvalLinkages: result?.content }));
          //   }
          // }));
          }
        })
      );
    })
  ), {dispatch: false});




  listAllAvailableApprovalLinkageBYWorkflowId$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.listAllAvailableApprovalLinkageBYWorkflowId),
    switchMap((action) => {
      return this.apollo.query({
        query: fromGraphql.LIST_ALL_APPROVAL_STAGEL_INKAGES_BY_WORKFLOW_UUID,
        variables: {
          severityId:  action.workflowId,
          pageParam: action.pageableParam?.size ? action.pageableParam : initializedPageableParameter,
         },
        fetchPolicy: 'network-only',
      }).pipe(
        this.notificationService.catchError('Problem occurred while fetching Approval Linkages'),
        map(({ data }: any) => {
          if (data) {
              const flowResult = data?.getAllChangeRequestApprovalLinkageBySeverity;
              if (flowResult?.content.length > 0) {
                this.paginationService.setPage(flowResult);
              }
              this.store.dispatch(fromActions.loadApprovalLinkages({ approvalLinkages: flowResult?.content }));
            
          }
        })
      );
    })
  ), {dispatch: false});



  listAllAvailableApprovalLinkageByUuid$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.listAllAvailableApprovalLinkageByUuid),
    switchMap((action) => {
      return this.apollo.query({
        query: fromGraphql.GET_APPROVAL_STAGEL_INKAGE_BY_UUID,
        variables: {
          uuid:  action.uuid,
          pageParam: action.pageableParam?.size ? action.pageableParam : initializedPageableParameter,
         },
        fetchPolicy: 'network-only',
      }).pipe(
        this.notificationService.catchError('Problem occurred while fetching Approval Linkages'),
        map(({ data }: any) => {
          if (data) {
            let result: any = Object.values(data)[0];
             
            
            this.store.dispatch(fromActions.loadApprovalLinkages({ approvalLinkages: result?.content }));
          }
        })
      );
    })
  ), {dispatch: false});


  // Create/Save Request Severity
 createApprovalLinkage$ = createEffect(() => this.actions$.pipe(
  ofType(fromActions.addApprovalLinkage),
  switchMap((action) => {
    return this.apollo.mutate({
      mutation: fromGraphql.CREATE_APPROVAL_STAGEL_INKAGE,
        variables: {
          linkageDto: action.approvalLinkage
        }
    }).pipe(
      this.notificationService.catchError('Problem occurred while saving Aproval Linkage'),
      map(({ data }: any) => {
        if (data) {
          this.handleResponse(data.createUpdateLinkage)
        }
      })
    );
  })
), {dispatch: false});


  // Detele Branch
  removeRequestAprovalLinkage$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.removeApprovalLinkage),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: fromGraphql.DELETE_APPROVAL_STAGEL_INKAGE,
        variables: {
          uuid: action.uuid,
          id: action.id
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while deleting department'),
        map(({ data }: any) => {
          if (data) {
            if (data.deleteApprovalLinkage.code === 9000) {
              this.store.dispatch(fromActions.deleteApprovalLinkage({ id: action.id}));

              this.notificationService.successMessage('Deleted Successfully');
            } else {
              this.notificationService.errorMessage(data.deleteApprovalLinkage);
            }
          }
        })
      );
    })
  ), {dispatch: false});

  
  constructor(
    private actions$: Actions,
    private apollo: Apollo,
    private store: Store<AppState>,
    private responseCodeService: ResponseCodeService,
    private notificationService: NotificationService,
    private paginationService: PaginationService,

  ) {

  }

  handleResponse(data) {
    if (data.code === 9000) {
      this.store.dispatch(fromActions.upsertApprovalLinkage({ approvalLinkage: data.data }));
      return this.notificationService.successMessage('Approval Linkage Saved Successful');
    } else {
      return this.handleError(data);
    }
  }

  handleError(data) {
    const responseCode = this.responseCodeService.getCodeDescription(data.code);
    const message = responseCode[0].code + ' : ' + responseCode[0].description;
    return this.notificationService.errorMessage(message);
  }

}
