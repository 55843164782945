import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Assetc } from './assetc.model';
import * as AssetcActions from './assetc.actions';

export const AssetcsFeatureKey = 'Assetcs';

export interface State extends EntityState<Assetc> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Assetc> = createEntityAdapter<Assetc>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(AssetcActions.addAssetc,
    (state, action) => adapter.addOne(action.assetc, state)
  ),
  on(AssetcActions.upsertAssetc,
    (state, action) => adapter.upsertOne(action.assetc, state)
  ),
  on(AssetcActions.addAssetcs,
    (state, action) => adapter.addMany(action.assetcs, state)
  ),
  on(AssetcActions.upsertAssetcs,
    (state, action) => adapter.upsertMany(action.assetcs, state)
  ),
  on(AssetcActions.updateAssetc,
    (state, action) => adapter.updateOne(action.assetc, state)
  ),
  on(AssetcActions.updateAssetcs,
    (state, action) => adapter.updateMany(action.assetcs, state)
  ),
  on(AssetcActions.deleteAssetc,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(AssetcActions.deleteAssetcs,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(AssetcActions.loadAssetcs,
    (state, action) => adapter.setAll(action.assetcs, state)
  ),
  on(AssetcActions.clearAssetcs,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
