import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Incident, MyIncident, IncidentPageable } from './incident.model';
import * as IncidentActions from './incident.actions';

export const incidentsFeatureKey = 'incidents';

export interface State extends EntityState<Incident> {
  // additional entities state properties
  myIncidents: MyIncident[];
  pageables: IncidentPageable[];
  pageable: IncidentPageable;
}

export const adapter: EntityAdapter<Incident> = createEntityAdapter<Incident>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  myIncidents: [],
  pageables: [],
  pageable: null
});


export const reducer = createReducer(
  initialState,
  on(IncidentActions.loadPageable,
    (state, action) => ({ ...state, pageable: action.pageable })
  ),
  on(IncidentActions.addIncident,
    (state, action) => adapter.addOne(action.incident, state)
  ),
  on(IncidentActions.upsertIncident,
    (state, action) => adapter.upsertOne(action.incident, state)
  ),
  on(IncidentActions.addIncidents,
    (state, action) => adapter.addMany(action.incidents, state)
  ),
  on(IncidentActions.upsertIncidents,
    (state, action) => adapter.upsertMany(action.incidents, state)
  ),
  on(IncidentActions.updateIncident,
    (state, action) => adapter.updateOne(action.incident, state)
  ),
  on(IncidentActions.updateIncidents,
    (state, action) => adapter.updateMany(action.incidents, state)
  ),
  on(IncidentActions.deleteIncident,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(IncidentActions.deleteIncidents,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(IncidentActions.loadIncidents,
    (state, action) => adapter.setAll(action.incidents, state)
  ),
  on(IncidentActions.clearIncidents,
    state => adapter.removeAll(state)
  ),
);

export const getMyIncidents = (state: State) => state.myIncidents;
export const getIncidentPageable = (state: State) => state.pageable;

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
