import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { IncidentResolution } from './incident-resolution.model';
import { PageableParam } from 'src/app/interfaces/global.interface';

export const loadIncidentResolutions = createAction(
  '[IncidentResolution/API] Load IncidentResolutions', 
  props<{ incidentResolutions: IncidentResolution[] }>()
);

export const addIncidentResolution = createAction(
  '[IncidentResolution/API] Add IncidentResolution',
  props<{ incidentResolution: IncidentResolution }>()
);

export const upsertIncidentResolution = createAction(
  '[IncidentResolution/API] Upsert IncidentResolution',
  props<{ incidentResolution: IncidentResolution }>()
);

export const addIncidentResolutions = createAction(
  '[IncidentResolution/API] Add IncidentResolutions',
  props<{ incidentResolutions: IncidentResolution[] }>()
);

export const upsertIncidentResolutions = createAction(
  '[IncidentResolution/API] Upsert IncidentResolutions',
  props<{ incidentResolutions: IncidentResolution[] }>()
);

export const updateIncidentResolution = createAction(
  '[IncidentResolution/API] Update IncidentResolution',
  props<{ incidentResolution: Update<IncidentResolution> }>()
);

export const updateIncidentResolutions = createAction(
  '[IncidentResolution/API] Update IncidentResolutions',
  props<{ incidentResolutions: Update<IncidentResolution>[] }>()
);

export const deleteIncidentResolution = createAction(
  '[IncidentResolution/API] Delete IncidentResolution',
  props<{ id: number }>()
);

export const deleteIncidentResolutions = createAction(
  '[IncidentResolution/API] Delete IncidentResolutions',
  props<{ ids: string[] }>()
);

export const clearIncidentResolutions = createAction(
  '[IncidentResolution/API] Clear IncidentResolutions'
);

export const removeIncidentResolution = createAction(
  '[IncidentResolution/API] Remove IncidentResolution',
  props<{ incidentResolution: IncidentResolution }>()
);

export const getIncidentResolutions = createAction(
  '[IncidentResolution/API] get IncidentResolution',
  props<{  pageparam: PageableParam }>()
);
