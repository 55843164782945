import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { map, shareReplay } from 'rxjs/operators';
import { IncidentNotificationType, Menu } from 'src/app/interfaces/global.interface';
import { AuthService } from 'src/app/services/auth.service';
import { StorageService } from 'src/app/services/storage.service';
import { AuthUser } from 'src/app/store/entities/auth-user/auth-user.model';
import { selectModifiedAuthUsers } from 'src/app/store/entities/auth-user/auth-user.selectors';
import { AppState } from 'src/app/store/reducers';
import { ChangePasswordComponent } from '../../change-password/change-password.component';
import { Observable } from 'rxjs';
import { selectAllIncidentNotifications, selectNotificationByType } from 'src/app/store/entities/notification/notification.selectors';
import { IncidentNotification } from 'src/app/store/entities/notification/notification.model';
import { ProfileComponent } from '../../profile/profile.component';

@Component({
  selector: 'app-client-layout',
  templateUrl: './client-layout.component.html',
  styleUrls: ['./client-layout.component.scss']
})
export class ClientLayoutComponent implements OnInit {

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
  );
  user$: Observable<AuthUser>;
  @Input() menu: Menu;
  sideMenu: Menu;
  at: string;
  gispUrl: string;
  institutionName: any;  
  outgoingTickets$: Observable<IncidentNotification[]>;
  
  constructor(
    private breakpointObserver: BreakpointObserver,
    private authService: AuthService,
    private router: Router,
    private store: Store<AppState>,
    private dialog: MatDialog,
    private storage: StorageService,
  ) {
    this.user$ = this.store.pipe( select(selectModifiedAuthUsers), map(users => users[0]) );
    this.at = this.storage.getItem('currentClient');
    this.institutionName = this.storage.getItem('institutionName');
  }

  ngOnInit() {
    this.sideMenu = this.menu;
    if(!this.at) return;
    this.outgoingTickets$ = this.store.pipe(select(selectNotificationByType({id: IncidentNotificationType.client })));
   
  }

  openChangePasswordDialog(){
    const dialogConfig = new MatDialogConfig();
    // dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '50%';
    // dialogConfig.height = '95vh';
    // dialogConfig.position = { top: '0', right: '0' };
    dialogConfig.panelClass = 'incident-details';
    this.dialog.open(ChangePasswordComponent, dialogConfig);
  }

  logout() {
    this.authService.logout();
    // this.router.navigateByUrl('/');
  }

  cleanStorage() {
    //  
    this.storage.clearStorage();
  }
  
  profile(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = '80%';
    dialogConfig.panelClass = 'incident-details';
    this.dialog.open(ProfileComponent, dialogConfig);
  }
}
