import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Severity } from './severity.model';
import * as SeverityActions from './severity.actions';

export const severitiesFeatureKey = 'severities';

export interface State extends EntityState<Severity> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Severity> = createEntityAdapter<Severity>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(SeverityActions.addSeverity,
    (state, action) => adapter.addOne(action.severity, state)
  ),
  on(SeverityActions.upsertSeverity,
    (state, action) => adapter.upsertOne(action.severity, state)
  ),
  on(SeverityActions.addSeveritys,
    (state, action) => adapter.addMany(action.severitys, state)
  ),
  on(SeverityActions.upsertSeveritys,
    (state, action) => adapter.upsertMany(action.severitys, state)
  ),
  on(SeverityActions.updateSeverity,
    (state, action) => adapter.updateOne(action.severity, state)
  ),
  on(SeverityActions.updateSeveritys,
    (state, action) => adapter.updateMany(action.severitys, state)
  ),
  on(SeverityActions.deleteSeverity,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(SeverityActions.deleteSeveritys,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(SeverityActions.loadSeveritys,
    (state, action) => adapter.setAll(action.severitys, state)
  ),
  on(SeverityActions.clearSeveritys,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
