import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Priority } from './priority.model';
import * as PriorityActions from './priority.actions';

export const prioritiesFeatureKey = 'priorities';

export interface State extends EntityState<Priority> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Priority> = createEntityAdapter<Priority>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(PriorityActions.upsertPriority,
    (state, action) => adapter.upsertOne(action.priority, state)
  ),
  on(PriorityActions.addPrioritys,
    (state, action) => adapter.addMany(action.prioritys, state)
  ),
  on(PriorityActions.upsertPrioritys,
    (state, action) => adapter.upsertMany(action.prioritys, state)
  ),
  on(PriorityActions.updatePriority,
    (state, action) => adapter.updateOne(action.priority, state)
  ),
  on(PriorityActions.updatePrioritys,
    (state, action) => adapter.updateMany(action.prioritys, state)
  ),
  on(PriorityActions.deletePriority,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(PriorityActions.deletePrioritys,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(PriorityActions.loadPrioritys,
    (state, action) => adapter.setAll(action.prioritys, state)
  ),
  on(PriorityActions.clearPrioritys,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
