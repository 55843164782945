import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { ApprovalWorkflowDtoInput, KbWorkflow } from './kb-workflow.model';
import { PageableParam } from 'src/app/interfaces/global.interface';

export const loadKbWorkflows = createAction(
  '[KbWorkflow/API] Load KbWorkflows', 
  props<{ kbWorkflows: KbWorkflow[] }>()
);

export const addKbWorkflow = createAction(
  '[KbWorkflow/API] Add KbWorkflow',
  props<{ kbWorkflow: KbWorkflow }>()
);

export const upsertKbWorkflow = createAction(
  '[KbWorkflow/API] Upsert KbWorkflow',
  props<{ kbWorkflow: KbWorkflow }>()
);

export const addKbWorkflows = createAction(
  '[KbWorkflow/API] Add KbWorkflows',
  props<{ kbWorkflows: KbWorkflow[] }>()
);

export const upsertKbWorkflows = createAction(
  '[KbWorkflow/API] Upsert KbWorkflows',
  props<{ kbWorkflows: KbWorkflow[] }>()
);

export const updateKbWorkflow = createAction(
  '[KbWorkflow/API] Update KbWorkflow',
  props<{ kbWorkflow: Update<KbWorkflow> }>()
);

export const updateKbWorkflows = createAction(
  '[KbWorkflow/API] Update KbWorkflows',
  props<{ kbWorkflows: Update<KbWorkflow>[] }>()
);

export const deleteKbWorkflow = createAction(
  '[KbWorkflow/API] Delete KbWorkflow',
  props<{ id: string }>()
);

export const deleteKbWorkflows = createAction(
  '[KbWorkflow/API] Delete KbWorkflows',
  props<{ ids: string[] }>()
);

export const clearKbWorkflows = createAction(
  '[KbWorkflow/API] Clear KbWorkflows'
);

////////// EFFECT ACTIONS \\\\\\\\\\\

export const addUpdateKBApprovalWorkflow = createAction(
  '[KbWorkflow/API] Create KbWorkflows',
  props<{ approvalStage: ApprovalWorkflowDtoInput }>()
);

export const getAllKBApprovalWorkflows = createAction(
  '[KbWorkflow/API] Get paged KbWorkflows',
  props<{ pageparam: PageableParam }>()
);

export const getAllKBActiveApprovalStages = createAction(
  '[KbWorkflow/API] Get active paged KbWorkflows',
  props<{ pageparam: PageableParam }>()
);


export const getKBApprovalStageByUuid = createAction(
  '[KbWorkflow/API] Get KbWorkflow by uuid',
  props<{ uuid: string }>()
);

