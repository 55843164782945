import gql from 'graphql-tag';

export const mailFields = `
            messageId
            emailFrom
            toList
            ccList
            sentDate
            emailSubject
            emailBody
            flag
            hasAttachment
`;





export const GET_INSTITION_MAIL_CONIGURATION =
  gql` query getInstitutionMailConfig{
    getInstitutionMailConfig{
      code
      status
      data{
        ${mailFields}

      }
    }
  }`


export const SYNC_MAILS =
  gql` mutation syncMails (
    $password: String,
    $mailFolder: EMailFolder,
    $mailUsernameUuid: String,
    $token: String
  ){
    syncMails(
      token:  $token,
      mailFolder:  $mailFolder,
      password:  $password,
      mailUsernameUuid:  $mailUsernameUuid,
    ){
            code
            inboxCount
            unreadCount
            userName
            message
            token
            dataList{
              messageId
              emailFrom
              toList
              ccList
              sentDate
              emailSubject
              flag
              hasAttachment
            }
    }
  }
  `

export const READ_MAIL =
gql` mutation readMail(
  $mailFolder: EMailFolder,
  $messageId: Long,
  $token: String
  $mailUsernameUuid: String
){
  readMail(
     mailFolder: $mailFolder,
     messageId: $messageId,
     token: $token,
     mailUsernameUuid: $mailUsernameUuid,
  ){
    code
    token
    userName
    data{
      sentDate
      emailBody
      userName
      messageId
      ccList
      emailFrom
      toList
      emailSubject
      
      attachments{
        fileName
        isPreviewable
      }

    }
    dataList{
      userName
      emailBody
      attachments{
        fileName
        isPreviewable
      }
      hasAttachment
    }
  }
}
`



export const SEND_INCIDENT_MAIL =
gql` 
mutation sendIncidentMail($emailParams: EmailSenderDtoInput){
  sendIncidentMail(emailParams:$emailParams){
    status
    message
    code
    
  }
  
}

`

















