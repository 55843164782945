import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { RolePermission } from './role-permission.model';

export const loadRolePermissions = createAction(
  '[RolePermission/API] Load RolePermissions',
  props<{ rolePermissions: RolePermission[] }>()
);

export const addRolePermission = createAction(
  '[RolePermission/API] Add RolePermission',
  props<{ rolePermission: RolePermission }>()
);

export const upsertRolePermission = createAction(
  '[RolePermission/API] Upsert RolePermission',
  props<{ rolePermission: RolePermission }>()
);

export const addRolePermissions = createAction(
  '[RolePermission/API] Add RolePermissions',
  props<{ rolePermissions: RolePermission[] }>()
);

export const upsertRolePermissions = createAction(
  '[RolePermission/API] Upsert RolePermissions',
  props<{ rolePermissions: RolePermission[] }>()
);

export const updateRolePermission = createAction(
  '[RolePermission/API] Update RolePermission',
  props<{ rolePermission: Update<RolePermission> }>()
);

export const updateRolePermissions = createAction(
  '[RolePermission/API] Update RolePermissions',
  props<{ rolePermissions: Update<RolePermission>[] }>()
);

export const deleteRolePermission = createAction(
  '[RolePermission/API] Delete RolePermission',
  props<{ id: string }>()
);

export const deleteRolePermissions = createAction(
  '[RolePermission/API] Delete RolePermissions',
  props<{ ids: string[] }>()
);

export const clearRolePermissions = createAction(
  '[RolePermission/API] Clear RolePermissions'
);
