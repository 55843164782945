<!-- Filter -->
<div class="table-filter">
    <div class="row">
        <div class="col-lg-8 col-md-6 col-sm-12">
            <mat-form-field class="search-form-field" floatLabel="never">
                <mat-icon matPrefix>search</mat-icon>
                <input
                        (keyup)="delayedFilter()"
                        [(ngModel)]="searchKey"
                        autocomplete="off"
                        matInput
                        placeholder="Search"
                />
                <button
                        (click)="onSearchClear()"
                        *ngIf="searchKey"
                        arial-label="Clear"
                        mat-button
                        mat-icon-button
                        matSuffix
                >
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
            <mat-form-field appearance="outline" class="material-form">
                <mat-label>Visible Columns</mat-label>
                <mat-select (selectionChange)="toggleTableColumnsVisibility($event.value)"
                            [(ngModel)]="selectedColumn"
                            dropzone="top"
                            multiple="true">
                    <mat-option *ngFor="let app of tableColumns" [disabled]="!app?.visible" [value]="app.key">
                        {{app.value}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

    </div>
</div>
<!-- end of Filter -->

<div class="mat-elevation-z8">
    <mat-table (matSortChange)="sortChange($event)" [dataSource]="listData" matSort>
        <ng-container *ngFor="let tableData of objectKeys(recreatedColumnHeader); let i = index">
            <ng-container *ngIf="i == 0">
                <!-- SN Column -->
                <ng-container matColumnDef="{{ tableData }}">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ recreatedColumnHeader[tableData] }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element; let i = index">
                        {{ i + 1 + myPaginator.pageIndex * myPaginator.pageSize }}
                    </mat-cell>
                </ng-container>
                <!-- end of SN Column -->
            </ng-container>

            <ng-container *ngIf="!actionColumn">
                <ng-container *ngIf="i > 0">
                    <ng-container matColumnDef="{{ tableData }}">
                        <mat-header-cell *matHeaderCellDef mat-sort-header
                        >{{ recreatedColumnHeader[tableData] }}
                        </mat-header-cell>
                        <!-- <mat-cell *matCellDef="let element" (click)="viewDetails(element)"> -->
                        <mat-cell *matCellDef="let element">
                            <span [innerHTML]="element[tableData]"> </span>
                        </mat-cell>
                    </ng-container>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="actionColumn || hasCheckBoxColumn">
                <ng-container *ngIf="i > 0 && i < objectKeys(recreatedColumnHeader).length - 1">
                    <ng-container matColumnDef="{{ tableData }}">
                        <mat-header-cell *matHeaderCellDef mat-sort-header
                        >{{ recreatedColumnHeader[tableData] }}
                        </mat-header-cell>

                        <mat-cell *matCellDef="let element">
                            <!-- <mat-cell *matCellDef="let element" (click)="viewDetails(element)"> -->
                            <span [innerHTML]="element[tableData]"> </span
                            ></mat-cell>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="i == objectKeys(recreatedColumnHeader).length - 1">
                    <ng-container matColumnDef="{{ tableData }}">
                        <mat-header-cell *matHeaderCellDef mat-sort-header
                        >{{ recreatedColumnHeader[tableData] }}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <ng-container
                                    [ngTemplateOutletContext]="{ data: row }"
                                    [ngTemplateOutlet]="actions"
                            ></ng-container>
                            
                            <ng-container *ngIf="hasCheckBoxColumn">
                                <div class="px-1 ">
                                    <mat-checkbox (change)="$event ? selection.toggle(row) : null"
                                                  (click)="$event.stopPropagation()"
                                                  [checked]="selection.isSelected(row)"
                                                  class="icon-holder">
                                    </mat-checkbox>
                                </div>
                            </ng-container>
                        </mat-cell>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>

        <!-- Table Configurations -->
        <mat-header-row
                *matHeaderRowDef="objectKeys(recreatedColumnHeader)"
        ></mat-header-row>
        <mat-row *matRowDef="let row; columns: objectKeys(recreatedColumnHeader)"></mat-row>
        <!-- end of Table Configurations -->

        <!-- If No Data Found -->
        <mat-footer-row
                *matFooterRowDef="['noData']"
                [ngClass]="{ hide: !(listData != null && listData.data.length == 0) }"
        ></mat-footer-row>
        <ng-container matColumnDef="noData">
            <mat-footer-cell *matFooterCellDef colspan="8">No Data</mat-footer-cell>
        </ng-container>
        <!-- end if no Data Found -->

        <!-- Loading Data Message -->
        <mat-footer-row
                *matFooterRowDef="['loading']"
                [ngClass]="{ hide: listData != null }"
        ></mat-footer-row>
        <ng-container matColumnDef="loading">
            <mat-footer-cell *matFooterCellDef colspan="8"
            >Loading ...
            </mat-footer-cell
            >
        </ng-container>
        <!-- end of Loading Data Message -->
    </mat-table>

    <mat-paginator
        #myPaginator
        (page)="handlePage($event, false)"
        [length]="dataLength"
        [pageSizeOptions]="pageSizeOptions"
        [pageSize]="pageSize"
        [pageIndex]="pageIndex"

        showFirstLastButtons
    >
    </mat-paginator>
</div>
