import { ResponseCodeService } from './../../../../services/response-code.service';
import { AppState } from './../../../reducers/index';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import { NotificationService } from 'src/app/services/notification.service';
import * as fromActions from './online-resource.actions';
import * as fromGraphql from './online-resource.graphql';
import { map, switchMap } from 'rxjs/operators';
@Injectable()
export class OnlineResourceEffects {


  // List All OnlineResources
  listAllOnlineResources$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.listAllOnlineResources),
    switchMap((action) => {
      return this.apollo.query({
        query: fromGraphql.GET_MY_INSTITUTION_ONLINE_RESOURCES,
        fetchPolicy: 'network-only',
      }).pipe(
        this.notificationService.catchError('Problem occurred while fetching OnlineResources'),
        map(({ data }: any) => {
          if (data) {
            this.store.dispatch(fromActions.loadOnlineResources({ onlineResources: data.getMyInstitutionOnlineResources.dataList }));
          }
        })
      );
    })
  ), {dispatch: false});

  // Get OnlineResource By Id
  getOnlineResourceById$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.getOnlineResourceById),
    switchMap((action) => {
      return this.apollo.query({
        query: fromGraphql.GET_ONLINE_RESOURCE_BY_ID,
        fetchPolicy: 'network-only',
        variables: {
          id: action.id
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while fetching OnlineResource'),
        map(({ data }: any) => {
          if (data) {
            if (data.OnlineResourceById.code === 9000) {
              this.store.dispatch(fromActions.upsertOnlineResource({ onlineResource: data.onlineResourceById.data }));
            } else {
              const error = this.responseCodesService.getCodeDescription(data.OnlineResourceById.code);
              this.notificationService.errorMessage(error[0]?.description);
            }
          }
        })
      );
    })
  ), {dispatch: false});

  // Create/Save OnlineResource
  saveOnlineResource$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.saveOnlineResource),
    switchMap((action) => {
     
      return this.apollo.mutate({
        mutation: fromGraphql.SAVE_ONLINE_RESOURCE,
        variables: {
          onlineResource: action.onlineResource
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while saving OnlineResource'),
        map(({ data }: any) => {
          if (data) {
             
            if (data.saveOnlineResource.code === 9000) {
              this.store.dispatch(fromActions.upsertOnlineResource({ onlineResource: data.saveOnlineResource.data }));
              if (action.onlineResource?.id) {
                this.notificationService.successMessage('Edited Successfully');
              } else {
                this.notificationService.successMessage('Created Successfully');
              }
            } else {
              const error = this.responseCodesService.getCodeDescription(data.saveOnlineResource.code);
              this.notificationService.errorMessage(error[0]?.description);
            }
          }
        })
      );
    })
  ), {dispatch: false});

  // Detele OnlineResource
  removeOnlineResource$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.removeOnlineResource),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: fromGraphql.DELETE_ONLINE_RESOURCE,
        variables: {
          onlineResourceId: action.id
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while deleting OnlineResource'),
        map(({ data }: any) => {
          if (data) {
            if (data.deleteOnlineResource === 'SUCCESS') {
              this.store.dispatch(fromActions.deleteOnlineResource({ id: action.id }));
              this.notificationService.successMessage('Deleted Successfully');
            } else {
              this.notificationService.errorMessage(data.deleteRole);
            }
          }
        })
      );
    })
  ), {dispatch: false});

  constructor(
    private actions$: Actions,
    private apollo: Apollo,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private responseCodesService: ResponseCodeService
  ) {
  }


}
