import { AppState } from './../../../reducers/index';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromDepartmentReducer from './department.reducer';
import { Department } from './department.model';

export const currentState = (state: AppState) => (state[fromDepartmentReducer.departmentsFeatureKey]);

export const selectAllDepartments = createSelector(currentState, fromDepartmentReducer.selectAll);

export const selectTotalDepartments = createSelector(currentState, fromDepartmentReducer.selectTotal);

export const selectModifiedDepartments = createSelector(selectAllDepartments, (departments) => {
  return departments.map((department) => {
    return {
      ...department,
      unitText: department.unit ? 'YES' : 'NO',
      ishelpdesk:department.helpdeskDepartment? 'YES' : 'NO',
      taasisi:department?.institution?.name,
      iSactive: department?.active ? 'ACTIVE' : 'IN-ACTIVE',
      parentDepartmentName: department?.parentDepartment ? department?.parentDepartment?.departmentName : ''
    };
  });
});

export const selectInstitutionDepartmentOrUnit = (params: {isUnit: boolean}) => createSelector(
  selectModifiedDepartments,
  ((departments: Department[]) => {
    return departments.filter((department) => department?.unit === params.isUnit);
  })
);

export const selectDepartmentByInstitutionId = (params: {id: number}) => createSelector(
  selectModifiedDepartments,
  (departments: Department[]) => {
      return departments.filter((dep) => dep?.institution?.id === params.id);
  }
);

export const selectDepartmentById = (params: {id: number}) => createSelector(
  selectModifiedDepartments,
  (departments: Department[]) => {
      return departments.find((dep) => dep.id === params.id);
  }
);
