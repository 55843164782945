import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { MaterialFile } from './material-file.model';
import * as MaterialFileActions from './material-file.actions';

export const materialFilesFeatureKey = 'materialFiles';

export interface State extends EntityState<MaterialFile> {
  // additional entities state properties
}

export const adapter: EntityAdapter<MaterialFile> = createEntityAdapter<MaterialFile>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export const reducer = createReducer(
  initialState,
  on(MaterialFileActions.addMaterialFile,
    (state, action) => adapter.addOne(action.materialFile, state)
  ),
  on(MaterialFileActions.upsertMaterialFile,
    (state, action) => adapter.upsertOne(action.materialFile, state)
  ),
  on(MaterialFileActions.addMaterialFiles,
    (state, action) => adapter.addMany(action.materialFiles, state)
  ),
  on(MaterialFileActions.upsertMaterialFiles,
    (state, action) => adapter.upsertMany(action.materialFiles, state)
  ),
  on(MaterialFileActions.updateMaterialFile,
    (state, action) => adapter.updateOne(action.materialFile, state)
  ),
  on(MaterialFileActions.updateMaterialFiles,
    (state, action) => adapter.updateMany(action.materialFiles, state)
  ),
  on(MaterialFileActions.deleteMaterialFile,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(MaterialFileActions.deleteMaterialFiles,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(MaterialFileActions.loadMaterialFiles,
    (state, action) => adapter.setAll(action.materialFiles, state)
  ),
  on(MaterialFileActions.clearMaterialFiles,
    state => adapter.removeAll(state)
  ),
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
