import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { AptitudeTest } from './aptitude-test.model';
import * as AptitudeTestActions from './aptitude-test.actions';

export const aptitudeTestsFeatureKey = 'aptitudeTests';

export interface State extends EntityState<AptitudeTest> {
  // additional entities state properties
}

export const adapter: EntityAdapter<AptitudeTest> = createEntityAdapter<AptitudeTest>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export const reducer = createReducer(
  initialState,
  on(AptitudeTestActions.addAptitudeTest,
    (state, action) => adapter.addOne(action.aptitudeTest, state)
  ),
  on(AptitudeTestActions.upsertAptitudeTest,
    (state, action) => adapter.upsertOne(action.aptitudeTest, state)
  ),
  on(AptitudeTestActions.addAptitudeTests,
    (state, action) => adapter.addMany(action.aptitudeTests, state)
  ),
  on(AptitudeTestActions.upsertAptitudeTests,
    (state, action) => adapter.upsertMany(action.aptitudeTests, state)
  ),
  on(AptitudeTestActions.updateAptitudeTest,
    (state, action) => adapter.updateOne(action.aptitudeTest, state)
  ),
  on(AptitudeTestActions.updateAptitudeTests,
    (state, action) => adapter.updateMany(action.aptitudeTests, state)
  ),
  on(AptitudeTestActions.deleteAptitudeTest,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(AptitudeTestActions.deleteAptitudeTests,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(AptitudeTestActions.loadAptitudeTests,
    (state, action) => adapter.setAll(action.aptitudeTests, state)
  ),
  on(AptitudeTestActions.clearAptitudeTests,
    state => adapter.removeAll(state)
  ),
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
