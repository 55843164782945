import gql from 'graphql-tag';

export const categoryFields = `
active
createdAt
createdBy
deleted
id
name
updatedAt
updatedBy
uuid
`;



export const LIST_OF_INSTITUTION_CATEGORIES = gql`
query listOfInstitutionCategories{
  listOfInstitutionCategories{
    ${categoryFields}  
  }
}
`;

export const GET_ALL_INSTITUTION_CATEGORIES_BY_UUID = gql`
query getInstitutionCategoryByUuid($uuid: String){
  getInstitutionCategoryByUuid(uuid:$uuid){
    code
    message
    status
    data{
      ${categoryFields}
    }
    dataList{
     ${categoryFields}
    }
  }
}
`;



