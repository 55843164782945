import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {Apollo} from 'apollo-angular';
import {UPDATE_PASSWORD} from 'src/app/store/entities/settings/user/user.graphql';
import {NotificationService} from 'src/app/services/notification.service';
import {map, take} from 'rxjs/operators';
import {StorageService} from '../../../services/storage.service';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

    title: string;
    changePasswordForm: UntypedFormGroup;
    loading = false;
    subscription$: any;

    constructor(
        private dialogRef: MatDialogRef<ChangePasswordComponent>,
        private fb: UntypedFormBuilder,
        private notificationService: NotificationService,
        private storageService: StorageService,
        private apollo: Apollo
        // @Inject(MAT_DIALOG_DATA) data
    ) {
    }

    ngOnInit(): void {

        this.title = 'Change Account Password';
        this.changePasswordForm = this.fb.group({
            newPassword: [null, [Validators.required]],
            confirmPassword: [null, [Validators.required]],
            oldPassword: [null, [Validators.required]],
        });

    }

    submitPasswordForm(formValue): void {
        localStorage.removeItem('passwordExpirationDate');
        const passChange = this.apollo.mutate({
            mutation: UPDATE_PASSWORD,
            variables: {
                passwordChange: {
                    previousPassword: formValue.oldPassword,
                    confirmPassword: formValue.confirmPassword,
                    newPassword: formValue.newPassword
                }
            }
        }).pipe(
            map(({data}: any) => {
                return data;
            })
        );


        this.subscription$ = passChange.pipe(take(1)).subscribe(res => {
            if (res.changePassword.code === 9000) {
                this.notificationService.successMessage('Password changed successfully');
            } else {
                this.notificationService.errorMessage(res.changePassword?.message + res.changePassword?.code);
            }
        });

        this.closeThisModal(true);
    }

    closeThisModal(event) {
        this.dialogRef.close(event);
    }

}
