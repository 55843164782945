import { AppState } from './../../../reducers/index';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromBranchReducer from './branch.reducer';
import { Branch } from './branch.model';

export const currentState = (state: AppState) => (state[fromBranchReducer.branchsFeatureKey]);

export const selectAllBranchs = createSelector(currentState, fromBranchReducer.selectAll);

export const selectTotalBranchs = createSelector(currentState, fromBranchReducer.selectTotal);

export const selectModifiedBranchs = createSelector(selectAllBranchs, (branchs) => {
  return branchs.map((branch) => {
    return {
      ...branch,
      // unitText: branch.unit ? 'YES' : 'NO',
      taasisi:branch?.institution?.name,
      iSactive: branch?.active ? 'ACTIVE' : 'IN-ACTIVE'
    };
  });
});


export const selectBranchById = (params: {id: number}) => createSelector(
  selectModifiedBranchs,
  (branchs: Branch[]) => {
      return branchs.find((dep) => dep.id === params.id);
  }
);
