import gql from 'graphql-tag';
import { institutionFields } from '../institution/institution.graphql';

export const requestCategorysFields = `
        active
        createdAt
        createdBy
        deleted
        description
        id
        institution{
          ${institutionFields}
        }
        title
        updatedAt
        updatedBy
        uuid
`;

// REQUESTs




export const GET_MY_INSTITUTION_ALL_REQUEST_CATEGORYS = gql`
query getAllInstitutionChangeRequestCategory($pageParam: PageableParamInput){
  getAllInstitutionChangeRequestCategory(pageParam:$pageParam){
    content{
      ${requestCategorysFields}
    }
    first
    last
    number
    numberOfElements
    pageable{
        pageNumber
        pageSize
    }
    size
    totalElements
    totalPages
  }
}
`;



export const SAVE_UPDATE_REQUEST_CATEGORY = gql`
mutation createUpdateChangeRequestCategory($category: ChangeRequestCategoryDtoInput){
  createUpdateChangeRequestCategory(category:$category){
    code
    status
    data{
      ${requestCategorysFields}
    }
    dataList{
      ${requestCategorysFields}
    }
    message
  }
}
`;


export const DELETE_REQUEST_CATEGORY = gql`
mutation deleteChangeRequestCategory($categoryId: Long){
  deleteChangeRequestCategory(categoryId:$categoryId){
    code
    status
    data{
      ${requestCategorysFields}
    }
    dataList{
      ${requestCategorysFields}
    }
    message
  }
}
`;










