import gql from "graphql-tag";
import { basePageableGraphqlField } from "../../base.graphql";

export const incidentFields = `   
    userFromUuid
    createdBy
    userFromName
    id
    active
    dateSubmitted
    title
    expectedClosedDate
    clientInternalCode
    category{
      id
      uuid
      title
    }
    issueCategory{
      id
      uuid
      title
    }
    severity {
      id
      uuid
      title
    }
    service {
      id
      uuid
      name
      issuesOperatedBranchWise
    }
    assignments{
      createdBy
      currentAssigned
      currentLeader
      firstLevelAssignment
      userEmail
      userName
      uuid
      userUuid
    }
    createdAt
    createdBy
    dateClosed
    deleted
    description
    incidentNo
    incidentOrigin
    incidentStatus
    secondLevelStatus
 
    institutionFromUuid
    institutionFrom{
      id
      uuid
      name
      canReportToBranches 
    }
    externalIncidentStatus
    ownerInstitutionUuid
    ownerInstitution{
      id
      uuid
      name
      canReportToBranches 
    }
    problem{
      id
      active
      description
      title
    }
    priority {
      id
      uuid
      title
      timePeriod
      periodType
    }
    rootCause
    departmentUuid
    ownerUuid
    uuid
    serviceUuid
    branchToUuid
    reportedOnBehalf
    reportedVia
    dateClosed
    ownerPersonel {
      id
      uuid
    }
    secondLevelDepartment{
      id
      uuid
      departmentName
    }
    resource{
      id
      uuid
      name
    }
    branchTo{
      id
      uuid
      branchName
    }
    branchFromUuid
    branchFrom{
      id
      uuid
      branchName
    }
    serviceName
    dateReOpened
    dateResolved
    parent {
      uuid
      id
      incidentNo
      serviceName
      title
      dateSubmitted
      institutionFrom{
        name
      }
    }
    children{
      uuid
      id
      incidentNo
      serviceName
      title
      dateSubmitted
      institutionFrom{
        name
      }
    }
    incidentResponses{
      id
      incidentStatus
      createdAt
      holdTimeInDays
      responseType
      seen
    }
`;

export const incidentFewFields = `
userFromUuid
createdBy
id
active
dateSubmitted
title
serviceName
expectedClosedDate
clientInternalCode
incidentResponses{
  id
  incidentStatus
  createdAt
  holdTimeInDays
  seen
  responseType
}
category{
  id
  uuid
  title
}
issueCategory{
  id
  uuid
  title
}
severity {
  id
  uuid
  title
}
service {
  id
  uuid
  name
}
assignments{
  id
  createdBy
  currentAssigned
  currentLeader
  firstLevelAssignment
  incident{
    id
  }
  userUuid
  userEmail
  userName
  uuid
}
createdAt
createdBy
dateClosed
deleted
description
incidentNo
incidentOrigin
incidentStatus
secondLevelStatus
institutionFromUuid
institutionFrom{
  id
  uuid
  name
  canReportToBranches 
}
externalIncidentStatus
ownerInstitutionUuid
ownerInstitution{
  id
  uuid
  name
  canReportToBranches 
}
rootCause
departmentUuid
ownerUuid
uuid
branchToUuid
branchFromUuid
branchTo{
  id
  uuid
  branchName
}
branchFrom{
  id
  uuid
  branchName
}
secondLevelDepartment{
  id
  uuid
  departmentName
}
dateReOpened
dateResolved
children{
  uuid
  id
  incidentNo
  serviceName
  title
  dateSubmitted
}

priority {
    id
    uuid
    title
    timePeriod
    periodType
  }
  reportedBy{
    id
    fullName
  }
`;

export const GET_ASSIGNED_TICKETS = gql`
  query getMyAssignedIncidents(
  $getAssignedToDept: Boolean
  ,$pageparam: PageableParamInput, $incidentStatus: [IncidentStatus]){
    getMyAssignedIncidents(getAssignedToDept:$getAssignedToDept, pageparam: $pageparam, incidentStatus: $incidentStatus){
      ${basePageableGraphqlField}
      content{
      ${incidentFewFields}
      }
    }
  }
`;

export const GET_TICKETS_BY_STATUS = gql`
  query getAllIncidentsByStatus($pageparam: PageableParamInput, $incidentOrigin: IncidentOrigin, $incidentStatus: [IncidentStatus],$reportedOnBehalf: Boolean){
    getAllIncidentsByStatus(pageparam: $pageparam, incidentOrigin: $incidentOrigin, incidentStatus: $incidentStatus,reportedOnBehalf: $reportedOnBehalf){
      content{
        ${incidentFewFields}
      }
      size
      totalPages
      totalElements
      numberOfElements
    }
  }
`;

export const GET_ALL_TICKETS_FOR_EXECUTIVE_OFFICERS = gql`
query getAllIncidentsByExecutiveOfficers($pageparam: PageableParamInput, $incidentOrigin: IncidentOrigin, $incidentStatus: [IncidentStatus],$branchUuid: String,$departmentUuid: String){
  getAllIncidentsByExecutiveOfficers(pageparam: $pageparam, incidentOrigin: $incidentOrigin, incidentStatusList: $incidentStatus,branchUuid: $branchUuid, departmentUuid: $departmentUuid){
      content{
        ${incidentFewFields}
      }
      size
      totalPages
      totalElements
      numberOfElements
    }
  }
`;
export const GET_ALL_UNMERGED_TICKETS_BY_STATUS = gql`
  query getAllUnMergedIncidentsByStatus($incidentOrigin: IncidentOrigin $pageparam: PageableParamInput $incidentStatus: [IncidentStatus]){
    getAllUnMergedIncidentsByStatus(incidentOrigin: $incidentOrigin pageparam: $pageparam incidentStatus: $incidentStatus){
      content{
        ${incidentFewFields}
      }
      size
      totalPages
      totalElements
      numberOfElements
    }
  }
`;
export const GET_ALL_PARENT_TICKETS_BY_STATUS = gql`
  query getAllParentIncidentsByStatus($incidentOrigin: IncidentOrigin $pageparam: PageableParamInput $incidentStatus: [IncidentStatus]){
    getAllParentIncidentsByStatus(incidentOrigin: $incidentOrigin pageparam: $pageparam incidentStatus: $incidentStatus){
      content{
        ${incidentFewFields}
      }
      size
      totalPages
      totalElements
      numberOfElements
    }
  }
`;

export const GET_TICKETS_BY_STATUS_AND_REPORTED_ONBEHALF = gql`
  query getAllIncidentsByStatus($reportedOnBehalf: Boolean, $pageparam: PageableParamInput, $incidentStatus: [IncidentStatus]){
    getAllIncidentsByStatus(reportedOnBehalf: $reportedOnBehalf,pageparam: $pageparam, incidentStatus: $incidentStatus){
      content{
        ${incidentFewFields}
      }
      size
      totalPages
      totalElements
      numberOfElements
    }
  }
`;

export const GET_DEPARTMMENT_TICKETS_BY_STATUS = gql`
  query getMyInstDeptIncidentsByStatus($pageparam: PageableParamInput, $incidentStatus: [IncidentStatus]){
    getMyInstDeptIncidentsByStatus(pageparam: $pageparam, incidentStatus: $incidentStatus){
      content{
        ${incidentFewFields}
      }
      size
      totalPages
      totalElements
      numberOfElements
    }
  }
`;

export const GET_MY_INST_SECTION_INCIDENTS = gql`
  query getMyInstSectionIncidents($pageparam: PageableParamInput, $incidentStatus: [IncidentStatus], $sectionUid: String){
    getMyInstSectionIncidents(pageparam: $pageparam, incidentStatus: $incidentStatus, department: $sectionUid){
      content{
        ${incidentFewFields}
      }
      size
      totalPages
      totalElements
      numberOfElements
    }
  }
`;

export const LIST_INCIDENTS_BY_STATUS = gql`
    query incidentByStatusList($pageable: PegeableParamInput, $status: IncidentStatus){
        incidentByStatusList(pageable: $pageable, status: $status){
            content{
              ${incidentFewFields}
            }
            size
            totalPages
            totalElements
            numberOfElements
        }
    }
`;

export const LIST_INCIDENTS_BY_STATE_AND_INSTITUTION = gql`
  query incidentByStateListInst($pageable: PegeableParamInput, $state: IncidentState){
    incidentByStateListInst(pageable: $pageable, state: $state){
      content{
        ${incidentFewFields}
      }
      id
      size
      totalPages
      totalElements
      numberOfElements
    }
  }
`;

export const ASSIGN_INCIDENT_TO_DEPARTMENT = gql`
  mutation assignIncidentToDeptByUuid($deptUuid: String, $incidentUuid: String) {
    assignIncidentToDeptByUuid(deptUuid: $deptUuid, incidentUuid: $incidentUuid) {
      code
      data{
        ${incidentFewFields}
      }
      message
    }
  }
`;

export const UN_MERGE_INCIDENT = gql`
  mutation unMergeIncident($incidentUuid: String){
    unMergeIncident(incidentUuid: $incidentUuid){
      code
      data{
        ${incidentFewFields}
      }
      message
    }
  }
`;

export const SELF_ASSIGN_INCIDENT = gql`
  mutation selfAssignIncident($IncidentAssignmentDto: IncidentAssignmentDtoInput) {
    selfAssignIncident(IncidentAssignmentDto: $IncidentAssignmentDto) {
      code
      data {
        id
        uuid
        institutionId
        userUuid
      }
      message
    }
  }
`;

export const LIST_INCIDENTS_BY_STATUS_PER_INSTITUTION = gql`
  query incidentsByStatus($pageable: PegeableParamInput, $institutionId: Long, $status: IncidentStatus) {
    incidentsByStatus(pageable: $pageable, status: $status, institutionId: $institutionId) {
      content{
        ${incidentFewFields}
      }
      size
      totalPages
      totalElements
      numberOfElements
    }
  }
`;

export const LIST_INCIDENTS_BY_STATE_PER_INSTITUTION = gql`
    query incidentsByState($pageable: PegeableParamInput, $state: IncidentState, $institutionId: Long){
      incidentsByState(pageable: $pageable, state: $state, institutionId: $institutionId){
        content{
          ${incidentFewFields}
        }
        size
        totalPages
        totalElements
        numberOfElements
      }
    }
`;

export const DELETE_INCIDENT = gql`
  mutation deleteIncident($incidentUuid: String) {
    deleteIncident(incidentUuid: $incidentUuid) {
      code
      data{
        ${incidentFewFields}
      }
    }
  }
`;

export const GET_ALL_INCIDENTS_LIST = gql`
  query getAllIncidents($pageparam: PageableParamInput) {
    getAllIncidents(pageparam: $pageparam) {
      content {
        id
        uuid
        createdAt
        description
        reportedVia
        institutionFromName
        title
        severity {
          id
          uuid
          title
          description
        }
        uuid
        incidentNo
        incidentOrigin
        incidentStatus
        resource {
          id
        }
      }
    }
  }
`;

export const REPORT_INCIDENCE = gql`
mutation createUpdateIncident($incidence:IncidentDtoInput){
  createUpdateIncident(incidence:$incidence){
    code
    message
    status
    data{
      ${incidentFields}

    }
    
  }
}
`;

export const INCIDENT_SENT_BY_ME = gql`
  query getMyInstIncidentsOut($incidentOrigin: IncidentOrigin, $incidentStatus: [ExternalIncidentStatus], $pageparam: PageableParamInput){
    getMyInstIncidentsOut(incidentOrigin: $incidentOrigin,  incidentStatus:$incidentStatus, pageparam:$pageparam){
      ${basePageableGraphqlField}
      content{
       ${incidentFewFields}
      }
    }
  }
`;

export const GET_OUT_IINCIDENTS_BY_STATUS = gql`
  query getMyInstIncidentsOutByStatus($pageparam: PageableParamInput, $incidentStatus: [ExternalIncidentStatus]){
    getMyInstIncidentsOutByStatus(pageparam:$pageparam, incidentStatus:$incidentStatus){
      ${basePageableGraphqlField}
      content{
       ${incidentFewFields}
      }
    }
  }
`;
export const GET_OUT_MY_INDIVIDUAL_NCIDENTS_BY_STATUS = gql`
  query getMyIndividualIncidentsOut($incidentOrigin: IncidentOrigin $pageparam: PageableParamInput, $incidentStatus: [ExternalIncidentStatus]){
    getMyIndividualIncidentsOut(incidentOrigin:$incidentOrigin pageparam:$pageparam, incidentStatus:$incidentStatus){
      ${basePageableGraphqlField}
      content{
       ${incidentFewFields}
      }
    }
  }
`;

export const GET_INCIDENT_BY_UID = gql`
  query findIncidentByUuid( $uuid: String){
    findIncidentByUuid( incidentUuid: $uuid){
      code
      data{
        ${incidentFields}
      }
    }
  }
`;

export const GET_INCIDENT_REPORTED_BY_ME = gql`
query getMyReportedIncidentsOutByStatus($ticketNumber: String,$pageparam: PageableParamInput, $incidentStatus: [ExternalIncidentStatus]){
  getMyReportedIncidentsOutByStatus(ticketNumber:$ticketNumber,pageparam:$pageparam, incidentStatus:$incidentStatus){
    content{
      ${incidentFewFields}
    }
    ${basePageableGraphqlField}
  }
}
`;

export const RECATEGORIZE_INCIDENT = gql`
mutation incidentRecategorization($incidentRecategorize: IncidentRecategorizeDtoInput){
  incidentRecategorization(incidentRecategorize:$incidentRecategorize){
    code
    message
    data{
      ${incidentFields}
    }
  }
}
`;

export const RECATEGORIZE_MULTIPLE_INCIDENTS = gql`
mutation multipleIncidentsRecategorization($incidentRecategorize: IncidentRecategorizeDtoInput){
  multipleIncidentsRecategorization(incidentRecategorize:$incidentRecategorize){
    code
    message
    dataList{
      ${incidentFewFields}
    }
  }
}
`;

export const CONFRIM_INCIDENT_RESOLVED = gql`
mutation confirmIncidentClosingByUuid($confirm: Boolean, $description: String, $incidentUuid: String){
  confirmIncidentClosingByUuid(confirm:$confirm, description: $description, incidentUuid: $incidentUuid){
    code
    message
    status
    data{
      ${incidentFields}
    }
    
  }
}
`;

export const GET_INCIDENT_TRANSACTIONS = gql`
  query getAllIncidentTransactionsByIncident($incidentUuid: String, $pageparam: PageableParamInput) {
    getAllIncidentTransactionsByIncident(incidentUuid: $incidentUuid, pageparam: $pageparam) {
      content {
        id
        uuid
        createdAt
        createdBy
        description
        incidentStatus
        userEmail
      }
    }
  }
`;

export const MERGE_INCIDENT_TO_PARENT = gql`
  mutation updateIncidentParentByUuid($parentTicketNo: String $incidentUuids: [String]){
    updateIncidentParentByUuid(parentTicketNo: $parentTicketNo incidentUuids: $incidentUuids){
      code
      message
      status
      data{
        ${incidentFields}
      }
    }
  }
`;

export const INCIDENT_DETAILED_REPORT = gql`
  query getIncidentDetailedReport(
    $issueTitleUuids: [String]
    $fromDate: LocalDate
    $categoryUuid: [String]
    $incidentOrigins: [IncidentOrigin]
    $exportDocType: ExportDocType
    $filterByDateResolved: Boolean
    $toDate: LocalDate
    $serviceUuids: [String]
    $institutionUuid: String
    $departmentUuid: String
    $incidentStatus: [IncidentStatus]
  ) {
    getIncidentDetailedReport(
      issueTitleUuids: $issueTitleUuids
      fromDate: $fromDate
      categoryUuid: $categoryUuid
      incidentOrigins: $incidentOrigins
      exportDocType: $exportDocType
      filterByDateResolved:$filterByDateResolved
      toDate: $toDate
      serviceUuids: $serviceUuids
      institutionUuid: $institutionUuid
      departmentUuid: $departmentUuid
      incidentStatus: $incidentStatus
    ) {
      code
      status
      message
      data
    }
  }
`;

export const INCIDENT_STATISTICAL_REPORT = gql`
  query getIncidentStatisticsReport(
    $issueTitleUuids: [String]
    $fromDate: LocalDate
    $incidentOrigins: [IncidentOrigin]
    $exportDocType: ExportDocType
    $filterByDateResolved: Boolean
    $toDate: LocalDate
    $serviceUuids: [String]
    $institutionUuid: String
    $incidentCategoryUuids: [String]
    $departmentUuid: String
  ) {
    getIncidentStatisticsReport(
      issueTitleUuids: $issueTitleUuids
      fromDate: $fromDate
      incidentOrigins: $incidentOrigins
      exportDocType: $exportDocType
      filterByDateResolved:$filterByDateResolved
      toDate: $toDate
      serviceUuids: $serviceUuids
      institutionUuid: $institutionUuid
      incidentCategoryUuids: $incidentCategoryUuids
      departmentUuid: $departmentUuid
    ) {
      code
      data
      message
      status
    }
  }
`;

export const CLOSE_ALL_INCIDENT_TIKETS = gql`
  mutation closeMyInstitutionTickets {
    closeMyInstitutionTickets
  }
`;
// getAllIncidentsByInstitutionUuid

// getAllIncidentsByInstitutionUuidAndDateRange

export const GENERAL_STATISTICAL_REPORT_SUMMERY = gql`
  query getDashboardData {
    getDashboardData {
      code
      message
      status
      data {
        totalClients
        totalInstitutionServices
        totalInstitutions
        totalServiceProviders
        totalYearlyCallsReceived
      }
    }
  }
`;

export const PROVIDER_GENERAL_STATISTICAL_REPORT_SUMMERY = gql`
  query getGraphDataByInstitutionUuid($institutionUuid: String) {
    getGraphDataByInstitutionUuid(institutionUuid: $institutionUuid) {
      code
      message
      status
      data {
        totalClients
        totalInstitutionServices
        totalInstitutions
        totalServiceProviders
        totalYearlyCallsReceived
      }
    }
  }
`;

export const GENERAL_DASHBOARD_REPORT = gql`
  query getIncidentDashboard {
    getIncidentDashboard {
      code
      message
      status
      data {
        callRegistryCounts
        durationFrom
        incidentCounts
        months
        pieChartDataList {
          incidentCount
          incidentStatus
        }
        totalAssets
        totalYearlyIncidents
      }
    }
  }
`;

export const PROVIDER_GENERAL_DASHBOARD_REPORT = gql`
  query getIncidentDashboardByUuid($institutionUuid: String) {
    getIncidentDashboardByUuid(institutionUuid: $institutionUuid) {
      code
      message
      status
      data {
        callRegistryCounts
        durationFrom
        incidentCounts
        months
        pieChartDataList {
          incidentCount
          incidentStatus
        }
        totalAssets
        totalYearlyIncidents
      }
    }
  }
`;

export const TRANSFER_TICKET_OWNERSHIP_TO_ANOTHER_FIRST_LEVEL = gql`
 mutation transferTicketOwnershipToAnotherFirstLevel($incidentUuid: String,$firstLevelUuid: String){
       transferTicketOwnershipToAnotherFirstLevel(incidentUuid: $incidentUuid, firstLevelUuid: $firstLevelUuid){
         code
         message
         data{
        ${incidentFields}

         }
       }
     }
`;

export const GET_INCIDENT_TRANSACTIONS_COUNTS = gql`
  query getAllIncidentTransactionsByIncident($incidentUuid: String, $pageparam: PageableParamInput) {
    getAllIncidentTransactionsByIncident(incidentUuid: $incidentUuid, pageparam: $pageparam) {
      totalElements
    }
  }
`;

export const GET_TOTAL_ISSUE_STATISTICS = gql`
  query totalStatisticsListIncident($institutionUuid: String) {
    totalStatisticsListIncident(institutionUuid: $institutionUuid) {
      rowCount
      title
    }
  }
`;

export const GET_ISSUE_CHART_STATISTICS = gql`
  query totalStatisticsIncidentGraph($incidentStatisticsReq: IncidentStatisticsReqDtoInput) {
    totalStatisticsIncidentGraph(incidentStatisticsReq: $incidentStatisticsReq) {
      code
      status
      message
      data {
        histogramGraphList {
          title
          subTitle
          rowCount
        }
        lineGraphList {
          title
          subTitle
          rowCount
        }
        pieChatGraphList {
          title
          subTitle
          rowCount
        }
      }
    }
  }
`;

export const GET_ISSUE_SERVICE_STATISTICS = gql`
  query totalStatisticsIncidentServiceWise($incidentStatisticsReq: IncidentStatisticsReqDtoInput) {
    totalStatisticsIncidentServiceWise(incidentStatisticsReq: $incidentStatisticsReq) {
      title
      subTitle
      rowCount
    }
  }
`;

export const GET__SECTION_WISE_ISSUE_STATISTICS = gql`
  query totalStatisticsIncidentSectionWise($incidentStatisticsReq: IncidentStatisticsReqDtoInput) {
    totalStatisticsIncidentSectionWise(incidentStatisticsReq: $incidentStatisticsReq) {
      name
      issueCategory
      closed
      pending
    }
  }
`;

export const GET__CATEGORY_WISE_ISSUE_STATISTICS = gql`
  query totalStatisticsIncidentClosedRatio($incidentStatisticsReq: IncidentStatisticsReqDtoInput) {
    totalStatisticsIncidentClosedRatio(incidentStatisticsReq: $incidentStatisticsReq) {
      categoryId
      incidentStatus
      rowCount
      title
      todayCount
    }
  }
`;

export const GET_EXECUTIVE_SUMMARY = gql`
  query getExecutiveSummaryOfProviderInstitutions {
    getExecutiveSummaryOfProviderInstitutions {
      acronym
      name
      services
      tickets
      users
      uuid
    }
  }
`;

export const GET_INSTITUTION_TICKETS = gql`
  query getExecutiveSummaryOfSPIncidents {
    getExecutiveSummaryOfSPIncidents {
      closed
      draft
      institutionUuid
      open
    }
  }
`;

export const GET_SYSTEM_USERS_DETAILED_REPORT = gql`
  query getSystemUsersDetailedReport($fromDate: LocalDate, $exportDocType: ExportDocType, $allUsers: Boolean, $toDate: LocalDate, $institutionUuid: String, $registeredByInstitutionUuids: [String]) {
    getSystemUsersDetailedReport( fromDate: $fromDate, exportDocType: $exportDocType, allUsers: $allUsers, toDate: $toDate, institutionUuid: $institutionUuid, registeredByInstitutionUuids: $registeredByInstitutionUuids) {
        code
        message
        status
        data
    }
  }
`;

