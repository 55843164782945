import {Injectable} from '@angular/core';
import {AppState} from 'src/app/store/reducers';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {Apollo} from 'apollo-angular';
import * as fromActions from './incident-type.actions';
import * as fromGraphql from './incident-type.graphql';
import {map, switchMap} from 'rxjs/operators';
import {NotificationService} from 'src/app/services/notification.service';
import {ResponseCodeService} from 'src/app/services/response-code.service';
import {PaginationService} from 'src/app/services/pagination.service';


@Injectable()
export class IncidentTypeEffects {

    // getAllIncidentCategories
    getAllIncidentCategories$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getAllIncidentCategories),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.LIST_ALL_INCIDENT_TYPES,
                variables: {
                    pageParam: action?.pageparam
                },
                fetchPolicy: 'network-only',
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching  Incident Categories'),
                map(({data}: any) => {
                    if (data) {
                        const result = data?.getAllIncidentCategories;
                        this.paginationService.setPage(result);
                        this.store.dispatch(fromActions.loadIncidentTypes({incidentTypes: data?.getAllIncidentCategories?.content || []}));
                    }
                })
            );
        })
    ), {dispatch: false});

    // getIncidentCategoryByUuid
    getIncidentCategoryByUuid$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getIncidentCategoryByUuid),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.GET_INCIDENT_TYPE_BY_UUID,
                fetchPolicy: 'network-only',
                variables: {
                    uuid: action.uuid
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching  Incident Categories'),
                map(({data}: any) => {
                    if (data) {
                        if (data.getServiceById) {
                            this.store.dispatch(fromActions.upsertIncidentType({incidentType: data.getIncidentCategoryByUuid.data}));
                        } else {
                            // const error = this.responseCodesService.getCodeDescription(data.getServiceById);
                            this.notificationService.errorMessage('Operation failed: ' + data.getIncidentCategoryByUuid.code);
                        }
                    }
                })
            );
        })
    ), {dispatch: false});

    // createdUpdateIncidenceCategory
    createdUpdateIncidenceCategory$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.createdUpdateIncidenceCategory),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.CREATE_INCIDENT_TYPE,
                variables: {
                    incidentCategory: action.incidentCategory
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while saving  Incident Categories'),
                map(({data}: any) => {
                    if (data) {
                        if (data.createdUpdateIncidenceCategory.code === 9000) {
                            this.store.dispatch(fromActions.upsertIncidentType({incidentType: data.createdUpdateIncidenceCategory.data}));
                            this.notificationService.successMessage('Operation successful');
                        } else {
                            this.notificationService.errorMessage('Operation failed: ' + data.createdUpdateIncidenceCategory.code);
                        }
                    }
                })
            );
        })
    ), {dispatch: false});

    // deleteIncidentCategoryByUuid
    deleteIncidentCategoryByUuid$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.deleteIncidentCategoryByUuid),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.DELETE_INCIDENT_TYPE,
                variables: {
                    uuid: action.uuid
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while saving  Incident Categories'),
                map(({data}: any) => {
                    if (data) {
                        if (data.deleteIncidentCategoryByUuid.code === 9000) {
                            this.store.dispatch(fromActions.deleteIncidentType({id: action.id}));
                            this.notificationService.successMessage('Operation successful');
                        } else {
                            this.notificationService.errorMessage('Operation failed: ' + data.deleteIncidentCategoryByUuid.code);
                        }
                    }
                })
            );
        })
    ), {dispatch: false});

// getAllIncidentCategories
    getAllIncidentCategoriesByInstitutionID$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getAllIncidentCategoriesByInstitutionID),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.LIST_INCIDENT_TYPES_BY_INSTITUTION_UUID,
                variables: {
                    institutionUid: action.institutionUid,
                },
                fetchPolicy: 'network-only',
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching Incident Categories '),
                map(({data}: any) => {
                    //
                    if (data) {
                        let result: any = Object.values(data)[0];
                        this.store.dispatch(fromActions.loadIncidentTypes({incidentTypes: result?.dataList || []}));
                    }
                })
            );
        })
    ), {dispatch: false});

    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private store: Store<AppState>,
        private notificationService: NotificationService,
        private paginationService: PaginationService,
        private responseCodesService: ResponseCodeService
    ) {
    }

}
