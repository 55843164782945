import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { AuthUser } from './auth-user.model';

export const loadAuthUsers = createAction(
  '[AuthUser/API] Load AuthUsers',
  props<{ authUsers: AuthUser[] }>()
);

export const addAuthUser = createAction(
  '[AuthUser/API] Add AuthUser',
  props<{ authUser: AuthUser }>()
);

export const upsertAuthUser = createAction(
  '[AuthUser/API] Upsert AuthUser',
  props<{ authUser: AuthUser }>()
);

export const addAuthUsers = createAction(
  '[AuthUser/API] Add AuthUsers',
  props<{ authUsers: AuthUser[] }>()
);

export const upsertAuthUsers = createAction(
  '[AuthUser/API] Upsert AuthUsers',
  props<{ authUsers: AuthUser[] }>()
);

export const updateAuthUser = createAction(
  '[AuthUser/API] Update AuthUser',
  props<{ authUser: Update<AuthUser> }>()
);

export const updateAuthUsers = createAction(
  '[AuthUser/API] Update AuthUsers',
  props<{ authUsers: Update<AuthUser>[] }>()
);

export const deleteAuthUser = createAction(
  '[AuthUser/API] Delete AuthUser',
  props<{ id: string }>()
);

export const deleteAuthUsers = createAction(
  '[AuthUser/API] Delete AuthUsers',
  props<{ ids: string[] }>()
);

export const clearAuthUsers = createAction(
  '[AuthUser/API] Clear AuthUsers'
);

//////// Effects //////////

