<div class="portal-page pg-container">

    <div class="d-flex justify-content-center flex-column h-100 align-items-center">

        <div class="col page-content d-flex align-items-center justify-content-center expand-width "
             style="--position: 6">

            <div class="custom-shape-divider-bottom-1674201436">
                <svg data-name="Layer 1" preserveAspectRatio="none" viewBox="0 0 1200 120"
                     xmlns="http://www.w3.org/2000/svg">
                    <path class="shape-fill"
                          d="M741,116.23C291,117.43,0,27.57,0,6V120H1200V6C1200,27.93,1186.4,119.83,741,116.23Z"></path>
                </svg>
            </div>

            <div class="container scale-up" style="--position: 4">
                <div class="row d-flex align-items-center justify-content-center">
                    <!-- <div class="col-6 image-container">
                        <img class="poster" src="../../../../assets/images/online-test-icon.png" alt="Picture" srcset="">
                    </div> -->

                    <div class="col-12 text-center">
                        <div class="content-heading">
                            <div class="img-holder">
                                <img alt="emblem" class="emblem" src="../../../../assets/images/online-test-icon.png"/>
                            </div>
                            <div class="text system-name text-large text-bold">
                                {{ title }}
                            </div>
                        </div>
                        <div class="max-width-420 text-white mb-3 test">
                            <div *ngIf="isLogin" class="row move-up" style="--order: 1.5">
                                <form (ngSubmit)="login(staffLoginForm.value)" [formGroup]="staffLoginForm" autocomplete="off"
                                      novalidate>
                                    <div class="row">
                                        
                                        <mat-form-field appearance="outline"
                                                        class="fill-white text-dark bold-500 material-form col-md-12">
                                            <mat-label class="text-dark faded">Email</mat-label>
                                            <input class="bg-none rounded-slight" formControlName="username" matInput
                                                   placeholder="Username"/>
                                            <mat-icon class="top--5" matSuffix>email</mat-icon>
                                            <mat-error>
                                                <span *ngIf="!staffLoginForm.get('username').valid && staffLoginForm.get('username').touched"> Email is required</span>
                                            </mat-error>
                                        </mat-form-field>
        
                                        <mat-form-field appearance="outline" class="fill-white bold-500 text-dark material-form col-md-12 move-up has-hover-hide-show-stacked"
                                                        color="white"
                                                        style="--order: 2">
                                            <mat-label class="text-dark faded">Password</mat-label>
                                            <input [type]="showPassword ? 'text' : 'password'" class="rounded-slight" formControlName="password"
                                                   matInput placeholder="Password"
                                                   type="password"/>
                                            <div (click)="togglePasswordVisibility()" class="position-relative stack top--5"
                                                 matSuffix>
                                                <mat-icon
                                                        class="faded hover-show">{{showPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                                            </div>
                                            <mat-error>
                                                <span *ngIf="!staffLoginForm.get('password').valid && staffLoginForm.get('password').touched">Password is required</span>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
        
                                    <div class="row login-row">
                                        <div class="col-md-12 d-flex justify-content-end">
                                            <button [disabled]="!staffLoginForm.valid || loading" class="px-4 blue-btn custom-blue-btn" mat-button
                                                    matRipple type="submit">
                                                Log in
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>

    <span *ngIf="sessionExpired" class="session-expired">Session Timed Out</span>

    <div class="custom-shape-divider-bottom-1674226228">
        <svg data-name="Layer 1" preserveAspectRatio="none" viewBox="0 0 1200 120" xmlns="http://www.w3.org/2000/svg">
            <path class="shape-fill"
                  d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25"></path>
            <path class="shape-fill"
                  d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5"></path>
            <path class="shape-fill"
                  d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"></path>
        </svg>
    </div>
</div>


<div class="ega xs-hide">
    <div class="d-none d-md-block">
        <strong>&copy; {{ currentYear }} </strong>
        <strong>Online Self Assessment Tool (OSAT)</strong>
    </div>
    <div>
        <strong class="d-none d-md-block">
            The system is designed and developed by
        </strong>
        <a
                href="http://ega.go.tz"
                target="_blank"
                title="e-Government Authority"
        >
        <span class="d-md-none position-relative text-white" style="top: 2px !important">
          <strong class="pr-2">&copy; {{ currentYear }} </strong> | &nbsp;
        </span>
            <img alt="eGA" src="/assets/images/ega.png"/>
        </a>
    </div>
</div>
