export const baseGraphqlField = `
    id
    uuid
    active
    createdAt
    updatedAt
    deleted
`;

export const baseGraphqlFields = `
    id
    uuid
    active
    createdAt
    updatedAt
    deleted
`;

export const baseGraphqlField_ = `
    id
    active
    uid
    createdAt
    updatedAt
    deleted
`;


export const basePageableGraphqlField = `
    totalElements
    totalPages
    size
    last
    first
`;

export interface PageResult {
  totalElements?: number;
  totalPages?: number;
  size?: number;
  last?: boolean;
  first?: boolean;
  hasContent: boolean;
}

