<app-modal-header (closeModal)="closeModal($event)" [title]="title"></app-modal-header>

<div class="p-2">
    <mat-hint class="mb-2 mx-1">Current assigned roles: </mat-hint>
    <mat-chip-list *ngFor="let role of user?.rolesList">
        <mat-chip class="text-infxo">
        {{role?.displayName}}
        </mat-chip>
    </mat-chip-list>
</div>

<form (ngSubmit)="submitForm(assignRoleForm.value)" [formGroup]="assignRoleForm" autocomplete="off" novalidate>
    <mat-dialog-content>
        <div class="row">
        </div>
        <div class="row">
            <div class="col-md-12">
                <mat-form-field appearance="outline" class="material-form">
                    <mat-label>Roles</mat-label>
                    <mat-select formControlName="roleIds" multiple>
                        <mat-option *ngFor="let role of roles$ | async" [value]="role.id">
                            {{role.displayName}}
                        </mat-option>
                    </mat-select>
                    <mat-error>
                        <span *ngIf="!assignRoleForm.get('roleIds').valid && assignRoleForm.get('roleIds').touched && assignRoleForm.get('roleIds').errors.required">
                            Roles Field is Required
                        </span>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

    </mat-dialog-content>
    <div class="row">
        <div class="col-md-12">
            <div class="save-right">
                <button [disabled]="!assignRoleForm.valid" class="custom-btn green-btn" mat-raised-button type="submit">
            Save
            </button>
            </div>
        </div>
    </div>

</form>
