import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { ResourceCategory } from './resource-category.model';
import { PageableParam } from 'src/app/interfaces/global.interface';

export const loadResourceCategorys = createAction(
  '[ResourceCategory/API] Load ResourceCategorys',
  props<{ resourceCategorys: ResourceCategory[] }>()
);

export const addResourceCategory = createAction(
  '[ResourceCategory/API] Add ResourceCategory',
  props<{ resourceCategory: ResourceCategory }>()
);

export const upsertResourceCategory = createAction(
  '[ResourceCategory/API] Upsert ResourceCategory',
  props<{ resourceCategory: ResourceCategory }>()
);

export const addResourceCategorys = createAction(
  '[ResourceCategory/API] Add ResourceCategorys',
  props<{ resourceCategorys: ResourceCategory[] }>()
);

export const upsertResourceCategorys = createAction(
  '[ResourceCategory/API] Upsert ResourceCategorys',
  props<{ resourceCategorys: ResourceCategory[] }>()
);

export const updateResourceCategory = createAction(
  '[ResourceCategory/API] Update ResourceCategory',
  props<{ resourceCategory: Update<ResourceCategory> }>()
);

export const updateResourceCategorys = createAction(
  '[ResourceCategory/API] Update ResourceCategorys',
  props<{ resourceCategorys: Update<ResourceCategory>[] }>()
);

export const deleteResourceCategory = createAction(
  '[ResourceCategory/API] Delete ResourceCategory',
  props<{ id: number }>()
);

export const deleteResourceCategorys = createAction(
  '[ResourceCategory/API] Delete ResourceCategorys',
  props<{ ids: number[] }>()
);

export const clearResourceCategorys = createAction(
  '[ResourceCategory/API] Clear ResourceCategorys'
);

// Effect Actions
export const listMyInstitutionAllResourceCategorys = createAction(
  '[ResourceCategory/API] List My Institution All ResourceCategorys',
  props<{ pageableParam?: PageableParam }>()
);

export const listMyInstitutionAllResourceCategorysForList = createAction(
  '[ResourceCategory/API] List My Institution All ResourceCategorys For List',
  props<{ pageableParam?: PageableParam }>()
);

export const activateResourceCategory = createAction(
  '[ResourceCategory/API] Activate ResourceCategory',
  props<{ id: string}>()
);

export const modifyResourceCategory = createAction(
  '[ResourceCategory/API] Modify ResourceCategory Details',
  props<{ resourceCategory: ResourceCategory }>()
);


export const createResourceCategory = createAction(
  '[ResourceCategory/API] Create ResourceCategory',
  props<{ resourceCategory: ResourceCategory }>()
);

export const removeResourceCategory = createAction(
  '[ResourceCategory/API] Remove ResourceCategory By Id',
  props<{ uuid: string,id: number }>()
);

// export const getDefaultResourceCategory = createAction(
//   '[User/API] Get Default ResourceCategory'
// );
