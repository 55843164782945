import { IncidentResponse } from "src/app/store/entities/incident-response/incident-response.model";
import { Incident, IncidentAssignment } from "src/app/store/entities/incident/incident.model";

    export const getTikeckAge = (incident: Incident) => {
      //  
      if(incident.dateSubmitted){
        let intervalBtnCurrentDateAndSubmittedDate;
        const submitDate = new Date(incident.dateSubmitted);
  
        const year = submitDate.getFullYear();
        const month = submitDate.getMonth();
        const day = submitDate.getDate();
        const hour = submitDate.getHours();
        const minute = submitDate.getMinutes();
        const submittedDate = new Date(year, month, day, hour, minute);
  
        const currentDate = new Date();
        if( incident?.incidentStatus === 'ON_HOLD') {
          let freezAge = '';          
          let minsLeft = 0;
          let hrsLeft = 0;
          let daysLeft =0;
   
        let responseWithHoldStatusWithoutHoldDays =  (incident?.incidentResponses || []).find(e => e?.incidentStatus == 'ON_HOLD' && !e?.holdTimeInDays);
        if(responseWithHoldStatusWithoutHoldDays){
          const freezDate = new Date(responseWithHoldStatusWithoutHoldDays?.createdAt);
          const intervalBtnSubmittedToFreezDate = freezDate.getTime() - submittedDate.getTime();
          const intervalBtnCurrentDateAndFreezDate = currentDate.getTime() - freezDate.getTime();
          // const freezTime = intervalBtnCurrentDateAndFreezDate - intervalBtnSubmittedToFreezDate;
          if(intervalBtnCurrentDateAndFreezDate > 0){
            let s = intervalBtnCurrentDateAndFreezDate / 1000;
            let mins = s / 60;
            let hrs = mins / 60;
            let days = (hrs / 24) + getTotalOnHoldDays(incident?.incidentResponses);
            let secondsLeft = Math.floor(s % 60);
             minsLeft = Math.floor(mins % 60);
             hrsLeft = Math.floor(hrs % 24);
             daysLeft = Math.floor(days);
            
            freezAge = 'This ticket is on hold - Hold time: ' +`${daysLeft}d, ${hrsLeft}h, ${minsLeft}m`;
          } else {
            daysLeft = getTotalOnHoldDays(incident?.incidentResponses);
            freezAge = 'This ticket is on hold - Hold time: ' +`${daysLeft}d, ${hrsLeft}h, ${minsLeft}m`;
          }
        } else {  
          daysLeft = getTotalOnHoldDays(incident?.incidentResponses);
          freezAge = 'This ticket is on hold - Hold time: ' +`${daysLeft}d, ${hrsLeft}h, ${minsLeft}m`;
        }
          return freezAge;
        } else{
          if(incident?.dateResolved != null && incident?.dateReOpened == null){
            const resolveDate = new Date(incident.dateResolved);
            intervalBtnCurrentDateAndSubmittedDate = resolveDate.getTime() - submittedDate.getTime();
    
          }
          else if(incident?.dateReOpened != null && incident?.dateResolved != null) {
            const resolveDate = new Date(incident.dateResolved);
            const reopenDate = new Date(incident?.dateReOpened);
            const intervalBtnResolveDateAndSubmittedDate = resolveDate.getTime() - submittedDate.getTime();
            const timeIntervalAfterReopen = reopenDate.getTime() - resolveDate.getTime();
            intervalBtnCurrentDateAndSubmittedDate = timeIntervalAfterReopen + intervalBtnResolveDateAndSubmittedDate;
    
          } 
          else if(incident?.dateReOpened == null && incident?.dateResolved == null) {
          intervalBtnCurrentDateAndSubmittedDate = currentDate.getTime() - submittedDate.getTime();
    
        } 
          else{
             
          }
          if(intervalBtnCurrentDateAndSubmittedDate > 0){
            let s = intervalBtnCurrentDateAndSubmittedDate / 1000;
            let mins = s / 60;
            let hrs = mins / 60;
            let days = hrs / 24;
            let secondsLeft = Math.floor(s % 60);
            let minsLeft = Math.floor(mins % 60);
            let hrsLeft = Math.floor(hrs % 24);
            let daysLeft = Math.floor(days);
            // return `${daysLeft}d, ${hrsLeft}h, ${minsLeft}m, ${secondsLeft}s`;
            return `${daysLeft}d, ${hrsLeft}h, ${minsLeft}m`;
          } else {
            return 'Default';
          }
        }
      } else {
        return 'N/A'
      }
    }
    
    export const checkOverdue = (incident: Incident) => {
      let final: any;
       if(incident?.expectedClosedDate && incident?.incidentStatus != 'CLOSED'){
         let currentDate = new Date();     
         let expectedClosedDate = new Date(incident?.expectedClosedDate);
         let periodDifference = expectedClosedDate.getTime() - currentDate.getTime();
         if(periodDifference < 0){
           let periodDifferenceDate = Math.abs(periodDifference);
           let s = periodDifferenceDate / 1000;
           let mins = s / 60;
           let hrs = mins / 60;
           let days = hrs / 24;
           
           let secondsLeft = Math.floor(s % 60);
           let minsLeft = Math.floor(mins % 60);
           let hrsLeft = Math.floor(hrs % 24);
           let daysLeft = Math.floor(days);      
           final = { overdue: true, overDueTime: `${daysLeft}d, ${hrsLeft}h, ${minsLeft}m` };    
         } else {
           final = { overdue: false, overDueTime: null };
         }
         
         
       } else {
         final = { overdue: false, overDueTime: null };
       }
       return final;
     }
    
    export const timeTillResolved = (incident: Incident) => {
      let final: any;      
      let resolvedDate = incident?.incidentResponses?.find(i=>i.incidentStatus=='RESOLVED')?.createdAt; 
      
      if(resolvedDate){
        let submittedDate = new Date(incident?.dateSubmitted);     
        let newResolvedDate = new Date(resolvedDate);
        let periodDifference = newResolvedDate.getTime() - submittedDate.getTime();
         
         if(periodDifference > 0){
           let periodDifferenceDate = Math.abs(periodDifference);
           let s = periodDifferenceDate / 1000;
           let mins = s / 60;
           let hrs = mins / 60;
           let days = hrs / 24;
           
           let secondsLeft = Math.floor(s % 60);
           let minsLeft = Math.floor(mins % 60);
           let hrsLeft = Math.floor(hrs % 24);
           let daysLeft = Math.floor(days);      
           final = { resolved: true, resolvedTime: `${daysLeft}d, ${hrsLeft}h, ${minsLeft}m` };    
         } else {
           final = { resolved: false, resolvedTime: null };
         }         
         
       } else {
         final = { resolved: false, resolvedTime: null };
       }
       return final;
     }


     export const getTotalOnHoldDays = (response: IncidentResponse[]) => {
      let currentTotalHoldDays = 0;
       (response || []).forEach(e => {
       currentTotalHoldDays = currentTotalHoldDays + (e?.holdTimeInDays ? e.holdTimeInDays : 0);
     })
     return currentTotalHoldDays;
  }

export function checkReassignement(assignments: IncidentAssignment[]): any {
  return( assignments || [])?.find(e => ((!e.currentAssigned && e?.firstLevelAssignment) || (!e.currentAssigned && !e?.firstLevelAssignment))) ? true : false
}

export const check2ndLevelResponse = (responses: IncidentResponse[]) => {
   let response = (responses || [])?.find(e =>( !e.seen && e.responseType == 'SecondLevel' ));  
   
 return response;
} 
