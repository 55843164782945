import { createAction, props } from "@ngrx/store";
import { Update } from "@ngrx/entity";

import { User, UserDtoInput, UserFromOtherSystemDtoInput } from "./user.model";
import { PageableParam } from "src/app/interfaces/global.interface";

export const loadUsers = createAction("[User/API] Load Users", props<{ users: User[] }>());

export const addUser = createAction("[User/API] Add User", props<{ user: User }>());

export const upsertUser = createAction("[User/API] Upsert User", props<{ user: User }>());

export const addUsers = createAction("[User/API] Add Users", props<{ users: User[] }>());

export const upsertUsers = createAction("[User/API] Upsert Users", props<{ users: User[] }>());

export const updateUser = createAction("[User/API] Update User", props<{ user: Update<User> }>());

export const updateUsers = createAction("[User/API] Update Users", props<{ users: Update<User>[] }>());

export const deleteUser = createAction("[User/API] Delete User", props<{ id: number }>());

export const deleteUsers = createAction("[User/API] Delete Users", props<{ ids: string[] }>());

export const clearUsers = createAction("[User/API] Clear Users");

// Effect Actions
// User by Insitution
export const listAllUsersByInstitutionID = createAction("[User/API] List All Users By InstitutionId", props<{ institutionId?: string; pageable?: PageableParam; option?: string }>());
// props<{ pageable?: Pageable }>()

export const listAllUsers = createAction("[User/API] List All Users");

export const listAllUsersForList = createAction("[User/API] List All Users For List");
//  create institution user
export const createNewUser = createAction("[User/API] Create New  User", props<{ userDto: User }>());

export const createUpdateMyInstitutionUser = createAction("[User/API] Create New Institution User", props<{ userDto: User }>());

export const registerUsersInBatch = createAction("[User/API] register Users In Batch", props<{ usersFromOtherSystem: UserFromOtherSystemDtoInput[] }>());

export const importLdapUsers = createAction("[User/API] import LDAP users", props<{ institutionUuid?: string }>());

// Create institution Admins
export const CreateInstitutionAdmins = createAction("[User/API] Create Institution Admins", props<{ institutionId: number; userDto: User }>());

export const getApprovalStageUsers = createAction("[User/API] Get Institution Approval Stage Users", props<{ stageUuid: string }>());

export const createUpdateUserAccount = createAction("[User/API] Create or Update User", props<{ user: UserDtoInput }>());

export const modifyUser = createAction("[User/API] Modify User Details", props<{ id: number; user: User }>());

export const activateUser = createAction("[User/API] Activate User", props<{ id: number }>());

export const deactivateUser = createAction("[User/API] Deactivate User", props<{ uuid: string }>());

export const deleteUserDetails = createAction("[User/API] delete User Details", props<{ id: number }>());

export const removeUser = createAction("[User/API] Remove User", props<{ userId: number }>());

export const userDetailsByUuid = createAction("[User/API] Get User Details Using UUID", props<{ uuid: string }>()); ///==TOUCHED

export const getMyDetails = createAction("[User/API] Get My User Details");

export const listOfUserAccounts = createAction("[User/API] Get Institutionl Users");

export const getAllUsersByDepartment = createAction("[User/API] Get All Department Personnel", props<{ departmentId: string; pageParam?: PageableParam }>());

export const assignUsersToDepartment = createAction("[User/API] Assign Users to Department", props<{ userIds: number[]; departmentId: number }>());

export const assignHeadOfDepartment = createAction("[User/API] Assign Head Of Department", props<{ departmentId: string; userId: string }>());

export const getEmployeesByDepartmentCode = createAction("[User/API] Get Department Employees", props<{ departmentCode: string }>());

export const setMyDetail = createAction("[User/API] Set My Detail", props<{ detail: User[] }>());

export const assignRoleToUser = createAction("[User/API] Assign Role To User", props<{ roleIds: number[]; userUuid: string }>());

export const assignRoleToExternalUser = createAction("[User/API] Assign Role To external User", props<{ roleIds: number[]; userUuid: string }>());

export const getMyDepartmentUsers = createAction("[User/API] get dept Users", props<{ serviceUuid: string; branchUuid: string }>());

export const removeUserFromDepartment = createAction("[Roster/API] remove User fromroster", props<{ departmentId: string; userIds: string[] }>());

export const userInfoByUuid = createAction("[User/API] Get User Info By Uuid", props<{ uuid: string }>());

export const assignUsersToService = createAction("[Roster/API] assign Users To Service", props<{ userUuidList: string[]; serviceUuid: string }>());

export const getAllUsersAssignedToService = createAction("[User/API] Get Service Users", props<{ serviceUuid: string }>());

export const removeUsersFromService = createAction("[User/API] Remove User From Service", props<{ userUuidList: string[]; serviceUuid: string; id: number }>());

export const getAllUsersFoundInServiceGroup = createAction("[User/API] Get All Users Found In Service Group", props<{ groupUuid: string }>());

// export const userForgotPassword = createAction(
//   '[User/API] User ForgotPassword By User Email',
//   props<{ email: string }>()
// );

export const getNewMyDepartmentUsers = createAction(
  '[User/API] get new dept Users',
  props<{ departmentUuid?: string }>()
);

export const getTotalInstitutionUsers = createAction(
  '[User/API] get institution Users counts',
  props<{ institutionId: string, pageable?: PageableParam }>()
);

export const getNonEmployeeUsers = createAction(
  '[User/API] get other not employee Users By InstitutionId',
  props<{ institutionId: string, pageable: PageableParam, branchUuid?: string }>()
);

export const clientsUsersSubscribedForServicesInMyInst = createAction(
  '[User/API] get individual subscribed in my institution InstitutionId',
  props<{ search?: string, pageable?: PageableParam }>()
);

export const unsubscribeFromAllServices = createAction(
  '[User/API] unsubscribeFromAllServices',
  props<{ userUuid: string }>()
);
