// import { departmentFields } from './../department/department.graphql';
import gql from 'graphql-tag';
import { basePageableGraphqlField } from '../../base.graphql';

export const identificationFields = `
id
uuid
localClientCode
institutionService{
  id
  uuid
  name
}
institution{
  id
  uuid
  name
}
clientInstitution{
  id
  uuid
  name
}
`;

export const GET_CLIENT_IDENTIFICATIOS_BY_INSTITUTION = gql`

query findInstitutionClientsLocalCodes($searchTerm: String, $pageparam: PageableParamInput,$servicesUuids: [String],$institutionUuid: String){
  findInstitutionClientsLocalCodes(searterm: $searchTerm, pageableParams: $pageparam, servicesUuids: $servicesUuids, institutionUuid: $institutionUuid){
    content{
     ${identificationFields}
    }
    ${basePageableGraphqlField}
  }
}
`;

export const CREATE_CLIENT_IDENTIFICATION = gql`
mutation createUpdateInstitutionClientLocalCodes($input: [InstitutionClientLocalCodeDtoInput]){
  createUpdateInstitutionClientLocalCodes(clientLocalCodeDtos: $input){
    uuid
  }
}
`;
