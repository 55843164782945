import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {Apollo} from 'apollo-angular';
import {AppState} from '../../../reducers';
import {ResponseCodeService} from 'src/app/services/response-code.service';
import {Router} from '@angular/router';
import * as fromActions from './roster-groups.actions';
import * as fromGraphql from './roster-groups.graphql';
import {map, switchMap} from 'rxjs/operators';
import {NotificationService} from 'src/app/services/notification.service';


@Injectable()
export class GroupEffects {
    createUpdateServiceGroup;
// DELETE SERVICE GROUP
    deleteServiceGroup$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.removeServiceGroup),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.DELETE_SERVICE_GROUP,
                variables: {
                    groupUuid: action.groupUuid,
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while Deleting Service Group'),
                map(({data}: any) => {
                    if (data) {
                        const results = data.deleteServiceGroup;
                        if (results.code === 9000) {
                            this.notificationService.successMessage(' Service Group Deleted  Successfully');
                            this.store.dispatch(fromActions.deleteRosterGroups({id: action.id + ''}));
                        } else {
                            this.notificationService.errorMessage(results?.message + ': ' + results?.code);
                        }
                    }
                })
            );
        })
    ), {dispatch: false});


// DELETE SERVICE GROUP
    createUpdateServiceGroup$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.createUpdateServiceGroup),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.CREATE_UPDATE_SERVICE_GROUP,
                variables: {
                    groupDto: action.groupDto,
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while Creating Service Group'),
                map(({data}: any) => {
                    if (data) {
                        const result = data?.createUpdateServiceGroup;
                        if (result?.code === 9000) {
                            this.store.dispatch(fromActions.upsertRosterGroups({rosterGroups: result?.data}));
                            this.notificationService.successMessage(' Service Group Saved  Successfully');
                        } else {
                            const error =  this.responseCodesService?.getCodeDescription(result?.code);
                            this.notificationService.errorMessage( result.message ? result?.message :  error[0].description + result?.code);
                          
                        }
                    }
                })
            );
        })
    ), {dispatch: false});

    // List  service Groups
    getAllServiceGroups$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getAllServiceGroups),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.GET_ALL_SERVICE_GROUP,
                fetchPolicy: 'network-only',
            }).pipe(
                map(({data}: any) => {

                    if (data) {
                        return fromActions.loadRosterGroupss({rosterGroupss: data.getAllServiceGroups.content});
                    }
                })
            );
        })
    ));


    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private store: Store<AppState>,
        private notificationService: NotificationService,
        private responseCodesService: ResponseCodeService,
        private router: Router
    ) {
    }

}


