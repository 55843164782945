import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {Apollo} from "apollo-angular";
import {map} from "rxjs/operators";
import {NotificationService} from "src/app/services/notification.service";
import {ResponseCodeService} from "src/app/services/response-code.service";
import {AppState} from "src/app/store/reducers";
import * as fromInstitutionGraphql from "src/app/store/entities/settings/institution/institution.graphql";
import * as fromIncidentsGraphql from "src/app/store/entities/incident/incident.graphql";
import * as fromAssetsGraphql from "src/app/store/entities/asset-management/assetc.graphql";
import * as fromCallGraphql from "src/app/store/entities/calltracker/calltracker.graphql";
import {GET_INSTITUTION_STORAGE_USAGE} from "src/app/store/entities/settings/institution/institution.graphql";
import {InstitutionStorageDto} from "src/app/store/entities/settings/institution/institution.model";
import {CallRegistryStatisticsDto} from "src/app/store/entities/calltracker/calltracker.model";
import {
    StatisticsTotalResponseDto,
    StatisticsGraphInput,
    StatisticsGraphResponseDto,
    StatisticsSectionWiseTotalResponseDto,
    CategoryWiseIssueStatisticsResponseDto,
    StatusWiseIssueStatisticsResponseDto,
    GeneralDashboardReportResponseDto,
    ServiceProviderInstitutionDto,
    InstitutionTicketsDto,
} from "src/app/store/entities/incident/incident.model";
import {Observable} from "rxjs";
import {GET_SYSTEM_USERS_DETAILED_REPORT} from "src/app/store/entities/incident/incident.graphql";
import { GENERATE_EXAMINATION_REPORT, GENERATE_INSTITUTION_EXAMINATION_REPORT, GENERATE_INSTITUTION_REPORT } from "./reports.graphql";

@Injectable({
    providedIn: "root",
})
export class ReportsServicesService {
    constructor(
        private apollo: Apollo,
        private responseCodesService: ResponseCodeService,
        private notificationService: NotificationService,
        private store: Store<AppState>
    ) {
    }

    // ==================================*************************========================================
    //                              START REPORT MANAGEMENT GRAPGQL @mblaiser
    // =================================*************************=========================================

    incidentDetailedReports(
        issueTitleUuids: string[],
        fromDate: string,
        categoryUuid: [string],
        incidentOrigins: string[],
        exportDocType: string,
        filterByDateResolved: boolean,
        toDate: string,
        serviceUuids: string[],
        institutionUuid: string,
        departmentUuid: string,
        incidentStatus: string[]
    ) {
        return this.apollo
            .query({
                query: fromIncidentsGraphql.INCIDENT_DETAILED_REPORT,

                variables: {
                    issueTitleUuids: issueTitleUuids,
                    fromDate: fromDate,
                    categoryUuid: categoryUuid,
                    incidentOrigins: incidentOrigins,
                    exportDocType: exportDocType,
                    filterByDateResolved: filterByDateResolved,
                    toDate: toDate,
                    serviceUuids: serviceUuids,
                    institutionUuid: institutionUuid,
                    departmentUuid: departmentUuid,
                    incidentStatus: incidentStatus,
                },
                fetchPolicy: "network-only",
            })
            .pipe(
                this.notificationService.catchError("Problem occurred during rendering incident Detailed Reports"),
                map(({data}: any) => {
                    if (data) {
                        if (data?.getIncidentDetailedReport?.code == 9000) {
                            return data.getIncidentDetailedReport.data;
                        } else {
                            this.notificationService.errorMessage(
                                "Failed to get Document Report",
                                data?.getIncidentDetailedReport?.code
                            );
                            return null;
                        }
                    }
                })
            );
    }

    incidentStatisticalReports(
        issueTitleUuids: string,
        fromDate: string,
        incidentOrigins: string[],
        exportDocType: string,
        filterByDateResolved: boolean,
        toDate: string,
        serviceUuids: string[],
        institutionUuid: string,
        incidentCategoryUuids: [string],
        departmentUuid: string
    ) {
        return this.apollo
            .query({
                query: fromIncidentsGraphql.INCIDENT_STATISTICAL_REPORT,
                variables: {
                    issueTitleUuids: issueTitleUuids,
                    fromDate: fromDate,
                    incidentOrigins: incidentOrigins,
                    exportDocType: exportDocType,
                    filterByDateResolved: filterByDateResolved,
                    toDate: toDate,
                    serviceUuids: serviceUuids,
                    institutionUuid: institutionUuid,
                    incidentCategoryUuids: incidentCategoryUuids,
                    departmentUuid: departmentUuid,
                },
                fetchPolicy: "network-only",
            })
            .pipe(
                this.notificationService.catchError("Problem occurred during rendering incident Statistical Reports"),
                map(({data}: any) => {
                    if (data) {
                        if (data?.getIncidentStatisticsReport?.code == 9000) {
                            return data.getIncidentStatisticsReport.data;
                        } else {
                            this.notificationService.errorMessage(
                                "Failed to get Document Report",
                                data?.getIncidentStatisticsReport?.code
                            );
                            return null;
                        }
                    }
                })
            );
    }

    assetsRegisterReports(fromDate, categoryUuid, exportDocType, toDate) {
        return this.apollo
            .query({
                query: fromAssetsGraphql.GET_ASSETS_REGISTER_REPORT,

                variables: {
                    fromDate: fromDate,
                    categoryUuid: categoryUuid,
                    exportDocType: exportDocType,
                    toDate: toDate,
                },
                fetchPolicy: "network-only",
            })
            .pipe(
                this.notificationService.catchError("Problem occurred during rendering Assets Register Reports"),
                map(({data}: any) => {
                    if (data) {
                        if (data?.getAssetRegisterReport?.code == 9000) {
                            return data.getAssetRegisterReport.data;
                        } else {
                            this.notificationService.errorMessage(
                                "Failed to get Document Report",
                                data?.getAssetRegisterReport?.code
                            );
                            return null;
                        }
                    }
                })
            );
    }

    callRegistryDetailedReports(fromDate, institutionFromUuid, exportDocType, toDate, institutionServiceUuid) {
        return this.apollo
            .query({
                query: fromCallGraphql.GET_CALL_DETAILED_REPORT,

                variables: {
                    fromDate: fromDate,
                    institutionFromUuid: institutionFromUuid,
                    exportDocType: exportDocType,
                    toDate: toDate,
                    institutionServiceUuid: institutionServiceUuid,
                },
                fetchPolicy: "network-only",
            })
            .pipe(
                this.notificationService.catchError("Problem occurred during rendering Call Registry Detailed Reports"),
                map(({data}: any) => {
                    if (data) {
                        if (data?.getCallRegisterSummaryReport?.code == 9000) {
                            return data.getCallRegisterSummaryReport.data;
                        } else {
                            this.notificationService.errorMessage(
                                "Failed to get Document Report",
                                data?.getCallRegisterSummaryReport?.code
                            );
                            return null;
                        }
                    }
                })
            );
    }

    callTrackerStatisticalReports(fromDate, institutionFromUuid, exportDocType, toDate) {
        return this.apollo
            .query({
                query: fromCallGraphql.CALL_TRACKER_STATISTICAL_REPORT,

                variables: {
                    fromDate: fromDate,
                    institutionFromUuid: institutionFromUuid,
                    exportDocType: exportDocType,
                    toDate: toDate,
                },
                fetchPolicy: "network-only",
            })
            .pipe(
                this.notificationService.catchError("Problem occurred during rendering Call Registry Statistical Reports"),
                map(({data}: any) => {
                    if (data) {
                        if (data?.getCallRegisterStatisticsReport?.code == 9000) {
                            return data.getCallRegisterStatisticsReport.data;
                        } else {
                            this.notificationService.errorMessage(
                                "Failed to get Document Report",
                                data?.getCallRegisterStatisticsReport?.code
                            );
                            return null;
                        }
                    }
                })
            );
    }

    institutionReport() {
        return this.apollo
            .query({
                query: GENERATE_INSTITUTION_REPORT,

                variables: {
                    reportFormat: "PDF"
                },
                fetchPolicy: "network-only",
            })
            .pipe(
                // this.notificationService.catchError("Problem occurred during rendering Call Registry Statistical Reports"),
                map(({data}: any) => {
                    if (data) {
                        if (data?.generateInstitutionReport?.code == 9000) {
                            return data.generateInstitutionReport.data;
                        } else {
                            this.notificationService.errorMessage(
                                "Failed to get Document Report",
                                data?.generateInstitutionReport?.code
                            );
                            return null;
                        }
                    }
                })
            );
    }

    examinationReport() {
        return this.apollo
            .query({
                query: GENERATE_EXAMINATION_REPORT,

                variables: {
                    reportFormat: "PDF"
                },
                fetchPolicy: "network-only",
            })
            .pipe(
                // this.notificationService.catchError("Problem occurred during rendering Call Registry Statistical Reports"),
                map(({data}: any) => {
                    if (data) {
                        if (data?.generateExaminationReport?.code == 9000) {
                            return data.generateExaminationReport.data;
                        } else {
                            this.notificationService.errorMessage(
                                "Failed to get Document Report",
                                data?.generateExaminationReport?.code
                            );
                            return null;
                        }
                    }
                })
            );
    }

    institutionExaminationReport(startDate,endDate) {
        return this.apollo
            .query({
                query: GENERATE_INSTITUTION_EXAMINATION_REPORT,

                variables: {
                    reportFormat: "PDF",
                    startDate: startDate,
                    endDate: endDate
                },
                fetchPolicy: "network-only",
            })
            .pipe(
                // this.notificationService.catchError("Problem occurred during rendering Call Registry Statistical Reports"),
                map(({data}: any) => {
                    if (data) {
                        if (data?.generateInstitutionExaminationReport?.code == 9000) {
                            return data.generateInstitutionExaminationReport.data;
                        } else {
                            this.notificationService.errorMessage(
                                "Failed to get Document Report",
                                data?.generateInstitutionExaminationReport?.code
                            );
                            return null;
                        }
                    }
                })
            );
    }

    





      

    // ==================== ****************** END *****************=========================================

    // ==================================*************************========================================
    //                            START REPORT DASHBOARD GRAPGQL @mblaiser
    // =================================*************************=========================================

    primaryStatisticalSummery() {
        return this.apollo
            .query({
                query: fromIncidentsGraphql.GENERAL_STATISTICAL_REPORT_SUMMERY,
                fetchPolicy: "network-only",
            })
            .pipe(
                this.notificationService.catchError("Problem occurred during rendering Dashboard Statistical Summery Data"),
                map(({data}: any) => {
                    if (data) {
                        return data.getDashboardData;
                    }
                })
            );
    }

    providerPrimaryStatisticalSummery() {
        return this.apollo
            .query({
                query: fromIncidentsGraphql.PROVIDER_GENERAL_STATISTICAL_REPORT_SUMMERY,
                fetchPolicy: "network-only",
            })
            .pipe(
                this.notificationService.catchError("Problem occurred during rendering Dashboard Statistical Summery Data"),
                map(({data}: any) => {
                    if (data) {
                        return data.getDashboardData;
                    }
                })
            );
    }

    generalDashboardReportsData() {
        return this.apollo
            .query({
                query: fromIncidentsGraphql.GENERAL_DASHBOARD_REPORT,
                fetchPolicy: "network-only",
            })
            .pipe(
                this.notificationService.catchError("Problem occurred during rendering Dashboard Report Data"),
                map(({data}: any) => {
                    if (data) {
                        return data.getIncidentDashboard;
                    }
                })
            );
    }

    generalCallDashboardData() {
        return this.apollo
            .query({
                query: fromCallGraphql.GET_CALL_DASHBOARD_DATA,
                fetchPolicy: "network-only",
            })
            .pipe(
                this.notificationService.catchError("Problem occurred during rendering Call Dashboard Data"),
                map(({data}: any) => {
                    if (data) {
                        return data.getGraphData;
                    }
                })
            );
    }

    // ==================== ****************** END *****************=========================================

    getInstitutionalStorageUsage(institutionUid: string): Promise<InstitutionStorageDto> {
        return this.apollo
            .query({
                query: GET_INSTITUTION_STORAGE_USAGE,
                fetchPolicy: "network-only",
                variables: {
                    institutionUuid: institutionUid,
                },
            })
            .pipe(
                this.notificationService.catchError("Problem occurred during rendering Storage usage data"),
                map(({data}: any) => {
                    if (data) {
                        const result: InstitutionStorageDto = data.institutionStorageUsage?.data;
                        return result;
                    }
                })
            )
            .toPromise();
    }

    async directoratesWithSubDirectoratesData(parentDepartment): Promise<any> {
        return this.apollo
            .query({
                query: fromInstitutionGraphql.GET_INSTITUTION_DEPARTMENT_SELECT,
                fetchPolicy: "network-only",
                variables: {
                    parentDepartment: parentDepartment,
                },
            })
            .pipe(
                this.notificationService.catchError("Problem occurred during rendering Institution Departments Data"),
                map(({data}: any) => {
                    if (data) {
                        return data.getAllDepartments;
                    }
                })
            )
            .toPromise();
    }

    async getCallRegistryStatistics(): Promise<CallRegistryStatisticsDto> {
        return this.apollo
            .query({
                query: fromCallGraphql.GET_CALL_REGISTRY_STATISTICS,
                fetchPolicy: "network-only",
            })
            .pipe(
                this.notificationService.catchError("Problem occurred during rendering call registry statistics"),
                map(({data}: any) => {
                    if (data) {
                        const result: CallRegistryStatisticsDto = data.getCallRegistryStatistics?.data;
                        return result;
                    }
                })
            )
            .toPromise();
    }

    async getTotalIssueStatistics(institutionUuid?: string): Promise<StatisticsTotalResponseDto[]> {
        return this.apollo
            .query({
                query: fromIncidentsGraphql.GET_TOTAL_ISSUE_STATISTICS,
                fetchPolicy: "network-only",
                variables: {
                    institutionUuid,
                },
            })
            .pipe(
                this.notificationService.catchError("Problem occurred during rendering call registry statistics"),
                map(({data}: any) => {
                    if (data) {
                        const result: StatisticsTotalResponseDto[] = data.totalStatisticsListIncident;
                        return result;
                    }
                })
            )
            .toPromise();
    }

    async getTotalIssueStatisticsGraphData(
        incidentStatisticsReq?: StatisticsGraphInput
    ): Promise<StatisticsGraphResponseDto> {
        return this.apollo
            .query({
                query: fromIncidentsGraphql.GET_ISSUE_CHART_STATISTICS,
                fetchPolicy: "network-only",
                variables: {
                    incidentStatisticsReq,
                },
            })
            .pipe(
                this.notificationService.catchError("Problem occurred during rendering call registry statistics"),
                map(({data}: any) => {
                    if (data) {
                        const result: StatisticsGraphResponseDto = data.totalStatisticsIncidentGraph.data;
                        return result;
                    }
                })
            )
            .toPromise();
    }

    async getTotalIssueServiceWiseStatisticsGraphData(
        incidentStatisticsReq?: StatisticsGraphInput
    ): Promise<StatisticsTotalResponseDto[]> {
        return this.apollo
            .query({
                query: fromIncidentsGraphql.GET_ISSUE_SERVICE_STATISTICS,
                fetchPolicy: "network-only",
                variables: {
                    incidentStatisticsReq,
                },
            })
            .pipe(
                this.notificationService.catchError("Problem occurred during rendering call service issues statistics"),
                map(({data}: any) => {
                    if (data) {
                        const result: StatisticsTotalResponseDto[] = data.totalStatisticsIncidentServiceWise;
                        return result;
                    }
                })
            )
            .toPromise();
    }

    async getTotalIssueSectionWiseStatisticsGraphData(
        incidentStatisticsReq?: StatisticsGraphInput
    ): Promise<StatisticsSectionWiseTotalResponseDto[]> {
        return this.apollo
            .query({
                query: fromIncidentsGraphql.GET__SECTION_WISE_ISSUE_STATISTICS,
                fetchPolicy: "network-only",
                variables: {
                    incidentStatisticsReq,
                },
            })
            .pipe(
                this.notificationService.catchError("Problem occurred during rendering call service issues statistics"),
                map(({data}: any) => {
                    if (data) {
                        const result: StatisticsSectionWiseTotalResponseDto[] = data.totalStatisticsIncidentSectionWise;
                        return result;
                    }
                })
            )
            .toPromise();
    }

    async getCategoryWiseIssueStatistics(
        incidentStatisticsReq?: StatisticsGraphInput
    ): Promise<CategoryWiseIssueStatisticsResponseDto[]> {
        return this.apollo
            .query({
                query: fromIncidentsGraphql.GET__CATEGORY_WISE_ISSUE_STATISTICS,
                fetchPolicy: "network-only",
                variables: {
                    incidentStatisticsReq,
                },
            })
            .pipe(
                this.notificationService.catchError("Problem occurred during rendering call service issues statistics"),
                map(({data}: any) => {
                    if (data) {
                        const result: CategoryWiseIssueStatisticsResponseDto[] = data.totalStatisticsIncidentClosedRatio;
                        return result;
                    }
                })
            )
            .toPromise();
    }

    async providerGeneralDashboardReportsData(uid: string): Promise<StatusWiseIssueStatisticsResponseDto> {
        return this.apollo
            .query({
                query: fromIncidentsGraphql.PROVIDER_GENERAL_DASHBOARD_REPORT,
                fetchPolicy: "network-only",
                variables: {
                    institutionUuid: uid,
                },
            })
            .pipe(
                this.notificationService.catchError("Problem occurred during rendering call service issues statistics"),
                map(({data}: any) => {
                    if (data) {
                        const result: StatusWiseIssueStatisticsResponseDto = data.getIncidentDashboardByUuid.data;
                        return result;
                    }
                })
            )
            .toPromise();
    }

    async getTotalAssetsStatsData(): Promise<GeneralDashboardReportResponseDto> {
        return this.apollo
            .query({
                query: fromIncidentsGraphql.GENERAL_DASHBOARD_REPORT,
                fetchPolicy: "network-only",
            })
            .pipe(
                this.notificationService.catchError("Problem occurred during rendering call service issues statistics"),
                map(({data}: any) => {
                    if (data) {
                        const result: GeneralDashboardReportResponseDto = data.getIncidentDashboard.data;
                        return result;
                    }
                })
            )
            .toPromise();
    }

    getExecutiveSummaryData(): Observable<ServiceProviderInstitutionDto[]> {
        return this.apollo
            .query({
                query: fromIncidentsGraphql.GET_EXECUTIVE_SUMMARY,
                fetchPolicy: "network-only",
            })
            .pipe(
                this.notificationService.catchError("Problem occurred during rendering call service issues statistics"),
                map(({data}: any) => {
                    return data.getExecutiveSummaryOfProviderInstitutions;
                })
            );
    }

    getInstitutionTicketsData(): Observable<InstitutionTicketsDto[]> {
        return this.apollo
            .query({
                query: fromIncidentsGraphql.GET_INSTITUTION_TICKETS,
                fetchPolicy: "network-only",
            })
            .pipe(
                this.notificationService.catchError("Problem occurred during rendering call service issues statistics"),
                map(({data}: any) => {
                    return data.getExecutiveSummaryOfSPIncidents;
                })
            );
    }

    getSystemUsersDetailedReport(
        fromDate,
        exportDocType,
        allUsers,
        toDate,
        institutionUuid,
        registeredByInstitutionUuids
    ): Observable<any> {
        return this.apollo
            .query({
                query: fromIncidentsGraphql.GET_SYSTEM_USERS_DETAILED_REPORT,
                variables: {
                    fromDate,
                    exportDocType,
                    allUsers,
                    toDate,
                    institutionUuid,
                    registeredByInstitutionUuids
                },
                fetchPolicy: 'network-only',
            })
            .pipe(
                this.notificationService.catchError('Problem occurred during rendering'),
                map(({data}: any) => {
                    if (data) {
                        if (data?.getSystemUsersDetailedReport?.code === 9000) {
                            return data.getSystemUsersDetailedReport.data;
                        } else {
                            this.notificationService.errorMessage(
                                'Failed to get Document Report',
                                data?.getSystemUsersDetailedReport?.code
                            );
                            return null;
                        }
                    }
                })
            );
    }

}
