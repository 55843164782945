
<mat-card>
    <mat-card-title>Donought Graph</mat-card-title>
    <mat-divider></mat-divider>
    <mat-card-content>
      <div style="display: block;">
        <canvas baseChart 
          [data]="doughnutChartData"
          [labels]="doughnutChartLabels"
          [chartType]="doughnutChartType"
          >
        </canvas>
      </div>  
    </mat-card-content>
</mat-card>
