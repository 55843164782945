import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {StoreModule} from '@ngrx/store';
import {metaReducers, reducers} from './store/reducers';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {environment} from '../environments/environment';
import {EffectsModule} from '@ngrx/effects';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {GraphQLModule} from './graphql.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {SharedModule} from './shared/shared.module';
import {NgHttpLoaderModule} from 'ng-http-loader';
import {NgxPermissionsModule} from 'ngx-permissions';
import {DatePipe} from '@angular/common';
import {AuthInterceptor} from './interceptors/auth-interceptor';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {InstitutionEffects} from './store/entities/settings/institution/institution.effects';
import {RoleEffects} from './store/entities/settings/role/role.effects';
import {PermissionEffects} from './store/entities/settings/permission/permission.effects';
import {UserEffects} from './store/entities/settings/user/user.effects';
import {DepartmentEffects} from './store/entities/settings/department/department.effects';
import {DesignationEffects} from './store/entities/settings/designation/designation.effects';
import {OnlineResourceEffects} from './store/entities/settings/online-resource/online-resource.effects';
import {IncidentTypeEffects} from './store/entities/settings/incident-type/incident-type.effects';
import {IncidentEffects} from './store/entities/incident/incident.effects';
import {ServiceEffects} from './store/entities/settings/service/service.effects';
import {SeverityEffects} from './store/entities/settings/severity/severity.effects';
import {ChangeManagementEffects} from './store/entities/change-management/change-management.effects';
import {SolutionStepEffects} from './store/entities/solution-step/solution-step.effects';
import {RosterEffects} from './store/entities/roster/roster.effects';
import {ResetPasswordComponent} from './guest/reset-password/reset-password.component';
import {ChangePasswordComponent} from './shared/components/change-password/change-password.component';
import {IncidentTrailEffects} from './store/entities/incident-trail/incident-trail.effects';
import {IncidentAssignmentEffects} from './store/entities/incident-assignment/incident-assignment.effects';
import {LandingComponent} from './modules/landing/landing.component';
import {TestingComponent} from './guest/testing/testing.component';
import {HelpdeskEmailNotificationEffects} from './store/entities/helpdesk-email-notification/helpdesk-email-notification.effects';
import {CalltrackerEffects} from './store/entities/calltracker/calltracker.effects';
import {CalltrackerSupportEffects} from './store/entities/calltracker-support/calltracker-support.effects';
import {BranchEffects} from './store/entities/settings/branch/branch.effects';
import {ServiceTypeEffects} from './store/entities/service-type/service-type.effects';
import {PageableEffects} from './store/entities/pageable/pageable.effects';

import {AdministrativeAreaEffects} from './store/entities/settings/administrative-area/administrative-area.effects';
import {CategoryEffects} from './store/entities/settings/category/category.effects';
import {SupplierEffects} from './store/entities/settings/supplier/supplier.effects';
import {ManufactureEffects} from './store/entities/settings/manufacture/manufacture.effects';
import {ResourceCategoryEffects} from './store/entities/settings/resource-category/resource-category.effects';
import {ResourceTypeEffects} from './store/entities/settings/resource-type/resource-type.effects';
import {AssetcEffects} from './store/entities/asset-management/assetc.effects';
import {ResourceCriticalityEffects} from './store/entities/settings/resource-criticality/resource-criticality.effects';
import {RequestCategoryEffects} from './store/entities/settings/request-category/request-category.effects';
import {RequestPriorityEffects} from './store/entities/settings/request-priority/request-priority.effects';
import {ChangeRequestEffects} from './store/entities/change-request/change-request.effects';
import {RequestSeverityEffects} from './store/entities/settings/request-severity/request-severity.effects';
import {ApprovalStageEffects} from './store/entities/settings/approval-stage/approval-stage.effects';
import {ApprovalLinkageEffects} from './store/entities/settings/approval-linkage/approval-linkage.effects';
import {KbWorkflowEffects} from './store/entities/kb-workflow/kb-workflow.effects';
import {AttachmentEffects} from './store/entities/attachment/attachment.effects';
import {IncidentResponseEffects} from './store/entities/incident-response/incident-response.effects';
import {IssueCategoryEffects} from './store/entities/settings/issue-category/issue-category.effects';
import {ConversationEffects} from './store/entities/conversation/conversation.effects';
import {PriorityEffects} from './store/entities/priority/priority.effects';
import {MailConfigEffects} from './store/entities/settings/mail-config/mail-config.effects';
import {MailsEffects} from './store/entities/mails/mails.effects';
import {NotificationEffects} from './store/entities/notification/notification.effects';
import {GroupEffects} from './store/entities/settings/roster-groups/roster-groups.effects';
import {DigitOnlyDirective} from './shared/directives/digit-only.directive';
import {PageEffects} from './store/entities/page/page.effects';
import {LoginComponent} from './guest/login/login.component';
import {OldLoginComponent} from './guest/__login/login.component';
import {ClientIdentificationEffects} from './store/entities/client-identification/client-identification.effects';
import {LoginNwComponent} from './guest/login-nw/login-nw.component';
import {IncidentRootCauseEffects} from './store/entities/incident-root-cause/incident-root-cause.effects';
import {IncidentResolutioneEffects} from './store/entities/incident-resolution/incident-resolutione.effects';
import {LdapConfigurationEffects} from './store/entities/ldap-configuration/ldap-configuration.effects';
import { TascoIsceGroupEffects } from './store/entities/tasco-isce-group/tasco-isce-group.effects';
import { TestEffects } from './store/entities/aptitude-test/test/test.effects';
import { MaterialFolderEffects } from './store/entities/material-folder/material-folder.effects';
import { MaterialFileEffects } from './store/entities/material-folder/material-file/material-file.effects';

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        ResetPasswordComponent,
        ChangePasswordComponent,
        LandingComponent,
        TestingComponent,
        DigitOnlyDirective,
        OldLoginComponent,
        LoginNwComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        StoreModule.forRoot(reducers, {
            metaReducers
        }),
        !environment.production ? StoreDevtoolsModule.instrument({maxAge: 25, logOnly: environment.production}) : [],
        EffectsModule.forRoot([
            InstitutionEffects,
            RoleEffects,
            IncidentAssignmentEffects,
            PermissionEffects,
            UserEffects,
            CalltrackerEffects,
            CalltrackerSupportEffects,
            DepartmentEffects,
            DesignationEffects,
            IncidentTypeEffects,
            IncidentEffects,
            ServiceEffects,
            OnlineResourceEffects,
            SeverityEffects,
            ChangeManagementEffects,
            RosterEffects,
            IncidentTrailEffects,
            IncidentResponseEffects,
            SolutionStepEffects,
            HelpdeskEmailNotificationEffects,
            PageableEffects,
            AdministrativeAreaEffects,
            CategoryEffects,
            SupplierEffects,
            ManufactureEffects,
            ResourceCategoryEffects,
            ResourceTypeEffects,
            ResourceCriticalityEffects,
            AssetcEffects,
            RequestCategoryEffects,
            RequestPriorityEffects,
            ChangeRequestEffects,
            RequestSeverityEffects,
            ApprovalStageEffects,
            ApprovalLinkageEffects,
            IssueCategoryEffects,
            AttachmentEffects,
            ConversationEffects,
            PriorityEffects,
            MailConfigEffects,
            MailsEffects,
            GroupEffects,
            NotificationEffects,
            PageEffects,
            ClientIdentificationEffects,
            TascoIsceGroupEffects,
            TestEffects,
            
        ]),
        BrowserAnimationsModule,
        GraphQLModule,
        HttpClientModule,
        NgHttpLoaderModule.forRoot(),
        NgxPermissionsModule.forRoot(),
        SharedModule,
        EffectsModule.forFeature([
            BranchEffects, ServiceTypeEffects, IncidentTypeEffects,
            KbWorkflowEffects, IncidentRootCauseEffects, IncidentResolutioneEffects, LdapConfigurationEffects, MaterialFolderEffects, MaterialFileEffects]),
    ],
    providers: [
        DatePipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
