import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayToString',
})
export class ArrayToStringPipe implements PipeTransform {
  transform(array: string[], n?: number): string {
    if(!Array.isArray(array)) return array;
    let str = array.map(s=>" "+s).toString();
    str = str.substring(0,str.lastIndexOf(',')) + " and" + str.substring(str.lastIndexOf(',')+1);
    return str;
  }
}
