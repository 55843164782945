import {Component, OnInit} from '@angular/core';
import {StorageService} from '../../services/storage.service';
import {Subscription} from 'rxjs';
import {AuthService} from 'src/app/services/auth.service';
import {Store} from '@ngrx/store';
import {AppState} from 'src/app/store/reducers';
import {IncidentReportingType, MenuItem} from 'src/app/interfaces/global.interface';
import {fadeSmooth, PAGE_ANIMATIONS} from 'src/app/shared/animations/router-animation';

@Component({
    selector: 'app-home',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.scss'],
    animations: [fadeSmooth, PAGE_ANIMATIONS]
})
export class LandingComponent implements OnInit {

    subscription = new Subscription();
    InstitutionID: string;
    institutionName: any;
    incidenType: string;
    menu: MenuItem[];

    systemTitle: string;

    token: string='1';

    constructor(
        private authService: AuthService,
        storage: StorageService,
        private store: Store<AppState>,
    ) {
        this.institutionName = storage.getItem('institutionName');
        this.incidenType = IncidentReportingType.external.toUpperCase();
    }

    ngOnInit(): void {

        this.systemTitle = 'Online Self Assessment Tool (OSAT)';

        this.menu = [

            {
                name: 'Aptitude Test',
                route: '/aptitude-test',
                icon: 'gisp_checklist',
                iconType: 'SVG',
                permission: ['ROLE_VIEW_APTITUDE_TEST_DASHBOARD']
            },
            {
                name: 'Institution',
                route: '/institution',
                icon: 'osat_institution',
                iconType: 'SVG',
                permission: ['ROLE_INSTITUTION_MANAGEMENT']
            },
            {
                name: 'Settings',
                route: '/settings',
                icon: 'settings',
                iconType: 'SVG',
                permission: ['ROLE_VIEW_SETTINGS_DASHBOARD']
            },
            {
                name: 'Reports',
                route: '/reports',
                icon: 'osat_chart',
                iconType: 'SVG',
                permission: ['ROLE_VIEW_REPORTS_DASHBOARD']
            },
            {
                name: 'Knowledge Base',
                route: '/knowledge-base',
                icon: 'notes',
                iconType: 'SVG',
                // permission: ['ROLE_VIEW_COURSE_MATERIALS']
            }
        ]
    }

    logout() {
        this.authService.logout();
    }

}
