import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Priority } from './priority.model';
import {PageableParam} from "../../../interfaces/global.interface";

export const loadPrioritys = createAction(
  '[Priority/API] Load Prioritys', 
  props<{ prioritys: Priority[] }>()
);

export const addPriority = createAction(
  '[Priority/API] Add Priority',
  props<{ priority: Priority }>()
);

export const upsertPriority = createAction(
  '[Priority/API] Upsert Priority',
  props<{ priority: Priority }>()
);

export const addPrioritys = createAction(
  '[Priority/API] Add Prioritys',
  props<{ prioritys: Priority[] }>()
);

export const upsertPrioritys = createAction(
  '[Priority/API] Upsert Prioritys',
  props<{ prioritys: Priority[] }>()
);

export const updatePriority = createAction(
  '[Priority/API] Update Priority',
  props<{ priority: Update<Priority> }>()
);

export const updatePrioritys = createAction(
  '[Priority/API] Update Prioritys',
  props<{ prioritys: Update<Priority>[] }>()
);

export const deletePriority = createAction(
  '[Priority/API] Delete Priority',
  props<{ id: number }>()
);

export const deletePrioritys = createAction(
  '[Priority/API] Delete Prioritys',
  props<{ ids: string[] }>()
);

export const clearPrioritys = createAction(
  '[Priority/API] Clear Prioritys'
);

export const listAllInstitutionPrioritys = createAction(
  '[Priority/API] list all institution Prioritys',
  props<{ pageParam: PageableParam }>()
);

export const removePriority = createAction(
  '[Priority/API] remove Priority',
  props<{ priorityUuid: string, id: number }>()
);

