import { Component, OnInit, HostBinding, Output, EventEmitter, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { fadeIn } from 'src/app/shared/animations/router-animation';
import { FieldConfig } from '../field.interface';
@Component({
  selector: 'app-button',
  template: `
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
    </div>
    <div class="col-sm-12 p-0" style="text-align: right;" *ngIf="!loading" @fadeIn [formGroup]="group">
      <button @fadeIn type="submit"  class="fa-pull-right" [disabled] = "group?.invalid"
        mat-raised-button color="primary">{{field?.label}}</button>
        <button type="button" @fadeIn *ngIf="field?.showCancel" class="fa-pull-right" mat-button color="warn"
        (click)="closeForm()">Cancel</button>
    </div>
  </div>

</div>

`,

  styles: [],
  animations: [fadeIn]
})
export class ButtonComponent implements OnInit {
  @Input() field?: FieldConfig;
  @Input() group: UntypedFormGroup = new UntypedFormGroup({});
  @Input() loading = false;
  @Output() cancelForm = new EventEmitter();
  constructor() { }

  ngOnInit() {

  }
  closeForm() {
    this.cancelForm.emit();
  }
}
