import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { RequestPriority } from './request-priority.model';
import { PageableParam } from 'src/app/interfaces/global.interface';

export const loadRequestPrioritys = createAction(
  '[RequestPriority/API] Load RequestPrioritys',
  props<{ requestPrioritys: RequestPriority[] }>()
);

export const addRequestPriority = createAction(
  '[RequestPriority/API] Add RequestPriority',
  props<{ requestPriority: RequestPriority }>()
);

export const upsertRequestPriority = createAction(
  '[RequestPriority/API] Upsert RequestPriority',
  props<{ requestPriority: RequestPriority }>()
);

export const addRequestPrioritys = createAction(
  '[RequestPriority/API] Add RequestPrioritys',
  props<{ requestPrioritys: RequestPriority[] }>()
);

export const upsertRequestPrioritys = createAction(
  '[RequestPriority/API] Upsert RequestPrioritys',
  props<{ requestPrioritys: RequestPriority[] }>()
);

export const updateRequestPriority = createAction(
  '[RequestPriority/API] Update RequestPriority',
  props<{ requestPriority: Update<RequestPriority> }>()
);

export const updateRequestPrioritys = createAction(
  '[RequestPriority/API] Update RequestPrioritys',
  props<{ requestPrioritys: Update<RequestPriority>[] }>()
);

export const deleteRequestPriority = createAction(
  '[RequestPriority/API] Delete RequestPriority',
  props<{ id: number }>()
);

export const deleteRequestPrioritys = createAction(
  '[RequestPriority/API] Delete RequestPrioritys',
  props<{ ids: number[] }>()
);

export const clearRequestPrioritys = createAction(
  '[RequestPriority/API] Clear RequestPrioritys'
);

// Effect Actions
export const listMyInstitutionAllRequestPrioritys = createAction(
  '[RequestPriority/API] List My Institution All RequestPrioritys',
  props<{ pageableParam?: PageableParam }>()
);

export const activateRequestPriority = createAction(
  '[RequestPriority/API] Activate RequestPriority',
  props<{ id: string}>()
);

export const modifyRequestPriority = createAction(
  '[RequestPriority/API] Modify RequestPriority Details',
  props<{ requestPriority: RequestPriority }>()
);


export const createRequestPriority = createAction(
  '[RequestPriority/API] Create RequestPriority',
  props<{ requestPriority: RequestPriority }>()
);

export const removeRequestPriority = createAction(
  '[RequestPriority/API] Remove RequestPriority By Id',
  props<{ id: number }>()
);

// export const getDefaultRequestPriority = createAction(
//   '[User/API] Get Default RequestPriority'
// );
