import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { CourseMaterialFolderDtoInput, MaterialFolder } from './material-folder.model';
import { PageableParamInput } from '../../base.entity';

export const loadMaterialFolders = createAction(
  '[MaterialFolder/API] Load MaterialFolders', 
  props<{ materialFolders: MaterialFolder[] }>()
);

export const addMaterialFolder = createAction(
  '[MaterialFolder/API] Add MaterialFolder',
  props<{ materialFolder: MaterialFolder }>()
);

export const upsertMaterialFolder = createAction(
  '[MaterialFolder/API] Upsert MaterialFolder',
  props<{ materialFolder: MaterialFolder }>()
);

export const addMaterialFolders = createAction(
  '[MaterialFolder/API] Add MaterialFolders',
  props<{ materialFolders: MaterialFolder[] }>()
);

export const upsertMaterialFolders = createAction(
  '[MaterialFolder/API] Upsert MaterialFolders',
  props<{ materialFolders: MaterialFolder[] }>()
);

export const updateMaterialFolder = createAction(
  '[MaterialFolder/API] Update MaterialFolder',
  props<{ materialFolder: Update<MaterialFolder> }>()
);

export const updateMaterialFolders = createAction(
  '[MaterialFolder/API] Update MaterialFolders',
  props<{ materialFolders: Update<MaterialFolder>[] }>()
);

export const deleteMaterialFolder = createAction(
  '[MaterialFolder/API] Delete MaterialFolder',
  props<{ id: string }>()
);

export const deleteMaterialFolders = createAction(
  '[MaterialFolder/API] Delete MaterialFolders',
  props<{ ids: string[] }>()
);

export const clearMaterialFolders = createAction(
  '[MaterialFolder/API] Clear MaterialFolders'
);


// ==== CUSTOM ACTIONS ========
export const saveAndUpdateCourseMaterialFolder = createAction(
  '[MaterialFolder/API] Save And Update MaterialFolder',
  props<{ courseMaterialFolderDto: CourseMaterialFolderDtoInput }>()
);

export const getAllCourseMaterialFolders = createAction(
  '[MaterialFolder/API] Get All MaterialFolders',
  props<{ pageParam: Partial<PageableParamInput> }>()
);

export const getCourseMaterialFoldersByUuid = createAction(
  '[MaterialFolder/API] Get Course MaterialFolder By uuid',
  props<{ uuid: string }>()
);

export const getCourseMaterialFoldersByAptitudeTestUuid = createAction(
  '[MaterialFolder/API] Get  MaterialFolders By Aptitude Test Uuid',
  props<{ aptitudeTestUuid: string }>()
);