import gql from "graphql-tag";
import { basePageableGraphqlField } from "../../base.graphql";

export const calltrackerFields = `
id
uuid
title
createdAt
address
issueDescription
callType
phoneNumber
title
reportedTime
reporterMail
reporterName
institutionService{
  id
  uuid
  name
}
otherServiceName
departmentTo{
  id
  uuid
  departmentName
}
branchTo{
  id
  uuid
  branchName
}
institutionFrom{
  id
  uuid
  name
}
providedSupport{
  id
  uuid
  name
}
user{
  email
  id
  uuid
  fullName
}
createdBy
`;

export const SAVE_CALL_TRACKER = gql`
  mutation createUpdateCallRegistry( $dto: CallRegistryDtoInput){
    createUpdateCallRegistry(callRegistry: $dto){
      code
      message
      data{
        ${calltrackerFields}
      }
    }
  }
`;

export const DELETE_CALL_TRACKER = gql`
  mutation deleteCallRegistry($registryUuid: String) {
    deleteCallRegistry(uuid: $registryUuid) {
      code
      message
      status
    }
  }
`;

export const GET_CALL_TRACKER_LIST_BY_INSTITUTION_PAGEABLE = gql`
query allCallRegistriesByInstitution($institutionUuid: String, $pageparam: PageableParamInput){
  allCallRegistriesByInstitution(institutionUuid: $institutionUuid, pageParam: $pageparam){
    content{
      ${calltrackerFields}
    }
    totalElements
    totalPages
    size
    last
    first
  }
}
`;

export const GET_CALL_TRACKER_LIST_DEPARTMENT_PAGEABLE = gql`
query  allCallRegistriesByDepartmentTo($departmentToUuid:String,$pageParam: PageableParamInput){
  allCallRegistriesByDepartmentTo(departmentToUuid:$departmentToUuid,pageParam:$pageParam){
    content{
      ${calltrackerFields}
    }
    totalElements
    totalPages
    size
    last
    first
  }
}
`;

export const GET_CALL_TRACKER_LIST_BRANCH_PAGEABLE = gql`
query  allCallRegistriesByBranchTo($branchUuid:String,$pageParam: PageableParamInput){
  allCallRegistriesByBranchTo(branchUuid:$branchUuid,pageParam:$pageParam){
    content{
      ${calltrackerFields}
    }
    totalElements
    totalPages
    size
    last
    first
  }
}
`;

/////////// END CALL TRACKER //////////////

export const RESOLVE_PROBLEM = gql`
  mutation resolveProblem( $problemId: Long, $remarks: String ){
    resolveProblem( problemId: $problemId, remarks: $remarks ){
      code
      data{
        ${calltrackerFields}
      }
    }
  }
`;

export const APPROVE_RESOLVED_PROBLEM = gql`
  mutation approveResolvedProblem( $decision: HelpdeskDecisionType, $comment: String, $problemId: Long ){
    approveResolvedProblem( decision: $decision, comment: $comment, problemId: $problemId ){
      code
      data{
        ${calltrackerFields}
      }
    }
  }
`;

export const CLOSE_PROBLEM = gql`
  mutation closeProblem( $problemId: Long ){
    closeProblem( problemId: $problemId ){
      code
      data{
        ${calltrackerFields}
      }
    }
  }
`;

export const ALL_PROBLEM_LIST = gql`
    query listOfAllProblems($pageable: PegeableParamInput){
      listOfAllProblems(pageable: $pageable){
        content{
          ${calltrackerFields}
        }
        size
        totalPages
        totalElements
        numberOfElements
      }
    }
`;

export const ASSIGN_PROBLEM_TO_DEPARTMENT = gql`
    query assignProblemToDept($department: String, $problemId: Long) {
      assignProblemToDept(department: $department, problemId: $problemId) {
            code
            data{
                ${calltrackerFields}
            }
        }
    }
`;

export const PROBLEM_BY_ID = gql`
    mutation problemById( $problemId: Long) {
      problemById( problemId: $problemId) {
        code
        data{
          ${calltrackerFields}
        }
      }
    }
`;

export const INCIDENT_RESPONSE_BY_PROBLEM_ID = gql`
    query incidentResponsesByProblemId($problemId: Long) {
      incidentResponsesByProblemId(problemId: $problemId) {
        ${calltrackerFields}
      }
    }
`;

export const ALL_PROBLEM_ASSIGNMENT = gql`
  query listOfAllProblems($pageable: PegeableParamInput){
    listOfAllProblems(pageable: $pageable){
      content{
        ${calltrackerFields}
      }
      size
      totalPages
      totalElements
      numberOfElements
    }
  }
`;

export const LIST_ALL_PROBLEM_ASSIGNMENT = gql`
    query listOfProblemAssignments {
        listOfProblemAssignments {
          ${calltrackerFields}
        }
    }
`;

export const GET_CALL_DETAILED_REPORT = gql`
  query getCallRegisterSummaryReport(
    $fromDate: LocalDate
    $institutionFromUuid: String
    $exportDocType: ExportDocType
    $toDate: LocalDate
    $institutionServiceUuid: String
  ) {
    getCallRegisterSummaryReport(
      fromDate: $fromDate
      institutionFromUuid: $institutionFromUuid
      exportDocType: $exportDocType
      toDate: $toDate
      institutionServiceUuid: $institutionServiceUuid
    ) {
      code
      data
      message
      status
    }
  }
`;

export const CALL_TRACKER_STATISTICAL_REPORT = gql`
  query getCallRegisterStatisticsReport(
    $fromDate: LocalDate
    $institutionFromUuid: String
    $exportDocType: ExportDocType
    $toDate: LocalDate
  ) {
    getCallRegisterStatisticsReport(
      fromDate: $fromDate
      institutionFromUuid: $institutionFromUuid
      exportDocType: $exportDocType
      toDate: $toDate
    ) {
      code
      data
      message
      status
    }
  }
`;

export const GET_CALL_DASHBOARD_DATA = gql`
  query getGraphData {
    getGraphData {
      code
      status
      message
      data {
        callCount
        months
      }
    }
  }
`;

export const GET_CALL_REGISTRY_STATISTICS = gql`
  query getCallRegistryStatistics {
    getCallRegistryStatistics {
      code
      message
      status
      data {
        totalClientsCallRegistered
        branchStatisticsList {
          branchName
          totalCallRegistered
        }
        departmentStatisticsList {
          departmentName
          totalCallRegistered
        }
        serviceCallStatisticsList {
          serviceName
          serviceCallTypeStatistics {
            callType
            totalCallRegistered
          }
        }
        totalCallRegistered
      }
    }
  }
`;
