import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { IncidentAssignment } from './incident-assignment.model';
import * as IncidentAssignmentActions from './incident-assignment.actions';

export const incidentAssignmentsFeatureKey = 'incidentAssignments';

export interface State extends EntityState<IncidentAssignment> {
  // additional entities state properties
}

export const adapter: EntityAdapter<IncidentAssignment> = createEntityAdapter<IncidentAssignment>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(IncidentAssignmentActions.addIncidentAssignment,
    (state, action) => adapter.addOne(action.incidentAssignment, state)
  ),
  on(IncidentAssignmentActions.upsertIncidentAssignment,
    (state, action) => adapter.upsertOne(action.incidentAssignment, state)
  ),
  on(IncidentAssignmentActions.addIncidentAssignments,
    (state, action) => adapter.addMany(action.incidentAssignments, state)
  ),
  on(IncidentAssignmentActions.upsertIncidentAssignments,
    (state, action) => adapter.upsertMany(action.incidentAssignments, state)
  ),
  on(IncidentAssignmentActions.updateIncidentAssignment,
    (state, action) => adapter.updateOne(action.incidentAssignment, state)
  ),
  on(IncidentAssignmentActions.updateIncidentAssignments,
    (state, action) => adapter.updateMany(action.incidentAssignments, state)
  ),
  on(IncidentAssignmentActions.deleteIncidentAssignment,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(IncidentAssignmentActions.deleteIncidentAssignments,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(IncidentAssignmentActions.loadIncidentAssignments,
    (state, action) => adapter.setAll(action.incidentAssignments, state)
  ),
  on(IncidentAssignmentActions.clearIncidentAssignments,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
