import { PageableParam } from '../../../interfaces/global.interface';
import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Problem } from './problem.model';



export const loadProblems = createAction(
  '[Problem/API] Load Problems',
  props<{ problems: Problem[] }>()
);

export const addProblem = createAction(
  '[Problem/API] Add Problem',
  props<{ problem: Problem }>()
);

export const upsertProblem = createAction(
  '[Problem/API] Upsert Problem',
  props<{ problem: Problem }>()
);

export const addProblems = createAction(
  '[Problem/API] Add Problems',
  props<{ problems: Problem[] }>()
);

export const upsertProblems = createAction(
  '[Problem/API] Upsert Problems',
  props<{ problems: Problem[] }>()
);

export const updateProblem = createAction(
  '[Problem/API] Update Problem',
  props<{ problem: Update<Problem> }>()
);

export const updateProblems = createAction(
  '[Problem/API] Update Problems',
  props<{ problems: Update<Problem>[] }>()
);

export const deleteProblem = createAction(
  '[Problem/API] Delete Problem',
  props<{ id: string }>()
);

export const deleteProblems = createAction(
  '[Problem/API] Delete Problems',
  props<{ ids: string[] }>()
);

export const clearProblems = createAction(
  '[Problem/API] Clear Problems'
);


////////////////////  APIs: ////////////////////////

// CREATE_PROBLEM
export const saveProblem = createAction(
  '[Problem/API] Save Problem',
  props<{ problemDto: {
    description?: string;
    id?: number;
    incidentIds?: number[];
    rootCause?: string;
    title?: string;
  } }>()
);

// incidentResponsesByProblemId
export const getProblemId = createAction(
  '[Problem/API] Get Problem By Id',
  props<{ problemId: number }>()
);


// getProblemsByUser
export const getProblemsByUser = createAction(
  '[Problem/API] Get Problem By Assigned User',
  props<{ user: number }>()
);

// assignProblemToDept
export const assignProblemToDept = createAction(
  '[Problem/API] Assign Problem To Department',
  props<{ department: string; problemId: number }>()
);

// resolveProblem
export const resolveProblem = createAction(
  '[Problem/API] Resolve Problem',
  props<{ remarks: string; problemId: number }>()
);

// approveResolvedProblem
export const approveResolvedProblem = createAction(
  '[Problem/API] Approved Resolved Problem',
  props<{ decision: string; comment: string; problemId: number }>()
);

// closeProblem
export const closeProblem = createAction(
  '[Problem/API] Problem Closed',
  props<{ problemId: number }>()
);


// getProblemsByDepartmentCode
export const getProblemsByDepartmentCode = createAction(
  '[Problem/API] Get Department Problems',
  props<{ departmentCode: string }>()
);

// getProblemsByStatus
export const getProblemsByStatus = createAction(
  '[Problem/API] Get Problems By Status',
  props<{ status: string }>()
);

export const getProblemsByDepartmentCodeAndStatus = createAction(
  '[Problem/API] Get Problems By Department Code And Status',
  props<{ departmentCode: string; status: string }>()
);


