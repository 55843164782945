import { gql } from "apollo-angular";

export const SAVE_AND_UPDATE_COURSE_MATERIAL_FILE = gql`
    mutation saveAndUpdateCourseMaterialFile($courseMaterialFileDto: CourseMaterialFileDtoInput){
        saveAndUpdateCourseMaterialFile(courseMaterialFileDto:$courseMaterialFileDto){
            code
            message
            dataList{
                courseMaterialFolder{
                    id
                    uuid
                    files{
                        id
                        uuid
                        fileName
                        filePath
                    }
                }
                fileName
                filePath
                id
                uuid
            }
        }
    }
`;

export const GET_ATTACHMENT_BY_PATH = gql`
    query getAttachmentByPath($filePath: String){
        getAttachmentByPath(filePath:$filePath){
            code 
            message
            data{
                base64String
                fileName
                filePath
                uuid
            }
        }
    }
`;