<mat-toolbar>
    <div class="app-logo" routerLink="/landing">
        <!-- for service providers redirect to landing page -->
        <!-- <a *ngIf="serviceUserType.serviceProvider == loggedServiceUserType">
            <img alt="Emblem" src="/assets/images/egas.png"/>
        </a> -->
        <!-- for service clients dont redirect  -->
        <!-- <a *ngIf="serviceUserType.client == loggedServiceUserType || serviceUserType.nonEmployee==loggedServiceUserType">
            <img alt="Emblem" src="/assets/images/egas.png"/>
        </a> -->

        <div class="banner">
            <div class="image-holder">
                <img alt="Emblem" style="width: 150px !important; height: 150px !important;" src="/assets/images/egas.png"/>
            </div>
        </div>
    </div>
</mat-toolbar>

<mat-nav-list *ngIf="menuData">
    <!-- <div class="sb-module-name" *ngIf="menuData?.moduleName ">
        {{ menuData?.moduleName }}
    </div> -->

    <ng-container *ngFor="let menu of menuData?.menuItems">
        <!-- without children -->
        <ng-container *ngIf="!menu.children">
            <ng-container>
                <a *ngxPermissionsOnly="menu.permission" mat-list-item routerLink="{{ menu.route }}"
                   routerLinkActive="active-menu">
                    <span *ngIf="menu.iconType === 'MATERIAL'">
                        <mat-icon>{{ menu.icon }}</mat-icon>
                    </span>
                    <span *ngIf="menu.iconType === 'SVG'">
                        <mat-icon svgIcon="{{ menu.icon }}"></mat-icon>
                    </span>
                    <span class="m-title">{{ menu.name }}</span>
                </a>
            </ng-container>
        </ng-container>

        <!-- with children -->
        <ng-container *ngIf="menu?.children">
            <mat-accordion *ngxPermissionsOnly="menu?.permission">
                <mat-expansion-panel (click)="currentMenu = menu.name" [expanded]="menu.name === currentMenu">
                    <mat-expansion-panel-header>
                        <mat-panel-title class="big-menu-items">
                            <span *ngIf="menu.icon">
                                <mat-icon>{{ menu.icon }}</mat-icon>
                            </span>
                            <span>{{ menu.name }}
                                <span *ngIf="menu?.count" style="color: #0eacda;"> ({{menu?.count || 0 }}) </span>
                            </span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <ng-container *ngFor="let menuItem of menu.children">
                        <a [routerLink]="menuItem.route" mat-list-item routerLinkActive="active-menu"
                           style="padding-left: 0">
                            <span *ngIf="menuItem.icon">
                                <mat-icon>format_list_bulleted</mat-icon>

                                <!-- <mat-icon svgIcon="{{ menuItem.icon }}"></mat-icon> -->
                                <!-- <span *ngIf="menu.iconType === 'MATERIAL'">
                                    <mat-icon>{{ menu.icon }}</mat-icon>
                                </span>
                            <span *ngIf="menu.iconType === 'SVG'">
                                    <mat-icon svgIcon="{{ menu.icon }}"></mat-icon>
                                </span> -->
                            </span>
                            <!--                          <span *ngIf="menu.icon">&nbsp;</span>-->
                            <span>{{ menuItem.name }}</span>
                        </a>
                    </ng-container>
                </mat-expansion-panel>
            </mat-accordion>
        </ng-container>
    </ng-container>

    <!-- <a (click)="logout()" class="logoutLink" mat-list-item routerLinkActive="active-menu">
        <mat-icon>power_settings_new</mat-icon>
        <span class="m-title "> Log out</span>
    </a> -->
</mat-nav-list>

