import {Apollo} from 'apollo-angular';
import { Pipe, PipeTransform } from '@angular/core';
import { map } from 'rxjs/operators';
import { GET_MY_INSTITUTION_BY_UUID } from 'src/app/store/entities/settings/institution/institution.graphql';

@Pipe({
  name: 'institutionUuid'
})
export class InstitutionPipe implements PipeTransform {

  constructor(private apollo: Apollo) { }

  transform(value: any, args?: any): any {
    if (value) {
    return this.apollo.query({
        query: GET_MY_INSTITUTION_BY_UUID,
        variables: {
          uuid: value
        },
        fetchPolicy: 'network-only'
      })
        .pipe(
          map(({ data }: any) => {
          if (data?.getInstitutionByUuid?.code === 9000) {
            return data?.getInstitutionByUuid?.data?.name;
          }

        }));

    }

}


}
