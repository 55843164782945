<div class="step-indicator">
  <div class="title text-center" *ngIf="title; else space">
    <h3> {{ title }}</h3> 
  </div>
  <ng-template #space>
    <div class="py-3"></div>
  </ng-template>

  <div class="sequence-container position-relative ">   
    
    <div class="step-container col d-flex flex-column align-items-center position-relative" *ngFor="let step of steps; let i = index">
      <ng-container *ngIf="steps.length > i+1">
        <div class="line position-absolute"></div>
        <div class="progress position-absolute" *ngIf="progress >= i+2" style="--order:{{i+2+3}}"></div>
      </ng-container>      
      <div class="step-index-container" [class.cleared]="progress >= i+1" (click)="goToStep_(i+1)" [class.active]="progress == i+1" style="--order:{{i+1+3}}">
        <span [class.d-none]="step===''" > {{ i+1 }}</span>
      </div>
      <div class="step-name-container faded mt-3 small position-relative text-center"><span class="">{{ step}} </span></div>
    </div>
  </div>
</div>
