import { incidentFields } from './../incident/incident.graphql';
import gql from 'graphql-tag';

export const conversationFields = `
  active
  comment
  createdAt
  createdBy
  deleted
  id
  updatedAt
  seen
  incident{
    id
    uuid
    incidentNo
    title
    ownerInstitution{
      id
      uuid
      acronym
    }
    institutionFrom{
      id
      uuid
      acronym
    }
  }
  attachments {
    active
    createdAt
    createdBy
    fileExtension
    filePath
    id
    title
    uuid
  }
  institutionId
  institutionUuid
  isPublic
  ticketVisibility
  updatedAt
  updatedBy
  userEmail
  userUuid
  uuid
`;

export const CREATE_CONVERSATION = gql`
  mutation addUpdateConversation($conversation: ConversationDtoInput){
    addUpdateConversation(conversation: $conversation){
      code
      data{
        ${conversationFields}
      }
      message
    }
  }
`;

export const DELETE_CONVERSATION_BY_UUID = gql`
  query deleteConversation($conversationUuid: String) {
    deleteConversation(conversationUuid: $conversationUuid){
      code
      data{
        ${conversationFields}
      }
      message
    }
  }
`;

export const GET_ALL_CONVERSATIONS_BY_INCIDENT = gql`
  query getAllConversationsByIncident($incidentUuid: String, $pageparam: PageableParamInput){
    getAllConversationsByIncident(incidentUuid: $incidentUuid, pageparam: $pageparam){
      content{
        ${conversationFields}
      }
      size
      totalPages
      totalElements
      numberOfElements
    }
  }
`;

export const GET_CONVERSATION_BY_UUID = gql`
  query getConversationByUuid($conversationUuid: String) {
    getConversationByUuid(conversationUuid: $conversationUuid){
      code
      data{
        ${conversationFields}
      }
      message
    }
  }
`;

export const GET_ALL_CONVERSATION_BY_USER_EMAIL = gql`
  query getConversationByUserEmail($userEmail: String, $pageparam: PageableParamInput){
    getConversationByUserEmail(userEmail: $userEmail, pageparam: $pageparam){
      content{
        ${conversationFields}
      }
      size
      totalPages
      totalElements
      numberOfElements
    }
  }
`;

export const GET_ALL_CONVERSATION_BY_USER_UUID = gql`
  query getConversationByUserUuid($userUuid: String, $pageparam: PageableParamInput){
    getConversationByUserUuid(userUuid: $userUuid, pageparam: $pageparam){
      content{
        ${conversationFields}
      }
      size
      totalPages
      totalElements
      numberOfElements
    }
  }
`;

export const GET_ALL_CONVERSATION = gql`
  query getAllConversations($pageparam: PageableParamInput){
    getAllConversations(pageparam: $pageparam){
      content{
        ${conversationFields}
      }
      size
      totalPages
      totalElements
      numberOfElements
    }
  }
`;

export const GET_ALL_CONVERSATION_BY_UVISIBILITY = gql`
  query getAllConversationsByTicketVisibility($ticketVisibility: IncidentOrigin, $pageparam: PageableParamInput){
    getAllConversationsByTicketVisibility(ticketVisibility: $ticketVisibility, pageparam: $pageparam){
      content{
        ${conversationFields}
      }
      size
      totalPages
      totalElements
      numberOfElements
    }
  }
`;

export const GET_ALL_CONVERSATION_BY_PUBLICITY = gql`
  query getAllConversationsByIsPublic($isPublic: Boolean, $pageparam: PageableParamInput){
    getAllConversationsByIsPublic(ticketVisibility: $ticketVisibility, pageparam: $pageparam){
      content{
        ${conversationFields}
      }
      size
      totalPages
      totalElements
      numberOfElements
    }
  }
`;



export const GET_CONVERSATION_BY_ID = gql`
  query getConversationById($conversationId: Long) {
    getConversationById(conversationId: $conversationId){
      code
      data{
        ${conversationFields}
      }
      message
    }
  }
`;

// export const GET_CONVERSATION_BY_USER = gql`
//   query getConversationByUserUuid($userUuid: String, $pageparam: PageableParamInput){
//     getConversationByUserUuid(userUuid: $userUuid, pageparam: $pageparam){
//       ${conversationFields}
//     }
//   }
// `;

// export const GET_ALL_PAGED_CONVERSATIONS = gql`
//   query getAllConversations($pageparam: PageableParamInput){
//     getAllConversations(pageparam: $pageparam){
//       ${conversationFields}
//     }
//   }
// `;

