

  <!-- Filter -->
  <div class="table-filter" *ngIf="searchFields?.length > 0">
    <div class="row">

      <div class="col-md-12">
        <mat-form-field  class="search-form-field" floatLabel="never" >
          <input (keyup)="filterTable()" [(ngModel)]="searchKey" autocomplete="off" matInput placeholder="Search" />
          <button (click)="onSearchClear()" *ngIf="searchKey" arial-label="Clear" mat-button mat-icon-button matSuffix>
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>

    </div>
  </div>
  <!-- end of Filter -->

  <div class="col-md-12 px-0 mat-elevation-z1 pb-3">
    <table (matSortChange)="sortData($event)" class="table align-middle custom-table {{ tableCssClass }}" matSort>
      <thead class="thead-light">
        <tr>
          <ng-container *ngFor="let tableColumn of tableColumns; let i = index;">
              <!--
                TODO: we need to implement the sortable option,
                      first idea, to allow column sort and vise versa
                      second idea, column can be sorted by defined column, i.e action can sort id column
              -->
              <th [mat-sort-header]="tableColumn?.keyword" class="{{ tableColumn?.theadCellClass}}">
                {{tableColumn.title}}
              </th>

          </ng-container>
        </tr>
      </thead>
      <tbody>
      <tr *ngFor="let tableData of tableData$ | async; let j = index">

        <ng-container *ngFor="let tableColumn of tableColumns;let m = index">
            <td class="{{ tableColumn?.tbodyCellClass}}">

            <!--index cell-->
            <ng-container *ngIf="tableColumn.type === tableColumnType.index">
              {{ j + 1 + currentPage * pageSize}}
            </ng-container>
            <!--/ index cell-->

            <!--checkbox cell-->
            <ng-container *ngIf="tableColumn.type === tableColumnType.inputCheckbox">
              <mat-checkbox
                (change)="$event ? selection.toggle(tableData) : null"
                (click)="$event.stopPropagation()"
                [checked]="selection.isSelected(tableData)">
              </mat-checkbox>
            </ng-container>
            <!--/ checkbox cell-->

              <!--text cell-->
            <ng-container *ngIf="tableColumn.type == null">
              {{tableData[tableColumn.keyword]}}
            </ng-container>
            <!--/ text cell-->

              <!--html cell-->
            <ng-container *ngIf="tableColumn.type === tableColumnType.html">
              <span [innerHTML]="tableData[tableColumn.keyword]"></span>
            </ng-container>
            <!--/ html cell-->

              <!--number cell-->
            <ng-container *ngIf="tableColumn.type == tableColumnType.number">
              {{ tableData[tableColumn.keyword] | number}}
            </ng-container>
            <!--/ number cell-->

            <!--date cell-->
            <ng-container *ngIf="tableColumn.type === tableColumnType.date">
              {{ tableData[tableColumn.keyword] | date : tableColumn.dateFormat}}
            </ng-container>
            <!--/ date column-->

            <!--action cell-->
            <ng-container *ngIf="tableColumn?.isActionColumn">
              <ng-container [ngTemplateOutletContext]="{data : tableData}" [ngTemplateOutlet]="actions"></ng-container>
            </ng-container>
            <!--/ action cell-->

            </td>
          </ng-container>

      </tr>
      </tbody>
    </table>
    <app-pagination-buttons (clickedPage)="getPage($event)"
                            (selectedPageSize)="getPageSize($event)"
                            [currentPage]="currentPage"
                            [pagination]="pagination"
                            [totalPage]="totalPages"
                            [pageSizeOptions]="pageSizeOptions">
    </app-pagination-buttons>

  </div>

