<app-modal-header [title]="title" (closeModal)="closeThisModal($event)"></app-modal-header>

<!-- <div class="form-holder">
    <main-dynamic-form class="py-5" [fields]="fields" 
    [form]="changePasswordForm" (submit)="submitForm($event)" >
    </main-dynamic-form>
</div> -->

<form (ngSubmit)="submitPasswordForm(changePasswordForm.value)" [formGroup]="changePasswordForm" autocomplete="off" novalidate>
  <div class="row">
    <mat-form-field appearance="outline" class="material-form col-md-12">
      <mat-label>Enter current password</mat-label>
      <input type="password" formControlName="oldPassword" matInput placeholder="enter your password">
      <mat-icon matSuffix>lock</mat-icon>
      <mat-error>
        <span *ngIf="!changePasswordForm.get('oldPassword').valid && changePasswordForm.get('oldPassword').touched">
          This field is required
        </span>
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="material-form col-md-12">
      <mat-label>Enter new password</mat-label>
      <input type="password" formControlName="newPassword" matInput placeholder="">
      <mat-icon matSuffix>lock</mat-icon>
      <mat-error>
        <span *ngIf="!changePasswordForm.get('newPassword').valid && changePasswordForm.get('newPassword').touched">
          This field is required
        </span>
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="material-form col-md-12">
      <mat-label>Confirm Password</mat-label>
      <input formControlName="confirmPassword" matInput placeholder="" type="password">
      <mat-icon matSuffix>lock</mat-icon>
      <mat-hint *ngIf="changePasswordForm.get('confirmPassword').value !== changePasswordForm.get('newPassword').value" class="text-info">The two passwords do not match</mat-hint>
      <mat-error>
        <span *ngIf="!changePasswordForm.get('confirmPassword').valid && changePasswordForm.get('confirmPassword').touched">
          This field is required
        </span>
      </mat-error>
    </mat-form-field>
  </div>

  <div class="row">
    <div class="col-md-12">
      <span *ngIf="loading">
          <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
      </span>
    </div>
    <div class="col-md-12 d-flex px-0">
      <div class="float-lg-right col-lg-5 order-xs-1 col-xs-12">
        <button [disabled]="!changePasswordForm.valid || loading || changePasswordForm.get('confirmPassword').value !== changePasswordForm.get('newPassword').value" class="custom-btn green-btn w-100"
                type="submit">
          Submit
        </button>
      </div>
    </div>
  </div>

</form>
