import {Component, Input, OnInit} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ActivatedRoute} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {initializedPageableParameter, PageableParam} from 'src/app/interfaces/global.interface';
import {NotificationService} from 'src/app/services/notification.service';
import {SettingsService} from 'src/app/services/settings.service';
import {StorageService} from 'src/app/services/storage.service';
import {listAllRolesPageable, removeRole} from 'src/app/store/entities/settings/role/role.actions';
import {Role, RoleSearchFields} from 'src/app/store/entities/settings/role/role.model';
import {selectRoleByInstitutionUuid} from 'src/app/store/entities/settings/role/role.selectors';
import {AppState} from 'src/app/store/reducers';
import {RoleFormComponent} from '../../../../modules/settings/roles/role-form/role-form.component';
import {Observable, Subscription} from 'rxjs';
import {InstitutionPipe} from 'src/app/shared/pipes/institution.pipe';
import {Institution} from '../../../../store/entities/settings/institution/institution.model';

@Component({
    selector: 'app-institution-role',
    templateUrl: './institution-role.component.html',
    styleUrls: ['./institution-role.component.scss'],
    providers: [InstitutionPipe]
})
export class InstitutionRoleComponent implements OnInit {

    title: string;
    tableColumns: any;
    roles$: Observable<Role[]>;
    pageable: PageableParam = initializedPageableParameter;

    searchFields = RoleSearchFields;
    institutionId: number;
    userType: any;
    institutionUuid: any;
    subscription = new Subscription();
    defaultInstitution = false;
    serviceUserType: any;

    @Input() institutionInput: Institution;


    constructor(
        private store: Store<AppState>,
        private dialog: MatDialog,
        private notificationService: NotificationService,
        private settingsService: SettingsService,
        private route: ActivatedRoute,
        private storageService: StorageService,
        private institutionPipe: InstitutionPipe
    ) {
        this.defaultInstitution = JSON.parse(storageService.getItem('defaultInstitution'));
        this.serviceUserType = storageService.getItem('serviceUserType');
        this.institutionId = +storageService.getItem('institutionId');
        this.loadData();
    }

    ngOnInit(): void {
        this.title = 'User Roles List';

        this.preDataLoad();

        if (this.userType == 'internal') {
            this.tableColumns = {
                no: 'SN',
                name: 'Name',
                displayName: 'Display Name',
                modififiedGlobal: 'Is visible to all ?',
                actions: 'Actions'
            };
        } else {
            this.tableColumns = {
                no: 'SN',
                name: 'Name',
                displayName: 'Display Name',
                actions: 'Actions'
            };
        }

        const institutionDetails$ = this.institutionPipe.transform(this.institutionUuid);
        this.subscription.add(
            institutionDetails$.subscribe((data) => {
                this.title = (data.length > 50 ? data?.substring(0, 49) + ' ...' : data) + '  ' + this.title;
            })
        );
        this.roles$ = this.store.pipe(select(selectRoleByInstitutionUuid({uuid: this.institutionUuid})));
    }

    loadMore(pageable: PageableParam) {
        this.pageable = pageable;
        this.loadData();
    }

    preDataLoad() {
        this.defaultInstitution = this.storageService.getItem('defaultInstitution');
        this.serviceUserType = this.storageService.getItem('serviceUserType');
        this.institutionUuid = this.route?.snapshot?.paramMap.get('institutionID') ?? this.institutionInput?.uuid;
        this.loadData();
    }

    loadData() {
        this.store.dispatch(listAllRolesPageable({institutionUuid: this.institutionUuid, pageableParam: this.pageable}));
    }


    addRole(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = '50%';
        this.dialog.open(RoleFormComponent, dialogConfig);
    }

    editRole(data: Role) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = '50%';
        dialogConfig.data = data;
        this.dialog.open(RoleFormComponent, dialogConfig);
    }

    deleteRole(role: Role) {
        const message = 'Are you sure?';
        this.notificationService.confirmation(message, 'Delete').then(
            data => {
                if (data.value) {
                    this.store.dispatch(removeRole({roleUuid: role.uuid, roleId: role.id}));
                }
            }
        );
    }

    encoder(id: number): string {
        return this.settingsService.encoder(id, 2, 'permissions');
    }

}
