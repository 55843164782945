<!-- top spacing -->
<div class="p-0"></div>

<!-- Filter -->
<div class="table-filter">
    <div class="row">
      <div class="col-md-12">
        <mat-form-field class="search-form-field" floatLabel="never">
          <mat-icon matPrefix>search</mat-icon>
          <input
          (keyup)="delayedFilter($event)"
            [(ngModel)]="searchKey"
            autocomplete="off"
            matInput
            placeholder="Search"
          />
          <button
            (click)="onSearchClear()"
            *ngIf="searchKey"
            arial-label="Clear"
            mat-button
            mat-icon-button
            matSuffix
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>
  </div>
<!-- end of Filter -->  


<div [hidden]="!dataLength" class="m-1 my-2  pt-0 {{animation}} {{classList}}" style="--order:2.4; overflow-x: visible;"> 
  <div class="table-container" [class.hide-paginator]="dataLength < 9" @fadeTable>
    <mat-table
      (matSortChange)="sortChange($event)"
      [dataSource]="listData"
      matSort
      [class.hide-last-column]="!actionColumn"
    >
      <ng-container
        *ngFor="let tableData of objectKeys(columnHeader); let i = index"
      >
        <ng-container *ngIf="i == 0">
          <!-- SN Column -->
          <ng-container matColumnDef="{{ tableData }}">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ columnHeader[tableData] }}
            </mat-header-cell>
            <mat-cell *matCellDef="let element; let i = index" >
              <span [innerHTML]="columnHeader[tableData]" class="column-title">
              </span>
              {{ i + 1 + myPaginator.pageIndex * myPaginator.pageSize }}
            </mat-cell>
          </ng-container>
          <!-- end of SN Column -->
        </ng-container>

        <ng-container *ngIf="!actionColumn">
          <ng-container *ngIf="i > 0">
            <ng-container matColumnDef="{{ tableData }}">
              <mat-header-cell *matHeaderCellDef mat-sort-header
              >{{ columnHeader[tableData] }}
              </mat-header-cell>
              <mat-cell *matCellDef="let element" (click)="viewDetails(element)">
                <span [innerHTML]="columnHeader[tableData]" class="column-title">
                </span>
                <span [innerHTML]="getObjectValue(element, tableData)"></span>
                <!--              <span [innerHTML]="element[tableData]"> </span>-->
              </mat-cell>
            </ng-container>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="actionColumn">
          <ng-container *ngIf="i > 0 && i < objectKeys(columnHeader).length - 1">
            <ng-container matColumnDef="{{ tableData }}">
              <mat-header-cell *matHeaderCellDef mat-sort-header
              >{{ columnHeader[tableData] }}
              </mat-header-cell>
              <mat-cell *matCellDef="let element" (click)="viewDetails(element)">
                <span [innerHTML]="columnHeader[tableData]" class="column-title">
                </span>
                <span [innerHTML]="getObjectValue(element, tableData)"></span>
                <!--              <span [innerHTML]="element[tableData]"> </span>-->
              </mat-cell>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="i == objectKeys(columnHeader).length - 1">
            <ng-container matColumnDef="{{ tableData }}">
              <mat-header-cell *matHeaderCellDef mat-sort-header
              >{{ columnHeader[tableData] }}
              </mat-header-cell>
              <mat-cell *matCellDef="let row">
                <span [innerHTML]="columnHeader[tableData]" class="column-title">
                </span>
                <span class="column-action">
                  <ng-container
                    [ngTemplateOutletContext]="{ data: row }"
                    [ngTemplateOutlet]="actions"
                  ></ng-container>
                </span>
              </mat-cell>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>

      <!-- Table Configurations -->
      <mat-header-row
        *matHeaderRowDef="objectKeys(columnHeader)"
      ></mat-header-row>

      <mat-row
        *matRowDef="let row; columns: objectKeys(columnHeader); let i = index"
        class="move-up"
        style="--order: {{ i * 0.7 }}"
      ></mat-row>
      <!-- end of Table Configurations -->

      <!-- If No Data Found -->
      <mat-footer-row
        *matFooterRowDef="['noData']"
        [ngClass]="{ hide: !(listData != null && listData.data.length == 0) }"
      ></mat-footer-row>

      <ng-container matColumnDef="noData">
        <mat-footer-cell *matFooterCellDef colspan="8">No Data</mat-footer-cell>
      </ng-container>
      <!-- end if no Data Found -->

      <!-- Loading Data Message -->
      <mat-footer-row
        *matFooterRowDef="['loading']"
        [ngClass]="{ hide: listData != null }"
      ></mat-footer-row>
      <ng-container matColumnDef="loading">
        <mat-footer-cell *matFooterCellDef colspan="8"
        >Loading ...
        </mat-footer-cell
        >
      </ng-container>
      <!-- end of Loading Data Message -->
    </mat-table>
    
    <mat-paginator
      #myPaginator
      (page)="handlePage($event)"
      [length]="dataLength"
      [pageSizeOptions]="pageSizeOptions"
      [pageSize]="10"
      [pageIndex]="pageIndex"
      showFirstLastButtons
    >
    </mat-paginator>

  </div>
</div>

<ng-container *ngIf="!dataLength">
  <div class="my-lg-4 my-3 {{animation}} rounded-medium bg-white rounded-slight overflow-hidden p-5 box-shadow-faded" style="--order:1.5">
    No Data
  </div>
</ng-container>
