import { Validators } from '@angular/forms';
import { FieldConfig, FieldType } from 'src/app/shared/components/dynamic-forms-components/field.interface';

export const deptfields: FieldConfig[] = [
  {
    type: FieldType.select,
    label: 'Select Department',
    key: 'department',
    useObservable: true,
    options: [],
    validations: [{ message: 'Department is Required', validator: Validators.required, name: 'required' }],
    rowClass: 'col12'
  },
  // {
  //   type: FieldType.attachment,
  //   acceptType: 'application/pdf',
  //   label: 'Add attachment (Optional)',
  //   // showWhen: '{(description)(!=)(null)}',
  //   key: 'attachments',
  // },
  {
    type: FieldType.button,
    label: 'Assign problem',
    rowClass: 'col12'
  }
];

export const personnelFields: FieldConfig[] = [
  {
    type: FieldType.select,
    label: 'Select Personnel',
    key: 'userIds',
    useObservable: true,
    multiple: true,
    options: [],
    validations: [{ message: 'Personnel is Required', validator: Validators.required, name: 'required' }],
    rowClass: 'col12'
  },
  // {
  //   type: FieldType.attachment,
  //   acceptType: 'application/pdf',
  //   label: 'Add attachment (Optional)',
  //   // showWhen: '{(description)(!=)(null)}',
  //   key: 'attachments',
  // },
  {
    type: FieldType.button,
    label: 'Assign problem',
    rowClass: 'col12'
  }
];

export const resolveFields: FieldConfig[] = [
  {
    type: FieldType.textarea,
    label: 'Resolve remarks(What you did)',
    key: 'remarks',
    validations: [{ message: 'Remarks are required', validator: Validators.required, name: 'required' }],
    rowClass: 'col12'
  },
  // {
  //   type: FieldType.attachment,
  //   acceptType: 'application/pdf',
  //   label: 'Add attachment (Optional)',
  //   // showWhen: '{(description)(!=)(null)}',
  //   key: 'attachments',
  // },
  {
    type: FieldType.button,
    label: 'Submit remarks',
    rowClass: 'col12'
  }
];

export const recheckFields: FieldConfig[] = [
  {
    type: FieldType.textarea,
    label: 'Comment',
    key: 'comment',
    validations: [{ message: 'Comments are required', validator: Validators.required, name: 'required' }],
    rowClass: 'col12'
  },
  {
    type: FieldType.select,
    label: 'Select decision',
    key: 'decision',
    options: [],
    validations: [{ message: 'Make your decision', validator: Validators.required, name: 'required' }],
    rowClass: 'col12'
  },
  // {
  //   type: FieldType.attachment,
  //   acceptType: 'application/pdf',
  //   label: 'Add attachment (Optional)',
  //   // showWhen: '{(description)(!=)(null)}',
  //   key: 'attachments',
  // },
  {
    type: FieldType.button,
    label: 'Submit',
    rowClass: 'col12'
  }
];

export const closeIncidentFields: FieldConfig[] = [
    {
        type: FieldType.textarea,
        label: 'Description',
        key: 'resolvingDescription',
        validations: [{ message: 'Description is Required', validator: Validators.required, name: 'required' }],
        rowClass: 'col12'
    },
    {
        type: FieldType.textarea,
        label: 'Root Cause',
        key: 'rootCause',
        validations: [{ message: 'Root Cause is Required', validator: Validators.required, name: 'required' }],
        rowClass: 'col12'
    },
    {
        type: FieldType.date,
        label: 'Resolved Date',
        key: 'endTime',
        maxDate: new Date(),
        dateFormat: 'yyyy-MM-dd h:mm:ss',
        validations: [{ message: 'Resolved Date is Required', validator: Validators.required, name: 'required' }],
        rowClass: 'col12'
    },
    {
        type: FieldType.button,
        label: 'Close Incident',
        rowClass: 'col12'
    }
];
