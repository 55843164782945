import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { ChangeRequest } from './change-request.model';
import { PageableParam } from 'src/app/interfaces/global.interface';

export const loadChangeRequests = createAction(
  '[ChangeRequest/API] Load ChangeRequests',
  props<{ changeRequests: ChangeRequest[] }>()
);

export const addChangeRequest = createAction(
  '[ChangeRequest/API] Add ChangeRequest',
  props<{ changeRequest: ChangeRequest }>()
);

export const upsertChangeRequest = createAction(
  '[ChangeRequest/API] Upsert ChangeRequest',
  props<{ changeRequest: ChangeRequest }>()
);

export const addChangeRequests = createAction(
  '[ChangeRequest/API] Add ChangeRequests',
  props<{ changeRequests: ChangeRequest[] }>()
);

export const upsertChangeRequests = createAction(
  '[ChangeRequest/API] Upsert ChangeRequests',
  props<{ changeRequests: ChangeRequest[] }>()
);

export const updateChangeRequest = createAction(
  '[ChangeRequest/API] Update ChangeRequest',
  props<{ changeRequest: Update<ChangeRequest> }>()
);

export const updateChangeRequests = createAction(
  '[ChangeRequest/API] Update ChangeRequests',
  props<{ changeRequests: Update<ChangeRequest>[] }>()
);

export const deleteChangeRequest = createAction(
  '[ChangeRequest/API] Delete ChangeRequest',
  props<{ id: string }>()
);

export const deleteChangeRequests = createAction(
  '[ChangeRequest/API] Delete ChangeRequests',
  props<{ ids: number[] }>()
);

export const clearChangeRequests = createAction(
  '[ChangeRequest/API] Clear ChangeRequests'
);

// Effect Actions
export const listMyInstitutionAllChangeRequests = createAction(
  '[ChangeRequest/API] List My Institution All ChangeRequests',
  props<{ pageableParam?: PageableParam }>()
);

export const listAllMyChangeRequests = createAction(
  '[ChangeRequest/API] List My ChangeRequests',
  props<{ pageableParam?: PageableParam }>()
);

export const activateChangeRequest = createAction(
  '[ChangeRequest/API] Activate ChangeRequest',
  props<{ id: string}>()
);

export const modifyChangeRequest = createAction(
  '[ChangeRequest/API] Modify ChangeRequest Details',
  props<{ changeRequest: ChangeRequest }>()
);


export const createChangeRequest = createAction(
  '[ChangeRequest/API] Create ChangeRequest',
  props<{ changeRequest: ChangeRequest }>()
);

export const removeChangeRequest = createAction(
  '[ChangeRequest/API] Remove ChangeRequest By Id',
  props<{ id: string }>()
);

// export const getDefaultChangeRequest = createAction(
//   '[User/API] Get Default ChangeRequest'
// );
