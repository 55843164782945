import { ResponseCodeService } from './../../../../services/response-code.service';
import { NotificationService } from './../../../../services/notification.service';
import { AppState } from './../../../reducers/index';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import * as fromActions from './designation.actions';
import * as fromGraphql from './designation.graphql';
import { map, switchMap } from 'rxjs/operators';


@Injectable()
export class DesignationEffects {

  // Get Institution Designations
  getInstitutionDesignations$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.getInstitutionDesignations),
    switchMap((action) => {
      return this.apollo.query({
        query: fromGraphql.INSTITUTION_DESIGNATIONS,
        fetchPolicy: 'network-only',
      }).pipe(
        this.notificationService.catchError('Problem occurred while fetching Designations'),
        map(({ data }: any) => {
          if (data) {
            this.store.dispatch(fromActions.loadDesignations({ designations: data.myInstitutionDesignations }));
          }
        })
      );
    })
  ), {dispatch: false});


  // List All Designation By Institution
  listAllInstitutionDegination$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.getDesignationsByInstitution),
    switchMap((action) => {
      return this.apollo.query({
        query: fromGraphql.GET_ALL_DESIGNATIONS_BY_INSTITUTION,
        variables: {
          institutionId:  action.institution.id
        },
        fetchPolicy: 'network-only',
      }).pipe(
        this.notificationService.catchError('Problem occurred while fetching Branches'),
        map(({ data }: any) => {
          if (data) {
            this.store.dispatch(fromActions.loadDesignations({ designations: data.getAllDesignationsByInstitution }));
          }
        })
      );
    })
  ), {dispatch: false});

  // Create Designation
  createDesignation$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.createDesignation),
    switchMap((action) => {
     
      return this.apollo.mutate({
        mutation: fromGraphql.CREATE_DESIGNATION,
        variables: {
          designation: action.designationDto
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while saving designation'),
        map(({ data }: any) => {
          if (data) {
             
            if (data.createDesignation.code === 9000) {
              this.store.dispatch(fromActions.upsertDesignation({ designation: data.createDesignation.data }));
              if (action.designationDto?.id) {
                this.notificationService.successMessage('Edited Successfully');
              } else {
                this.notificationService.successMessage('Created Successfully');
              }
            } else {
              const error = this.responseCodesService.getCodeDescription(data.createDesignation.code);
              this.notificationService.errorMessage(error[0]?.description);
            }
          }
        })
      );
    })
  ), {dispatch: false});



  // Update Designation 
  modifyDesignation$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.modifyDesignation),
    switchMap((action) => {
     
      return this.apollo.mutate({
        mutation: fromGraphql.CREATE_DESIGNATION,
        variables: {
          designation: action.designationDto
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while updating Designation'),
        map(({ data }: any) => {
          if (data) {
             
            if (data.createDesignation.code === 9000) {
              this.store.dispatch(fromActions.upsertDesignation({ designation: data.createDesignation.data }));
              this.notificationService.successMessage('Designation Updated Successfully');
            } else {
              const error = this.responseCodesService.getCodeDescription(data.createDesignation.code);
              this.notificationService.errorMessage(error[0]?.description);
            }
          }
        })
      );
    })
  ), {dispatch: false});


  // Delete Designation
  removeDesignation$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.removeDesignation),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: fromGraphql.REMOVE_DESIGNATION,
        variables: {
          id: action.id
        }
      }).pipe(
        map(({ data }: any) => {
          if (data) {
            this.notificationService.catchError('Problem occurred while deleting designation')
            if (data.deleteDesignation.code === 9000) {
              this.store.dispatch(fromActions.deleteDesignation({ id: action._id }));
              this.notificationService.successMessage('Deleted Successfully');
            } else {
              this.notificationService.errorMessage(data.deleteDesignation);
            }
          }
        })
      );
    })
  ), {dispatch: false});

  constructor(
    private actions$: Actions,
    private apollo: Apollo,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private responseCodesService: ResponseCodeService
  ) {
  }

}
