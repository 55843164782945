
export function enumToObjectArray(enum_obj: any): {name:string, value:string}[] {
    var array = [];
    for (const key in enum_obj) array.push({ name:key, value: enum_obj[key]});
    return array;
}

export function enumToArray(enum_obj: any): any[] {
    var array = [];
    for (const key in enum_obj) array.push(enum_obj[key]);
    return array;
}

export function hasSameProps( obj1, obj2 ) {
    return Object.keys( obj1 ).every( function( prop ) {
      return obj2.hasOwnProperty( prop );
    });
}

export function hasAllPropsIn( obj1, obj2 ) {
    let same = true;
    if(typeof obj2 !=="object" || !obj2 || !obj1) return;
    Object.keys( obj1 ).forEach( key => {
      if(!obj1[key]) return
      same = same && obj1[key] === obj2[key];
    });
    return same;
}

export function copyFields( obj1, obj2,fields:any[] ) {
  if(typeof obj2 !=="object" || !obj2 || !obj1) return;
  fields.forEach( key => {
    if(!obj2[key]) return
    obj1[key] = obj2[key];
  });
  return obj1;
}

export function formatPhoneNumber(phone){
  let str = phone?.toString();
  if(!(str?.length > 0) || !phone) return phone;
  if(str.indexOf('0') === 0 || str.indexOf('+') === 0) phone = setCharAt(str,0,'255');
  return phone;
}


export function setCharAt(str,index,chr) {
  if(index > str.length-1) return str;
  return str.substring(0,index) + chr + str.substring(index+1);
}

export function formatNumber(number,separator?) {
  return number.toString().replace(/\B(?=(\d3)+(?!\d))/g, separator?? ",");
}

export function fieldsArrayFromObj( obj ) {
  let fields:{key:string, value:any}[] = [];

  if(typeof obj !=="object" || !obj ) return;
  Object.keys( obj ).forEach( key => {
     fields.push({key:key, value:obj[key]});
  });
  return fields;
}

export function valueIsEither(value,values:any[]):boolean{
 return !!values.find(v => v === value);
}

export function valueIncludesEither(value,values:string[]):boolean{
  let includes = false;
  values.forEach(v => includes = includes || value.toLowerCase().includes(v.toLowerCase()))
  return includes
}

export function nameValueArr(y){
  return y.map(x=>{return{name:x,value:x}})
}

export function camelToSpaced(y){
  if(typeof y !=="string") return y
  return y.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1")
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function toTitleCase(text){
  var i, j, str, lowers, uppers;
  str = text.replace(/([^\W_]+[^\s-]*) */g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });

  // Certain minor words should be left lowercase unless 
  // they are the first or last words in the string
  lowers = ['A', 'An', 'The', 'And', 'But', 'Or', 'For', 'Nor', 'As', 'At', 
  'By', 'For', 'From', 'In', 'Into', 'Near', 'Of', 'On', 'Onto', 'To', 'With'];
  for (i = 0, j = lowers.length; i < j; i++)
    str = str.replace(new RegExp('\\s' + lowers[i] + '\\s', 'g'), 
      function(txt) {
        return txt.toLowerCase();
      });

  // Certain words such as initialisms or acronyms should be left uppercase
  uppers = ['Id', 'Tv'];
  for (i = 0, j = uppers.length; i < j; i++)
    str = str.replace(new RegExp('\\b' + uppers[i] + '\\b', 'g'), 
      uppers[i].toUpperCase());

  return str;
}


export function getDaysBetween(StartDate, EndDate) {
  if(!StartDate?.split('-').length || !EndDate?.split('-').length ) return undefined;

  // The number of milliseconds in all UTC days (no DST)
  const oneDay = 1000 * 60 * 60 * 24;

  // A day in UTC always lasts 24 hours (unlike in other time formats)
  const start = Date.UTC(EndDate.split('-')[0], EndDate.split('-')[1], EndDate.split('-')[2]);
  const end = Date.UTC(StartDate.split('-')[0], StartDate.split('-')[1], StartDate.split('-')[2]);

  // so it's safe to divide by 24 hours
  return (start - end) / oneDay;
}

export function singularize(word) {
  const endings = {
    ves: 'fe',
    ies: 'y',
    i: 'us',
    zes: '',
    ses: '',
    es: '',
    s: ''
  };
  return word.replace(
    new RegExp(`(${Object.keys(endings).join('|')})$`), 
    r => endings[r]
  );
}

export function countOccurrences(str, find) {
  if(typeof str !=="string") return 0;
  return (str.split(find)).length - 1;
}

var num = "zero one two three four five six seven eight nine ten eleven twelve thirteen fourteen fifteen sixteen seventeen eighteen nineteen".split(" ");
var tens = "twenty thirty forty fifty sixty seventy eighty ninety".split(" ");

export function numberTowords(n){
    if (n < 20) return num[n];
    var digit = n%10;
    if (n < 100) return tens[~~(n/10)-2] + (digit? "-" + num[digit]: "");
    if (n < 1000) return num[~~(n/100)] +" hundred" + (n%100 == 0? "": " and " + numberTowords(n%100));
    return numberTowords(~~(n/1000)) + " thousand" + (n%1000 != 0? " " + numberTowords(n%1000): "");
}

export function coolness(x){
  return x;
}

export function removeLeadingPhoneCode(phone){
  let str = phone?.toString();
  if(!(str?.length > 0) || !phone) return phone;
  if(str.indexOf('0') === 0) return setCharAt(str,0,'');
  if(str.indexOf('+255') === 0)  return str?.replace('+255','');
  if(str.indexOf('255') === 0)  return str?.replace('255','');
}

export function isAllNumbersWithLengthBetween(val:string,n,m){
  let isnum = /^\d+$/.test(val);
   
  if(val.length >= n && val.length <= m && isnum) return true;
  return false;
}