import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as IssueCategoryActions from './issue-category.actions';
import { IssueCategory } from './issue-category.model';

export const issueCategorysFeatureKey = 'issueCategorys';

export interface State extends EntityState<IssueCategory> {
  // additional entities state properties
}

export const adapter: EntityAdapter<IssueCategory> = createEntityAdapter<IssueCategory>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(IssueCategoryActions.addIssueCategory,
    (state, action) => adapter.addOne(action.issueCategory, state)
  ),
  on(IssueCategoryActions.upsertIssueCategory,
    (state, action) => adapter.upsertOne(action.issueCategory, state)
  ),
  on(IssueCategoryActions.addIssueCategorys,
    (state, action) => adapter.addMany(action.issueCategorys, state)
  ),
  on(IssueCategoryActions.upsertIssueCategorys,
    (state, action) => adapter.upsertMany(action.issueCategorys, state)
  ),
  on(IssueCategoryActions.updateIssueCategory,
    (state, action) => adapter.updateOne(action.issueCategory, state)
  ),
  on(IssueCategoryActions.updateIssueCategorys,
    (state, action) => adapter.updateMany(action.issueCategorys, state)
  ),
  on(IssueCategoryActions.deleteIssueCategory,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(IssueCategoryActions.deleteIssueCategorys,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(IssueCategoryActions.loadIssueCategorys,
    (state, action) => adapter.setAll(action.issueCategorys, state)
  ),
  on(IssueCategoryActions.clearIssueCategorys,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
