import {gql} from 'apollo-angular';


export const emailFields = `
  id
  title
  emailNotificationType
  content
  createdAt
`;

export const LIST_EMAIL_NOTIFICATION = gql`
query listHelpdeskEmailNotifications{
  listHelpdeskEmailNotifications{
   ${emailFields}
  }
}
`
  ;

export const UPDATE_EMAIL_NOTIFICATION = gql`
mutation updateHelpdeskEmailNotification($id: Long, $content: String){
  updateHelpdeskEmailNotification(id: $id, content: $content){
    code
    data{
      ${emailFields}
    }
  }
}`
  ;
