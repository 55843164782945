import {StorageService} from 'src/app/services/storage.service';
import {selectModifiedAuthUsers} from '../../../store/entities/auth-user/auth-user.selectors';
import {AppState} from '../../../store/reducers';
import {AuthUser} from '../../../store/entities/auth-user/auth-user.model';
import {Router} from '@angular/router';
import {AuthService} from '../../../services/auth.service';
import {Component, Input, OnInit} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {IncidentNotificationType, Menu} from 'src/app/interfaces/global.interface';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ChangePasswordComponent} from '../change-password/change-password.component';
import {IncidentNotification} from 'src/app/store/entities/notification/notification.model';
// import {
//     ProcessingIncidentService
// } from 'src/app/modules/incidents/reported-incident-pagable/more-incident-detail/processing-incident.service';
import {selectNotificationByType} from 'src/app/store/entities/notification/notification.selectors';
import {ProfileComponent} from '../profile/profile.component';

@Component({
    selector: 'app-dash-layout',
    templateUrl: './dash-layout.component.html',
    styleUrls: ['./dash-layout.component.scss']
})
export class DashLayoutComponent implements OnInit {

    isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
        .pipe(
            map(result => result.matches),
            shareReplay()
        );
    user$: Observable<AuthUser>;
    @Input() menu: Menu;

    @Input() menuObservable$: BehaviorSubject<Menu>;
    sideMenu: Menu;
    at: string;
    gispUrl: string;
    institutionName: any;
    incommingTickets$: Observable<IncidentNotification[]>;

    constructor(
        private breakpointObserver: BreakpointObserver,
        private authService: AuthService,
        private router: Router,
        private store: Store<AppState>,
        private dialog: MatDialog,
        private storage: StorageService,
        // private processingIncidentService: ProcessingIncidentService,
    ) {
        this.user$ = this.store.pipe(select(selectModifiedAuthUsers), map(users => users[0]));
        this.at = this.storage.getItem('currentClient');
        this.institutionName = this.storage.getItem('institutionName');
    }

    ngOnInit() {
        this.sideMenu = this.menu;
        // this.processingIncidentService.getIncidentNotifications(); // gets notifications
        this.incommingTickets$ = this.store.pipe(select(selectNotificationByType({id: IncidentNotificationType.external})));
    }

    openChangePasswordDialog() {
        const dialogConfig = new MatDialogConfig();
        // dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = '50%';
        // dialogConfig.height = '95vh';
        // dialogConfig.position = { top: '0', right: '0' };
        dialogConfig.panelClass = 'incident-details';
        this.dialog.open(ChangePasswordComponent, dialogConfig);
    }

    logout() {
        this.authService.logout();
        // this.router.navigateByUrl('/');
    }

    cleanStorage() {
        //
        this.storage.clearStorage();
    }

    profile() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.width = '80%';
        dialogConfig.panelClass = 'incident-details';
        this.dialog.open(ProfileComponent, dialogConfig);
    }
}
