import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';

import {ClientDtoInput, ClientOnBehafDtoInput, InstitutionServiceDtoInput, Service} from './service.model';
import {PageableParam} from 'src/app/interfaces/global.interface';

export const loadServices = createAction(
    '[Service/API] Load Services',
    props<{ services: Service[] }>()
);

export const addService = createAction(
    '[Service/API] Add Service',
    props<{ service: Service }>()
);

export const upsertService = createAction(
    '[Service/API] Upsert Service',
    props<{ service: Service }>()
);

export const addServices = createAction(
    '[Service/API] Add Services',
    props<{ services: Service[] }>()
);

export const upsertServices = createAction(
    '[Service/API] Upsert Services',
    props<{ services: Service[] }>()
);

export const updateService = createAction(
    '[Service/API] Update Service',
    props<{ service: Update<Service> }>()
);

export const updateServices = createAction(
    '[Service/API] Update Services',
    props<{ services: Update<Service>[] }>()
);

export const deleteService = createAction(
    '[Service/API] Delete Service',
    props<{ id: number }>()
);

export const deleteServices = createAction(
    '[Service/API] Delete Services',
    props<{ ids: number[] }>()
);

export const clearServices = createAction(
    '[Service/API] Clear Services'
);

/////// Effect Actions \\\\\\\\\\\
export const listAllServices = createAction(
    '[Service/API] List All Services',
    props<{ pageableParam?: PageableParam }>()
);

export const listAllServicesForOption = createAction(
    '[Service/API] List All Services For Options',
    props<{ pageableParam?: PageableParam }>()
);
export const loadSubScribedservices = createAction(
    '[ServiceType/API] Get All loadSubScribedservices by institution',
    props<{ instutionUuid: string }>()
);

export const getInstitutionServices = createAction(
    '[Service/API] Get Institution Services',
    props<{ institutionId: number }>()
);


export const getAllInstitutionResourcesByService = createAction(
    '[Service/API] Get ResourcesByService',
    props<{ institutionServiceUuid: String }>()
);

export const getServiceToSubscribeByinstitutionUuid = createAction(
    '[Service/API] Get Institution Services To Subscribe',
    props<{ institutionUuid: String }>()
);

export const getServiceByUuid = createAction(
    '[Service/API] Get Institution Service By Uid',
    props<{ uuid: string }>()
);

// subscribe To Service
export const subscribeToService = createAction(
    '[Service/API] subscribe To Service ',
    props<{ ClientDtoInput: ClientDtoInput }>()
);

// add new service
export const addNewService = createAction(
    '[Service/API] Add New Service',
    props<{ institutionService: InstitutionServiceDtoInput }>()
);

export const subscibeClientOnBehalf = createAction(
    '[Service/API] Add New subscibe Client OnBehalf Service',
    props<{ clientDetails: ClientOnBehafDtoInput, viewingType: string }>()
);


export const updateInstitutionService = createAction(
    '[Service/API] Update Service',
    props<{ institutionService: InstitutionServiceDtoInput; id: number }>()
);

export const deactivateInstitutionService = createAction(
    '[Service/API] Update Service',
    props<{ uuid: string }>()
);

export const deleteInstitutionService = createAction(
    '[Service/API] delete Institution Service',
    props<{ institutionServiceId: number }>()
);

export const servicesSubscribedInMyInstByClient = createAction(
    '[ServiceType/API] list client Scribed services by institution',
    props<{ instutionUuid: string, option: 'FEW' | 'MANY' }>()
);

export const copyAssignedUsersToAnotherService = createAction(
    '[ServiceType/API] copy Assigned Users To Another Service',
    props<{ serviceToUuidList: [], serviceFromUuid: string, }>()
);

export const deleteMyServiceSubscriptionFromMyClientServiceSUbscription = createAction(
    '[Institution/API] unsubscribe client Using Client Uuid and Service Uuid from My service ',
    props<{ institutionUuid?: string, serviceUuid: string, id?: number, userUuid?: string }>()
);

export const listAllServicesFewFields = createAction(
    '[Service/API] List All Services few fields',
    props<{ pageableParam?: PageableParam }>()
);

export const loadSubScribedservicesFewFields = createAction(
    '[ServiceType/API] Get All loadSubScribedservicesFewFields by institution',
    props<{ instutionUuid: string }>()
);

export const nonEmployeeSubscribedServicesByProviderInstitution = createAction(
    '[ServiceType/API] Get All non employee subscribed services by institution',
    props<{ instutionUuid: string, option?:'FEW'|'MANY', userUuid?: string }>()
);

