
<div class="mat-elevation-z8">
    <mat-form-field appearance="standard">
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input>
    </mat-form-field>
    <table mat-table [dataSource]="dataSource">
  
      <!-- Position Column -->
      <ng-container matColumnDef="no">
        <mat-header-cell *matHeaderCellDef> No. </mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index"> {{i + 1 }}</mat-cell>
      </ng-container>
  
      <ng-container matColumnDef="firstName">
        <mat-header-cell *matHeaderCellDef> First Name </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.firstName}} </mat-cell>
      </ng-container>
  
      <ng-container matColumnDef="middleName">
        <mat-header-cell *matHeaderCellDef> Middle Name </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.middleName}} </mat-cell>
      </ng-container>
  
      <ng-container matColumnDef="lastName">
        <mat-header-cell *matHeaderCellDef> Last Name </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.lastName}} </mat-cell>
      </ng-container>
  
      <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.email}} </mat-cell>
      </ng-container>
  
      <ng-container matColumnDef="phone">
        <mat-header-cell *matHeaderCellDef> Phone </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.phone}} </mat-cell>
      </ng-container>
  
      <ng-container matColumnDef="designationCode">
        <mat-header-cell *matHeaderCellDef> Designation </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.designationCode}} </mat-cell>
      </ng-container>
  
      <!-- <ng-container matColumnDef="checkNumberOrId">
        <th mat-header-cell *matHeaderCellDef> Check Number/Id </th>
        <td mat-cell *matCellDef="let element"> {{element.checkNumberOrId}} </td>
      </ng-container> -->
  
      <!-- <ng-container matColumnDef="departmentTrackingCode">
        <th mat-header-cell *matHeaderCellDef> Department Code </th>
        <td mat-cell *matCellDef="let element"> {{element.departmentTrackingCode}} </td>
      </ng-container>
  
      <ng-container matColumnDef="branchTrackingCode">
        <th mat-header-cell *matHeaderCellDef> Branch Code </th>
        <td mat-cell *matCellDef="let element"> {{element.branchTrackingCode}} </td>
      </ng-container> -->
  
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        <!-- Row shown when there is no matching data. -->
        <mat-header-cell class="mat-row" *matNoDataRow>
        <mat-cell class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</mat-cell>
        </mat-header-cell>
    </table>  
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Select page"></mat-paginator>

</div>
