import { initializedPageableParameter, PageableParam } from 'src/app/interfaces/global.interface';
import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { IncidentResponse, IncidentResponseDtoInput } from './incident-response.model';
import { Incident } from '../incident/incident.model';

export const loadIncidentResponses = createAction(
  '[IncidentResponse/API] Load IncidentResponses',
  props<{ incidentResponses: IncidentResponse[] }>()
);

export const addIncidentResponse = createAction(
  '[IncidentResponse/API] Add IncidentResponse',
  props<{ incidentResponse: IncidentResponse }>()
);

export const upsertIncidentResponse = createAction(
  '[IncidentResponse/API] Upsert IncidentResponse',
  props<{ incidentResponse: IncidentResponse }>()
);

export const addIncidentResponses = createAction(
  '[IncidentResponse/API] Add IncidentResponses',
  props<{ incidentResponses: IncidentResponse[] }>()
);

export const upsertIncidentResponses = createAction(
  '[IncidentResponse/API] Upsert IncidentResponses',
  props<{ incidentResponses: IncidentResponse[] }>()
);

export const updateIncidentResponse = createAction(
  '[IncidentResponse/API] Update IncidentResponse',
  props<{ incidentResponse: Update<IncidentResponse> }>()
);

export const updateIncidentResponses = createAction(
  '[IncidentResponse/API] Update IncidentResponses',
  props<{ incidentResponses: Update<IncidentResponse>[] }>()
);

export const deleteIncidentResponse = createAction(
  '[IncidentResponse/API] Delete IncidentResponse',
  props<{ id: string }>()
);

export const deleteIncidentResponses = createAction(
  '[IncidentResponse/API] Delete IncidentResponses',
  props<{ ids: string[] }>()
);

export const clearIncidentResponses = createAction(
  '[IncidentResponse/API] Clear IncidentResponses'
);

///////// A - P - I ///////////

export const addUpdateIncidentResponse = createAction(
  '[IncidentResponse/API] addUpdate Incident Response',
  props<{ incidentResponse: IncidentResponseDtoInput }>()
);

export const getAllIncidentResponses = createAction(
  '[IncidentResponse/API] get All Incident Responses',
  props<{ pageparam: PageableParam }>()
);

export const getIncidentResponseByUserUuid = createAction(
  '[IncidentResponse/API] Get Incident Response By UserUuid',
  props<{ userUuid: string; pageparam: PageableParam }>()
);

export const getIncidentResponseByUuid = createAction(
  '[IncidentResponse/API] get Incident Response ByUuid',
  props<{ incidentResponseUuid: string }>()
);

export const getAllIncidentResponsesByIncident = createAction(
  '[IncidentResponse/API] get All Incident Responses By Incident',
  props<{ incidentUuid: string; pageparam: PageableParam  }>()
);

export const updateIncidentResponseToSeen = createAction(
  '[IncidentResponse/API] update incident response to seen',
  props<{ incidentResponseUuid: string, incident: Incident }>()
);

