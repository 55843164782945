import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Institution, ServiceList } from './institution.model';
import * as InstitutionActions from './institution.actions';

export const institutionsFeatureKey = 'institutions';

export interface State extends EntityState<Institution> {
  // additional entities state properties
  // institutionServices: ServiceList[];
}

export const adapter: EntityAdapter<Institution> = createEntityAdapter<Institution>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  // institutionServices: []
});


export const reducer = createReducer(
  initialState,
  on(InstitutionActions.loadInstitutionServices,
    (state, action) => ({ ...state, institutionServices: action.institutionServices })
  ),
  on(InstitutionActions.addInstitution,
    (state, action) => adapter.addOne(action.institution, state)
  ),
  on(InstitutionActions.upsertInstitution,
    (state, action) => adapter.upsertOne(action.institution, state)
  ),
  on(InstitutionActions.addInstitutions,
    (state, action) => adapter.addMany(action.institutions, state)
  ),
  on(InstitutionActions.upsertInstitutions,
    (state, action) => adapter.upsertMany(action.institutions, state)
  ),
  on(InstitutionActions.updateInstitution,
    (state, action) => adapter.updateOne(action.institution, state)
  ),
  on(InstitutionActions.updateInstitutions,
    (state, action) => adapter.updateMany(action.institutions, state)
  ),
  on(InstitutionActions.deleteInstitution,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(InstitutionActions.deleteInstitutions,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(InstitutionActions.loadInstitutions,
    (state, action) => adapter.setAll(action.institutions, state)
  ),
  on(InstitutionActions.loadProviderInstitutions,
    (state, action) => adapter.setAll(action.institutions, state)
  ),
  on(InstitutionActions.clearInstitutions,
    state => adapter.removeAll(state)
  ),
);

// export const getInstitutionServices = (state: State) => state.institutionServices;

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
