<div  class="row no-print" *ngIf="incidentNavOptions">
    <div class="col-md-12 group-buttons">
      <section >
      <span  *ngFor="let option of incidentNavOptions?.actions; let i = index">
          <button
          mat-raised-button
          title="{{ option?.title }}"
          class="nav-btn"
          [style.background-color]="option?.status !=selected ? '#fffcf7':null"
          [ngClass]="{'active-button': selected == option?.status}"
          (click)="optionSelected(option?.status)"
          >
       <span class="p-3">{{ option?.title }}</span>
        <ng-container *ngIf="incidentNavOptions?.notifications$| async as notifications">  
         <ng-container *ngIf="!option?.noCounts">
            <ng-container *ngIf="incidentNavOptions?.accessedFrom =='myReported'">
                <span class="count">
                  <ng-container *ngIf="option?.status == 'individualDRAFT'"> {{notifications[0]?.myReportedStatuses?.draft || 0 | number}}</ng-container>
                  <ng-container *ngIf="option?.status == 'individualSUBMITTED'"> {{notifications[0]?.myReportedStatuses?.open || 0 | number}} </ng-container>
                  <ng-container *ngIf="option?.status == 'individualRESOLVED'"> {{notifications[0]?.myReportedStatuses?.resolved || 0 | number}} </ng-container>
                  <ng-container *ngIf="option?.status == 'individualCLOSED'"> {{notifications[0]?.myReportedStatuses?.closed || 0 | number}}</ng-container>            
                  <ng-container *ngIf="option?.status == 'individualON_HOLD'"> {{notifications[0]?.myReportedStatuses?.onHold || 0 | number}} </ng-container>
                </span> 
           </ng-container>
           
            <ng-container *ngIf="incidentNavOptions?.accessedFrom =='assigned'">
                <span class="count">
                  <ng-container *ngIf="option?.status == 'assignedSUBMITTED'"> {{notifications[0]?.myAssignedStatuses?.open || 0 | number}}</ng-container>
                  <ng-container *ngIf="option?.status == 'assignedASSIGNED'"> {{notifications[0]?.myAssignedStatuses?.assignedToDept || 0 | number}} </ng-container>
                  <ng-container *ngIf="option?.status == 'assignedRESOLVED'"> {{notifications[0]?.myAssignedStatuses?.resolved || 0 | number}} </ng-container>
                  <ng-container *ngIf="option?.status == 'assignedCLOSED'"> {{notifications[0]?.myAssignedStatuses?.closed || 0 | number}}</ng-container>            
                  <ng-container *ngIf="option?.status == 'assignedON_HOLD'"> {{notifications[0]?.myAssignedStatuses?.onHold || 0 | number}} </ng-container>
                </span> 
           </ng-container>
           
            <ng-container *ngIf="incidentNavOptions?.accessedFrom =='section'">
                <span class="count">
                  <ng-container *ngIf="option?.status == 'secINCOMMING'"> {{notifications[0]?.sectionStatuses?.submitted || 0 | number}}</ng-container>
                  <ng-container *ngIf="option?.status == 'secSUBMITTED'"> {{notifications[0]?.sectionStatuses?.open || 0 | number}} </ng-container>
                  <ng-container *ngIf="option?.status == 'secASSIGNED'"> {{notifications[0]?.sectionStatuses?.assigned || 0 | number}} </ng-container>
                  <ng-container *ngIf="option?.status == 'secRESOLVED'"> {{notifications[0]?.sectionStatuses?.resolved || 0 | number}}</ng-container>            
                  <ng-container *ngIf="option?.status == 'secCLOSED'"> {{notifications[0]?.sectionStatuses?.closed || 0 | number}} </ng-container>
                  <ng-container *ngIf="option?.status == 'secON_HOLD'"> {{notifications[0]?.sectionStatuses?.onHold || 0 | number}} </ng-container>
                </span> 
           </ng-container>
           
            <ng-container *ngIf="incidentNavOptions?.accessedFrom =='department'">
                <span class="count">
                  <ng-container *ngIf="option?.status == 'deptINCOMMING'"> {{notifications[0]?.departmentStatus?.submitted || 0 | number}}</ng-container>
                  <ng-container *ngIf="option?.status == 'deptSUBMITTED'"> {{notifications[0]?.departmentStatus?.open || 0 | number}}</ng-container>
                  <ng-container *ngIf="option?.status == 'deptRESOLVED'"> {{notifications[0]?.departmentStatus?.resolved || 0 | number}} </ng-container>
                  <ng-container *ngIf="option?.status == 'deptCLOSED'"> {{notifications[0]?.departmentStatus?.closed || 0 | number}}</ng-container>            
                  <ng-container *ngIf="option?.status == 'deptON_HOLD'"> {{notifications[0]?.departmentStatus?.onHold || 0 | number}} </ng-container>
                </span> 
           </ng-container>
           
            <ng-container *ngIf="incidentNavOptions?.accessedFrom =='incomming'">
                <span class="count">
                  <ng-container *ngIf="option?.status == 'ONBEHALFDRAFT'"> {{notifications[0]?.incommingStatuses?.draft || 0 | number}}</ng-container>
                  <ng-container *ngIf="option?.status == 'incomingSUBMITTED'"> {{notifications[0]?.incommingStatuses?.open || 0 | number}} </ng-container>
                  <ng-container *ngIf="option?.status == 'incomingASSIGNED'"> {{notifications[0]?.incommingStatuses?.assigned || 0 | number}} </ng-container>
                  <ng-container *ngIf="option?.status == 'incomingRESOLVED'"> {{notifications[0]?.incommingStatuses?.resolved || 0 | number}}</ng-container>            
                  <ng-container *ngIf="option?.status == 'incomingCLOSED'"> {{notifications[0]?.incommingStatuses?.closed || 0 | number}} </ng-container>
                  <ng-container *ngIf="option?.status == 'incomingON_HOLD'"> {{notifications[0]?.incommingStatuses?.onHold || 0 | number}} </ng-container>
                </span> 
           </ng-container>
           
            <ng-container *ngIf="incidentNavOptions?.accessedFrom =='outgoing'">
                <span class="count">
                  <ng-container *ngIf="option?.status == 'outDRAFT'"> {{notifications[0]?.outgoingStatuses?.draft || 0 | number}}</ng-container>
                  <ng-container *ngIf="option?.status == 'outSUBMITTED'"> {{notifications[0]?.outgoingStatuses?.open || 0 | number}} </ng-container>
                  <ng-container *ngIf="option?.status == 'outRESOLVED'"> {{notifications[0]?.outgoingStatuses?.resolved || 0 | number}} </ng-container>
                  <ng-container *ngIf="option?.status == 'outCLOSED'"> {{notifications[0]?.outgoingStatuses?.closed || 0 | number}}</ng-container>            
                </span> 
           </ng-container>
           
            <ng-container *ngIf="incidentNavOptions?.accessedFrom =='internal'">
                <span class="count">
                  <ng-container *ngIf="option?.status == 'internalDRAFT'"> {{notifications[0]?.internalStatuses?.draft || 0 | number}}</ng-container>
                  <ng-container *ngIf="option?.status == 'internalSUBMITTED'"> {{notifications[0]?.internalStatuses?.open || 0 | number}} </ng-container>
                  <ng-container *ngIf="option?.status == 'internalASSIGNED'"> {{notifications[0]?.internalStatuses?.assigned || 0 | number}} </ng-container>
                  <ng-container *ngIf="option?.status == 'internalRESOLVED'"> {{notifications[0]?.internalStatuses?.resolved || 0 | number}}</ng-container>            
                  <ng-container *ngIf="option?.status == 'internalCLOSED'"> {{notifications[0]?.internalStatuses?.closed || 0 | number}}</ng-container>            
                  <ng-container *ngIf="option?.status == 'internalON_HOLD'"> {{notifications[0]?.internalStatuses?.onHold || 0 | number}}</ng-container>            
                </span> 
           </ng-container>
           
            <ng-container *ngIf="incidentNavOptions?.accessedFrom =='client'">
                <span class="count">
                  <ng-container *ngIf="option?.status == 'clientDRAFT'"> {{notifications[0]?.outgoingStatuses?.draft || 0 | number}}</ng-container>
                  <ng-container *ngIf="option?.status == 'clientSUBMITTED'"> {{notifications[0]?.outgoingStatuses?.open || 0 | number}} </ng-container>
                  <ng-container *ngIf="option?.status == 'clientRESOLVED'"> {{notifications[0]?.outgoingStatuses?.resolved || 0 | number}} </ng-container>
                  <ng-container *ngIf="option?.status == 'clientCLOSED'"> {{notifications[0]?.outgoingStatuses?.closed || 0 | number}}</ng-container>            
                 </span> 
           </ng-container>
         </ng-container>
        </ng-container>
        </button>
      </span>
      </section>
    </div>
  </div>
