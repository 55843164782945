import {createSelector} from '@ngrx/store';
import {AppState} from './../../../reducers/index';
import * as fromServiceReducer from './service.reducer';
import {Service} from './service.model';

export const currentState = (state: AppState) => (state[fromServiceReducer.servicesFeatureKey]);

export const selectAllServices = createSelector(currentState, fromServiceReducer.selectAll);

export const selectTotalServices = createSelector(currentState, fromServiceReducer.selectTotal);

export const selectModifiedServices = createSelector(selectAllServices, (services: Service[]) => {
    return services.map((service) => {
        return {
            ...service,
            serviceType: service?.institutionServiceType?.name,
            confimation: service.toBeconfirmedAfterSubscription ? 'YES' : 'NO',
            isActive: service.active ? 'ACTIVE' : 'INACTIVE',

        };
    });
});


export const selectActiveServices = (params: { isActive: boolean }) => createSelector(
    selectModifiedServices,
    (services: Service[]) => {
        return services.filter((service) => service.active === params.isActive);
    }
);

export const selectSubscribedServices = createSelector(selectAllServices, (services: Service[]) => {
    return services.map((service) => {

        return {
            ...service,
            serviceName: service?.name,
        };
    });
});

export const selectServiceById = (params: { id: number }) => createSelector(
    selectModifiedServices,
    (services: Service[]) => {

        return services.find((service) => service.id === params.id);
    }
);

export const selectServiceByUuid = (params: { uuid: string }) => createSelector(
    selectModifiedServices,
    (services: Service[]) => {
        return services.find((service) => service.uuid === params.uuid);
    }
);
