import { DatePipe } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxPermissionsService } from 'ngx-permissions';
import { AppComponent } from 'src/app/app.component';
// import { CompanionFormComponent } from 'src/app/modules/special-groups/companions/companion-form/companion-form.component';
import { AuthService } from 'src/app/services/auth.service';
import { SettingsService } from 'src/app/services/settings.service';
import { StorageService } from 'src/app/services/storage.service';
import { DynamicFormService } from '../components/dynamic-forms-components/dynamic-form.service';
import { UserTypesEnum } from '../enums/user-types.enum';
import { camelToSpaced, capitalizeFirstLetter, countOccurrences, fieldsArrayFromObj, singularize, valueIncludesEither, valueIsEither } from '../functions/functions';

@Component({
  selector: 'app-all-details-component',
  templateUrl: './all-details-component.component.html',
  styleUrls: ['./all-details-component.component.scss']
})
export class AllDetailsComponentComponent implements OnInit {
  @Input() entity:any;
  title;
  allFields:{key:string, value:any}[];

  arrays:any[];
  objects:any[];
  fields:any[];

  baseFields:any[];
  statusFields:any[];
  nameFields:any[];

  userType:string;

  baseFields_ = ['id','uid','created','updated','deleted','active','last','Active']
  statusFields_ = ['status']
  nameFields_ = ['name','title']
  nameFields_inner = ['name','title','approval','comment','details']

  sensitiveFields = ['id','uid','deleted','active','last'];

  objectName;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<AppComponent>,
    private settingsService:SettingsService,
    private storage:StorageService,
    private authService:AuthService,
    private permissionsService: NgxPermissionsService,
  ) { 
    if(!this.isObject(data)) this.closeModal(true);
    this.entity = data;
    this.objectName = data.constructor.name;
    this.userType = this.storage.getItem('userType');
  }


  async ngOnInit(): Promise<void> {
    this.allFields = fieldsArrayFromObj(this.entity);

    //format fields
    this.allFields = this.formatFields(this.allFields)

    // hide ids if not staff
    if(!(await this.permissionsService.hasPermission(['SUPER_ADMIN']))) {
      this.allFields = this.allFields.filter(x => !valueIncludesEither(x.key,this.sensitiveFields)) 
    }

    // fields
    this.fields = this.allFields.filter(x=> this.isField(x.value))
    
    this.baseFields = this.fields.filter(x => valueIncludesEither(x.key,this.baseFields_))
    this.nameFields = this.fields.filter(x => valueIncludesEither(x.key,this.nameFields_))
    this.statusFields = this.fields.filter(x => valueIncludesEither(x.key,this.statusFields_))
   
    this.fields = this.fields.filter(x => {
       return !valueIncludesEither(x.key,this.baseFields_) 
       && !valueIncludesEither(x.key,[...this.statusFields_,...this.nameFields_])
    })

    this.arrays = this.allFields.filter(x=> this.isArray(x.value))
    this.objects = this.allFields.filter(x=> this.isObject(x.value))

    this.fields = this.fields.concat(this.objects)
    this.title = this.nameFields.length? this.nameFields[0].value: "Object"

    //clear empty fields
    this.arrays = this.arrays.filter(x => !!x.value?.length);
    this.fields = this.fields.filter(x => !!x.value);

    // group fiels if fewer
    if(this.statusFields.length < 3) this.nameFields = this.nameFields.concat(this.statusFields);
    if(this.fields.length < 3) this.nameFields = this.nameFields.concat(this.fields);
    if(this.baseFields.length < 3) this.nameFields = this.nameFields.concat(this.baseFields);
    
  }
  

  formatFields(fields){
    if(!this.isArray(fields)) return fields;
    return fields?.map(x =>{ 
      return {
        value: countOccurrences(x.value,':') === 2?
        new DatePipe('en-GB').transform(x.value,'HH:mm  |  dd - MMM - y '): x.value, 
        key:capitalizeFirstLetter(camelToSpaced(x.key))
    }})
  }


  // for objects
 getFields(object){
    if(typeof object !== "object") return object;
    let fields = fieldsArrayFromObj(object)
    
    // hide ids if not staff
    let t = setTimeout(async ()=>{
      if(!(await this.permissionsService.hasPermission(['SUPER_ADMIN']))) {
        fields = fields.filter(x => !valueIncludesEither(x.key,this.sensitiveFields)) 
      }
    },0)
   
    return  this.formatFields(fields);
  }
    

  getFirstNameField(object){
    if(typeof object !== "object") return object;
    return this.getFields(object)?.find(x => valueIncludesEither(x.key,this.nameFields_inner));
  }


  isField(item){
    return typeof item === 'string' || typeof item === 'number'
  }


  toSingular(value){
    return singularize(value).replace("List","")
  }


  isArray(item){
    return Array.isArray(item)
  }

  
  isObject(item){
    return ((typeof item === 'function') || (typeof item === 'object')) && typeof item !== 'string' 
    && !this.isArray(item);
  }


  includes(x:string,y:string){
    if(typeof x !== 'string') return false;
    return x.includes(y)
  }


  viewDetails(data){
    this.settingsService.openDialogModal({
      component: AllDetailsComponentComponent,
      width: '70%',
      panelClass: ['full-screen-dialog'],
      height: '100%',
      position: { top: '0', right: '0' },
      // width: '70%',
      // height: '90%',
      data:data,
    });
  }
  

  closeModal(close: boolean): void {
    if (close) this.dialogRef.close();
  }

}
