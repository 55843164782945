import {Roster} from '../../roster/roster.model';
import {Branch} from '../branch/branch.model';
import {Department} from '../department/department.model';
import {Designation} from '../designation/designation.model';
import {Institution, TascoGroup} from '../institution/institution.model';
import {Role} from '../role/role.model';
import {SearchFieldsDtoInput, SearchOperationType} from '../../../../interfaces/global.interface';

export interface User {
    id?: number;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    accountNonLocked?: boolean;
    active?: boolean;
    checkNumber?: string;
    institutionId?: number;
    credentialsNonExpired?: boolean;
    deleted?: boolean;
    email?: string;
    name?: string;
    enabled?: boolean;
    firstLogin?: boolean;
    passwordExpirationDate?: string;
    phone?: number;
    rolesList?: Role[];
    userAccounts?: User[];
    username?: string;
    institution?: Institution;
    roles?: string;
    fullName?: string;
    headOfDepartment?: boolean;
    branch?: Branch;
    registeredByBranch?: Branch;
    designation?: Designation;
    department?: Department;
    headOfUnit?: boolean;
    headOfUnitText?: boolean;
    accountNonExpired?: boolean;
    authorities?: GrantedAuthority[];
    createdAt?: string;
    createdBy?: number;
    formRole?: number;
    hasHandover?: boolean;
    isOpen?: boolean;
    jobTitle?: string;
    lastLogin?: string;
    nationalId?: string;
    password?: string;
    passwordExpired?: boolean;
    permissionIdsList?: number[];
    picture?: string;
    rememberToken?: string;
    resetLinkSent?: boolean;
    rolesListStrings?: string[];
    rosters?: Roster[];
    tokenCreatedAt?: string;
    updatedAt?: string;
    updatedBy?: number;
    userInstitutionId?: number;
    uuid?: string;
    userType?: string;
    reportingCapability?: string;
    modDepartment?: string;
    registeredByInstitution?: Institution;
    tascoGroup?:TascoGroup


}

export interface UserDtoInput {
    uuid?: string;
    departmentId?: number;
    lastName?: string;
    phone?: string;
    firstName?: string;
    institutionUuid?: string;
    middleName?: string;
    designationId?: number;
    email?: string;
    checkNumber?: string;
}

export interface GrantedAuthority {
    authority?: string;
}

export interface UserFromOtherSystemDtoInput {
    firstName?: string;
    designationCode?: string;
    middleName?: string;
    departmentTrackingCode?: string;
    lastName?: string;
    phone?: string;
    branchTrackingCode?: string;
    email?: string;
    type?: string;
    checkNumberOrId?: string;
}

export interface DepartmentUser {
    id?: number;
    uuid?: string;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    fullName?: string;
    department?: Department;
}

export const UserSearchFields: SearchFieldsDtoInput[] = [
    {
        fieldName: 'email',
        searchType: SearchOperationType.Like,
    },
    {
        fieldName: 'phone',
        searchType: SearchOperationType.Like,
    },
    {
        fieldName: 'firstName',
        searchType: SearchOperationType.Like,
    },
    {
        fieldName: 'middleName',
        searchType: SearchOperationType.Like,
    },
    {
        fieldName: 'checkNumber',
        searchType: SearchOperationType.Like,
    },
    {
        fieldName: 'lastName',
        searchType: SearchOperationType.Like,
    },
];
