import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { isObservable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { SettingsService } from 'src/app/services/settings.service';
import { listAnimation } from 'src/app/shared/animations/basic-animation';
import { DynamicFormService } from '../dynamic-form.service';
import { FieldConfig, FieldType } from '../field.interface';
import { fadeIn } from 'src/app/shared/animations/router-animation';


@Component({
    exportAs: 'dynamicForm',
    selector: 'main-dynamic-form',
    templateUrl: 'main-dynamic-form.component.html',
    animations: [listAnimation, fadeIn],
    styleUrls: ['./main-dynamic.component.scss']

})
export class MainDynamicFormComponent implements OnChanges, AfterViewChecked {
    @Input() fields: FieldConfig[] = [];
    @Input() formData: any = null;
    @Input() loading = false;
    @Input() spaced = false;
    @Input() loadingMessage = 'Please Wait, Loading ...';
    @Input() form?: UntypedFormGroup;
    @Output() submit: EventEmitter<any> = new EventEmitter<any>();
    @Output() fieldData: EventEmitter<any> = new EventEmitter<any>();
    @Output() closeForm: EventEmitter<any> = new EventEmitter<any>();

    // allFields = [];
    selectedKeyValue = [];
    sortedFields: FieldConfig[] = [];
    isLoading = false;
    get value() {
        return this.form?.value;
    }
    constructor(
        private readonly changeDetectorRef: ChangeDetectorRef,
        private dynamicFormService: DynamicFormService, private settings: SettingsService) { }


    cancelForm() {
        this.closeForm.emit();
    }


    ngAfterViewChecked(): void {
        this.changeDetectorRef.detectChanges();
    }

    ngOnChanges() {
        this.isLoading = this.loading;
        this.sortedFields = this.fields.sort((firstEl, secondEl) => {
            if ((firstEl.order || 999) < (secondEl.order || 999)) { return -1; }
            if ((firstEl.order || 999) > (secondEl.order || 999)) { return 1; }
            return 0;
        });
    }

    isVisible(field: FieldConfig, option = null): boolean {
        const key = field.key as string;
        let visible = false;
        visible = this.dynamicFormService.isVisible(field, this.form, option);

        if (field.visible === false) { return false; }
        if (field.type !== FieldType.button) {
            if (!visible) {
                this.form?.controls[key]?.setValidators(null);
            } else {
                this.form?.controls[key]?.setValidators(this.dynamicFormService.bindValidations(field.validations || []));
            }
            this.form?.controls[key]?.updateValueAndValidity();
        }
        return visible;
    }


    async fieldValue(data: { value: string; field: any, object: any }) {
        this.fieldData.emit({ value: data.value, key: data.field.key, field: data.field, object: data.object });
        const dataField = this.sortedFields.find(f => f.filterValueKey === data.field.key);
        let option: any ;        
        if(isObservable(data.field.options)){
             option = await data.field.options?.pipe(map((items: any[]) => items ? items.find(opt => opt.value === data.value) : null), first()).toPromise();
        }else {        
             option =  (data.field.options || [])?.find(opt => opt.value === data.value) || null;
        }
        
        this.isVisible(data.field, option);
        if (dataField) {
            this.sortedFields = this.sortedFields.map((field) => {
                return {
                    ...field,
                };
            });
        }
    }

    onSubmit(event: Event) {
        this.submit.emit(this.dynamicFormService.onSubmit(event, this.form, this.sortedFields));
    }

    onClose() {
        this.closeForm.emit();
    }


}
