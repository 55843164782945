<router-outlet></router-outlet>

<!-- <ng-http-loader
  [entryComponent]="spinnerComponent"
  [backgroundColor]="'#ff0000'"
  [debounceDelay]="0"
  [extraDuration]="0"
  [minDuration]="0"
  [backdropBackgroundColor]="'#ffffff82'"
>
</ng-http-loader> -->


<ng-http-loader
  [backgroundColor]="'#30197b'"
  [debounceDelay]="0"
  [extraDuration]="0"
  [minDuration]="0"
  [backdropBackgroundColor]="'#ffffff82'"
  [spinner]="spinkit.skThreeBounce">
</ng-http-loader>
