import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { HelpdeskEmailNotification } from './helpdesk-email-notification.model';

export const loadHelpdeskEmailNotifications = createAction(
  '[HelpdeskEmailNotification/API] Load HelpdeskEmailNotifications',
  props<{ helpdeskEmailNotifications: HelpdeskEmailNotification[] }>()
);

export const addHelpdeskEmailNotification = createAction(
  '[HelpdeskEmailNotification/API] Add HelpdeskEmailNotification',
  props<{ helpdeskEmailNotification: HelpdeskEmailNotification }>()
);

export const upsertHelpdeskEmailNotification = createAction(
  '[HelpdeskEmailNotification/API] Upsert HelpdeskEmailNotification',
  props<{ helpdeskEmailNotification: HelpdeskEmailNotification }>()
);

export const addHelpdeskEmailNotifications = createAction(
  '[HelpdeskEmailNotification/API] Add HelpdeskEmailNotifications',
  props<{ helpdeskEmailNotifications: HelpdeskEmailNotification[] }>()
);

export const upsertHelpdeskEmailNotifications = createAction(
  '[HelpdeskEmailNotification/API] Upsert HelpdeskEmailNotifications',
  props<{ helpdeskEmailNotifications: HelpdeskEmailNotification[] }>()
);

export const updateHelpdeskEmailNotification = createAction(
  '[HelpdeskEmailNotification/API] Update HelpdeskEmailNotification',
  props<{ helpdeskEmailNotification: Update<HelpdeskEmailNotification> }>()
);

export const updateHelpdeskEmailNotifications = createAction(
  '[HelpdeskEmailNotification/API] Update HelpdeskEmailNotifications',
  props<{ helpdeskEmailNotifications: Update<HelpdeskEmailNotification>[] }>()
);

export const deleteHelpdeskEmailNotification = createAction(
  '[HelpdeskEmailNotification/API] Delete HelpdeskEmailNotification',
  props<{ id: string }>()
);

export const deleteHelpdeskEmailNotifications = createAction(
  '[HelpdeskEmailNotification/API] Delete HelpdeskEmailNotifications',
  props<{ ids: string[] }>()
);

export const clearHelpdeskEmailNotifications = createAction(
  '[HelpdeskEmailNotification/API] Clear HelpdeskEmailNotifications'
);

////////// A P I ////////////

export const listHelpdeskEmailNotifications = createAction(
  '[HelpdeskEmailNotification/API] List Helpdesk Email Notifications'
);

export const editHelpdeskEmailNotification = createAction(
  '[HelpdeskEmailNotification/API] Update Helpdesk Email Notifications',
  props<{ id: number; content: string}>()
);
