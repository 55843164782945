
import gql from 'graphql-tag';
import {basePageableGraphqlField} from '../../../base.graphql';

export const issueCategoryFields = `
  active
  createdAt
  createdBy
  deleted
  description
  id
  institutionId
  institutionUuid
  title
  updatedAt
  updatedBy
  uuid
`;

export const CREATE_ISSUE_CATEGORY = gql`
  mutation addUpdateIssueCategory( $issueCategory: IssueCategoryDtoInput){
    addUpdateIssueCategory( issueCategory: $issueCategory ){
      code
      data{
        ${issueCategoryFields}
      }
      message
    }
  }
`;

export const LIST_ALL_ISSUE_CATEGORYS = gql`
  query getAllInstitutionIssueCategories( $pageparam: PageableParamInput){
    getAllInstitutionIssueCategories( pageparam: $pageparam ){
      content{${issueCategoryFields}}
      size
      first
      totalPages
      size
      totalElements
      numberOfElements
    }
  }
`;
export const LIST_ALL_ISSUE_CATEGORY_FOR_LIST = gql`
  query getAllInstitutionIssueCategories( $pageparam: PageableParamInput){
    getAllInstitutionIssueCategories( pageparam: $pageparam ){
      content{
        id
        uuid
        title
      }
      ${basePageableGraphqlField}
    }
  }
`;

export const GET_ISSUE_CATEGORY_BY_UUID = gql`
  query getIssueCategoryByUuid( $issueCategoryUuid: String ){
    getIssueCategoryByUuid( issueCategoryUuid: $issueCategoryUuid ){
      code
      data{
        ${issueCategoryFields}
      }
      message
    }
  }
`;

export const ACTIVATE_DEACTIVATE_ISSUE_CATEGORY = gql`
  mutation activateDeactivateIssueCategory( $issueCategoryUuid: String ){
    activateDeactivateIssueCategory( issueCategoryUuid: $issueCategoryUuid ){
      code
      data{
        ${issueCategoryFields}
      }
      message
    }
  }
`;

export const DELETE_ISSUE_CATEGORY = gql`
  mutation deleteIssueCategory( $issueCategoryUuid: String ){
    deleteIssueCategory( issueCategoryUuid: $issueCategoryUuid ){
      code
      data{
        ${issueCategoryFields}
      }
      message
    }
  }
`;
export const GET_ISSUE_CATEGORY_SERVICE_UUID = gql`
  query listIssueCategoryByServiceUuid( $serviceUuid: String ){
    listIssueCategoryByServiceUuid( serviceUuid: $serviceUuid ){
        ${issueCategoryFields}
    }
  }
`;
