import gql from 'graphql-tag';

export const resourceTypesFields = `
active
createdAt
createdBy
deleted
id
institutionId
description
institutionUuid
title
updatedAt
updatedBy
uuid
`;

// resources




export const GET_MY_INSTITUTION_ALL_RESOURCE_TYPES = gql`
query getAllResourceType($pageparam: PageableParamInput){
  getAllResourceType(pageparam:$pageparam){
    content{
     ${resourceTypesFields}
    }
    first
    hasContent
    hasNext
    hasPrevious
    last
    nextOrLastPageable{
      pageSize
      pageNumber
    }
    nextPageable{
      pageSize
      pageNumber
    }
    number
    numberOfElements
    pageable{
      pageSize
      pageNumber
    }
    previousPageable{
      pageSize
      pageNumber
    }
    previousOrFirstPageable{
      pageSize
      pageNumber
    }
    size
    totalElements
    totalPages
    
  }
}
`;
export const GET_MY_INSTITUTION_ALL_RESOURCE_TYPES_FOR_LIST = gql`
query getAllResourceType($pageparam: PageableParamInput){
  getAllResourceType(pageparam:$pageparam){
    content{
      id
      uuid
      title
    }
  }
}
`;

export const ACTIVATE_AND_DEACTIVATE_RESOURCE_TYPE = gql`
mutation activateDeactivateResourceType($resourceTypeIdUuid: String){
  activateDeactivateResourceType(resourceTypeIdUuid:$resourceTypeIdUuid){
    code
    data{
      ${resourceTypesFields}
    }
    dataList{
      ${resourceTypesFields}
    }
    message
    status
  }
}
`;

export const SAVE_UPDATE_RESOURCE_TYPE = gql`
mutation addUpdateResourceType($resourceType: ResourceTypeDtoInput){
  addUpdateResourceType(resourceType:$resourceType){
    code
    data{
      ${resourceTypesFields}
    }
    dataList{
      ${resourceTypesFields}
    }
    message
    status
  }
}
`;


export const DELETE_RESOURCE_TYPE = gql`
mutation deleteResourceType($resourceTypeIdUuid: String){
  deleteResourceType(resourceTypeIdUuid:$resourceTypeIdUuid){
    code
    message
    status
    data{
      ${resourceTypesFields}
    }
    dataList{
      ${resourceTypesFields}
    }
  }
}
`;




