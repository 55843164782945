<app-modal-header
  (closeModal)="closeModal($event)"
  [title]="title"
></app-modal-header>

<div class="medium-font-size pb-3">

    <!-- NAME FIELDS OR GENERAL -->
    <ng-container>
        <!-- <div class="text-muted faded text-bold mb-2 px-3 px-md-3 px-lg-4 mt-3 text-uppercase"> {{objectName}} </div> -->
        <div *ngIf="nameFields.length"  baseFields class="py-2 pb-4 px-3 px-md-3 px-lg-4 h-100 mb-3 w-100 position-relative 
             content-item bg-primary-slightly-faded border-slightly-faded rounded-slight
            "style="--order:1"
            >
            <div class="row">
                <div class="col-md-12 mb-2">
                    <div class="bg-primary my-2 p-2 rounded text-white d-inline-block list-field">
                        {{nameFields[0].key}}: {{ nameFields[0].value }}
                    </div>
                </div>
                <ng-container *ngFor="let field of nameFields; let i=index">
                    <div *ngIf="i>0 || true" class="col-md-6 col-lg-4 px-md-3 px-lg-4 d-flex flex-column justify-content-between py-2">
                        <div class="text-primary text-bold">{{ field.key }}</div>
                        <div class="text-muted" >
                            <span *ngIf="!isObject(field.value); else showName">{{ field.value }}</span>
                            <ng-template #showName>
                                <span matRipple (click)="viewDetails(field.value)"
                                    class="px-2 border cursor-pointer rounded-slight d-inline-block">
                                    <span class="d-flex align-items-center">
                                        <span class="p-1"> <mat-icon class="" svgIcon="view"></mat-icon> </span>
                                        <span class="padd-left-5"> {{getFirstNameField(field.value)?.value}} </span> 
                                    </span>
                                </span>
                            </ng-template>
                        </div> 
                        <mat-divider></mat-divider>                                                               
                    </div>  
                </ng-container>
            </div>                                     
        </div>
    </ng-container>

    <!-- STATUS FIELDS -->
    <ng-container  *ngIf="statusFields.length >=3" >
        <div class="text-muted px-3 px-md-3 px-lg-4 faded text-bold my-2 mt-3"> STATUS </div>
        <div class="py-2 pb-4 px-3 px-md-3 px-lg-4 h-100 mb-3 w-100 position-relative 
             content-item bg-primary-slightly-faded border-slightly-faded rounded-slight
            "style="--order:1"
            >
            <div class="row">
                <ng-container *ngFor="let field of statusFields">
                    <div class="col-md-6 col-lg-4 px-md-3 px-lg-4 d-flex flex-column justify-content-between py-2">
                        <div class="text-primary text-bold">{{ field.key }}</div>
                        <div class="text-muted" >
                            <span *ngIf="!isObject(field.value); else showName">{{ field.value }}</span>
                            <ng-template #showName>
                                <span matRipple (click)="viewDetails(field.value)"
                                    class="px-2 border cursor-pointer rounded-slight d-inline-block">
                                    <span class="d-flex align-items-center">
                                        <span class="p-1"> <mat-icon class="" svgIcon="view"></mat-icon> </span>
                                        <span class="padd-left-5"> {{getFirstNameField(field.value)?.value}} </span> 
                                    </span>
                                </span>
                            </ng-template>
                        </div> 
                        <mat-divider></mat-divider>                                                              
                    </div>  
                </ng-container>
            </div>
        </div>
    </ng-container>
    
    <!-- NORMA FIELDS -->
    <ng-container *ngIf="fields.length >=3" >
        <div class="text-muted px-3 px-md-3 px-lg-4 faded text-bold my-2 mt-3"> BASIC DETAILS </div>
        <div class="py-2 pb-4 px-3 px-md-3 px-lg-4 h-100 mb-3 w-100 position-relative 
             content-item bg-primary-slightly-faded border-slightly-faded rounded-slight
            "style="--order:1"
            >
            <div class="row">
                <ng-container *ngFor="let field of fields">
                    <div class="col-md-6 col-lg-4 px-md-3 px-lg-4 d-flex flex-column justify-content-between py-2">
                        <div class="text-primary text-bold">{{ field.key }}</div>
                        <div class="text-muted" >
                            <span *ngIf="!isObject(field.value); else showName">{{ field.value }}</span>
                            <ng-template #showName>
                                <span matRipple (click)="viewDetails(field.value)"
                                    class="px-2 border cursor-pointer rounded-slight d-inline-block">
                                    <span class="d-flex align-items-center">
                                        <span class="p-1"> <mat-icon class="" svgIcon="view"></mat-icon> </span>
                                        <span class="padd-left-5"> {{getFirstNameField(field.value)?.value}} </span> 
                                    </span>
                                </span>
                            </ng-template>
                        </div> 
                        <mat-divider></mat-divider>                                                              
                    </div> 
                </ng-container>
            </div>  
        </div> 
    </ng-container>

    <!-- BASE ENTITY FIELDS -->
    <ng-container *ngIf="baseFields.length >=3">
        <div class="text-muted faded text-bold my-2 mt-3 px-3 px-md-3 px-lg-4"> ENTITY DETAILS </div>
        <div class="py-2 pb-4 px-3 px-md-3 px-lg-4 h-100 mb-3 w-100 position-relative 
         content-item bg-primary-slightly-faded border-slightly-faded rounded-slight
         "style="--order:1">
            <div class="row">
                <ng-container *ngFor="let field of baseFields">
                    <div class="col-md-6 col-lg-4 px-md-3 px-lg-4 d-flex flex-column justify-content-between py-2">
                        <div class="text-primary text-bold">{{ field.key }}</div>
                        <div class="text-muted" >
                            <span *ngIf="!isObject(field.value); else showName">{{ field.value }}</span>
                            <ng-template #showName>
                                <span matRipple (click)="viewDetails(field.value)"
                                    class="px-2 border cursor-pointer rounded-slight d-inline-block">
                                    <span class="d-flex align-items-center">
                                        <span class="p-1"> <mat-icon class="" svgIcon="view"></mat-icon> </span>
                                        <span class="padd-left-5"> {{getFirstNameField(field.value)?.value}} </span> 
                                    </span>
                                </span>
                            </ng-template>
                        </div> 
                        <mat-divider></mat-divider>                                                              
                    </div>  
                </ng-container>
            </div>
        </div>
    </ng-container>

    <!-- ARRAYS -->
    <ng-container *ngIf="arrays.length">
        <ng-container *ngFor="let arrayField of arrays">
            <div class="text-muted text-uppercase faded text-bold my-2 mt-3 px-3 px-md-3 px-lg-4">
                {{arrayField?.key}} ({{ arrayField.value.length}}) 
            </div>
            <div class="py-2 pb-4 px-3 px-md-3 px-lg-4 h-100 mb-3 w-100 position-relative 
            content-item bg-primary-slightly-faded border-slightly-faded rounded-slight
            "style="--order:1">
                <ng-container *ngFor="let object of arrayField.value; let i = index" >
                    <mat-accordion>
                        <div class="my-1">
                            <mat-expansion-panel (opened)="true" (closed)="false">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <span class="text-primary text-bold">
                                            <span class="faded"> {{i+1}} </span>
                                            <span class="padd-left-5 text-capitalize">{{getFirstNameField(object)?.value}} </span>
                                            <!-- <span class="padd-left-5 text-capitalize">{{toSingular(arrayField?.key)}} </span> -->
                                        </span>
                                    </mat-panel-title>
                                    <!-- <mat-panel-description>desc</mat-panel-description> -->
                                </mat-expansion-panel-header>
                                <div class="row ">
                                    <ng-container *ngFor="let field of getFields(object)">
                                        <div class="col-md-6 col-lg-4 px-md-3 px-lg-4 d-flex flex-column justify-content-between py-2">
                                            <div class="text-primary text-bold">{{ field.key }}</div>
                                            <div class="text-muted" >
                                                <span *ngIf="!isObject(field.value); else showName">{{ field.value }}</span>
                                                <ng-template #showName>
                                                    <span matRipple (click)="viewDetails(field.value)"
                                                        class="px-2 border cursor-pointer rounded-slight d-inline-block">
                                                        <span class="d-flex align-items-center">
                                                            <span class="p-1"> <mat-icon class="" svgIcon="view"></mat-icon> </span>
                                                            <span class="padd-left-5"> {{getFirstNameField(field.value)?.value}} </span> 
                                                        </span>
                                                    </span>
                                                </ng-template>
                                            </div> 
                                            <!-- <mat-divider></mat-divider>                                                               -->
                                        </div>  
                                    </ng-container>
                                </div>  
                            </mat-expansion-panel>
                        </div>
                      
                    </mat-accordion>
                </ng-container>
            </div> 
        </ng-container>
    </ng-container>
   
</div>    