import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { IssueCategory, IssueCategoryDtoInput } from './issue-category.model';
import { PageableParam } from 'src/app/interfaces/global.interface';

export const loadIssueCategorys = createAction(
  '[IssueCategory/API] Load IssueCategorys', 
  props<{ issueCategorys: IssueCategory[] }>()
);

export const addIssueCategory = createAction(
  '[IssueCategory/API] Add IssueCategory',
  props<{ issueCategory: IssueCategory }>()
);

export const upsertIssueCategory = createAction(
  '[IssueCategory/API] Upsert IssueCategory',
  props<{ issueCategory: IssueCategory }>()
);

export const addIssueCategorys = createAction(
  '[IssueCategory/API] Add IssueCategorys',
  props<{ issueCategorys: IssueCategory[] }>()
);

export const upsertIssueCategorys = createAction(
  '[IssueCategory/API] Upsert IssueCategorys',
  props<{ issueCategorys: IssueCategory[] }>()
);

export const updateIssueCategory = createAction(
  '[IssueCategory/API] Update IssueCategory',
  props<{ issueCategory: Update<IssueCategory> }>()
);

export const updateIssueCategorys = createAction(
  '[IssueCategory/API] Update IssueCategorys',
  props<{ issueCategorys: Update<IssueCategory>[] }>()
);

export const deleteIssueCategory = createAction(
  '[IssueCategory/API] Delete IssueCategory',
  props<{ id: number }>()
);

export const deleteIssueCategorys = createAction(
  '[IssueCategory/API] Delete IssueCategorys',
  props<{ ids: string[] }>()
);

export const clearIssueCategorys = createAction(
  '[IssueCategory/API] Clear IssueCategorys'
);

//////// API CALLS ////////

export const getAllInstitutionIssueCategories = createAction(
  '[IssueCategory/API] Get All IssueCategorys',
  props<{ pageParam?: PageableParam }>()
);
export const getAllInstitutionIssueCategoriesForList = createAction(
  '[IssueCategory/API] Get All IssueCategorys For List',
  props<{ pageParam: PageableParam }>()
);

export const getIssueCategoryByUuid = createAction(
  '[IssueCategory/API] get Issue Category By Uuid',
  props<{ issueCategoryUuid: string }>()
);

export const addUpdateIssueCategory = createAction(
  '[IssueCategory/API] add Update Issue Category',
  props<{ issueCategory: IssueCategoryDtoInput }>()
);

export const activateDeactivateIssueCategory = createAction(
  '[IssueCategory/API] activate Deactivate Issue Category',
  props<{ issueCategoryUuid: string }>()
);

export const removeIssueCategory = createAction(
  '[IssueCategory/API] Remove Issue Category',
  props<{ issueCategoryUuid: string,id:number }>()
);

export const getIssueCategoryByServiceUuid = createAction(
  '[IssueCategory/API] get issue category by uuid',
  props<{ serviceUuid: string }>()
);
