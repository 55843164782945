import { enumToArray, enumToObjectArray } from "../functions/functions";

export enum TascoIsceCategoryEnum {
    tasco = 'TASCO', 
    isce = 'ISCE', 
}
export const TASCO_ISCE_CATEGORIES_OBJS = enumToObjectArray(TascoIsceCategoryEnum)
export const TASCO_ISCE_CATEGORIES = enumToArray(TascoIsceCategoryEnum)

export enum TascoGroupLevelEnum {
    major = 'MAJOR', 
    subMajor = 'SUB-MAJOR', 
    minor = 'MINOR', 
    unit = 'UNIT', 
}
export const TASCO_GROUP_LEVEL_OBJS = enumToObjectArray(TascoGroupLevelEnum)
export const TASCO_GROUP_LEVELS = enumToArray(TascoGroupLevelEnum)

export enum IsceGroupLevelEnum {
    major = 'MAJOR', 
    subMajor = 'SUB-MAJOR', 
    minor = 'MINOR', 
}
export const ISCE_GROUP_LEVEL_OBJS = enumToObjectArray(IsceGroupLevelEnum)
export const ISCE_GROUP_LEVELS = enumToArray(IsceGroupLevelEnum)


