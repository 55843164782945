import gql from 'graphql-tag';
import { basePageableGraphqlField } from '../../base.graphql';

export const rootCauseFields = `
id
ldapAnonymousReadOnly
ldapBaseDn
#ldapPassword
ldapUrl
ldapUserDn
uuid
`;

export const CREATE_LDAP_CONFIGURATION = gql`
  mutation addUpdateLdapConfig($ldapConfigDto: LdapConfigDtoInput){
    addUpdateLdapConfig(ldapConfigDto: $ldapConfigDto){
      code
      data{
        ${rootCauseFields}
      }
    }
  }
`;

export const GET_ALL_LDAP_CONFIGURATIONS = gql`
  query getAllLdapConfig($pageparam: PageableParamInput){
    getAllLdapConfig(pageParam: $pageparam){
      content{
        ${rootCauseFields}
      }
      ${basePageableGraphqlField}
    }
  }
`;

export const REMOVE_LDAP_CONFIGURATION = gql`
  mutation activateDeactivateLdapConfig($uuid: String) {
    activateDeactivateLdapConfig(uuid: $uuid){
      code
      data{
        ${rootCauseFields}
      }
      message
    }
  }
`;
