import {Injectable} from '@angular/core';
import {AppState} from 'src/app/store/reducers';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {Apollo} from 'apollo-angular';
import * as fromActions from './issue-category.actions';
import * as fromGraphql from './issue-category.graphql';
import {map, switchMap} from 'rxjs/operators';
import {NotificationService} from 'src/app/services/notification.service';
import {ResponseCodeService} from 'src/app/services/response-code.service';
import {PaginationService} from 'src/app/services/pagination.service';
import {IssueCategory} from './issue-category.model';


@Injectable()
export class IssueCategoryEffects {

    // getAllInstitutionIssueCategories
    getAllInstitutionIssueCategories$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getAllInstitutionIssueCategories),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.LIST_ALL_ISSUE_CATEGORYS,
                fetchPolicy: 'network-only',
                variables: {
                    pageparam: action.pageParam
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching Services'),
                map(({data}: any) => {
                    if (data) {
                        const result = data?.getAllInstitutionIssueCategories;
                        this.paginationService.setPage(result);
                        this.store.dispatch(fromActions.loadIssueCategorys({issueCategorys: result?.content}));
                    }
                })
            );
        })
    ), {dispatch: false});
    getAllInstitutionIssueCategoriesForList$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getAllInstitutionIssueCategoriesForList),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.LIST_ALL_ISSUE_CATEGORY_FOR_LIST,
                fetchPolicy: 'network-only',
                variables: {
                    pageparam: action.pageParam
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching Services'),
                map(({data}: any) => {
                    if (data) {
                        const result = data?.getAllInstitutionIssueCategories;
                        this.paginationService.setPage(result);
                        this.store.dispatch(fromActions.loadIssueCategorys({
                            issueCategorys:
                            data.getAllInstitutionIssueCategories.content
                        }));
                    }
                })
            );
        })
    ), {dispatch: false});

    // getIssueCategoryByUuid
    getIssueCategoryByUuid$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getIssueCategoryByUuid),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.GET_ISSUE_CATEGORY_BY_UUID,
                fetchPolicy: 'network-only',
                variables: {
                    issueCategoryUuid: action.issueCategoryUuid
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching Service'),
                map(({data}: any) => {
                    if (data) {
                        if (data.getServiceById) {
                            this.store.dispatch(fromActions.upsertIssueCategory({issueCategory: data.getIssueCategoryByUuid.data}));
                        } else {
                            // const error = this.responseCodesService.getCodeDescription(data.getServiceById);
                            this.notificationService.errorMessage('Operation failed: ' + data.getIssueCategoryByUuid.code);
                        }
                    }
                })
            );
        })
    ), {dispatch: false});

    // getIssueCategoryByServiceUuid
    getIssueCategoryByServiceUuid$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getIssueCategoryByServiceUuid),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.GET_ISSUE_CATEGORY_SERVICE_UUID,
                fetchPolicy: 'network-only',
                variables: {
                    serviceUuid: action.serviceUuid
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while getting issue titles'),
                map(({data}: any) => {
                    if (data) {
                        if (data.listIssueCategoryByServiceUuid) {

                            let result: IssueCategory[] = data?.listIssueCategoryByServiceUuid;
                            let otherIssueCategory = {id: result.length + 1000, title: 'Other', description: 'Other', uuid: 'Other'};
                            result = [...result, otherIssueCategory];
                            this.store.dispatch(fromActions.loadIssueCategorys({issueCategorys: result}));
                        } else {
                            this.notificationService.errorMessage('Failed to get issue titles');
                        }
                    }
                })
            );
        })
    ), {dispatch: false});

    // addUpdateIssueCategory
    addUpdateIssueCategory$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.addUpdateIssueCategory),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.CREATE_ISSUE_CATEGORY,
                variables: {
                    issueCategory: action.issueCategory
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while saving service'),
                map(({data}: any) => {
                    if (data) {
                        if (data.addUpdateIssueCategory.code === 9000) {
                            this.store.dispatch(fromActions.upsertIssueCategory({issueCategory: data.addUpdateIssueCategory.data}));
                            this.notificationService.successMessage('Operation successful');
                        } else {
                            this.notificationService.errorMessage('Operation failed: ' + data.addUpdateIssueCategory.code);
                        }
                    }
                })
            );
        })
    ), {dispatch: false});

    // activateDeactivateIssueCategory
    activateDeactivateIssueCategory$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.activateDeactivateIssueCategory),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.ACTIVATE_DEACTIVATE_ISSUE_CATEGORY,
                variables: {
                    issueCategoryUuid: action.issueCategoryUuid
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while saving service'),
                map(({data}: any) => {
                    if (data) {
                        if (data.activateDeactivateIssueCategory.code === 9000) {
                            this.store.dispatch(fromActions.upsertIssueCategory({issueCategory: data.activateDeactivateIssueCategory.data}));
                            this.notificationService.successMessage('Operation successful');
                        } else {
                            this.notificationService.errorMessage('Operation failed: ' + data.activateDeactivateIssueCategory.code);
                        }
                    }
                })
            );
        })
    ), {dispatch: false});

    // deleteIssueCategory
    deleteIssueCategory$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.removeIssueCategory),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.DELETE_ISSUE_CATEGORY,
                variables: {
                    issueCategoryUuid: action.issueCategoryUuid
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while saving service'),
                map(({data}: any) => {
                    if (data) {
                        if (data.deleteIssueCategory.code === 9000) {
                            this.store.dispatch(fromActions.deleteIssueCategory({id: action.id}));
                            this.notificationService.successMessage('Operation successful');
                        } else {
                            this.notificationService.errorMessage('Operation failed: ' + data.deleteIssueCategory.code);
                        }
                    }
                })
            );
        })
    ), {dispatch: false});

    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private store: Store<AppState>,
        private notificationService: NotificationService,
        private paginationService: PaginationService,
        private responseCodesService: ResponseCodeService
    ) {
    }

}
