import { Injectable } from '@angular/core';
import { PageResult } from '../store/base.graphql';

@Injectable({
  providedIn: 'root',
})
export class PaginationService {
  page:PageResult;
  
  constructor() {}

  setPage(page){
    this.page = page;
  }
 
  getPage(){
    return this.page; 
  }
}
