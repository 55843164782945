import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { UserFormComponent } from 'src/app/modules/settings/users/user-form/user-form.component';
import { SettingsService } from 'src/app/services/settings.service';
import { Role } from 'src/app/store/entities/settings/role/role.model';
import { User } from 'src/app/store/entities/settings/user/user.model';
import { AppState } from 'src/app/store/reducers';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-view-role',
  templateUrl: './view-role.component.html',
  styleUrls: ['./view-role.component.scss']
})
export class ViewRoleComponent implements OnInit {

  title: string;
  roles$: Observable<Role[]>;
  roles: Role[];
  modalType: string;
  user: User;

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store<AppState>,
    private settingService: SettingsService,
    private dialogRef: MatDialogRef<UserFormComponent>,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
     
    this.user = data;
    // this.store.dispatch(listAllRoles());
  }
    

  ngOnInit(): void {
    this.title = 'Assigned Roles';
    // this.assignRoleForm = this.fb.group({
    //   roleIds: [null, Validators.required],
    // });
    // this.roles$ = this.store.pipe(select(selectModifiedRoles));
    // const roles = [];
    // if (!!this.user?.rolesList) {
    //   if (this.user?.rolesList.length > 0) {
    //     this.user?.rolesList.forEach(x => {
    //       roles.push(x.id);
    //     });
    //   }
    // }
    // this.assignRoleForm.get('roleIds').setValue(roles);
  }

  closeModal(close: boolean): void {
    if (close) {
      this.dialogRef.close();
    }
  }

}
