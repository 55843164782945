import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import { switchMap, map } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification.service';
import { ResponseCodeService } from 'src/app/services/response-code.service';
import { AppState } from '../../reducers';
import * as fromActions from './change-management.actions';
import * as fromGraphql from './change-management.graphql';

@Injectable()
export class ChangeManagementEffects {

  // Create/Save Change Request
  createChangeManagement$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.saveChangeManagement),
    switchMap((action) => {
       
      return this.apollo.mutate({
        mutation: fromGraphql.CREATE_CHANGE_REQUEST,
        variables: {
          HelpdeskChangeRequestDto: action.HelpdeskChangeRequestDto
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while saving change request'),
        map(({ data }: any) => {
          if (data) {
            if (data.saveHelpdeskChangeRequest.code === 9000) {
              this.store.dispatch(fromActions.upsertChangeManagement({ changeManagement: data.saveHelpdeskChangeRequest.data }));
              this.notificationService.successMessage('Created Successfully');
              // if (action.changeManagementDto?.id) {
              //   this.notificationService.successMessage('Edited Successfully');
              // } else {
              //   this.notificationService.successMessage('Created Successfully');
              // }
            } else {
              this.responseCodesService.getCodeDescription(data.saveHelpdeskChangeRequest.code);
            }
          }
        })
      );
    })
  ), {dispatch: false});

  //  Update change request
  updateChangeRequest$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.updateChangeRequest),
    switchMap((action) => {
       
      return this.apollo.mutate({
        mutation: fromGraphql.UPDATE_CHANGE_REQUEST,
        variables: {
          requestId: action.requestId,
          HelpdeskChangeRequestDto: action.HelpdeskChangeRequestDto
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while updating change request'),
        map(({ data }: any) => {
          if (data) {
            if (data.updateChangeRequest.code === 9000) {
              this.store.dispatch(fromActions.upsertChangeManagement({ changeManagement: data.updateChangeRequest.data }));
              this.notificationService.successMessage('Updated Successfully');
            } else {
              this.notificationService.errorMessage('Failed to edit Change request');
            }
          }
        })
      );
    })
  ), {dispatch: false});

  // Get All Change Requests
  getHelpdeskChangeRequestByStage$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.getHelpdeskChangeRequestByStage),
    switchMap((action) => {
      return this.apollo.query({
        query: fromGraphql.GET_CHANGE_REQUEST_BY_STAGE,
        variables: {
          stage: action?.stage
        },
        fetchPolicy: 'network-only',
      }).pipe(
        this.notificationService.catchError('Problem occurred while fetching change requests'),
        map(({ data }: any) => {
          if (data) {
            this.store.dispatch(fromActions.loadChangeManagements({ changeManagements: data.getHelpdeskChangeRequestByStage }));
          } else {
            this.notificationService.errorMessage('Failed to fetch Change request');
          }
        })
      );
    })
  ), {dispatch: false});

  // Get All Change Requests
  findChangeRequestsStageNotIn$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.findChangeRequestsStageNotIn),
    switchMap((action) => {
      return this.apollo.query({
        query: fromGraphql.FIND_CHANGE_REQUEST_BY_EXEMPTED_STAGE,
        variables: {
          stages: action?.stage
        },
        fetchPolicy: 'network-only',
      }).pipe(
        this.notificationService.catchError('Problem occurred while fetching change requests'),
        map(({ data }: any) => {
          if (data) {
            this.store.dispatch(fromActions.loadChangeManagements({ changeManagements: data.findHelpdeskChangeRequestsStageNotIn }));
          } else {
            this.notificationService.errorMessage('Failed to fetch Change request');
          }
        })
      );
    })
  ), {dispatch: false});

  // Find Change Request By Request Type
  findChangeRequestByRequestType$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.findChangeRequestByRequestType),
    switchMap((action) => {
       
      return this.apollo.query({
        query: fromGraphql.FIND_CHANGE_REQUEST_BY_REQUEST_TYPE,
        variables: {
          requestType: action.requestType,
        },
        fetchPolicy: 'network-only',
      }).pipe(
        this.notificationService.catchError('Problem occurred while fetching change requests'),
        map(({ data }: any) => {
          if (data) {
            this.store.dispatch(fromActions.loadChangeManagements({ changeManagements: data.findHelpdeskChangeRequestByRequestType }));
          } else {
            this.notificationService.errorMessage('Failed to fetch Change request');
          }
        })
      );
    })
  ), {dispatch: false});

  // Find Change Request By Request Type and Stage
  getChangeRequestByStageAndType$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.getChangeRequestByStageAndType),
    switchMap((action) => {
       
      return this.apollo.query({
        query: fromGraphql.GET_CHANGE_REQUEST_BY_STAGE_AND_TYPE,
        variables: {
          type: action.requestType,
          stage: action.stage
        },
        fetchPolicy: 'network-only',
      }).pipe(
        this.notificationService.catchError('Problem occurred while fetching change requests'),
        map(({ data }: any) => {
          if (data) {
            this.store.dispatch(fromActions.loadChangeManagements({ changeManagements: data.getChangeRequestByStageAndType }));
            this.notificationService.successMessage('Fetched Successfully');
          } else {
            this.notificationService.errorMessage('Failed to fetch Change request');
          }
        })
      );
    })
  ), {dispatch: false});

  //  submitChangeRequest change request
  submitChangeRequest$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.submitChangeRequest),
    switchMap((action) => {
       
      return this.apollo.mutate({
        mutation: fromGraphql.SUBMIT_CHANGE_REQUEST,
        variables: {
          requestId: action.requestId,
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while submitting change request'),
        map(({ data }: any) => {
          if (data) {
            if (data.submitHelpdeskChangeRequest.code === 9000) {
              this.store.dispatch(fromActions.upsertChangeManagement({ changeManagement: data.submitHelpdeskChangeRequest.data }));
              this.notificationService.successMessage('Submitted Successfully');
            } else {
              this.notificationService.errorMessage('Failed to submit Change request');
            }
          }
        })
      );
    })
  ), {dispatch: false});

  //  managerReviewChangeRequest change request
  managerReviewChangeRequest$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.managerReviewChangeRequest),
    switchMap((action) => {
       
      return this.apollo.mutate({
        mutation: fromGraphql.MANAGER_REVIEW_CHANGE_REQUEST,
        variables: {
          reviewDto: {
            comment: action?.reviewDto?.comment,
            reviewDecission: action?.reviewDto?.reviewDecission
          },
          requestId: action.requestId,
          HelpdeskChangeRequestDto: action?.HelpdeskChangeRequestDto
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while submitting change request'),
        map(({ data }: any) => {
          if (data) {
             
            if (data.managerReviewHelpdeskChangeRequest.code === 9000) {
              this.store.dispatch(fromActions.upsertChangeManagement({ changeManagement: data.managerReviewHelpdeskChangeRequest.data }));
              this.notificationService.successMessage('Completed Successfully');
            } else {
              this.notificationService.errorMessage('Failed to submit Change request');
            }
          }
        })
      );
    })
  ), {dispatch: false});

  //  directorReviewChangeRequest change request
  directorReviewChangeRequest$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.directorReviewChangeRequest),
    switchMap((action) => {
       
      return this.apollo.mutate({
        mutation: fromGraphql.DIRECTOR_REVIEW_CHANGE_REQUEST,
        variables: {
          reviewDto: {
            comment: action?.reviewDto?.comment,
            reviewDecission: action?.reviewDto?.reviewDecission
          },
          requestId: action.requestId,
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while submitting change request'),
        map(({ data }: any) => {
          if (data) {
            if (data.directorReviewHelpdeskChangeRequest.code === 9000) {
              this.store.dispatch(fromActions.upsertChangeManagement({ changeManagement: data.directorReviewHelpdeskChangeRequest.data }));
              this.notificationService.successMessage('Submitted Successfully');
            } else {
              this.notificationService.errorMessage('Failed to submit Change request');
            }
          }
        })
      );
    })
  ), {dispatch: false});

  //  assignChangeRequestToExecutor change request
  assignChangeRequestToExecutor$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.assignChangeRequestToExecutor),
    switchMap((action) => {
       
      return this.apollo.mutate({
        mutation: fromGraphql.ASSIGN_CHANGE_REQUEST_TO_EXECUTOR,
        variables: {
          executorAssignmentDto: {
            requestId : action?.requestId,
            comment: action?.comment,
            assignees: action?.assignees,
            repititiveFrequency: action?.repititiveFrequency,
            priority: action?.priority,
            repetitive: action?.repetitive,
            taskLeader: action?.taskLeader,
            endDate: action?.endDate,
            startDate: action?.startDate
          }
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while assigning change request'),
        map(({ data }: any) => {
          if (data) {
            if (data.assignChangeRequestToExecutor.code === 9000) {
              this.store.dispatch(fromActions.upsertChangeManagement({ changeManagement: data.assignChangeRequestToExecutor.data }));
              this.notificationService.successMessage('Submitted Successfully');
            } else {
              this.notificationService.errorMessage('Failed to assign change request');
            }
          }
        })
      );
    })
  ), {dispatch: false});

  //  executeChangeRequest change request
  executeChangeRequest$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.executeChangeRequest),
    switchMap((action) => {
       
      return this.apollo.mutate({
        mutation: fromGraphql.EXECUTE_CHANGE_REQUEST,
        variables: {
          requestId : action?.requestId,
          executionDto: {
            executorChangeStatus: action?.executionDto?.executorChangeStatus,
            solution: action?.executionDto?.solution,
            attachments: action?.executionDto?.attachments,
            changes: action?.executionDto?.changes,
          },
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred executing change request'),
        map(({ data }: any) => {
          if (data) {
            if (data.executeChangeRequest.code === 9000) {
              this.store.dispatch(fromActions.upsertChangeManagement({ changeManagement: data.executeChangeRequest.data }));
              this.notificationService.successMessage('Submitted Successfully');
            } else {
              this.notificationService.errorMessage('Failed to submit response request');
            }
          }
        })
      );
    })
  ), {dispatch: false});

  //  sendFeedbackToRequester change request
  sendFeedbackToRequester$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.sendFeedbackToRequester),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: fromGraphql.SEND_FEEDBACK_TO_EXECUTOR,
        variables: {
          requestId : action?.requestId,
          description: action?.description,
          changeStatus: action?.changeStatus,
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while sending request execution feedback'),
        map(({ data }: any) => {
          if (data) {
            if (data.sendFeedbackToRequester.code === 9000) {
              this.store.dispatch(fromActions.upsertChangeManagement({ changeManagement: data.sendFeedbackToRequester.data }));
              this.notificationService.successMessage('Feedback sent successfully');
            } else {
              this.notificationService.errorMessage('Failed to send feedback');
            }
          }
        })
      );
    })
  ), {dispatch: false});

  //  closeChangeRequest change request
  closeChangeRequest$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.closeChangeRequest),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: fromGraphql.CLOSE_CHANGE_REQUEST,
        variables: {
          requestId : action?.requestId,
          remarks: action?.remarks,
          changeStatus: action?.changeStatus,
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while closing change request'),
        map(({ data }: any) => {
          if (data) {
            if (data.closeChangeRequest.code === 9000) {
              this.store.dispatch(fromActions.upsertChangeManagement({ changeManagement: data.closeChangeRequest.data }));
              this.notificationService.successMessage('Closed successfully');
            } else {
              this.notificationService.errorMessage('Failed to close change request');
            }
          }
        })
      );
    })
  ), {dispatch: false});


  // Detele Department
  // removeDepartment$ = createEffect(() => this.actions$.pipe(
  //   ofType(fromActions.removeDepartment),
  //   switchMap((action) => {
  //     return this.apollo.mutate({
  //       mutation: fromGraphql.DELETE_DEPARTMENT,
  //       variables: {
  //         id: action.id
  //       }
  //     }).pipe(
  //       this.notificationService.catchError('Problem occurred while deleting department'),
  //       map(({ data }: any) => {
  //         if (data) {
  //           if (data.deleteDepartment === 'SUCCESS') {
  //             this.store.dispatch(fromActions.deleteDepartment({ id: action.id }));
  //             this.notificationService.successMessage('Deleted Successfully');
  //           } else {
  //             this.notificationService.errorMessage(data.deleteRole);
  //           }
  //         }
  //       })
  //     );
  //   })
  // ), {dispatch: false});

  constructor(
    private actions$: Actions,
    private apollo: Apollo,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private responseCodesService: ResponseCodeService
  ) {
  }

}
