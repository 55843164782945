import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {Apollo} from 'apollo-angular';
import {NotificationService} from 'src/app/services/notification.service';
import * as fromActions from './assetc.actions';
import * as fromGraphql from './assetc.graphql';
import {map, switchMap} from 'rxjs/operators';
import {ResponseCodeService} from 'src/app/services/response-code.service';
import {AppState} from '../../reducers';
import {PaginationService} from 'src/app/services/pagination.service';
import {initializedPageableParameter} from 'src/app/interfaces/global.interface';

@Injectable()
export class AssetcEffects {

    // List All Branchs
    listMyInstitutionAllAssetcs$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.listMyInstitutionAllAssetcs),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.ALL_INSTITUTION_ASSETS_LIST,
                variables: {
                    pageparam: action?.pageableParam ? action.pageableParam : initializedPageableParameter,
                },
                fetchPolicy: 'network-only',
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching services'),
                map(({data}: any) => {
                    if (data) {
                        const result = data?.getAllInstitutionResources;
                        this.paginationService.setPage(result);
                        this.store.dispatch(fromActions.loadAssetcs({assetcs: result?.content}));
                    }
                })
            );
        })
    ), {dispatch: false});


    getAllInstitutionResourcesByResourceCategory$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getAllInstitutionResourcesByResourceCategory),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.GET_ALL_INSTITUTION_ASSETS_LIST_BY_RESOURCE_CATEGORY,
                variables: {
                    resourceCategoryUuid: action.id,
                    pageparam: action.pageableParam
                },
                fetchPolicy: 'network-only',
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching Resource By Category'),
                map(({data}: any) => {
                    if (data) {
                        const res = data.getAllInstitutionResourcesByResourceCategory;
                        this.paginationService.setPage(res);

                        this.store.dispatch(fromActions.loadAssetcs({assetcs: res?.content}));
                    }
                })
            );
        })
    ), {dispatch: false});


    getAllInstitutionResourcesByResourceCriticality$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getAllInstitutionResourcesByResourceCriticality),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.GET_ALL_INSTITUTION_ASSETS_LIST_BY_RESOURCE_CRITICALITY,
                variables: {
                    resourceCriticalityUuid: action.id,
                    pageparam: action.pageableParam
                },
                fetchPolicy: 'network-only',
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching Resource By Criticality'),
                map(({data}: any) => {
                    if (data) {
                        const res = data?.getAllInstitutionResourcesByResourceCriticality;
                        this.paginationService.setPage(res);
                        this.store.dispatch(fromActions.loadAssetcs({assetcs: res.content}));
                    }
                })
            );
        })
    ), {dispatch: false});


    getAllInstitutionResourcesByResourceType$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getAllInstitutionResourcesByResourceType),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.GET_ALL_INSTITUTION_ASSETS_LIST_BY_RESOURCE_TYPE,
                variables: {
                    resourceTypeUuid: action.id,
                    pageparam: action.pageableParam
                },
                fetchPolicy: 'network-only',
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching Resource By Type'),
                map(({data}: any) => {
                    if (data) {
                        const res = data.getAllInstitutionResourcesByResourceType;
                        this.paginationService.setPage(res);

                        this.store.dispatch(fromActions.loadAssetcs({assetcs: res.content}));
                    }
                })
            );
        })
    ), {dispatch: false});


    getAllInstitutionResourcesByManufacturer$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getAllInstitutionResourcesByManufacturer),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.GET_ALL_INSTITUTION_ASSETS_LIST_BY_RESOURCE_MANUFACTURE,
                variables: {
                    manufacturerUuid: action.id,
                    pageparam: action.pageableParam
                },
                fetchPolicy: 'network-only',
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching Resource By Manufacture'),
                map(({data}: any) => {
                    if (data) {
                        const res = data.getAllInstitutionResourcesByManufacturer;
                        this.paginationService.setPage(res);

                        this.store.dispatch(fromActions.loadAssetcs({assetcs: res?.content}));
                    }
                })
            );
        })
    ), {dispatch: false});


    getAllInstitutionResourcesBySupplier$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getAllInstitutionResourcesBySupplier),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.GET_ALL_INSTITUTION_ASSETS_LIST_BY_RESOURCE_SUPPLIER,
                variables: {
                    supplierUuid: action.id,
                    pageparam: action.pageableParam
                },
                fetchPolicy: 'network-only',
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching Resource By Supplier'),
                map(({data}: any) => {
                    if (data) {
                        const res = data.getAllInstitutionResourcesBySupplier;
                        this.paginationService.setPage(res);

                        this.store.dispatch(fromActions.loadAssetcs({assetcs: res.content}));
                    }
                })
            );
        })
    ), {dispatch: false});


    activateAssetc$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.activateAssetc),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.ACTIVATE_INSTITUTION_ASSETS_LIST,
                variables: {
                    resourceUuid: action.id,

                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while updating Assetc details'),
                map(({data}: any) => {

                    if (data) {

                        this.notificationService.successMessage('Assetc details Activated Successfully');
                        if (data.activateDeactivateResource.code === 9000) {
                            this.store.dispatch(fromActions.upsertAssetc({assetc: data.activateDeactivateResource.data}));
                            this.notificationService.successMessage('Assetc details Activated Successfully');
                        } else {
                            this.notificationService.errorMessage('Failed to Activate Assetc detals');
                        }
                    }
                })
            );
        })
    ), {dispatch: false});


    // Create/Save Branch
    createUpdateAssetc$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.createUpdateAssetc),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.CREATE_UPDATE_INSTITUTION_ASSETS_LIST,
                variables: {
                    resource: action.assetc
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while saving Assets'),
                map(({data}: any) => {
                    if (data) {

                        if (data.addUpdateNewResource.code === 9000) {
                            if (action.assetc?.id) {
                                this.notificationService.successMessage('Edited Successfully ');
                            } else {
                                this.notificationService.successMessage('Created Successfully ');
                            }
                            this.store.dispatch(fromActions.listMyInstitutionAllAssetcs({pageableParam: initializedPageableParameter}));
                        } else {
                            const error = this.responseCodesService.getCodeDescription(data.addUpdateNewResource.code);
                            this.notificationService.errorMessage(error[0]?.description);
                        }
                    }
                })
            );
        })
    ), {dispatch: false});


    // Detele Branch
    removeAssetc$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.removeAssetc),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.DELETE_INSTITUTION_ASSETS_LIST,
                variables: {
                    resourceUuid: action.uuid,
                    id: action.id
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while deleting Resource'),
                map(({data}: any) => {
                    if (data) {
                        if (data.deleteResource.code === 9000) {
                            this.store.dispatch(fromActions.deleteAssetc({id: action.id}));
                            this.notificationService.successMessage('Deleted Successfully');
                        } else {
                            this.notificationService.errorMessage(data.deleteResource);
                        }
                    }
                })
            );
        })
    ), {dispatch: false});


    getMyBranchAssetsByServiceUid$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getMyBranchAssetsByServiceUid),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.GET_MY_BRANCH_ASSETS_BY_SERVICE_UUID,
                variables: {
                    serviceUuid: action.serviceUuid
                },
                fetchPolicy: 'network-only',
            }).pipe(
                this.notificationService.catchError('Problem occurred while listing Resources'),
                map(({data}: any) => {
                    if (data) {
                        this.store.dispatch(fromActions.loadAssetcs({assetcs: data?.getAllBranchResourcesByService}));
                    }
                })
            );
        })
    ), {dispatch: false});
    getResourceByUuid$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getResourceByUuid),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.GET_ASSET_BY_UID,
                variables: {
                    resourceUuid: action.resourceUuid
                },
                fetchPolicy: 'network-only',
            }).pipe(
                this.notificationService.catchError('Problem occurred while getting Resources'),
                map(({data}: any) => {
                    if (data) {
                        const result = data?.getResourceByUuid;
                        if (result?.code === 9000) {
                            this.store.dispatch(fromActions.upsertAssetc({assetc: result?.data}));
                        } else {
                            this.notificationService.handleErrorMessage(result);
                        }
                    }
                })
            );
        })
    ), {dispatch: false});


    // List all resources by services   
    getAllInternalAndExternalResources$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getAllInternalAndExternalResources),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.GET_ALL_ASSETS_BY_SERVICE_UUID,
                variables: {
                    serviceUuid: action.serviceUuid,
                    pageParam: action?.pageable ? action.pageable : initializedPageableParameter,
                },
                fetchPolicy: 'network-only',
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching services'),
                map(({data}: any) => {
                    if (data) {
                        const result = data?.getAllInternalAndExternalResources;
                        this.paginationService.setPage(result);
                        this.store.dispatch(fromActions.loadAssetcs({assetcs: result?.content}));
                    }
                })
            );
        })
    ), {dispatch: false});

    // List All Branchs 
    getAllInternalAndExternalResourcesByConsumer$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getAllInternalAndExternalResourcesByConsumer),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.GET_ALL_ASSETS_BY_SERVICE_UUID_AND_INSTITUTION_UUID,
                variables: {
                    serviceUuid: action.serviceUuid,
                    consumerInstitutionUuid: action.consumerInstitutionUuid,
                    pageParam: action?.pageable ? action.pageable : initializedPageableParameter,
                },
                fetchPolicy: 'network-only',
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching resources'),
                map(({data}: any) => {
                    if (data) {
                        const result = data?.getAllInternalAndExternalResourcesByConsumer;
                        this.paginationService.setPage(result);
                        this.store.dispatch(fromActions.loadAssetcs({assetcs: result?.content}));
                    }
                })
            );
        })
    ), {dispatch: false});


    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private store: Store<AppState>,
        private notificationService: NotificationService,
        private responseCodesService: ResponseCodeService,
        private paginationService: PaginationService,
    ) {
    }

}
