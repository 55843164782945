import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {select, Store} from '@ngrx/store';
import {SettingsService} from 'src/app/services/settings.service';
import {Role} from 'src/app/store/entities/settings/role/role.model';
import {selectModifiedRoles} from 'src/app/store/entities/settings/role/role.selectors';
import {assignRoleToExternalUser, assignRoleToUser} from 'src/app/store/entities/settings/user/user.actions';
import {User} from 'src/app/store/entities/settings/user/user.model';
import {AppState} from 'src/app/store/reducers';
import {Observable, Subscription} from 'rxjs';
import {UserFormComponent} from '../user-form/user-form.component';
import {listAllRolesPageable} from 'src/app/store/entities/settings/role/role.actions';
import {StorageService} from 'src/app/services/storage.service';
import {map} from 'rxjs/operators';
import {specialInitializedPageableParameter} from 'src/app/interfaces/global.interface';

export interface UserData {
    user: User,
    type: string
}

@Component({
    selector: 'app-assign-role',
    templateUrl: './assign-role.component.html',
    styleUrls: ['./assign-role.component.scss']
})
export class AssignRoleComponent implements OnInit {

    title: string;
    assignRoleForm: UntypedFormGroup;
    user: User;
    roles$: Observable<Role[]>;
    roles: Role[];
    modalType: string;
    userUuid: string;
    userData: UserData;
    subscription = new Subscription();
    institutionId: number;
    userType: any;

    constructor(
        private fb: UntypedFormBuilder,
        private store: Store<AppState>,
        private settingService: SettingsService,
        private dialogRef: MatDialogRef<UserFormComponent>,
        @Inject(MAT_DIALOG_DATA) data,
        private storageService: StorageService,
    ) {
        this.institutionId = +storageService.getItem('institutionId');
        this.userType = storageService.getItem('userType');

        this.userData = data;
        this.user = this.userData?.user;

        if (this.userData.type === 'external') {
            this.modalType = 'external';
            this.userUuid = this.userData.user.uuid;
        }
        if (this.userData.type === 'internal') {
            this.modalType = 'internal';
            this.userUuid = this.userData.user.uuid;
        }
        this.store.dispatch(listAllRolesPageable({
            institutionUuid: this.userData?.user?.institution?.uuid,
            pageableParam: specialInitializedPageableParameter
        }));
    }


    ngOnInit(): void {
        this.title = 'Assign Role';
        this.assignRoleForm = this.fb.group({
            roleIds: [null, Validators.required],
        });
        this.roles$ = this.store.pipe(select(selectModifiedRoles)).pipe(map(data => {
            return data.filter(res => res?.global || res?.institution?.id == this.userData?.user?.institution?.id);
        }));

        const roles = [];
        this.userData.user?.rolesList?.forEach(el => {
            roles.push(el?.id);
        });
        this.assignRoleForm.get('roleIds').setValue(roles);
    }

    submitForm(formValues): void {
        if (this.modalType === 'external') {
            this.store.dispatch(assignRoleToExternalUser({roleIds: formValues.roleIds, userUuid: this.userData.user.uuid}));
        } else {
            this.store.dispatch(assignRoleToUser({roleIds: formValues.roleIds, userUuid: this.userUuid}));
        }
        this.closeModal(true);
    }

    closeModal(close: boolean): void {
        if (close) {
            this.dialogRef.close();
        }
    }

}
