import gql from 'graphql-tag';
import {basePageableGraphqlField} from 'src/app/store/base.graphql';

export const serviceFields = `
                periodType
                timePeriod
                active
                code
                description
                id
                institution{
                 id
                 uuid
                 name
                }
                institutionServiceType{
                  id
                  name
                  uuid
                }
                name
                serviceVisibility
                toBeconfirmedAfterSubscription
                uuid
                incidentInitialCode
                incidentInternalInitialCode
                incidentHandlingMethodType

`;

export const serviceFewFields = `
                code
                description
                id
                institution{
                 id
                 uuid
                 name
                }
                name
                uuid
`;

export const LIST_ALL_SERVICES = gql`
    query getAllInstitutionServices($pageParam: PageableParamInput){
        getAllInstitutionServices(pageParam:$pageParam){
            content{
                ${serviceFields}
            }
            ${basePageableGraphqlField}
        }
    }
`;

export const LIST_ALL_SERVICES_FOR_LIST = gql`
    query getAllInstitutionServices($pageParam: PageableParamInput){
        getAllInstitutionServices(pageParam:$pageParam){
            content{
                id
                uuid
                name
            }
            ${basePageableGraphqlField}  
        }
    }
`;

export const INSTITUTION_SERVICE_DASHBOARD_DTO = gql`
    query getInstitutionServiceStatistics{
        getInstitutionServiceStatistics{
            code
            data{
                allServiceProviders
                confirmedSubscriptions
                myServiceProviders
                serviceTypeStatistics{
                    serviceType
                    totalServices
                }
                subScriptionStatisticsList{
                    confirmedSubscription
                    month
                    unConfirmedSubscription
                    year
                }
                totalServices
                unConfirmedSubscriptions
            }
        }
    }
`;


export const GET_INSTITUTION_SERVICE_BY_SERIVICE_TYPE_ID = gql`
    query getOtherInstitutionsServicesByTypeId($serviceTypeId: Long,$pageParam: PageableParamInput){
        getOtherInstitutionsServicesByTypeId(serviceTypeId:$serviceTypeId,pageParam:$pageParam){
            content{
                ${serviceFields}
            }
            first
            last
            number
            numberOfElements
            pageable{
                pageNumber
                pageSize
            }
            size
            totalElements
            totalPages
        }
    }
`;


export const GET_SERVICES_TO_SUBSCRIBED_BY_INSTITUTION_UUID = gql`
    query getServicesToSubscribeByInstitutionUuid($institutionUuid: String){
        getServicesToSubscribeByInstitutionUuid(institutionUuid: $institutionUuid){
            content{
                uuid
                description
                name
                id
                institutionServiceType{
                    id
                    uuid
                    name
                }
            }
            first
            last
            numberOfElements
            size
            totalPages
            totalElements
        }
    }
`;

export const GET_ALL_INSTITUTION_RESOURCES_BY_SERVICE = gql`
    query getAllInstitutionResourcesByService($pageparam: PageableParamInput,$institutionServiceUuid: String){
        getAllInstitutionResourcesByService(pageparam: $pageparam,institutionServiceUuid: $institutionServiceUuid){
            content{
                ${serviceFields}
            }
            first
            last
            number
            numberOfElements
            pageable{
                pageNumber
                pageSize
            }
            size
            totalElements
            totalPages
        }
    }
`;


export const GET_INSTITUTION_SERVICES_BY_INSTITUTION_UUID = gql`
    query getInstitutionServicesByInstitutionUuid($institutionUuid: String,$pageParam: PageableParamInput){
        getInstitutionServicesByInstitutionUuid(institutionUuid:$institutionUuid,pageParam:$pageParam){
            content{
                ${serviceFields}
            }
            first
            last
            number
            numberOfElements
            pageable{
                pageNumber
                pageSize
            }
            size
            totalElements
            totalPages
        }
    }
`;

export const GET_SERVICES_BY_SERVICE_TYPE = gql`
    query getAllServicesByServiceType($serviceTypeId: Long){
        getAllServicesByServiceType(serviceTypeId:$serviceTypeId){
            ${serviceFields}
        }
    }
`;


export const LIST_ALL_OTHER_SERVICE = gql`
    query allOtherServices{
        allOtherServices
    }
`;

export const FIND_INSTITUTION_SERVICE_BY_UUID = gql`
    query findInstitutionServiceByUuid($uuid: String){
        findInstitutionServiceByUuid(uuid:$uuid){
            code
            data{
                ${serviceFields}
            }
            message
            status
        }
    }
`;

export const OTHER_INSTITUTION_SERVICE_BY_INSTI_AND_TYPE_ID = gql`
    query getOtherInstitutionsServicesByInstAndTypeId($serviceTypeId: Long,$institutionUuid: String){
        getOtherInstitutionsServicesByInstAndTypeId(serviceTypeId:$serviceTypeId,institutionUuid:$institutionUuid){
            ${serviceFields}
        }
    }
`;


export const CREATE_AND_UPDATE_INSTITUTION_SERVICE = gql`
    mutation createUpdateInstitutionService($institutionService: InstitutionServiceDtoInput){
        createUpdateInstitutionService(institutionService:$institutionService){
            code
            data{
                ${serviceFields}
            }
            message
            status
        }
    }

`;

export const SUBSCRIBE_TO_SERVICES = gql`
    mutation subscribeToService($clientDetails:ClientDtoInput){
        subscribeToService(clientDetails:$clientDetails){
            code
            data{
                id
                uuid
                confirmed
                service{
                    id
                    uuid
                    name
                    institutionServiceType{
                        id
                        uuid
                        name
                    }
                }

            }

        }
    }

`;


export const SUBSCRIBE_CLIENT_TO_SERVICES_ON_BEHALF = gql`
    mutation subscribeToServiceOnBehafMany($clientDetails:ClientOnBehafDtoInput){
        subscribeToServiceOnBehafMany(clientDetails:$clientDetails){
            code
            data{
                id
                uuid
                confirmed
                service{
                    id
                    uuid
                    name
                    institutionServiceType{
                        id
                        uuid
                        name
                    }
                }
                clientInstitution{
                    name
                    uuid
                    acronym
                }

            }

        }
    }
`;

export const DEACTIVATE_INSTITUTION_SERVICE = gql`
    mutation deactivateInstitutionService($uuid: String){
        deactivateInstitutionService(uuid:$uuid){
            code
            data{
                ${serviceFields}
            }
            message
            status
        }
    }

`;


export const DELETE_INSTITUTION_SERVICE = gql`
    mutation deleteInstitutionService($institutionServiceId: Long){
        deleteInstitutionService(institutionServiceId:$institutionServiceId){
            code
            data
            message
            status
        }
    }

`;

export const SUBSCRIBED_SERVICES = gql`
    query servicesMyInstSubscribedByProviderInstitution($providerInstitutionUuid:String){
        servicesMyInstSubscribedByProviderInstitution(providerInstitutionUuid:$providerInstitutionUuid){
            ${serviceFields}
        }
    }
`;


export const CLIENTS_SUBSCRIBED_SERVICES = gql`
    query servicesSubscribedInMyInstByClient($clientInstitutionUuid:String){
        servicesSubscribedInMyInstByClient(clientInstitutionUuid:$clientInstitutionUuid){
            ${serviceFields}
        }
    }
`;

export const GET_MY_SUBSCRIBED_SERVICES = gql`
    query servicesMyInstSubscribedByProviderInstitution($providerInstitutionUuid:String){
        servicesMyInstSubscribedByProviderInstitution(providerInstitutionUuid:$providerInstitutionUuid){
            id
            name
        }
    }
`;

export const COPY_ASSIGNED_USERS_TO_ANOTHER_SERVICES = gql`
    mutation copyAssignedUsersToAnotherService($serviceToUuidList: [String],$serviceFromUuid: String){
        copyAssignedUsersToAnotherService(serviceToUuidList: $serviceToUuidList,serviceFromUuid: $serviceFromUuid){
            code
            message
            dataList{
                id
                uuid
                name
            }
        }
    }
`;


export const DELETE_SERVICE_SUBSCRIBED_TO_INSTITUTION_WITH_INSTITUTION_AND_SERVICE_UUID = gql`
    mutation deleteClientFromMyInstitutionByServiceAndInst($institutionUuid: String,$serviceUuid: String){
        deleteClientFromMyInstitutionByServiceAndInst(institutionUuid:$institutionUuid, serviceUuid:$serviceUuid){
            code
            message
            status
        }
    }
`;


export const LIST_ALL_SERVICES_FEW_FIELDS = gql`
    query getAllInstitutionServices($pageParam: PageableParamInput){
        getAllInstitutionServices(pageParam:$pageParam){
            content{
                ${serviceFewFields}
            }
            ${basePageableGraphqlField}
        }
    }
`;

export const LIST_ALL_SERVICES_FEW_FIELD_BY_INSTITUTION = gql`
    query getInstitutionServicesByInstitutionUuid($pageParam: PageableParamInput, $institutionUuid: String){
        getInstitutionServicesByInstitutionUuid(pageParam:$pageParam, institutionUuid: $institutionUuid){
            content{
                id
                uuid
                name
            }
            ${basePageableGraphqlField}
        }
    }
`;


export const SUBSCRIBED_SERVICES_FEW_FIELDS = gql`
    query servicesMyInstSubscribedByProviderInstitution($providerInstitutionUuid:String){
        servicesMyInstSubscribedByProviderInstitution(providerInstitutionUuid:$providerInstitutionUuid){
            ${serviceFewFields}
        }
    }
`;


export const GET_NOT_EMPLOYEE_SUBSCRIBED_SERVICES = gql`
    query mySubscribedServicesByProviderInstitution($providerInstitutionUuid:String, $userUuid: String){
        mySubscribedServicesByProviderInstitution(providerInstitutionUuid:$providerInstitutionUuid, userUuid: $userUuid){
            ${serviceFields}
        }
    }
`;

export const GET_NOT_EMPLOYEE_SUBSCRIBED_SERVICES_FEW = gql`
    query mySubscribedServicesByProviderInstitution($providerInstitutionUuid:String, $userUuid: String){
        mySubscribedServicesByProviderInstitution(providerInstitutionUuid:$providerInstitutionUuid, userUuid: $userUuid){
            id
            uuid
            name        }
    }
`;


export const CLIENTS_SUBSCRIBED_SERVICES_FEW_FIELDS = gql`
    query servicesSubscribedInMyInstByClient($clientInstitutionUuid:String){
        servicesSubscribedInMyInstByClient(clientInstitutionUuid:$clientInstitutionUuid){
            ${serviceFewFields}
        }
    }
`;





