import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/reducers';
import {AuthService} from 'src/app/services/auth.service';
import {NotificationService} from 'src/app/services/notification.service';

@Component({
    selector: 'app-create-password',
    templateUrl: './create-password.component.html',
    styleUrls: ['./create-password.component.scss']
})
export class CreatePasswordComponent implements OnInit, OnDestroy {

    hide: boolean;
    loading = false;
    password_strength: string;

    public changePasswordForm: UntypedFormGroup;
    public barLabel: string = 'Password strength:';
    tokenValid: boolean;
    currentYear: any;
    title = 'Government Helpdesk System';
    private subscription = new Subscription();
    private rememberToken: string;

    constructor(
        private auth: AuthService,
        private router: Router,
        private snackbar: MatSnackBar,
        private activatedRoute: ActivatedRoute,
        private authService: AuthService,
        // private route: ActivatedRoute,
        private store: Store<AppState>,
        private notificationService: NotificationService,
    ) {
        this.hide = true;
        this.currentYear = new Date().getFullYear();

    }

    ngOnInit() {
        this.rememberToken = this.activatedRoute.snapshot.paramMap.get('token');
        this.validateToken(this.rememberToken);
        this.initLoginForm();
    }

    validateToken(token) {
        this.authService.validateToken(token).subscribe(data => {

            if (data?.status) {
                this.tokenValid = true;
            } else {
                this.tokenValid = false;
                this.notificationService.errorMessage(data.message);
                return this.router.navigate(['', 'login']);

            }
        });
    }

    getStrength(value) {
        this.password_strength = value;
    }

    initLoginForm() {
        this.changePasswordForm = new UntypedFormGroup({
            newPassword: new UntypedFormControl('', Validators.required),
            confirmPassword: new UntypedFormControl('', Validators.required),
            token: new UntypedFormControl(this.rememberToken ? this.rememberToken : null)
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    submitCreatePassword() {
        this.loading = true;
        this.changePasswordForm.disable();

        this.subscription.add(
            this.auth.createPassword(this.changePasswordForm.value).subscribe(
                res => this.handleResponse(res),
                error => this.handleError(error)
            )
        );
        // this.store.dispatch(createPassword({createPassword: this.changePasswordForm.value}));
    }


    handleResponse(res) {


        if (res.status) {
            const message = this.notificationService.successMessage(res.data);
            ;
            const action = 'Dismiss';
            // this.snackbar.open(message, action, {
            //   duration: 5000
            // });
            return this.router.navigate(['', 'login']);

        } else {
            this.errorMessage(res);
            this.loading = false;
            this.changePasswordForm.enable();
            // this.changePasswordForm.reset()

        }

    }

    handleError(error) {


        this.errorMessage(error);
    }


    errorMessage(data) {
        const message = this.notificationService.errorMessage(data.message);
        const action = 'Dismiss';
        // this.snackbar.open(message, action, {
        //   duration: 5000
        // });
    }
}
