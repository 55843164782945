<div class="dailog-container">
  <h2 mat-dialog-title>{{data.title}}</h2>
  <mat-form-field mat-dialog-title>

    <input matInput
      [(ngModel)]="searchQuery"
      placeholder="Search"
      (keydown)="_handleKeydown($event)"
      (keyup)="_handleKeyup($event)"
      #input>
    <button mat-stroked-button
      *ngIf="searchQuery && data.isPaginated"
      @fadeIn
      color="primary"
      (click)="search()"
      matSuffix
      style="margin-bottom: 5px;">
      <mat-icon>search</mat-icon> Search
    </button>
  </mat-form-field>

  <mat-dialog-content class="mat-typography">
    <!-- <app-paginated-data-table *ngIf="data.tableConfigurations.tableColumns" [query]="data.query"
            [tableConfigurations]="data.tableConfigurations" [mapFunction]="data.mapFunction"
            [noSearchFields]="data.noSearchFields">
        </app-paginated-data-table> -->

    <app-loader *ngIf="loading"
      message="Please Wait... "></app-loader>
    <!-- <div class="excel-button fa-pull-right"
          style="margin-bottom: 5px; margin-right:5px;"
          *ngIf="!hideExport">
            <button mat-raised-button
              (click)="downloadToCsv()">
                <mat-icon>import_export</mat-icon> Export
            </button>
        </div> -->

    <table mat-table
      [dataSource]="dataSource"
      matSort
      class="mat-elevation-z0"
      *ngIf="!loading">
      <!-- Position Column -->
      <ng-container matColumnDef="position">
        <th mat-header-cell
          *matHeaderCellDef> No. </th>
        <td mat-cell
          *matCellDef="let element"> {{element.position}} </td>
      </ng-container>
      <ng-container *ngFor="let column of data.tableColumns"
        matColumnDef="{{ column.name }}">
        <th scope="col"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header>
          {{ column.label }}
          <mat-icon *ngIf="column.info"
            class="ml-2"
            [matTooltip]="column.info">info_outline</mat-icon>
        </th>
        <td mat-cell
          *matCellDef="let element"
          [ngClass]="{'error': element?.hasError}"
          [title]="element?.tableToolTip ||''"
          style="max-width: 250px">
          <span *ngIf="column.type == 'number'">
            {{ element[column.name] | number }}
          </span>
          <span *ngIf="column.type == 'date'">
            {{ element[column.name] | date:'mediumDate' }}
          </span>
          <span *ngIf="column.type == 'dateTime'">
            {{ element[column.name] | date:'col6' }}
          </span>
          <span *ngIf="column.type == 'image'">
            <img alt="element[column?.name]"
              [src]="element[column.name]"
              style="height: 60px; width: 60px">
          </span>
          <span
            *ngIf="column.type != 'date' && column.type != 'dateTime' && column.type != 'number' && column.type != 'image'">
            <ng-container *ngIf="column.case=='titlecase'">
              {{ element[column.name] | titlecase}}
            </ng-container>
            <ng-container *ngIf="column.case == 'uppercase'">
              {{ element[column.name] | uppercase}}
            </ng-container>
            <ng-container *ngIf="column.case == 'lowercase'">
              {{ element[column.name] | lowercase}}
            </ng-container>
            <ng-container
              *ngIf="column.case != 'lowercase' && column.case != 'uppercase' && column.case != 'titlecase' ">
              {{ element[column.name] }}
            </ng-container>

          </span>
        </td>
      </ng-container>

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell
          *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell
          *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <tr mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row
        *matRowDef="let row; columns: displayedColumns;"
        style="cursor: pointer;"
        (click)="selection.toggle(row); selectRow(row); row.highlighted = data.multiple && !row.highlighted"
        [ngClass]="{hovered: row.hovered, highlighted: row.highlighted}"
        (mouseover)="row.hovered = true"
        (mouseout)="row.hovered = false">
      </tr>
    </table>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <mat-paginator [length]="resultLength"
      [pageSize]="pageSize"
      [pageSizeOptions]="[5, 10, 20, 50, 100]"
      showFirstLastButtons></mat-paginator>
    <button mat-button
      color="warn"
      mat-dialog-close>Cancel</button>
    <button mat-raised-button
      color="primary"
      *ngIf="data.multiple && useAsForm"
      (click)="close()">Select</button>
  </mat-dialog-actions>
</div>