<div [align]="'center'">

    <h1>Hello, {{user.name}} </h1>
<h4 class="text-muted">For the privacy and security of your information,<br> sessions automatically end after a long period of inactivity. <br> Please <b>UNLOCK</b> to continue</h4> 
        <br>
        <form class="example-form">
          <mat-form-field  [appearance]="'outline'">
            <input matInput placeholder="Password" [(ngModel)]="password"type="password" name="password" required>
          </mat-form-field>
          <br>
        <button mat-raised-button [disabled]="!password || showSpinner" (click)="login()" style="width: 100%" color="primary">UNLOCK</button>
        </form>
     <hr>
     <br>
     <button *ngIf="!showSpinner" mat-button  (click)="logout()" style="color: rgb(83, 82, 82);">or Logout</button>
        <app-loader [message]="'Unlocking, Please wait ..'" [withCard]="false" [style.display]="showSpinner ? 'block' : 'none'"></app-loader>
</div>


