import {Injectable} from '@angular/core';
import {ResponseCodeService} from '../../../../services/response-code.service';
import {AppState} from '../../../reducers/index';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {Apollo} from 'apollo-angular';
import {NotificationService} from 'src/app/services/notification.service';
import * as fromActions from './resource-category.actions';
import * as fromGraphql from './resource-category.graphql';
import {map, switchMap} from 'rxjs/operators';
import {PaginationService} from '../../../../services/pagination.service';


@Injectable()
export class ResourceCategoryEffects {
    // List All Branchs
    listMyInstitutionAllResourceCategorys$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.listMyInstitutionAllResourceCategorys),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.GET_MY_INSTITUTION_ALL_RESOURCE_CATEGORYS,
                variables: {
                    pageparam: action.pageableParam
                },
                fetchPolicy: 'network-only',
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching Branches'),
                map(({data}: any) => {
                    if (data) {
                        const res = data?.getAllResourceCategory;
                        this.paginationService.setPage(res);
                        this.store.dispatch(fromActions.loadResourceCategorys({resourceCategorys: res?.content}));
                    }
                })
            );
        })
    ), {dispatch: false});

    listMyInstitutionAllResourceCategorysForList$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.listMyInstitutionAllResourceCategorysForList),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.GET_MY_INSTITUTION_ALL_RESOURCE_CATEGORYS_FOR_LIST,
                variables: {
                    pageparam: action.pageableParam
                },
                fetchPolicy: 'network-only',
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching Branches'),
                map(({data}: any) => {
                    if (data) {
                        const res = data?.getAllResourceCategory;
                        this.paginationService.setPage(res);
                        this.store.dispatch(fromActions.loadResourceCategorys({resourceCategorys: res?.content}));
                    }
                })
            );
        })
    ), {dispatch: false});


    activateResourceCategory$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.activateResourceCategory),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.ACTIVATE_AND_DEACTIVATE_RESOURCE_CATEGORY,
                variables: {
                    resourceCategoryUuid: action.id,

                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while updating Resource Category details'),
                map(({data}: any) => {

                    if (data) {

                        this.notificationService.successMessage('Resource Category details Activated Successfully');
                        if (data.activateDeactivateResourceCategory.code === 9000) {
                            this.store.dispatch(fromActions.upsertResourceCategory({resourceCategory: data.activateDeactivateResourceCategory.data}));
                            this.notificationService.successMessage('Resource Category details Activated Successfully');
                        } else {
                            this.notificationService.errorMessage('Failed to Activate Resource Category detals');
                        }
                    }
                })
            );
        })
    ), {dispatch: false});


    // Create/Save Branch
    createResourceCategory$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.createResourceCategory),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.SAVE_UPDATE_RESOURCE_CATEGORY,
                variables: {
                    resourceCategory: action.resourceCategory
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while saving Resource Category'),
                map(({data}: any) => {
                    if (data) {
                        if (data.addUpdateResourceCategory.code === 9000) {
                            this.store.dispatch(fromActions.upsertResourceCategory({resourceCategory: data.addUpdateResourceCategory.data}));
                            if (action.resourceCategory?.id) {
                                this.notificationService.successMessage('Edited Successfully');
                            } else {
                                this.notificationService.successMessage('Created Successfully');
                            }
                        } else {
                            const error = this.responseCodesService.getCodeDescription(data.addUpdateResourceCategory.code);
                            this.notificationService.errorMessage(error[0]?.description);
                        }
                    }
                })
            );
        })
    ), {dispatch: false});


    // Detele Branch
    removeBranch$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.removeResourceCategory),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.DELETE_RESOURCE_CATEGORY,
                variables: {
                    resourceCategoryUuid: action.uuid,
                    id: action.id
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while deleting department'),
                map(({data}: any) => {
                    if (data) {
                        if (data.deleteResourceCategory.code === 9000) {
                            // this.store.dispatch(fromActions.deleteBranch({ id: action.id }));
                            this.store.dispatch(fromActions.deleteResourceCategory({id: action.id}));
                            // this.store.dispatch(fromActions.upsertResourceCategory({ resourceCategory: data.deleteResourceCategory.data }));

                            this.notificationService.successMessage('Deleted Successfully');
                        } else {
                            this.notificationService.errorMessage(data.deleteResourceCategory);
                        }
                    }
                })
            );
        })
    ), {dispatch: false});


    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private store: Store<AppState>,
        private notificationService: NotificationService,
        private paginationService: PaginationService,
        private responseCodesService: ResponseCodeService
    ) {
    }


}
