import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Attachment } from './attachment.model';
import * as AttachmentActions from './attachment.actions';

export const attachmentsFeatureKey = 'attachments';

export interface State extends EntityState<Attachment> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Attachment> = createEntityAdapter<Attachment>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const attachmentReducer = createReducer(
  initialState,
  on(AttachmentActions.upsertAttachment,
    (state, action) => adapter.upsertOne(action.attachment, state)
  ),
  on(AttachmentActions.addAttachments,
    (state, action) => adapter.addMany(action.attachments, state)
  ),
  on(AttachmentActions.upsertAttachments,
    (state, action) => adapter.upsertMany(action.attachments, state)
  ),
  on(AttachmentActions.updateAttachment,
    (state, action) => adapter.updateOne(action.attachment, state)
  ),
  on(AttachmentActions.updateAttachments,
    (state, action) => adapter.updateMany(action.attachments, state)
  ),
  on(AttachmentActions.deleteAttachment,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(AttachmentActions.deleteAttachments,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(AttachmentActions.loadAttachments,
    (state, action) => adapter.setAll(action.attachments, state)
  ),
  on(AttachmentActions.clearAttachments,
    state => adapter.removeAll(state)
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return attachmentReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();


export const getAllAttachments = selectAll;
export const getTotalAttachments = selectTotal;
