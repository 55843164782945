import {HttpClient} from '@angular/common/http';
import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ActivatedRouteSnapshot, CanDeactivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from 'src/app/services/auth.service';
import {StorageService} from 'src/app/services/storage.service';
import {User} from 'src/app/store/entities/settings/user/user.model';
import {Observable} from 'rxjs';

@Component({
    selector: 'app-lock-dialog',
    templateUrl: './lock-dialog.component.html',
    styleUrls: ['./lock-dialog.component.scss']
})
export class LockDialogComponent implements OnInit,  CanDeactivate<LockDialogComponent> {

    showSpinner = false;
    password: any;
    user: User;

    constructor(
        private httpClient: HttpClient,
        private storageService: StorageService,
        private authService: AuthService,
        private router: Router,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) private data: any,
        private dialogRef: MatDialogRef<LockDialogComponent>
    ) {
        this.user = data;
    }

    onClose(): void {
        this.dialogRef.close(true);
    }

    ngOnInit(): void {


        window.addEventListener('beforeunload', (event) => {
            this.dialog.closeAll();
            this.authService.logout();

            // Delay the reload by 2 seconds
            // setTimeout(() => {
            //     window.location.reload();
            // }, 4000);

            // Prevent the default reload behavior
            event.preventDefault();
        });
    }


    canDeactivate(
        component: LockDialogComponent,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {

        // // Prevent navigation if some condition is met
        // if (someCondition) {
        //     console.log('Navigation prevented!');
        //     return false;
        // }

        // Allow navigation
        // console.log('Navigation allowed!');
        return false;
    }
    async login() {
        let formData: any = {username: this.user.username, password: this.password};
        this.authService.login(formData).then(e => {
            if (e.access_token) {
                this.showSpinner = false;
                this.dialogRef.close();
            }
        });

        // try {
        //     this.showSpinner = true;
        //     const body = `username=${encodeURIComponent(
        //         this.user.username!
        //     )}&password=${encodeURIComponent(this.password)}&grant_type=password`;
        //
        //     const headers = new HttpHeaders({
        //         'Content-Type': 'application/x-www-form-urlencoded',
        //         Accept: 'application/json',
        //     });
        //     await this.httpClient
        //         .post<AuthTokenModel>(environment.SERVER_URL + `/shared-helpdesk-uaa/oauth/token`, body, {headers})
        //         .pipe(
        //             map(async tokenDetails => {
        //                 this.storageService.saveTokenDetails(tokenDetails);
        //                 if (tokenDetails && tokenDetails.access_token) {
        //                     this.storageService.saveLogInTime(Date.now().toString());
        //                 }
        //             })
        //         )
        //         .toPromise();
        //     this.showSpinner = false;
        //     this.dialogRef.close();
        // } catch (error) {
        //     console.error(error);
        //     this.showSpinner = false;
        // }


    }

    logout() {
        this.authService.logout();
        this.onClose();
        return this.router.navigate(['/']);
    }
}
