import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import { switchMap, map } from 'rxjs/operators';
import { initializedPageableParameter } from 'src/app/interfaces/global.interface';
import { NotificationService } from 'src/app/services/notification.service';
import { ResponseCodeService } from 'src/app/services/response-code.service';
import { AppState } from 'src/app/store/reducers';
import * as fromActions from './approval-stage.actions';
import * as fromGraphql from './approval-stage.graphql';



@Injectable()
export class ApprovalStageEffects {

  listInstitutionApprovalStages$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.listInstitutionApprovalStages),
    switchMap((action) => {
      return this.apollo.query({
        query: fromGraphql.LIST_ALL_APPROVAL_STAGES_BY_INSTITUTION_ID,
        variables: {
          institutionId:  action.institutionId,
          pageParam: action.pageableParam?.size ? action.pageableParam : initializedPageableParameter,
         },
        fetchPolicy: 'network-only',
      }).pipe(
        this.notificationService.catchError('Problem occurred while fetching stages'),
        map(({ data }: any) => {
          if (data) {
            let result: any = Object.values(data)[0];
             
            
            this.store.dispatch(fromActions.loadApprovalStages({ approvalStages: result?.content }));
          //   this.store.dispatch(fromActions.loadPageable({ pageable: {
          //     id: 1,
          //     totalPages: data.incidentByStatusList?.totalPages,
          //     totalElements: data.incidentByStatusList?.totalElements,
          //     numberOfElements: data.incidentByStatusList?.numberOfElements,
          //     count: data.incidentByStatusList?.size
          //   }
          // }));
          }
        })
      );
    })
  ), {dispatch: false});




  listAllAvailableApprovalStage$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.listAllAvailableApprovalStage),
    switchMap((action) => {
      return this.apollo.query({
        query: fromGraphql.LIST_ALL_APPROVAL_STAGES,
        variables: {
          pageParam: action.pageableParam?.size ? action.pageableParam : initializedPageableParameter,
         },
        fetchPolicy: 'network-only',
      }).pipe(
        this.notificationService.catchError('Problem occurred while fetching Approval stages'),
        map(({ data }: any) => {
          if (data) {
            let result: any = Object.values(data)[0];
             
            
            this.store.dispatch(fromActions.loadApprovalStages({ approvalStages: result?.content }));
          
          }
        })
      );
    })
  ), {dispatch: false});




  // Create/Save Request Severity
 createApprovalStage$ = createEffect(() => this.actions$.pipe(
  ofType(fromActions.addApprovalStage),
  switchMap((action) => {
    return this.apollo.mutate({
      mutation: fromGraphql.CREATE_APPROVAL_STAGE,
        variables: {
          approvalStage: action.approvalStage
        }
    }).pipe(
      this.notificationService.catchError('Problem occurred while saving Aproval Stage'),
      map(({ data }: any) => {
        if (data) {
          this.handleResponse(data.createUpdateApprovalStage)
          
        }
      })
    );
  })
), {dispatch: false});

// CREATE APPROVAL STAGE USERS

addApprovalStageUser$ = createEffect(() => this.actions$.pipe(
  ofType(fromActions.addApprovalStageUser),
  switchMap((action) => {
    return this.apollo.mutate({
      mutation: fromGraphql.CREATE_UPDATE_APPROVAL_STAGE_USERS,
        variables: {
          stageUuid:action.stageUuid, 
          userUuid:action.UserUuid 
        }
    }).pipe(
      this.notificationService.catchError('Problem occurred while saving Aproval Stage Users'),
      map(({ data }: any) => {
        if (data) {
          this.handleResponse(data.addUserToApprovalStage)
        }
      })
    );
  })
), {dispatch: false});


removeApprovalStageUser$ = createEffect(() => this.actions$.pipe(
  ofType(fromActions.removeApprovalStageUser),
  switchMap((action) => {
    return this.apollo.mutate({
      mutation: fromGraphql.DELETE_APPROVAL_STAGE_USERS,
      variables: {
        stageID:action.stageID, 
        userID:action.UserID, 
        stageUuid:action.stageUuid, 
        userUuid:action.UserUuid 
      }
    }).pipe(
      this.notificationService.catchError('Problem occurred while deleting Approval Stage Users'),
      map(({ data }: any) => {
        if (data) {
          if (data.removeUserFromApprovalStage.code === 9000) {
            // this.store.dispatch(fromActions.deleteApprovalStage({ stageID: action.stageID, userID:action.UserID}));

            this.notificationService.successMessage('Deleted Successfully');
          } else {
            this.notificationService.errorMessage(data.removeUserFromApprovalStage);
          }
        }
      })
    );
  })
), {dispatch: false});



activateApprovalStage$ = createEffect(() => this.actions$.pipe(
  ofType(fromActions.activateApprovalStage),
  switchMap((action) => {
    return this.apollo.mutate({
      mutation: fromGraphql.ACTIVATE_DEACTIVATE_APPROVAL_STAGE,
      variables: {
        id: action.id,
       
      }
    }).pipe(
      this.notificationService.catchError('Problem occurred while updating AprovalStage details'),
      map(({ data }: any) => {

        if (data) {
          this.handleResponse(data.activateDeactivateApprovalStage)
          
        }
      })
    );
  })
), {dispatch: false});


  // Detele Approval Stage
  removeRequestAprovalStage$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.removeApprovalStage),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: fromGraphql.DELETE_APPROVAL_STAGE,
        variables: {
          uuid: action.uuid,
          id: action.id
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while deleting department'),
        map(({ data }: any) => {
          if (data) {
            if (data.deleteApprovalStage.code === 9000) {
              // this.store.dispatch(fromActions.deleteBranch({ id: action.id }));
              this.store.dispatch(fromActions.deleteApprovalStage({ id: action.id}));
              // this.store.dispatch(fromActions.upsertResourceSeverity({ resourceSeverity: data.deleteResourceSeverity.data }));

              this.notificationService.successMessage('Deleted Successfully');
            } else {
              this.notificationService.errorMessage(data.deleteApprovalStage);
            }
          }
        })
      );
    })
  ), {dispatch: false});

  
  constructor(
    private actions$: Actions,
    private apollo: Apollo,
    private store: Store<AppState>,
    private responseCodeService: ResponseCodeService,
    private notificationService: NotificationService,
  ) {

  }

  handleResponse(data) {
    if (data.code === 9000) {
      this.store.dispatch(fromActions.upsertApprovalStage({ approvalStage: data.data }));
      return this.notificationService.successMessage('Approval stage Saved Successful');
    } else {
      return this.handleError(data);
    }
  }

  handleError(data) {
    const responseCode = this.responseCodeService.getCodeDescription(data.code);
    const message = responseCode[0].code + ' : ' + responseCode[0].description;
    return this.notificationService.errorMessage(message);
  }

}
