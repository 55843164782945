import { Component, OnInit, EventEmitter, Output, ElementRef, ViewChild, ChangeDetectionStrategy, Input, forwardRef, OnChanges } from '@angular/core';
import { UntypedFormGroup, NG_VALUE_ACCESSOR, ControlValueAccessor, FormControl, AbstractControl } from '@angular/forms';
import { FieldConfig } from '../field.interface';
@Component({
  selector: 'app-input',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true
    }
  ],
  template: `
   <ng-container *ngIf="field">
<mat-form-field class="demo-full-width" [formGroup]="group" appearance="outline">
<mat-label> {{ field.label }} </mat-label>
<input matInput #inputValue [formControlName]="field.key || null" [placeholder]="field.placeholder || field.label || ''" [type]="showPassword ? 'text' : field.inputType || 'text'"  [readonly]="field.readonly" (keyup)="fieldChange()"
  [required]="field.key && group?.get(field.key)?.errors !== null && group?.get(field.key)?.errors?.['required']">
<mat-hint align="start"  [ngClass]="{'danger': field.hintDanger}">{{field.hint}}</mat-hint>
<mat-hint align="end"  *ngIf="field.showCounter && field.key">{{group?.get(field.key)?.value?.length}} / {{field.allowedLength}}</mat-hint>


<div *ngIf="field.inputType == 'password'" matTooltip="showPassword ? 'hide password' :'show password'" matSuffix class="position-relative stack top--5" (click)="togglePasswordVisibility()" >
<mat-icon class="faded hover-show">{{showPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
</div>

<ng-container *ngFor="let validation of field.validations;" ngProjectAs="mat-error">
<mat-error *ngIf="field.key && group?.get(field.key)?.hasError(validation.name)">{{validation.message}}</mat-error>
</ng-container>
</mat-form-field>
   </ng-container>
`,
  // host: {'class': 'col-md-6'},
  styles: ['.danger { color:red }']
})
export class InputComponent implements OnInit, ControlValueAccessor, OnChanges {
  @Input() field?: FieldConfig;
  @Input() group: UntypedFormGroup = new UntypedFormGroup({});
  @Output() fieldValue = new EventEmitter();
  @ViewChild('inputValue', { static: true }) inputValue?: ElementRef;

  value: any;
  disabled = false;
  onChange: any = () => { };
  onTouched: any = () => { };
  showPassword: boolean;


  settingValue($event: any): void {
    this.value = $event;
    this.onChange(this.value);
  }

  // @HostBinding('class') rowClass = 'col-md-6';
  constructor() {

  }
  writeValue(value: any): void {
    if (value !== undefined) {
      this.fieldChange(value);
    }
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  ngOnInit() {
    //
    this.group.addControl(this.field?.key || 'id', new FormControl('', []));
    //  this.rowClass = this.field.rowClass === 'col12' ? 'col-md-12' : 'col-md-6';
  }

  ngOnChanges() {
    if (this.field?.disabled) {
      this.group.controls[this.field.key as string].disable();
    }
  }

  fieldChange(v = null) {
    const value = v || this.inputValue?.nativeElement?.value;
    this.settingValue(value);
    this.fieldValue.emit({ value, field: this.field, object: {} });
  }
  
  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }
}
