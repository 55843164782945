import gql from 'graphql-tag';

export const kbWorkFlowFields = `
  active
  createdAt
  createdBy
  deleted
  description
  id
  institution{
    id
    uuid
  }
  requestType
  severityCode
  title
  updatedAt
  updatedBy
  uuid
`;
export const pagedFields = `
  content{${kbWorkFlowFields}}
  first
  last
  number
  numberOfElements
  size
  totalElements
  totalPages
`;

export const CREATE_APPROVAL_FLOW = gql`
  mutation addUpdateKBApprovalWorkflow($approvalStage: ApprovalWorkflowDtoInput) {
    addUpdateKBApprovalWorkflow(approvalStage: $approvalStage) {
      code
      data {
        ${kbWorkFlowFields}
      }
      status
    }
  }
`;

export const GET_PAGED_APPROVAL_FLOW = gql`
  query getAllKBApprovalWorkflows( $pageparam: PageableParamInput ){
    getAllKBApprovalWorkflows(pageparam: $pageparam){
      ${pagedFields}
    }
  }
`;

export const GET_ACTIVE_PAGED_APPROVAL_FLOW = gql`
  query getAllKBActiveApprovalStages( $pageparam: PageableParamInput ){
    getAllKBActiveApprovalStages(pageparam: $pageparam){
      ${pagedFields}
    }
  }
`;

export const GET_APPROVAL_FLOW_BY_UUID = gql`
  query getKBApprovalStageByUuid($approvalStageUuid: String) {
    getKBApprovalStageByUuid(approvalStageUuid: $approvalStageUuid) {
      code
      data {
        ${kbWorkFlowFields}
      }
      status
    }
  }
`;
