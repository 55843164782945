import {Injectable} from '@angular/core';
import {ResponseCodeService} from '../../../../services/response-code.service';
import {AppState} from '../../../reducers/index';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {Apollo} from 'apollo-angular';
import {NotificationService} from 'src/app/services/notification.service';
import * as fromActions from './supplier.actions';
import * as fromGraphql from './supplier.graphql';
import {map, switchMap} from 'rxjs/operators';
import {PaginationService} from '../../../../services/pagination.service';


@Injectable()
export class SupplierEffects {
    // List All Branchs
    listMyInstitutionAllSuppliers$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.listMyInstitutionAllSuppliers),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.GET_MY_INSTITUTION_ALL_SUPPLIERS,
                variables: {
                    pageParam: action.pageableParam
                },
                fetchPolicy: 'network-only',
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching Branches'),
                map(({data}: any) => {
                    if (data) {
                        const res = data?.getAllInstitutionSuppliers;
                        this.paginationService.setPage(res);
                        this.store.dispatch(fromActions.loadSuppliers({suppliers: res?.content}));
                    }
                })
            );
        })
    ), {dispatch: false});

    listMyInstitutionAllSuppliersForList$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.listMyInstitutionAllSuppliersForList),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.GET_MY_INSTITUTION_ALL_SUPPLIERS_FOR_LIST,
                variables: {
                    pageParam: action.pageableParam
                },
                fetchPolicy: 'network-only',
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching Branches'),
                map(({data}: any) => {
                    if (data) {
                        const res = data?.getAllInstitutionSuppliers;
                        this.paginationService.setPage(res);
                        this.store.dispatch(fromActions.loadSuppliers({suppliers: data.getAllInstitutionSuppliers.content}));
                    }
                })
            );
        })
    ), {dispatch: false});


    activateSupplier$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.activateSupplier),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.ACTIVATE_AND_DEACTIVATE_SUPPLIER,
                variables: {
                    supplierId: action.id,

                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while updating Supplier details'),
                map(({data}: any) => {

                    if (data) {

                        this.notificationService.successMessage('Supplier details Activated Successfully');
                        if (data.activateDeactivateSupplier.code === 9000) {
                            this.store.dispatch(fromActions.upsertSupplier({supplier: data.activateDeactivateSupplier.data}));
                            this.notificationService.successMessage('Supplier details Activated Successfully');
                        } else {
                            this.notificationService.errorMessage('Failed to Activate Supplier detals');
                        }
                    }
                })
            );
        })
    ), {dispatch: false});


    // Create/Save Branch
    createBranch$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.createSupplier),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.SAVE_UPDATE_SUPPLIER,
                variables: {
                    supplier: action.supplier
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while saving Branch'),
                map(({data}: any) => {
                    if (data) {
                        if (data.createUpdateSupplier.code === 9000) {
                            this.store.dispatch(fromActions.upsertSupplier({supplier: data.createUpdateSupplier.data}));
                            if (action.supplier?.id) {
                                this.notificationService.successMessage('Edited Successfully');
                            } else {
                                this.notificationService.successMessage('Created Successfully');
                            }
                        } else {
                            const error = this.responseCodesService.getCodeDescription(data.createUpdateSupplier.code);
                            this.notificationService.errorMessage(error[0]?.description);
                        }
                    }
                })
            );
        })
    ), {dispatch: false});


    // Detele Branch
    removeBranch$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.removeSupplier),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.DELETE_SUPPLIER,
                variables: {
                    supplierId: action.id
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while deleting department'),
                map(({data}: any) => {
                    if (data) {
                        if (data.deleteSupplier.code === 9000) {
                            // this.store.dispatch(fromActions.deleteBranch({ id: action.id }));
                            this.store.dispatch(
                                fromActions.deleteSupplier({id: action.id})
                            );
                            // this.store.dispatch(fromActions.upsertSupplier({ supplier: data.deleteSupplier }));

                            this.notificationService.successMessage('Deleted Successfully');
                        } else {
                            this.notificationService.errorMessage(data.deleteSupplier);
                        }
                    }
                })
            );
        })
    ), {dispatch: false});


    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private store: Store<AppState>,
        private notificationService: NotificationService,
        private paginationService: PaginationService,
        private responseCodesService: ResponseCodeService
    ) {
    }


}
