import { gql } from 'apollo-angular';
import { baseGraphqlField, baseGraphqlFields } from 'src/app/store/base.graphql';
// import { educationalLevelFields } from '../educational-level/educational-level.graphql';

export const tascoIsceGroupFields = `
    ${baseGraphqlFields}
    name
    category
    groupLevel
    parentUid
    code
`;

export const GET_ALL_TASCO_ISCE_GROUPS = gql`
  query getAllGroupClassifications {
    getAllGroupClassifications {
      ${tascoIsceGroupFields}
    }
  }
`;

export const GET_ALL_ACTIVE_TASCO_ISCE_GROUPS_BY_STATUS = gql`
  query getAllActiveTascoIsceGroupsByStatus($active: Boolean) {
    getAllActiveTascoIsceGroupsByStatus(active: $active){
      ${tascoIsceGroupFields}
    }
  }
`;

export const GET_TASCO_ISCE_GROUP_OBJECT_BY_UID = gql`
  query getTascoIsceGroupObjectByUid($uid: String) {
    getTascoIsceGroupObjectByUid(uid: $uid) {
      ${tascoIsceGroupFields}
    }
  }
`;

export const SAVE_TASCO_ISCE_GROUP= gql`
  mutation saveTascoIsceGroup($groupClassificationDto:GroupClassificationDtoInput){
    saveTascoIsceGroup(groupClassificationDto: $groupClassificationDto){
      code
      data{
        ${tascoIsceGroupFields}
      }
    }
  }
`;

export const MODIFY_TASCO_ISCE_GROUP= gql`
  mutation saveTascoIsceGroup($tascoIsceGroupDto:TascoIsceGroupDtoInput){
    saveTascoIsceGroup(tascoIsceGroupDto: $tascoIsceGroupDto){
      code
      data{
        ${tascoIsceGroupFields}
      }
    }
  }
`;

export const DELETE_TASCO_ISCE_GROUP= gql`
  mutation deleteTascoIsceGroup($uid: String) {
    deleteTascoIsceGroup(uid: $uid) {
      code
      data {
        ${tascoIsceGroupFields}
      }
    }
  }
`;

export const ACTIVATE_TASCO_ISCE_GROUP= gql`
  mutation activateTascoIsceGroup($uid: String) {
    activateTascoIsceGroup(uid: $uid) {
      code
      data {
        ${tascoIsceGroupFields}
      }
    }
  }
`;


export const GET_ALL_TASCO_ISCE_GROUPS_PAGEABLE= gql`
  query getAllTascoIsceGroupPageable($pageableParam: PageableParamInput) {
    getAllTascoIsceGroupPageable(pageableParam: $pageableParam) {
       content{
        ${tascoIsceGroupFields}
       }
       first
       last
       number
       numberOfElements
       totalElements
       totalPages
    }
  }
`;


// 
export const GET_GROUPS_BY_CATEGORY_AND_GROUP = gql`
  query getGroupsByCategoryAndGroup($group:String, $category:String) {
    getGroupsByCategoryAndGroup(group:$group, category:$category) {
      groupLevel
      name
      uuid
      id
    }
  }
`