import { ResetPasswordComponent } from './guest/reset-password/reset-password.component';
import { AuthGuard } from './guards/auth.guard';
import { AnonymousGuard } from './guards/anonymous.guard';
import { NgModule } from '@angular/core';
import { LandingComponent } from './modules/landing/landing.component';
import { HomePageComponent } from './shared/components/home-page/home-page.component';
import { ServiceProviderGuard } from './guards/service-provider.guard';
import { CreatePasswordComponent } from './guest/create-password/create-password.component';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './guest/login/login.component';
import {LoginNwComponent} from "./guest/login-nw/login-nw.component";
import { ClientGuard } from './guards/client.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  // {
  //   path: 'welcome',
  //   component: HomePageComponent,
  //   canActivate: [AnonymousGuard]
  // },
  {
    path: 'login',
    component: LoginNwComponent,
    canActivate: [AnonymousGuard]
  },
  // {
  //   path: 'reset-password/:token',
  //   component: ResetPasswordComponent,
  //   canActivate: [AnonymousGuard]
  // },
  // {
  //   path: 'create-password/:token',
  //   component: CreatePasswordComponent, 
  //   canActivate: [AnonymousGuard]
  // },
  {
    path: 'landing',
    component: LandingComponent,
    // canActivate: [AuthGuard, ServiceProviderGuard]
  },
  // {
  //   path: 'calltrack',
  //   loadChildren: () => import('./modules/calltrack/calltrack.module').then(m => m.CalltrackModule),
  //   canActivate: [AuthGuard, ServiceProviderGuard]
  // },
  {
    path: 'institution',
    loadChildren: () => import('./modules/institution/institution.module').then(m => m.InstitutionModule),
    canActivate: [AuthGuard, ServiceProviderGuard]
  },
  {
    path: 'settings',
    loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule),
    canActivate: [AuthGuard, ServiceProviderGuard]
  },
  {
    path: 'services',
    loadChildren: () => import('./modules/service/service.module').then(m => m.ServiceModule),
    canActivate: [AuthGuard, ServiceProviderGuard]
  },
  {
    path: 'aptitude-test',
    loadChildren: () => import('./modules/aptitude-test/aptitude-test.module').then(m => m.AptitudeTestModule),
    canActivate: [AuthGuard, ServiceProviderGuard]
  },
  // {
  //   path: 'incidents',
  //   loadChildren: () => import('./modules/incidents/incidents.module').then(m => m.IncidentsModule),
  //   canActivate: [AuthGuard, ServiceProviderGuard],
  // },

  // {
  //   path: 'change-management',
  //   loadChildren: () => import('./modules/change-management/change-management.module').then(m => m.ChangeManagementModule),
  //   canActivate: [AuthGuard, ServiceProviderGuard]
  // },
  // {
  //   path: 'roster',
  //   loadChildren: () => import('./modules/roster/roster.module').then(m => m.RosterModule),
  //   canActivate: [AuthGuard, ServiceProviderGuard],
  // },
  // {
  //   path: 'incidents',
  //   loadChildren: () => import('./modules/incidents/incidents.module').then(m => m.IncidentsModule),
  //   canActivate: [AuthGuard, ServiceProviderGuard]
  // },
  // { 
  // path: 'assets', 
  // loadChildren: () => import('./modules/assets-management/assets-management.module').then(m => m.AssetsManagementModule) ,
  // canActivate: [AuthGuard, ServiceProviderGuard]
  // },
  // { path: 'landing', loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule) },
  { 
  path: 'institution-user', 
  loadChildren: () => import('./modules/user-institution/user-institution.module').then(m => m.UserInstitutionModule) ,
  canActivate: [AuthGuard, ServiceProviderGuard]
  },
  // { 
  //   path: 'individual', 
  //   loadChildren: () => import('./modules/individual/individual.module').then(m => m.IndividualModule),  
  //   canActivate: [AuthGuard, ClientGuard]
  // },
  {
    path: 'reports', 
    loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule),
    canActivate: [AuthGuard, ServiceProviderGuard]
  },
  {
    path: 'knowledge-base', 
    loadChildren: () => import('./modules/knowledge-base/knowledge-base.module').then(m => m.KnowledgeBaseModule),
    canActivate: [AuthGuard, ServiceProviderGuard]
  },
  // { 
  //   path: 'self-service', 
  //   loadChildren: () => import('./modules/self-service/self-service.module').then(m => m.SelfServiceModule),
  //   canActivate: [AuthGuard, ServiceProviderGuard]
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
