import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {ChartOptionsMod, IncidentNotificationType} from 'src/app/interfaces/global.interface';
import {IncidentNotification} from 'src/app/store/entities/notification/notification.model';
import {
    selectAllIncidentNotifications,
    selectNotificationByType
} from 'src/app/store/entities/notification/notification.selectors';
import {AppState} from 'src/app/store/reducers';;

@Component({
    selector: 'app-incident-notification',
    templateUrl: './incident-notification.component.html',
    styleUrls: ['./incident-notification.component.scss']
})
export class IncidentNotificationComponent implements OnInit {

    notifications$: Observable<IncidentNotification[]>;
    @Input() viewedFrom: string;
    sectionTickets$: Observable<IncidentNotification[]>;
    internalTickets$: Observable<IncidentNotification[]>;
    incommingTickets$: Observable<IncidentNotification[]>;
    outgoingTickets$: Observable<IncidentNotification[]>;
    myIndividualTickets$: Observable<IncidentNotification[]>;
    departmentTickets$: Observable<IncidentNotification[]>;


    constructor(
        private store: Store<AppState>) {
    }

    ngOnInit(): void {

        this.notifications$ = this.store.pipe(select(selectAllIncidentNotifications));
        this.internalTickets$ = this.store.pipe(select(selectNotificationByType({id: IncidentNotificationType.internal})));
        this.sectionTickets$ = this.store.pipe(select(selectNotificationByType({id: IncidentNotificationType.section})));
        this.incommingTickets$ = this.store.pipe(select(selectNotificationByType({id: IncidentNotificationType.external})));
        this.outgoingTickets$ = this.store.pipe(select(selectNotificationByType({id: IncidentNotificationType.client})));
        this.myIndividualTickets$ = this.store.pipe(select(selectNotificationByType({id: IncidentNotificationType.individual})));
        this.departmentTickets$ = this.store.pipe(select(selectNotificationByType({id: IncidentNotificationType.departmental})));

    }

}
