import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Designation } from './designation.model';
import * as DesignationActions from './designation.actions';

export const designationsFeatureKey = 'designations';

export interface State extends EntityState<Designation> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Designation> = createEntityAdapter<Designation>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(DesignationActions.addDesignation,
    (state, action) => adapter.addOne(action.designation, state)
  ),
  on(DesignationActions.upsertDesignation,
    (state, action) => adapter.upsertOne(action.designation, state)
  ),
  on(DesignationActions.addDesignations,
    (state, action) => adapter.addMany(action.designations, state)
  ),
  on(DesignationActions.upsertDesignations,
    (state, action) => adapter.upsertMany(action.designations, state)
  ),
  on(DesignationActions.updateDesignation,
    (state, action) => adapter.updateOne(action.designation, state)
  ),
  on(DesignationActions.updateDesignations,
    (state, action) => adapter.updateMany(action.designations, state)
  ),
  on(DesignationActions.deleteDesignation,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(DesignationActions.deleteDesignations,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(DesignationActions.loadDesignations,
    (state, action) => adapter.setAll(action.designations, state)
  ),
  on(DesignationActions.clearDesignations,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
