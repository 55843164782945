import {gql} from 'apollo-angular';


export const attachmentGqlFields = `
  id
  title
  filePath
  uuid
  createdAt
`;

export const conversationFields = `
  id
  title
  filePath
  uuid
  createdAt
  conversation{
    id
    uuid
    comment
  }
`;


export const LIST_ATTACHMENTS_BY_UUID = gql`
  query attachmentsByIncidentUuid( $incidentUid: String){
  attachmentsByIncidentUuid(incidenceUuid: $incidentUid){
    ${attachmentGqlFields}
  }
}
`;

export const LIST_ATTACHMENTS_BY_CONVERSATION_UUID = gql`
  query attachmentsByConversationUuid( $conversationUuid: String){
    attachmentsByConversationUuid(conversationUuid: $conversationUuid){
      ${conversationFields}
    }
  }
`;

export const  READ_ATTACHMENT_CONVERSATION_ATTACHMENT = gql`
  query base64ContentAttachmentsByfileUrl($filePath: String){
    base64ContentAttachmentsByfileUrl(fileUrl: $filePath){
      code
      data
    }
  }
`;

export const UPLOAD_ATTACHMENT = gql`
mutation addIncidentAttachment($attachment: IncidentAttachmentDtoInput){
 addIncidentAttachment(attachmentDto:$attachment){
  code
  data{
    ${attachmentGqlFields}
  	}
	}
}
`;

export const  DELETE_ATTACHMENT = gql`
  mutation deleteAttachment($attachmentUuid: String){
    deleteAttachment(attachmentUuid: $attachmentUuid){
      code
      data
      message
    }
  }
`;

export const  READ_ATTACHMENT_INCIDENT_ATTACHMENT = gql`
  query base64ContentAttachmentsByfileUrl($filePath: String){
    base64ContentAttachmentsByfileUrl(fileUrl: $filePath){
      code
      data
      message
    }
  }
`;

export const  READ_MAIL_ATTACHMENT_INCIDENT_ATTACHMENT = gql`
  query uaaBase64ContentAttachmentsByfileUrl($filePath: String){
    uaaBase64ContentAttachmentsByfileUrl(fileUrl: $filePath){
      code
      data
      message
    }
  }
`;

export const  CREATE_UPDATE_INSTITUTION_LOGO = gql`
mutation updateInstitutionLogo($institutionLogo: InstitutionLogoDtoInput){
  updateInstitutionLogo(institutionLogo:$institutionLogo){
    code
    data{
      id
    }
    message
    status
  }
}
`;
