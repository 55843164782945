import { StorageService } from './../../services/storage.service';
import { AuthService } from './../../services/auth.service';
import { AppState } from './../../store/reducers/index';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { ServiceUserType } from 'src/app/interfaces/global.interface';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class OldLoginComponent implements OnInit, OnDestroy {
  hide = true;
  currentYear: any;
  loading = false;
  loginView = false;
  subscription: Subscription = new Subscription();
  staffLoginForm: UntypedFormGroup;
  resetPasswordForm: UntypedFormGroup;

  constructor(
    private store: Store<AppState>,
    private fb: UntypedFormBuilder,
    private route: Router,
    private authService: AuthService,
    private storageService: StorageService,
    private notificationService:NotificationService,
  ) {
    // localStorage.clear();
  }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
    this.staffLoginForm = this.fb.group({
      username: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required]]
    });
    this.resetPasswordForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
    });
  }

  async login(auth) {
    await this.authService.login(auth);
    await this.authService.authRole();
    let serviceUserType = this.storageService.getItem('serviceUserType');

    if (serviceUserType == ServiceUserType.client) {
      this.route.navigateByUrl('/individual');
    } else {
      this.route.navigateByUrl('/landing');
    }
  }

  changeView() {
    this.loginView = !this.loginView;
  }

  forgetPassword(formData: any) {
    this.authService.forgotPassword(formData).subscribe(data => {
       
      if (data?.status){
        this.notificationService.successMessage(data.data);
        this.loginView = false;
      }
      else{
        this.notificationService.errorMessage(data.data)
      }
    })
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}


