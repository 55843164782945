<div class="login-holder">
  <div class="container d-flex justify-content-center text-center vh-100">
      <div class="col-lg-9 m-auto">
        <div class="row nembo-heading">
          <div class="col-lg-2">
            <img alt="emblem" class="emblem xs-hide w-75" src="/assets/images/egas.png" title="Emblem">
          </div>
          <div class="col-lg-8 title-text">
            <div>e - Government Authority</div>
            <div>HelpDesk System</div>
          </div>
          <div class="col-lg-2">
            <img alt="eGA Logo" class="w-100 xs-hide" src="/assets/images/ega.png" title="eGA Logo">
          </div>
        </div>

        <div class="shadow-container">
          <div class="login-box">
            <div class="display-box xs-hide">
                <img src="/src/assets/images/support1_old.png" alt="Support">
            </div>

            <div class="login-form">
              <div *ngIf="!loginView" class="logo-image">
                <h1 class="display1"> Reset Password</h1>
                <!-- Login Form -->
                <form (ngSubmit)="resetPassword(resetPasswordForm.value)" [formGroup]="resetPasswordForm" autocomplete="off" novalidate>
                  <div class="row">
                    <mat-form-field appearance="outline" class="material-form col-md-12">
                      <mat-label>Enter email</mat-label>
                      <input  formControlName="email" matInput placeholder="enter your email">
                      <mat-icon matSuffix>email</mat-icon>
                      <mat-error>
                        <span *ngIf="!resetPasswordForm.get('email').valid && resetPasswordForm.get('email').touched">
                          Email is required
                        </span>
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="material-form col-md-12">
                      <mat-label>Enter new password</mat-label>
                      <input type="password" formControlName="newPassword" matInput placeholder="XXXXXXXX">
                      <mat-icon matSuffix>lock</mat-icon>
                      <mat-error>
                        <span *ngIf="!resetPasswordForm.get('newPassword').valid && resetPasswordForm.get('newPassword').touched">
                          This field is required
                        </span>
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="material-form col-md-12">
                      <mat-label>Confirm Password</mat-label>
                      <input formControlName="confirmPassword" matInput placeholder="XXXXXXX" type="password">
                      <mat-icon matSuffix>lock</mat-icon>
                      <mat-hint *ngIf="resetPasswordForm.get('confirmPassword').value !== resetPasswordForm.get('newPassword').value" class="text-info">The two passwords do not match</mat-hint>
                      <mat-error>
                        <span *ngIf="!resetPasswordForm.get('confirmPassword').valid && resetPasswordForm.get('confirmPassword').touched">
                          This field is required
                        </span>
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <span *ngIf="loading">
                          <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
                      </span>
                    </div>
                    <div class="col-md-12 d-flex px-0">
                      <div class="float-lg-right col-lg-5 order-xs-1 col-xs-12">
                        <button [disabled]="!resetPasswordForm.valid || loading || resetPasswordForm.get('confirmPassword').value !== resetPasswordForm.get('newPassword').value" class="custom-btn green-btn w-100"
                                type="submit">
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>

                </form>
                <!-- End of Login Form -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="ega xs-hide">
      <div>
        <strong>&copy; {{currentYear}} </strong> - <strong>e - Government Authority</strong>
      </div>
      <div>
        <strong>The system is designed and developed by </strong>
        <a href="http://ega.go.tz" target="_blank" title="e-Government Authority">
          <img alt="eGA" src="/assets/images/ega.png">
        </a>
      </div>
    </div>
</div>
