import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import { map, switchMap } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification.service';
import { BaseResponse } from 'src/app/store/base.entity';
import { AppState } from 'src/app/store/reducers';

import * as fromGraphql from './material-file.graphql';
import * as fromActions from './material-file.actions';
import { getCourseMaterialFoldersByUuid, upsertMaterialFolder } from '../material-folder.actions';



@Injectable()
export class MaterialFileEffects {
  constructor(private actions$: Actions,
    private apollo: Apollo,
    private store: Store<AppState>,
    private notificationService: NotificationService
  ) {}

  saveAndUpdateCourseMaterialFile = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.saveAndUpdateCourseMaterialFile),
        switchMap((action) => {
          return this.apollo
            .mutate({
              mutation: fromGraphql.SAVE_AND_UPDATE_COURSE_MATERIAL_FILE,
              variables: {
                courseMaterialFileDto: action.courseMaterialFileDto
              },
            })
            .pipe(
              map(({data}: any) => {

                let result: BaseResponse = Object.values(data)[0];

                if(result?.code === 9000){
                  
                  this.store.dispatch(upsertMaterialFolder({materialFolder: result?.dataList[0]?.courseMaterialFolder }));

                  const message = "file Created Successfully";

                  this.notificationService.successMessage(result?.message || message);

                } else {
                  this.notificationService.handleErrorMessage(result);
                }

              })
            );
        }),
      ),
    {dispatch: false}
  );

  getAttachmentByPath = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.getAttachmentByPath),
        switchMap((action) => {
          return this.apollo
            .query({
              query: fromGraphql.GET_ATTACHMENT_BY_PATH,
              fetchPolicy: "network-only",
              variables: {
                filePath: action.filePath
              }
            })
            .pipe(
              map(({data}: any) => {
                let result: BaseResponse = Object.values(data)[0];
                  this.store.dispatch(
                    fromActions.upsertMaterialFile({ materialFile: result?.data})
                  );
              })
            );
        }),
      ),
    {dispatch: false}
  );
}
