import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, AfterViewInit, OnChanges, forwardRef, ChangeDetectorRef } from '@angular/core';
import { UntypedFormControl, FormGroup, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Subject, merge, of } from 'rxjs';
import { debounceTime, tap, filter, map, takeUntil, mapTo, startWith, distinctUntilChanged, mergeMap, catchError } from 'rxjs/operators';
import { FieldConfig } from '../field.interface';
import { SettingsService } from 'src/app/services/settings.service';
import { MatDialog } from '@angular/material/dialog';
import { PaginatedSelectTableComponent } from './paginated-select-table/paginated-select-table.component';
import { fadeIn } from 'src/app/shared/animations/router-animation';
import { Apollo } from 'apollo-angular';
import { initializedPageableParameter, PageableParam, SearchCombinationType, SearchOperationType } from 'src/app/store/entities/page/page.model';

@Component({
  selector: 'app-paginated-select',
  // changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './paginated-select.component.html',
  styleUrls: ['./paginated-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PaginatedSelectComponent),
      multi: true
    }
  ],
  animations: [fadeIn]
})
export class PaginatedSelectComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges, ControlValueAccessor {

  constructor(public dialog: MatDialog, private apollo: Apollo, private settings: SettingsService) {
  }

  @Input() field?: FieldConfig;
  @Input() group: FormGroup = new FormGroup({});
  @Input() disabled = false;
  @Output() fieldValue = new EventEmitter();
  required = false;
  filterVal: any;
  noEntriesFoundLabel = 'No entry matches';
  options: any[] = [];
  page = 1;
  /* indicate search operation is in progress */
  public searching = false;

  /* control for the search input value */
  searchCtrl: UntypedFormControl = new UntypedFormControl();

  private incrementPageSize$: Subject<void> = new Subject<void>();
  private resetPageSize$: Subject<void> = new Subject<void>();

  private destroy$: Subject<void> = new Subject<void>();
  totalPages = 0;
  loading = false;

  selected: any;


  showTable = false;
  allOptions: any[] = [];
  searchOptions: any[] = [];
  scrolling = false;
  fieldKey?: string;
  onChange: any = () => {
  }
  onTouched: any = () => {
  }


  ngOnInit() {

    try {
      this.setDefault();
      this.checkRequiredFields(this.field, 'field');
      this.checkFormData();
      if (this.field?.key) {
        this.group.addControl(this.field?.key, new UntypedFormControl('', []));
        this.required = (this.group?.get(this.field?.key) && this.group?.get(this.field?.key)!.errors !== null) || false;
        this.filterVal = (this.group?.get(this.field?.filterValueKey || '')?.value) || this.selected;

      }
    } catch (error) {
      console.error(error);
    }
  }


  ngOnChanges(): void {  
    this.setDefault();
    if (this.field?.key) {
      this.checkRequiredFields(this.field, 'field');
      this.allOptions = this.field?.preventConcat ? [] : this.allOptions;
      this.options = this.field?.preventConcat ? [] : this.options;
      if (this.field.fetchPolicy === 'cache-first') {
        this.resetPageSize$.next();
      }
      if (this.disabled) {
        this.group.controls[this.field.key]?.disable();
      } else {
        this.group.controls[this.field.key]?.enable();
      }
    }

  }

  ngAfterViewInit(): void {
    this.searchCtrl.valueChanges.pipe(
      filter(search => !!search),
      tap(() => this.searching = true),
      takeUntil(this.destroy$),
      debounceTime(300),
      distinctUntilChanged(),
      mergeMap(() => this.getDataFromServer(false, null)),
      tap(() => this.searching = false),
      takeUntil(this.destroy$)
    ).subscribe(res => {
      this.generateOptions(res);
    });
    setTimeout(() => {
      merge(this.incrementPageSize$.pipe(mapTo(true)),
        this.resetPageSize$.pipe(mapTo(false))).pipe(
          startWith(false),
          tap(scrolling => {
            if (scrolling) {
              this.searching = true;
            } else {
              this.loading = true;
              // this.cdr.detectChanges();
            }
          }),
          mergeMap((doIncrement) => this.getDataFromServer(doIncrement, null)),
          tap(() => {
            this.loading = false;
            this.searching = false;
          }),
          takeUntil(this.destroy$),
        ).subscribe((res: any) => {
          this.generateOptions(res);
        });
    }, 0);

  }

  /** Get filtered options by the initial value */
  checkFormData() {
    const control = this.group?.controls[this.field?.key as string];
    const initValue = control?.value || this.selected;
    if (initValue) {
      if (this.settings.isArray(initValue)) {        
        const customFields: any[] = [];
        const initialElement: any[] = [];
        initValue.forEach((value: any) => {
          // customFields.push(value + '');
          customFields.push({
            fieldName: this.field?.optionValue,
            isSearchable: true,
            isSortable: false,
            operation: 'EQ',
            searchValue: value + ''
          });
          initialElement.push(value + '');

          this.getInitialDataFromServer({
            ...initializedPageableParameter,
            sortBy: this.field?.sortField,
            sortDirection: 'DESC',
            searchFields: this.field?.searchFields?.map(fieldName => ({
              fieldName,
              fieldValue: value + '',
              searchType: SearchOperationType.Equals
            }))
          });
        });
        control?.setValue(initialElement);
      } else {
        control?.setValue(initValue + '');
        this.getInitialDataFromServer({
          ...initializedPageableParameter,
          sortBy: this.field?.sortField,
          sortDirection: 'DESC',
          searchFields: this.field?.searchFields?.map(fieldName => ({
            fieldName,
            fieldValue: initValue + '',
            searchType: SearchOperationType.Equals
          }))
        }
        );
      }


    }
  }

  /** Get initial options from server using specified fields and generate options */
  private getInitialDataFromServer(customFields: PageableParam) {
    this.loading = true;
    this.getDataFromServer(false, customFields)
      .pipe(takeUntil(this.destroy$),
        tap(() => this.loading = false))
      .subscribe((res: any) => {
        this.generateOptions(res);
      });
  }

  /** Modifying response to match matselect options */
  getData(res: any) {
    const data = this.field?.mapFunction ? (res?.content || []).map(this.field.mapFunction) : (res?.content || []);
    return (data || []).map((item: any) => ({
      ...item, name: this.field?.optionName?.split(',')?.map(name => (item[name]))?.join(' - ') || item['name'], optionValue: item[this.field?.optionValue || ''] + ''
    }));
  }

  /** Generating Options while scrolling and searching */
  generateOptions(res: any) {
    if (res) {
      const optionValue = this.field?.optionValue || '';
      this.totalPages = res.totalPages + 1;
      const modifiedOptions = this.getData(res);
      const arr: any[] = this.allOptions.map(opt => opt[optionValue]);
      this.allOptions = this.allOptions.concat(modifiedOptions.filter((item: any) => arr.indexOf(item[optionValue]) === -1));
      if (this.searchCtrl?.value) {
        if (this.scrolling) {
          const arrSearch: any[] = this.searchOptions.map(opt => opt[optionValue]);
          this.searchOptions = this.searchOptions.concat(modifiedOptions.filter((item: any) => arrSearch.indexOf(item[optionValue]) === -1));
          this.options = this.searchOptions;
        } else {
          this.searchOptions = modifiedOptions;
          this.options = modifiedOptions;
        }
      } else {
        this.options = this.allOptions;
      }

      this.options = this.settings.sortArray(this.options, 'optionValue', 'ASC');
    }
  }

  /** Getting data from server with specified filters */
  getDataFromServer(doIncrement = false, customFields: PageableParam | null) {
    if (this.field?.queryKey) {
      this.scrolling = doIncrement;
      this.page = doIncrement ? this.page + 1 : 0;
      if (this.page === 0 || this.totalPages > this.page) {
        let pageableParam = customFields === null ? this.queryFields() : customFields;
        
        pageableParam = {
          ...pageableParam,
          first: this.page,

        };
        return this.apollo.query(
          {
            query: this.field?.query,
            variables: {
              ...this.field?.additionalVariables,
              pageParam: pageableParam
            }
          },
        ).pipe(map((response: any) => {
          if (response?.errors) {
            console.error(response?.errors);
          }
           
  
          return response?.data[this.field?.queryKey as string];
        }), catchError(error => {
          this.loading = false;
          this.searching = false;
          console.error(error);
          return of([]);
        }));
      }
    }

    return of([]);
  }

  /**
   * Load the next page
   */
  getNextPage(): void {
    if (!this.searching) {
      this.incrementPageSize$.next();
    }
  }

  /** Generating and emitting modified value */
  fieldChange(event: any) {  
    let object: any;
    this.settingValue(event.value);
    if (this.field?.multiple) {
      object = this.allOptions?.filter(element => event.value.indexOf(element.optionValue) > -1) || null;
    } else {
      object = this.allOptions?.find(element => element.optionValue + '' == event.value + '') || null;
    }    
    this.fieldValue.emit({ value: event.value, field: this.field, object });
  }

  toggleSelectAll(selectAllValue: boolean) {
    const key = this.field?.key as string;
    if (selectAllValue) {
      const values = this.options.map(option => option.optionValue);
      if (this.group) {
        this.group?.controls[key]?.setValue(values);
      }
      this.selected = values;
    } else {
      this.selected = [];
      if (this.group) {
        this.group?.controls[key]?.patchValue([]);
      }
    }
  }

  queryFields(): PageableParam {  
    return {
      first: 0,
      size: 10,
      sortBy: this.field?.sortField,
      sortDirection: 'DESC',
      // searchCombinationType: SearchCombinationType.Or,
      searchFields: this.searchCtrl?.value && this.searchCtrl?.value?.length > 1 ? this.field?.searchFields?.map(fieldName => ({
        fieldName,
        fieldValue: this.searchCtrl?.value || '',
        searchType: SearchOperationType.Like
      })): []
    };
  }

  openedChange(opened: boolean) {  
    // if (opened) {
    //   this.resetPageSize$.next();
    // } else {
    //   this.options = this.allOptions;
    // }
  }

  setDefault() {
    /** Defining Default Values */
    this.field = {
      ...this.field,
      label: this.field?.label || '',
      hideRequiredMarker: this.field?.hideRequiredMarker || true,
      key: this.field?.key || 'id',
      placeholder: this.field?.placeholder || this.field?.label || '',
      multiple: this.field?.multiple || false,
      filterKey: this.field?.filterKey || '',
      dynamicPipes: this.field?.dynamicPipes || [{ pipe: 'defaultCase' }, { pipe: 'replace', firstArgs: '_', secondArgs: ' ' }],
      hint: this.field?.hint || '',
      validations: this.field?.validations || [],
      preventConcat: this.field?.preventConcat || false,
      pageSize: this.field?.pageSize || 10,
      value: this.settings.isNullOrUndefined(this.field?.value) ? null : this.field?.value,
      mustHaveFilters: this.field?.mustHaveFilters || [],
      queryKey: this.field?.queryKey || 'content',
      optionValue: this.field?.optionValue || 'id',
      optionName: this.field?.optionName || '',
      fetchPolicy: this.field?.fetchPolicy || 'cache-first',
      sortField: this.field?.sortField || 'id',
      searchFields: this.field?.searchFields || []
    };
  }


  settingValue($event: any): void {
    this.selected = $event;
    this.onChange(this.selected);
  }


  writeValue(value: any): void {
    this.selected = value;
    this.checkFormData();
    this.fieldChange({ value });
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    const key = this.field?.key as string;
    if (isDisabled) {
      this.group.controls[key].disable();
    } else {
      this.group.controls[key].enable();
    }
  }

  checkRequiredFields(input: any, attr: any) {
    if (input === null) {
      console.error('Attribute \'' + attr + '\' is required');
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  showTableFn() {
    if (!this.disabled) {
      this.showTable = true;
      const dialogRef = this.dialog.open(PaginatedSelectTableComponent, {
        width: '80%',
        data: {
          title: this.field?.label,
          query: this.field?.query,
          fields: this.queryFields(),
          multiple: this.field?.multiple,
          isPaginated: true,
          tableColumns: this.field?.tableColumns,
          mapFunction: this.field?.mapFunction,
          noSearchFields: this.field?.noSearchFields,
          mustHaveFilters: this.field?.mustHaveFilters,
          queryKey: this.field?.queryKey,
          optionValue: this.field?.optionValue,
          initialSelection: this.field?.multiple ? this.selected : [this.selected]
        }
      });

      // const sub = dialogRef.componentInstance.onSelectData.subscribe((data: any) => {
      //   // do something
      //
      // });
      dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
        this.showTable = false;
        if (result) {
          this.generateOptions({
            content: this.field?.multiple ? result : [result],
            totalPages: this.totalPages - 1
          });
          this.selected = this.field?.multiple ? result?.map((item: any) => item[this.field?.optionValue || ''] + '') : result[this.field?.optionValue || ''] + '';
          this.group.controls[this.field?.key as string].patchValue(this.selected);
          this.fieldChange({ value: this.selected });
        }
      });
    }


  }

}
