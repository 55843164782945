<app-page-title [title]="title" [useBackButton]="!institution"></app-page-title>

<!-- INNER LINKS -->
<div *ngxPermissionsOnly="['ROLE_CREATE_INSTITUTION','ROLE_EDIT_INSTITUTION','ROLE_UPDATE_INSTITUTION_CLIENT_LOCAL_CODE']"
     class="row">
    <div class="col-md-12">
        <div class="inner-links">
            <button (click)="upload()" *ngIf="institution" class="custom-btn blue-btn"
                    style="margin-top: 6px;max-height: 35px;">
                <mat-icon>upload</mat-icon>
                Upload Customers
            </button>
            <button (click)="addClientIdentifications()" class="icon-btn add-btn">
                <div class="add-icon"></div>
                <div class="btn-txt">Add New</div>
            </button>
        </div>
    </div>
</div>
<!-- End of Inner Links -->

<!-- TABLE -->
<div class="row mt-2 content-item" style="--order: 2">
    <div class="col-md-12">
        <app-data-table-hybrid (pageable)="loadMore($event)" (searchedData)="searchedData($event)"
                               [columnHeader]="tableColumns"
                               [isPageable]="true"
                               [searchFields]="searchFields" [tableData]="clientIdentifications$"
                               class="last-col-narrow">
            <ng-template #actions let-data="data">
                <ng-container>
                    <button *ngxPermissionsOnly="['ROLE_UPDATE_INSTITUTION_CLIENT_LOCAL_CODE', 'ROLE_EDIT_INSTITUTION']"
                            [matMenuTriggerFor]="menu" class="bn-color" mat-mini-fab matTooltip="More options"
                            matTooltipPosition="above">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu" class="sub-links">
                        <button (click)="editIdetification(data)" class="icon-holder" mat-menu-item matTooltip="Edit"
                                matTooltipPosition="above">
                            <mat-icon>edit</mat-icon>
                            Edit
                        </button>
                        <button (click)="deleteIdentification(data)" class="icon-holder text-danger" mat-menu-item
                                matTooltip="Delete"
                                matTooltipPosition="above">
                            <mat-icon>delete_forever</mat-icon>
                            Delete
                        </button>
                    </mat-menu>
                </ng-container>
            </ng-template>
        </app-data-table-hybrid>
    </div>
</div>
<!-- END OF TABLE -->


<!-- /*ngxPermissionsOnly="['ROLE_UPDATE_SEVERITY']" *ngxPermissionsOnly="['ROLE_DELETE_SEVERITY']" -->
