import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { IncidentNotification } from './notification.model';

export const loadIncidentNotifications = createAction(
  '[IncidentNotification/API] Load IncidentNotifications', 
  props<{ incidentNotifications: IncidentNotification[] }>()
);



export const addIncidentNotification = createAction(
  '[IncidentNotification/API] Add IncidentNotification',
  props<{ incidentNotification: IncidentNotification }>()
);

export const upsertIncidentNotification = createAction(
  '[IncidentNotification/API] Upsert IncidentNotification',
  props<{ incidentNotification: IncidentNotification }>()
);

export const addIncidentNotifications = createAction(
  '[IncidentNotification/API] Add IncidentNotifications',
  props<{ incidentNotifications: IncidentNotification[] }>()
);

export const upsertIncidentNotifications = createAction(
  '[IncidentNotification/API] Upsert IncidentNotifications',
  props<{ incidentNotifications: IncidentNotification[] }>()
);

export const updateIncidentNotification = createAction(
  '[IncidentNotification/API] Update IncidentNotification',
  props<{ incidentNotification: Update<IncidentNotification> }>()
);

export const updateIncidentNotifications = createAction(
  '[IncidentNotification/API] Update IncidentNotifications',
  props<{ incidentNotifications: Update<IncidentNotification>[] }>()
);

export const deleteIncidentNotification = createAction(
  '[IncidentNotification/API] Delete IncidentNotification',
  props<{ id: number }>()
);

export const deleteIncidentNotifications = createAction(
  '[IncidentNotification/API] Delete IncidentNotifications',
  props<{ ids: string[] }>()
  );
  
  export const clearIncidentNotifications = createAction(
    '[IncidentNotification/API] Clear IncidentNotifications'
    );
    
    
    ///////// API CALLS ///////////
    
    
    // gets all notification for action takers e.g 1st, 2nd levels and suppervisors for service providers
    export const getAllIncidentNotificationsForActionTakersServiceProviders = createAction(
      '[IncidentNotification/API] Get All IncidentNotifications for action takers service providers',
      props<{ incidentOrigin: string }>()
    );
    
    // gets all notification for action takers e.g 1st, 2nd levels and suppervisors for service providers
    export const getAllOutgoingIncidentNotifications = createAction(
      '[IncidentNotification/API] Get All outgoing IncidentNotifications '
    );
    export const getMyIndividualIncidentsOutByStatusStatistics = createAction(
      '[IncidentNotification/API] Get All outgoing IncidentNotifications For Individual '
    );

    // gets all department notification 
    export const getMyInstDeptIncidentsByStatusStatistics = createAction(
      '[IncidentNotification/API] Get All Department tickets'
    );
  
    // gets all my reported notification 
    export const getMyReportedIncidentNotification = createAction(
      '[IncidentNotification/API] Get All my reported tickets'
    );
  
    // gets all internal notification for action takers e.g 1st, 2nd levels and suppervisors for service providers
    export const getAllInternalIncidentNotificafications = createAction(
      '[IncidentNotification/API] Get All Internal IncidentNotifications',
      props<{ incidentOrigin: string }>()
    );
    // gets all department notifications
    export const getDeptIncidentsByStatusStatistics = createAction(
      '[IncidentNotification/API] Get department Incident Notifications',
      props<{ departmentUuid?: string }>()
    );


