import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { AptitudeTestCandidate } from './aptitude-test-candidate.model';
import { PageableParamInput } from 'src/app/store/base.entity';

export const loadAptitudeTestCandidates = createAction(
  '[AptitudeTestCandidate/API] Load AptitudeTestCandidates', 
  props<{ aptitudeTestCandidates: AptitudeTestCandidate[] }>()
);

export const addAptitudeTestCandidate = createAction(
  '[AptitudeTestCandidate/API] Add AptitudeTestCandidate',
  props<{ aptitudeTestCandidate: AptitudeTestCandidate }>()
);

export const upsertAptitudeTestCandidate = createAction(
  '[AptitudeTestCandidate/API] Upsert AptitudeTestCandidate',
  props<{ aptitudeTestCandidate: AptitudeTestCandidate }>()
);

export const addAptitudeTestCandidates = createAction(
  '[AptitudeTestCandidate/API] Add AptitudeTestCandidates',
  props<{ aptitudeTestCandidates: AptitudeTestCandidate[] }>()
);

export const upsertAptitudeTestCandidates = createAction(
  '[AptitudeTestCandidate/API] Upsert AptitudeTestCandidates',
  props<{ aptitudeTestCandidates: AptitudeTestCandidate[] }>()
);

export const updateAptitudeTestCandidate = createAction(
  '[AptitudeTestCandidate/API] Update AptitudeTestCandidate',
  props<{ aptitudeTestCandidate: Update<AptitudeTestCandidate> }>()
);

export const updateAptitudeTestCandidates = createAction(
  '[AptitudeTestCandidate/API] Update AptitudeTestCandidates',
  props<{ aptitudeTestCandidates: Update<AptitudeTestCandidate>[] }>()
);

export const deleteAptitudeTestCandidate = createAction(
  '[AptitudeTestCandidate/API] Delete AptitudeTestCandidate',
  props<{ id: string }>()
);

export const deleteAptitudeTestCandidates = createAction(
  '[AptitudeTestCandidate/API] Delete AptitudeTestCandidates',
  props<{ ids: string[] }>()
);

export const clearAptitudeTestCandidates = createAction(
  '[AptitudeTestCandidate/API] Clear AptitudeTestCandidates'
);


// === CUSTOM ACTIONS ===
export const getMyAssignedAptitudeTest = createAction(
  '[AptitudeTestCandidate/API] get My Assigned  Aptitude Test',
  props<{ pageParam: any, isConducted: boolean }>()
);
export const getMyAssignedAndConductedAptitudeTest = createAction(
  '[AptitudeTestCandidate/API] get My Assigned And Conducted Aptitude Test',
  props<{ hasSubmittedTest?: boolean,hasStartedTest?: boolean,pageParam: Partial<PageableParamInput> }>()
);

