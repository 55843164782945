<div class="topnav">
    <div class="container-fluid">
        <nav class="navbar navbar-light navbar-expand-lg topnav-menu">
            <div class="collapse navbar-collapse" id="topnav-menu-content">
                <ul class="navbar-nav" *ngIf="menuData">


                    <ng-container *ngFor="let menu of menuData.menuItems">
                        <!-- without children -->
                        <ng-container *ngIf="!menu.children">
                            <ng-container>
                                <li class="nav-item ">
                                    <a class="nav-link text-primary" mat-list-item routerLink="{{ menu.route }}" *ngxPermissionsOnly="menu.permission" routerLinkActive="active-menu" id="topnav-dashboard" role="button">
                                        <!-- <i class="bx bx-home-circle me-2"></i> -->
                                        <span *ngIf="menu.iconType === 'MATERIAL'">
                                            <mat-icon>{{ menu.icon }}</mat-icon>
                                        </span>
                                        <span *ngIf="menu.iconType === 'SVG'">
                                            <mat-icon svgIcon="{{ menu.icon }}"></mat-icon>
                                        </span>
                                        <span class="ml-2" key="t-dashboards">{{ menu.name }}</span>
                                    </a>
                                </li>
                            </ng-container>
                        </ng-container>
                    </ng-container>

                </ul>
            </div>
        </nav>
    </div>
</div>
