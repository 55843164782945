import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Calltracker } from './calltracker.model';
import * as calltrackActions from './calltracker.actions';

export const calltrackerFeatureKey = 'calltrackers';

export interface State extends EntityState<Calltracker> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Calltracker> = createEntityAdapter<Calltracker>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(calltrackActions.addCalltracker,
    (state, action) => adapter.addOne(action.calltracker, state)
  ),
  on(calltrackActions.upsertCalltracker,
    (state, action) => adapter.upsertOne(action.calltracker, state)
  ),
  on(calltrackActions.addCalltrackers,
    (state, action) => adapter.addMany(action.calltrackers, state)
  ),
  on(calltrackActions.upsertCalltrackers,
    (state, action) => adapter.upsertMany(action.calltrackers, state)
  ),
  on(calltrackActions.updateCalltracker,
    (state, action) => adapter.updateOne(action.calltracker, state)
  ),
  on(calltrackActions.updateCalltrackers,
    (state, action) => adapter.updateMany(action.calltrackers, state)
  ),
  on(calltrackActions.deleteCalltracker,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(calltrackActions.deleteCalltrackers,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(calltrackActions.loadCalltrackers,
    (state, action) => {
      return adapter.setAll(action.calltrackers, state);
    }
  ),
  on(calltrackActions.clearCalltrackers,
    state => adapter.removeAll(state)
  ),
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
