import { ResponseCodeService } from '../../../services/response-code.service';
import { NotificationService } from '../../../services/notification.service';
import { AppState } from '../../reducers';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import * as fromActions from './client-identification.actions';
import * as fromGraphql from './client-identification.graphql';
import { map, switchMap } from 'rxjs/operators';
import {StorageService} from '../../../services/storage.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { initializedPageableParameter } from 'src/app/interfaces/global.interface';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UserFormComponent } from 'src/app/modules/settings/users/user-form/user-form.component';


@Injectable()
export class ClientIdentificationEffects {

  findInstitutionClientsLocalCodes$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.findInstitutionClientsLocalCodes),
    switchMap((action) => {      
      return this.apollo.query({
        query: fromGraphql.GET_CLIENT_IDENTIFICATIOS_BY_INSTITUTION,
        fetchPolicy: 'network-only',
        variables: {
          searchTerm: action?.searchTerm,
          pageparam: action?.pageable ? action.pageable : initializedPageableParameter,
          serviceUuids: action?.serviceUuids,
          institutionUuid: action?.institutionUuid
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while client identifications'),
        map(({ data }: any) => {
          const result = data?.findInstitutionClientsLocalCodes;
          if (result?.content.length > 0) {
            this.paginationService.setPage(result);
            this.store.dispatch(fromActions.loadClientIdentifications({ clientIdentifications: result?.content }));
          }
        })
      );
    })
  ), {dispatch: false});

 
  createUpdateInstitutionClientLocalCodes$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.createUpdateInstitutionClientLocalCodes),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: fromGraphql.CREATE_CLIENT_IDENTIFICATION,
        variables: {
          input: action.identificationData
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while saving client identifications'),
        map(({ data }: any) => {
          if (data) {
            const result: any = data?.createUpdateInstitutionClientLocalCodes;
            if(result.length > 0){
              this.store.dispatch(fromActions.findInstitutionClientsLocalCodes({ searchTerm: action?.otherOptions?.searchTerm ,serviceUuids: action?.otherOptions?.serviceUuids, institutionUuid: action?.otherOptions?.institutionUuid , pageable: initializedPageableParameter }))
              this.notificationService.successMessage('Registered successful');
            } else { 
              this.notificationService.errorMessage('Failed to add client identifications'); 
            }
          }
        })
      );
    })
  ), {dispatch: false});


  
  constructor(
    private actions$: Actions,
    private apollo: Apollo,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private storage: StorageService,
    private responseCodesService: ResponseCodeService,
    private paginationService: PaginationService,
    private dialog: MatDialog,
  ) {
  }

  handleResponse(data) {
    if (data.code === 9000) {
      this.store.dispatch(fromActions.upsertClientIdentification({ clientIdentification: data?.data }));
      return this.notificationService.successMessage('Action done Successful');
    } else {
      return this.handleError(data);
    }
  }

  handleError(data) {
    const responseCode = this.responseCodesService.getCodeDescription(data.code);
    const message = responseCode[0].code + ' : ' + responseCode[0].description;
    return this.notificationService.errorMessage(message);
  }
}


