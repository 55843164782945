import { Component, OnInit, HostBinding, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { AbstractControl, FormControl, UntypedFormGroup } from '@angular/forms';
import { FieldConfig } from '../field.interface';
@Component({
  selector: 'app-checkbox',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
  <ng-container *ngIf="field">
<div class="demo-full-width margin-top" [formGroup]="group" >
<mat-checkbox [formControlName]="field?.key || null" (change)="toggleCheckbox($event)">{{field.label}}</mat-checkbox>
</div>
  </ng-container>
`,
  // host: {'class': 'col-md-6'},
  styles: []
})
export class CheckboxComponent implements OnInit {
  field?: FieldConfig;
  group: UntypedFormGroup = new UntypedFormGroup({});
  // @HostBinding('class') rowClass = 'col-md-6';
  @Output() fieldValue = new EventEmitter();
  constructor() {

  }
  ngOnInit() {
    //  this.rowClass = this.field.rowClass === 'col12' ? 'col-md-12' : 'col-md-6';
  }

  toggleCheckbox(event: any) {
    this.fieldValue.emit({ value: event.checked, field: this.field });
  }
}
