// import { departmentFields } from './../department/department.graphql';
import gql from "graphql-tag";
import { basePageableGraphqlField } from "src/app/store/base.graphql";

export const userFields = `
    id
    active
    authorities{
      authority
    }
    checkNumber
    department{
      id
      departmentName
    }
    designation{
      id
      designationName
    }
    branch{
      id
      uuid
      branchName
    }
    email
    enabled
    firstLogin
    firstName
    formRole
    fullName
    hasHandover
    institution{
     id
     uuid
     name
     canReportToBranches
    }
    institutionId
    isOpen
    jobTitle
    lastLogin
    lastName
    middleName
    name
    nationalId
    permissionIdsList
    phone
    picture
    resetLinkSent
    rolesList{
      id
      uuid
      name
      displayName
    
    }
    rolesListStrings
    
    updatedAt
    updatedBy
    userInstitutionId
    username
    uuid
    userType
    headOfDepartment
`;

export const userInfoFields = `
id
uuid
userType
username
headOfDepartment
email
phone
active
fullName
firstName
middleName
checkNumber
lastName
rolesList{
  id
  uuid
  displayName
}
department{
  id
  uuid
  departmentName
}
designation{
  id
  uuid
  designationName
}
branch{
  id
  uuid
  branchName
}
registeredByBranch{
  id
  uuid
  branchName
}
institution{
  id
  uuid
  name
  canReportToBranches
 }
 registeredByInstitution {
    uuid
    name
}
tascoGroup {
  uuid
  name
}
`;
export const userInfoFewFields = `
id
uuid
username
email
phone
active
fullName
firstName
middleName
checkNumber
lastName
department{
  id
  uuid
  departmentName
}
institution{
  id
  uuid
  name
 }
 registeredByInstitution {
    uuid
    name
}
`;

export const LIST_ALL_USERS = gql`
query listOfUserAccounts{
  listOfUserAccounts{
    ${userInfoFields}
  }
}
`;
export const LIST_ALL_USERS_FOR_LIST = gql`
  query listOfUserAccounts {
    listOfUserAccounts {
      id
      uuid
      email
      fullName
    }
  }
`;

export const ASSIGN_ROLE_USER = gql`
  mutation assignRoleToUser($roleIds: [Long], $userUuid: String){
    assignRoleToUser(roleIds: $roleIds, userUuid: $userUuid){
      data{
        ${userInfoFields}
      }
      code
    }
  }
`;

export const ASSIGN_ROLE_TO_EXTERNAL_USER = gql`
  mutation assignRoleToExternalUser($roleIds: [Long], $userUuid: String){
    assignRoleToExternalUser(roleIds: $roleIds, userUuid: $userUuid){
      data{
        ${userInfoFields}

      }
      code
      message
    }
  }
`;

export const GET_USER_BY_INSTITUTION = gql`
query getAllUsersByInstitutionId($institutionId: String,$pageparam: PageableParamInput){
  getAllUsersByInstitutionId(institutionId:$institutionId,pageParam:$pageparam){
    content{
      ${userInfoFields}
    }
    ${basePageableGraphqlField}
  }
}

`;

// CHANGE REQUEST MANAGEMENT
export const GET_APPROVAL_STAGE_USER = gql`
query getApprovalStageUsers($stageUuid: String){
  getApprovalStageUsers(stageUuid:$stageUuid){
    code
    dataList{
      ${userInfoFields}
    }
    message
    status

  }
}
`;

export const GET_DEPARTMENT_PERSONNELS = gql`
  query getAllUsersByDepartment($departmentId: String, $pageParam: PageableParamInput) {
    getAllUsersByDepartment(departmentId: $departmentId, pageParam: $pageParam){
      content{
        ${userInfoFields}
      }
      size
      totalPages
      totalElements
      numberOfElements
        
    }
  }
`;

export const SAVE_USER = gql`
  mutation createUpdateUserAccount($user: UserDtoInput){
    createUpdateUserAccount(user: $user){
      code
      message
      data{
        ${userFields}
      }
    }
  }
`;

export const SAVE_USERS_IN_BATCH = gql`
  mutation registerUsersInBatch($usersFromOtherSystem: BatchRegistrationDtoInput) {
    registerUsersInBatch(usersFromOtherSystem: $usersFromOtherSystem) {
      code
      message
      dataList {
        emailOrId
        registered
      }
    }
  }
`;

export const CREATE_MY_INSTITUTION_USER = gql`
  mutation createUpdateMyInstUserAccount($user: UserDtoInput){
    createUpdateMyInstUserAccount(user: $user){
      code
      message
      data{
        ${userFields}
      }
    }
  }
`;

export const ADD_NEW_USER = gql`
mutation addNewUser($user:UserDtoInput){
  addNewUser(user:$user){
    code
    error
    message
    data{
      ${userFields}
    }
  }
}
`;

export const IMPORT_LDAP_USERS = gql`
  mutation pullUsersFromLdap($institutionUuid: String) {
    pullUsersFromLdap(institutionUuid: $institutionUuid) {
      code
      message
      data
    }
  }
`;

export const CREATE_INSTITUTION_ADMINS = gql`
mutation createInstitutionalAdmin($institutionId: Long,$user: UserDtoInput){
  createInstitutionalAdmin(institutionId:$institutionId,user:$user){
    code
    message
    error
    data{
      ${userInfoFields}
    }
  }
}
`;
// end of my graphql save user

export const UPDATE_USER = gql`
    mutation updateUserAccount($id:Long, $user:UserDtoInput){
        updateUserAccount(id:$id,user:$user) {
            error
             message
            code
            data{
                ${userFields}
            }
        }
    }
`;

export const ACTIVATE_USER = gql`
mutation activateUser($id:Long){
  activateUser(id:$id){
    error
    message
    code
    data{
      ${userInfoFields}
    }
  }
}
`;

export const DEACTIVATE_USER = gql`
mutation deactivateUser($uuid: String){
  deactivateUser(uuid: $uuid){
    code
    data{
      ${userInfoFields}
    }
  }
}
`;

export const DELETE_USER = gql`
mutation deleteUser($id:Long){
  deleteUser(id:$id){
    error
    message
    code
    data{
      ${userInfoFields}
    }
  }
}
`;

export const MY_DETAILS = gql`
    query myDetails{
        myDetails{
            code
            data{
                ${userFields}
            }
        }
    }
`;

export const USER_INFO_BY_UUID = gql`
    query userInfoByUuid($uuid: String){
      userInfoByUuid( uuid: $uuid ){
        code
        data{
          ${userFields}
        }
      }
    }
`;


//==TOUCHED
export const GET_DETAILS_BY_UUID = gql`
    query userDetailsByUuid($uuid: String){
      userDetailsByUuid( uuid: $uuid ){
        code
        data{
          ${userFields}
        }
      }
    }
`;

export const GET_MY_DETAILS = gql`
  query myDetails {
    myDetails {
      code
      data {
        id
        uuid
        accountNonExpired
        accountNonLocked
        active
        checkNumber
        createdAt
        createdBy
        credentialsNonExpired
        deleted
        department {
          id
          uuid
          departmentName
        }
        branch {
          id
          uuid
          branchName
        }
        registeredByBranch {
          id
          uuid
          branchName
        }
        email
        enabled
        firstLogin
        firstName
        formRole
        fullName
        hasHandover
        institution {
          id
          uuid
          name
          helpdeskServiceProvider
          pathLogo
          defaultInstitution
          canReportToBranches
        }
        institutionId
        isOpen
        jobTitle
        lastLogin
        lastName
        middleName
        name
        nationalId
        permissionIdsList
        phone
        picture
        resetLinkSent

        updatedAt
        updatedBy
        userInstitutionId
        username
        uuid
        userType
      }
    }
  }
`;

// export const REQUEST_RESET_PASSWORD = gql`
//   mutation requestResetPassword( $email ){
//     requestResetPassword( email: $email )
//   }
// `;

export const RESET_PASSWORD = gql`
  mutation resetPassword($passwordCreate: PasswordCreateInput, $email: String) {
    resetPassword(passwordCreate: $passwordCreate, email: $email)
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation changePassword($passwordChange: ChangePasswordDtoInput) {
    changePassword(changePasswordDto: $passwordChange) {
      code
      message
    }
  }
`;

export const ASSIGN_USERS_TO_DEPARTMENT = gql`
  mutation addUsersToDepartment($userIds: [String], $departmentId: String) {
    addUsersToDepartment(userIds: $userIds, departmentId: $departmentId) {
      code
      data {
        id
        departmentName
        departmentCode
        uuid
        users {
          id
          uuid
          firstName
          middleName
          lastName
          email
          phone
        }
      }
    }
  }
`;

export const ASSIGN_HEAD_OF_DEPARTMENT = gql`
    mutation assignHeadOfDepartment($departmentId: String, $userId: String){
        assignHeadOfDepartment(departmentId: $departmentId, userId: $userId) {
            code
            data{
              ${userFields}
            }
            status
            message
        }
    }
`;

export const GET_DEPARTMENT_USERS = gql`
  query getMyDepartmentUsers($serviceUuid: String, $branchUuid: String) {
    getMyDepartmentUsers(serviceUuid: $serviceUuid, branchUuid: $branchUuid) {
      code
      dataList {
        id
        email
        firstName
        fullName
        jobTitle
        lastName
        middleName
        uuid
      }
    }
  }
`;

export const GET_NEW_DEPARTMENT_USERS = gql`
  query getDepartmentUsers($departmentUuid: String) {
    getDepartmentUsers(departmentUuid: $departmentUuid) {
      id
      email
      firstName
      fullName
      jobTitle
      lastName
      middleName
      uuid
    }
  }
`;

export const USER_INFOFEW_FIELDS_ID = gql`
  query userInfoByUuid($uuid: String) {
    userInfoByUuid(uuid: $uuid) {
      code
      data {
        id
        fullName
      }
    }
  }
`;

export const LIST_ALL_USERS_FEW_FIELDS = gql`
  query listOfUserAccounts {
    listOfUserAccounts {
      id
      firstName
      fullName
      lastName
      middleName
      uuid
    }
  }
`;

export const REMOVE_USER_FROM_DEPARTMENT = gql`
  mutation removeUsersFromDepartment($departmentId: String, $userIds: [String]) {
    removeUsersFromDepartment(departmentId: $departmentId, userIds: $userIds) {
      code
      data {
        id
        uuid
        departmentName
      }
    }
  }
`;
export const ASSIGN_USER_TO_SERVICE = gql`
mutation assignUsersToService($userUuidList: [String],$serviceUuid: String){
    assignUsersToService(userUuidList: $userUuidList, serviceUuid: $serviceUuid){
      code
      message
      dataList{
        ${userInfoFewFields}
      }
    }
  }
`;

export const GET_ALL_USERS_ASSIGNED_TO_SERVICE = gql`
    query getAllUsersAssignedToService($serviceUuid: String){
        getAllUsersAssignedToService(serviceUuid: $serviceUuid){
            code
            dataList{
                ${userInfoFewFields}
            }
            message
            status
        }
    }
`;

export const REMOVE_USER_FROM_SERVICE = gql`
  mutation removeUsersFromService($userUuidList: [String], $serviceUuid: String) {
    removeUsersFromService(userUuidList: $userUuidList, serviceUuid: $serviceUuid) {
      code
      message
      dataList {
        id
        uuid
      }
    }
  }
`;

export const REMOVE_USER_FROM_ALL_SERVICES = gql`
    mutation removeUserFromAllServices($userUuid: String){
        removeUserFromAllServices(userUuid:$userUuid){
            code
            message
            data{
              ${userInfoFewFields}
            }
        }
    }
`;

export const GET_ALL_USER_FOUND_IN_SERVICE_GROUP = gql`
  query getAllUsersFoundInServiceGroup($groupUuid: String) {
    getAllUsersFoundInServiceGroup(groupUuid: $groupUuid) {
      code
      status
      dataList {
        uuid
        name
        fullName
        id
        checkNumber
      }
    }
  }
`;

export const GET_USER_BY_EMAIL = gql`
  query getMyInstUserAccountByEmail($email: String) {
    getMyInstUserAccountByEmail(email: $email) {
      code
      data {
        email
        id
        uuid
        firstName
        lastName
        fullName
      }
    }
  }
`;

export const USER_INFOFEW_FIELDS_UUID = gql`
  query userDetailsByUuid($userUuid: String) {
    userDetailsByUuid(userUuid: $userUuid) {
      code
      data {
        id
        uuid
        fullName
      }
    }
  }
`;

export const LIST_ALL_DEPARTMENT_USERS = gql`
  query getDepartmentUsers {
    getDepartmentUsers {
      id
      email
      firstName
      fullName
      lastName
      middleName
      uuid
    }
  }
`;

export const GET_INSTITUTION_USER_COUNTS = gql`
  query getAllUsersByInstitutionId($institutionId: String, $pageParam: PageableParamInput) {
    getAllUsersByInstitutionId(institutionId: $institutionId, pageParam: $pageParam) {
      totalElements
      content {
        uuid
      }
    }
  }
`;

export const GET_NON_EMPLOYEE_USERS_BY_INSTITUTION = gql`
query getRegisterIndividualUsersByInstitutionBranch($institutionUuid: String,$pageparam: PageableParamInput, $branchUuid: String){
  getRegisterIndividualUsersByInstitutionBranch(institutionUuid:$institutionUuid,pageableParam:$pageparam, branchUuid: $branchUuid){
    content{
      ${userInfoFields}
    }
   ${basePageableGraphqlField}
  }
}

`;

export const GET_NON_EMPLOYEE_SUBSCRIBERS = gql`
query clientsUsersSubscribedForServicesInMyInst($search: String,$pageparam: PageableParamInput){
  clientsUsersSubscribedForServicesInMyInst(search:$search,pageParam:$pageparam){
    content{
      ${userInfoFewFields}
    }
    ${basePageableGraphqlField}
  }
}

`;

export const GET_USER_BY_INSTITUTION_FEW_FIELDS = gql`
query getAllUsersByInstitutionId($institutionId: String,$pageparam: PageableParamInput){
  getAllUsersByInstitutionId(institutionId:$institutionId,pageParam:$pageparam){
    content{
      id
      uuid
      firstName
      middleName
      lastName
      fullName
    }
    ${basePageableGraphqlField}
  }
}

`;
