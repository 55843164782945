import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { ResponseCodeService } from '../../../../services/response-code.service';
import { AppState } from '../../../reducers/index';
import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import { NotificationService } from 'src/app/services/notification.service';
import * as fromActions from './mail-config.actions';
import * as fromGraphql from './mail-config.graphql';
import { map, switchMap } from 'rxjs/operators';



@Injectable()
export class MailConfigEffects {

  addUpdateMailConfig$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.addUpdateMailConfig),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: fromGraphql.ADD_UPDATE_MAIL_CONFIG,
        variables: {
          mailConfigDto: action.mailConfigDto,         
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while updating Add Mail Configurations details'),
        map(({ data }: any) => {  
          if (data) {
            if (data.addUpdateMailConfig.code === 9000) {
              this.store.dispatch(fromActions.upsertMailConfig({ mailConfig: data.addUpdateMailConfig.data }));
              if(action?.mailConfigDto?.uuid){
                this.notificationService.successMessage('Mail Configurations Added Successfully');
              } else {
                this.notificationService.successMessage('Mail Configurations Edited Successfully');
              }
            } else {
              this.notificationService.errorMessage('Failed to Add Mail Configurations');
            }
          }
        })
      );
    })
  ), {dispatch: false});

   //  getInstitutionMailConfig 
  getInstitutionMailConfig$ = createEffect(() => this.actions$.pipe(
  ofType(fromActions.getInstitutionMailConfig),
  switchMap((action) => {
    return this.apollo.query({
      query: fromGraphql.GET_INSTITION_MAIL_CONIGURATION,    
      fetchPolicy: 'network-only',
    }).pipe(
      this.notificationService.catchError('Problem occurred while fetching Mail Configurations'),
      map(({ data }: any) => {
        if (data) {
          this.store.dispatch(fromActions.loadMailConfigs({ mailConfigs: data.getInstitutionMailConfig.dataList }));
        }
      })
    );
  })
), {dispatch: false});

   // removeMailConfiguration 
  removeMailConfiguration$ = createEffect(() => this.actions$.pipe(
  ofType(fromActions.removeMailConfiguration),
  switchMap((action) => {
    return this.apollo.mutate({
      mutation: fromGraphql.REMOVE_MAIL_CONFIG,
      variables: { uuid: action.mailConfigs.uuid}
    }).pipe(
      this.notificationService.catchError('Problem occurred while deleting Mail Configuration'),
      map(({ data }: any) => {
        if (data) {
            let result: any = data?.deleteMailConfig;
          if(result?.code == 9000){
            this.store.dispatch(fromActions.deleteMailConfig({ id: action.mailConfigs.id }));
            this.notificationService.successMessage('Mail configuration deleted successfully');
          } else {
            let message = this.responseCodesService.getCodeDescription(result?.code);
            this.notificationService.errorMessage(message + ': ', result?.code)
          }
        }
      })
    );
  })
), {dispatch: false});


  constructor(
    private actions$: Actions,
    private apollo: Apollo,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private responseCodesService: ResponseCodeService
  ) {
  }


}


