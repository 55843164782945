import { saveRole } from './../../../../store/entities/settings/role/role.actions';
import { SettingsService } from './../../../../services/settings.service';
import { AppState } from './../../../../store/reducers/index';
import { Role } from './../../../../store/entities/settings/role/role.model';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-role-form',
  templateUrl: './role-form.component.html',
  styleUrls: ['./role-form.component.scss']
})
export class RoleFormComponent implements OnInit {

  title: string;
  roleForm: UntypedFormGroup;
  role: Role;
  userType: any;
  options = [{value: true, name: 'YES '}, { value: false, name: 'NO' }];
  
  constructor(
    private fb: UntypedFormBuilder,
    private store: Store<AppState>,
    private dialogRef: MatDialogRef<RoleFormComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private storageService: StorageService,
  ) {
    this.role = data;
    this.userType = storageService.getItem('userType')
  }

  ngOnInit(): void {
    this.title = this.role ? 'Edit Role' : 'Add Role';
    this.roleForm = this.fb.group({
      name: [null, Validators.required],
      displayName: [null, Validators.required],
      permissions: [null],
      global: [null],
    });
    
    if (this.role) {
      this.roleForm.controls.name.clearValidators();
      this.roleForm.patchValue(this.role);
      this.roleForm.patchValue({
         global: this.role.global.toString()
      })
    } else {
      this.roleForm.controls.name.setValidators([Validators.required]);
    }
    this.roleForm.controls.name.updateValueAndValidity();

    
    if(this.userType == 'internal'){
      this.roleForm.controls.global.setValidators([Validators.required]);
      this.roleForm.controls.global.updateValueAndValidity();
    } else {
      this.roleForm.controls.global.clearValidators();
      this.roleForm.controls.global.updateValueAndValidity();
    }
  }

  submitForm(role): void {
    role.global = role?.global == 'true' ? true : false
    if (this.role) {
      // edit role
      role.uuid = this.role.uuid;
      this.store.dispatch(saveRole({roleDto: role}));
    } else {
      // add role
      this.store.dispatch(saveRole({roleDto: role}));
    }
    this.closeModal(true);
  }

  closeModal(close: boolean): void {
    if (close) {
      this.dialogRef.close();
    }
  }

}
