<app-page-title [title]="title" [useBackButton]="!institutionInput"></app-page-title>


<div class="row">
    <div class="col-md-12">
        <div class="inner-links">
            <button (click)="createNewUsers()" class="icon-btn add-btn">
                <div class="add-icon"></div>
                <div class="btn-txt">New User</div>
            </button>
        </div>
    </div>
</div>
<!-- TABLE -->
<div class="mt-3">
    <app-data-table-hybrid
            (pageable)="loadMore($event)"
            [columnHeader]="tableColumns"
            [isPageable]="true"
            [searchFields]="searchFields"
            [tableData]="instUsers$"
            class="last-col-narrow">
        <ng-template #actions let-data="data">
            <div *ngIf="spCondition(data) || userType=='internal'" class="action-icons actions-center">
                <button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" color="default"
                        mat-mini-fab
                        style="color:#214F7E;">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu" class="sub-links">
                    <button (click)="editUser(data)" *ngxPermissionsOnly="['ROLE_INSTITUTION_USER_ROLE_ASSIGNMENT']"
                            mat-menu-item>
                        <mat-icon>edit</mat-icon>
                        Edit User
                    </button>

                    <button (click)="assignRole(data)" *ngxPermissionsOnly="['ROLE_INSTITUTION_USER_ROLE_ASSIGNMENT']"
                            mat-menu-item>
                        <mat-icon>rule</mat-icon>
                        Assign Role
                    </button>
                    <ng-container *ngxPermissionsOnly="['ROLE_INSTITUTION_USER_DISABLE']">
                        <ng-container *ngIf="data?.isActive == 'IN-ACTIVE'">
                            <button (click)="activateUser(data)" class="icon-holder text-primary" mat-menu-item>
                                <mat-icon>lock_open</mat-icon>
                                Activate User
                            </button>
                        </ng-container>
                        <ng-container *ngIf="data?.isActive == 'ACTIVE'">
                            <button (click)="deActivateUser(data)" class="btn-color" mat-menu-item>
                                <mat-icon>lock</mat-icon>
                                Deactivate User
                            </button>
                        </ng-container>
                        <button (click)="deleteuser(data)" class="btn-color btn-outline-danger" mat-menu-item>
                            <mat-icon>delete</mat-icon>
                            Delete User
                        </button>
                    </ng-container>

                </mat-menu>
            </div>
        </ng-template>
    </app-data-table-hybrid>

</div>
<!-- END OF TABLE -->
