<mat-form-field *ngIf="loading"
  @fadeIn
  appearance="outline">
  <mat-label>{{field?.label}} ...</mat-label>
  <input matInput
    disabled>
  <mat-spinner matSuffix
    [diameter]="18"
    style="float: right; margin-left: 8px"></mat-spinner>
</mat-form-field>
<mat-form-field *ngIf="!loading"
  @fadeIn
  class="demo-full-width margin-top"
  [hideRequiredMarker]="field?.hideRequiredMarker"
  [formGroup]="group"
  appearance="outline">
  <mat-label>{{field?.label}}</mat-label>
  <mat-select (openedChange)="openedChange($event)"
    gInfiniteScroll
    (infiniteScroll)="getNextPage()"
    [required]="required"
    [placeholder]="field?.placeholder || ''"
    [formControlName]="field?.key|| null"
    (selectionChange)="fieldChange($event)"
    [multiple]="field?.multiple">
    <ng-container *ngIf="!showTable">

      <mat-option class="multiline-mat-option">
        <ng-template [ngIf]="!field?.multiple">
          <!--disableScrollToActiveOnOptionsChanged should be set to true-->
          <app-select-search [formControl]="searchCtrl"
            [disableScrollToActiveOnOptionsChanged]="true"
            [noEntriesFoundLabel]="noEntriesFoundLabel"
            [searching]="searching"
            [hideClearSearchButton]="true"></app-select-search>
        </ng-template>
        <ng-template [ngIf]="field?.multiple">
          <app-select-search [formControl]="searchCtrl"
            [noEntriesFoundLabel]="noEntriesFoundLabel"
            [showToggleAllCheckbox]="true"
            [disableScrollToActiveOnOptionsChanged]="true"
            [toggleAllCheckboxIndeterminate]="field?.key!=null && (group.controls[field?.key!]?.value || selected)?.length > 0 && (group.controls[field?.key!]?.value || selected)?.length < options.length"
            [toggleAllCheckboxChecked]="field?.key!=null &&(group.controls[field?.key!]?.value || selected)?.length === options?.length"
            (toggleAll)="toggleSelectAll($event)"
            [searching]="searching"
            [hideClearSearchButton]="true"></app-select-search>
        </ng-template>
      </mat-option>
      <mat-option *ngIf="!field?.multiple">--- Select ---</mat-option>
      <span *ngIf="!field?.removeUnderscore">
        <mat-option *ngFor="let item of options | search: (field?.filterKey || ''): filterVal"
          [value]="item.optionValue"
          [disabled]="item.disabled"
          [ngClass]="{'multiline-mat-option':true,'danger': item.danger}">{{ item.name | dynamicPipes:
          (field?.dynamicPipes || [])}}
        </mat-option>
      </span>

    </ng-container>
  </mat-select>

  <mat-hint><strong>{{field?.hint}}</strong></mat-hint>
  <ng-container *ngFor="let validation of field?.validations;"
    ngProjectAs="mat-error">
    <mat-error *ngIf="field?.key!=null && group.controls[field?.key!]?.hasError(validation.name)">{{validation.message}}</mat-error>
  </ng-container>
  <button [ngClass]="{'inactive' : showTable, 'active': !showTable}"
    *ngIf="field?.tableColumns"
    mat-button
    matSuffix
    mat-icon-button
    type="button"
    (click)="showTableFn()">
    <mat-icon>visibility</mat-icon>
  </button>
</mat-form-field>
