import { Injectable } from '@angular/core';
import { ResponseCodeService } from '../../../../services/response-code.service';
import { AppState } from '../../../reducers/index';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import { NotificationService } from 'src/app/services/notification.service';
import * as fromActions from './request-category.actions';
import * as fromGraphql from './request-category.graphql';
import { map, switchMap } from 'rxjs/operators';



@Injectable()
export class RequestCategoryEffects {
 // List All Branchs 
 listMyInstitutionAllRequestCategorys$ = createEffect(() => this.actions$.pipe(
  ofType(fromActions.listMyInstitutionAllRequestCategorys),
  switchMap((action) => {
    return this.apollo.query({
      query: fromGraphql.GET_MY_INSTITUTION_ALL_REQUEST_CATEGORYS,
      // variables: {
      //   institutionId:  action.institution.id
      // },
      fetchPolicy: 'network-only',
    }).pipe(
      // this.notificationService.catchError('Problem occurred while fetching Branches'),
      map(({ data }: any) => {
        if (data) {
          this.store.dispatch(fromActions.loadRequestCategorys({ requestCategorys: data.getAllInstitutionChangeRequestCategory.content }));
        }
      })
    );
  })
), {dispatch: false});




 // Create/Save Branch
 createRequestCategory$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.createRequestCategory),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: fromGraphql.SAVE_UPDATE_REQUEST_CATEGORY,
        variables: {
          category: action.requestCategory
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while saving Request Category'),
        map(({ data }: any) => {
          if (data) {
            if (data.createUpdateChangeRequestCategory.code === 9000) {
              this.store.dispatch(fromActions.upsertRequestCategory({ requestCategory: data.createUpdateChangeRequestCategory.data }));
              if (action.requestCategory?.id) {
                this.notificationService.successMessage('Edited Successfully');
              } else {
                this.notificationService.successMessage('Created Successfully');
              }
            } else {
              this.handleResponse(data.createUpdateChangeRequestCategory)
              // const error = this.responseCodesService.getCodeDescription(data.createUpdateChangeRequestCategory.code);
              // this.notificationService.errorMessage(error[0]?.description);
            }
          }
        })
      );
    })
  ), {dispatch: false});

  
  // Detele Branch
  removeRequestCategory$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.removeRequestCategory),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: fromGraphql.DELETE_REQUEST_CATEGORY,
        variables: {
          categoryId: action.id,
          id: action.id
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while deleting Request Category'),
        map(({ data }: any) => {
          if (data) {
            if (data.deleteChangeRequestCategory.code === 9000) {
              // this.store.dispatch(fromActions.deleteBranch({ id: action.id }));
              this.store.dispatch(fromActions.deleteRequestCategory({ id: action.id}));
              // this.store.dispatch(fromActions.upsertRequestCategory({ RequestCategory: data.deleteRequestCategory.data }));

              this.notificationService.successMessage('Deleted Successfully');
            } else {             
              this.handleResponse(data.deleteChangeRequestCategory)
            }
          }
        })
      );
    })
  ), {dispatch: false});



  constructor(
    private actions$: Actions,
    private apollo: Apollo,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private responseCodesService: ResponseCodeService
  ) {
  }

  handleResponse(data) {
    if (data.code === 9000) {
      this.store.dispatch(fromActions.upsertRequestCategory({ requestCategory: data.data }));
      return this.notificationService.successMessage('Action Completed Successful');
    } else {
      return this.handleError(data);
    }
  }

  handleError(data) {
    const responseCode = this.responseCodesService.getCodeDescription(data.code);
    const message = responseCode[0].code + ' : ' + responseCode[0].description;
    return this.notificationService.errorMessage(message);
  }

}
