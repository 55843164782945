<div class="incident-box">
  <div class="incident-content">
      <div class="incidentNo">
        <div>Ticket No :</div>
        <div>{{incident?.incidentNo}}</div>
      </div>
      <div class="inc-data">
        <div class="data-title">Issue Description</div>
        <div class="data-value">{{incident?.description}}</div>
      </div> 
      <div class="trailers">
        <ul>
          <li *ngFor=" let trail of incidentTrail$ |async">
            <div>
              <time>{{trail?.createdAtFormatted}}</time>
              <p>{{trail?.description}}</p>
            </div>
          </li>
        </ul>
      </div>
      <div class="inc-data">
        <div class="data-title">Issue State</div>
        <div class="data-value">{{incident?.state | uppercase}}</div>
      </div>
  </div>
  <span class="close-incident">
      <mat-icon (click)="closeThisModal()">clear</mat-icon>
  </span>
</div>

<script>
  var items = document.querySelectorAll("li");

  function isItemInView(item){
    var rect = item.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
  }

  function callbackFunc() {
    for (var i = 0; i < items.length; i++) {
      if (isItemInView(items[i])) {
        items[i].classList.add("show");
      }
    }
  }

  // listen for events
  window.addEventListener("load", callbackFunc);
  window.addEventListener("resize", callbackFunc);
  window.addEventListener("scroll", callbackFunc);

</script>
