import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Institution, InstitutionDtoInput, ServiceList } from './institution.model';
import { PageableParam } from 'src/app/interfaces/global.interface';

export const loadInstitutions = createAction(
  '[Institution/API] Load Institutions',
  props<{ institutions: Institution[] }>()
);

export const loadProviderInstitutions = createAction(
  '[Institution/API] Load Provider Institutions',
  props<{ institutions: any}>()
);

export const addInstitution = createAction(
  '[Institution/API] Add Institution',
  props<{ institution: Institution }>()
);

export const upsertInstitution = createAction(
  '[Institution/API] Upsert Institution',
  props<{ institution: Institution }>()
);

export const addInstitutions = createAction(
  '[Institution/API] Add Institutions',
  props<{ institutions: Institution[] }>()
);

export const upsertInstitutions = createAction(
  '[Institution/API] Upsert Institutions',
  props<{ institutions: Institution[] }>()
);

export const updateInstitution = createAction(
  '[Institution/API] Update Institution',
  props<{ institution: Update<Institution> }>()
);

export const updateInstitutions = createAction(
  '[Institution/API] Update Institutions',
  props<{ institutions: Update<Institution>[] }>()
);

export const deleteInstitution = createAction(
  '[Institution/API] Delete Institution',
  props<{ id: string }>()
);

export const deleteInstitutions = createAction(
  '[Institution/API] Delete Institutions',
  props<{ ids: string[] }>()
);

export const clearInstitutions = createAction(
  '[Institution/API] Clear Institutions'
);

// Effects Actions 
export const getInstitutionList = createAction(
  '[Institution/API] Get Institution List',
  props<{ pageaParam?: PageableParam }>()
);

export const getInstitutionListForOptions = createAction(
  '[Institution/API] Get Institution List For Options',
  props<{ pageaParam: PageableParam }>()
);


export const getAllInstitutionClientsAvailable = createAction(
  '[Institution/API] Get All Institution Clients Available List',
  props<{ pageaParam?: PageableParam }>()
);


export const availableServiceProvader = createAction(
  '[Institution/API] Get Available Service provider Institution List',
  props<{ pageaParam?: PageableParam }>()
);


export const getSubScribersList = createAction(
  '[Institution/API] Get SubScriberInstitution List',
  props<{ pageaParam?: PageableParam }>()
);

export const getSubScribersByServiceId = createAction(
  '[Institution/API] Get SubScriberInstitution By Service ID',
  props<{serviceId?:number, pageaParam: PageableParam }>()
);

export const getMyServiceSubscribersByConfirmedStatus = createAction(
  '[Institution/API] Get getMyServiceSubscribers ByConfirmedStatus List',
  props<{ confirmed?:boolean, pageaParam?: PageableParam }>()
);

export const getAllServiceProviders = createAction(
  '[Institution/API] Get Service ProviderInstitution List',
  props<{ pageaParam?: PageableParam }>()
);


export const getBlockedInstitutionList = createAction(
  '[Institution/API] Get Blocked Institution List'
);

export const listServiceByInstitutionId = createAction(
  '[Institution/API] Get Service Institution Id',
  props<{ id: number }>()
);

export const loadInstitutionServices = createAction(
  '[Institution/API] Load Service Institution',
  props<{ institutionServices: ServiceList[] }>()
);

export const getDefaultInstitution = createAction(
  '[Institution/API] Get Default Institution'
);

export const getInstitutionById = createAction(
  '[Institution/API] Get Institution By Id',
  props<{ institutionId: string }>()
);


// UPDATE_INSTITUTION
export const CreateInstitutionProfile = createAction(
  '[Institution/API] Create Institution Profile',
  props<{institution: InstitutionDtoInput}>()
);

export const updateInstitutionDetails = createAction(
  '[Institution/API] Update Institution',
  props<{institution: InstitutionDtoInput}>()
);

export const activateInstitution = createAction(
  '[Institution/API] Activate Institution',
  props<{ id: number}>()
);

export const deactivateInstitution = createAction(
  '[Institution/API] Deactivate Institution',
  props<{ id: number;}>()
);

export const deleteInstitutionDetails = createAction(
  '[Institution/API] delete Institution Details',
  props<{ id: number; }>()
);

export const deleteClientFromMyServiceSubscription = createAction(
  '[Institution/API] unsubscribe client from service ',
  props<{ id: number; }>()
);




export const unblockBlockedInstitution = createAction(
  '[Institution/API] unblockBlockedInstitution Institution Details',
  props<{ id: number; }>()
);


export const sycInstitutionToGISP = createAction(
  '[Institution/API] sycInstitutionToGISP Institution to Gisp',
  // props<{ id: number; }>()
);

export const getMyInstitutionById = createAction(
  '[Institution/API] getMyInstitutionById Institution Details',
  props<{ id: string; }>()
);
export const getInstitutionByUuid = createAction(
  '[Institution/API] get Institution Details By Uuid ',
  props<{ uuid: string; }>()
);

export const confirmSubscription = createAction(
  '[Institution/API] confirmSubscription ',
  props<{ clientId: number; }>()
);

export const setIncidentHandlingMode = createAction(
  '[Incident/API] set setIncidentHandlingMode ',
  props<{ setIncidentHandlingMode: string }>()
)

export const getAllInstitutionFewFields = createAction(
  '[Incident/API] get institution few fields ',
  props<{ pageaParam?: PageableParam }>()
)

export const getSubScribersListFewFields = createAction(
  '[Institution/API] Get SubScriberInstitution few fields List',
  props<{ pageaParam?: PageableParam }>()
);

export const getAllServiceProvidersFewFields = createAction(
  '[Institution/API] Get Service providers List few fields',
  props<{ pageaParam?: PageableParam }>()
);

export const getMyNonEmployeeServiceProviders = createAction(
  '[Institution/API] Get my  ProviderInstitution List',
  props<{ pageaParam?: PageableParam }>()
);
export const getMyNonEmployeeServiceProvidersForList = createAction(
  '[Institution/API] Get my  ProviderInstitution List For List',
  props<{ pageaParam: PageableParam }>()
);
