import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { AptitudeTest } from './aptitude-test.model';

export const loadAptitudeTests = createAction(
  '[AptitudeTest/API] Load AptitudeTests', 
  props<{ aptitudeTests: AptitudeTest[] }>()
);

export const addAptitudeTest = createAction(
  '[AptitudeTest/API] Add AptitudeTest',
  props<{ aptitudeTest: AptitudeTest }>()
);

export const upsertAptitudeTest = createAction(
  '[AptitudeTest/API] Upsert AptitudeTest',
  props<{ aptitudeTest: AptitudeTest }>()
);

export const addAptitudeTests = createAction(
  '[AptitudeTest/API] Add AptitudeTests',
  props<{ aptitudeTests: AptitudeTest[] }>()
);

export const upsertAptitudeTests = createAction(
  '[AptitudeTest/API] Upsert AptitudeTests',
  props<{ aptitudeTests: AptitudeTest[] }>()
);

export const updateAptitudeTest = createAction(
  '[AptitudeTest/API] Update AptitudeTest',
  props<{ aptitudeTest: Update<AptitudeTest> }>()
);

export const updateAptitudeTests = createAction(
  '[AptitudeTest/API] Update AptitudeTests',
  props<{ aptitudeTests: Update<AptitudeTest>[] }>()
);

export const deleteAptitudeTest = createAction(
  '[AptitudeTest/API] Delete AptitudeTest',
  props<{ id: number }>()
);

export const deleteAptitudeTests = createAction(
  '[AptitudeTest/API] Delete AptitudeTests',
  props<{ ids: string[] }>()
);

export const clearAptitudeTests = createAction(
  '[AptitudeTest/API] Clear AptitudeTests'
);


// CUSTOM
export const getAptitudeTestByZoneId = createAction(
  '[AptitudeTest/API] get Aptitude Test By Zone Id',
  props<{ zonalId: string, pageParam: any }>()
);


export const getAptitudeTestByEducationLevel = createAction(
  '[AptitudeTest/API] get Aptitude Test By Education Level',
  props<{ educationLevel: string, pageParam: any }>()
);

export const getAptitudeTestByEducationCategory = createAction(
  '[AptitudeTest/API] get Aptitude Test By Education Category',
  props<{ educationCategory: string, pageParam: any }>()
);

export const getAllAptitudeTests = createAction(
  '[AptitudeTest/API] get All Aptitude Test ',
  props<{ pageParam: any }>()
);



export const getAptitudeTestByDateRange = createAction(
  '[AptitudeTest/API] get Aptitude Test By Date Range',
  props<{ aptitudeTestStartDate: string, aptitudeTestEndDate: string, pageParam: any }>()
);

export const getAptitudeTestBySchedule = createAction(
  '[AptitudeTest/API] get Aptitude Test By Education Schedule',
  props<{ scheduleUid: string, pageParam: any }>()
);

export const removeAptitudeTest = createAction(
  '[AptitudeTest/API] Remove Aptitude Test ',
  props<{ aptitudeTestUid: string, id: number }>()
);

export const saveAndUpdateAptitudeTest = createAction(
  '[AptitudeTest/API] save And Update Aptitude Test',
  props<{ aptitudeTestDto: any }>()
);

export const startAptitudeTest = createAction(
  '[AptitudeTest/API] Start Aplitude Test',
  props<{ aptitudeTestUid: string }>()
);


export const publishAptitudeTest = createAction(
  '[AptitudeTest/API] Publish Aplitude Test',
  props<{ uuid: string,publish:boolean}>()
);

export const generateQuestionsForAptitudeTest = createAction(
  '[AptitudeTest/API] Generate Questions For Aplitude Test',
  props<{ uid: string}>()
);

// export const getMyAssignedAptitudeTest = createAction(
//   '[AptitudeTest/API] get My Assigned  Aptitude Test',
//   props<{ pageParam: any,isConducted:boolean }>()
// );


export const answerAptitudeTest = createAction(
  '[AptitudeTest/API] Answer an Aplitude Test',
  props<{ aptitudeTestDto: any }>()
);





export const getAptitudeTestByUid = createAction(
  '[AptitudeTest/API] get Aptitude Test By Uid',
  props<{ aptitudeTestUid: string }>()
);




export const getMyQuestionAnswersByAptitudeTest = createAction(
  '[AptitudeTest/API] GET USER ANSWER QUESTION BY USER AND APTITUDE TEST',
  props<{ aptitudeTestUid: string }>()
);

// export const submitMyAptitudeTest = createAction(
//   '[AptitudeTest/API] SUBMIT MY APTITUDE TEST',
//   props<{ aptitudeTestUuid: string }>()
// );

export const getAllAptitudeTestResults = createAction(
  '[AptitudeTest/API] GET ALL APTITUDE TEST RESULTS',
  props<{ aptitudeTestUid: string, pageParam: any}>()
);

export const getAptitudeTestResults = createAction(
  '[AptitudeTest/API] GET  APTITUDE TEST RESULT',
  props<{ aptitudeTestUid: string}>()
);

// getMyAptitudeTestInformation
export const getMyAptitudeTestInformation = createAction(
  '[AptitudeTest/API] GET MY APTITUDE TEST INFORMATION',
  props<{ aptitudeTestUid: string}>()
);

// getAptitudeTestUserByUserAndAptitudeTest
export const getAptitudeTestUserByUserAndAptitudeTest = createAction(
  '[AptitudeTest/API] GET APTITUDE TEST USER BY USER AND APTITUDE TEST',
  props<{ aptitudeTestUid: string,userEmail:string}>()
);
// getAptitudeTestUsersByAptitudeTest
export const getAptitudeTestUsersByAptitudeTest = createAction(
  '[AptitudeTest/API] GET APTITUDE TEST USERS BY APTITUDE TEST',
  props<{ aptitudeTestUid: string,pageParam:any}>()
);
export const getAptitudeTestUserByUser = createAction(
  '[AptitudeTest/API] GET APTITUDE TEST USER BY USER',
  props<{ aptitudeTestUid: string}>()
);

export const updateAptitudeTestUser = createAction(
  '[AptitudeTest/API] UPDATE APTITUDE TEST USER',
  props<{ aptitudeTestUserDto: any }>()
);

export const answerManyQuestionsAptitudeTest = createAction(
  '[AptitudeTest/API] Answer an Aplitude Test',
  props<{ aptitudeaptitudeTestDtoListTestDto: any }>()
);

export const getMyAttemptedAptitudeTests = createAction(
  '[AptitudeTest/API] GET MY ATTEMPTED APTITUDE TEST'
);

