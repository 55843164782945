import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import { switchMap, map } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { ResponseCodeService } from 'src/app/services/response-code.service';
import { AppState } from '../../reducers';
import * as fromActions from './kb-workflow.actions';
import * as fromGraphql from './kb-workflow.graphql';

@Injectable()
export class KbWorkflowEffects {

  addUpdateKBApprovalWorkflow$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.addUpdateKBApprovalWorkflow),
    switchMap((action) => {
       
      return this.apollo.mutate({
        mutation: fromGraphql.CREATE_APPROVAL_FLOW,
        variables: {
          approvalStage: action.approvalStage
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while saving'),
        map(({ data }: any) => {
          if (data) {
            const response = this.responseCodesService?.getCodeDescription(data.addUpdateKBApprovalWorkflow.code);
            if (data.addUpdateKBApprovalWorkflow.code === 9000) {
              this.store.dispatch(fromActions.upsertKbWorkflow({ kbWorkflow: data.addUpdateKBApprovalWorkflow.data }));
              this.notificationService.successMessage('Operation successful');
            } else {
              this.notificationService.determineSnackbar(response[0]);
            }
          }
        })
      );
    })
  ), {dispatch: false});

  getAllKBApprovalWorkflows$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.getAllKBApprovalWorkflows),
    switchMap((action) => {
      return this.apollo.query({
        query: fromGraphql.GET_PAGED_APPROVAL_FLOW,
        variables: {
          pageparam: action?.pageparam
        },
        fetchPolicy: 'network-only',
      }).pipe(
        this.notificationService.catchError('Problem occurred'),
        map(({ data }: any) => {
          if (data) {
            const result = data?.getAllKBApprovalWorkflows;
            if (result?.content?.length > 0) {
              this.paginationService.setPage(result);
            }
            this.store.dispatch(fromActions.loadKbWorkflows({ kbWorkflows: data.getAllKBApprovalWorkflows.content }));
          } else {
            this.notificationService.errorMessage('Failed to fetch Change request');
          }
        })
      );
    })
  ), {dispatch: false});

  getAllKBActiveApprovalStages$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.getAllKBActiveApprovalStages),
    switchMap((action) => {
      return this.apollo.query({
        query: fromGraphql.GET_ACTIVE_PAGED_APPROVAL_FLOW,
        variables: {
          pageparam: action?.pageparam
        },
        fetchPolicy: 'network-only',
      }).pipe(
        this.notificationService.catchError('Problem occurred'),
        map(({ data }: any) => {
          if (data) {
            const result = data?.getAllKBActiveApprovalStages;
            if (result?.content?.length > 0) {
              this.paginationService.setPage(result);
            }
            this.store.dispatch(fromActions.loadKbWorkflows({ kbWorkflows: data.getAllKBActiveApprovalStages.content }));
          } else {
            this.notificationService.errorMessage('Failed to fetch Change request');
          }
        })
      );
    })
  ), {dispatch: false});

  getKBApprovalStageByUuid$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.getKBApprovalStageByUuid),
    switchMap((action) => {
      return this.apollo.query({
        query: fromGraphql.GET_APPROVAL_FLOW_BY_UUID,
        variables: {
          uuid: action?.uuid
        },
        fetchPolicy: 'network-only',
      }).pipe(
        this.notificationService.catchError('Problem occurred'),
        map(({ data }: any) => {
          if (data) {
            const response = this.responseCodesService?.getCodeDescription(data.addUpdateKBApprovalWorkflow.code);
            if (data.addUpdateApprovalWorkflow.code === 9000) {
              this.store.dispatch(fromActions.upsertKbWorkflow({ kbWorkflow: data.addUpdateApprovalWorkflow.data }));
              this.notificationService.successMessage('Operation successful: '+ response[0].code);
            } else {
              this.notificationService.determineSnackbar(response[0]);
            }
          }
        })
      );
    })
  ), {dispatch: false});

  constructor(
    private actions$: Actions,
    private apollo: Apollo,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private responseCodesService: ResponseCodeService,
    private paginationService: PaginationService,
  ) {
  }

}
