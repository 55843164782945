import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { OnlineResource } from './online-resource.model';
import * as OnlineResourceActions from './online-resource.actions';

export const onlineResourcesFeatureKey = 'onlineResources';

export interface State extends EntityState<OnlineResource> {
  // additional entities state properties
}

export const adapter: EntityAdapter<OnlineResource> = createEntityAdapter<OnlineResource>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(OnlineResourceActions.addOnlineResource,
    (state, action) => adapter.addOne(action.onlineResource, state)
  ),
  on(OnlineResourceActions.upsertOnlineResource,
    (state, action) => adapter.upsertOne(action.onlineResource, state)
  ),
  on(OnlineResourceActions.addOnlineResources,
    (state, action) => adapter.addMany(action.onlineResources, state)
  ),
  on(OnlineResourceActions.upsertOnlineResources,
    (state, action) => adapter.upsertMany(action.onlineResources, state)
  ),
  on(OnlineResourceActions.updateOnlineResource,
    (state, action) => adapter.updateOne(action.onlineResource, state)
  ),
  on(OnlineResourceActions.updateOnlineResources,
    (state, action) => adapter.updateMany(action.onlineResources, state)
  ),
  on(OnlineResourceActions.deleteOnlineResource,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(OnlineResourceActions.deleteOnlineResources,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(OnlineResourceActions.loadOnlineResources,
    (state, action) => adapter.setAll(action.onlineResources, state)
  ),
  on(OnlineResourceActions.clearOnlineResources,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
