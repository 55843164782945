// import { Pageable } from 'src/app/interfaces/global.interface';
import {PageableParam} from '../../../interfaces/global.interface';
import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';

import {Assetc} from './assetc.model';

export const loadAssetcs = createAction(
    '[Assetcs/API] Load Assetcs',
    props<{ assetcs: Assetc[] }>()
);


export const addAssetc = createAction(
    '[Assetc/API] Add Assetc',
    props<{ assetc: Assetc }>()
);

export const upsertAssetc = createAction(
    '[Assetcs/API] Upsert Assetc',
    props<{ assetc: Assetc }>()
);

export const addAssetcs = createAction(
    '[Assetcs/API] Add Assetcs',
    props<{ assetcs: Assetc[] }>()
);

export const upsertAssetcs = createAction(
    '[Assetcs/API] Upsert Assetcs',
    props<{ assetcs: Assetc[] }>()
);

export const updateAssetc = createAction(
    '[Assetcs/API] Update Assetcs',
    props<{ assetc: Update<Assetc> }>()
);

export const updateAssetcs = createAction(
    '[Assetcs/API] Update Assetcs',
    props<{ assetcs: Update<Assetc>[] }>()
);

export const deleteAssetc = createAction(
    '[Assetcs/API] Delete Assetc',
    props<{ id: number }>()
);

export const deleteAssetcs = createAction(
    '[Assetcs/API] Delete Assetcs',
    props<{ ids: number[] }>()
);

export const clearAssetcs = createAction(
    '[Assetcs/API] Clear Assetcs'
);


// Effect Actions
export const listMyInstitutionAllAssetcs = createAction(
    '[Assetc/API] List My Institution All Assetcs',
    props<{ pageableParam?: PageableParam }>()
);

export const getAllInstitutionResourcesByResourceCategory = createAction(
    '[Assetc/API] Get Category Resource List Assetc',
    props<{ id: string, pageableParam: PageableParam }>()
);
export const getAllInstitutionResourcesByResourceCriticality = createAction(
    '[Assetc/API] Get Criticality Resource List Assetc',
    props<{ id: string, pageableParam: PageableParam }>()
);
export const getAllInstitutionResourcesByResourceType = createAction(
    '[Assetc/API] Get Type Resource List Assetc',
    props<{ id: string, pageableParam: PageableParam }>()
);
export const getAllInstitutionResourcesByManufacturer = createAction(
    '[Assetc/API] Get Manufacturer Resource List Assetc',
    props<{ id: string, pageableParam: PageableParam }>()
);
export const getAllInstitutionResourcesBySupplier = createAction(
    '[Assetc/API] Get Supplier Resource List Assetc',
    props<{ id: string, pageableParam: PageableParam }>()
);


export const activateAssetc = createAction(
    '[Assetc/API] Activate Assetc',
    props<{ id: string }>()
);


export const createUpdateAssetc = createAction(
    '[Assetc/API] Create Update Assetc',
    props<{ assetc: Assetc }>()
);

export const removeAssetc = createAction(
    '[Assetc/API] Remove Assetc By Id',
    props<{ id?: number, uuid: string }>()
);

export const getMyBranchAssetsByServiceUid = createAction(
    '[Assetc/API] get my branch Assets By service',
    props<{ serviceUuid: string }>()
);

export const getAllInternalAndExternalResources = createAction(
    '[Assetc/API] get all Assets By services',
    props<{ serviceUuid: string, pageable?: PageableParam }>()
);

export const getAllInternalAndExternalResourcesByConsumer = createAction(
    '[Assetc/API] get all Assets By services and institutionUUid',
    props<{ serviceUuid: string, consumerInstitutionUuid: string, pageable?: PageableParam }>()
);

export const getResourceByUuid = createAction(
    '[Assetc/API] get Resource By Uuid',
    props<{ resourceUuid: string }>()
);
