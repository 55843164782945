import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { BaseResponse } from '../../base.entity';

import * as fromGraphql from './material-folder.graphql';
import * as fromActions from './material-folder.actions';
import { Apollo } from 'apollo-angular';
import { NotificationService } from 'src/app/services/notification.service';
import { AppState } from '../../reducers';
import { Store } from '@ngrx/store';



@Injectable()
export class MaterialFolderEffects {
  constructor(private actions$: Actions,
    private apollo: Apollo,
    private store: Store<AppState>,
    private notificationService: NotificationService
  ) {}

  saveAndUpdateCourseMaterialFolder = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.saveAndUpdateCourseMaterialFolder),
        switchMap((action) => {
          return this.apollo
            .mutate({
              mutation: fromGraphql.SAVE_AND_UPDATE_COURSE_MATERIAL_FOLDER,
              variables: {
                courseMaterialFolderDto: action.courseMaterialFolderDto
              },
            })
            .pipe(
              map(({data}: any) => {

                let result: BaseResponse = Object.values(data)[0];

                if(result?.code === 9000){
                  this.store.dispatch(
                    fromActions.upsertMaterialFolder({
                      materialFolder: result?.data,
                    })
                  );
                  const message = "Folder Created Successfully";

                  this.notificationService.successMessage(message);

                } else {
                  this.notificationService.handleErrorMessage(result);
                }

              })
            );
        }),
      ),
    {dispatch: false}
  );



  getAllCourseMaterialFolders = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.getAllCourseMaterialFolders),
        switchMap((action) => {
          return this.apollo
            .query({
              query: fromGraphql.GET_ALL_COURSE_MATERIAL_FOLDERS,
              fetchPolicy: "network-only",
              variables: {
                pageParam: action.pageParam
              }
            })
            .pipe(
              map(({data}: any) => {
                let result: BaseResponse = Object.values(data)[0];
                  this.store.dispatch(
                    fromActions.loadMaterialFolders({ materialFolders: result?.content})
                  );
                
              })
            );
        }),
      ),
    {dispatch: false}
  );

  getCourseMaterialFoldersByUuid = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.getCourseMaterialFoldersByUuid),
        switchMap((action) => {
          return this.apollo
            .query({
              query: fromGraphql.GET_COURSE_MATERIAL_BY_UUID,
              fetchPolicy: "network-only",
              variables: {
                uuid: action.uuid
              }
            })
            .pipe(
              map(({data}: any) => {
                let result: BaseResponse = Object.values(data)[0];
                  this.store.dispatch(
                    fromActions.upsertMaterialFolder({ materialFolder: result?.data})
                  );
                
              })
            );
        }),
      ),
    {dispatch: false}
  );

  getCourseMaterialFoldersByAptitudeTestUuid = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.getCourseMaterialFoldersByAptitudeTestUuid),
        switchMap((action) => {
          return this.apollo
            .query({
              query: fromGraphql.GET_COURSE_MATERIAL_FOLDERS_BY_APTITUDE_TEST_UUID,
              fetchPolicy: "network-only",
              variables: {
                aptitudeTestUuid: action?.aptitudeTestUuid
              }
            })
            .pipe(
              map(({data}: any) => {
                let result: BaseResponse = Object.values(data)[0];

                if(result?.dataList){
                  this.store.dispatch(
                    fromActions.loadMaterialFolders({ materialFolders: result?.dataList})
                  );
                }
                
              })
            );
        }),
      ),
    {dispatch: false}
  );
}
