import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { ServiceType, ServiceTypeDtoInput } from './service-type.model';
import { PageableParam } from 'src/app/interfaces/global.interface';

export const loadServiceTypes = createAction(
  '[ServiceType/API] Load ServiceTypes', 
  props<{ serviceTypes: ServiceType[] }>()
);



export const addServiceType = createAction(
  '[ServiceType/API] Add ServiceType',
  props<{ serviceType: ServiceType }>()
);

export const upsertServiceType = createAction(
  '[ServiceType/API] Upsert ServiceType',
  props<{ serviceType: ServiceType }>()
);

export const addServiceTypes = createAction(
  '[ServiceType/API] Add ServiceTypes',
  props<{ serviceTypes: ServiceType[] }>()
);

export const upsertServiceTypes = createAction(
  '[ServiceType/API] Upsert ServiceTypes',
  props<{ serviceTypes: ServiceType[] }>()
);

export const updateServiceType = createAction(
  '[ServiceType/API] Update ServiceType',
  props<{ serviceType: Update<ServiceType> }>()
);

export const updateServiceTypes = createAction(
  '[ServiceType/API] Update ServiceTypes',
  props<{ serviceTypes: Update<ServiceType>[] }>()
);

export const deleteServiceType = createAction(
  '[ServiceType/API] Delete ServiceType',
  props<{ id: number }>()
);

export const deleteServiceTypes = createAction(
  '[ServiceType/API] Delete ServiceTypes',
  props<{ ids: string[] }>()
);

export const clearServiceTypes = createAction(
  '[ServiceType/API] Clear ServiceTypes'
);


///////// API CALLS ///////////


export const getAllInstitutionServiceTypes = createAction(
  '[ServiceType/API] Gett All ServiceTypes'
);

export const createUpdateServiceType = createAction(
  '[ServiceType/API] Create ServiceType',
  props<{ serviceType: ServiceTypeDtoInput }>()
);

export const getAllServiceTypesPageable = createAction(
  '[ServiceType/API] Get Paged ServiceType',
  props<{ pageableParam?: PageableParam }>()
);

export const deactivateServiceType = createAction(
  '[ServiceType/API] Deactivate ServiceType',
  props<{ serviceTypeUuid: String }>()
);
export function loadInstitutions(arg0: { institutions: any; }): any {
  throw new Error('Function not implemented.');
}

