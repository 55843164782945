import { PageableParam } from '../../../interfaces/global.interface';
import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Calltracker, CallTrackerDtoInput } from './calltracker.model';



export const loadCalltrackers = createAction(
  '[Calltracker/API] Load Calltrackers',
  props<{ calltrackers: Calltracker[] }>()
);

export const addCalltracker = createAction(
  '[Calltracker/API] Add Calltracker',
  props<{ calltracker: Calltracker }>()
);

export const upsertCalltracker = createAction(
  '[Calltracker/API] Upsert Calltracker',
  props<{ calltracker: Calltracker }>()
);

export const addCalltrackers = createAction(
  '[Calltracker/API] Add Calltrackers',
  props<{ calltrackers: Calltracker[] }>()
);

export const upsertCalltrackers = createAction(
  '[Calltracker/API] Upsert Calltrackers',
  props<{ calltrackers: Calltracker[] }>()
);

export const updateCalltracker = createAction(
  '[Calltracker/API] Update Calltracker',
  props<{ calltracker: Update<Calltracker> }>()
);

export const updateCalltrackers = createAction(
  '[Calltracker/API] Update Calltrackers',
  props<{ calltrackers: Update<Calltracker>[] }>()
);

export const deleteCalltracker = createAction(
  '[Calltracker/API] Delete Calltracker',
  props<{ id: number }>()
);

export const deleteCalltrackers = createAction(
  '[Calltracker/API] Delete Calltrackers',
  props<{ ids: string[] }>()
);

export const clearCalltrackers = createAction(
  '[Calltracker/API] Clear Calltrackers'
);


export const removeCalltracker = createAction(
  '[Calltracker/API] remove Calltracker',
  props<{registryUuid: string, id: number }>()
);


////////////////////  APIs: ////////////////////////

// saveCallTracker
export const saveCallTracker = createAction(
  '[Calltracker/API] Save Calltracker',
  props<{ dto: CallTrackerDtoInput }>()
);

// getCallTrackerList
export const getCallTrackerListByInstitutionPageable = createAction(
  '[Calltracker/API] get All CallTracker List by institution uuid pageable',
  props<{institutionUuid?: string, pageable?: PageableParam }>()
);

export const getCallTrackerListByDepartmentPageable = createAction(
  '[Calltracker/API] get All CallTracker List by Department uuid pageable',
  props<{departmentUuid?: string, pageable?: PageableParam }>()
);

export const getCallTrackerListByBranchPageable = createAction(
  '[Calltracker/API] get All CallTracker List by Branch uuid pageable',
  props<{branchUuid?: string, pageable?: PageableParam }>()
);


