import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-user-import-table',
  templateUrl: './import-table.component.html',
  styleUrls: ['./import-table.component.scss']
})
export class ImportTableComponent implements OnInit {
    @Input() tableData: any;
    displayedColumns: string[] = ['no', 'firstName', 'middleName', 'lastName', 'email', 'phone', 'designationCode', ];

    @ViewChild(MatPaginator) paginator: MatPaginator;
    dataSource: MatTableDataSource<any>;
  
    ngAfterViewInit() {
      this.dataSource.paginator = this.paginator;
    }

    applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    constructor(
    ) { 
    }

    ngOnInit(): void {
        this.dataSource = new MatTableDataSource<any>(this.tableData);
    }

}