import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {map, shareReplay} from 'rxjs/operators';
import {Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Menu } from 'src/app/interfaces/global.interface';
import { AuthService } from 'src/app/services/auth.service';
import { StorageService } from 'src/app/services/storage.service';
import { AuthUser } from 'src/app/store/entities/auth-user/auth-user.model';
import { selectModifiedAuthUsers } from 'src/app/store/entities/auth-user/auth-user.selectors';
import { AppState } from 'src/app/store/reducers';
import { ChangePasswordComponent } from '../../change-password/change-password.component';

@Component({
  selector: 'app-home-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HomeHeaderComponent implements OnInit {

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
  user$: Observable<AuthUser>;
  at: string;
  gispUrl: string;
  @Input() menu: Menu;
  sideMenu: Menu;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private authService: AuthService,
    private router: Router,
    private store: Store<AppState>,
    private dialog: MatDialog,
    private storage: StorageService,
  ) {
    this.at = this.storage.getItem('currentClient');
  }

  ngOnInit() {
    this.sideMenu = this.menu;
  }

  openChangePasswordDialog(){
    const dialogConfig = new MatDialogConfig();
    // dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '50%';
    // dialogConfig.height = '95vh';
    // dialogConfig.position = { top: '0', right: '0' };
    dialogConfig.panelClass = 'incident-details';
    this.dialog.open(ChangePasswordComponent, dialogConfig);
  }
  logout() {
    this.authService.logout();
    this.router.navigateByUrl('/');
  }

  cleanStorage() {
    //  
    this.storage.clearStorage();
  }


}
