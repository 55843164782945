import { Injectable } from '@angular/core';
import * as SecureLS from 'secure-ls';
import { User } from '../store/entities/settings/user/user.model';
import { AuthTokenModel } from './auth.service';

export const TOKEN_DETAILS_KEY = 'abc_details';
export const TEMP_TOKEN_DETAILS_KEY = 'temp_abc_details';
export const TEMP_TOKEN_KEY = 'temp_abc';
const ACTIVE_TAB = 'def';
const SCREEN_LOCKED = 'ghi';
const NHIF_LOGIN_TIME_KEY = 'njklh';
const LOGIN_TIME_KEY = 'jkl';
const USER_KEY = 'mno';
export const REDIRECT_URL = 'pqr';
export const LAST_TIME_TOKEN_REFRESH = 'stu';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private ls = new SecureLS({ encodingType: 'aes', isCompression: true });

  constructor() { }

  setItem(itemName, itemValue) {
    // localStorage.setItem(itemName, itemValue);
    this.ls.set(itemName, itemValue);
  }

  getItem(itemName) {
    // return localStorage.getItem(itemName);
    return this.ls.get(itemName);
  }

  clearStorage() {
    this.ls.removeAll();
    localStorage.clear();
  }
  
  public getScreenLocked(): boolean {
    return this.getItem(SCREEN_LOCKED) == 'true'
  }
  
  removeItem(key: string) {
      window.sessionStorage.removeItem(key);
  }
  
  
  public saveTempTokenDetails(details: AuthTokenModel): void {
    this.removeItem(TEMP_TOKEN_DETAILS_KEY);
    this.setItem(TEMP_TOKEN_DETAILS_KEY, JSON.stringify(details));
  }

  public getTempTokenDetails(): AuthTokenModel | undefined {
    const details = this.getItem(TEMP_TOKEN_DETAILS_KEY);
    if (details) {
      return JSON.parse(details);
    }

    return undefined;
  }
  public saveTokenDetails(details: AuthTokenModel): void {
    this.removeItem(TOKEN_DETAILS_KEY);
    this.setItem(TOKEN_DETAILS_KEY, JSON.stringify(details));
  }
  public saveLogInTime(time: string): void {
    this.removeItem(LOGIN_TIME_KEY);
    this.setItem(LOGIN_TIME_KEY, time);
  }
  public saveScreenLocked(isLocked: boolean): void {
    this.removeItem(SCREEN_LOCKED);
    this.setItem(SCREEN_LOCKED, isLocked);
  }
  public saveUser(user: any): void {
    this.removeItem(USER_KEY)
    this.setItem(USER_KEY, JSON.stringify(user));
  }

  public getUser(): User | undefined {
    const user = this.getItem(USER_KEY);
    if (user) {
      return JSON.parse(user);
    }

    return undefined;
  }
  public getTokenDetails(): AuthTokenModel | undefined {
    const details = this.getItem(TOKEN_DETAILS_KEY);
    if (details) {
      return JSON.parse(details);
    }
    return undefined;
  }
  
  public getToken(): string | undefined {
    return this.getTokenDetails()?.access_token;
  }
  
  public getLastTokenRefresh(): string | null {
    return this.getItem(LAST_TIME_TOKEN_REFRESH);
  }
  public saveLastTokenRefresh(time: string): void {
    this.removeItem(LAST_TIME_TOKEN_REFRESH)
    this.setItem(LAST_TIME_TOKEN_REFRESH, time);
  }
}
