import {DialogPosition} from '@angular/material/dialog';
import {enumToObjectArray} from '../shared/functions/functions';
import {SearchCombinationType} from '../store/entities/page/page.model';

import {
    ApexAxisChartSeries,
    ApexChart,
    ApexDataLabels,
    ApexFill,
    ApexGrid,
    ApexLegend,
    ApexMarkers,
    ApexNonAxisChartSeries,
    ApexPlotOptions,
    ApexResponsive,
    ApexStroke,
    ApexTitleSubtitle,
    ApexTooltip,
    ApexXAxis,
    ApexYAxis,
} from 'ng-apexcharts';

export interface MenuItem {
    name: string;
    route?: string;
    iconType?: string;
    icon: string;
    permission?: string[];
    url?: string;
    count?: string;
    children?: MenuItem[];
}

export interface Menu {
    moduleName?: string;
    moduleIcon?: string;
    menuItems: MenuItem[];
}

export interface SvgIcon {
    name: string;
    tag: string;
}

export interface ResponseCode {
    code: number;
    description: string;
}

export interface PageableParam {
    first?: number;
    size?: number;
    sortBy?: string;
    sortDirection?: string;
    searchFields?: SearchFieldsDtoInput[];
}

export interface SearchFieldsDtoInput {
    fieldName?: string;
    fieldValue?: string;
    fieldValues?: string[];
    searchType?: SearchOperationType;
    queryOperator?: QueryOperator;
}

export enum SearchOperationType {
    NotEqual = 'NotEqual',
    Equals = 'Equals',
    GreaterThan = 'GreaterThan',
    In = 'In',
    LessThan = 'LessThan',
    Like = 'Like',
    NotEquals = 'NotEquals',
    like = 'like',
}

export enum QueryOperator {
    and = 'AND',
    or = 'OR',
}

export const initializedPageableParameter = {
    first: 0,
    size: 10,
    sortBy: 'id',
    sortDirection: 'DESC',
};

export const specialInitializedPageableParameter = {
    first: 0,
    size: 1000,
    sortBy: 'id',
    sortDirection: 'DESC',
};

export interface AttachmentDtoInput {
    id?: number;
    createdAt?: string;
    fullPath?: string;
    dataBinary?: string | ArrayBuffer;
    sourceName?: string;
    filePath?: string;
    attachmentTitle?: string;
    attachmentType?: string;
    data?: string | ArrayBuffer;
    sourceUid?: string;
}

export interface OpenDialog {
    data?: any;
    component: any;
    width?: string;
    panelClass?: string[];
    position?: DialogPosition;
    height?: string;
}

export const rating = [
    {name: 'FAIR', value: 'FAIR'},
    {name: 'GOOD', value: 'GOOD'},
    {name: 'VERY GOOD', value: 'VERYGOOD'},
];

export interface ProfileStatus {
    isFilled: boolean;
    isRequired: boolean;
    itemName: string;
}

export const tzPhone = '(255|0)[^0123459]([0-9]{8})';

export const generalPhoneNumber = '[0-9]{6,}';

export enum IncidentReportingType {
    external = 'EXTERNAL',
    onbehalf = 'ONBEHALF',
    internal = 'INTERNAL',
    onbehalfinternal = 'ONBEHALFINTERNAL',
    nonEmployee = 'NONEMPLOYEE',
}

export enum ServiceUserType {
    client = 'CLIENT',
    serviceProvider = 'SERVICEPROVIDER',
    nonEmployee = 'NON_EMPLOYEE',
}

export const INCIDENT_REPORTING_TYPE = enumToObjectArray(IncidentReportingType);

export interface PermissionCheck {
    isFirstLevel: boolean;
    isSecondLevel: boolean;
    isFirstLevelSupervisor: boolean;
    isSecondLevelSupervisor: boolean;
    canWorkOnAllTickets: boolean;
    canWorkOnOutgoingTickets: boolean;
    canWorkOnInternalTickets: boolean;
    isHeadOfDepartment: boolean;
    isExecutiveOfficer: boolean;
}

export enum IncidentNotificationType {
    external = 'EXTERNAL', // for incomming
    internal = 'INTERNAL',
    client = 'CLIENT', // for client only
    departmental = 'DEPARTMENTAL',
    section = 'SECTION',
    individual = 'INDIVIDUAL',
    nonEmployee = 'NONEMPLOYEE',
}

export interface SearchState {
    key?: string;
    name?: string;
    values?: any[];
    active?: boolean;
    value?: any;
    searchType?: SearchOperationType;
}

export interface SearchDataDtoInput {
    pageableParam: PageableParam;
    searchCombinationType?: SearchCombinationType;
}

export interface InputSearchState {
    keys?: string[];
    value?: any;
}

export interface PageableResponse<T> {
    content?: T[];
    pagination?: ResponsePagination;
    size?: number;
    number?: number;
    first?: boolean;
    last?: boolean;
}

export interface ResponsePagination {
    totalElements?: number;
    totalPages?: number;
    hasContent: boolean;
}

export type ChartOptionsMod = {
    series: ApexNonAxisChartSeries;
    chart: ApexChart;
    labels: string[];
    colors: string[];
    legend: ApexLegend;
    plotOptions: ApexPlotOptions;
    responsive: ApexResponsive | ApexResponsive[];
};

export type ChartOptionsLine = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    xaxis: ApexXAxis;
    dataLabels: ApexDataLabels;
    grid: ApexGrid;
    stroke: ApexStroke;
    title: ApexTitleSubtitle;
    yaxis: ApexYAxis;
    fill: ApexFill;
    markers: ApexMarkers;
    colors: string[];
    legend?: ApexLegend;
    plotOptions?: ApexPlotOptions;
    subtitle?: any;
    labels?: any;
};

export type ChartOptionsDonut = {
    series: ApexNonAxisChartSeries;
    chart: ApexChart;
    responsive: ApexResponsive[];
    labels: any;
    title: ApexTitleSubtitle;
    plotOptions: ApexPlotOptions;
    fill: any;
    legend?: ApexLegend;
};

export type ChartOptionsColumn = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    dataLabels: ApexDataLabels;
    plotOptions: ApexPlotOptions;
    yaxis: ApexYAxis;
    xaxis: ApexXAxis;
    fill: ApexFill;
    tooltip: ApexTooltip;
    stroke: ApexStroke;
    legend: ApexLegend;
    title: ApexTitleSubtitle;
    subtitle?: any;
};

export const yesNoOptions = [
    {name: 'YES', value: true},
    {name: 'NO', value: false},
];

export interface InstitutionManagementStatisticsResponseDto {
    branches: number;
    departments: number;
    designations: number;
    roles: number;
    serviceType: number;
    services: number;
    users: number;
}
