import { createSelector } from '@ngrx/store';
import * as fromPageableReducer from './pageable.reducer';
import { AppState } from '../../reducers';

export const currentState = (state: AppState) =>
  state[fromPageableReducer.pageablesFeatureKey];

export const selectPageFromReducer = createSelector(
  currentState,
  fromPageableReducer.selectAll
);

export const selectPage = createSelector(
  selectPageFromReducer,
  (pages) => pages[0]
);
