<!-- If we're loading -->
<ng-template #loading>
  <mat-form-field appearance="outline">
    <mat-label>{{field?.label}} ...</mat-label>
    <input matInput
      disabled>
    <mat-spinner matSuffix
      [diameter]="18"
      style="float: right; margin-left: 8px"></mat-spinner>
  </mat-form-field>
</ng-template>
<ng-container *ngIf="!field?.optionData?.loading && (filteredData$ | async) as options; else loading">
  <mat-form-field class="demo-full-width margin-top"
    [hideRequiredMarker]="field?.hideRequiredMarker"
    [formGroup]="group"
    appearance="outline">
    <mat-label>{{field?.label}}</mat-label>
    <!--        <mat-select (openedChange)="openedChange($event)" gInfiniteScroll (infiniteScroll)="getNextBatch()"-->
    <mat-select (openedChange)="openedChange($event)"
      [required]="required"
      [placeholder]="field?.placeholder || ''"
      [formControlName]="field?.key || null"
      (selectionChange)="fieldChange($event)"
      [multiple]="field?.multiple">
      <ng-container *ngIf="!showTable">
        <mat-option class="multiline-mat-option">
          <ng-template [ngIf]="!field?.multiple">
            <!--disableScrollToActiveOnOptionsChanged should be set to true-->
            <app-select-search [formControl]="searchCtrl"
              [disableScrollToActiveOnOptionsChanged]="true"
              [noEntriesFoundLabel]="noEntriesFoundLabel">
            </app-select-search>
          </ng-template>
          <ng-template [ngIf]="field?.multiple">
            <app-select-search [formControl]="searchCtrl"
              [noEntriesFoundLabel]="noEntriesFoundLabel"
              [showToggleAllCheckbox]="true"
              [toggleAllCheckboxIndeterminate]="(field?.key && group.controls[field?.key!]?.value?.length > 0 && group.controls[field?.key!]?.value?.length < field?.optionData?.value?.length) || false"
              [toggleAllCheckboxChecked]="(field?.key && group.controls[field?.key!]?.value?.length === field?.optionData?.value?.length) || false"
              (toggleAll)="toggleSelectAll($event)">
            </app-select-search>
          </ng-template>
        </mat-option>
        <mat-option *ngIf="!field?.multiple">--- Select ---</mat-option>
        <span *ngIf="!field?.removeUnderscore">
          <mat-option *ngFor="let item of options || [] | search: 'name': searchCtrl.value"
            [value]="item.value"
            [disabled]="item.disabled"
            [ngClass]="{'multiline-mat-option': true,'danger': item.danger}">{{item.name | dynamicPipes:
            field?.dynamicPipes || []}}
          </mat-option>
        </span>
      </ng-container>
    </mat-select>

    <mat-hint><strong>{{field?.hint}}</strong> </mat-hint>
    <ng-container *ngFor="let validation of field?.validations;"
      ngProjectAs="mat-error">
      <mat-error *ngIf="field?.key && group.controls[field?.key!]?.hasError(validation.name)">{{validation.message}}</mat-error>
    </ng-container>
    <button color="primary"
      *ngIf="field?.tableColumns"
      mat-button
      matSuffix
      mat-icon-button
      type="button"
      (click)="showTableFn()">
      <mat-icon>visibility</mat-icon>
    </button>
  </mat-form-field>
</ng-container>