import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ResourceType } from './resource-type.model';
import * as ResourceTypeActions from './resource-type.actions';

export const ResourceTypesFeatureKey = 'ResourceTypes';

export interface State extends EntityState<ResourceType> {
  // additional entities state properties
}

export const adapter: EntityAdapter<ResourceType> = createEntityAdapter<ResourceType>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(ResourceTypeActions.addResourceType,
    (state, action) => adapter.addOne(action.resourceType, state)
  ),
  on(ResourceTypeActions.upsertResourceType,
    (state, action) => adapter.upsertOne(action.resourceType, state)
  ),
  on(ResourceTypeActions.addResourceTypes,
    (state, action) => adapter.addMany(action.resourceTypes, state)
  ),
  on(ResourceTypeActions.upsertResourceTypes,
    (state, action) => adapter.upsertMany(action.resourceTypes, state)
  ),
  on(ResourceTypeActions.updateResourceType,
    (state, action) => adapter.updateOne(action.resourceType, state)
  ),
  on(ResourceTypeActions.updateResourceTypes,
    (state, action) => adapter.updateMany(action.resourceTypes, state)
  ),
  on(ResourceTypeActions.deleteResourceType,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(ResourceTypeActions.deleteResourceTypes,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(ResourceTypeActions.loadResourceTypes,
    (state, action) => adapter.setAll(action.resourceTypes, state)
  ),
  on(ResourceTypeActions.clearResourceTypes,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
