import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { LdapConfiguration } from './ldap-configuration.model';
import * as LdapConfigurationActions from './ldap-configuration.actions';

export const ldapConfigurationsFeatureKey = 'ldapConfigurations';

export interface State extends EntityState<LdapConfiguration> {
  // additional entities state properties
}

export const adapter: EntityAdapter<LdapConfiguration> = createEntityAdapter<LdapConfiguration>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export const reducer = createReducer(
  initialState,
  on(LdapConfigurationActions.upsertLdapConfiguration,
    (state, action) => adapter.upsertOne(action.ldapConfiguration, state)
  ),
  on(LdapConfigurationActions.addLdapConfigurations,
    (state, action) => adapter.addMany(action.ldapConfigurations, state)
  ),
  on(LdapConfigurationActions.upsertLdapConfigurations,
    (state, action) => adapter.upsertMany(action.ldapConfigurations, state)
  ),
  on(LdapConfigurationActions.updateLdapConfiguration,
    (state, action) => adapter.updateOne(action.ldapConfiguration, state)
  ),
  on(LdapConfigurationActions.updateLdapConfigurations,
    (state, action) => adapter.updateMany(action.ldapConfigurations, state)
  ),
  on(LdapConfigurationActions.deleteLdapConfiguration,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(LdapConfigurationActions.deleteLdapConfigurations,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(LdapConfigurationActions.loadLdapConfigurations,
    (state, action) => adapter.setAll(action.ldapConfigurations, state)
  ),
  on(LdapConfigurationActions.clearLdapConfigurations,
    state => adapter.removeAll(state)
  ),
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
