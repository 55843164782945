import { createSelector } from '@ngrx/store';
import { AppState } from './../../../reducers/index';
import * as fromUserReducer from './user.reducer';
import { User } from './user.model';

export const currentState = (state: AppState) => (state[fromUserReducer.usersFeatureKey]);

export const selectAllUsers = createSelector(currentState, fromUserReducer.selectAll);

export const selectTotalUsers = createSelector(currentState, fromUserReducer.selectTotal);

export const selectModifiedUsers = createSelector(selectAllUsers, (users) => {
    return users.map((user: any) => {
        return {
            ...user,

            firstName: user.firstName ?? "",
            middleName: user.middleName ?? "",
            lastName: user.lastName ?? "",

            fullName: user?.fullName,
            isdepartment: user?.department?.departmentName,
            tascoGroup: user?.tascoGroup?.name ?? "",
            modDepartment: user?.department?.departmentName,
            iSdesignation: user?.designation?.designationName,
            headDepartment: user?.headOfDepartment ? 'Head of Department' : '',
            isBranch: user?.branch ? user?.branch.branchName : user?.registeredByBranch ? user?.registeredByBranch.branchName : '',
            isActive: user?.active ? 'ACTIVE' : 'IN-ACTIVE',
            locked: user?.accountNonLocked ? 'LOCKED' : '-',
            roles_: user?.rolesList?.name

        };
    });
});


export const selectActiveUsers = createSelector(
    selectModifiedUsers,
    ((users: User[]) => {
        return users.filter(user => user?.deleted === false).map(u => {
            const user: User = {
                ...u,
                firstName: u.firstName ?? "",
                middleName: u.middleName ?? "",
                lastName: u.lastName ?? "",
            };
            return u;
        });
    })
);

export const selectUsersByDepartmentId = (params: { id: number }) => createSelector(
    selectModifiedUsers,
    (users: User[]) => {
        return users.filter(user => user?.department?.id === params.id).map(u => {
            const user: User = {
                ...u,
                firstName: u.firstName ?? "",
                middleName: u.middleName ?? "",
                lastName: u.lastName ?? "",
            };
            return u;
        });
    }
);

export const selectUsersByInstitutionUuid = (params: { uuid: string }) => createSelector(
    selectModifiedUsers,
    (users: User[]) => {
        return users.filter(user => user?.institution?.uuid === params.uuid).map(u => {
            const user: User = {
                ...u,
                firstName: u.firstName ?? "",
                middleName: u.middleName ?? "",
                lastName: u.lastName ?? "",
            };
            return u;
        });
    }
);

export const selectUsersById = (params: { userId: number }) => createSelector(
    selectModifiedUsers,
    (users: User[]) => {
        const u = users.find(user => user?.id === params.userId);

        const user: User = {
            ...u,
            firstName: u.firstName ?? "",
            middleName: u.middleName ?? "",
            lastName: u.lastName ?? "",
        };

        return user;
    }
);

export const selectMyDetails = createSelector(
    currentState,
    fromUserReducer.getMyDetails
);

