import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { IncidentCategoryDtoInput, IncidentType } from './incident-type.model';
import { PageableParam } from 'src/app/interfaces/global.interface';

export const loadIncidentTypes = createAction(
  '[IncidentType/API] Load IncidentTypes', 
  props<{ incidentTypes: IncidentType[] }>()
);

export const addIncidentType = createAction(
  '[IncidentType/API] Add IncidentType',
  props<{ incidentType: IncidentType }>()
);

export const upsertIncidentType = createAction(
  '[IncidentType/API] Upsert IncidentType',
  props<{ incidentType: IncidentType }>()
);

export const addIncidentTypes = createAction(
  '[IncidentType/API] Add IncidentTypes',
  props<{ incidentTypes: IncidentType[] }>()
);

export const upsertIncidentTypes = createAction(
  '[IncidentType/API] Upsert IncidentTypes',
  props<{ incidentTypes: IncidentType[] }>()
);

export const updateIncidentType = createAction(
  '[IncidentType/API] Update IncidentType',
  props<{ incidentType: Update<IncidentType> }>()
);

export const updateIncidentTypes = createAction(
  '[IncidentType/API] Update IncidentTypes',
  props<{ incidentTypes: Update<IncidentType>[] }>()
);

export const deleteIncidentType = createAction(
  '[IncidentType/API] Delete IncidentType',
  props<{ id: number }>()
);

export const deleteIncidentTypes = createAction(
  '[IncidentType/API] Delete IncidentTypes',
  props<{ ids: string[] }>()
);

export const clearIncidentTypes = createAction(
  '[IncidentType/API] Clear IncidentTypes'
);

//////// API CALLS ////////

export const getAllIncidentCategories = createAction(
  '[IncidentType/API] get All Incidents By Incident Type Category',
  props<{ pageparam?: PageableParam }>());

export const getIncidentCategoryByUuid = createAction(
  '[IncidentType/API] get Issue Category By Uuid',
  props<{ uuid: string }>()
);

export const createdUpdateIncidenceCategory = createAction(
  '[IncidentType/API] created Update Incidence Category',
  props<{ incidentCategory: IncidentCategoryDtoInput }>()
);

export const deleteIncidentCategoryByUuid = createAction(
  '[IncidentType/API] delete Issue Category By Uuid',
  props<{id:number, uuid: string }>()
);

export const getAllIncidentCategoriesByInstitutionID = createAction(
  '[IncidentType/API] Get Issue Category By institution Uuid',
  props<{institutionUid:string}>()
);
