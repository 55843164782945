import { ReviewDecission } from './../../../store/entities/change-management/change-management.model';
import { FieldConfig } from './../dynamic-forms-components/field.interface';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as formConfigs from './create-change-request-form-fields';
import { directorReviewChangeRequest, managerReviewChangeRequest, updateChangeRequest } from 'src/app/store/entities/change-management/change-management.actions';
import { ChangeManagement } from 'src/app/store/entities/change-management/change-management.model';
import { AppState } from 'src/app/store/reducers';
import { DynamicFormService } from '../dynamic-forms-components/dynamic-form.service';

@Component({
  selector: 'app-review-change-request',
  templateUrl: './review-change-request.component.html',
  styleUrls: ['./review-change-request.component.scss']
})
export class ReviewChangeRequestComponent implements OnInit {

  title: string;
  changeRequest: ChangeManagement;
  incidentForm: UntypedFormGroup;
  fields: FieldConfig[] = formConfigs.fields;
  institutionId: number;
  category: string;
  serviceId: number;

  constructor(
    private store: Store<AppState>,
    private dynamicFormService: DynamicFormService,
    // private dialogRef: MatDialogRef<CreateChangeComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.changeRequest = data;
  }

  ngOnInit(): void {
    //  


    this.title = 'Review Change Request';

    this.fields = this.fields.map(field => {
      if (field.key === 'reviewDecission') {
        field.options = [
          { name: 'Approve Request', value: 'APPROVED' },
          { name: 'RejectRequest', value: 'REJECTED' },
        ];
      }
      if (field.key === 'requestType') {
        field.options = [
          { name: 'Major Change', value: 'MAJOR' },
          { name: 'Minor Change', value: 'MINOR' },
        ];
      }
      return field;
    });
    this.incidentForm = this.dynamicFormService.createControl(this.fields, null);

    if(this.changeRequest){
      this.incidentForm.patchValue({
        requestType: this.changeRequest?.requestType,
        fullName: this.changeRequest?.fullName,
        affectedServices: this.changeRequest?.affectedServices,
        description: this.changeRequest?.description,
        email: this.changeRequest?.email,
        phoneNumber: this.changeRequest?.phoneNumber,
        rollbackPlan: this.changeRequest?.rollbackPlan,
        changeDate: this.changeRequest?.changeDate,
        completionDate: this.changeRequest?.completionDate,
      });
    }
  }

  submitForm(formValue): void {
    formValue.fullName = this.changeRequest?.fullName;
    formValue.affectedServices = this.changeRequest?.affectedServices;
    formValue.description = this.changeRequest?.description;
    formValue.email = this.changeRequest?.email;
    formValue.phoneNumber = this.changeRequest?.phoneNumber;
    formValue.changeDate = this.changeRequest?.changeDate;
    formValue.completionDate = this.changeRequest?.completionDate;
    formValue.rollbackPlan = this.changeRequest?.rollbackPlan;
    let attachment = null;
    let reviewDto = {
      "reviewDecission": formValue?.reviewDecission,
      "comment": formValue?.comment,
    }
    if(formValue.attachments !== null){
      const bs64 = formValue.attachments?.data?.substring(28, formValue.attachments?.data.length-1);
      attachment = {
        "attachments": {
        "filePath": "CHANGE_REQUEST",
        "attachmentTitle": formValue.attachments.filename,
          "data": bs64
        }
      }
      // attachment = {
      //   "attachments": {
      //   "filePath": "change-request",
      //   "attachmentTitle": "change-request",
      //     "data": formValue.attachments
      //   }
      // }
      delete formValue.attachments;
      delete formValue.comment;
      delete formValue.reviewDecission;
    } else{
      formValue.attachments = [];
      delete formValue.comment;
      delete formValue.reviewDecission;
    }
    const data: any = {...formValue, ...attachment};

    if (this.changeRequest?.currentProcess?.toStage === "SUBMITTED") {
      this.store.dispatch(managerReviewChangeRequest({requestId: this.changeRequest?.id, HelpdeskChangeRequestDto: data, reviewDto: reviewDto}));
    } else if (this.changeRequest?.currentStage === "DIRECTOR_ASSIGNED") {
      this.store.dispatch(directorReviewChangeRequest({requestId: this.changeRequest?.id, reviewDto: reviewDto}));
    }

    this.closeThisModal(true);
  }

  closeThisModal(event) {
    // this.dialogRef.close(event);
  }

}
