import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {Apollo} from 'apollo-angular';
import {map, switchMap} from 'rxjs/operators';
import {NotificationService} from 'src/app/services/notification.service';
import {PaginationService} from 'src/app/services/pagination.service';
import {ResponseCodeService} from 'src/app/services/response-code.service';
import {AppState} from '../../reducers';
import * as fromActions from './incident-root-cause.actions';
import * as fromGraphql from './incident-root-cause.graphql';


@Injectable()
export class IncidentRootCauseEffects {

// addIncidentRootCause
    addIncidentRootCause = createEffect(() => this.actions$.pipe(
        ofType(fromActions.addIncidentRootCause),
        switchMap((action) => {

            return this.apollo.mutate({
                mutation: fromGraphql.CREATE_INCIDENT_ROOT_CAUSE,
                variables: {
                    rootCause: action.incidentRootCause
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while adding root cause'),
                map(({data}: any) => {
                    if (data) {
                        let result: any = data?.addUpdateRootCause;
                        if (result?.code === 9000) {
                            this.store.dispatch(fromActions.upsertIncidentRootCause({incidentRootCause: result?.data}));
                            if (action.incidentRootCause?.uuid) {
                                this.notificationService.successMessage('Root cause edited successfullty');
                            } else {
                                this.notificationService.successMessage('Root cause added successfully');
                            }
                        } else {
                            return this.handleError(result);
                        }
                    }
                })
            );
        })
    ), {dispatch: false});

// getIncidentRootCauses
    getIncidentRootCauses$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getIncidentRootCauses),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.GET_ALL_INCIDENT_ROOT_CAUSES,
                fetchPolicy: 'network-only',
                variables: {
                    pageparam: action?.pageparam
                }
            }).pipe(
                this.notificationService.catchError('Problem while fetching Incidents root causes'),
                map(({data}: any) => {
                    if (data) {
                        const result = data?.getAllInstitutionRootCauses;
                        this.paginationService.setPage(result);
                        this.store.dispatch(fromActions.loadIncidentRootCauses({incidentRootCauses: result?.content}));
                    }
                })
            );
        })
    ), {dispatch: false});

// removeIncidentRootCause
    removeIncidentRootCause$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.removeIncidentRootCause),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.REMOVE_ROOT_CAUSE,
                variables: {
                    rootCauseUuid: action.incidentRootCause.uuid
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while deleting incident root cause'),
                map(({data}: any) => {
                    if (data) {
                        let result: any = data?.activateDeactivateRootCause;
                        if (result?.code == 9000) {
                            this.store.dispatch(fromActions.deleteIncidentRootCause({id: action?.incidentRootCause?.id}));
                            this.notificationService.successMessage('Root cause deleted successfully');
                        } else {
                            return this.handleError(result);
                        }
                    }
                })
            );
        })
    ), {dispatch: false});


    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private store: Store<AppState>,
        private notificationService: NotificationService,
        private responseCodesService: ResponseCodeService,
        private router: Router,
        private paginationService: PaginationService,
    ) {
    }

    handleError(data) {
        const responseCode = this.responseCodesService.getCodeDescription(data.code);
        const message = responseCode[0].code + ' : ' + responseCode[0].description;
        return this.notificationService.errorMessage(message);
    }

}
