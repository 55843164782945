import gql from 'graphql-tag';
import {basePageableGraphqlField} from '../../../base.graphql';


export const parrentOrChieldAdministrativeAreafields = `
active
areaCode
areaName
id
parentAreaId
uuid
`;
export const administrativeAreaFields = `
active
administrativeAreas{
  id
  uuid
  areaName
}
areaCode
areaName
id
parentAdministrativeArea{
  id
  uuid
  areaName
}
parentAreaId
uuid
`;

export const GET_ALL_ADMINISTRATIVE_AREA_LIST = gql`
    query administrativeAreaAll($pageableParam:PageableParamInput){
        administrativeAreaAll(pageableParam:$pageableParam){
           ${basePageableGraphqlField}
            content{
                ${administrativeAreaFields}
            }
        }
    }
`;

export const GET_ADMINISTRATIVE_AREAS_BY_TYPE = gql`
    query administrativeAreaByType($type: AdministrativeAreasTypeEnum, $pageableParam:PageableParamInput){
        administrativeAreaByType(type:$type, pageableParam: $pageableParam){
            content {
                id
                uuid
                areaName
            }
            ${basePageableGraphqlField}
        }
    }
`;


export const GET_ADMINISTRATIVE_AREAS_BY_PARRENT = gql`
    query administrativeAreaByParent($type:AdministrativeAreasTypeEnum, $parentId:Long){
        administrativeAreaByParent(type:$type,parentId:$parentId){
            ${administrativeAreaFields}
        }
    }
`;

export const GET_ADMINISTRATIVE_AREAS_BY_UUID = gql`
    query administrativeAreaByUuid($uuid:String){
        administrativeAreaByUuid(uuid:$uuid){
            code
            data{
                ${administrativeAreaFields}
            }
            dataList{
                ${administrativeAreaFields}
            }
        }
    }
`;

export const GET_ADMINISTRATIVE_AREAS_BY_ID = gql`
    query administrativeAreaById($administrativeAreaInput:AdministrativeAreaDtoInput){
        administrativeAreaById(administrativeAreaInput:$administrativeAreaInput){
            code
            data{
                ${administrativeAreaFields}
            }
        }
    }
`;

export const SAVE_AND_UPDATE_ADMINISTRATIVE_AREA = gql`
    mutation createUpdateAdministrativeArea($administrativeAreaInput:AdministrativeAreaDtoInput){
        createUpdateAdministrativeArea(administrativeAreaInput:$administrativeAreaInput){
            code
            data{
                ${administrativeAreaFields}
            }
        }
    }
`;

export const ACTIVATE_DEACTIVATE_ADMINISTRATIVE_AREA = gql`
    mutation activateDeactivateAdministrativeArea($uuid: String){
        activateDeactivateAdministrativeArea(uuid:$uuid){
            code
            data{
                ${administrativeAreaFields}
            }
        }
    }
`;

export const DELETE_ADMINISTRATIVE_AREA = gql`
    mutation deleteAdministrativeArea($uuid: String){
        deleteAdministrativeArea(uuid:$uuid){
            code
            data{
                ${administrativeAreaFields}
            }
        }
    }
`;


export const GET_ALL_ADMINISTRATIVE_AREA_LIST_FEW_FIELDS = gql`
    query administrativeAreaAll($pageableParam:PageableParamInput){
        administrativeAreaAll(pageableParam:$pageableParam){
           ${basePageableGraphqlField}
            content{
                id
                uuid
                areaName               
            }
        }
    }
`;







