import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IncidentReportingType } from 'src/app/interfaces/global.interface';
// import { ProcessingIncidentService } from 'src/app/modules/incidents/reported-incident-pagable/more-incident-detail/processing-incident.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {

  currentYear: any;
  institutionId: number;
  serviceUserType: any;
  incidentReportingType = IncidentReportingType
  inIncidentModule: boolean;
  permissionCheck: any;

  constructor(
    storageService: StorageService,
    private router: Router,
    // private processingIncidentService: ProcessingIncidentService
    ) {
      this.serviceUserType = storageService.getItem('serviceUserType');
   
    }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
    this.inIncidentModule = this.router.url.startsWith('/incident', 0);   
    // this.permissionCheck = this.processingIncidentService.checkLevelOfPermission();
  }

  openIndentForm(incidentReportingType){
    return this.router.navigateByUrl('/incidents/report-incident/'+ incidentReportingType.toLowerCase());
  }

}
