<div class="page-title">

    <div class="title">{{title}}</div>

    <ng-container *ngIf="useBackButton">
        <div class="go-back-button" (click)="goBack()">
            <mat-icon>chevron_left</mat-icon>
            <span style="display: block;">Go Back</span>
        </div>
    </ng-container>

</div>
