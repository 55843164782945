import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Supplier } from './supplier.model';
import { PageableParam } from 'src/app/interfaces/global.interface';

export const loadSuppliers = createAction(
  '[Supplier/API] Load Suppliers',
  props<{ suppliers: Supplier[] }>()
);

export const addSupplier = createAction(
  '[Supplier/API] Add Supplier',
  props<{ supplier: Supplier }>()
);

export const upsertSupplier = createAction(
  '[Supplier/API] Upsert Supplier',
  props<{ supplier: Supplier }>()
);

export const addSuppliers = createAction(
  '[Supplier/API] Add Suppliers',
  props<{ suppliers: Supplier[] }>()
);

export const upsertSuppliers = createAction(
  '[Supplier/API] Upsert Suppliers',
  props<{ suppliers: Supplier[] }>()
);

export const updateSupplier = createAction(
  '[Supplier/API] Update Supplier',
  props<{ supplier: Update<Supplier> }>()
);

export const updateSuppliers = createAction(
  '[Supplier/API] Update Suppliers',
  props<{ suppliers: Update<Supplier>[] }>()
);

export const deleteSupplier = createAction(
  '[Supplier/API] Delete Supplier',
  props<{ id: number }>()
);

export const deleteSuppliers = createAction(
  '[Supplier/API] Delete Suppliers',
  props<{ ids: number[] }>()
);

export const clearSuppliers = createAction(
  '[Supplier/API] Clear Suppliers'
);

// Effect Actions
export const listMyInstitutionAllSuppliers = createAction(
  '[Supplier/API] List My Institution All Suppliers',
  props<{ pageableParam?: PageableParam }>()
);
export const listMyInstitutionAllSuppliersForList = createAction(
  '[Supplier/API] List My Institution All Suppliers For List',
  props<{ pageableParam?: PageableParam }>()
);

export const activateSupplier = createAction(
  '[Supplier/API] Activate Supplier',
  props<{ id: number}>()
);


export const createSupplier = createAction(
  '[Supplier/API] Create Supplier',
  props<{ supplier: Supplier }>()
);

export const removeSupplier = createAction(
  '[Supplier/API] Remove Supplier By Id',
  props<{ id: number }>()
);


// export const getDefaultSupplier = createAction(
//   '[User/API] Get Default Supplier'
// );
