import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { RequestPriority } from './request-priority.model';
import * as RequestPriorityActions from './request-priority.actions';

export const RequestPrioritysFeatureKey = 'RequestPrioritys';

export interface State extends EntityState<RequestPriority> {
  // additional entities state properties
}

export const adapter: EntityAdapter<RequestPriority> = createEntityAdapter<RequestPriority>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(RequestPriorityActions.addRequestPriority,
    (state, action) => adapter.addOne(action.requestPriority, state)
  ),
  on(RequestPriorityActions.upsertRequestPriority,
    (state, action) => adapter.upsertOne(action.requestPriority, state)
  ),
  on(RequestPriorityActions.addRequestPrioritys,
    (state, action) => adapter.addMany(action.requestPrioritys, state)
  ),
  on(RequestPriorityActions.upsertRequestPrioritys,
    (state, action) => adapter.upsertMany(action.requestPrioritys, state)
  ),
  on(RequestPriorityActions.updateRequestPriority,
    (state, action) => adapter.updateOne(action.requestPriority, state)
  ),
  on(RequestPriorityActions.updateRequestPrioritys,
    (state, action) => adapter.updateMany(action.requestPrioritys, state)
  ),
  on(RequestPriorityActions.deleteRequestPriority,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(RequestPriorityActions.deleteRequestPrioritys,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(RequestPriorityActions.loadRequestPrioritys,
    (state, action) => adapter.setAll(action.requestPrioritys, state)
  ),
  on(RequestPriorityActions.clearRequestPrioritys,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
