import {ResponseCodeService} from './../../../../services/response-code.service';
import {NotificationService} from './../../../../services/notification.service';
import {AppState} from 'src/app/store/reducers';
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {Apollo} from 'apollo-angular';
import * as fromActions from './service.actions';
import * as fromGraphql from './service.graphql';
import {map, switchMap} from 'rxjs/operators';
import {PaginationService} from 'src/app/services/pagination.service';
import {initializedPageableParameter} from 'src/app/interfaces/global.interface';
import {Router} from '@angular/router';


@Injectable()
export class ServiceEffects {

    // List All Services
    listAllServices$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.listAllServices),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.LIST_ALL_SERVICES,
                variables: {
                    pageParam: action?.pageableParam ? action.pageableParam : initializedPageableParameter,
                },
                fetchPolicy: 'network-only',
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching Services'),
                map(({data}: any) => {
                    if (data) {
                        const result = data?.getAllInstitutionServices;
                        this.paginationService.setPage(result);
                        this.store.dispatch(fromActions.loadServices({services: result?.content}));
                    }
                })
            );
        })
    ), {dispatch: false});

    // List All Services
    listAllServicesForOption$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.listAllServicesForOption),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.LIST_ALL_SERVICES_FOR_LIST,
                variables: {
                    pageParam: action?.pageableParam,
                },
                fetchPolicy: 'network-only',
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching Services'),
                map(({data}: any) => {
                    if (data) {
                        const result = data?.getAllInstitutionServices;
                        this.paginationService.setPage(result);
                        this.store.dispatch(fromActions.loadServices({services: result?.content}));
                    }
                })
            );
        })
    ), {dispatch: false});


    // List Institution Services
    getServiceByUuid$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getServiceByUuid),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.FIND_INSTITUTION_SERVICE_BY_UUID,
                fetchPolicy: 'network-only',
                variables: {
                    uuid: action.uuid
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching Service'),
                map(({data}: any) => {
                    if (data) {
                        const result = data.findInstitutionServiceByUuid;
                        if (result) {
                            this.store.dispatch(fromActions.upsertService({service: result?.data}));
                        } else {
                            this.notificationService.handleErrorMessage(result);
                        }
                    }
                })
            );
        })
    ), {dispatch: false});


    //  getAllInstitutionResourcesByService

    getAllInstitutionResourcesByService$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getAllInstitutionResourcesByService),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.GET_ALL_INSTITUTION_RESOURCES_BY_SERVICE,
                fetchPolicy: 'network-only',
                variables: {
                    institutionServiceUuid: action.institutionServiceUuid
                }
            }).pipe(
                map(({data}: any) => {
                    if (data) {

                        // const response = this.responseCodesService?.getCodeDescription(data.getAllInstitutionResourcesByService.code);

                        if (data.getAllInstitutionResourcesByService.code === 9000) {
                            this.store.dispatch(fromActions.upsertService({service: data.getAllInstitutionResourcesByService}));
                            // this.notificationService.successMessage('Operation successful: '+ response[0].code);
                        } else {
                            // this.notificationService.determineSnackbar(response[0]);
                        }

                    }
                })
            );
        })
    ), {dispatch: false});


    // Get Service tu Subscribe by Institution UUID
    getServiceToSubscribeByinstitutionUuid$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getServiceToSubscribeByinstitutionUuid),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.GET_SERVICES_TO_SUBSCRIBED_BY_INSTITUTION_UUID,
                fetchPolicy: 'network-only',
                variables: {
                    institutionUuid: action.institutionUuid
                }
            }).pipe(
                map(({data}: any) => {


                    const result = data?.getServicesToSubscribeByInstitutionUuid;
                    if (result?.content?.length > 0) {
                        this.paginationService.setPage(result);
                        this.store.dispatch(fromActions.loadServices({services: result?.content}));

                    } else {
                        // const error = this.responseCodesService.getCodeDescription(data.getServiceById);
                        this.notificationService.errorMessage('Failed to load service');
                    }


                })
            );
        })
    ), {dispatch: false});


    // subscribeToService
    subscribeToService$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.subscribeToService),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.SUBSCRIBE_TO_SERVICES,
                variables: {
                    clientDetails: action.ClientDtoInput
                }
            }).pipe(
                map(({data}: any) => {
                    if (data) {
                        let result: any = data.subscribeToService;
                        if (result?.code === 9000) {
                            this.store.dispatch(fromActions.upsertService({service: result?.data.service}));
                            this.notificationService.successMessage('Subscribed successful');
                        } else {
                            const response = this.responseCodesService?.getCodeDescription(result?.code);
                            this.notificationService.errorMessage(result?.messsage ? result?.messsage : response[0] + result?.code);
                        }
                    }
                })
            );
        })
    ), {dispatch: false});


    // Add New Service
    addNewService$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.addNewService),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.CREATE_AND_UPDATE_INSTITUTION_SERVICE,
                variables: {
                    institutionService: action.institutionService
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while saving service'),
                map(({data}: any) => {
                    if (data) {
                        let result: any = data?.createUpdateInstitutionService;
                        if (result?.code === 9000) {
                            this.store.dispatch(fromActions.upsertService({service: result?.data}));
                            if (action.institutionService.uuid) {
                                this.notificationService.successMessage('Edited successful');
                            } else {
                                this.notificationService.successMessage('Saved successful');
                            }
                        } else {
                            const response = this.responseCodesService?.getCodeDescription(result?.code);
                            this.notificationService.errorMessage(result?.messsage ? result?.messsage : response[0] + result?.code);
                        }
                    }
                })
            );
        })
    ), {dispatch: false});


    subscibeClientOnBehalf$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.subscibeClientOnBehalf),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.SUBSCRIBE_CLIENT_TO_SERVICES_ON_BEHALF,
                variables: {
                    clientDetails: action.clientDetails
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while subscribing service'),
                map(({data}: any) => {
                    if (data) {
                        let result: any = data?.subscribeToServiceOnBehafMany;
                        if (result?.code === 9000) {
                            this.store.dispatch(fromActions.upsertService({service: result?.data}));
                            if(action.viewingType=='institution'){
                                return this.notificationService.successMessageWithRedirect('Subscribed successfully', '/institution-user/institutionSubscription/' + action.clientDetails.institutionUuid);
                            } else {
                                return this.notificationService.successMessageWithRedirect('Subscribed successfully', '/services/individual-subscribed-service/' + action.clientDetails.userUuid);
                            }
                        } else {
                            const response = this.responseCodesService?.getCodeDescription(result?.code);
                            this.notificationService.errorMessage(result?.messsage ? result?.messsage : response[0] + result?.code);
                        }
                    }
                })
            );
        })
    ), {dispatch: false});


    // deactivateInstitutionService
    deactivateInstitutionService$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.deactivateInstitutionService),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.DEACTIVATE_INSTITUTION_SERVICE,
                variables: {
                    uuid: action.uuid
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while saving service'),
                map(({data}: any) => {
                    if (data) {
                        const response = this.responseCodesService?.getCodeDescription(data.deactivateInstitutionService.code);
                        if (data.deactivateInstitutionService.code === 9000) {
                            this.store.dispatch(fromActions.upsertService({service: data.deactivateInstitutionService.data}));
                            this.notificationService.successMessage('Service deactivated successful');
                        } else {
                            this.notificationService.determineSnackbar(response[0]);
                        }
                    }
                })
            );
        })
    ), {dispatch: false});

    // deleteInstitutionService
    deleteInstitutionService$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.deleteInstitutionService),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.DELETE_INSTITUTION_SERVICE,
                variables: {
                    institutionServiceId: action.institutionServiceId
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while saving service'),
                map(({data}: any) => {
                    if (data) {
                        const response = this.responseCodesService?.getCodeDescription(data.deleteInstitutionService.code);
                        if (data.deleteInstitutionService.code === 9000) {
                            this.store.dispatch(fromActions.deleteService({id: action.institutionServiceId}));
                            this.notificationService.successMessage('Operation successful');
                        } else {
                            this.notificationService.determineSnackbar(response[0]);
                        }
                    }
                })
            );
        })
    ), {dispatch: false});


    loadSubScribedservices$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.loadSubScribedservices),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.SUBSCRIBED_SERVICES,
                variables: {
                    providerInstitutionUuid: action.instutionUuid
                },
                fetchPolicy: 'network-only'
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching services'),
                map(({data}: any) => {

                    if (data) {

                        this.store.dispatch(fromActions.loadServices({services: data?.servicesMyInstSubscribedByProviderInstitution}));

                    }
                })
            );
        })
    ), {dispatch: false});


    servicesSubscribedInMyInstByClient$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.servicesSubscribedInMyInstByClient),
        switchMap((action) => {
            return this.apollo.query({
                query: action?.option == 'MANY' ? fromGraphql.CLIENTS_SUBSCRIBED_SERVICES : fromGraphql.CLIENTS_SUBSCRIBED_SERVICES_FEW_FIELDS,
                variables: {
                    clientInstitutionUuid: action.instutionUuid
                },
                fetchPolicy: 'network-only'
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching subscribed services'),
                map(({data}: any) => {
                    if (data) {

                        this.store.dispatch(fromActions.loadServices({services: data.servicesSubscribedInMyInstByClient}));

                    }
                })
            );
        })
    ), {dispatch: false});

    copyAssignedUsersToAnotherService$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.copyAssignedUsersToAnotherService),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.COPY_ASSIGNED_USERS_TO_ANOTHER_SERVICES,
                variables: {
                    serviceToUuidList: action.serviceToUuidList,
                    serviceFromUuid: action.serviceFromUuid
                },
            }).pipe(
                map(({data}: any) => {

                    if (data) {
                        let result = data.copyAssignedUsersToAnotherService;
                        if (result?.code === 9000) {
                            this.notificationService.successMessage('Users Copied Successfully');
                        } else {
                            const error = this.responseCodesService.getCodeDescription(result?.code);
                            this.notificationService.errorMessage(error[0]?.description);
                        }
                    }

                })
            );
        })
    ), {dispatch: false});


    deleteMyServiceSubscriptionFromMyClientServiceSUbscription$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.deleteMyServiceSubscriptionFromMyClientServiceSUbscription),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.DELETE_SERVICE_SUBSCRIBED_TO_INSTITUTION_WITH_INSTITUTION_AND_SERVICE_UUID,
                variables: {
                    institutionUuid: action.institutionUuid,
                    serviceUuid: action.serviceUuid,
                    userUuid: action.userUuid,
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while updating Subscription Details details'),
                map(({data}: any) => {
                    if (data) {
                        if (data.deleteClientFromMyInstitutionByServiceAndInst.code === 9000) {
                            this.store.dispatch(fromActions.deleteService({id: action.id}));
                            this.notificationService.successSnackbar('Client unsubscribed Successfully');
                        } else {
                            this.notificationService.errorSnackbar('Failed to unsubscribed Client details');
                        }
                    }
                })
            );
        })
    ), {dispatch: false});

    // List All Services
    listAllServicesFewFields$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.listAllServicesFewFields),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.LIST_ALL_SERVICES_FEW_FIELDS,
                variables: {
                    pageParam: action?.pageableParam ? action.pageableParam : initializedPageableParameter,
                },
                fetchPolicy: 'network-only',
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching Services'),
                map(({data}: any) => {
                    if (data) {
                        const result = data?.getAllInstitutionServices;
                        this.paginationService.setPage(result);
                        this.store.dispatch(fromActions.loadServices({services: result?.content}));
                    }
                })
            );
        })
    ), {dispatch: false});


    loadSubScribedservicesFewFields$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.loadSubScribedservicesFewFields),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.SUBSCRIBED_SERVICES_FEW_FIELDS,
                variables: {
                    providerInstitutionUuid: action.instutionUuid
                },
                fetchPolicy: 'network-only'
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching services'),
                map(({data}: any) => {

                    if (data) {

                        this.store.dispatch(fromActions.loadServices({services: data?.servicesMyInstSubscribedByProviderInstitution}));

                    }
                })
            );
        })
    ), {dispatch: false});


    nonEmployeeSubscribedServicesByProviderInstitution$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.nonEmployeeSubscribedServicesByProviderInstitution),
        switchMap((action) => {
            return this.apollo.query({
                query: action?.option === 'FEW' ? fromGraphql.GET_NOT_EMPLOYEE_SUBSCRIBED_SERVICES_FEW
                    : fromGraphql.GET_NOT_EMPLOYEE_SUBSCRIBED_SERVICES,
                variables: {
                    providerInstitutionUuid: action.instutionUuid,
                    userUuid: action?.userUuid ? action?.userUuid : null
                },
                fetchPolicy: 'network-only'
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching subsribed services'),
                map(({data}: any) => {
                    if (data) {

                        this.store.dispatch(fromActions.loadServices({services: data?.mySubscribedServicesByProviderInstitution}));

                    }
                })
            );
        })
    ), {dispatch: false});

    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private store: Store<AppState>,
        private notificationService: NotificationService,
        private paginationService: PaginationService,
        private route: Router,
        private responseCodesService: ResponseCodeService,
    ) {
    }

}
