import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import { map, switchMap } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification.service';
import { AppState } from '../../reducers';
import * as fromActions from './calltracker-support.actions';
import * as fromGraphql from './calltracker-support.graphql';



@Injectable()
export class CalltrackerSupportEffects {

  // saveCallTrackerSupport
  saveCallTrackerSupport$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.saveCallTrackerSupport),
    switchMap((action) => {
       
      return this.apollo.mutate({
        mutation: fromGraphql.SAVE_CALL_TRACKER_SUPPORT,
        variables: {
          dto: action.dto,
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while saving record'),
        map(({ data }: any) => {
          if (data) {
            let result = data.createUpdateCallSupportProvided;
            if (result?.code === 9000) {
              this.store.dispatch(fromActions.upsertCalltrackerSupport({ calltrackerSupport: result?.data }));
             if(action.dto.uuid) {
               this.notificationService.successMessage('Edited Successfully')
             } else {
               this.notificationService.successMessage('Created Successfully');
             }
            } else {
              this.notificationService.errorMessage(result?.message + result?.code);
            }
          }
        })
      );
    })
  ), {dispatch: false});

  getCallTrackerSupportList$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.getCallTrackerSupportList),
    switchMap(() => {
      return this.apollo.query({
        query: fromGraphql.GET_CALL_TRACKER_SUPPORT_LIST,
        fetchPolicy: 'no-cache',
      }).pipe(
        this.notificationService.catchError('Problem occurred'),
        map(({ data }: any) => {
          if (data) {
            let result = data.getAllCallSupportedReported;            
            
            if(result?.code === 9000){
              this.store.dispatch(fromActions.loadCalltrackerSupports({ calltrackerSupports: result?.dataList }));
            } else {
              this.notificationService.errorMessage(result?.message + result?.code)
            }
          } else {
            this.notificationService.errorMessage('Failed to fetch records');
          }
        })
      );
    })
  ), {dispatch: false});

  removeCallSupport$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.removeCallSupport),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: fromGraphql.DELETE_CALL_TRACKER_SUPPORT,
        variables: {
          trackUuid: action.trackUud
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while deleting '),
        map(({ data }: any) => {
          if (data) {
            let result = data.deleteCallSupportProvided
            if(result?.code === 9000){
              this.notificationService.successMessage('Deleted Successfully');
              this.store.dispatch(fromActions.deleteCalltrackerSupport({ id: action.id }));
            } else {
              this.notificationService.errorMessage(result?.message + result?.code)
            }
          } else {
            this.notificationService.errorMessage('Failed to delete records');
          }
        })
      );
    })
  ), {dispatch: false});



  constructor(
    private actions$: Actions,
    private apollo: Apollo,
    private store: Store<AppState>,
    private notificationService: NotificationService  ) {
  }

}
