import { Validators } from '@angular/forms';
// import {
//   slideInRightOnEnterAnimation,
//   slideOutLeftOnLeaveAnimation,
// } from 'angular-animations';
import { phoneNumbervalidator } from '../validators/input-validators';

export const PAGE_ANIMATIONS = [
  // slideOutLeftOnLeaveAnimation({
  //   duration: 400,
  //   animateChildren: 'after',
  //   translate: '100px',
  // }),
  // slideInRightOnEnterAnimation({
  //   duration: 400,
  //   animateChildren: 'after',
  //   translate: '100px',
  // }),
];

export const commonValidations = [
  {
    message: 'This field is required',
    validator: Validators.required,
    name: 'required',
  },
];

export const localPhoneValidations = [
  {
    message:"Invalid Local Phone number",
    name:"phone",
    validator:Validators.pattern(phoneNumbervalidator)
  }
]

// FOR CROSS BOARDER STAGE, FOR ATTACHMENT WITHIN APPROVAL STAGE
export const relatedStage = [
  { application: 'APPLICATION' },
  { preview: 'POST-PREVIEW' },
  { approve: 'POST-APPROVE' },
];

// for hidden features click activation
export const SECRECT_CLICK_THRESHOLD = 2;
export const CLICK_RESET_TIME = 1500;


/// rando texts
export const SYSTEM_NAME = "KAZI GATEWAY";

export const loremIpsum = `orem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,  molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum
orem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,  molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum
orem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,  molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum
orem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,  molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum
orem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,  molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum
orem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,  molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum ...`


export const JOBSEEKER = `
An individual with qualifications or expertise who is searching for an 
employment/internship opportunity, it includes those employed who want 
to change employment.

TaESA jobs portal allows jobseeker to register and apply for jobs and
internship opportunities, access to employment counselling and 
vocational guidance services, job search and employability trainings, 
undertake aptitude test and job and internship placements.
`

export const ARTIST = `
Individuals engaged in activities related to creating, participating, 
or demonstrating an art. In TaESA jobs portal, these individuals 
register and apply for permits to work abroad.
`
  
export const EMPLOYER = `
Any natural or legal entity that employs people. TaESA jobs portal 
allows employers to register jobs and internship opportunities to be 
accessed by potential applicants.
`

export const PESA = `
A natural or legal person, company, institution or any other entity whose 
objective is to provide employment services both local and cross border. 
Portal allows Private Employment Services Agencies (PESA) to register 
employment opportunities and apply for permits to connect Tanzanians 
with job opportunities abroad.
`