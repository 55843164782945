import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { ResourceCriticality } from './resource-criticality.model';
import { PageableParam } from 'src/app/interfaces/global.interface';

export const loadResourceCriticalitys = createAction(
  '[ResourceCriticality/API] Load ResourceCriticalitys',
  props<{ resourceCriticalitys: ResourceCriticality[] }>()
);

export const addResourceCriticality = createAction(
  '[ResourceCriticality/API] Add ResourceCriticality',
  props<{ resourceCriticality: ResourceCriticality }>()
);

export const upsertResourceCriticality = createAction(
  '[ResourceCriticality/API] Upsert ResourceCriticality',
  props<{ resourceCriticality: ResourceCriticality }>()
);

export const addResourceCriticalitys = createAction(
  '[ResourceCriticality/API] Add ResourceCriticalitys',
  props<{ resourceCriticalitys: ResourceCriticality[] }>()
);

export const upsertResourceCriticalitys = createAction(
  '[ResourceCriticality/API] Upsert ResourceCriticalitys',
  props<{ resourceCriticalitys: ResourceCriticality[] }>()
);

export const updateResourceCriticality = createAction(
  '[ResourceCriticality/API] Update ResourceCriticality',
  props<{ resourceCriticality: Update<ResourceCriticality> }>()
);

export const updateResourceCriticalitys = createAction(
  '[ResourceCriticality/API] Update ResourceCriticalitys',
  props<{ resourceCriticalitys: Update<ResourceCriticality>[] }>()
);

export const deleteResourceCriticality = createAction(
  '[ResourceCriticality/API] Delete ResourceCriticality',
  props<{ id: number }>()
);

export const deleteResourceCriticalitys = createAction(
  '[ResourceCriticality/API] Delete ResourceCriticalitys',
  props<{ ids: number[] }>()
);

export const clearResourceCriticalitys = createAction(
  '[ResourceCriticality/API] Clear ResourceCriticalitys'
);

// Effect Actions
export const listMyInstitutionAllResourceCriticalitys = createAction(
  '[ResourceCriticality/API] List My Institution All ResourceCriticalitys',
  props<{ pageableParam?: PageableParam }>()
);
export const listMyInstitutionAllResourceCriticalitysForOptions = createAction(
  '[ResourceCriticality/API] List My Institution All ResourceCriticalitys For Options',
  props<{ pageableParam?: PageableParam }>()
);

export const activateResourceCriticality = createAction(
  '[ResourceCriticality/API] Activate ResourceCriticality',
  props<{ id: string}>()
);

export const modifyResourceCriticality = createAction(
  '[ResourceCriticality/API] Modify ResourceCriticality Details',
  props<{ resourceCriticality: ResourceCriticality }>()
);


export const createResourceCriticality = createAction(
  '[ResourceCriticality/API] Create ResourceCriticality',
  props<{ resourceCriticality: ResourceCriticality }>()
);

export const removeResourceCriticality = createAction(
  '[ResourceCriticality/API] Remove ResourceCriticality By Id',
  props<{ id:number, uuid: string }>()
);


// export function listMyInstitutionAllResourceTypes(listMyInstitutionAllResourceTypes: any): import("rxjs").OperatorFunction<import("@ngrx/store").Action, any> {
//   throw new Error('Function not implemented.');
// }
// export const getDefaultResourceCriticality = createAction(
//   '[User/API] Get Default ResourceCriticality'
// );
