import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';

import {Pageable} from './pageable.model';

export const loadPageables = createAction(
  '[Pageable/API] Load Pageables',
  props<{ pageables: Pageable[] }>()
);

export const addPageable = createAction(
  '[Pageable/API] Add Pageable',
  props<{ pageable: Pageable }>()
);

export const upsertPageable = createAction(
  '[Pageable/API] Upsert Pageable',
  props<{ pageable: Pageable }>()
);

export const addPageables = createAction(
  '[Pageable/API] Add Pageables',
  props<{ pageables: Pageable[] }>()
);

export const upsertPageables = createAction(
  '[Pageable/API] Upsert Pageables',
  props<{ pageables: Pageable[] }>()
);

export const updatePageable = createAction(
  '[Pageable/API] Update Pageable',
  props<{ pageable: Update<Pageable> }>()
);

export const updatePageables = createAction(
  '[Pageable/API] Update Pageables',
  props<{ pageables: Update<Pageable>[] }>()
);

export const deletePageable = createAction(
  '[Pageable/API] Delete Pageable',
  props<{ id: string }>()
);

export const deletePageables = createAction(
  '[Pageable/API] Delete Pageables',
  props<{ ids: string[] }>()
);

export const clearPageables = createAction(
  '[Pageable/API] Clear Pageables'
);

export const insertPageTotalElement = createAction(
  '[Pageable/API] insert Page total element',
  props<{ totalElement: number }>()
);

export const insertPage = createAction(
  '[Pageable/API] insert Pageable',
  props<{ page: Pageable }>()
);
