import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Pageable } from './pageable.model';
import * as PageableActions from './pageable.actions';

export const pageablesFeatureKey = 'pageables';

export interface State extends EntityState<Pageable> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Pageable> = createEntityAdapter<Pageable>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(PageableActions.addPageable,
    (state, action) => adapter.addOne(action.pageable, state)
  ),
  on(PageableActions.upsertPageable,
    (state, action) => adapter.upsertOne(action.pageable, state)
  ),
  on(PageableActions.addPageables,
    (state, action) => adapter.addMany(action.pageables, state)
  ),
  on(PageableActions.upsertPageables,
    (state, action) => adapter.upsertMany(action.pageables, state)
  ),
  on(PageableActions.updatePageable,
    (state, action) => adapter.updateOne(action.pageable, state)
  ),
  on(PageableActions.updatePageables,
    (state, action) => adapter.updateMany(action.pageables, state)
  ),
  on(PageableActions.deletePageable,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(PageableActions.deletePageables,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(PageableActions.loadPageables,
    (state, action) => adapter.setAll(action.pageables, state)
  ),
  on(PageableActions.clearPageables,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
