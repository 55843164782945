import {Injectable} from '@angular/core';
import {ResponseCodeService} from '../../../../services/response-code.service';
import {AppState} from '../../../reducers/index';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {Apollo} from 'apollo-angular';
import {NotificationService} from 'src/app/services/notification.service';
import * as fromActions from './resource-type.actions';
import * as fromGraphql from './resource-type.graphql';
import {map, switchMap} from 'rxjs/operators';
import {listMyInstitutionAllResourceTypesForOption} from './resource-type.actions';
import {GET_MY_INSTITUTION_ALL_RESOURCE_TYPES_FOR_LIST} from './resource-type.graphql';
import {PaginationService} from '../../../../services/pagination.service';


@Injectable()
export class ResourceTypeEffects {
    // List All Branchs
    listMyInstitutionAllResourceTypes$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.listMyInstitutionAllResourceTypes),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.GET_MY_INSTITUTION_ALL_RESOURCE_TYPES,
                variables: {
                    pageparam: action.pageableParam
                },
                fetchPolicy: 'network-only',
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching Branches'),
                map(({data}: any) => {
                    if (data) {
                        const res = data?.getAllResourceType;
                        this.paginationService.setPage(res);
                        this.store.dispatch(fromActions.loadResourceTypes({resourceTypes: res.content}));
                    }
                })
            );
        })
    ), {dispatch: false});
    listMyInstitutionAllResourceTypesForOption$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.listMyInstitutionAllResourceTypesForOption),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.GET_MY_INSTITUTION_ALL_RESOURCE_TYPES_FOR_LIST,
                variables: {
                    pageparam: action.pageableParam
                },
                fetchPolicy: 'network-only',
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching Branches'),
                map(({data}: any) => {
                    if (data) {
                        const res = data?.getAllResourceType;
                        this.paginationService.setPage(res);
                        this.store.dispatch(fromActions.loadResourceTypes({resourceTypes: res?.content}));
                    }
                })
            );
        })
    ), {dispatch: false});


    activateResourceType$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.activateResourceType),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.ACTIVATE_AND_DEACTIVATE_RESOURCE_TYPE,
                variables: {
                    resourceTypeIdUuid: action.id,

                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while updating Resource type details'),
                map(({data}: any) => {

                    if (data) {

                        this.notificationService.successMessage('Resource type details Activated Successfully');
                        if (data.activateDeactivateResourceType.code === 9000) {
                            this.store.dispatch(fromActions.upsertResourceType({resourceType: data.activateDeactivateResourceType.data}));
                            this.notificationService.successMessage('Resource type details Activated Successfully');
                        } else {
                            this.notificationService.errorMessage('Failed to Activate Resource type detals');
                        }
                    }
                })
            );
        })
    ), {dispatch: false});


    // Create/Save Branch
    createResourceType$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.createResourceType),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.SAVE_UPDATE_RESOURCE_TYPE,
                variables: {
                    resourceType: action.resourceType
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while saving Resource Category'),
                map(({data}: any) => {
                    if (data) {
                        if (data.addUpdateResourceType.code === 9000) {
                            this.store.dispatch(fromActions.upsertResourceType({resourceType: data.addUpdateResourceType.data}));
                            if (action.resourceType?.id) {
                                this.notificationService.successMessage('Edited Successfully');
                            } else {
                                this.notificationService.successMessage('Created Successfully');
                            }
                        } else {
                            const error = this.responseCodesService.getCodeDescription(data.addUpdateResourceCategory.code);
                            this.notificationService.errorMessage(error[0]?.description);
                        }
                    }
                })
            );
        })
    ), {dispatch: false});


    // Detele Branch
    removeResourceType$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.removeResourceType),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.DELETE_RESOURCE_TYPE,
                variables: {
                    resourceTypeIdUuid: action.uuid,
                    id: action.id
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while deleting Resource Type'),
                map(({data}: any) => {
                    if (data) {
                        if (data.deleteResourceType.code === 9000) {
                            // this.store.dispatch(fromActions.deleteBranch({ id: action.id }));
                            this.store.dispatch(
                                fromActions.deleteResourceType({id: action.id})
                            );
                            // this.store.dispatch(fromActions.upsertResourceType({ resourceType: data.deleteResourceType.data }));

                            this.notificationService.successMessage('Deleted Successfully');
                        } else {
                            this.notificationService.errorMessage(data.deleteResourceType);
                        }
                    }
                })
            );
        })
    ), {dispatch: false});


    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private store: Store<AppState>,
        private notificationService: NotificationService,

        private paginationService: PaginationService,
        private responseCodesService: ResponseCodeService
    ) {
    }


}
