import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { RequestSeverity } from './request-severity.model';
import { PageableParam } from 'src/app/interfaces/global.interface';

export const loadRequestSeveritys = createAction(
  '[RequestSeverity/API] Load RequestSeveritys',
  props<{ requestSeveritys: RequestSeverity[] }>()
);

export const addRequestSeverity = createAction(
  '[RequestSeverity/API] Add RequestSeverity',
  props<{ requestSeverity: RequestSeverity }>()
);

export const upsertRequestSeverity = createAction(
  '[RequestSeverity/API] Upsert RequestSeverity',
  props<{ requestSeverity: RequestSeverity }>()
);

export const addRequestSeveritys = createAction(
  '[RequestSeverity/API] Add RequestSeveritys',
  props<{ requestSeveritys: RequestSeverity[] }>()
);

export const upsertRequestSeveritys = createAction(
  '[RequestSeverity/API] Upsert RequestSeveritys',
  props<{ requestSeveritys: RequestSeverity[] }>()
);

export const updateRequestSeverity = createAction(
  '[RequestSeverity/API] Update RequestSeverity',
  props<{ requestSeverity: Update<RequestSeverity> }>()
);

export const updateRequestSeveritys = createAction(
  '[RequestSeverity/API] Update RequestSeveritys',
  props<{ requestSeveritys: Update<RequestSeverity>[] }>()
);

export const deleteRequestSeverity = createAction(
  '[RequestSeverity/API] Delete RequestSeverity',
  props<{ id: number }>()
);

export const deleteRequestSeveritys = createAction(
  '[RequestSeverity/API] Delete RequestSeveritys',
  props<{ ids: number[] }>()
);

export const clearRequestSeveritys = createAction(
  '[RequestSeverity/API] Clear RequestSeveritys'
);

// Effect Actions
export const listMyInstitutionAllRequestSeveritys = createAction(
  '[RequestSeverity/API] List My Institution All RequestSeveritys',
  props<{ pageableParam?: PageableParam }>()
);

export const activateRequestSeverity = createAction(
  '[RequestSeverity/API] Activate RequestSeverity',
  props<{ id: string}>()
);

export const modifyRequestSeverity = createAction(
  '[RequestSeverity/API] Modify RequestSeverity Details',
  props<{ requestSeverity: RequestSeverity }>()
);


export const createRequestSeverity = createAction(
  '[RequestSeverity/API] Create RequestSeverity',
  props<{ requestSeverity: RequestSeverity }>()
);

export const removeRequestSeverity = createAction(
  '[RequestSeverity/API] Remove RequestSeverity By Id',
  props<{ id: number, uuid:string }>()
);

// export const getDefaultRequestSeverity = createAction(
//   '[User/API] Get Default RequestSeverity'
// );
