import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ResponseCodeService } from '../../../services/response-code.service';
import { AppState } from '../../reducers/index';
import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import { NotificationService } from 'src/app/services/notification.service';
import * as fromActions from './mails.actions';
import * as fromGraphql from './mails.graphql';
import { map, switchMap } from 'rxjs/operators';
import { StorageService } from 'src/app/services/storage.service';
import { Mail } from './mails.model';



@Injectable()
export class MailsEffects {



  //  syncMails
  syncMails$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.syncMails),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: fromGraphql.SYNC_MAILS,
        variables: {
          password: action.password,
          mailFolder: action.mailFolder,
          token: action.token,
          mailUsernameUuid:action.mailUsernameUuid

        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while fetching Institution Mails'),
        map(({ data }: any) => {
          if (data) {
            localStorage.removeItem('mail_token')
            if (data.syncMails.code === 9000) {
              this.storageService.setItem('mail_token', data.syncMails.token)

               

              //Kama hautaki kufanya kwenye reduzer kwa payload inayokuja bila id
              // let mails: Mail[] = [];
              // mails =  data.syncMails.dataList.map(d => {
              //   return {
              //     ...d,
              //     id: d.messageId
              //   }
              // })

              this.store.dispatch(fromActions.loadMails({ mails: data.syncMails.dataList }));

            } else {
              this.notificationService.errorMessage('Failed to  Sync Mail');
            }
          }
        })
      );
    })
  ), { dispatch: false });



    //  sendIncidentMail
    sendIncidentMail$ = createEffect(() => this.actions$.pipe(
      ofType(fromActions.sendIncidentMail),
      switchMap((action) => {
        return this.apollo.mutate({
          mutation: fromGraphql.SEND_INCIDENT_MAIL,
          variables: {
            emailParams:action.emailParams
          }
        }).pipe(
          this.notificationService.catchError('Problem occurred while Sending Mails'),
          map(({ data }: any) => {
             
           
            if (data) {
              if (data.sendIncidentMail.code === 9000) {   
                 //  this.store.dispatch(fromActions.clearIncidents());
                 this.notificationService.successMessage('Forwaded successfully ');      
                 
              } else {
               this.notificationService.determineSnackbar(data);  
              }
            }

          })
        );
      })
    ), { dispatch: false });

  //  readMail
  readMail$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.readMail),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: fromGraphql.READ_MAIL,
        variables: {
          mailFolder: action.mailFolder,
          messageId: action.messageId,
          token: action.token,
          mailUsernameUuid:action.mailUsernameUuid
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while Reading Mails'),
        map(({ data }: any) => {
           

          if (data) {
            if (data.readMail.code === 9000) {
              this.store.dispatch(fromActions.loadMails({ mails: data.readMail.data }));

              this.notificationService.successMessage('Succefuly');
            } else {
              this.notificationService.errorMessage('Failed to  Ready Mail');
            }
          }
        })
      );
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private apollo: Apollo,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private responseCodesService: ResponseCodeService,
    private storageService: StorageService
  ) {
  }


}


