import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {IncidentNotification} from '../../../store/entities/notification/notification.model';
import {ChartComponent} from 'ng-apexcharts';
import {ChartOptionsMod, IncidentNotificationType} from '../../../interfaces/global.interface';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../store/reducers';
import {
    selectAllIncidentNotifications,
    selectNotificationByType
} from '../../../store/entities/notification/notification.selectors';

@Component({
    selector: 'app-incident-notification-dashboard',
    templateUrl: './incident-notification-dashboard.component.html',
    styleUrls: ['./incident-notification-dashboard.component.scss']
})
export class IncidentNotificationDashboardComponent implements OnInit {

    notifications$: Observable<IncidentNotification[]>;
    @Input() viewedFrom: string;
    sectionTickets$: Observable<IncidentNotification[]>;
    internalTickets$: Observable<IncidentNotification[]>;
    incommingTickets$: Observable<IncidentNotification[]>;
    outgoingTickets$: Observable<IncidentNotification[]>;
    myIndividualTickets$: Observable<IncidentNotification[]>;
    departmentTickects$: Observable<IncidentNotification[]>;

    @ViewChild('chart') chart: ChartComponent;
    public chartOptions: Partial<ChartOptionsMod>;
    subscription = new Subscription();

    totalOpen = 0;
    totalResolved = 0;
    totalClosed = 0;
    grandTotal = 0;

    constructor(
        private store: Store<AppState>) {
    }

    ngOnInit(): void {

        this.notifications$ = this.store.pipe(select(selectAllIncidentNotifications));
        this.internalTickets$ = this.store.pipe(select(selectNotificationByType({id: IncidentNotificationType.internal})));
        this.sectionTickets$ = this.store.pipe(select(selectNotificationByType({id: IncidentNotificationType.section})));
        this.incommingTickets$ = this.store.pipe(select(selectNotificationByType({id: IncidentNotificationType.external})));
        this.outgoingTickets$ = this.store.pipe(select(selectNotificationByType({id: IncidentNotificationType.client})));
        this.myIndividualTickets$ = this.store.pipe(select(selectNotificationByType({id: IncidentNotificationType.individual})));
        this.departmentTickects$ = this.store.pipe(select(selectNotificationByType({id: IncidentNotificationType.departmental})));

        // this.chartOptions = {
        //     series: [76, 67, 61, 90],
        //     chart: {
        //         height: 390,
        //         type: 'radialBar'
        //     },
        //     plotOptions: {
        //         radialBar: {
        //             offsetY: 0,
        //             startAngle: 0,
        //             endAngle: 270,
        //             hollow: {
        //                 margin: 5,
        //                 size: '30%',
        //                 background: 'transparent',
        //                 image: undefined
        //             },
        //             dataLabels: {
        //                 name: {
        //                     show: false
        //                 },
        //                 value: {
        //                     show: false
        //                 }
        //             }
        //         }
        //     },
        //     colors: ["#1ab7ea", "#0084ff", "#39539E", "#0077B5"],
        //     labels: ["Vimeo", "Messenger", "Facebook", "LinkedIn"],
        //     legend: {
        //         show: true,
        //         floating: true,
        //         fontSize: "16px",
        //         position: "left",
        //         offsetX: 50,
        //         offsetY: 10,
        //         labels: {
        //             useSeriesColors: true
        //         },
        //         formatter: function (seriesName, opts) {
        //             return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex];
        //         },
        //         itemMargin: {
        //             horizontal: 3
        //         }
        //     },
        //     responsive: [
        //         {
        //             breakpoint: 480,
        //             options: {
        //                 legend: {
        //                     show: false
        //                 }
        //             }
        //         }
        //     ]
        // };


        this.subscription.add(
            this.store.pipe(select(selectAllIncidentNotifications)).subscribe(dt => {
                if (dt.length) {
                    const int = dt.find(d => d?.id === IncidentNotificationType.internal);
                    const out = dt.find(d => d?.id === IncidentNotificationType.client);
                    const inc = dt.find(d => d?.id === IncidentNotificationType.external);
                    const ind = dt.find(d => d?.id === IncidentNotificationType.individual);
                    const sec = dt.find(d => d?.id === IncidentNotificationType.section);
                    const dept = dt.find(d => d?.id === IncidentNotificationType.departmental);

                    const totalOpen = (inc?.myAssignedStatuses?.open ?? 0) + (inc?.incommingStatuses?.open ?? 0) +
                        (out?.outgoingStatuses?.open ?? 0) + (sec?.sectionStatuses?.open ?? 0) + (int?.internalStatuses?.open ?? 0) +
                        (ind?.myReportedStatuses?.open ?? 0) +  (dept?.departmentStatus?.open ?? 0);

                    const totalResolved = (inc?.myAssignedStatuses?.resolved ?? 0) + (inc?.incommingStatuses?.resolved ?? 0) +
                        (out?.outgoingStatuses?.resolved ?? 0) + (sec?.sectionStatuses?.resolved ?? 0)
                        + (int?.internalStatuses?.resolved ?? 0) + (ind?.myReportedStatuses?.resolved ?? 0) + (dept?.departmentStatus?.resolved ?? 0);

                    const totalClosed = (inc?.myAssignedStatuses?.closed ?? 0) + (inc?.incommingStatuses?.closed ?? 0) +
                        (out?.outgoingStatuses?.closed ?? 0) + (sec?.sectionStatuses?.closed ?? 0) + (int?.internalStatuses?.closed ?? 0)
                        + (ind?.myReportedStatuses?.closed ?? 0) + (dept?.departmentStatus?.closed ?? 0);

                    const grandTotal = totalClosed + totalOpen + totalResolved;
                    this.totalClosed = totalClosed;
                    this.totalResolved = totalResolved;
                    this.totalOpen = totalOpen;
                    this.grandTotal = grandTotal;

                    this.setChart({
                        totalResolved: this.getPercent({val: totalResolved, total: grandTotal}),
                        totalClosed: this.getPercent({val: totalClosed, total: grandTotal}),
                        totalOpen: this.getPercent({val: totalOpen, total: grandTotal}),
                        total: grandTotal
                    });
                }
            })
        );

    }

    // rgba(254, 176, 25, 0.85)
    // gba(0, 227, 150, 0.85)

    getPercent(input: { val: number, total: number }) {
        return Math.round((input.val / input.total) * 100);
    }

    setChart(input: { totalOpen: number, totalResolved: number, totalClosed: number, total: number }) {
        this.chartOptions = {
            series: [input.totalOpen, input.totalResolved, input.totalClosed],
            // colors: ['#f8cb3b', '#1bf53f', '#12fbba'],
            colors: ['#4097cc', '#ffbe46', '#12fbba'],
            chart: {
                height: 300,
                type: 'radialBar',
            },
            plotOptions: {
                radialBar: {
                    dataLabels: {
                        show: true,
                        name: {
                            fontSize: '22px'
                        },
                        value: {
                            fontSize: '16px'
                        },
                        total: {
                            show: true,
                            label: 'Total',
                            formatter(w) {
                                return input.total + '';
                            }
                        }
                    }
                }
            },
            labels: ['Open', 'Closed', 'Resolved']
        };

    }
}
