
<mat-card>
    <mat-card-title>Polar Graph</mat-card-title>
    <mat-divider></mat-divider>
    <mat-card-content>
      <div style="display: flex;">
        <canvas baseChart  height="100"
          [data]="polarAreaChartData"
          [labels]="polarAreaChartLabels"
          [legend]="polarAreaLegend"
          [chartType]="polarAreaChartType">
        </canvas>
      </div>  
    </mat-card-content>
</mat-card>
