import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromActions from './incident-trail.actions';
import * as fromGraphql from './incident-trail.graphql';
import { map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';
import { ResponseCodeService } from 'src/app/services/response-code.service';
import { AppState } from '../../reducers';



@Injectable()
export class IncidentTrailEffects {

  // Get Incident trail By Id
  incidentTransactionListByIncident$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.incidentTransactionListByIncident),
    switchMap((action) => {
      return this.apollo.query({
        query: fromGraphql.INCIDENT_TRANSACTION_BY_ID,
        fetchPolicy: 'network-only',
        variables: {
          incidentId: action.incidentId
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while fetching Incident trail'),
        map(({ data }: any) => {
          if (data) {
            if (data.incidentTransactionListByIncident) {
              this.store.dispatch(fromActions.upsertIncidentTrails({ incidentTrails: data.incidentTransactionListByIncident }));
            } else {
              this.notificationService.errorMessage('Failed');
            }
          }
        })
      );
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private apollo: Apollo,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private responseCodesService: ResponseCodeService,
    private router: Router
  ) {
  }

}
