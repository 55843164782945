import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ResourceCategory } from './resource-category.model';
import * as ResourceCategoryActions from './resource-category.actions';

export const ResourceCategorysFeatureKey = 'ResourceCategorys';

export interface State extends EntityState<ResourceCategory> {
  // additional entities state properties
}

export const adapter: EntityAdapter<ResourceCategory> = createEntityAdapter<ResourceCategory>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(ResourceCategoryActions.addResourceCategory,
    (state, action) => adapter.addOne(action.resourceCategory, state)
  ),
  on(ResourceCategoryActions.upsertResourceCategory,
    (state, action) => adapter.upsertOne(action.resourceCategory, state)
  ),
  on(ResourceCategoryActions.addResourceCategorys,
    (state, action) => adapter.addMany(action.resourceCategorys, state)
  ),
  on(ResourceCategoryActions.upsertResourceCategorys,
    (state, action) => adapter.upsertMany(action.resourceCategorys, state)
  ),
  on(ResourceCategoryActions.updateResourceCategory,
    (state, action) => adapter.updateOne(action.resourceCategory, state)
  ),
  on(ResourceCategoryActions.updateResourceCategorys,
    (state, action) => adapter.updateMany(action.resourceCategorys, state)
  ),
  on(ResourceCategoryActions.deleteResourceCategory,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(ResourceCategoryActions.deleteResourceCategorys,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(ResourceCategoryActions.loadResourceCategorys,
    (state, action) => adapter.setAll(action.resourceCategorys, state)
  ),
  on(ResourceCategoryActions.clearResourceCategorys,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
