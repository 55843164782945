
<!-- HEADER START HERE -->
<mat-toolbar color="primary">
    <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()"  class="btn-toggle">
      <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
    </button>

    <span class="ega">{{ institutionName }}</span>

    <!-- right-panel -->
    <div class="right-panel">
      <button *ngIf="outgoingTickets$ | async as notifications" class="notifications"  mat-icon-button [matMenuTriggerFor]="notification">
        <mat-icon  matBadge="{{notifications[0]?.outgoingStatuses?.open || 0 | number}}">notifications</mat-icon>
     </button> 
          
      <div class="user-name" *ngIf="user$ | async as user">
        {{ user.firstName }} {{ user.lastName }}
      </div>
      <button mat-icon-button [matMenuTriggerFor]="userMenu" class="btn-logout">
        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>
    </div>
    <!-- end of right-panel -->

  </mat-toolbar>
<!-- HEADER END HERE -->

<mat-sidenav-container class="sidenav-container">
    <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false">
        <!-- SIDEBAR START HERE -->
        <app-sidebar [sidebarMenu]="sideMenu"></app-sidebar>
        <!-- SIDEBAR END HERE -->
    </mat-sidenav>
    <mat-sidenav-content>

        <!-- CONTENT START HERE -->
        <app-client-content></app-client-content>
        <!-- CONTENT END HERE -->
    </mat-sidenav-content>
</mat-sidenav-container>

<mat-menu #userMenu="matMenu">
    <button mat-menu-item (click)="profile()">
    <mat-icon>person</mat-icon>
    Profile
  </button>
    <button (click)="openChangePasswordDialog()" mat-menu-item>
    <mat-icon>vpn_key</mat-icon>
    Change Password
  </button>
  <mat-divider></mat-divider>
  <button class="text-danger" mat-menu-item (click)="logout()">
    <mat-icon class="text-danger">power_settings_new</mat-icon> Log Out
  </button>
</mat-menu>
<mat-menu #notification="matMenu">
   <app-incident-notification></app-incident-notification>
  </mat-menu>
  
