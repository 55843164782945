<app-header></app-header>
<!-- <app-modal-header title="">
</app-modal-header> -->

<div class="landing-content">
    <div class="header-row">
        <div class="header-title">
            <h1 class="text-center"> {{systemTitle}} </h1>
        </div>
    </div>
    <div class="module-holder">

        <ng-container *ngFor="let menu of menu; let i=index">
            <div *ngxPermissionsOnly="menu?.permission" [@slideInRightOnEnter] [@slideOutLeftOnLeave]
                 class="module-box fade-on" style="--position: {{i *2}}" routerLink="{{menu?.route}}">
                <div class="icon_module">
                    <mat-icon svgIcon="{{menu?.icon}}"> </mat-icon>
                </div>
                <span class="mb-name">{{menu?.name}} </span>
            </div>
        </ng-container>

        <!-- <div class="all">
            <div class="center">
                <div class="explainer"><span>Upcoming Services</span></div>
                <div class="text">Change Request</div>
            </div>
            <div class="right">
                <div class="text">Knowledge Base</div>
            </div>
            <div class="righter">
                <div class="text">Problems Management</div>
            </div>
        </div> -->
    </div>
</div> 