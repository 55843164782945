import gql from 'graphql-tag';
import { basePageableGraphqlField } from '../../base.graphql';

export const SolutionStepFields = `
  active
  createdAt
  createdBy
  deleted
  id
  uid
  step
  stepDescription
  title
  updatedAt
  updatedBy

`;


export const GET_INCIDENT_SOLUTIONS_BY_INCIDENT = gql`
query getAllSolutionStepsByIncident($incidentUuid: String, $pageparam: PageableParamInput){
  getAllSolutionStepsByIncident(incidentUuid: $incidentUuid, pageparam: $pageparam){
    content{
      id
      uuid
      step
      title
      stepDescription
      createdBy
      createdAt
      url
    }
    ${basePageableGraphqlField}
  }
}
`;


export const ADD_INCIDENT_SOLUTIONS = gql`
mutation addUpdateKnowledgeBaseQuestionSolutionStep($incidentSolutionStep: KnowledgeBaseSolutionStepDtoInput){
  addUpdateKnowledgeBaseQuestionSolutionStep(incidentSolutionStep: $incidentSolutionStep){
     data{
      id
      uuid
      step
      title
      stepDescription
      createdBy
      url
      createdAt
    }
   code
   message
  }
}
`;

export const REMOVE_INCIDENT_SOLUTION = gql`
mutation deleteKnowledgeBaseQuestionSolutionStep($incidentSolutionStepUuid: String){
  deleteKnowledgeBaseQuestionSolutionStep(incidentSolutionStepUuid: $incidentSolutionStepUuid){
     data{
      id
    }
   code
   message
  }
}
`;


