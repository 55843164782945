import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { ApprovalStage, Stage } from './approval-stage.model';
import { PageableParam } from 'src/app/interfaces/global.interface';

export const loadApprovalStages = createAction(
  '[ApprovalStage/API] Load ApprovalStages', 
  props<{ approvalStages: ApprovalStage[] }>()
);

export const loadPageables = createAction(
  '[ApprovalStage/API] Load ApprovalStages', 
  props<{ stages: Stage }>()
);

export const addApprovalStage = createAction(
  '[ApprovalStage/API] Add ApprovalStage',
  props<{ approvalStage: ApprovalStage }>()
);

export const upsertApprovalStage = createAction(
  '[ApprovalStage/API] Upsert ApprovalStage',
  props<{ approvalStage: ApprovalStage }>()
);

export const addApprovalStages = createAction(
  '[ApprovalStage/API] Add ApprovalStages',
  props<{ approvalStages: ApprovalStage[] }>()
);

export const upsertApprovalStages = createAction(
  '[ApprovalStage/API] Upsert ApprovalStages',
  props<{ approvalStages: ApprovalStage[] }>()
);

export const updateApprovalStage = createAction(
  '[ApprovalStage/API] Update ApprovalStage',
  props<{ approvalStage: Update<ApprovalStage> }>()
);

export const updateApprovalStages = createAction(
  '[ApprovalStage/API] Update ApprovalStages',
  props<{ approvalStages: Update<ApprovalStage>[] }>()
);

export const deleteApprovalStage = createAction(
  '[ApprovalStage/API] Delete ApprovalStage',
  props<{ id: number }>()
);

export const deleteApprovalStageUser = createAction(
  '[ApprovalStage/API] Delete ApprovalStageUser',
  props<{ stageID: number, UserID: number }>()
);

export const deleteApprovalStages = createAction(
  '[ApprovalStage/API] Delete ApprovalStages',
  props<{ ids: string[] }>()
);

export const clearApprovalStages = createAction(
  '[ApprovalStage/API] Clear ApprovalStages'
);

export const activateApprovalStage = createAction(
  '[ApprovalStage/API] Activate ApprovalStage',
  props<{ id: number}>()
);

export const removeApprovalStage = createAction(
  '[ApprovalStage/API] remove Approval Stages',  
  props<{ id:number,uuid: string }>()
);

export const listInstitutionApprovalStages = createAction(
  '[ApprovalStage/API] List Institution approval stages',
  props<{institutionId: number, pageableParam?: PageableParam }>()
);

export const listAllAvailableApprovalStage = createAction(
  '[ApprovalStage/API] List Available approval stages',
  props<{pageableParam?: PageableParam }>()
);

export const addApprovalStageUser = createAction(
  '[ApprovalStage/API] Add ApprovalStageUser',
  props<{ stageUuid: string, UserUuid: string }>()
);

export const removeApprovalStageUser = createAction(
  '[ApprovalStage/API] Delete ApprovalStageUser',
  props<{ stageID: number, UserID: number, stageUuid: string, UserUuid: string }>()
);



