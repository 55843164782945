import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Test } from './test.model';
import * as TestActions from './test.actions';

export const testsFeatureKey = 'tests';

export interface State extends EntityState<Test> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Test> = createEntityAdapter<Test>({
  selectId: (test: Test) => test.uuid,
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export const reducer = createReducer(
  initialState,
  on(TestActions.addTest,
    (state, action) => adapter.addOne(action.test, state)
  ),
  on(TestActions.upsertTest,
    (state, action) => adapter.upsertOne(action.test, state)
  ),
  on(TestActions.addTests,
    (state, action) => adapter.addMany(action.tests, state)
  ),
  on(TestActions.upsertTests,
    (state, action) => adapter.upsertMany(action.tests, state)
  ),
  on(TestActions.updateTest,
    (state, action) => adapter.updateOne(action.test, state)
  ),
  on(TestActions.updateTests,
    (state, action) => adapter.updateMany(action.tests, state)
  ),
  on(TestActions.deleteTest,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(TestActions.deleteTests,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(TestActions.loadTests,
    (state, action) => adapter.setAll(action.tests, state)
  ),
  on(TestActions.clearTests,
    state => adapter.removeAll(state)
  ),
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
