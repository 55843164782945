import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import { switchMap, map } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { ResponseCodeService } from 'src/app/services/response-code.service';
import { AppState } from '../../reducers';
import * as fromActions from './priority.actions';
import * as fromGraphql from './priority.graphql';


@Injectable()
export class PriorityEffects {


 // listAllInstitutionPrioritys
  listAllInstitutionPrioritys$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.listAllInstitutionPrioritys),
    switchMap((action) => {
      return this.apollo.query({
        query: fromGraphql.LIST_ALL_INSTITUTION_PRIORITYS,
         variables: {
          pageparam: action?.pageParam,
         },
        fetchPolicy: 'network-only',
      }).pipe(
        this.notificationService.catchError('Problem occurred while fetching priorities'),
        map(({ data }: any) => {
          if (data) {
            const result = data?.getAllInstitutionPriorities;            
            if (result?.content.length > 0) {
              this.paginationService.setPage(result);
              this.store.dispatch(fromActions.loadPrioritys({ prioritys: data?.getAllInstitutionPriorities?.content || [] }));
            }
            } 
        })
      );
    })
  ), {dispatch: false});

  // addPriority
  addPriority$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.addPriority),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: fromGraphql.CREATE_INSTITUTION_PRIORITY,
        variables: {
          priority: action.priority
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while saving  Incident Categories'),
        map(({ data }: any) => {
          if (data) {
            if (data.addUpdatePriority.code === 9000) {
              this.store.dispatch(fromActions.upsertPriority({ priority: data.addUpdatePriority.data }));
              if(action.priority.uuid){
                this.notificationService.successMessage('Priority edited successful');
              } else {
                this.notificationService.successMessage('Priority saved successful');
              }
            } else {
              this.notificationService.errorMessage('Priority not saved: ' + data.addUpdatePriority.code);
            }
          }
        })
      );
    })
  ), {dispatch: false});

  // removePriority
  removePriority$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.removePriority),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: fromGraphql.DELETE_INSTITUTION_PRIORITY,
        variables: {
          priorityUuid: action.priorityUuid
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while deleting priority'),
        map(({ data }: any) => {
          if (data) {
            if (data.deletePriority.code === 9000) {
              this.store.dispatch(fromActions.deletePriority({ id: action.id }));
              this.notificationService.successMessage('Priority deleted successful');
            } else {
              this.notificationService.errorMessage('Priority deletion failed');
            }
          }
        })
      );
    })
  ), {dispatch: false});

  
  constructor(
    private actions$: Actions,
    private apollo: Apollo,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private paginationService: PaginationService,
    private responseCodesService: ResponseCodeService
  ) {
  }

}
