import { Component, OnInit, HostBinding, ChangeDetectionStrategy, Input } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { FieldConfig } from '../field.interface';
@Component({
  selector: 'app-textarea',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
<ng-container *ngIf="field">
<mat-form-field class="demo-full-width" [formGroup]="group" appearance="outline">
        <mat-label> {{ field.label }} </mat-label>
        <textarea matInput [formControlName]="field.key || null" [placeholder]="field.placeholder || field.label || ''"
        [required]="field.key && group?.get(field.key)?.errors !== null && group?.get(field.key)?.errors?.['required']" ></textarea>
        <ng-container *ngFor="let validation of field.validations;" ngProjectAs="mat-error">
        <mat-error *ngIf="field.key && group?.get(field.key)?.hasError(validation.name)">{{validation.message}}</mat-error>
        </ng-container>
        </mat-form-field>
</ng-container>
`,
  // host: {'class': 'col-md-6'},
  styles: []
})
export class TextareaComponent implements OnInit {
  @Input() field?: FieldConfig;
  @Input() group: UntypedFormGroup = new UntypedFormGroup({});

  // @HostBinding('class') rowClass = 'col-md-6';
  constructor() {

  }
  ngOnInit() {


    //  this.rowClass = this.field.rowClass === 'col12' ? 'col-md-12' : 'col-md-6';
  }
}
