import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Question, QuestionAnswerChoiceDtoInput } from './question.model';

export const loadQuestions = createAction(
  '[Question/API] Load Questions', 
  props<{ questions: Question[]; }>()
);
export const storeModifiedResult = createAction(
  '[Question] Store Modified Result',
  props<{ modifiedResult: any }>()
);
export const addQuestion = createAction(
  '[Question/API] Add Question',
  props<{ question: Question }>()
);

export const upsertQuestion = createAction(
  '[Question/API] Upsert Question',
  props<{ question: Question }>()
);

export const addQuestions = createAction(
  '[Question/API] Add Questions',
  props<{ questions: Question[] }>()
);

export const upsertQuestions = createAction(
  '[Question/API] Upsert Questions',
  props<{ questions: Question[] }>()
);

export const updateQuestion = createAction(
  '[Question/API] Update Question',
  props<{ question: Update<Question> }>()
);

export const updateQuestions = createAction(
  '[Question/API] Update Questions',
  props<{ questions: Update<Question>[] }>()
);

export const deleteQuestion = createAction(
  '[Question/API] Delete Question',
  props<{ id: number }>()
);

export const deleteQuestions = createAction(
  '[Question/API] Delete Questions',
  props<{ ids: string[] }>()
);

export const clearQuestions = createAction(
  '[Question/API] Clear Questions'
);

// CUSTOM

export const getAllQuestionsByEducationLevelUid = createAction(
  '[Question/API] Get all questions by education uid',
  props<{ pageParam: any, educationLevelUid: string }>()
);

export const getAllQuestionsByEducationCategory = createAction(
  '[Question/API] Get all questions by education category',
  props<{ pageParam: any, educationCategoryUid: string }>()
);

export const getAllQuestionsByAptitudeTest = createAction(
  '[Question/API] Get all questions by aptitude test',
  props<{ aptitudeTestUid: string }>()
);

export const getAllQuestions = createAction(
  '[Question/API] Get all questions',
  props<{ pageParam: any  }>()
);

export const getAllQuestionChoiceByQuestionAndIsCorrect = createAction(
  '[Question/API] Get all questions Choice By Question And Is Correct',
  props<{ question: string,iscorrect:boolean }>()
);

export const getQuestionByAnswerChoiceUid = createAction(
  '[Question/API] Get question by answer choice uid',
  props<{ questionAnswerChoiceUids: any }>()
);

export const getQuestionChoiceByUid = createAction(
  '[Question/API] Get question by choice uid',
  props<{ questionUId: string }>()
);

export const getAllQuestionChoicesByQuestion = createAction(
  '[Question/API] Get All Question Choice By Question',
  props<{ question: string }>()
);

export const saveOrUpdateQuestions = createAction(
  '[Question/API] save or update questions by uploading excel',
  props<{ QuestionDto: any }>()
);

export const saveOrUpdateQuestion = createAction(
  '[Question/API] save or update question',
  props<{ QuestionDto: any}>()
);

export const saveOrUpdateQuestionBank = createAction(
  '[Question/API] save or update question bank',
  props<{ QuestionBankDto: any }>()
);

export const removeQuestion = createAction(
  '[Question/API] Remove question',
  props<{ questionUid: string , id: number}>()
);

export const deleteQuestionChoicesByQuestion = createAction(
  '[Question/API] Remove Question  Choice By Question',
  props<{ question: string }>()
);

export const deleteQuestionAnswerChoiceByUid = createAction(
  '[Question/API] Remove Question Answer Choice By Uid',
  props<{ questionChoiceUid: string }>()
);

export const saveAndUpdateQuestionChoice = createAction(
  '[Question/API] save or update question Choice',
  props<{ questionAnswerChoiceDto: QuestionAnswerChoiceDtoInput }>()
);

export const saveAndUpdateMultipleQuestionChoices = createAction(
  '[Question/API] save or update multiple question Choice',
  props<{ questionAnswerChoiceDtoList: [QuestionAnswerChoiceDtoInput]}>()
);


//ANSWER APLITUDE TEST
export const getUserAnswerQuestionByUserAndAptitudeTest = createAction(
  '[Question/API] Get User Answer Question By User And Aplitude Test',
  props<{ aptitudeTestUid: string }>()
);

export const getAptitudeTestQuestions = createAction(
  '[Question/API] Get Aptitude Test Questions',
  props<{ aptitudeTestUid: string }>()
);