import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { ApprovalLinkage, Stage } from './approval-linkage.model';
import { PageableParam } from 'src/app/interfaces/global.interface';

export const loadApprovalLinkages = createAction(
  '[ApprovalLinkage/API] Load ApprovalLinkages', 
  props<{ approvalLinkages: ApprovalLinkage[] }>()
);

export const loadPageables = createAction(
  '[ApprovalLinkage/API] Load ApprovalLinkages', 
  props<{ stages: Stage }>()
);

export const addApprovalLinkage = createAction(
  '[ApprovalLinkage/API] Add ApprovalLinkage',
  props<{ approvalLinkage: ApprovalLinkage }>()
);

export const upsertApprovalLinkage = createAction(
  '[ApprovalLinkage/API] Upsert ApprovalLinkage',
  props<{ approvalLinkage: ApprovalLinkage }>()
);

export const addApprovalLinkages = createAction(
  '[ApprovalLinkage/API] Add ApprovalLinkages',
  props<{ approvalLinkages: ApprovalLinkage[] }>()
);

export const upsertApprovalLinkages = createAction(
  '[ApprovalLinkage/API] Upsert ApprovalLinkages',
  props<{ approvalLinkages: ApprovalLinkage[] }>()
);

export const updateApprovalLinkage = createAction(
  '[ApprovalLinkage/API] Update ApprovalLinkage',
  props<{ approvalLinkage: Update<ApprovalLinkage> }>()
);

export const updateApprovalLinkages = createAction(
  '[ApprovalLinkage/API] Update ApprovalLinkages',
  props<{ approvalLinkages: Update<ApprovalLinkage>[] }>()
);

export const deleteApprovalLinkage = createAction(
  '[ApprovalLinkage/API] Delete ApprovalLinkage',
  props<{ id: number }>()
);

export const deleteApprovalLinkages = createAction(
  '[ApprovalLinkage/API] Delete ApprovalLinkages',
  props<{ ids: string[] }>()
);

export const clearApprovalLinkages = createAction(
  '[ApprovalLinkage/API] Clear ApprovalLinkages'
);

export const activateApprovalLinkage = createAction(
  '[ApprovalLinkage/API] Activate ApprovalLinkage',
  props<{ id: number}>()
);

export const removeApprovalLinkage = createAction(
  '[ApprovalLinkage/API] remove Approval Linkages',  
  props<{ id:number,uuid: string }>()
);

export const listInstitutionApprovalLinkages = createAction(
  '[ApprovalLinkage/API] List Institution approval stage Linkages',
  props<{approvalStageID: number, pageableParam?: PageableParam }>()
);


export const listAllAvailableApprovalLinkageBYWorkflowId = createAction(
  '[ApprovalLinkage/API] List Institution approval workflow Linkages',
  props<{workflowId: number, pageableParam?: PageableParam }>()
);


export const listAllAvailableApprovalLinkageByUuid = createAction(
  '[ApprovalLinkage/API] List Institution approval uuid Linkages',
  props<{uuid: string, pageableParam?: PageableParam }>()
);
export const listAllAvailableApprovalLinkage = createAction(
  '[ApprovalLinkage/API] List Available approval Linkages',
  props<{pageableParam?: PageableParam }>()
);




