import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { RolePermission } from './role-permission.model';
import * as RolePermissionActions from './role-permission.actions';

export const rolePermissionsFeatureKey = 'rolePermissions';

export interface State extends EntityState<RolePermission> {
  // additional entities state properties
}

export const adapter: EntityAdapter<RolePermission> = createEntityAdapter<RolePermission>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(RolePermissionActions.addRolePermission,
    (state, action) => adapter.addOne(action.rolePermission, state)
  ),
  on(RolePermissionActions.upsertRolePermission,
    (state, action) => adapter.upsertOne(action.rolePermission, state)
  ),
  on(RolePermissionActions.addRolePermissions,
    (state, action) => adapter.addMany(action.rolePermissions, state)
  ),
  on(RolePermissionActions.upsertRolePermissions,
    (state, action) => adapter.upsertMany(action.rolePermissions, state)
  ),
  on(RolePermissionActions.updateRolePermission,
    (state, action) => adapter.updateOne(action.rolePermission, state)
  ),
  on(RolePermissionActions.updateRolePermissions,
    (state, action) => adapter.updateMany(action.rolePermissions, state)
  ),
  on(RolePermissionActions.deleteRolePermission,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(RolePermissionActions.deleteRolePermissions,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(RolePermissionActions.loadRolePermissions,
    (state, action) => adapter.setAll(action.rolePermissions, state)
  ),
  on(RolePermissionActions.clearRolePermissions,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
