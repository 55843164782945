import gql from 'graphql-tag';
import { institutionFields } from '../settings/institution/institution.graphql';
import { requestCategorysFields } from '../settings/request-category/request-category.graphql';
import { requestPrioritysFields } from '../settings/request-priority/request-priority.graphql';
export const changeRequestsFields = `
active
affectedParts
attachment
category{
  ${requestCategorysFields}
}
createdAt
createdBy
deleted
description
id
impact
institution{
${institutionFields}
}
priority{
  ${requestPrioritysFields}
}
requestType
title
updatedAt
updatedBy
uuid
`;

// REQUESTs




export const GET_MY_INSTITUTION_ALL_CHANGE_REQUESTS = gql`
query getAllInstitutionChangeRequest($pageParam: PageableParamInput){
  getAllInstitutionChangeRequest(pageParam:$pageParam){
    content{
      ${changeRequestsFields}
    }
    first
    last
    number
    numberOfElements
    pageable{
        pageNumber
        pageSize
    }
    size
    totalElements
    totalPages
  }
}
`;

export const GET_ALL_MY_CHANGE_REQUEST = gql`
query getAllMyChangeRequests($pageParam: PageableParamInput){
  getAllMyChangeRequests(pageParam:$pageParam){
    content{
      ${changeRequestsFields}
    }
    first
    last
    number
    numberOfElements
    pageable{
        pageNumber
        pageSize
    }
    size
    totalElements
    totalPages
  }
}
`;

export const GET_ALL_ACTIVE_CHANGE_REQUESTS = gql`
query getAllActiveChangeRequest($pageParam: PageableParamInput){
  getAllActiveChangeRequest(pageParam:$pageParam){
    content{
      ${changeRequestsFields}
    }
    first
    last
    number
    numberOfElements
    pageable{
        pageNumber
        pageSize
    }
    size
    totalElements
    totalPages
  }
}
`;

export const SAVE_UPDATE_CHANGE_REQUEST = gql`
mutation createUpdateChangeRequest($changeRequest: ChangeRequestDtoInput){
  createUpdateChangeRequest(changeRequest:$changeRequest){
    code
    status
    data{
      ${changeRequestsFields}
    }
    dataList{
      ${changeRequestsFields}
    }
    message
  
  }
}
`;

export const ACTIVATE_CHANGE_REQUEST = gql`
mutation activateDeactivateChangeRequest($id: Long){
  activateDeactivateChangeRequest(id:$id){
    code
    status
    data{
      ${changeRequestsFields}
    }
    dataList{
      ${changeRequestsFields}
    }
    message
  
  }
}
`;

export const DELETE_CHANGE_REQUEST = gql`
mutation deleteChangeRequest($id: String){
  deleteChangeRequest(id:$id){
    code
    status
    data{
      ${changeRequestsFields}
    }
    dataList{
      ${changeRequestsFields}
    }
    message
  
  }
}
`;




















