import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { IncidentType } from './incident-type.model';
import * as IncidentTypeActions from './incident-type.actions';

export const incidentTypesFeatureKey = 'incidentTypes';

export interface State extends EntityState<IncidentType> {
  // additional entities state properties
}

export const adapter: EntityAdapter<IncidentType> = createEntityAdapter<IncidentType>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(IncidentTypeActions.addIncidentType,
    (state, action) => adapter.addOne(action.incidentType, state)
  ),
  on(IncidentTypeActions.upsertIncidentType,
    (state, action) => adapter.upsertOne(action.incidentType, state)
  ),
  on(IncidentTypeActions.addIncidentTypes,
    (state, action) => adapter.addMany(action.incidentTypes, state)
  ),
  on(IncidentTypeActions.upsertIncidentTypes,
    (state, action) => adapter.upsertMany(action.incidentTypes, state)
  ),
  on(IncidentTypeActions.updateIncidentType,
    (state, action) => adapter.updateOne(action.incidentType, state)
  ),
  on(IncidentTypeActions.updateIncidentTypes,
    (state, action) => adapter.updateMany(action.incidentTypes, state)
  ),
  on(IncidentTypeActions.deleteIncidentType,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(IncidentTypeActions.deleteIncidentTypes,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(IncidentTypeActions.loadIncidentTypes,
    (state, action) => adapter.setAll(action.incidentTypes, state)
  ),
  on(IncidentTypeActions.clearIncidentTypes,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
