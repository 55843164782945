import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { IncidentTrail } from './incident-trail.model';

export const loadIncidentTrails = createAction(
  '[IncidentTrail/API] Load IncidentTrails',
  props<{ incidentTrails: IncidentTrail[] }>()
);

export const addIncidentTrail = createAction(
  '[IncidentTrail/API] Add IncidentTrail',
  props<{ incidentTrail: IncidentTrail }>()
);

export const upsertIncidentTrail = createAction(
  '[IncidentTrail/API] Upsert IncidentTrail',
  props<{ incidentTrail: IncidentTrail }>()
);

export const addIncidentTrails = createAction(
  '[IncidentTrail/API] Add IncidentTrails',
  props<{ incidentTrails: IncidentTrail[] }>()
);

export const upsertIncidentTrails = createAction(
  '[IncidentTrail/API] Upsert IncidentTrails',
  props<{ incidentTrails: IncidentTrail[] }>()
);

export const updateIncidentTrail = createAction(
  '[IncidentTrail/API] Update IncidentTrail',
  props<{ incidentTrail: Update<IncidentTrail> }>()
);

export const updateIncidentTrails = createAction(
  '[IncidentTrail/API] Update IncidentTrails',
  props<{ incidentTrails: Update<IncidentTrail>[] }>()
);

export const deleteIncidentTrail = createAction(
  '[IncidentTrail/API] Delete IncidentTrail',
  props<{ id: string }>()
);

export const deleteIncidentTrails = createAction(
  '[IncidentTrail/API] Delete IncidentTrails',
  props<{ ids: string[] }>()
);

export const clearIncidentTrails = createAction(
  '[IncidentTrail/API] Clear IncidentTrails'
);

/* API Effect */

export const incidentTransactionListByIncident = createAction(
  '[IncidentTrail/API] Incident Transaction List By Incident',
  props<{ incidentId: number }>()
);
