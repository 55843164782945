import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from './../../../reducers/index';
import * as fromDesignationReducer from './designation.reducer';
import { Designation } from './designation.model';

export const currentState = (state: AppState) => (state[fromDesignationReducer.designationsFeatureKey]);

export const selectAllDesignations = createSelector(currentState, fromDesignationReducer.selectAll);

export const selectTotalDesignations = createSelector(currentState, fromDesignationReducer.selectTotal);

export const selectModifiedDesignations = createSelector(selectAllDesignations, (designations) => {
  return designations.map((designation) => {
    return {
      ...designation,
    };
  });
});
