import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { LdapConfiguration } from './ldap-configuration.model';
import { PageableParam } from 'src/app/interfaces/global.interface';

export const loadLdapConfigurations = createAction(
  '[LdapConfiguration/API] Load LdapConfigurations', 
  props<{ ldapConfigurations: LdapConfiguration[] }>()
);

export const addLdapConfiguration = createAction(
  '[LdapConfiguration/API] Add LdapConfiguration',
  props<{ ldapConfiguration: any }>()
);

export const upsertLdapConfiguration = createAction(
  '[LdapConfiguration/API] Upsert LdapConfiguration',
  props<{ ldapConfiguration: LdapConfiguration }>()
);

export const addLdapConfigurations = createAction(
  '[LdapConfiguration/API] Add LdapConfigurations',
  props<{ ldapConfigurations: LdapConfiguration[] }>()
);

export const upsertLdapConfigurations = createAction(
  '[LdapConfiguration/API] Upsert LdapConfigurations',
  props<{ ldapConfigurations: LdapConfiguration[] }>()
);

export const updateLdapConfiguration = createAction(
  '[LdapConfiguration/API] Update LdapConfiguration',
  props<{ ldapConfiguration: Update<LdapConfiguration> }>()
);

export const updateLdapConfigurations = createAction(
  '[LdapConfiguration/API] Update LdapConfigurations',
  props<{ ldapConfigurations: Update<LdapConfiguration>[] }>()
);

export const deleteLdapConfiguration = createAction(
  '[LdapConfiguration/API] Delete LdapConfiguration',
  props<{ id: number }>()
);

export const deleteLdapConfigurations = createAction(
  '[LdapConfiguration/API] Delete LdapConfigurations',
  props<{ ids: string[] }>()
);

export const clearLdapConfigurations = createAction(
  '[LdapConfiguration/API] Clear LdapConfigurations'
);

export const removeLdapConfiguration = createAction(
  '[LdapConfiguration/API] Remove LdapConfiguration',
  props<{ ldapConfiguration: LdapConfiguration }>()
);

export const getLdapConfigurations = createAction(
  '[LdapConfiguration/API] get LdapConfigurations',
  props<{  pageparam: PageableParam }>()
);

