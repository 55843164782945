import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';
import { StorageService } from 'src/app/services/storage.service';
import { AppState } from 'src/app/store/reducers';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  currentYear: any;
  loading = false;
  loginView = false;
  subscription: Subscription = new Subscription();
  resetPasswordForm: UntypedFormGroup;
  token: any;

  constructor(
    private fb: UntypedFormBuilder,
    private route: Router,
    private router: ActivatedRoute,
    private store: Store<AppState>,
    private apollo: Apollo,
    private authService: AuthService,
    private storage: StorageService,
    private notificationService: NotificationService,
  ) {
    // localStorage.clear();
    this.token = this.router.snapshot.paramMap.get('token');
  }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
    this.resetPasswordForm = this.fb.group({
      newPassword : [null, [Validators.required]],
      confirmPassword: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
    });
  }

  ngOnDestroy(): void {}

  resetPassword(formData: any) {
  //   this.apollo.mutate({
  //     mutation: REQUEST_RESET_PASSWORD,
  //     variables: {
  //       passwordCreate: {
  //         confirmPassword: formData.confirmPassword,
  //         rememberToken: this.token,
  //         newPassword: formData.newPassword
  //       },
  //       email: formData.email
  //     }
  //   }).pipe(
  //     map(({data}: any) => {
  //       if(data){
  //         data.requestResetPassword;
  //         this.notificationService.successMessage('Submitted successfully, you will receive an email at the address you specified');
  //       } else {
  //         this.notificationService.errorMessage('failed to submit request');
  //       }
  //     })
  //   );
  }

}
