import { Component, Inject, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import { Observable, of } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { initializedPageableParameter } from 'src/app/interfaces/global.interface';
import { NotificationService } from 'src/app/services/notification.service';
import { ResponseCodeService } from 'src/app/services/response-code.service';
import { findInstitutionClientsLocalCodes } from 'src/app/store/entities/client-identification/client-identification.actions';
import { UPLOAD_CLIENTS } from 'src/app/store/entities/settings/institution/institution.graphql';
import { Institution } from 'src/app/store/entities/settings/institution/institution.model';
import { AppState } from 'src/app/store/reducers';
import * as XLSX from 'xlsx';

export interface UploadData{
  NAME: string;
  CLIENT_SHARED_HELPDESK_CODE: string;
  SERVICE_CODE: string;
  CUSTOMER_IDENTIFICATION_CODE: string;
}

@Component({
  selector: 'app-uploading-clients',
  templateUrl: './uploading-clients.component.html',
  styleUrls: ['./uploading-clients.component.scss']
})
export class UploadingClientsComponent implements OnInit {

  items$:Observable<any> = of([]);
  title: string;
  tableColumns: any;
  dataSet: boolean;
  tableData: any[];
  institution: Institution;
  
  constructor(  
    private dialogRef: MatDialogRef<UploadingClientsComponent>,
    private apollo: Apollo,
    private notificationService: NotificationService,
    private store: Store<AppState>,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
  this.institution = data;
  }

  ngOnInit(): void {
    this.title = 'Upload Customers for ' + this.institution?.name;
    this.tableColumns = {
      no: 'SN',
      name: 'Name',
      institutionServiceCode: 'Service Code',
      clientInstitutionShCode: 'Helpdesk Code',
      localClientCode: 'Customer Identification Code',
    };

  }
  
  
  async submit() {  
    this.tableData.forEach(function(v){ delete v?.name });
    this.apollo.mutate({
    mutation: UPLOAD_CLIENTS,
    variables: {
      institutionUuid: this.institution?.uuid,
      clientLocalCodeDtos: this.tableData
    }
    }).pipe(
      this.notificationService.catchError('Problem occured while uploading customers'),
      map(({ data }: any) => {
        if (data) {
          const result = data?.importInstitutionClientLocalCodes;          
          if(result?.length > 0){          
            this.store.dispatch(findInstitutionClientsLocalCodes({
              pageable: initializedPageableParameter,
              institutionUuid: this.institution?.uuid
          }));
          this.notificationService.successMessage('Customers uploaded successfully');
          this.closeModal(true);
          } else {
            this.notificationService.errorMessage('Failed to upload customers')
          }
          
        }
      })
    ).pipe(first()).toPromise();
  
  }
  
  closeModal(close: boolean): void {
    if (close) {
      this.dialogRef.close();
    }
  }
  
  selectedData(event){
    /* wire up file reader */
    
    const target: DataTransfer = <DataTransfer>(<unknown>event.target);
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const data: UploadData[] = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
      // console.log(data); // Data will be logged in array format containing objects
      // this.tableData = data;
      // this.tableTitle = Object.keys(this.tableData[0]); // culumn headers
      // console.log('title', this.tableTitle);
      let items = [];
      for (let i = 0; i < data.length; ++i) {
        items.push({
            name: data[i]?.NAME,
            institutionServiceCode: data[i]?.CLIENT_SHARED_HELPDESK_CODE,
            clientInstitutionShCode: data[i]?.SERVICE_CODE,
            localClientCode: data[i]?.CUSTOMER_IDENTIFICATION_CODE,
        });
        
    }
    this.tableData = items;
      this.items$ = of(items);
      this.dataSet=true;
    };
  
  }

}
