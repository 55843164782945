import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {IncidentNotification} from 'src/app/store/entities/notification/notification.model';

export interface IncidentStatus{
  status: 'assignedSUBMITTED' | 'assignedASSIGNED' | 'assignedRESOLVED' | 'assignedCLOSED' | 'assignedON_HOLD' | 'individualDRAFT' | 'individualSUBMITTED' | 'individualRESOLVED' 
  | 'individualCLOSED' | 'individualON_HOLD'| 'secINCOMMING' | 'secSUBMITTED' | 'secASSIGNED' | 'secRESOLVED' | 'secCLOSED' | 'secON_HOLD'
  | 'deptINCOMMING' | 'deptSUBMITTED' |'deptRESOLVED' | 'deptCLOSED' |'deptON_HOLD' | 'ONBEHALFDRAFT'
  | 'incomingSUBMITTED' | 'incomingASSIGNED' | 'incomingRESOLVED' | 'incomingCLOSED' | 'incomingON_HOLD' |'outSUBMITTED' | 'outDRAFT'
  | 'outRESOLVED' |'outCLOSED' | 'internalDRAFT' | 'internalSUBMITTED' | 'internalASSIGNED' | 'internalRESOLVED' | 'internalCLOSED' | 'internalON_HOLD' | 'clientDRAFT'
  | 'clientSUBMITTED' | 'clientRESOLVED' | 'clientCLOSED' | 'nonEmployeeDRAFT' | 'nonEmployeeSUBMITTED'
  |  'nonEmployeeRESOLVED' | 'nonEmployeeCLOSED' | 'nonEmployeeON_HOLD' | 'internalAllBranch'
  
  title: string;
  noCounts?: boolean;
}

export interface IncidentNav{
  accessedFrom: 'assigned' | 'myReported' | 'incomming' | 'section' | 'internal' | 'department' | 'outgoing' | 'client' | 'nonEmployee';
  actions: IncidentStatus[];
  notifications$: Observable<IncidentNotification[]>;  
  selected: 'assignedSUBMITTED' | 'assignedASSIGNED' | 'assignedRESOLVED' | 'assignedCLOSED' | 'assignedON_HOLD' | 'individualDRAFT' | 'individualSUBMITTED' | 'individualRESOLVED' 
  | 'individualCLOSED' | 'individualON_HOLD'| 'secINCOMMING' | 'secSUBMITTED' | 'secASSIGNED' | 'secRESOLVED' | 'secCLOSED' | 'secON_HOLD'
  | 'deptINCOMMING' | 'deptSUBMITTED' |'deptRESOLVED' | 'deptCLOSED' |'deptON_HOLD' | 'ONBEHALFDRAFT'
  | 'incomingSUBMITTED' | 'incomingASSIGNED' | 'incomingRESOLVED' | 'incomingCLOSED' | 'incomingON_HOLD' |'outSUBMITTED' | 'outDRAFT'
  | 'outRESOLVED' |'outCLOSED' | 'internalDRAFT' | 'internalSUBMITTED' | 'internalASSIGNED' | 'internalRESOLVED' | 'internalCLOSED' | 'internalON_HOLD' | 'clientDRAFT'
  | 'clientSUBMITTED' | 'clientRESOLVED' | 'clientCLOSED' | 'nonEmployeeDRAFT' | 'nonEmployeeSUBMITTED'
  |  'nonEmployeeRESOLVED' | 'nonEmployeeCLOSED' | 'nonEmployeeON_HOLD' | 'internalAllBranch'

}

@Component({
    selector: 'app-incident-nav',
    templateUrl: './incident-nav.component.html',
    styleUrls: ['./incident-nav.component.scss']
})
export class IncidentNavComponent implements OnInit {
  @Output() selectedOption = new EventEmitter<String>(); // sent to parent component e.g IncommingIncidentsComponent
  @Input() incidentNavOptions: IncidentNav;
  selected: any;
  constructor() { }

ngOnInit(): void {
    if (this.incidentNavOptions.selected) {
        this.selected = this.incidentNavOptions.selected;
    } else {
        if (this.incidentNavOptions.accessedFrom == 'incomming') {
            this.selected = this.incidentNavOptions.actions.find(e => e.status == 'incomingSUBMITTED')?.status;
        } else {
            this.selected = this.incidentNavOptions.actions[0]?.status;
        }
    }

}

    optionSelected(status) {
        this.selected = status;
        this.selectedOption.emit(status);
    }

}
