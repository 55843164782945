import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { OnlineResource } from './online-resource.model';

export const loadOnlineResources = createAction(
  '[OnlineResource/API] Load OnlineResources',
  props<{ onlineResources: OnlineResource[] }>()
);

export const addOnlineResource = createAction(
  '[OnlineResource/API] Add OnlineResource',
  props<{ onlineResource: OnlineResource }>()
);

export const upsertOnlineResource = createAction(
  '[OnlineResource/API] Upsert OnlineResource',
  props<{ onlineResource: OnlineResource }>()
);

export const addOnlineResources = createAction(
  '[OnlineResource/API] Add OnlineResources',
  props<{ onlineResources: OnlineResource[] }>()
);

export const upsertOnlineResources = createAction(
  '[OnlineResource/API] Upsert OnlineResources',
  props<{ onlineResources: OnlineResource[] }>()
);

export const updateOnlineResource = createAction(
  '[OnlineResource/API] Update OnlineResource',
  props<{ onlineResource: Update<OnlineResource> }>()
);

export const updateOnlineResources = createAction(
  '[OnlineResource/API] Update OnlineResources',
  props<{ onlineResources: Update<OnlineResource>[] }>()
);

export const deleteOnlineResource = createAction(
  '[OnlineResource/API] Delete OnlineResource',
  props<{ id: number }>()
);

export const deleteOnlineResources = createAction(
  '[OnlineResource/API] Delete OnlineResources',
  props<{ ids: string[] }>()
);

export const clearOnlineResources = createAction(
  '[OnlineResource/API] Clear OnlineResources'
);


// Effect Actions
export const listAllOnlineResources = createAction(
  '[OnlineResource/API] List All OnlineResources'
);

export const getOnlineResourceById = createAction(
  '[OnlineResource/API] Get OnlineResource By Id',
  props<{ id: number }>()
);

export const saveOnlineResource = createAction(
  '[OnlineResource/API] Create OnlineResource',
  props<{ onlineResource: OnlineResource }>()
);

export const removeOnlineResource = createAction(
  '[OnlineResource/API] Remove OnlineResource By Id',
  props<{ id: number }>()
);
