<app-modal-header (closeModal)="closeModal($event)" [title]="title"></app-modal-header>

<form (ngSubmit)="submitForm(roleForm.value)" [formGroup]="roleForm" autocomplete="off" novalidate>

    <div class="row">
        <div class="col-md-12" >
            <mat-form-field appearance="outline" class="material-form">
                <mat-label>Name</mat-label>
                <input oninput="this.value = this.value.toUpperCase()" formControlName="name" matInput placeholder="Name" autocomplete="off" novalidate>
                <mat-error>
                    <span *ngIf="!roleForm.get('name').valid && roleForm.get('name').touched">
                    Name is required.
                    </span>
                </mat-error>
            </mat-form-field>
        </div>

        <div class="col-md-12">
            <mat-form-field appearance="outline" class="material-form">
                <mat-label>Display Name</mat-label>
                <input oninput="this.value = this.value.toUpperCase()" formControlName="displayName" matInput placeholder="Display Name" autocomplete="off" novalidate>
                <mat-error>
                    <span *ngIf="!roleForm.get('displayName').valid && roleForm.get('displayName').touched">
                    Display Name is required.
                </span>
                </mat-error>
            </mat-form-field>
        </div>

        <div class="col-md-12" *ngIf="userType == 'internal'">
            <mat-form-field appearance="outline" class="material-form">
                <mat-label>Is this role visible to all ?</mat-label>
                <mat-select formControlName="global" placeholder="Is this role visible to all ?">
                    <mat-option *ngFor="let option of options" value={{option.value}}>{{option.name}}</mat-option>
                </mat-select>
                <mat-error>
                    <span *ngIf="!roleForm.get('global').valid && roleForm.get('global').touched">
                    This field  is required.
                </span>
                </mat-error>
            </mat-form-field>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="save-right">
                <button [disabled]="!roleForm.valid" class="custom-btn green-btn" type="submit">
                    Save
                </button>
            </div>
        </div>
    </div>

</form>
