import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Manufacture } from './manufacture.model';
import { PageableParam } from 'src/app/interfaces/global.interface';

export const loadManufactures = createAction(
  '[Manufacture/API] Load Manufactures',
  props<{ manufactures: Manufacture[] }>()
);

export const addManufacture = createAction(
  '[Manufacture/API] Add Manufacture',
  props<{ manufacture: Manufacture }>()
);

export const upsertManufacture = createAction(
  '[Manufacture/API] Upsert Manufacture',
  props<{ manufacture: Manufacture }>()
);

export const addManufactures = createAction(
  '[Manufacture/API] Add Manufactures',
  props<{ manufactures: Manufacture[] }>()
);

export const upsertManufactures = createAction(
  '[Manufacture/API] Upsert Manufactures',
  props<{ manufactures: Manufacture[] }>()
);

export const updateManufacture = createAction(
  '[Manufacture/API] Update Manufacture',
  props<{ manufacture: Update<Manufacture> }>()
);

export const updateManufactures = createAction(
  '[Manufacture/API] Update Manufactures',
  props<{ manufactures: Update<Manufacture>[] }>()
);

export const deleteManufacture = createAction(
  '[Manufacture/API] Delete Manufacture',
  props<{ id: number }>()
);

export const deleteManufactures = createAction(
  '[Manufacture/API] Delete Manufactures',
  props<{ ids: number[] }>()
);

export const clearManufactures = createAction(
  '[Manufacture/API] Clear Manufactures'
);

// Effect Actions
export const listMyInstitutionAllManufactures = createAction(
  '[Manufacture/API] List My Institution All Manufactures',
  props<{ pageableParam?: PageableParam }>()
);

export const listMyInstitutionAllManufacturesForOption = createAction(
  '[Manufacture/API] List My Institution All Manufactures For Option',
  props<{ pageableParam?: PageableParam }>()
);

export const activateManufacture = createAction(
  '[Manufacture/API] Activate Manufacture',
  props<{ id: number}>()
);

export const modifyManufacture = createAction(
  '[Manufacture/API] Modify Manufacture Details',
  props<{ manufacture: Manufacture }>()
);


export const createManufacture = createAction(
  '[Manufacture/API] Create Manufacture',
  props<{ manufacture: Manufacture }>()
);

export const removeManufacture = createAction(
  '[Manufacture/API] Remove Manufacture By Id',
  props<{ id: number }>()
);

// export const getDefaultManufacture = createAction(
//   '[User/API] Get Default Manufacture'
// );
