import { Pipe, PipeTransform } from "@angular/core";
import { Apollo } from "apollo-angular";
import { map } from "rxjs/operators";
import { USER_INFOFEW_FIELDS_UUID } from "src/app/store/entities/settings/user/user.graphql";

@Pipe({
    name: 'userUuid'
  })
  export class UserUuidInfoPipe implements PipeTransform {
  
    constructor(private apollo: Apollo) { }
  
    transform(value: any, args?: any): any {
      if (value) {
      return this.apollo.query({
          query: USER_INFOFEW_FIELDS_UUID,
          variables: {
            userUuid: value
          },
          fetchPolicy: 'network-only'
        })
          .pipe(
            map(({ data }: any) => {
            if (data?.userDetailsByUuid?.code === 9000) {
              return data?.userDetailsByUuid?.data?.fullName;
            }
  
          }));
  
      }
  
  }
  
  
  }
  

