<app-modal-header (closeModal)="closeModal($event)" [title]="title"></app-modal-header>

<mat-chip-list aria-label="Dog selection">
    <mat-chip class="text-info" *ngFor="let role of user?.rolesList">
      <mat-chip-avatar>
          <img src="https://material.angular.io/assets/img/examples/shiba1.jpg" alt="Photo of a Shiba Inu"/>
      </mat-chip-avatar>
      {{role?.displayName}}
    </mat-chip>
</mat-chip-list>

