import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import { AppState } from '../../reducers';
import * as fromActions from './incident-response.actions';
import * as fromGraphql from './incident-response.graphql';
import { map, switchMap } from 'rxjs/operators';
import {NotificationService} from '../../../services/notification.service';
import {ResponseCodeService} from '../../../services/response-code.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { initializedPageableParameter } from '../page/page.model';
import { specialInitializedPageableParameter } from 'src/app/interfaces/global.interface';


@Injectable()
export class IncidentResponseEffects {

  // addUpdateIncidentResponse
  addUpdateIncidentResponse = createEffect(() => this.actions$.pipe(
    ofType(fromActions.addUpdateIncidentResponse),
    switchMap((action) => {
       
      return this.apollo.mutate({
        mutation: fromGraphql.CREATE_INCIDENT_RESPONSE,
        variables: {
          incidentResponse: action.incidentResponse
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while submitting comment'),
        map(({ data }: any) => {
          if (data) {
            if (data.addUpdateIncidentResponse.code === 9000) {
                this.notificationService.successMessage('Operation Successful');
                this.store.dispatch(fromActions.upsertIncidentResponse({ incidentResponse: data.addUpdateIncidentResponse.data }));
            } else {
              this.notificationService.errorMessage('Operation failed: '+data.addUpdateIncidentResponse.message + ': '+ data.addUpdateIncidentResponse.code);
            }
          }
        })
      );
    })
  ), { dispatch: false });

  // getAllIncidentResponses
  getAllIncidentResponses$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.getAllIncidentResponses),
    switchMap((action) => {
      return this.apollo.query({
        query: fromGraphql.GET_ALL_PAGED_INCIDENT_RESPONSES,
        fetchPolicy: 'network-only',
        variables: {
          pageparam: action.pageparam
        }
      }).pipe(
        this.notificationService.catchError('Problem while fetching Incidents'),
        map(({ data }: any) => {
          if (data) {
            const result = data?.getAllIncidentResponses;
            if (result?.content?.length > 0) {
              this.paginationService.setPage(result);
            }
            this.store.dispatch(fromActions.loadIncidentResponses({ incidentResponses: data.getAllIncidentResponses.content }));
          }
        })
      );
    })
  ), { dispatch: false });

  // getIncidentResponseByUuid
  getIncidentResponseByUuid$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.getIncidentResponseByUuid),
    switchMap((action) => {
      return this.apollo.query({
        query: fromGraphql.GET_INCIDENT_RESPONSE_BY_UUID,
        fetchPolicy: 'network-only',
        variables: {
          incidentResponseUuid: action.incidentResponseUuid
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while fetching Incidents'),
        map(({ data }: any) => {
          if (data) {
            if (data.getIncidentResponseByUuid) {
              this.store.dispatch(fromActions.upsertIncidentResponse({ incidentResponse: data.getIncidentResponseByUuid.data }));
            } else {
              // const error = this.responseCodesService.getCodeDescription(data.getInternalIncidentsByStage.code);
              this.notificationService.errorMessage('Failed to get records');
            }
          }
        })
      );
    })
  ), { dispatch: false });

  // getAllIncidentResponsesByIncident
  getAllIncidentResponsesByIncident$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.getAllIncidentResponsesByIncident),
    switchMap((action) => {
      return this.apollo.query({
        query: fromGraphql.GET_ALL_INCIDENT_RESPONSES_BY_INCIDENT,
        fetchPolicy: 'network-only',
        variables: {
          incidentUuid: action.incidentUuid,
          pageparam: action?.pageparam ? action.pageparam : specialInitializedPageableParameter,
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while fetching Incidents responses'),
        map(({ data }: any) => {
          if (data) {
            if (data.getAllIncidentResponsesByIncident) {
              const result = data?.getAllIncidentResponsesByIncident;
              if (result?.content?.length > 0) {
                this.paginationService.setPage(result);
                this.store.dispatch(fromActions.loadIncidentResponses({ incidentResponses: result?.content }));
              }
            } 
          }
        })
      );
    })
  ), { dispatch: false });
  
    // updateIncidentResponseToSeen
    updateIncidentResponseToSeen$ = createEffect(() => this.actions$.pipe(
      ofType(fromActions.updateIncidentResponseToSeen),
      switchMap((action) => {
          return this.apollo.mutate({
              mutation: fromGraphql.UPDATE_INCIDENT_RESPONSE_TO_SEEN,
              variables: {
                  incidentResponseUuid: action.incidentResponseUuid
              }
          }).pipe(
              this.notificationService.catchError('Problem occurred while clearing second level added response'),
              map(({data}: any) => {
                  if (data) {
                      const result = data.updateIncidentResponseToSeen;
                      if (result?.code === 9000) {
                          this.store.dispatch(fromActions.getAllIncidentResponsesByIncident({incidentUuid: action.incident.uuid, pageparam: specialInitializedPageableParameter}));
                      } else {
                          this.notificationService.determineSnackbar(data);
                      }
                  }
              })
          );
      })
  ), {dispatch: false});

  constructor(
    private actions$: Actions,
    private apollo: Apollo,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private responseCodeService: ResponseCodeService,
    private router: Router,
    private paginationService: PaginationService,
  ) {
  }

  // handleResponse(data) {
  //   if (data.code === 9000) {
  //     this.store.dispatch(fromActions.upsertIncident({ incident: data.data }));
  //     return this.notificationService.successMessage('Action completed Successful');
  //   } else {
  //     return this.handleError(data);
  //   }
  // }

  handleError(data) {
    const responseCode = this.responseCodeService.getCodeDescription(data.code);
    const message = responseCode[0].code + ' : ' + responseCode[0].description;
    return this.notificationService.errorMessage(message);
  }

}
