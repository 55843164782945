import {AppState} from './../../../reducers/index';
import {createSelector} from '@ngrx/store';
import * as fromInstitutionReducer from './institution.reducer';
import {Institution} from './institution.model';

export const currentState = (state: AppState) => (state[fromInstitutionReducer.institutionsFeatureKey]);

export const selectAllInstitutions = createSelector(currentState, fromInstitutionReducer.selectAll);
// export const selectAllInstitutionServices = createSelector(currentState, fromInstitutionReducer.getInstitutionServices);

export const selectTotalInstitutions = createSelector(currentState, fromInstitutionReducer.selectTotal);

export const selectModifiedInstitutions = createSelector(selectAllInstitutions, (institutions) => {
    return institutions.map((institution) => {

        return {
            ...institution,
            // service:institution?.service.name,
            institution: institution?.name,
            acronmy: institution?.acronym,
            iSpublic: institution?.institutionType == 'PRIVATE_INSTITUTION' ? 'PRIVATE' : 'PUBLIC',
            iSprovider: institution?.helpdeskServiceProvider ? 'HELPDESK PROVIDER' : '',
            isActive: institution?.active ? 'ACTIVE' : 'IN-ACTIVE',
            incident: institution?.incidentHandlingMethodType,
            modifiedcanReportToBranches: institution?.canReportToBranches ? 'YES' : 'NO',
            // displayCode: institution?.institutionCode ? institution?.institutionCode : '',
            // ldapUuid: institution?.ldapConfig ? institution.ldapConfig?.uuid : null,
            displayCode: institution?.institutionType == 'PRIVATE_INSTITUTION' ? institution?.institutionCode : institution?.gispCode,
        };
    });
});

export const selectModifiedClientInstitutions = createSelector(selectModifiedInstitutions, (institutions: Institution[]) => {

    return institutions.map((institution) => {
        return {
            ...institution,
            clientName: institution?.clientInstitution?.name,
            acronym: institution?.clientInstitution?.acronym,
            telephone: institution?.clientInstitution?.telephone,
            website: institution?.clientInstitution?.website,
            physicalAddress: institution?.clientInstitution?.physicalAddress,
            postalAddress: institution?.clientInstitution?.postalAddress,
            iSpublic: institution?.clientInstitution?.institutionType === 'PRIVATE_INSTITUTION' ? 'PRIVATE' : 'PUBLIC',
            isActive: institution?.active ? 'ACTIVE' : 'IN-ACTIVE',
            modifiedcanReportToBranches: institution?.clientInstitution?.canReportToBranches ? 'YES' : 'NO',
        };
    });
});


export const selectInstitutionByUuid = (params: { uuid: string }) => createSelector(
    selectModifiedInstitutions,
    (institutions: Institution[]) => {
        return institutions.find((institution) => institution.uuid === params.uuid);
    }
);

export const selectInstitutionById = (params: { id: number }) => createSelector(
    selectModifiedInstitutions,
    (institutions: Institution[]) => {
        return institutions.find((institution) => institution.id === params.id);
    }
);

export const selectBlockedInstitutionInstitution = (params: { isDeleted: boolean }) => createSelector(
    selectModifiedInstitutions,
    (institutions: Institution[]) => {
        return institutions.filter((institution) => institution.deleted === params.isDeleted);
    }
);

export const selectChildInstitutionInstitution = (params: { isDeleted: boolean, parent: string }) => createSelector(
    selectModifiedInstitutions,
    (institutions: Institution[]) => {
        return institutions.filter((institution) => institution.deleted === params.isDeleted && institution.parentEntity?.uuid === params.parent);
    }
);
export const selectActiveInstitution = (params: { isActive: boolean, isDeleted: boolean }) => createSelector(
    selectModifiedInstitutions,
    (institutions: Institution[]) => {
        return institutions.filter((institution) => institution.active === params.isActive && institution.deleted === params.isDeleted);
    }
);


export const selectModifiedInstitutionNeedsConfimation = createSelector(selectAllInstitutions, (institutions) => {
    return institutions.map((institution) => {

        return {
            ...institution,
            service: institution?.service?.name,
            institution: institution?.clientInstitution?.name,
            // institutionCode:
            acronmy: institution?.acronym,
            isActive: institution?.confirmed ? 'CONFIRMED' : 'NOT-CONFIRMED'
// actions:institutions.
        };
    });
});
