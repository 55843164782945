import {Component, Input, OnInit} from '@angular/core';
import {fadeIn, fadeOut} from '../../animations/router-animation';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-multi-columns',
  templateUrl: './multi-columns.component.html',
  styleUrls: ['./multi-columns.component.scss'],
  animations: [
    fadeIn,
    fadeOut,
    trigger('columSize', [
      state('small', style({
        width: '32%',
        opacity: 1
      })),
      state('large', style({
        width: '49.5%',
        opacity: 1
      })),
      state('lastlarge', style({
        width: '49.7%',
        opacity: 1,
        marginRight: 0
      })),
      state('zero', style({
        width: '0%',
        opacity: 0,
        padding: 0,
        marginRight: 0
      })),
      state('smaller', style({
        width: '32%',
        opacity: 1
      })),
      state('last', style({
        width: '34.8%',
        opacity: 1,
        marginRight: 0
      })),
      transition('small <=> large', animate('300ms ease-in')),
      transition('small <=> zero', animate('300ms ease-in')),
      transition('small <=> smaller', animate('300ms ease-in')),
      transition('small <=> last', animate('300ms ease-in')),
      transition('zero <=> large', animate('300ms ease-in')),
      transition('zero <=> smaller', animate('300ms ease-in')),
      transition('zero <=> last', animate('300ms ease-in')),
      transition('lastlarge <=> small', animate('300ms ease-in')),
      transition('lastlarge <=> smaller', animate('300ms ease-in')),
      transition('lastlarge <=> zero', animate('300ms ease-in')),
      transition('lastlarge <=> last', animate('300ms ease-in')),
      transition('lastlarge <=> large', animate('300ms ease-in')),
      transition('last <=> smaller', animate('300ms ease-in')),
    ])
  ]
})
export class MultiColumnsComponent implements OnInit {
  @Input() column_size = 'large';
  constructor() { }

  ngOnInit() {
  }

}
