import gql from 'graphql-tag';

export const roleFields = `
active
applyToOtherInstitution
createdAt
createdBy
deleted
displayName
global
id
name
permissionList{
  id
  active
  description
  displayName
  name
}
updatedAt
updatedBy
uuid
institution{
  id
  uuid
  name
}
`;

export const roleFewFields = `
active
uuid
applyToOtherInstitution
createdAt
createdBy
deleted
displayName
global
id
name
institution{
  id
  uuid
  name
}
`;
export const LIST_OF_ALL_ROLES_PAGEABLE = gql`
    query allRolesPageable($institutionUuid: String, $pageParam: PageableParamInput){
        allRolesPageable(institutionUuid: $institutionUuid,pageParam:$pageParam){
            content{
                ${roleFewFields}
            }
            first
            last
            number
            numberOfElements
            pageable{
                pageNumber
                pageSize
            }
            size
            totalElements
            totalPages
        }
    }
`;


export const LIST_OF_ALL_ROLES = gql`
    query listOfRoles{
        listOfRoles{
            ${roleFewFields}
        }
    }
`;
export const CREATE_UPDATE_ROLE = gql`
    mutation createUpdateRole($role: RoleDtoInput){
        createUpdateRole(role:$role){
            data{
                ${roleFewFields}
            }
            code
            message
            status
        }
    }
`;


export const GET_ROLE_BY_ID = gql`
    query getRoleById($id: Long){
        getRoleById(id:$id){
            code
            data{
                ${roleFields}
            }
            status
            message
        }
    }
`;


//   query getPermissionsByRoleId($roleId: Long){
//     getPermissionsByRoleId(roleId:$roleId){
//       code
//       data{

//       }
//       error
//       message
//     }
//   }

export const EXTERNAL_ROLES = gql`
    query externalRoles {
        externalRoles{
            ${roleFields}
        }
    }

`;

export const GET_ROLE_BY_NAME = gql`
    query getRoleByName($name: String){
        getRoleByName(name:$name){
            data{
                ${roleFields}
            }
            code
            message
            status
        }
    }
`;


export const DELETE_ROLE = gql`
    mutation deleteRole($roleUuid: String) {
        deleteRole(roleUuid: $roleUuid) {
            message
            code
            data
        }
    }
`;

export const COPY_ROLES = gql`
    mutation copyRoleToOtherInstitution($institutionUuid: String, $roleUuid: [String]) {
        copyRoleToOtherInstitution(institutionUuid: $institutionUuid, roleUuid: $roleUuid) {
            message
            code
            dataList{
                ${roleFewFields}
            }
        }
    }
`;
