<app-modal-header [title]="title" (closeModal)="closeModal($event)"></app-modal-header>
<div row class="upload">
    <div class="col-md-8">
     <input type="file" (change)="selectedData($event)" multiple="false"  class="input"/>
    </div>
    <div class="col-md-4">
        <div class="inner-links">
            <button *ngIf="dataSet" class="custom-btn green-btn" (click)="submit()">
                 Upload Customers
            </button>
        </div>
    </div>
</div>
<div class="row" *ngIf="dataSet">
    <div class="col-md-12">
        <div class="added-roster">
            <app-data-table [columnHeader]="tableColumns" [tableData]="items$" [hasActionsColumn]="false">
                
            </app-data-table>
        </div>
    </div>
</div>