import {Injectable} from '@angular/core';
import {ResponseCodeService} from '../../../../services/response-code.service';
import {AppState} from '../../../reducers/index';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {Apollo} from 'apollo-angular';
import {NotificationService} from 'src/app/services/notification.service';
import * as fromActions from './administrative-area.actions';
import * as fromGraphql from './administrative-area.graphql';
import {map, switchMap} from 'rxjs/operators';
import {PaginationService} from 'src/app/services/pagination.service';
import {initializedPageableParameter} from 'src/app/interfaces/global.interface';


@Injectable()
export class AdministrativeAreaEffects {
    // List All Administrative Area
    listAdministrativeArea$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.listAllAdministrativeAreas),
        switchMap((action) => {
            return this.apollo.query({
                query: action?.fewFields ? fromGraphql.GET_ALL_ADMINISTRATIVE_AREA_LIST_FEW_FIELDS : fromGraphql.GET_ALL_ADMINISTRATIVE_AREA_LIST,
                variables: {
                    pageableParam: action?.pageableParam ? action.pageableParam : initializedPageableParameter,
                },
                fetchPolicy: 'network-only',
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching Administrative Areas'),
                map(({data}: any) => {
                    if (data) {
                        const result = data?.administrativeAreaAll;
                        this.paginationService.setPage(result);
                        this.store.dispatch(fromActions.loadAdministrativeAreas({administrativeAreas: result?.content}));
                    }
                })
            );
        })
    ), {dispatch: false});


//  // Create/Save Branch
    createAdministrativeArea$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.createAdministrativeArea),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.SAVE_AND_UPDATE_ADMINISTRATIVE_AREA,
                variables: {
                    administrativeAreaInput: action.administrativeArea
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while saving Administrative Area'),
                map(({data}: any) => {
                    if (data) {
                        const response = this.responseCodesService?.getCodeDescription(data.createUpdateAdministrativeArea.code);
                        if (data.createUpdateAdministrativeArea.code === 9000) {
                            this.store.dispatch(fromActions.upsertAdministrativeArea({administrativeArea: data.createUpdateAdministrativeArea.data}));
                            this.notificationService.determineSnackbar(response[0]);
                        } else {
                            this.notificationService.determineSnackbar(response[0]);
                        }
                    }
                })
            );
        })
    ), {dispatch: false});


// List All Administrative Area 
//     listAllAdministrativeAreasByType$ = createEffect(() => this.actions$.pipe(
//         ofType(fromActions.listAllAdministrativeAreasByType),
//         switchMap((action) => {
//             return this.apollo.query({
//                 query: fromGraphql.GET_ADMINISTRATIVE_AREAS_BY_TYPE,
//                 variables: {
//                     type: action.areaType,
//                     pageableParam: action?.pageableParam
//                 },
//                 fetchPolicy: 'network-only',
//             }).pipe(
//                 this.notificationService.catchError('Problem occurred while fetching Administrative Areas By Type'),
//                 map(({data}: any) => {
//                     if (data) {
//                         this.store.dispatch(fromActions.loadAdministrativeAreas({administrativeAreas: data.administrativeAreaByType}));
//                     }
//                 })
//             );
//         })
//     ), {dispatch: false});


//   // Detele AdmiistrativeArea
    activateDeactivateAdmiistrativeArea$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.activateDeactivateAdministrativeArea),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.ACTIVATE_DEACTIVATE_ADMINISTRATIVE_AREA,
                variables: {
                    uuid: action.uuid,
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while deleting department'),
                map(({data}: any) => {
                    if (data) {
                        if (data.activateDeactivateAdministrativeArea.code === 9000) {
                            // this.store.dispatch(fromActions.deleteAdmiistrativeArea({ id: action.id }));
                            this.store.dispatch(fromActions.upsertAdministrativeArea({administrativeArea: data.activateDeactivateAdministrativeArea.data}));

                            this.notificationService.successMessage('Deleted Successfully');
                        } else {
                            this.notificationService.errorMessage(data.activateDeactivateAdministrativeArea);
                        }
                    }
                })
            );
        })
    ), {dispatch: false});


//   // Detele AdmiistrativeArea
    removeAdmiistrativeArea$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.removeAdministrativeArea),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.DELETE_ADMINISTRATIVE_AREA,
                variables: {
                    uuid: action.uuid,
                    id: action.id
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while deleting department'),
                map(({data}: any) => {
                    if (data) {
                        if (data.deleteAdministrativeArea.code === 9000) {
                            // this.store.dispatch(fromActions.deleteAdmiistrativeArea({ id: action.id }));
                            this.store.dispatch(
                                fromActions.deleteAdministrativeArea({id: action.id})
                            );
                            this.notificationService.successMessage('Deleted Successfully');
                        } else {
                            this.notificationService.errorMessage(data.deleteAdministrativeArea);
                        }
                    }
                })
            );
        })
    ), {dispatch: false});


    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private store: Store<AppState>,
        private notificationService: NotificationService,
        private responseCodesService: ResponseCodeService,
        private paginationService: PaginationService,
    ) {
    }


}
