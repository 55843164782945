import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { InstitutionPipe } from "src/app/shared/pipes/institution.pipe";
import { Institution } from "src/app/store/entities/settings/institution/institution.model";
import * as Highcharts from "highcharts";
import HC_exporting from "highcharts/modules/exporting";
import { SettingsService } from "src/app/services/settings.service";
import { ReportsServicesService } from "src/app/modules/reports/reports-services.service";
import { ChartOptionsColumn, ChartOptionsDonut, ChartOptionsLine } from "src/app/interfaces/global.interface";
import { ChartComponent } from "ng-apexcharts";
import { InstitutionStorageDto } from "src/app/store/entities/settings/institution/institution.model";
import { padLeft } from "src/app/shared/utils/functions";
import { getLastDateOfMonth } from "src/app/shared/utils/date.utils";
import {
  StatisticsTotalResponseDto,
  StatisticsGraphResponseDto,
  StatisticsSectionWiseTotalResponseDto,
  CategoryWiseIssueStatisticsResponseDto,
  StatusWiseIssueStatisticsResponseDto,
} from "src/app/store/entities/incident/incident.model";
import { StorageService } from "src/app/services/storage.service";

@Component({
  selector: "app-institutional-dashboard",
  templateUrl: "./institutional-dashboard.component.html",
  styleUrls: ["./institutional-dashboard.component.scss"],
  providers: [InstitutionPipe],
})
export class InstitutionalDashboardComponent implements OnInit, OnDestroy {
  institutionUuid: string;
  title: string;
  searchFields = [];

  chartOptions?: {};
  pietOptions?: {};
  priorities: string[] = [];
  severities: string[] = [];
  Highcharts = Highcharts;
  summeryReport: Observable<any>;
  incidentReport: { incident: number; assets: number };
  months: string[] = [];
  incidentCounts: any = [];
  issueCountStatistics: StatisticsTotalResponseDto[] = [];
  startDate: { year?: number; yearShort?: string; month?: number; monthShort?: string; day?: number };
  endDate: { year?: number; yearShort?: string; month?: number; monthShort?: string; day?: number };
  topPendingServices: StatisticsTotalResponseDto[];
  storageUsage: InstitutionStorageDto;
  groupedBySection = new Map<string, { category: string; pending: number; closed: number; total: number }[]>();
  groupedIssueCategories: { category: string; closed: number; open: number; today: number }[];
  totalReportedIssues: number = 0;
  totalClosedIssues: number = 0;
  totalOpenIssues: number = 0;
  totalIssuesClosedToday: number = 0;
  reportedIssuesData: StatisticsGraphResponseDto;
  sectionWiseIssuesData: StatisticsSectionWiseTotalResponseDto[];
  issueTypes: string[];
  sections: string[];
  pendingIssuesSubtitle: any;
  reportedIssuesSubtitle: any;

  @Input() inputInstitution: Institution;

  @ViewChild("chart") chart: ChartComponent;
  public openIncidentsOptions: Partial<ChartOptionsLine>;
  public closedIncidentsOptions: Partial<ChartOptionsColumn>;
  public internalVsExternalIncidentsChartOptions?: {};
  public incidentsByPipelineChartOptions: Partial<ChartOptionsDonut>;

  constructor(
    private route: ActivatedRoute,
    private reportsServicesService: ReportsServicesService,
    private settingsService: SettingsService,
    private storageService: StorageService
  ) {}

  ngOnInit(): void {
    this.title = "DASHBOARD";

    this.institutionUuid =
      this.route?.snapshot?.paramMap.get("institutionID") ??
      this.inputInstitution?.uuid ??
      this.storageService.getItem("institutionUuid");
    this.loadData();
  }

  loadData() {
    localStorage.removeItem("selectedInstitution");
    localStorage.setItem("selectedInstitution", JSON.stringify(this.institutionUuid));

    this.setUp();
    HC_exporting(Highcharts);

    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 300);
  }

  ngOnDestroy() {}

  public async setUp() {
    this.renderStorageStatistics();

    this.renderGenenalStatisticsFigures();

    this.setUpRequestDto();

    this.renderServiceWisePendingIssuesTable();

    this.renderIssuesStatusGraph();

    this.getIssueStatsGraphData().then((reportedIssuesData) => {
      this.renderReportedIssuesGraph(reportedIssuesData.lineGraphList);
      this.renderPendingIssuesGraph(reportedIssuesData.histogramGraphList);
      this.renderExternalVsInternalIssuesGraph(reportedIssuesData.pieChatGraphList);
    });

    this.renderSectionWiseIssuesTable();

    this.renderClosedIssuesFigures();
  }

  public async renderGenenalStatisticsFigures() {
    let totalIssueStatistics = await this.reportsServicesService.getTotalIssueStatistics(this.institutionUuid);

    totalIssueStatistics = totalIssueStatistics.map((stat) => {
      if (stat.title === "Total Users") {
        return { rowCount: stat.rowCount, title: "Active Users" };
      }

      return { rowCount: stat.rowCount, title: stat.title };
    });

    if (totalIssueStatistics) {
      this.issueCountStatistics = [...totalIssueStatistics];
    }
  }

  public async renderStorageStatistics() {
    let institutionStorageUsage = await this.reportsServicesService.getInstitutionalStorageUsage(this.institutionUuid);

    if (institutionStorageUsage) {
      this.storageUsage = {
        ...institutionStorageUsage,
        folderSizeInByte: this.settingsService.convertBytesToGB(institutionStorageUsage.folderSizeInByte),
        usagePercentage: Math.floor(
          this.settingsService.convertBytesToGB(institutionStorageUsage.folderSizeInByte) /
            institutionStorageUsage.storageSizeGivenInGb
        ),
      };
    }
  }

  public async renderReportedIssuesGraph(data: { rowCount: number; subTitle: string; title: string }[]) {
    const categories = data
      .map((dt) => {
        return dt.subTitle;
      })
      .filter((value, index, self) => self.indexOf(value) === index);

    let categoryWiseStats: { name: string; data: number[] }[];

    categoryWiseStats = categories.map((category) => {
      const categoryData = this.months.map((month) => {
        return data.filter((item) => item.title === month).find((stat) => stat.subTitle === category)?.rowCount ?? 0;
      });

      return { name: category, data: categoryData };
    });

    this.openIncidentsOptions = {
      series: categoryWiseStats,

      chart: {
        height: 250,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 2,
        curve: "straight",
      },
      legend: {
        tooltipHoverFormatter: function (val, opts) {
          return val + " - " + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + "";
        },
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6,
        },
      },
      title: {
        text: `Reported Issues (${this.startDate.day} ${this.startDate.monthShort} ${this.startDate.yearShort} - ${this.endDate.day} ${this.endDate.monthShort} ${this.endDate.yearShort})`,
        align: "center",
      },
      subtitle: {
        text: `Total : --`,
        align: "center",
        offsetY: 60,
        floating: true,
        style: {
          fontSize: "1.5em",
          fontWeight: "700",
          fontFamily: "Roboto, 'Helvetica Neue', sans-serif",
          color: "#6c757d",
        },
      },
      grid: {
        borderColor: "#f1f1f1",
      },
      xaxis: {
        categories: this.months,
      },
      yaxis: {
        title: {
          text: "Reported Issues",
        },
      },
    };
  }

  public async renderPendingIssuesGraph(data: { rowCount: number; subTitle: string; title: string }[]) {
    const severities = data
      .map((dt) => {
        return dt.subTitle;
      })
      .filter((value, index, self) => self.indexOf(value) === index);

    const severityWiseStats = severities.map((severity) => {
      const severityData = this.months.map((month) => {
        return data.filter((item) => item.title === month).find((stat) => stat.subTitle === severity)?.rowCount ?? 0;
      });

      return { name: severity, data: severityData };
    });

    this.closedIncidentsOptions = {
      series: severityWiseStats,
      chart: {
        type: "bar",
        height: 250,
      },
      title: {
        text: `Pending Issues (${this.startDate.day} ${this.startDate.monthShort} ${this.startDate.yearShort} - ${this.endDate.day} ${this.endDate.monthShort} ${this.endDate.yearShort})`,
        align: "center",
      },
      subtitle: {
        text: `Total : --`,
        align: "center",
        offsetY: 60,
        floating: true,
        style: {
          fontSize: "1.5em",
          fontWeight: "700",
          fontFamily: "Roboto, 'Helvetica Neue', sans-serif",
          color: "#6c757d",
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: this.months,
      },
      yaxis: {
        title: {
          text: "Pending Issues",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return `${val}`;
          },
        },
      },
    };
  }

  public async renderExternalVsInternalIssuesGraph(data: { rowCount: number; subTitle: string; title: string }[]) {
    const issuesDistributionData = data.map((data) => {
      if (data.title === "INTERNAL") {
        return {
          name: data.title,
          y: 12.8,
          sliced: true,
          selected: true,
        };
      }
      return [data.title, data.rowCount];
    });

    this.internalVsExternalIncidentsChartOptions = {
      chart: {
        type: "pie",
        options3d: {
          enabled: true,
          alpha: 45,
          beta: 0,
        },
      },
      title: {
        text: "Internal Tickets VS External Tickets",
      },
      subtitle: {
        text: "Graph",
      },
      tooltip: {
        split: true,
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: true,
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          depth: 35,
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b> ",
            style: {
              color: (Highcharts.theme && Highcharts.theme.responsive) || "black",
            },
          },
        },
      },
      series: [
        {
          type: "pie",
          name: "Incidents",
          data: issuesDistributionData,
        },
      ],
      colors: ["#008FFB", "#199925"],
    };
  }

  public async renderClosedIssuesFigures() {
    const categoryWiseIssueStats: CategoryWiseIssueStatisticsResponseDto[] =
      await this.reportsServicesService.getCategoryWiseIssueStatistics({
        institutionUuid: this.institutionUuid,
      });

    const categories = categoryWiseIssueStats
      .map((dt) => {
        return dt.title;
      })
      .filter((value, index, self) => self.indexOf(value) === index);

    const groupedIssueCategories: { category: string; closed: number; open: number; today: number }[] = categories.map(
      (type) => {
        return { category: type, closed: 0, open: 0, today: 0 };
      }
    );

    categoryWiseIssueStats?.forEach((stat) => {
      const cat = groupedIssueCategories.find((entry) => entry.category === stat.title);
      if (cat && stat.incidentStatus === "OPEN") {
        cat.open = cat.open + stat.rowCount;
      } else if (cat && stat.incidentStatus === "CLOSED") {
        cat.closed = cat.closed + stat.rowCount;
        cat.today = cat.today + stat.todayCount;
      }
    });

    this.groupedIssueCategories = [...groupedIssueCategories];

    this.totalClosedIssues = this.groupedIssueCategories
      .map((cat) => cat.closed)
      .reduce((aggregate: number, current: number) => aggregate + current, 0);

    this.totalOpenIssues = this.groupedIssueCategories
      .map((cat) => cat.open)
      .reduce((aggregate: number, current: number) => aggregate + current, 0);

    this.pendingIssuesSubtitle = {
      text: `Total : ${this.totalOpenIssues}`,
      align: "center",
      offsetY: 60,
      floating: true,
      style: {
        fontSize: "1.5em",
        fontWeight: "700",
        fontFamily: "Roboto, 'Helvetica Neue', sans-serif",
        color: "#6c757d",
      },
    };

    this.totalIssuesClosedToday = this.groupedIssueCategories
      .map((cat) => cat.today)
      .reduce((aggregate: number, current: number) => aggregate + current, 0);

    this.totalReportedIssues = this.totalClosedIssues + this.totalOpenIssues;

    this.reportedIssuesSubtitle = {
      text: `Total : ${this.totalReportedIssues}`,
      align: "center",
      offsetY: 60,
      floating: true,
      style: {
        fontSize: "1.5em",
        fontWeight: "700",
        fontFamily: "Roboto, 'Helvetica Neue', sans-serif",
        color: "#6c757d",
      },
    };
  }

  public async renderIssuesStatusGraph() {
    let result: StatusWiseIssueStatisticsResponseDto =
      await this.reportsServicesService.providerGeneralDashboardReportsData(this.institutionUuid);

    let pieChartData: {}[] = [];

    result?.pieChartDataList?.forEach((pie) => {
      if (pie?.incidentStatus === "ON_PROGRESS") {
        pieChartData.push({
          name: pie.incidentStatus,
          y: pie.incidentCount,
          sliced: true,
          selected: true,
        });
      } else {
        pieChartData?.push([pie.incidentStatus, pie.incidentCount]);
      }
    });

    this.incidentReport = {
      incident: result?.totalYearlyIncidents ?? 0,
      assets: result?.totalAssets ?? 0,
    };

    this.pietOptions = {
      chart: {
        type: "pie",
        options3d: {
          enabled: true,
          alpha: 45,
          beta: 0,
        },
      },
      title: {
        text: "General Issues Data Presentation",
      },
      subtitle: {
        text: "Graph",
      },
      tooltip: {
        split: true,
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: true,
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          depth: 35,
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b> ",
            style: {
              color: (Highcharts.theme && Highcharts.theme.responsive) || "black",
            },
          },
        },
      },
      series: [
        {
          type: "pie",
          name: "Incidents",
          data: pieChartData,
        },
      ],
      colors: ["#8D301F", "#D1D9E2", "rgb(0, 128, 12)", "#A9CCE3", "#1F618D"],
    };
  }

  public async renderServiceWisePendingIssuesTable() {
    const serviceData: StatisticsTotalResponseDto[] =
      await this.reportsServicesService.getTotalIssueServiceWiseStatisticsGraphData({
        institutionUuid: this.institutionUuid,
        startDate: `${this.startDate.year}-${padLeft(this.startDate.month, 2)}-01`,
        endDate: `${this.endDate.year}-${padLeft(this.endDate.month, 2)}-${this.endDate.day}`,
      });

    function compareNumbers(a: StatisticsTotalResponseDto, b: StatisticsTotalResponseDto) {
      return b.rowCount - a.rowCount;
    }

    this.topPendingServices = serviceData.slice().sort(compareNumbers).slice(0, 5);
  }

  public async renderSectionWiseIssuesTable() {
    const tmpGroupedBySection = new Map<
      string,
      { category: string; pending: number; closed: number; total: number }[]
    >();

    const sectionWiseIssuesData = await this.reportsServicesService.getTotalIssueSectionWiseStatisticsGraphData({
      institutionUuid: this.institutionUuid,
    });

    const sections = sectionWiseIssuesData
      .map((dt) => {
        return dt.name;
      })
      .filter((value, index, self) => self.indexOf(value) === index);

    const categories = sectionWiseIssuesData
      .map((dt) => {
        return dt.issueCategory;
      })
      .filter((value, index, self) => self.indexOf(value) === index);

    sections.forEach((section) => {
      let tempCollection: { category: string; pending: number; closed: number; total: number }[] = [];

      categories.forEach((type) => {
        const pending: number =
          sectionWiseIssuesData.find((dt) => dt.name === section && dt.issueCategory === type)?.pending ?? 0;
        const closed: number =
          sectionWiseIssuesData.find((dt) => dt.name === section && dt.issueCategory === type)?.closed ?? 0;

        let d = {
          category: type,
          pending,
          closed,
          total: pending + closed,
        };

        tempCollection.push(d);
      });

      tmpGroupedBySection.set(section, tempCollection);
    });

    this.groupedBySection = new Map(tmpGroupedBySection);
  }

  public async getIssueStatsGraphData() {
    return this.reportsServicesService.getTotalIssueStatisticsGraphData({
      institutionUuid: this.institutionUuid,
      startDate: `${this.startDate.year}-${padLeft(this.startDate.month, 2)}-01`,
      endDate: `${this.endDate.year}-${padLeft(this.endDate.month, 2)}-${this.endDate.day}`,
    });
  }

  public setUpRequestDto() {
    const firstHalfMonths = ["Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const lastHalsMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun"];

    const datetimeMonthMap = {
      0: "Jan",
      1: "Feb",
      2: "Mar",
      3: "Apr",
      4: "May",
      5: "Jun",
      6: "Jul",
      7: "Aug",
      8: "Sep",
      9: "Oct",
      10: "Nov",
      11: "Dec",
    };

    const monthToNumberMap = {
      Jan: 1,
      Feb: 2,
      Mar: 3,
      Apr: 4,
      May: 5,
      Jun: 6,
      Jul: 7,
      Aug: 8,
      Sep: 9,
      Oct: 10,
      Nov: 11,
      Dec: 12,
    };

    let currentMonth = datetimeMonthMap[new Date().getMonth()];

    this.months = firstHalfMonths.includes(currentMonth) ? firstHalfMonths : lastHalsMonths;

    let year: number = new Date().getFullYear();
    let month: number = monthToNumberMap[this.months[0]];

    this.startDate = {
      year,
      yearShort: `${year}`.slice(2),
      month: month,
      monthShort: this.months[0],
      day: 1,
    };

    month = monthToNumberMap[this.months[this.months.length - 1]];

    this.endDate = {
      year,
      yearShort: `${year}`.slice(2),
      month: month,
      monthShort: this.months[this.months.length - 1],
      day: getLastDateOfMonth(year, month),
    };
  }
}
