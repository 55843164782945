import { Component, OnInit, EventEmitter, Output, ChangeDetectionStrategy, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { SettingsService } from 'src/app/services/settings.service';
import { FieldConfig } from '../field.interface';
@Component({
  selector: 'app-attachment',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './attachment.component.html',

  styles: [
    '.attachment{display: flex;  flex-direction: row; align-items: flex-end;}',
    '.photo{max-width: 100%}',
    'img{  border-radius: 5px; margin-right: 10px;  border: 4px solid #404dbc;}',
    'mat-progress-bar { margin-top: 5px; width: 120px; }',
    '.attach-button{width: 100%; height: 46px; }',
  ]
})
export class AttachmentComponent implements OnInit {
  @Output() fieldValue = new EventEmitter();
  @Output() viewAttachedAttachment = new EventEmitter();
  @Input() field?: FieldConfig;
  group: UntypedFormGroup = new UntypedFormGroup({});
  fileUploadedName?: string;
  selectedFile?: string | ArrayBuffer;
  uploadedImage: any;
  accept?: string;
  showPhoto = false;
  fileFormat: any;
  // @HostBinding('class') rowClass = 'col-md-6';

  private attachmentStr = new BehaviorSubject<string>('attachment');

  public attachmentStr$ = this.attachmentStr.asObservable();


  private updateAttachmentString(newattachmentStr: string) {
    this.attachmentStr.next(newattachmentStr);
  }
  constructor(private settings: SettingsService,) { }
  ngOnInit() {
    this.accept = this.field?.acceptType ? this.field.acceptType.join() : '';
    this.uploadedImage = this.field?.value;
    this.updateAttachmentString(this.uploadedImage);
    this.showPhoto = this.field?.acceptType ? this.field.acceptType.findIndex(item => item === '.jpg' || item === '.png') > -1 && this.field?.showPhoto : false;


    this.group?.reset
  }
  showAttachment() {
    // const showValue = true;
    // this.viewAttachedAttachment.emit({ showValue, field: this.field });
    this.settings.viewFile(this.uploadedImage, this.fileFormat, this.fileUploadedName);
  }

  onFileSelected(event: any) {
    const file = event.target?.files[0];    

    const extension = file?.name?.split('.')[1];    
    const fileSize = + parseFloat((file?.size / 1000000).toFixed(5));
    
    this.fileFormat = event.target.accept;
    const fileUploadedNameData = file?.name;
    if (fileUploadedNameData) {
      if (fileUploadedNameData.length > 58) {
        this.fileUploadedName = fileUploadedNameData.slice(0, 58) + '...';
      } else {
        this.fileUploadedName = fileUploadedNameData;
      }
    }
    const fileReader: FileReader = new FileReader();
    const self = this;
    fileReader.onloadend = (x) => {
      const attachmentFile = fileReader.result;
      this.uploadedImage = fileReader.result;
      this.updateAttachmentString(this.uploadedImage)
      self.group?.controls[this.field?.key as string]?.setValue(attachmentFile);
      let object: any  = {fileSize,extension,attachmentName: fileUploadedNameData };
      this.fieldValue.emit({ value: attachmentFile, field: this.field, object});
    };
    if (event.target.files[0]) {
      fileReader.readAsDataURL(file);
    }

  }

}
