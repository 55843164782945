import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { DynamicFormService } from 'src/app/shared/components/dynamic-forms-components/dynamic-form.service';
import { FieldConfig } from 'src/app/shared/components/dynamic-forms-components/field.interface';
import { AppState } from 'src/app/store/reducers';
import * as formConfigs from './add-user-form-fields';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { createUpdateUserAccount } from 'src/app/store/entities/settings/user/user.actions';
import { Institution } from 'src/app/store/entities/settings/institution/institution.model';
import { User } from 'src/app/store/entities/settings/user/user.model';
@Component({
  selector: 'app-add-institution-user',
  templateUrl: './add-institution-user.component.html',
  styleUrls: ['./add-institution-user.component.scss']
})
export class AddInstitutionUserComponent implements OnInit {
title: string;
departmentForm: UntypedFormGroup;
fields: FieldConfig[] = formConfigs.fields;
userDetails: {institution: Institution, user: User};


constructor(
  private store: Store<AppState>,
  private dynamicFormService: DynamicFormService,
  private dialogRef: MatDialogRef<AddInstitutionUserComponent>,
  
   @Inject(MAT_DIALOG_DATA) data,
) {
  this.userDetails = data;
}

ngOnInit(): void {
  this.title = this.userDetails?.user ? 'Edit Institution User Details' : 'Create Institution User';
  this.fields = this.fields.map(field => {
    return field;
  });
  this.departmentForm = this.dynamicFormService.createControl(this.fields, null);

  if (this.userDetails?.user) {     
    this.departmentForm.patchValue(this.userDetails?.user);

  }
}


async submitForm(user) {
  const data: any = {...user, institutionUuid: this.userDetails?.institution?.uuid
  };
  
  if (this.userDetails?.user) {
    const editData: any = {...user, uuid:this.userDetails?.user?.uuid
    };
    // edit formValue
    this.store.dispatch(createUpdateUserAccount({user: editData}));
  } else {
      this.store.dispatch(createUpdateUserAccount({user: data}));
  }
  
  this.closeModal(true);
}

closeModal(close: boolean): void {
    if (close) {
      this.dialogRef.close();
    }
  }
  
}
