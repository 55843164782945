import gql from 'graphql-tag';

export const requestPrioritysFields = `
active
createdAt
createdBy
deleted
description
id
title
updatedAt
updatedBy
uuid
`;

// resources




export const GET_MY_INSTITUTION_ALL_REQUEST_PRIORITYS = gql`
query getAllInstitutionChangeRequestPriority($pageParam: PageableParamInput){
  getAllInstitutionChangeRequestPriority(pageParam:$pageParam){
    content{
        ${requestPrioritysFields} 
    }
    first
    last
    number
    numberOfElements
    pageable{
        pageNumber
        pageSize
    }
    size
    totalElements
    totalPages
  }
}
`;


export const SAVE_UPDATE_REQUEST_PRIORITY = gql`
mutation createUpdateChangeRequestPriority($priority: ChangeRequestPriorityDtoInput){
  createUpdateChangeRequestPriority(priority:$priority){
    code
    status
    data{
      ${requestPrioritysFields}
    }
    dataList{
      ${requestPrioritysFields}
    }
    message
  }
}
`;


export const DELETE_REQUEST_PRIORITY = gql`
mutation deleteChangeRequestPriority($id: String){
  deleteChangeRequestPriority(id:$id){
    code
    status
    data{
      ${requestPrioritysFields}
    }
    dataList{
      ${requestPrioritysFields}
    }
    message
  }
}
`;








