import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Problem } from './problem.model';
import * as problemActions from './problem.actions';

export const problemFeatureKey = 'problems';

export interface State extends EntityState<Problem> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Problem> = createEntityAdapter<Problem>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(problemActions.addProblem,
    (state, action) => adapter.addOne(action.problem, state)
  ),
  on(problemActions.upsertProblem,
    (state, action) => adapter.upsertOne(action.problem, state)
  ),
  on(problemActions.addProblems,
    (state, action) => adapter.addMany(action.problems, state)
  ),
  on(problemActions.upsertProblems,
    (state, action) => adapter.upsertMany(action.problems, state)
  ),
  on(problemActions.updateProblem,
    (state, action) => adapter.updateOne(action.problem, state)
  ),
  on(problemActions.updateProblems,
    (state, action) => adapter.updateMany(action.problems, state)
  ),
  on(problemActions.deleteProblem,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(problemActions.deleteProblems,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(problemActions.loadProblems,
    (state, action) => {
      return adapter.setAll(action.problems, state);
    }
  ),
  on(problemActions.clearProblems,
    state => adapter.removeAll(state)
  ),
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
