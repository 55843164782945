import {StorageService} from './../services/storage.service';
import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {AuthService} from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
        private auth: AuthService,
        private storage: StorageService
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const currentClient = this.storage.getItem('currentClient');
        const passwordExpirationDate = this.storage.getItem('passwordExpirationDate');
        if (passwordExpirationDate) {
            this.auth.handlePasswordExpiration({passwordExpirationDate});
        }

        if (currentClient) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentClient}`,
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': '*'
                }
            });
            if (!request.url.includes('currentClient')) {
                request = request.clone({url: request.url + '?access_token=' + currentClient});
            }
        }
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {

                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                const data = {
                    reason: error && error.error?.reason ? error.error?.reason : '',
                    status: error?.status,
                    causedBy: error.error?.error_description
                };
                this.auth.collectFailedRequest(data);
                return throwError(error);
            }));
    }
}
