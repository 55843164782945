import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {Apollo} from 'apollo-angular';
import {map, switchMap} from 'rxjs/operators';
import {NotificationService} from 'src/app/services/notification.service';
import {PaginationService} from 'src/app/services/pagination.service';
import {ResponseCodeService} from 'src/app/services/response-code.service';
import {AppState} from '../../reducers';
import * as fromActions from './incident-resolution.actions';
import * as fromGraphql from './incident-resolution.graphql';


@Injectable()
export class IncidentResolutioneEffects {

// addIncidentResolution
    addIncidentResolution = createEffect(() => this.actions$.pipe(
        ofType(fromActions.addIncidentResolution),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.CREATE_INCIDENT_RESOLUTION,
                variables: {
                    rootCause: action.incidentResolution
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while adding resolution'),
                map(({data}: any) => {
                    if (data) {
                        let result: any = data?.addUpdateIncidentResolution;
                        if (result?.code === 9000) {
                            this.store.dispatch(fromActions.upsertIncidentResolution({incidentResolution: result?.data}));
                            if (action.incidentResolution?.uuid) {
                                this.notificationService.successMessage('Resolution edited successfullty');
                            } else {
                                this.notificationService.successMessage('Resolution added successfully');
                            }
                        } else {
                            return this.handleError(result);
                        }
                    }
                })
            );
        })
    ), {dispatch: false});

// getincidentResolutions
    getincidentResolutions$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getIncidentResolutions),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.GET_ALL_INCIDENT_RESOLUTIONS,
                fetchPolicy: 'network-only',
                variables: {
                    pageparam: action?.pageparam
                }
            }).pipe(
                this.notificationService.catchError('Problem while fetching Incidents resolutions'),
                map(({data}: any) => {
                    if (data) {
                        const result = data?.getAllInstitutionIncidentResolutions;
                        if (result?.content?.length > 0) {
                            this.paginationService.setPage(result);
                        }
                        this.store.dispatch(fromActions.loadIncidentResolutions({incidentResolutions: result?.content}));
                    }
                })
            );
        })
    ), {dispatch: false});

// removeincidentResolution
    removeincidentResolution$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.removeIncidentResolution),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.REMOVE_INCIDENT_RESOLUTION,
                variables: {
                    resolutionUuid: action.incidentResolution.uuid
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while deleting incident resolution'),
                map(({data}: any) => {
                    if (data) {
                        let result: any = data?.activateDeactivateIncidentResolution;
                        if (result?.code == 9000) {
                            this.store.dispatch(fromActions.deleteIncidentResolution({id: action?.incidentResolution?.id}));
                            this.notificationService.successMessage('Resolution deleted successfully');
                        } else {
                            return this.handleError(result);
                        }
                    }
                })
            );
        })
    ), {dispatch: false});


    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private store: Store<AppState>,
        private notificationService: NotificationService,
        private responseCodesService: ResponseCodeService,
        private router: Router,
        private paginationService: PaginationService,
    ) {
    }

    handleError(data) {
        const responseCode = this.responseCodesService.getCodeDescription(data.code);
        const message = responseCode[0].code + ' : ' + responseCode[0].description;
        return this.notificationService.errorMessage(message);
    }

}
