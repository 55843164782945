<mat-sidenav-container class="sidenav-container">
    <mat-sidenav-content>
        <!-- HEADER START HERE -->
        <mat-toolbar color="primary">
            <div class="ega">
                <h2 class="title">Sugar Board Of Tanzania</h2>
                <p mat-hint class="subtitle">ServiceDesk</p>
            </div>


            <!-- right-panel -->
            <div class="right-panel">
                <button routerLink="/welcome" class="notifications">
                  Home
                </button>
                <button class="notifications" routerLink="/login">
                  Login
                </button>
                <!-- <a class="notifications">
                  <mat-icon matBadge="8">notifications</mat-icon>
                </a> -->
            </div>
            <!-- end of right-panel -->

        </mat-toolbar>
        <!-- HEADER END HERE -->
    </mat-sidenav-content>
</mat-sidenav-container>