import { DatePipe } from "@angular/common";
import { FormGroup } from "@angular/forms";
import { map } from "highcharts";
import { indexOf } from "lodash";
import { Observable } from "rxjs";

export function enumToObjectArray(enum_obj: any): { name: string; key: string }[] {
  var array = [];
  for (const key in enum_obj) {
    array.push({ name: key, value: enum_obj[key] });
  }
  return array;
}

export function enumToArray(enum_obj: any): any[] {
  var array = [];
  for (const key in enum_obj) {
    array.push(enum_obj[key]);
  }
  return array;
}

export function hasSameProps(obj1, obj2) {
  return Object.keys(obj1).every(function (prop) {
    return obj2.hasOwnProperty(prop);
  });
}

export function hasAllPropsIn(obj1, obj2) {
  let same = true;
  if (typeof obj2 !== "object" || !obj2 || !obj1) {
    return false;
  }
  Object.keys(obj1).forEach((key) => {
    if (!obj1[key]) {
      return false;
    }
    same = same && obj1[key] === obj2[key];
  });
  return same;
}

export function isObjectEmpty(obj) {
  return Object.keys(obj).length === 0;
}

export function copyFields(obj2, fields: any[]) {
  let obj1: any = {};

  if (typeof obj2 !== "object" || !obj2) {
    return;
  }
  fields.forEach((key) => {
    if (!obj2[key]) {
      return;
    }
    obj1[key] = obj2[key];
  });
  return obj1;
}

export function formatPhoneNumber(phone) {
  let str = phone?.toString();
  if (!(str?.length > 0) || !phone) {
    return phone;
  }
  if (str.indexOf("0") === 0 || str.indexOf("+") === 0) {
    phone = setCharAt(str, 0, "255");
  }
  return phone;
}

export function setCharAt(str, index, chr) {
  if (index > str.length - 1) {
    return str;
  }
  return str.substring(0, index) + chr + str.substring(index + 1);
}

export function formatNumber(number, separator?) {
  return number.toString().replace(/\B(?=(\d3)+(?!\d))/g, separator ?? ",");
}

export function fieldsArrayFromObj(obj) {
  let fields: { key: string; value: any; name: string }[] = [];

  if (typeof obj !== "object" || !obj) {
    return [];
  }
  Object.keys(obj).forEach((key) => {
    fields.push({ key: key, value: obj[key], name: key });
  });
  return fields;
}

export function truncateString(str, num) {
  if (!str) {
    return str;
  }

  if (str.length > num) {
    return str.slice(0, num) + "...";
  } else {
    return str;
  }
}

export function getFirstPresentKeyValue(object, keys): any {
  let value = undefined;
  keys.forEach((key) => {
    if (!!object[key]) {
      value = object[key];
    }
  });
  return value;
}

export function getFirstPresentKey(object, keys): any {
  let key = undefined;
  keys.forEach((key_) => {
    if (!!object[key_]) {
      key = key_;
    }
  });
  return key;
}

export function valueIsEither(value, values: any[]): boolean {
  return !!values.find((v) => v === value);
}

export function valueIncludesEither(value, values: string[]): boolean {
  let includes = false;
  if (typeof value !== "string") return false;
  values?.forEach(
    (v) =>
      (includes =
        includes ||
        (v?.includes(".")
          ? v?.toLowerCase()?.replace(".", "") === value?.toLowerCase()
          : value?.toLowerCase()?.includes(v?.toLowerCase())))
  );
  return includes;
}

export function hasProp(object, keys: string | string[]) {
  if (typeof keys !== "string" && !Array.isArray(keys)) return false;
  let hasKey = false;
  typeof keys === "string" ? (hasKey = !!object[keys]) : [...keys].forEach((key) => (hasKey = hasKey || !!object[key]));
  return hasKey;
}

export function valueIncludes(value, values: string[] | string): boolean {
  let includes = false;
  if (!value || typeof value !== "string") return false;
  if (Array.isArray(values))
    values?.forEach(
      (v) =>
        (includes =
          includes ||
          (v?.includes(".")
            ? v?.toLowerCase()?.replace(".", "") === value?.toLowerCase()
            : value?.toLowerCase()?.includes(v?.toLowerCase())))
    );
  else
    includes = values?.includes(".")
      ? values?.toLowerCase()?.replace(".", "") === value?.toLowerCase()
      : value?.toLowerCase()?.includes(values?.toLowerCase());

  return includes;
}

export function toCamelCase(str) {
  return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
}

export function replaceWords(str, find, replace = "") {
  if (Array.isArray(find)) find.forEach((s) => (str = str.replace(new RegExp(s, "g"), replace)));
  else str = str.replace(new RegExp(find, "g"), replace);
  return str;
}

export function valueIncluded(value, values: string[] | string): boolean {
  let includes = false;
  if (Array.isArray(values))
    values?.forEach(
      (v) =>
        (includes =
          includes ||
          (value?.includes(".")
            ? value?.toLowerCase()?.replace(".", "") === v?.toLowerCase()
            : v?.toLowerCase()?.includes(value?.toLowerCase())))
    );
  else
    includes = value?.includes(".")
      ? value?.toLowerCase()?.replace(".", "") === values?.toLowerCase()
      : values?.toLowerCase()?.includes(value?.toLowerCase());

  return includes;
}

export function valueIncludedInEither(value, values: string[]): boolean {
  let includes = false;
  values.forEach((v) => (includes = includes || v.toLowerCase().includes(value.toLowerCase())));
  return includes;
}

export function nameValueArr(y) {
  return y.map((x) => {
    return { name: x, value: x };
  });
}

export function camelToSpaced(y) {
  if (typeof y !== "string" || y.includes(" ")) {
    return y;
  }
  return y.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, "$1");
}

export function trim(s) {
  if (typeof s !== "string") return s;
  return s.replace(/\s+/g, "");
}

export function replaceSpaces(s, x = "") {
  if (typeof s !== "string") return s;
  return s.replace(/\s+/g, x);
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function toTitleCase(text) {
  if (!text) {
    return "";
  }
  var i, j, str, lowers, uppers;
  str = text.replace(/([^\W_]+[^\s-]*) */g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });

  // Certain minor words should be left lowercase unless
  // they are the first or last words in the string
  lowers = [
    "A",
    "An",
    "The",
    "And",
    "But",
    "Or",
    "For",
    "Nor",
    "As",
    "At",
    "By",
    "For",
    "From",
    "In",
    "Into",
    "Near",
    "Of",
    "On",
    "Onto",
    "To",
    "With",
  ];
  for (i = 0, j = lowers.length; i < j; i++) {
    str = str.replace(new RegExp("\\s" + lowers[i] + "\\s", "g"), function (txt) {
      return txt.toLowerCase();
    });
  }

  // Certain words such as initialisms or acronyms should be left uppercase
  uppers = ["Id", "Tv"];
  for (i = 0, j = uppers.length; i < j; i++) {
    str = str.replace(new RegExp("\\b" + uppers[i] + "\\b", "g"), uppers[i].toUpperCase());
  }

  return str;
}

export function getDaysBetween(StartDate, EndDate) {
  if (!StartDate?.split("-").length || !EndDate?.split("-").length) {
    return undefined;
  }

  // The number of milliseconds in all UTC days (no DST)
  const oneDay = 1000 * 60 * 60 * 24;

  // A day in UTC always lasts 24 hours (unlike in other time formats)
  const start = Date.UTC(EndDate.split("-")[0], EndDate.split("-")[1], EndDate.split("-")[2]);
  const end = Date.UTC(StartDate.split("-")[0], StartDate.split("-")[1], StartDate.split("-")[2]);

  // so it's safe to divide by 24 hours
  return (start - end) / oneDay;
}

export function singularize(word) {
  const endings = {
    ves: "fe",
    ies: "y",
    i: "us",
    zes: "",
    ses: "",
    es: "",
    s: "",
  };
  return word.replace(new RegExp(`(${Object.keys(endings).join("|")})$`), (r) => endings[r]);
}

export function countOccurrences(str, find) {
  if (typeof str !== "string") {
    return 0;
  }
  return str.split(find).length - 1;
}

var num =
  "zero one two three four five six seven eight nine ten eleven twelve thirteen fourteen fifteen sixteen seventeen eighteen nineteen".split(
    " "
  );
var tens = "twenty thirty forty fifty sixty seventy eighty ninety".split(" ");

export function numberTowords(n) {
  if (n < 20) {
    return num[n];
  }
  var digit = n % 10;
  if (n < 100) {
    return tens[~~(n / 10) - 2] + (digit ? "-" + num[digit] : "");
  }
  if (n < 1000) {
    return num[~~(n / 100)] + " hundred" + (n % 100 == 0 ? "" : " and " + numberTowords(n % 100));
  }
  return numberTowords(~~(n / 1000)) + " thousand" + (n % 1000 != 0 ? " " + numberTowords(n % 1000) : "");
}

export function coolness(x) {
  return x;
}

export function removeLeadingPhoneCode(phone) {
  let str = phone?.toString();
  if (!(str?.length > 0) || !phone) {
    return phone;
  }
  if (str.indexOf("0") === 0) {
    return setCharAt(str, 0, "");
  }
  if (str.indexOf("+255") === 0) {
    return str?.replace("+255", "");
  }
  if (str.indexOf("255") === 0) {
    return str?.replace("255", "");
  }
}

export function isAllNumbersWithLengthBetween(val: string, n, m) {
  let isnum = /^\d+$/.test(val);
  if (val.length >= n && val.length <= m && isnum) {
    return true;
  }
  return false;
}

export function getFormatedDate(string, format = "dd - MMM - y", type = "en-GB") {
  new DatePipe(type).transform(string, format);
}

export function addMonthsToDate(date_, numOfMonths: number) {
  if (!numOfMonths) return date_;
  let date = new Date(date_);
  const dateCopy = new Date(date.getTime());
  dateCopy.setMonth(dateCopy.getMonth() + numOfMonths);
  return dateCopy;
}

export function getTodayDate() {
  return new Date().toISOString().slice(0, 10);
}

export function isNotDefined(x) {
  return x === undefined || x === null || x === "";
}

export function isDefined(x) {
  return x !== undefined && x !== null && x !== "";
}

export function getClassListFromFieldString(s: string) {
  let classList = s;
  if (!s) return s;
  if (!s.includes("(")) return (classList = "");
  else if (!s.includes(")")) return (classList = "");
  else return (classList = s.substring(s.indexOf("(") + 1, s.indexOf(")")).trim());
}

export function getIconClassListFromFieldString(s: string) {
  let classList = s;
  if (!s) return s;
  if (!s.includes("[")) return (classList = "");
  else if (!s.includes("]")) return (classList = "");
  else return (classList = s.substring(s.indexOf("[") + 1, s.indexOf("]")).trim());
}

export function getIconFromFieldString(s: string) {
  let icon = s;
  if (!s) return s;
  if (!s.includes("icon-") || !s.includes("(") || !s.includes(")")) return (icon = "");
  s = s.replace("(", " ");
  s = s.replace(")", " ");
  return (icon = s.substring(s.indexOf("icon-") + 5, s.indexOf(" ", s.indexOf("icon-"))).trim());
}

export function getInnerVisibleFields(s: string) {
  let fieldStrings = [];
  if (!s) return s;
  if (!s.includes("{")) return [];
  else if (!s.includes("}")) return [];
  else
    s.substring(s.indexOf("{") + 1, s.indexOf("}"))
      .trim()
      .split(",")
      .forEach((x) => fieldStrings.push(x.trim()));
  return fieldStrings;
}

export function checkIfHasTabularDisplay(s: string) {
  let icon = s;
  if (!s) return s;
  if (s.includes("tabular")) return true;
  else return false;
}

export function getDefaultColSize(col_: string, allFieldsClassList: string) {
  let col = "medium";
  if (valueIncludes(col_, ["large", "medium", "small", "very-small"])) return col_;
  else if (!allFieldsClassList.includes("col-")) return (col = "medium");
  else if (allFieldsClassList?.includes("-12")) return (col = "large");
  else if (allFieldsClassList?.includes("-6")) return (col = "medium");
  else if (allFieldsClassList?.includes("-4")) return (col = "small");
  else if (allFieldsClassList?.includes("-3")) return (col = "very-small");

  return col;
}

export function getColFromFieldString(s: string) {
  let col = "";
  if (!s.includes("col-")) return "";

  if (s.includes("(")) {
    if (s.indexOf("col-") > s.indexOf("(")) col = s.trim().split("col-")[s.split("col-").length - 1];
    if (s.indexOf("col-") < s.indexOf("(")) col = s.trim().split("col-")[1].split("(")[0].trim();
  }

  if (!s.includes("(")) return (col = s.trim().split("col-")[s.split("col-").length - 1]);

  return col;
}

export function getNameFromFieldString(s: string) {
  if (!s) return s;
  s = s.split("{")[0];

  let as_i = s.indexOf(" as ");
  let class_i = s.indexOf("(");
  let col_i = s.indexOf("col-");

  if (as_i >= 0 && class_i >= 0 && col_i >= 0) return s.substring(as_i + 3, class_i > col_i ? col_i : class_i);
  if (as_i >= 0 && class_i >= 0 && !(col_i >= 0)) return s.substring(as_i + 3, class_i);
  if (as_i >= 0 && !(class_i >= 0) && col_i >= 0) return s.substring(as_i + 3, col_i);
  if (as_i >= 0 && !(class_i >= 0) && !(col_i >= 0)) return s.substring(as_i + 3, undefined);
  return s.split(" ")[0];
}

export function getKeyFromFieldString(s: string) {
  let key = s;
  if (!s) return s;
  return (key = s.split(" ")[0]);
}

export function valueIncludesBoth(value, values: string[]): boolean {
  let includes = true;
  values?.forEach(
    (v) =>
      (includes =
        includes &&
        (v.includes(".")
          ? v.toLowerCase().replace(".", "") === value.toLowerCase()
          : value?.toLowerCase()?.includes(v?.toLowerCase())))
  );
  return includes;
}

export function spacelesslyIncludes(x: string, y: string) {
  if (typeof x !== "string") return false;
  else if (y.includes(".")) return trim(x?.toLowerCase()) === trim(y.replace(".", "")?.toLowerCase());
  else return !!trim(x?.toLowerCase())?.includes(trim(y?.toLowerCase()));
}

export const randomNumbers = (min, max) => {
  return Math.round(Math.random() * (max - min)) + min;
};

export function getRandomNumberBetween(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function roundToNearest(n?: number, x?: number): number {
  return n ? Math.ceil(n / x) * x : 0;
}

export function setFormFieldsValidators(form: FormGroup, fields: string[], validators: any[]) {
  fields.forEach((field) => {
    if (validators.length <= 0) form.get(field).clearValidators();
    else form.get(field).setValidators(validators);
    form.get(field).updateValueAndValidity();
  });
}

export function removeFormFieldsValidators(form: FormGroup, fields: string[]) {
  setFormFieldsValidators(form, fields, []);
}

export function padLeft(n: any, width: number, z?: any) {
  z = z || "0";
  n = n + "";

  return n.length >= width ? n : new Array(width - n.length).fill("0").join("") + n;
}
