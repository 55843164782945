import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { MailConfig, mailConfigDto } from './mail-config.model';

export const loadMailConfigs = createAction(
  '[MailConfig/API] Load MailConfigs', 
  props<{ mailConfigs: MailConfig[] }>()
);

export const addMailConfig = createAction(
  '[MailConfig/API] Add MailConfig',
  props<{ mailConfig: MailConfig }>()
);

export const upsertMailConfig = createAction(
  '[MailConfig/API] Upsert MailConfig',
  props<{ mailConfig: MailConfig }>()
);

export const addMailConfigs = createAction(
  '[MailConfig/API] Add MailConfigs',
  props<{ mailConfigs: MailConfig[] }>()
);

export const upsertMailConfigs = createAction(
  '[MailConfig/API] Upsert MailConfigs',
  props<{ mailConfigs: MailConfig[] }>()
);

export const updateMailConfig = createAction(
  '[MailConfig/API] Update MailConfig',
  props<{ mailConfig: Update<MailConfig> }>()
);

export const updateMailConfigs = createAction(
  '[MailConfig/API] Update MailConfigs',
  props<{ mailConfigs: Update<MailConfig>[] }>()
);

export const deleteMailConfig = createAction(
  '[MailConfig/API] Delete MailConfig',
  props<{ id: number }>()
);

export const deleteMailConfigs = createAction(
  '[MailConfig/API] Delete MailConfigs',
  props<{ ids: string[] }>()
);

export const clearMailConfigs = createAction(
  '[MailConfig/API] Clear MailConfigs'
);


// CUSTOM
export const addUpdateMailConfig = createAction(
  '[MailConfig/API] Add iMailConfig',
  props<{ mailConfigDto: mailConfigDto }>()
);



export const getInstitutionMailConfig = createAction(
  '[MailConfig/API] get MailConfigs', 
  // props<{ mailConfigs: MailConfig[] }>()
);


export const removeMailConfiguration = createAction(
  '[MailConfig/API] remove mail config', 
  props<{ mailConfigs: MailConfig }>()
);