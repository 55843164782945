import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Roster } from './roster.model';
import * as RosterActions from './roster.actions';

export const rostersFeatureKey = 'rosters';

export interface State extends EntityState<Roster> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Roster> = createEntityAdapter<Roster>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(RosterActions.addRoster,
    (state, action) => adapter.addOne(action.roster, state)
  ),
  on(RosterActions.upsertRoster,
    (state, action) => adapter.upsertOne(action.roster, state)
  ),
  on(RosterActions.addRosters,
    (state, action) => adapter.addMany(action.rosters, state)
  ),
  on(RosterActions.upsertRosters,
    (state, action) => adapter.upsertMany(action.rosters, state)
  ),
  on(RosterActions.updateRoster,
    (state, action) => adapter.updateOne(action.roster, state)
  ),
  on(RosterActions.updateRosters,
    (state, action) => adapter.updateMany(action.rosters, state)
  ),
  on(RosterActions.deleteRoster,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(RosterActions.deleteRosters,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(RosterActions.loadRosters,
    (state, action) => adapter.setAll(action.rosters, state)
  ),
  on(RosterActions.clearRosters,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
