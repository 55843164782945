import { Component, OnInit, Input, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-step-indicator',
  templateUrl: './step-indicator.component.html',
  styleUrls: ['./step-indicator.component.scss']
})
export class StepIndicatorComponent implements OnInit {

  @Input() title: string;
  @Input() progress = 0;
  @Input() steps = [];

  @Output() goToStep = new EventEmitter<number>();

  constructor(
    private location: Location
  ){

  }

  ngOnInit() {
  }

  goBack() {
    this.location.back();
  }

  goToStep_(step){
    this.goToStep.emit(step)
  }

}
