import gql from 'graphql-tag';
import {incidentFewFields} from '../incident/incident.graphql';

export const incidentAssignmentFields = `
    id
    createdAt
    createdBy
    currentAssigned
    id
    incident{
      uuid
      id
    }
    active
    currentLeader
    deleted
    endDateTime
    institutionId
    institutionName
    institutionUuid
    startDateTime
    updatedAt
    updatedBy
    userEmail
    userName
    userUuid
    uuid

  `;

export const ASSIGN_INCIDENT = gql`
  mutation assignIncident($IncidentAssignmentDto: IncidentAssignmentDtoInput) {
    assignIncident(IncidentAssignmentDto: $IncidentAssignmentDto) {
    code
    dataList{
      id
    }
    }
  }
`;


export const SELF_MULTIPLE_ASSIGN_INCIDENT = gql`
  mutation selfAssignMultipleIncidents($IncidentAssignmentDto: IncidentAssignmentDtoInput){
    selfAssignMultipleIncidents(IncidentAssignmentDto: $IncidentAssignmentDto){
      code
      message
    }
  }
`;
export const ASSIGN_MULTIPLE_INCIDENT = gql`
  mutation assignMultipleIncidents($IncidentAssignmentDto: IncidentAssignmentDtoInput){
    assignMultipleIncidents(IncidentAssignmentDto: $IncidentAssignmentDto){
      code
      message
    }
  }
`;
