import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss']
})
export class PageTitleComponent implements OnInit {

  @Input() title: string;
  @Input() useBackButton = false;
  @Input() useRouterButton = false;
  @Input() useIncidentBack = false;
  @Input() routerUrlName: string;
  @Input() routerUrl: string;

  constructor(
    private location: Location
  ){

  }

  ngOnInit() {
  }

  goBack() {
    this.location.back();
  }

}
