import {PageableParam} from './../../../interfaces/global.interface';
import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';

import {ConversationDtoInput, Incident, IncidentPageable, IncidentResolveDto, IncidentResolveDtoInput} from './incident.model';
import {IncidentAssignmentDtoInput} from '../incident-assignment/incident-assignment.model';

export const loadIncidents = createAction(
    '[Incident/API] Load Incidents',
    props<{ incidents: Incident[] }>()
);

export const loadPageable = createAction(
    '[Incident/API] Load Incident Pageables',
    props<{ pageable: IncidentPageable }>()
);

export const addIncident = createAction(
    '[Incident/API] Add Incident',
    props<{ incident: Incident }>()
);

export const upsertIncident = createAction(
    '[Incident/API] Upsert Incident',
    props<{ incident: Incident }>()
);

export const addIncidents = createAction(
    '[Incident/API] Add Incidents',
    props<{ incidents: Incident[] }>()
);

export const upsertIncidents = createAction(
    '[Incident/API] Upsert Incidents',
    props<{ incidents: Incident[] }>()
);

export const updateIncident = createAction(
    '[Incident/API] Update Incident',
    props<{ incident: Update<Incident> }>()
);

export const updateIncidents = createAction(
    '[Incident/API] Update Incidents',
    props<{ incidents: Update<Incident>[] }>()
);

export const deleteIncident = createAction(
    '[Incident/API] Delete Incident',
    props<{ id: number }>()
);

export const deleteIncidents = createAction(
    '[Incident/API] Delete Incidents',
    props<{ ids: number[] }>()
);

export const clearIncidents = createAction(
    '[Incident/API] Clear Incidents'
);

// Effect Actions
export const allByCategoryIncidentList = createAction(
    '[Incident/API] List All Incidents By Category',
    props<{ pageable?: PageableParam; category: string }>()
);
export const listIncidents = createAction(
    '[Incident/API] List Incidents'
);

export const listIncidentsByState = createAction(
    '[Incident/API] List Incidents By State',
    props<{ state: string; pageable?: PageableParam }>()
);

export const listIncidentsByStatus = createAction(
    '[Incident/API] List Incidents By Status',
    props<{ status: string; pageable?: PageableParam }>()
);

export const countByCategoryAndStatusOnRange = createAction(
    '[Incident/API] Get Incidents Count By Category And Status On Range',
    props<{ startDate?: string; category?: string; endDate?: string; }>()
);

export const getIncidentsByInstitutionAndStatusAndDateRange = createAction(
    '[Incident/API] Get Incidents By Institution And Status And Date Range',
    props<{ startDate?: string; institutionCode?: number; status?: string; endDate?: string; }>()
);

export const listIncidentsByStateAndInstitution = createAction(
    '[Incident/API] List Incidents By State And Institution',
    props<{ state: string; pageable?: PageableParam }>()
);

export const incidenteSentByMe = createAction(
    '[Incident/API] List My Incidents ',
    props<{ incidentOrigin: string, incidentStatus?: string[], pageable?: PageableParam }>()
);

export const getMyInstIncidentsOutByStatus = createAction(
    '[Incident/API] List My InstIncidents Out By Status  ',
    props<{ incidentStatus?: string[]; pageable?: PageableParam }>()
);
export const getMyIndividualIncidentsOut = createAction(
    '[Incident/API] List My Individual Incidents Out By Status  ',
    props<{ incidentOrigin: 'INTERNAL' | 'EXTERNAL', incidentStatus?: string[]; pageable?: PageableParam }>()
);

export const allIncidentList = createAction(
    '[Incident/API] List Incidents ',
    props<{ pageable?: PageableParam }>()
);

export const getIncidentById = createAction(
    '[Incident/API] Get Incident By Id',
    props<{ incidentId: number }>()
);

export const saveIncident = createAction(
    '[Incident/API] Save Incident',
    props<{ incidentDto: Incident; from?: string }>()
);

export const sendIncidence = createAction(
    '[Incident/API] Save Incident',
    props<{ IncidentDtoInput: Incident; redirectUrl: string }>()
);

export const removeIncident = createAction(
    '[Incident/API] Remove Incident',
    props<{ incidentId: number }>()
);

export const assignIncidentToDepartment = createAction(
    '[Incident/API] Assign Incident To Department',
    props<{ incidentId: number; departmentId: number }>()
);

export const assignToFirstLevel = createAction(
    '[Incident/API] Reassign Incident To First Level',
    props<{ incidentId: number; userId: number }>()
);

export const listAssignedIncidentsToDepartment = createAction(
    '[Incident/API] List Assigned Incidents to Department',
    props<{ pageable?: PageableParam }>()
);

export const getMyAssignedIncidents = createAction(
    '[Incident/API] Get assigned Incidents',
    props<{ getAssignedToDept?: boolean, pageable?: PageableParam; incidentStatus?: string[] }>()
);

export const assignIncidentToSecondLevel = createAction(
    '[Incident/API] Assign Incident To Second Level',
    props<{ assignToSecondLevel: any }>()
);
export const listAssignedIncidentsToSecondLevel = createAction(
    '[Incident/API] List Assigned Incidents to Second Level',
    props<{ pageable?: PageableParam }>()
);
export const resolveIncident = createAction(
    '[Incident/API] Resolve Incident',
    props<{ incidentResolveDto: IncidentResolveDto; from?: string }>()
);
export const closeIncident = createAction(
    '[Incident/API] Close Incident',
    props<{ incidentResolveDto: IncidentResolveDtoInput; from?: string }>()
);
export const incidentCategorization = createAction(
    '[Incident/API] Incident Categorization',
    props<{ incidentCategorizationDto: Incident }>()
);
export const multipleIncidentCategorization = createAction(
    '[Incident/API] Multiple Incident Categorization',
    props<{ incidentCategorizationDto: Incident }>()
);

export const reOpenIncident = createAction(
    '[Incident/API] Re-open Incident',
    props<{ incidentId: number }>()
);
export const createConversation = createAction(
    '[Incident/API] Create conversation',
    props<{ projectReviewCommunicationDto: ConversationDtoInput; from?: string }>()
);
export const getIncidentsByLoggedInUser = createAction(
    '[Incident/API] Get Incidents By Logged In User',
    props<{ pageable?: PageableParam }>()
);
export const firstLevelIncidents = createAction(
    '[Incident/API] First Level Incidents',
);


export const incidentByCategoryStatusList = createAction(
    '[Incident/API] List Incidents By Category And Status',
    props<{ status: string; pageable?: PageableParam; category: string }>()
);

export const getIncidentByUid = createAction(
    '[Incident/API] gte Incident By uuid',
    props<{ uuid: string }>()
);

export const unMergeIncident = createAction(
    '[Incident/API] Un Merge Incident By uuid',
    props<{ incidentUuid: string, parentIncidentUuid: string, }>()
);

export const getAllIncidentsByStatus = createAction(
    '[Incident/API] get Incident By status',
    props<{
        reportedOnBehalf?: boolean
        incidentStatus: string[];
        incidentOrigin: string,
        pageable?: PageableParam
    }>()
);
export const getAllUnMergedIncidentsByStatus = createAction(
    '[Incident/API] get All Un Merged Incident By status',
    props<{
        incidentStatus: string[];
        incidentOrigin: string,
        pageable?: PageableParam
    }>()
);

export const getAllParentIncidentsByStatus = createAction(
    '[Incident/API] get All Parent Incident By status',
    props<{
        incidentStatus: string[];
        incidentOrigin: string,
        pageable?: PageableParam
    }>()
);

export const getMyInstDeptIncidentsByStatus = createAction(
    '[Incident/API] get Department tickets By status',
    props<{ incidentStatus: string[]; pageable?: PageableParam }>()
);

// assignIncidentToDeptByUuid
export const assignIncidentToDeptByUuid = createAction(
    '[IncidentAssignment/API] Assign Incident To Department',
    props<{ deptUuid: string; incidentUuid: string }>()
);

// selfAssignIncident
export const selfAssignIncident = createAction(
    '[selfAssignIncident/API] selfAssignIncident',
    props<{ IncidentAssignmentDto: IncidentAssignmentDtoInput }>()
);


export const queryAllIncidentReportedByMe = createAction(
    '[Incident/API] List Incidents Created By Me',
    props<{ incidentStatus: string[]; pageable?: PageableParam; }>()
);

export const incidentRecategorization = createAction(
    '[Incident/API] rectegorize Incidents',
    props<{ incidentStatus: string[] }>()
);

export const getAllIncidentsReportedByOnBehalfAndByStatus = createAction(
    '[Incident/API] get Incident By status and onbehalf',
    props<{ incidentStatus: string[]; pageable?: PageableParam, reportedOnBehalf: boolean }>()
);

export const updateIncidentParentByUuid = createAction(
    '[Incident/API] update Incident Parent ByUuid',
    props<{ incidentUuids: string[]; parentTicketNo: string }>()
);

export const confirmIncidentResolved = createAction(
    '[Incident/API] confirm Incident Resolved',
    props<{
        confirm: boolean,
        description: string,
        incidentUuid: string
    }>()
);

export const getMyInstSectionIncidents = createAction(
    '[Incident/API] get Section tickets By status',
    props<{ incidentStatus: string[]; pageable?: PageableParam }>()
);


export const incidentDetailedReports = createAction(
    '[Incident/API] Export Incident Detailed Reports',
    props<{
        fromDate?: string,
        categoryUuid?: string[],
        incidentOrigins?: string[],
        exportDocType?: string,
        toDate?: string,
        institutionUuid?: string,
        incidentStatus?: string[],
    }>()
);


export const getAllIncidentsByExecutiveOfficers = createAction(
    '[Incident/API] getAllIncidentsByExecutiveOfficers',
    props<{
        incidentStatus: string[];
        incidentOrigin: string,
        pageable?: PageableParam
        branchUuid?: string
        departmentUuid?: string
    }>()
);
