import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'percentagePrecision'
})
export class PercentagePrecision implements PipeTransform {


  transform(value: any, type: 'ceil' | 'floor'): any {

    if (value){
      if (type === 'ceil') {
        return Math.ceil(value * 100) / 100;
      }
      else if (type === 'floor') {
        return Math.floor(value * 100) / 100;
      }
    }

  }

}
