import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-pagination-buttons',
  templateUrl: './pagination-buttons.component.html',
  styleUrls: ['./pagination-buttons.component.scss']
})
export class PaginationButtonsComponent implements OnInit {

  @Input() currentPage: number;
  @Input() totalPage: number;
  @Input() pagination: any[];
  @Input() pageSizeOptions: number[] = [10, 50, 100, 250];
  @Output() clickedPage: EventEmitter<any> = new EventEmitter();
  @Output() selectedPageSize: EventEmitter<number> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  setPage(page: number) {
    this.clickedPage.emit(page);
  }

  setPageSize(page: number) {
    this.selectedPageSize.emit(page);
  }
}
