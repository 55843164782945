import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeNull'
})
export class RemoveNullPipe implements PipeTransform {



  transform(value: any, ...args: any[]): any {
    // remove  null 
    
    if(value){
      let fullName = value;

              fullName = fullName.replace(null, " ")

              return fullName;
     
    } else {
        return value;
    }
  }

}
