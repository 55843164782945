import gql from 'graphql-tag';

export const mailconfigurationFields = `
id
uuid
userName
port
host
active
mailForIncidentReporting
cryptographicProtocol
issueCategoryUuid
sentFolderName
inboxFolderName
issueCategory{
uuid
title
}
institutionService{
id
uuid
name
}
`;



export const ADD_UPDATE_MAIL_CONFIG =
  gql` mutation addUpdateMailConfig($mailConfigDto: MailConfigDtoInput){
  addUpdateMailConfig(mailConfigDto: $mailConfigDto)
  {
    code
    status
    message
    data{
      ${mailconfigurationFields}

    }
  }
}`

export const GET_INSTITION_MAIL_CONIGURATION =
  gql` query getInstitutionMailConfig{
    getInstitutionMailConfig{
      code
      status      
      dataList{
        ${mailconfigurationFields}
      }
    }
  }`


export const SYNC_MAILS =
  gql` mutation syncMails{
    syncMails(
      password:"gmsclient123",
      # token:"ooUWjkE43N3GG0MYajLJscTdlndH1I9dU9VJrpmrYgZWVxNpSXJxALSgS44xhFni"
      mailFolder:INBOX
    ){
      code
      inboxCount
      unreadCount
      userName
      message
      token
      dataList{
        messageId
        emailFrom
        toList
        ccList
        sentDate
        emailSubject
        emailBody
        flag
        hasAttachment
      }
    }
  }
  `

export const READ_MAIL =
gql` mutation readMail{
  readMail(
    messageId:249,
    token:"ooUWjkE43N3GG0MYajLJsTer2GzcHnaK9Zfp+VEooFQURGVywHijvRYpOjE1N9q+"
    mailFolder:INBOX
  ){
    code
    inboxCount
    unreadCount
    userName
    message
    token
    data{
      messageId
      emailFrom
      toList
      ccList
      sentDate
      emailSubject
      emailBody
      flag
      hasAttachment
    }
  }
}
`


export const REMOVE_MAIL_CONFIG = gql` 
mutation deleteMailConfig($uuid: String){
    deleteMailConfig(uuid: $uuid){
      code
      status  
    }
  }`






export const TEST_CONNECTION = gql` 
mutation testConnectionToMailServer ($mailConfigDto: MailConfigDtoInput){
    testConnectionToMailServer (mailConfigDto: $mailConfigDto){
      code
      status  
      message
    }
  }`









