import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { StorageService } from 'src/app/services/storage.service';
import { DynamicFormService } from 'src/app/shared/components/dynamic-forms-components/dynamic-form.service';
import { FieldConfig } from 'src/app/shared/components/dynamic-forms-components/field.interface';
import { ClientIdentification } from 'src/app/store/entities/client-identification/client-identification.model';
import { Service, ServiceSearchFields } from 'src/app/store/entities/settings/service/service.model';
import { AppState } from 'src/app/store/reducers';
import * as clientIdentification from './client-identification-fields';
import { fadeIn } from 'src/app/shared/animations/router-animation';
import { listAllServicesFewFields } from 'src/app/store/entities/settings/service/service.actions';
import { initializedPageableParameter, PageableParam, specialInitializedPageableParameter } from 'src/app/interfaces/global.interface';
import { Institution } from 'src/app/store/entities/settings/institution/institution.model';
import { clearClientIdentifications, createUpdateInstitutionClientLocalCodes } from 'src/app/store/entities/client-identification/client-identification.actions';

export interface Item{
  id?: string;
  localClientCode?: string;
  institutionServiceUuid?: string;
  institutionUuid?: string;
  clientInstitutionUuid?: string;
}

export interface ClientIdentificationData{
  clientIdentification: ClientIdentification; 
  otherOptions: any;
}

@Component({
  selector: 'app-client-identification-form',
  templateUrl: './client-identification-form.component.html',
  styleUrls: ['./client-identification-form.component.scss'],
  animations: [fadeIn]
})
export class ClientIdentificationFormComponent implements OnInit {
  clientIdentificationData: ClientIdentificationData;
  serviceFiels: FieldConfig[] = clientIdentification.serviceFiels;
  fields: FieldConfig[] = clientIdentification.fields;
  serviceForm?: UntypedFormGroup;
  form?: UntypedFormGroup;
  loading = false;
  itemList: any[] = [];
  institutionUuid: any;
  services$: Observable<Service[]>;
  pageable: PageableParam = initializedPageableParameter;
  searchFields = ServiceSearchFields;
  institutions$: Observable<Institution[]>;
  itemsToSave: Item[] = [];
  selectedService: any;
  selectedClient: any;
  selectedCode: any;
  
  constructor(
    private dynamicFormService: DynamicFormService,
    public dialogRef: MatDialogRef<ClientIdentificationFormComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private storageService: StorageService,
    private store: Store<AppState>
  ) {
    this.clientIdentificationData = data;
    this.institutionUuid = this.clientIdentificationData.otherOptions.institutionUuid;
  }

  ngOnInit(): void {
    this.form = this.dynamicFormService.createControl(this.fields, null);    
    this.serviceFiels = this.serviceFiels.map((field) => { 
      if(field.key == 'service'){
        field.additionalVariables = {institutionUuid: this.institutionUuid }
      }
      return {
        ...field
      };
    });

    this.serviceForm = this.dynamicFormService.createControl(this.serviceFiels, null);
    
  }

  async submit() {   
  const itemList: Item[] = [];
  this.itemsToSave.forEach(e=> {
    itemList.push({clientInstitutionUuid: e?.clientInstitutionUuid, localClientCode: e.localClientCode, institutionUuid: e?.institutionUuid, institutionServiceUuid: e?.institutionServiceUuid});
  })
    this.store.dispatch(clearClientIdentifications());
    this.store.dispatch(createUpdateInstitutionClientLocalCodes({identificationData: itemList, otherOptions: this.clientIdentificationData.otherOptions}));
    this.dialogRef.close();
 
  }
  institutionCode

  fieldData(event: any) {
  if(event?.key == 'client'){
    this.selectedClient = event;    
    this.serviceForm.patchValue({ code: event?.object?.institutionCode });
  }
  if(event?.key == 'service'){
    this.selectedService = event;
  }
  }


  addItem(obj: any) {
  
    let data: any = { id: this.itemsToSave?.length + obj?.code, localClientCode: obj?.code, institutionServiceUuid: this.selectedService?.value, uuid: null, institutionUuid: this.institutionUuid, clientInstitutionUuid: this.selectedClient?.object?.uuid }
     
    const existingInToSave = this.itemsToSave?.find(i => ((i?.institutionServiceUuid == this.selectedService?.object?.uuid ) && (i?.clientInstitutionUuid == this.selectedClient?.object?.uuid) ));
          
    if(existingInToSave){
      this.itemsToSave =  this.itemsToSave?.filter(i => !((i?.institutionServiceUuid == this.selectedService?.object?.uuid ) && (i?.clientInstitutionUuid == this.selectedClient?.object?.uuid) ))
    }
    
    this.itemsToSave = [...this.itemsToSave,data];    
    let tempData: any = { id: this.itemList?.length + obj?.code, code: obj?.code, serviceName: this.selectedService?.object ? this.selectedService?.object?.name: 'Applies to all services', uuid: null, institutionUuid: this.institutionUuid, clientName: this.selectedClient?.object?.name }
    const existingIndex = this.itemList?.find(i => ((i.serviceName == this.selectedService?.object ? this.selectedService?.object?.name : 'Applies to all services') && (i.clientName == this.selectedClient?.object?.name) ));
    
    if(existingIndex){
      this.itemList = this.itemList?.filter(i => !((i.serviceName == this.selectedService?.object ? this.selectedService?.object?.name : 'Applies to all services') && (i.clientName == this.selectedClient?.object?.name) ))
    }
    this.itemList = [...this.itemList, tempData];

    setTimeout(() => {
      this.itemList = this.itemList;
    }, 50);
    this.form?.reset();

  }

  deleteItem(item?: any) {
    this.itemsToSave = this.itemsToSave?.filter(i => i?.id != item.id );
    this.itemList = this.itemList.filter(i => i.id != item?.id);
      
    setTimeout(() => {
      this.itemList = this.itemList;
    }, 50);
  }

  close() {
    this.dialogRef.close();
  } 
  
  closeThisModal(close: boolean) {
    if (close) {
      this.dialogRef.close();
    }
  }

}
