import { StorageService } from './services/storage.service';
import { AuthService } from './services/auth.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { SpinnerComponent } from './shared/components/spinner/spinner.component';
import { Spinkit } from 'ng-http-loader';
import { Observable } from 'rxjs';
import { AuthUser } from './store/entities/auth-user/auth-user.model';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../environments/environment';
import { NotificationService } from './services/notification.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'help-desk-frontend';
  public spinnerComponent = SpinnerComponent;
  public spinkit = Spinkit;
  user$: Observable<AuthUser>;
  level: string;

  thread: any;
  maxAllowedIdleSeconds = environment.SESSION_TIME_OUT_ON_IDLE;
  remainingSeconds = this.maxAllowedIdleSeconds;

  constructor(
      private storage: StorageService,
      private authService: AuthService,
      private dialog: MatDialog,
      private storageService: StorageService,
      private notificationService: NotificationService
  ) {}

  @HostListener('window:scroll', ['$event'])
  @HostListener('keydown', ['$event'])
  @HostListener('click', ['$event'])
  @HostListener('mouseover', ['$event'])
  onEvent(event) {
    this.remainingSeconds = this.maxAllowedIdleSeconds;
  }

  @HostListener('window:online', ['$event'])
  onlineEvent(event) {
    if (event.type === 'online') this.notificationService.successSnackbar("Back online! Your internet connection has been restored");
  }

  @HostListener("window:offline", ["$event"])
  offlineEvent(event) {
    const message: string = 'Opps! You are currently offline, Please check your internet connection';
    if(event.type === 'offline') this.notificationService.errorSnackbar(message);
  }


  async ngOnInit() {
    try {
      if (this.storageService.getItem('currentClient')) {
        await this.authService.authRole();
      }
    } catch (exceptionVar) {
      this.storageService.clearStorage();
      //   location.reload();
    } finally {
      this.thread = setInterval(() => {
        if (this.authService.alreadyLoggedIn()) {
          this.remainingSeconds -= 1;
          if (this.remainingSeconds < 1) {
            this.dialog.closeAll();
            this.authService.logout();
            // this.storage.setItem('sessionExpired', 'true');
            // this.authService.lockScreen();
          }
        } else {
          this.remainingSeconds = this.maxAllowedIdleSeconds;
        }
      }, 1000);
    }
  }
}