import {SearchFieldsDtoInput, SearchOperationType} from 'src/app/interfaces/global.interface';
import {Institution} from '../institution/institution.model';
import {Permission} from '../permission/permission.model';

export interface Role {
    id?: number;
    global?: boolean;
    displayName: string;
    name?: string;
    uuid?: string;
    selected?: boolean;
    permissionList?: Permission[];
    institution?: Institution;
}

export const RoleSearchFields: SearchFieldsDtoInput[] = [
    {
        fieldName: 'name',
        searchType: SearchOperationType.Like,
    },
    {
        fieldName: 'displayName',
        searchType: SearchOperationType.Like,
    },
];
