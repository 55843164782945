import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {Menu, ServiceUserType} from 'src/app/interfaces/global.interface';
import {StorageService} from 'src/app/services/storage.service';
import {AppState} from 'src/app/store/reducers';
import {BehaviorSubject, Subscription} from 'rxjs';
import {AuthService} from 'src/app/services/auth.service';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
    @Input() sidebarMenu: Menu;
    menuData: Menu;
    currentMenu: any;
    serviceUserType = ServiceUserType;
    loggedServiceUserType: string;

    @Input() sidebarMenuObservable$: BehaviorSubject<Menu>;

    subscription = new Subscription();

    constructor(
        private storageService: StorageService,
        private store: Store<AppState>,
        private authService: AuthService,
    ) {
        this.loggedServiceUserType = this.storageService.getItem('serviceUserType');
    }

    ngOnInit(): void {
        this.menuData = this.sidebarMenu;
        this.subscription.add(
            this.sidebarMenuObservable$?.subscribe(t => {
                if (t) {
                    this.menuData = t;
                }
            }));
    }

    logout() {
        this.authService.logout();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
