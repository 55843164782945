import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from './../../reducers/index';
import * as fromIncidentTrailReducer from './incident-trail.reducer';
import { DatePipe } from '@angular/common';
import { IncidentTrail } from './incident-trail.model';

const today = new Date();

export const currentState = (state: AppState) => (state[fromIncidentTrailReducer.incidentTrailsFeatureKey]);

export const selectAllIncidentTrails = createSelector(currentState, fromIncidentTrailReducer.selectAll);

export const selectTotalIncidentTrails = createSelector(currentState, fromIncidentTrailReducer.selectTotal);

export const selectModifiedIncidentTrails = createSelector(selectAllIncidentTrails, (incidentTrails: IncidentTrail[]) => {
  return incidentTrails.map((incidentTrail) => {
    return {
      ...incidentTrail,
      createdAtFormatted: (new DatePipe('en-GB').transform(incidentTrail.createdAt, 'dd MMM yyyy')) + ' at ' +
                          (new DatePipe('en-GB').transform(incidentTrail.createdAt, 'HH:mm')),
    };
  });
});
