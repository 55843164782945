import { Validators } from '@angular/forms';
import { FieldConfig, FieldType } from 'src/app/shared/components/dynamic-forms-components/field.interface';

export const fields: FieldConfig[] = [
  {
    type: FieldType.input,
    label: 'Requested by',
    disabled: true,
    key: 'fullName',
    // validations: [{message: 'Requestor  is Required', validator: Validators.required, name: 'required'}],
    rowClass: 'col6'
  },
  // {
  //   type: FieldType.input,
  //   label: 'Phone number',
  //   key: 'phoneNumber',
  //   disabled: true,
  //   validations: [{message: 'Phone number is Required', validator: Validators.required, name: 'required'}],
  //   rowClass: 'col6'
  // },
  {
    type: FieldType.input,
    label: 'Email',
    key: 'email',
    disabled: true,
    validations: [
      {message: 'Email is Required', validator: Validators.required, name: 'required'},
      {message: 'Email is Required', validator: Validators.email, name: 'required'}
    ],
    rowClass: 'col6'
  },
  {
    type: FieldType.input,
    label: 'Affected Service',
    key: 'affectedServices',
    disabled: true,
    validations: [{message: 'This field is Required', validator: Validators.required, name: 'required'}],
    // rowClass: 'col12'
  },
  {
    type: FieldType.input,
    label: 'Rollback plan',
    key: 'rollbackPlan',
    disabled: true,
    validations: [{message: 'This field is Required', validator: Validators.required, name: 'required'}],
    // rowClass: 'col12'
  },
  {
    type: FieldType.select,
    label: 'Request type',
    key: 'requestType',
    options: [],
    // showWhen: '{(queryingInstitutionId)(!=)(null)}',
    // useObservable: true,
    validations: [
      {message: 'Request type is Required', validator: Validators.required, name: 'required'},
    ]
  },
  {
    type: FieldType.textarea,
    label: 'Description',
    disabled: true,
    key: 'description',
    validations: [{message: 'Description is Required', validator: Validators.required, name: 'required'}],
    // rowClass: 'col12'
  },
  {
    type: FieldType.date,
    label: 'Change Date',
    key: 'changeDate',
    disabled: true,
    maxDate: new Date(),
    dateFormat: 'yyyy-MM-dd',
    validations: [{message: 'change date is Required', validator: Validators.required, name: 'required'}],
    // rowClass: 'col12'
  },
  {
    type: FieldType.date,
    label: 'Date completed',
    key: 'completionDate',
    disabled: true,
    maxDate: new Date(),
    dateFormat: 'yyyy-MM-dd',
    validations: [{message: 'Completion date is Required', validator: Validators.required, name: 'required'}],
    // rowClass: 'col12'
  },
  {
    type: FieldType.select,
    label: 'Decision',
    key: 'reviewDecission',
    options: [],
    // showWhen: '{(queryingInstitutionId)(!=)(null)}',
    // useObservable: true,
    validations: [
      {message: 'Request decision is Required', validator: Validators.required, name: 'required'},
    ]
  },
  {
    type: FieldType.textarea,
    label: 'Review Comment',
    key: 'comment',
    validations: [{message: 'Comment is Required', validator: Validators.required, name: 'required'}],
    // rowClass: 'col12'
  },
  {
    type: FieldType.attachment,
    acceptType: ['.pdf'],
    label: 'Add attachment (Optional)',
    key: 'attachments',
  },
  {
    type: FieldType.button,
    label: 'Save request',
    // rowClass: 'col12'
  },
];

