import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../../reducers';
import { IncidentNotification } from './notification.model';
import * as fromNotificationReducer from './notification.reducer';

export const currentState = (state: AppState) => (state[fromNotificationReducer.incidentNotificationsFeatureKey]);

export const selectAllIncidentNotifications = createSelector(currentState, fromNotificationReducer.selectAll);

export const selectTotalServices = createSelector(currentState, fromNotificationReducer.selectTotal);

export const selectNotificationByType = (params: {id: string}) => createSelector(
    selectAllIncidentNotifications,
    (notifications: IncidentNotification[]) => {
      return notifications.filter((notification) => notification.id === params.id);
    }
  );

