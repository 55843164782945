import { Pipe, PipeTransform } from '@angular/core';
import { SettingsService } from 'src/app/services/settings.service';

@Pipe({
  name: 'formatDate',
})
export class FormatDatePipe implements PipeTransform {
  constructor(private settingsService: SettingsService) {}

  transform(date: any, ...args: any[]): any {
    if (date) {
    
     const dateOnly = this.settingsService.formatDateMonthName(new Date (date));
      const newFormatDate: any[] = date.split('-');
      const dateGroup = newFormatDate[2];
      const itime = dateGroup.split('T')[1].split('.')[0];
      const finalDate = dateOnly + ' @ ' + itime;
      return finalDate;
    }
  }
}
