import {Injectable} from '@angular/core';
import {ResponseCodeService} from '../../../../services/response-code.service';
import {AppState} from '../../../reducers/index';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {Apollo} from 'apollo-angular';
import {NotificationService} from 'src/app/services/notification.service';
import * as fromActions from './manufacture.actions';
import * as fromGraphql from './manufacture.graphql';
import {map, switchMap} from 'rxjs/operators';
import {PaginationService} from '../../../../services/pagination.service';


@Injectable()
export class ManufactureEffects {
    // List All Branchs
    listMyInstitutionAllManufactures$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.listMyInstitutionAllManufactures),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.GET_MY_INSTITUTION_ALL_MANUFUCTURES,
                variables: {
                    pageParam: action.pageableParam
                },
                fetchPolicy: 'network-only',
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching Resource Manufacture'),
                map(({data}: any) => {
                    if (data) {
                        const res = data?.getAllInstitutionManufactures;
                        this.paginationService.setPage(res);
                        this.store.dispatch(fromActions.loadManufactures({manufactures: res?.content}));
                    }
                })
            );
        })
    ), {dispatch: false});

    listMyInstitutionAllManufacturesForOption$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.listMyInstitutionAllManufacturesForOption),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.GET_MY_INSTITUTION_ALL_MANUFUCTURES_FOR_LIST,
                variables: {
                    pageParam: action.pageableParam
                },
                fetchPolicy: 'network-only',
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching Resource Manufacture'),
                map(({data}: any) => {
                    if (data) {
                        const res = data?.getAllInstitutionManufactures;
                        this.paginationService.setPage(res);
                        this.store.dispatch(fromActions.loadManufactures({manufactures: res?.content}));
                    }
                })
            );
        })
    ), {dispatch: false});


    activateManufacture$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.activateManufacture),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.ACTIVATE_AND_DEACTIVATE_MANUFUCTURE,
                variables: {
                    id: action.id,

                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while updating Resource Manufacture details'),
                map(({data}: any) => {

                    if (data) {

                        this.notificationService.successMessage('Manufacture details Activated Successfully');
                        if (data.activateDeactivateManufacture.code === 9000) {
                            this.store.dispatch(fromActions.upsertManufacture({manufacture: data.activateDeactivateManufacture.data}));
                            this.notificationService.successMessage('Manufacture details Activated Successfully');
                        } else {
                            this.notificationService.errorMessage('Failed to Activate Manufacture detals');
                        }
                    }
                })
            );
        })
    ), {dispatch: false});


    // Create/Save Branch
    createBranch$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.createManufacture),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.SAVE_UPDATE_MANUFUCTURE,
                variables: {
                    manufacture: action.manufacture
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while saving Resource Manufacture'),
                map(({data}: any) => {
                    if (data) {
                        if (data.createUpdateManufacture.code === 9000) {
                            this.store.dispatch(fromActions.upsertManufacture({manufacture: data.createUpdateManufacture.data}));
                            if (action.manufacture?.id) {
                                this.notificationService.successMessage('Edited Successfully');
                            } else {
                                this.notificationService.successMessage('Created Successfully');
                            }
                        } else {
                            const error = this.responseCodesService.getCodeDescription(data.createUpdateManufacture.code);
                            this.notificationService.errorMessage(error[0]?.description);
                        }
                    }
                })
            );
        })
    ), {dispatch: false});


    // Detele Branch
    removeBranch$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.removeManufacture),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.DELETE_MANUFUCTURE,
                variables: {
                    id: action.id
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while deleting Resource Manufacture'),
                map(({data}: any) => {
                    if (data) {
                        if (data.deleteManufacture.code === 9000) {
                            // this.store.dispatch(fromActions.deleteBranch({ id: action.id }));
                            this.store.dispatch(
                                fromActions.deleteManufacture({id: action.id})
                            );
                            // this.store.dispatch(fromActions.upsertManufacture({ manufacture: data.deleteManufacture }));
                            this.notificationService.successMessage('Deleted Successfully');
                        } else {
                            this.notificationService.errorMessage(data.deleteManufacture);
                        }
                    }
                })
            );
        })
    ), {dispatch: false});


    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private store: Store<AppState>,
        private notificationService: NotificationService,
        private responseCodesService: ResponseCodeService,
        private paginationService: PaginationService
    ) {
    }


}
