import { StorageService } from '../../services/storage.service';
import { AuthService } from '../../services/auth.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NotificationService } from 'src/app/services/notification.service';
import { ServiceUserType } from 'src/app/interfaces/global.interface';
import { fadeSmooth, PAGE_ANIMATIONS } from 'src/app/shared/animations/router-animation';
// import { SYSTEM_NAME, SYSTEM_PHONE } from 'src/app/shared/constants/constants';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations:[fadeSmooth, PAGE_ANIMATIONS]
})
export class LoginComponent implements OnInit, OnDestroy {
  currentYear: any;
  loading = false;
  loginView = false;
  subscription = new Subscription();
  staffLoginForm: UntypedFormGroup;
  resetPasswordForm: UntypedFormGroup;
  forgotPasswordForm: UntypedFormGroup;
  userType: string;
  title: string;
  isLogin = true;
  showPassword: boolean;
  redirectUrl: string;
  sessionExpired: boolean;
  location: any;

  constructor(
    private fb: UntypedFormBuilder,
    private route: Router,
    private authService: AuthService,
    private storageService: StorageService,
    private notificationService:NotificationService,
  ) {
    localStorage.clear();
  }

  ngOnInit(): void {

    this.title = 'Government Helpdesk System';

    this.currentYear = new Date().getFullYear();
    this.staffLoginForm = this.fb.group({
      username: [null, [Validators.required]],
      password: [null, [Validators.required]],
    });
    this.forgotPasswordForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
    });
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  async login(auth) {
    await this.authService.login(auth);
    await this.authService.authRole();
    let serviceUserType = this.storageService.getItem('serviceUserType');


    if (!this.storageService.getScreenLocked()) {
      if (serviceUserType == ServiceUserType.client) {
        this.route.navigateByUrl('/individual');
      } else {
        this.route.navigateByUrl('/landing');
      }     
    } else {
      this.storageService.saveScreenLocked(false);
    }
  }

  changeView() {
    this.loginView = !this.loginView;
  }

  goBack() {
    this.location.back();
  }

  goToUrl(slug) {
    this.route.navigateByUrl(slug);
  }

  resetPassword(formData: any) {}

  async forgetPassword(formValue: any) {
    const result = await this.authService.forgotPassword(formValue).toPromise();
    //  
    // code: 9000, status: true, data:
    // if (result === 'NOT_EXIST') {
    //   this.notificationService.errorMessage(
    //     `User with this email ${formValue.email} does not exist`
    //   );
    //   this.isLogin = true;
    //   this.forgotPasswordForm.reset();
    // } 
    if (result.status === true) {
      this.notificationService.successMessage('Reset link sent successfully');
      this.isLogin = true;
      this.forgotPasswordForm.reset();
    } else {
      this.notificationService.errorMessage(
        `Failed to sent reset link to User with this email ${formValue.email}`
      );
      this.forgotPasswordForm.reset();
    }
  }

  toggleLogin() {
    this.isLogin = !this.isLogin;
  }

  ngOnDestroy(): void {
    // this.subscription.unsubscribe();
  }
}
