
<!-- <fusioncharts
  width="700"
  height="400"
  type="Column2d"
  [dataSource]="dataSource"
>
</fusioncharts> -->

<mat-card>
  <mat-card-title>Bar Graph</mat-card-title>
  <mat-divider></mat-divider>
  <mat-card-content>
    <div style="display: block;">
      <canvas baseChart 
        [datasets]="barChartData"
        [labels]="barChartLabels"
        [options]="barChartOptions"
        [plugins]="barChartPlugins"
        [legend]="barChartLegend"
        [chartType]="barChartType">
      </canvas>
    </div>  
  </mat-card-content>
</mat-card>
