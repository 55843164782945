import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {Apollo} from 'apollo-angular';
import {map, switchMap} from 'rxjs/operators';
import {IncidentNotificationType} from 'src/app/interfaces/global.interface';
import {NotificationService} from 'src/app/services/notification.service';
import {PaginationService} from 'src/app/services/pagination.service';
import {ResponseCodeService} from 'src/app/services/response-code.service';
import {AppState} from '../../reducers';
import * as fromActions from './notification.actions';
import * as fromGraphql from './notification.graphql';


@Injectable()
export class NotificationEffects {


    // getAllIncidentNotificationsForActionTakersServiceProviders e.g 1st, 2nd levels and their suppervisors
    getAllIncidentNotificationsForActionTakersServiceProviders$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getAllIncidentNotificationsForActionTakersServiceProviders),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.LIST_ALL_NOTIFICATION_FOR_ACTION_TAKERS_SERVICE_PROVIDERS,
                fetchPolicy: 'network-only',
                variables: {
                    incidentOrigin: action.incidentOrigin
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while getting incidentNotifications'),
                map(({data}: any) => {
                    if (data) {
                        const incommingStatuses = data?.getAllIncidentsByStatusStatistics?.data;
                        const myAssignedStatuses = data?.getMyAssignedIncidentsStatistics?.data;
                        let counts = {
                            id: IncidentNotificationType.external,
                            incommingStatuses,
                            myAssignedStatuses
                        };

                        this.store.dispatch(fromActions.upsertIncidentNotification({incidentNotification: counts}));
                    }
                })
            );
        })
    ), {dispatch: false});

    // getMyReportedIncidentNotification
    getMyReportedIncidentNotification$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getMyReportedIncidentNotification),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.GET_ALL_MY_INCIDENT_NOTIFICATIONS,
                fetchPolicy: 'network-only',
            }).pipe(
                this.notificationService.catchError('Problem occurred while getting my incident notifications'),
                map(({data}: any) => {
                    if (data) {
                        const myReportedStatuses = data?.getMyInstIncidentsOutStatistics?.data;
                        let counts = {
                            id: IncidentNotificationType.individual,
                            myReportedStatuses,
                        };

                        this.store.dispatch(fromActions.upsertIncidentNotification({incidentNotification: counts}));
                    }
                })
            );
        })
    ), {dispatch: false});

    // getAllInternalIncidentNotificafications e.g 1st, 2nd levels and their suppervisors
    getAllInternalIncidentNotificafications$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getAllInternalIncidentNotificafications),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.GET_INTERNAL_INCIDENT_NOTIFICATIONS,
                fetchPolicy: 'network-only',
                variables: {
                    incidentOrigin: action.incidentOrigin
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while getting incidentNotification'),
                map(({data}: any) => {
                    if (data) {
                        const internalStatuses = data?.getAllIncidentsByStatusStatistics?.data;
                        let counts = {
                            id: IncidentNotificationType.internal,
                            internalStatuses,
                        };

                        this.store.dispatch(fromActions.upsertIncidentNotification({incidentNotification: counts}));
                    }
                })
            );
        })
    ), {dispatch: false});


    // getAllOutgoingIncidentNotifications
    getAllOutgoingIncidentNotifications$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getAllOutgoingIncidentNotifications),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.LIST_ALL_OUTGOING_NOTIFICATIONS,
                fetchPolicy: 'network-only',
            }).pipe(
                this.notificationService.catchError('Problem occurred while getting incidentNotifications'),
                map(({data}: any) => {
                    if (data) {
                        const outgoingStatuses = data?.getMyInstIncidentsOutByStatusStatistics?.data;
                        let counts = {
                            id: IncidentNotificationType.client,
                            outgoingStatuses,
                        };

                        this.store.dispatch(fromActions.upsertIncidentNotification({incidentNotification: counts}));
                    }
                })
            );
        })
    ), {dispatch: false});

    // getMyIndividualIncidentsOutByStatusStatistics
    getMyIndividualIncidentsOutByStatusStatistics = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getMyIndividualIncidentsOutByStatusStatistics),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.LIST_ALL_OUTGOING_NOTIFICATIONS_FOR_INDIVIDUALS,
                fetchPolicy: 'network-only',
            }).pipe(
                this.notificationService.catchError('Problem occurred while getting incidentNotifications'),
                map(({data}: any) => {
                    if (data) {
                        const outgoingStatuses = data?.getMyIndividualIncidentsOutByStatusStatistics?.data;
                        let counts = {
                            id: IncidentNotificationType.client,
                            outgoingStatuses,
                        };
                        this.store.dispatch(fromActions.upsertIncidentNotification({incidentNotification: counts}));
                    }
                })
            );
        })
    ), {dispatch: false});

    // getAllIncidentNotificationsDepartment
    getMyInstDeptIncidentsByStatusStatistics$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getMyInstDeptIncidentsByStatusStatistics),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.GET_SECTION_NOTIFICATIONS,
                fetchPolicy: 'network-only',
            }).pipe(
                this.notificationService.catchError('Problem occurred while getting section notitification'),
                map(({data}: any) => {
                    if (data) {
                        const sectionStatuses = data?.getMyInstDeptIncidentsByStatusStatistics?.data;
                        let counts = {
                            id: IncidentNotificationType.section,
                            sectionStatuses,
                        };

                        this.store.dispatch(fromActions.upsertIncidentNotification({incidentNotification: counts}));
                    }
                })
            );
        })
    ), {dispatch: false});

    // getDeptIncidentsByStatusStatistics
    getDeptIncidentsByStatusStatistics$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getDeptIncidentsByStatusStatistics),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.GET_DEPARTMENT_NOTIFICATIONS,
                variables: {departmentUuid: action?.departmentUuid},
                fetchPolicy: 'network-only',
            }).pipe(
                this.notificationService.catchError('Problem occurred while getting department notitification'),
                map(({data}: any) => {
                    if (data) {
                        const departmentStatus = data?.getDeptIncidentsByStatusStatistics?.data;
                        let counts = {
                            id: IncidentNotificationType.departmental,
                            departmentStatus,
                        };

                        this.store.dispatch(fromActions.upsertIncidentNotification({incidentNotification: counts}));
                    }
                })
            );
        })
    ), {dispatch: false});


    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private store: Store<AppState>,
        private notificationService: NotificationService,
        private paginationService: PaginationService,
        private responseCodesService: ResponseCodeService,
    ) {
    }

}
