import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { ClientIdentification } from './client-identification.model';
import { PageableParam } from 'src/app/interfaces/global.interface';

export const loadClientIdentifications = createAction(
  '[ClientIdentification/API] Load Client intification',
  props<{ clientIdentifications: ClientIdentification[] }>()
);

export const addClientIdentification = createAction(
  '[ClientIdentification/API] Add Client Identification',
  props<{ clientIdentification: ClientIdentification }>()
);

export const upsertClientIdentification = createAction(
  '[ClientIdentification/API] Upsert Client Identification',
  props<{ clientIdentification: ClientIdentification }>()
);

export const addClientIdentifications= createAction(
  '[ClientIdentification/API] Add ClientIdentifications',
  props<{ clientIdentifications: ClientIdentification[] }>()
);

export const upsertClientIdentifications= createAction(
  '[ClientIdentification/API] Upsert ClientIdentifications',
  props<{ clientIdentifications: ClientIdentification[] }>()
);

export const updateClientIdentification = createAction(
  '[ClientIdentification/API] Update Client Identification',
  props<{ clientIdentification: Update<ClientIdentification> }>()
);

export const updateClientIdentifications= createAction(
  '[ClientIdentification/API] Update ClientIdentifications',
  props<{ clientIdentifications: Update<ClientIdentification>[] }>()
);

export const deleteClientIdentification = createAction(
  '[ClientIdentification/API] Delete Client Identification',
  props<{ id: number }>()
);

export const deleteClientIdentifications= createAction(
  '[ClientIdentification/API] Delete ClientIdentifications',
  props<{ ids: number[] }>()
);

export const clearClientIdentifications= createAction(
  '[ClientIdentification/API] Clear ClientIdentifications'
);

export const findInstitutionClientsLocalCodes = createAction(
  '[ClientIdentification/API] findInstitutionClientsLocalCodes Identifications',
  props<{ serviceUuids?: string[]; pageable?: PageableParam, searchTerm?: string,  institutionUuid: string}>()
);

export const createUpdateInstitutionClientLocalCodes = createAction(
  '[ClientIdentification/API] createUpdateInstitutionClientLocalCodes Identifications',
  props<{ identificationData: any, otherOptions: {serviceUuids?: string[]; pageable?: PageableParam, searchTerm?: string,  institutionUuid: string}}>()
);