import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { StorageService } from 'src/app/services/storage.service';
import { DynamicFormService } from 'src/app/shared/components/dynamic-forms-components/dynamic-form.service';
import { FieldConfig } from 'src/app/shared/components/dynamic-forms-components/field.interface';
import { approveResolvedProblem, assignProblemToDept, closeProblem, resolveProblem } from 'src/app/store/entities/problem/problem.actions';
import { Problem } from 'src/app/store/entities/problem/problem.model';
import { listAllDepartments } from 'src/app/store/entities/settings/department/department.actions';
import { Department } from 'src/app/store/entities/settings/department/department.model';
import { selectModifiedDepartments } from 'src/app/store/entities/settings/department/department.selectors';
import { getEmployeesByDepartmentCode } from 'src/app/store/entities/settings/user/user.actions';
import { selectModifiedUsers } from 'src/app/store/entities/settings/user/user.selectors';
import { AppState } from 'src/app/store/reducers';
import * as formConfigs from './take-action-form';
import { Observable } from 'rxjs';
import { assignIncident } from 'src/app/store/entities/incident-assignment/incident-assignment.actions';

@Component({
  selector: 'app-take-action',
  templateUrl: './take-action.component.html',
  styleUrls: ['./take-action.component.scss']
})
export class TakeActionComponent implements OnInit {

  title: string;
  problem: Problem;
  assignType: string;
  fields: FieldConfig[];
  commentForm: UntypedFormGroup;
  departments$: Observable<Department[]>;
  userId: number;
  departmentId: number;
  institutionId: number;
  problemId: number;

  constructor(
    private store: Store<AppState>,
    private storageService: StorageService,
    private dynamicFormService: DynamicFormService,
  ) {
    this.userId = this.storageService.getItem('userId');
    this.institutionId = Number(this.storageService.getItem('institutionId'));

      if(this.assignType === 'dept'){
        // this.problemId = data.problemId;
        //  
        this.title = 'Assign to department';
        this.fields = formConfigs.deptfields;
        this.store.dispatch(listAllDepartments());
      } else if(this.assignType === 'personnel'){
        this.title = 'Assign Personnel';
        // this.problem = data.problem;
        //  
        this.fields = formConfigs.personnelFields;
        this.departmentId = this.storageService.getItem('departmentId');
        this.store.dispatch(getEmployeesByDepartmentCode({departmentCode: this.problem?.departmentCode}));
        this.fields = formConfigs.personnelFields;
      } else if(this.assignType === 'resolve'){
        this.title = 'Resolve Problem';
        // this.problem = data.problem;
        //  
        this.fields = formConfigs.resolveFields;
        this.departmentId = this.storageService.getItem('departmentId');
        this.store.dispatch(getEmployeesByDepartmentCode({departmentCode: this.problem?.departmentCode}));
        this.fields = formConfigs.resolveFields;
      } else if(this.assignType === 'return'){
        this.title = 'Recheck Problem';
        // this.problem = data.problem;
        this.fields = formConfigs.recheckFields;
      }
  }

  ngOnInit(): void {

    this.departments$ = this.store.pipe(select(selectModifiedDepartments));

    this.fields = this.fields.map(field => {
      if(this.assignType === 'dept'){
        if (field.key === 'department') {
          field.options = this.departments$.pipe(
            map(departments => departments?.length > 0 ? (departments.map((department) =>
            ({name: department?.departmentName, value: department?.departmentCode}))) : [])
          );
        }
      } else if (this.assignType === 'personnel'){
        if (field.key === 'userIds') {
          field.options = this.store.pipe(select(selectModifiedUsers)).pipe(
            map(users => users ? users.map(user => ({ name: user?.fullName, value: user?.id })) : [])
          );
        }
      }
      else if (this.assignType === 'return'){
        if (field.key === 'decision') {
          field.options = [
            {
              name: 'APPROVE',
              value: 'APPROVED'
            },
            {
              name: 'REJECT',
              value: 'REJECTED'
            },
        ]
        }
      }

      return field;
    });
    this.commentForm = this.dynamicFormService.createControl(this.fields, null);
  }

  submitForm(data): void {

    if(this.assignType === 'dept'){
      // assign problem to department
      this.store.dispatch(assignProblemToDept({ department: data.department, problemId: this.problemId }));
    } else if(this.assignType === 'personnel'){
      
      // assign problem to user
      this.store.dispatch(assignIncident({ IncidentAssignmentDto: data,  }));
    } else if (this.assignType === 'resolve'){
      this.store.dispatch(resolveProblem({ remarks: data.remarks, problemId: this.problem?.id }));
    } else if (this.assignType === 'return'){
      this.store.dispatch(approveResolvedProblem(
        { decision: data.decision, comment: data.comment, problemId: this.problem?.id }));
    } else if (this.assignType === 'close'){
      this.store.dispatch(closeProblem( {  problemId: this.problem?.id }));
    }
  }

}
