<ng-container>
    <div class="row my-4">
        <ng-container *ngFor="let stat of issueCountStatistics">
            <div class="col-md-3 col-lg-3 col-xl-2">
                <div class="dashbox flex-lg-column flex-xxl-row mb-3">
                    <span>{{stat.title}}</span>
                    <span class="count mx-lg-auto mt-lg-2 ml-xxl-auto mr-xxl-0 mt-xxl-0">{{stat.rowCount | number}}</span>
                </div>
            </div>
        </ng-container>

        <div class="col-md-3 col-lg-3 col-xl-2">
            <div class="dashbox flex-column py-3 my-0 mb-3">
                <span>Storage Usage</span>
                <span class="count text-center fs-4 mx-0 my-2">
                    {{storageUsage?.folderSizeInByte}} /{{storageUsage?.storageSizeGivenInGb}}GB </span>
                <div class="border border-1 border-secondary w-100">
                    <div [ngClass]="{'bg-success': storageUsage?.usagePercentage <= 33, 'bg-warning': storageUsage?.usagePercentage > 33 && storageUsage?.usagePercentage <= 66, 'bg-danger': storageUsage?.usagePercentage > 66}" [ngStyle]="{'width.%': storageUsage?.usagePercentage}" style="height:4px;"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="d-grid">
        <div class="open-incidents p-3">
            <div class="light-bg p-3">
                <ng-container *ngIf="openIncidentsOptions">
                    <apx-chart [chart]="openIncidentsOptions.chart" [dataLabels]="openIncidentsOptions.dataLabels" [grid]="openIncidentsOptions.grid" [legend]="openIncidentsOptions.legend" [markers]="openIncidentsOptions.markers" [plotOptions]="openIncidentsOptions.plotOptions" [series]="openIncidentsOptions.series" [stroke]="openIncidentsOptions.stroke" [subtitle]="reportedIssuesSubtitle" [title]="openIncidentsOptions.title" [xaxis]="openIncidentsOptions.xaxis" [yaxis]="openIncidentsOptions.yaxis"></apx-chart>
                </ng-container>
            </div>
        </div>
        <div class="closed-incidents p-3">
            <div class="light-bg p-3">
                <ng-container *ngIf="closedIncidentsOptions">
                    <apx-chart [chart]="closedIncidentsOptions.chart" [dataLabels]="closedIncidentsOptions.dataLabels" [fill]="closedIncidentsOptions.fill" [plotOptions]="closedIncidentsOptions.plotOptions" [series]="closedIncidentsOptions.series" [stroke]="closedIncidentsOptions.stroke" [subtitle]="pendingIssuesSubtitle" [title]="closedIncidentsOptions.title" [tooltip]="closedIncidentsOptions.tooltip" [xaxis]="closedIncidentsOptions.xaxis" [yaxis]="closedIncidentsOptions.yaxis"></apx-chart>
                </ng-container>
            </div>
        </div>
        <div class="internal-external-incidents p-3">
            <div class="light-bg p-3 h-100">
                <ng-container *ngIf="internalVsExternalIncidentsChartOptions">
                    <highcharts-chart [Highcharts]="Highcharts" [options]="internalVsExternalIncidentsChartOptions" style="width: 100%; height: 250px; display: block;"></highcharts-chart>
                </ng-container>
            </div>
        </div>
    </div>

    <div class="d-grid-2">
        <div class="figures p-3">
            <div class="light-bg p-3 h-100">
                <div class="card card-stats mb-4 mb-xl-0">
                    <div class="card-body p-3">
                        <div class="row">
                            <div class="col">
                                <h5 class="card-title text-uppercase font-weight-bold mb-0">Total Issues</h5>
                                <span class="h5 mb-0 d-flex justify-content-between align-items-end">
                                    <span>
                                        {{totalReportedIssues | number}}
                                    </span>
                                    <!-- <small class="fs-6 text-success">40 Today</small> -->
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card card-stats mb-4 mb-xl-0">
                    <div class="card-body p-3">
                        <div class="row">
                            <div class="col">
                                <h5 class="card-title text-uppercase font-weight-bold mb-0">Closed Issues </h5>
                                <span class="h5 mb-0 d-flex justify-content-between align-items-end">
                                    <span>
                                        {{totalClosedIssues | number}}<small class="fs-6">/{{totalReportedIssues | number}}</small>
                                    </span>
                                    <small class="fs-6 text-success">{{totalIssuesClosedToday | number}} Today</small>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <ng-container *ngFor="let stat of groupedIssueCategories">
                    <div class="card card-stats mb-4 mb-xl-0">
                        <div class="card-body p-3">
                            <div class="row">
                                <div class="col">
                                    <h5 class="card-title text-uppercase font-weight-bold mb-0">Closed {{stat.category}}</h5>
                                    <span class="h5 mb-0 d-flex justify-content-between align-items-end">
                                        <span>
                                            {{stat.closed| number}} <small class="fs-6">/{{stat.closed + stat.open| number}}</small>
                                        </span>
                                        <small class="fs-6 text-success">{{stat.today| number}} Today</small>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <div class="card card-stats mb-4 mb-xl-0" *ngIf="incidentReport">
                    <div class="card-body p-3">
                        <div class="row">
                            <div class="col">
                                <h5 class="card-title text-uppercase font-weight-bold mb-0">Total Assets</h5>
                                <span class="h5 mb-0">{{incidentReport.incident | number}}</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="pipelines p-3">
            <div class="light-bg p-3 h-100">
                <ng-container *ngIf="incidentReport">
                    <highcharts-chart [Highcharts]="Highcharts" [options]="pietOptions" style="width: 100%; height: 400px; display: block;"></highcharts-chart>
                </ng-container>
            </div>
        </div>
        <div class="statuses p-3">
            <div class="light-bg p-3 h-100">
                <h4 class="stats-card-title">Top 5 Services With Most Pending Issues</h4>
                <table class="table table-bordered table-hover table-striped">
                    <thead class="thead-primary">
                        <tr class="table-primary">
                            <th class="" scope="col" style="width: 60%;">Service</th>
                            <th class="text-center" scope="col">Pending Issues</th>
                        </tr>
                    </thead>
                    <tbody>

                        <ng-container *ngFor="let stat of topPendingServices">
                            <tr class="">
                                <td scope="row">{{stat.title}}</td>
                                <td class="fs-4 fw-bold text-center">{{stat.rowCount | number}}</td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div class="p-3">
                <div class="light-bg p-3 h-100">
                    <table class="table table-bordered table-striped table-hover w-100">
                        <thead>
                            <tr>
                                <th style="width:30%">Section</th>
                                <th style="width:10%">Issue</th>
                                <th style="width:10%">Reported</th>
                                <th style="width:10%">Closed</th>
                                <th style="width:10%">Pending</th>
                            </tr>
                        </thead>
                        <tbody>

                            <ng-container *ngFor="let section of groupedBySection |keyvalue">
                                <ng-container *ngFor="let issues of groupedBySection.get(section.key) let i = index">
                                    <tr>
                                        <th *ngIf="i == 0" class="text-center align-middle" [attr.rowspan]="groupedBySection.get(section.key).length">{{section.key}}</th>
                                        <td>{{issues.category}}</td>
                                        <td>{{issues.total}}</td>
                                        <td>{{issues.closed}}</td>
                                        <td>{{issues.pending}}</td>
                                    </tr>
                                </ng-container>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</ng-container>