import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { TascoIsceGroup } from './tasco-isce-group.model';
// import { Pageable } from '../../pageable/pageable.model';
import { PageableParam } from 'src/app/interfaces/global.interface';

export const loadTascoIsceGroups = createAction(
  '[TascoIsceGroup/API] Load TascoIsceGroups', 
  props<{ tascoIsceGroups: TascoIsceGroup[] }>()
);

export const addTascoIsceGroup = createAction(
  '[TascoIsceGroup/API] Add TascoIsceGroup',
  props<{ tascoIsceGroup: TascoIsceGroup }>()
);

export const upsertTascoIsceGroup = createAction(
  '[TascoIsceGroup/API] Upsert TascoIsceGroup',
  props<{ tascoIsceGroup: TascoIsceGroup }>()
);

export const addTascoIsceGroups = createAction(
  '[TascoIsceGroup/API] Add TascoIsceGroups',
  props<{ tascoIsceGroups: TascoIsceGroup[] }>()
);

export const upsertTascoIsceGroups = createAction(
  '[TascoIsceGroup/API] Upsert TascoIsceGroups',
  props<{ tascoIsceGroups: TascoIsceGroup[] }>()
);

export const updateTascoIsceGroup = createAction(
  '[TascoIsceGroup/API] Update TascoIsceGroup',
  props<{ tascoIsceGroup: Update<TascoIsceGroup> }>()
);

export const updateTascoIsceGroups = createAction(
  '[TascoIsceGroup/API] Update TascoIsceGroups',
  props<{ tascoIsceGroups: Update<TascoIsceGroup>[] }>()
);

export const deleteTascoIsceGroup = createAction(
  '[TascoIsceGroup/API] Delete TascoIsceGroup',
  props<{ id: number }>()
);

export const deleteTascoIsceGroups = createAction(
  '[TascoIsceGroup/API] Delete TascoIsceGroups',
  props<{ ids: number[] }>()
);

export const clearTascoIsceGroups = createAction(
  '[TascoIsceGroup/API] Clear TascoIsceGroups'
);



// Custom Actions
export const getAllTascoIsceGroups = createAction(
  '[TascoIsceGroup/API] Get All Academic Institutions'
);

export const getAllActiveTascoIsceGroupsByStatus = createAction(
  '[TascoIsceGroup/API] Get All Active Academic Institutions By Status',
  props<{ active: boolean }>()
);

export const getTascoIsceGroupByUid = createAction(
  '[TascoIsceGroup/API] Get Academic Insitution By Uid',
  props<{ uid: string }>()
);

export const saveTascoIsceGroup = createAction(
  '[TascoIsceGroup/API] Save Academic Institution',
  props<{ tascoIsceGroupDto: TascoIsceGroup }>()
);

// export const modifyTascoIsceGroup = createAction(
//   '[TascoIsceGroup/API] Modify Academic Insitution',
//   props<{ tascoIsceGroupDto: TascoIsceGroup }>()
// );

export const removeTascoIsceGroup = createAction(
  '[TascoIsceGroup/API] Remove Academic Institution',
  props<{ uid:string, id?: number }>()
);

export const getTascoIsceGroupObjectByUid = createAction(
  '[TascoIsceGroup/API] Get Academic Insitution Object BY Uid',
  props<{ uid: string }>()
);

export const activateTascoIsceGroup = createAction(
  '[TascoIsceGroup/API] Activate Academic Insitution',
  props<{ uid: string }>()
);


export const getAllTascoIsceGroupsPageable = createAction(
  '[TascoIsceGroup/API] Get All Academic Institution Pageable',
  props<{ pageableParam?: PageableParam }>()
);

export const getGroupsByCategoryAndGroup = createAction(
  '[TascoIsceGroup/API] Get Group By category and Group',
  props<{ group?:string, category?:string }>()
);