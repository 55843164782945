<!-- Filter -->
<div class="table-filter">
    <div class="row">
      <div class="col-md-12">
        <mat-form-field class="search-form-field" floatLabel="never">
          <mat-icon matPrefix>search</mat-icon>
          <input
            (keyup)="delayedFilter()"
            [(ngModel)]="searchKey"
            autocomplete="off"
            matInput
            placeholder="Search"
          />
          <button
            (click)="onSearchClear()"
            *ngIf="searchKey"
            arial-label="Clear"
            mat-button
            mat-icon-button
            matSuffix
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>
  </div>
  <!-- end of Filter -->
  
  <div class="mat-elevation-z8">
    <mat-table [dataSource]="listData" matSort (matSortChange)="sortChange($event)">
      <ng-container *ngFor="let tableData of objectKeys(columnHeader); let i = index" >
        <ng-container *ngIf="i == 0">
          <!-- SN Column -->
          <ng-container matColumnDef="{{ tableData }}">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ columnHeader[tableData] }}
            </mat-header-cell>
            <mat-cell *matCellDef="let element; let i = index">
              {{ i + 1 + myPaginator.pageIndex * myPaginator.pageSize }}
            </mat-cell>
          </ng-container>
          <!-- end of SN Column -->
        </ng-container>
  
        <ng-container *ngIf="!actionColumn">
          <ng-container *ngIf="i > 0">
            <ng-container matColumnDef="{{ tableData }}">
              <mat-header-cell *matHeaderCellDef mat-sort-header
                >{{ columnHeader[tableData] }}
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <span [innerHTML]="element[tableData]"> </span>
              </mat-cell>
            </ng-container>
          </ng-container>
        </ng-container>
  
        <ng-container *ngIf="actionColumn">
          <ng-container *ngIf="i > 0 && i < objectKeys(columnHeader).length - 1">
            <ng-container matColumnDef="{{ tableData }}">
              <mat-header-cell *matHeaderCellDef mat-sort-header
                >{{ columnHeader[tableData] }}
              </mat-header-cell>
  
              <mat-cell *matCellDef="let element">
                <span [innerHTML]="element[tableData]"> </span
              ></mat-cell>
            </ng-container>
          </ng-container>
  
          <ng-container *ngIf="i == objectKeys(columnHeader).length - 1">
            <ng-container matColumnDef="{{ tableData }}">
              <mat-header-cell *matHeaderCellDef mat-sort-header
                >{{ columnHeader[tableData] }}
              </mat-header-cell>
              <mat-cell *matCellDef="let row">
                <ng-container
                  [ngTemplateOutlet]="actions"
                  [ngTemplateOutletContext]="{ data: row }"
                ></ng-container>
              </mat-cell>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
  
      <!-- Table Configurations -->
      <mat-header-row
        *matHeaderRowDef="objectKeys(columnHeader)"
      ></mat-header-row>
      <mat-row *matRowDef="let row; columns: objectKeys(columnHeader)"></mat-row>
      <!-- end of Table Configurations -->
  
      <!-- If No Data Found -->
      <mat-footer-row
        *matFooterRowDef="['noData']"
        [ngClass]="{ hide: !(listData != null && listData.data.length == 0) }"
      ></mat-footer-row>
      <ng-container matColumnDef="noData">
        <mat-footer-cell *matFooterCellDef colspan="8">No Data</mat-footer-cell>
      </ng-container>
      <!-- end if no Data Found -->
  
      <!-- Loading Data Message -->
      <mat-footer-row
        *matFooterRowDef="['loading']"
        [ngClass]="{ hide: listData != null }"
      ></mat-footer-row>
      <ng-container matColumnDef="loading">
        <mat-footer-cell *matFooterCellDef colspan="8"
          >Loading ...</mat-footer-cell
        >
      </ng-container>
      <!-- end of Loading Data Message -->
    </mat-table>
  
    <mat-paginator
      #myPaginator
      [pageSizeOptions]="pageSizeOptions"
      [pageSize]="pageSize"
      showFirstLastButtons
      (page)="handlePage($event)"
      [length]="dataLength"
    >
    </mat-paginator>
  </div>
  