import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { Subscription } from 'rxjs';
import { ServiceUserType } from 'src/app/interfaces/global.interface';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';
import { StorageService } from 'src/app/services/storage.service';
import { FieldConfig } from 'src/app/shared/components/dynamic-forms-components/field.interface';
import { DynamicFormService } from '../dynamic-forms-components/dynamic-form.service';
import * as formConfigs from './home-form-fields';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  hide = true;
  staffLoginForm: UntypedFormGroup;

  subscription: Subscription = new Subscription();
  departmentForm: UntypedFormGroup;
  resetPasswordForm: UntypedFormGroup;
  currentYear = new Date().getFullYear();
  loginView = false;
  loading = false;
  renderer: any;
  status: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private route: Router,
    private authService: AuthService,
    private notificationService: NotificationService,
    private storageService: StorageService
    ) { }

  ngOnInit(): void {

    this.staffLoginForm = this.fb.group({
      username : [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required]]
    });
    this.resetPasswordForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
    });

  }

  changeView() {
    this.loginView = !this.loginView;
  }

  toggleFormType(){
    this.status = !this.status; 
  }

  async login(auth) {
    await this.authService.login(auth);
    await this.authService.authRole();
    let serviceUserType = this.storageService.getItem('serviceUserType');
     
    if(serviceUserType == ServiceUserType.client){
      this.route.navigateByUrl('/individual');
    } else {
      this.route.navigateByUrl('/landing');

    }
  }

  resetPassword(formData: any) {

    this.authService.requestResetLink(formData).subscribe( data => {
       
    })

    this.subscription.add(
      this.authService.requestResetLink(formData).subscribe( data => {
         
        if(data === 'SUCCESS'){
          this.notificationService.successMessage('Submitted successfully');
        } else {
          return this.notificationService.errorMessage('failed to submit request');
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
