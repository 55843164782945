import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/reducers';
import { Observable, Subscription } from 'rxjs';

import { Sort } from '@angular/material/sort';
import {
  PageableParam,
  SearchFieldsDtoInput,
} from '../../../../interfaces/global.interface';
import { SettingsService } from '../../../../services/settings.service';
import {
  TableColumn,
  TableColumnType,
} from '../../../../interfaces/table.interface';
import { SelectionModel } from '@angular/cdk/collections';
import { selectPage } from 'src/app/store/entities/pageable/pageable.selectors';

@Component({
  selector: 'app-table-pageable',
  templateUrl: './table-pageable.component.html',
  styleUrls: ['./table-pageable.component.scss'],
})
export class TablePageableComponent implements OnInit, OnDestroy {
  @Input() tableColumns: TableColumn[];
  @Input() currentPage = 0;
  @Input() pageSize = 10;
  @Input() tableCssClass: string[];
  @Input() tableData$: Observable<any>;
  @Input() hasActionsColumn = true;
  @Input() pageSizeOptions: number[] = [10, 50, 100, 250];
  @Input() searchFields: SearchFieldsDtoInput[];
  cacheSearchFields: SearchFieldsDtoInput[];
  cacheTableData: any;
  @ContentChild(TemplateRef, { static: true }) actions: TemplateRef<any>;

  objectKeys = Object.keys;
  tableColumnType = TableColumnType;
  @Output() totalElements = new EventEmitter<number>();
  cacheTotalElements: number;
  @Output() pageable = new EventEmitter<PageableParam>();
  @Output() selectionChange = new EventEmitter<any>();
  subscriptions: Subscription = new Subscription();
  selection = new SelectionModel<any>(true, []);

  totalPages = 1;
  pagination = [];
  sortKey: string;
  sortDirectionKey: string;
  searchKey: string;

  constructor(
    private store: Store<AppState>,
    private settingsService: SettingsService
  ) {
    this.cacheSearchFields = this.searchFields;
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.store.select(selectPage).subscribe((page) => {
        if (page != null) {
          this.totalPages = page?.totalPages;
          this.cacheTotalElements = page?.totalElements;
          this.totalElements.emit(page?.totalElements);
          this.pagination = this.settingsService.generatePagination(
            this.currentPage,
            page?.totalPages
          );
        }
      })
    );
    this.subscriptions.add(
      this.tableData$.subscribe((tableData) => {
        if (tableData) {
          this.cacheTableData = tableData;
        }
      })
    );

    this.subscriptions.add(
      this.selection.changed.subscribe((selectedRow) => {
        if (selectedRow) {
          this.selectionChange.emit(this.selection.selected);
        }
      })
    );
  }

  loadData() {
    this.pageable.emit({
      first: this.currentPage,
      size: this.pageSize,
      sortBy: this.sortKey,
      sortDirection: this.sortDirectionKey,
      searchFields: this.searchFields,
    });
    this.totalElements.emit(this.cacheTotalElements);
  }

  getPage(pageIndex: number) {
    this.currentPage = pageIndex;
    this.pagination = this.settingsService.generatePagination(
      pageIndex,
      this.totalPages
    );
    this.loadData();
  }

  onSearchClear() {
    this.searchKey = null;
    this.searchFields = null;
    this.loadData();
  }

  filterTable() {
    this.searchKey = this.searchKey.trim().toLowerCase();
    this.searchFields = this.cacheSearchFields?.map((searchField) => {
      return {
        fieldName: searchField?.fieldName,
        searchType: searchField?.searchType,
        fieldValue: this.searchKey,
      };
    });
    this.loadData();
  }

  sortData(event: Sort) {
    this.sortKey = event.active;
    this.sortDirectionKey = event.direction ?? this.sortDirectionKey;
    this.loadData();
  }

  getPageSize(page: number) {
    this.pageSize = page;
    this.loadData();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;

    return numSelected === this.cacheTableData.data.length;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
