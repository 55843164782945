import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { CourseMaterialFileDtoInput, MaterialFile } from './material-file.model';

export const loadMaterialFiles = createAction(
  '[MaterialFile/API] Load MaterialFiles', 
  props<{ materialFiles: MaterialFile[] }>()
);

export const addMaterialFile = createAction(
  '[MaterialFile/API] Add MaterialFile',
  props<{ materialFile: MaterialFile }>()
);

export const upsertMaterialFile = createAction(
  '[MaterialFile/API] Upsert MaterialFile',
  props<{ materialFile: MaterialFile }>()
);

export const addMaterialFiles = createAction(
  '[MaterialFile/API] Add MaterialFiles',
  props<{ materialFiles: MaterialFile[] }>()
);

export const upsertMaterialFiles = createAction(
  '[MaterialFile/API] Upsert MaterialFiles',
  props<{ materialFiles: MaterialFile[] }>()
);

export const updateMaterialFile = createAction(
  '[MaterialFile/API] Update MaterialFile',
  props<{ materialFile: Update<MaterialFile> }>()
);

export const updateMaterialFiles = createAction(
  '[MaterialFile/API] Update MaterialFiles',
  props<{ materialFiles: Update<MaterialFile>[] }>()
);

export const deleteMaterialFile = createAction(
  '[MaterialFile/API] Delete MaterialFile',
  props<{ id: string }>()
);

export const deleteMaterialFiles = createAction(
  '[MaterialFile/API] Delete MaterialFiles',
  props<{ ids: string[] }>()
);

export const clearMaterialFiles = createAction(
  '[MaterialFile/API] Clear MaterialFiles'
);

// === CUSTOM FILES ====
export const saveAndUpdateCourseMaterialFile = createAction(
  '[MaterialFile/API] save And Update MaterialFile',
  props<{ courseMaterialFileDto: CourseMaterialFileDtoInput }>()
);

export const getAttachmentByPath = createAction(
  '[MaterialFile/API] Get Attachment By Path',
  props<{ filePath: string }>()
);