import { gql } from "apollo-angular"

export const folderFields = `
    createdByInstitution
    folderDescription
    folderName
    id
    uuid
`;

export const SAVE_AND_UPDATE_COURSE_MATERIAL_FOLDER = gql`
    mutation saveAndUpdateCourseMaterialFolder($courseMaterialFolderDto: CourseMaterialFolderDtoInput){
        saveAndUpdateCourseMaterialFolder(courseMaterialFolderDto:$courseMaterialFolderDto){
            code
            message
            data{
                ${folderFields}
            }
        }
    }
`;

export const GET_ALL_COURSE_MATERIAL_FOLDERS = gql`
    query getAllCourseMaterialFolders($pageParam: PageableParamInput){
        getAllCourseMaterialFolders(pageParam:$pageParam){
            content{
                ${folderFields}
            }
        }
    }
`;

export const GET_COURSE_MATERIAL_BY_UUID = gql`
    query getCourseMaterialFoldersByUuid($uuid: String){
        getCourseMaterialFoldersByUuid(uuid:$uuid){
            code 
            message
            data{
                createdAt
                files{
                    fileName
                    filePath
                    id
                }
                folderDescription
                folderName
                id
                occupations{
                    occupation{
                        name
                    }
                }
                uuid
            }
        }
    }
`;

export const GET_COURSE_MATERIAL_FOLDERS_BY_APTITUDE_TEST_UUID = gql`
    query getCourseMaterialFoldersByAptitudeTestUuid($aptitudeTestUuid: String){
        getCourseMaterialFoldersByAptitudeTestUuid(aptitudeTestUuid:$aptitudeTestUuid){
            code
            message
            dataList{
                ${folderFields}
            }
        }
    }
`;