import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from '@ngrx/store';
import { environment } from '../../../environments/environment';
import * as fromAuthUser from '../entities/auth-user/auth-user.reducer';
import * as fromInstitution from '../entities/settings/institution/institution.reducer';
import * as fromUser from '../entities/settings/user/user.reducer';
import * as fromRole from '../entities/settings/role/role.reducer';
import * as fromRolePermission from '../entities/settings/role-permission/role-permission.reducer';
import * as fromPermission from '../entities/settings/permission/permission.reducer';
import * as fromDepartment from '../entities/settings/department/department.reducer';
import * as fromDesignation from '../entities/settings/designation/designation.reducer';
import * as fromOnlineResource from '../entities/settings/online-resource/online-resource.reducer';
import * as fromIncidentType from '../entities/settings/incident-type/incident-type.reducer';
import * as fromIncident from '../entities/incident/incident.reducer';
import * as fromService from '../entities/settings/service/service.reducer';
import * as fromSeverity from '../entities/settings/severity/severity.reducer';
import * as fromChangeManagement from '../entities/change-management/change-management.reducer';
import * as fromSolutionStep from '../entities/solution-step/solution-step.reducer';
import * as fromRoster from '../entities/roster/roster.reducer';
import * as fromIncidentTrail from '../entities/incident-trail/incident-trail.reducer';
import * as fromProblem from '../entities/problem/problem.reducer';
import * as fromIncidentResponse from '../entities/incident-response/incident-response.reducer';
import * as fromHelpdeskEmailNotification from '../entities/helpdesk-email-notification/helpdesk-email-notification.reducer';
import * as fromCalltracker from '../entities/calltracker/calltracker.reducer';
import * as fromCalltrackerSupport from '../entities/calltracker-support/calltracker-support.reducer';
import * as fromBranch from '../entities/settings/branch/branch.reducer';
import * as fromAdministrativeArea from '../entities/settings/administrative-area/administrative-area.reducer';
import * as fromCategory from '../entities/settings/category/category.reducer';
import * as fromServiceType from '../entities/service-type/service-type.reducer';
import * as fromPageable from '../entities/pageable/pageable.reducer';
import * as fromSupplier from '../entities/settings/supplier/supplier.reducer';
import * as fromManufacture from '../entities/settings/manufacture/manufacture.reducer';
import * as fromResourceCategory from '../entities/settings/resource-category/resource-category.reducer';
import * as fromResourceType from '../entities/settings/resource-type/resource-type.reducer';
import * as fromResourceCriticality from '../entities/settings/resource-criticality/resource-criticality.reducer';
import * as fromAssetc from '../entities/asset-management/assetc.reducer';
import * as fromRequestCategory from '../entities/settings/request-category/request-category.reducer';
import * as fromRequestPriority from '../entities/settings/request-priority/request-priority.reducer';
import * as fromChangeRequest from '../entities/change-request/change-request.reducer';
import * as fromKbWorkflow from '../entities/kb-workflow/kb-workflow.reducer';
import * as fromRequestSeverity from '../entities/settings/request-severity/request-severity.reducer';
import * as fromApprovalStage from '../entities/settings/approval-stage/approval-stage.reducer';
import * as fromApprovalLinkage from '../entities/settings/approval-linkage/approval-linkage.reducer';
import * as fromAttachment from '../entities/attachment/attachment.reducer';
import * as fromIncidentAssignment from '../entities/incident-assignment/incident-assignment.reducer';
import * as fromIssueCategory from '../entities/settings/issue-category/issue-category.reducer';
import * as fromConversation from '../entities/conversation/conversation.reducer';
import * as fromPriority from '../entities/priority/priority.reducer';
import * as fromMailConfig from '../entities/settings/mail-config/mail-config.reducer';
import * as fromMail from '../entities/mails/mails.reducer';
import * as fromIncidentNotification from '../entities/notification/notification.reducer';
import * as fromRosterGroups from '../entities/settings/roster-groups/roster-groups.reducer';
import * as fromPage from '../entities/page/page.reducer';
import * as fromClientIdentification from '../entities/client-identification/client-identification.reducer';
import * as fromIncidentResolution from '../entities/incident-resolution/incident-resolution.reducer';
import * as fromIncidentRootCause from '../entities/incident-root-cause/incident-root-cause.reducer';
import * as fromLdapConfiguration from '../entities/ldap-configuration/ldap-configuration.reducer';
import * as fromTascoIsceGroup from '../entities/tasco-isce-group/tasco-isce-group.reducer';
import * as fromAptitudeTest from '../entities/aptitude-test/aptitude-test/aptitude-test.reducer';
import * as fromQuestion from '../entities/aptitude-test/question/question.reducer';
import * as fromTest from '../entities/aptitude-test/test/test.reducer';
import * as fromUserAptitudeTestResult from '../entities/aptitude-test/user-aptitude-test-result/user-aptitude-test-result.reducer';
import * as fromAptitudeTestCandidate from '../entities/aptitude-test/aptitude-test-candidate/aptitude-test-candidate.reducer';
import * as fromMaterialFolder from '../entities/material-folder/material-folder.reducer';
import * as fromMaterialFile from '../entities/material-folder/material-file/material-file.reducer';


export interface AppState {
  modifiedResult: any;
  [fromPageable.pageablesFeatureKey]: fromPageable.State;
  [fromAuthUser.authUsersFeatureKey]: fromAuthUser.State;
  [fromInstitution.institutionsFeatureKey]: fromInstitution.State;
  [fromUser.usersFeatureKey]: fromUser.State;
  [fromRole.rolesFeatureKey]: fromRole.State;
  [fromRolePermission.rolePermissionsFeatureKey]: fromRolePermission.State;
  [fromPermission.permissionsFeatureKey]: fromPermission.State;
  [fromChangeManagement.changeRequestFeatureKey]: fromChangeManagement.State;
  [fromSolutionStep.solutionStepsFeatureKey]: fromSolutionStep.State;  
  [fromDepartment.departmentsFeatureKey]: fromDepartment.State;
  [fromDesignation.designationsFeatureKey]: fromDesignation.State;
  [fromOnlineResource.onlineResourcesFeatureKey]: fromOnlineResource.State;
  [fromIncidentType.incidentTypesFeatureKey]: fromIncidentType.State;
  [fromIncident.incidentsFeatureKey]: fromIncident.State;
  [fromService.servicesFeatureKey]: fromService.State;
  [fromSeverity.severitiesFeatureKey]: fromSeverity.State;
  [fromChangeManagement.changeRequestFeatureKey]: fromChangeManagement.State;
  [fromRoster.rostersFeatureKey]: fromRoster.State;
  [fromIncidentTrail.incidentTrailsFeatureKey]: fromIncidentTrail.State;
  [fromProblem.problemFeatureKey]: fromProblem.State;
  [fromIncidentAssignment.incidentAssignmentsFeatureKey]: fromIncidentAssignment.State;
  [fromIncidentResponse.incidentResponsesFeatureKey]: fromIncidentResponse.State;
  [fromConversation.conversationsFeatureKey]: fromConversation.State;
  [fromHelpdeskEmailNotification.helpdeskEmailNotificationsFeatureKey]: fromHelpdeskEmailNotification.State;
  [fromCalltracker.calltrackerFeatureKey]: fromCalltracker.State;
  [fromCalltrackerSupport.calltrackerSupportsFeatureKey]: fromCalltrackerSupport.State;
  [fromBranch.branchsFeatureKey]: fromBranch.State;  
  [fromAdministrativeArea.administrativeAreasFeatureKey]: fromAdministrativeArea.State;
  [fromServiceType.serviceTypesFeatureKey]: fromServiceType.State;
  [fromIncidentType.incidentTypesFeatureKey]: fromIncidentType.State;
  [fromCategory.CategorysFeatureKey]: fromCategory.State;
  [fromSupplier.SuppliersFeatureKey]: fromSupplier.State;
  [fromManufacture.ManufacturesFeatureKey]: fromManufacture.State;
  [fromResourceCategory.ResourceCategorysFeatureKey]: fromResourceCategory.State;
  [fromResourceType.ResourceTypesFeatureKey]: fromResourceType.State;
  [fromResourceCriticality.ResourceCriticalitysFeatureKey]: fromResourceCriticality.State;
  [fromAssetc.AssetcsFeatureKey]: fromAssetc.State;
  [fromRequestCategory.RequestCategorysFeatureKey]: fromRequestCategory.State;
  [fromRequestPriority.RequestPrioritysFeatureKey]: fromRequestPriority.State;
  [fromChangeRequest.ChangeRequestsFeatureKey]: fromChangeRequest.State;
  [fromKbWorkflow.kbWorkflowsFeatureKey]: fromKbWorkflow.State;
  [fromChangeRequest.ChangeRequestsFeatureKey]: fromChangeRequest.State;
  [fromRequestSeverity.RequestSeveritysFeatureKey]: fromRequestSeverity.State;
  [fromApprovalStage.approvalStagesFeatureKey]: fromApprovalStage.State;
  [fromApprovalLinkage.approvalLinkagesFeatureKey]: fromApprovalLinkage.State;
  [fromAttachment.attachmentsFeatureKey]: fromAttachment.State;
  [fromIssueCategory.issueCategorysFeatureKey]: fromIssueCategory.State;
  [fromPriority.prioritiesFeatureKey]: fromPriority.State;
  [fromMailConfig.mailConfigsFeatureKey]: fromMailConfig.State;
  [fromMail.mailsFeatureKey]: fromMail.State;
  [fromIncidentNotification.incidentNotificationsFeatureKey]: fromIncidentNotification.State;
  [fromRosterGroups.rosterGroupsesFeatureKey]: fromRosterGroups.State;
  [fromPage.pagesFeatureKey]: fromPage.State;
  [fromClientIdentification.clientIdentificationFeatureKey]: fromClientIdentification.State;
  [fromIncidentResolution.incidentResolutionsFeatureKey]: fromIncidentResolution.State;
  [fromIncidentRootCause.incidentRootCausesFeatureKey]: fromIncidentRootCause.State;
  [fromLdapConfiguration.ldapConfigurationsFeatureKey]: fromLdapConfiguration.State,
  [fromTascoIsceGroup.tascoIsceGroupsFeatureKey]: fromTascoIsceGroup.State;
  [fromAptitudeTest.aptitudeTestsFeatureKey]: fromAptitudeTest.State;
  [fromQuestion.questionsFeatureKey]: fromQuestion.State;
  [fromTest.testsFeatureKey]: fromTest.State;
  [fromUserAptitudeTestResult.userAptitudeTestResultsFeatureKey]: fromUserAptitudeTestResult.State;
  [fromAptitudeTestCandidate.aptitudeTestCandidatesFeatureKey]: fromAptitudeTestCandidate.State;
  [fromMaterialFolder.materialFoldersFeatureKey]: fromMaterialFolder.State;
  [fromMaterialFile.materialFilesFeatureKey]: fromMaterialFile.State;




  
  

  
}

export const reducers: ActionReducerMap<AppState> = {
  [fromPageable.pageablesFeatureKey]: fromPageable.reducer,
  [fromAuthUser.authUsersFeatureKey]: fromAuthUser.reducer,
  [fromInstitution.institutionsFeatureKey]: fromInstitution.reducer,
  [fromUser.usersFeatureKey]: fromUser.reducer,
  [fromRole.rolesFeatureKey]: fromRole.reducer,
  [fromRolePermission.rolePermissionsFeatureKey]: fromRolePermission.reducer,
  [fromPermission.permissionsFeatureKey]: fromPermission.reducer,
  [fromDepartment.departmentsFeatureKey]: fromDepartment.reducer,
  [fromDesignation.designationsFeatureKey]: fromDesignation.reducer,
  [fromOnlineResource.onlineResourcesFeatureKey]: fromOnlineResource.reducer,
  [fromIncidentType.incidentTypesFeatureKey]: fromIncidentType.reducer,
  [fromIncident.incidentsFeatureKey]: fromIncident.reducer,
  [fromService.servicesFeatureKey]: fromService.reducer,
  [fromSeverity.severitiesFeatureKey]: fromSeverity.reducer,
  [fromChangeManagement.changeRequestFeatureKey]: fromChangeManagement.reducer,
  [fromSolutionStep.solutionStepsFeatureKey]: fromSolutionStep.reducer,
  [fromRoster.rostersFeatureKey]: fromRoster.reducer,
  [fromIncidentTrail.incidentTrailsFeatureKey]: fromIncidentTrail.reducer,
  [fromProblem.problemFeatureKey]: fromProblem.reducer,
  [fromIncidentAssignment.incidentAssignmentsFeatureKey]: fromIncidentAssignment.reducer,
  [fromIncidentResponse.incidentResponsesFeatureKey]: fromIncidentResponse.reducer,
  [fromHelpdeskEmailNotification.helpdeskEmailNotificationsFeatureKey]: fromHelpdeskEmailNotification.reducer,
  [fromCalltracker.calltrackerFeatureKey]: fromCalltracker.reducer,
  [fromCalltrackerSupport.calltrackerSupportsFeatureKey]: fromCalltrackerSupport.reducer,
  [fromBranch.branchsFeatureKey]: fromBranch.reducer,
  [fromServiceType.serviceTypesFeatureKey]: fromServiceType.reducer,
  [fromAdministrativeArea.administrativeAreasFeatureKey]: fromAdministrativeArea.reducer,
  [fromCategory.CategorysFeatureKey]: fromCategory.reducer,
  [fromSupplier.SuppliersFeatureKey]: fromSupplier.reducer,
  [fromManufacture.ManufacturesFeatureKey]: fromManufacture.reducer,
  [fromResourceCategory.ResourceCategorysFeatureKey]: fromResourceCategory.reducer,
  [fromResourceType.ResourceTypesFeatureKey]: fromResourceType.reducer,
  [fromResourceCriticality.ResourceCriticalitysFeatureKey]: fromResourceCriticality.reducer,
  [fromAssetc.AssetcsFeatureKey]: fromAssetc.reducer,
  [fromRequestCategory.RequestCategorysFeatureKey]: fromRequestCategory.reducer,
  [fromRequestPriority.RequestPrioritysFeatureKey]: fromRequestPriority.reducer,
  [fromChangeRequest.ChangeRequestsFeatureKey]: fromChangeRequest.reducer,
  [fromKbWorkflow.kbWorkflowsFeatureKey]: fromKbWorkflow.reducer,
  [fromRequestSeverity.RequestSeveritysFeatureKey]: fromRequestSeverity.reducer,
  [fromApprovalStage.approvalStagesFeatureKey]: fromApprovalStage.reducer,
  [fromApprovalLinkage.approvalLinkagesFeatureKey]: fromApprovalLinkage.reducer,
  [fromAttachment.attachmentsFeatureKey]: fromAttachment.reducer,
  [fromIssueCategory.issueCategorysFeatureKey]: fromIssueCategory.reducer,
  [fromConversation.conversationsFeatureKey]: fromConversation.reducer,
  [fromPriority.prioritiesFeatureKey]: fromPriority.reducer,
  [fromMailConfig.mailConfigsFeatureKey]: fromMailConfig.reducer,
  [fromMail.mailsFeatureKey]: fromMail.reducer,
  [fromIncidentNotification.incidentNotificationsFeatureKey]: fromIncidentNotification.reducer,
  [fromRosterGroups.rosterGroupsesFeatureKey]: fromRosterGroups.reducer,
  [fromPage.pagesFeatureKey]: fromPage.reducer,
  [fromClientIdentification.clientIdentificationFeatureKey]: fromClientIdentification.reducer,
  [fromIncidentResolution.incidentResolutionsFeatureKey]: fromIncidentResolution.reducer,
  [fromIncidentRootCause.incidentRootCausesFeatureKey]: fromIncidentRootCause.reducer,
  [fromLdapConfiguration.ldapConfigurationsFeatureKey]: fromLdapConfiguration.reducer,
  [fromTascoIsceGroup.tascoIsceGroupsFeatureKey]: fromTascoIsceGroup.reducer,
  [fromAptitudeTest.aptitudeTestsFeatureKey]: fromAptitudeTest.reducer,
  [fromQuestion.questionsFeatureKey]: fromQuestion.reducer,
  modifiedResult: undefined,
  [fromTest.testsFeatureKey]: fromTest.reducer,
  [fromUserAptitudeTestResult.userAptitudeTestResultsFeatureKey]: fromUserAptitudeTestResult.reducer,
  [fromAptitudeTestCandidate.aptitudeTestCandidatesFeatureKey]: fromAptitudeTestCandidate.reducer,
  [fromMaterialFolder.materialFoldersFeatureKey]: fromMaterialFolder.reducer,
  [fromMaterialFile.materialFilesFeatureKey]: fromMaterialFile.reducer,
};

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [] : [];