/*
 * @Author: your name
 * @Date: 2022-04-04 15:19:40
 * @LastEditTime: 2022-04-04 16:26:55
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /research-helpdesk-ui/src/app/store/entities/settings/service/service.model.ts
 */
import {SearchFieldsDtoInput, SearchOperationType} from 'src/app/interfaces/global.interface';
import {Institution} from './../institution/institution.model';
import {User} from '../user/user.model';

export interface Service {
    active: Boolean;
    createdAt: string;
    code: String;
    color: String;
    createdBy: number;
    deleted: Boolean;
    description: String;
    id: number;
    institution: Institution;
    institutionServiceType: InstitutionServiceType;
    name: String;
    serviceVisibility: string;
    subscribers: Client[];
    toBeconfirmedAfterSubscription: Boolean;
    updatedAt: string;
    updatedBy: number;
    uuid: string;
    selected?: boolean;
    // issuesOperatedBranchWise?: boolean;
    operatedBranchWise?: string;
    periodType?: string;
    timePeriod?: string;
    incidentHandlingMethodType?: string;

    incidentInitialCode?: string;
    incidentInternalInitialCode?: string;

    isActive?: string;
}


export interface ClientDtoInput {
    serviceUuid?: string;
    uuid?: string;
}


export interface InstitutionServiceType {
    active?: boolean;
    createdAt?: string;
    createdBy?: number;
    deleted?: boolean;
    description?: string;
    id?: number;
    name?: string;
    updatedAt?: string;
    updatedBy?: number;
    uuid?: string;
}

export interface Client {
    active?: boolean;
    clientCode?: string;
    clientOwners: Institution[];
    clientType: ClientType;
    createdAt?: string;
    createdBy?: number;
    deleted?: boolean;
    id?: number;
    services: Service[];
    updatedAt?: string;
    updatedBy?: number;
    uuid?: string;
    clientInstitution?: Institution;
}

export interface ClientType {
    active?: boolean;
    clientType?: string;
    createdAt?: string;
    createdBy?: number;
    deleted?: boolean;
    id?: number;
    name?: string;
    updatedAt?: string;
    updatedBy?: number;
    uuid?: string;
}

export interface InstitutionServiceDtoInput {
    visibleToOtherInstitution?: boolean;
    institutionId?: number;
    name?: string;
    description?: string;
    institutionServiceTypeId?: number;
    code?: string;
    uuid?: string;
    active?: boolean;
    IncidentHandlingMethodType?: string;

    timePeriod?: number;
    periodType: string;
}

export interface ClientOnBehafDtoInput {
    userUuid?: string;
    serviceUuid?: string;
    serviceUuids?: Service[];
    institutionUuid?: string;
    uuid?: string;
}

export const ServiceSearchFields: SearchFieldsDtoInput[] = [
    {
        fieldName: 'name',
        searchType: SearchOperationType.Like,
        fieldValue: ''
    },
    {
        fieldName: 'code',
        searchType: SearchOperationType.Like,
        fieldValue: ''
    }
];

export interface InstitutionServiceDashboardDto {
    allServiceProviders?: number;
    confirmedSubscriptions?: number;
    myServiceProviders?: number;
    serviceTypeStatistics?: {
        serviceType?: string;
        totalServices?: number;
    }[];
    subScriptionStatisticsList?: {
        confirmedSubscription?: number;
        month?: string;
        unConfirmedSubscription?: number;
        year?: string;
    }[];
    totalServices?: number;
    unConfirmedSubscriptions?: number;
}

