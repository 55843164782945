import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { RequestSeverity } from './request-severity.model';
import * as RequestSeverityActions from './request-severity.actions';

export const RequestSeveritysFeatureKey = 'RequestSeveritys';

export interface State extends EntityState<RequestSeverity> {
  // additional entities state properties
}

export const adapter: EntityAdapter<RequestSeverity> = createEntityAdapter<RequestSeverity>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(RequestSeverityActions.addRequestSeverity,
    (state, action) => adapter.addOne(action.requestSeverity, state)
  ),
  on(RequestSeverityActions.upsertRequestSeverity,
    (state, action) => adapter.upsertOne(action.requestSeverity, state)
  ),
  on(RequestSeverityActions.addRequestSeveritys,
    (state, action) => adapter.addMany(action.requestSeveritys, state)
  ),
  on(RequestSeverityActions.upsertRequestSeveritys,
    (state, action) => adapter.upsertMany(action.requestSeveritys, state)
  ),
  on(RequestSeverityActions.updateRequestSeverity,
    (state, action) => adapter.updateOne(action.requestSeverity, state)
  ),
  on(RequestSeverityActions.updateRequestSeveritys,
    (state, action) => adapter.updateMany(action.requestSeveritys, state)
  ),
  on(RequestSeverityActions.deleteRequestSeverity,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(RequestSeverityActions.deleteRequestSeveritys,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(RequestSeverityActions.loadRequestSeveritys,
    (state, action) => adapter.setAll(action.requestSeveritys, state)
  ),
  on(RequestSeverityActions.clearRequestSeveritys,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
