<app-header></app-header>

<div class="contact-form">
    <div class="first-container border-right">
        <img src="../../../../assets/images/support1_old.png" alt="Support">
    </div>
    <div class="second-container">
        <ng-container *ngIf="!loginView">
            <h2>Sign In</h2>
            <form (ngSubmit)="login(staffLoginForm.value)" [formGroup]="staffLoginForm" autocomplete="off" novalidate>
                    <mat-form-field appearance="outline" class="material-form col-md-12">
                        <mat-label>Email</mat-label>
                        <input formControlName="username" matInput placeholder="Username">
                        <mat-icon matSuffix>email</mat-icon>
                        <mat-error>
                            <span class="error-message" *ngIf="!staffLoginForm.get('username').valid && staffLoginForm.get('username').touched">
                                Email is required filed
                            </span>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="material-form col-md-12">
                        <mat-label>Password</mat-label>
                        <input formControlName="password" matInput placeholder="Password" [type]="hide ? 'password' : 'text'">
                        <a mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </a>
                        <mat-error>
                            <span *ngIf="!staffLoginForm.get('password').valid && staffLoginForm.get('password').touched">
                                Password is required filed
                            </span>
                        </mat-error>
                    </mat-form-field>
                <button [disabled]="!staffLoginForm.valid || loading" type="submit" class="submit custom-btn green-btn">Sign In</button>
            </form>
            <div class="quicklinks d-inline-flex flex-row justify-content-between">
                <a (click)="changeView()" class="forgot-pass">Forgot password?</a>
                <a (click)="changeView()" class="forgot-pass text-info text-right">Forgot link</a>
            </div>
        </ng-container>
        <ng-container *ngIf="loginView">
            <h2>Fill Us A Message</h2>
            <form (ngSubmit)="login(staffLoginForm.value)" [formGroup]="staffLoginForm" autocomplete="off" novalidate>
                    <mat-form-field appearance="outline" class="material-form col-md-12">
                        <mat-label>Email</mat-label>
                        <input formControlName="username" matInput placeholder="Username">
                        <mat-icon matSuffix>authemail</mat-icon>
                        <mat-error>
                            <span class="error-message" *ngIf="!staffLoginForm.get('username').valid && staffLoginForm.get('username').touched">
                                Email is required filed
                            </span>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="material-form col-md-12">
                        <mat-label>Password</mat-label>
                        <input formControlName="password" matInput placeholder="Password" [type]="hide ? 'password' : 'text'">
                        <a mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </a>
                        <mat-error>
                            <span *ngIf="!staffLoginForm.get('password').valid && staffLoginForm.get('password').touched">
                                Password is required filed
                            </span>
                        </mat-error>
                    </mat-form-field>
                <button [disabled]="!staffLoginForm.valid || loading" type="submit" class="submit custom-btn blue-btn">Sign Up</button>
            </form>
            <div class="quicklinks d-inline-flex flex-row justify-content-between">
                <a (click)="changeView()" class="forgot-pass">Forgot password?</a>
                <a (click)="changeView()" class="forgot-pass text-info text-right">Forgot link</a>
            </div>
        </ng-container>
    </div>
  </div>


<div class="ega xs-hide">
    <div class="my-2">
        <strong>&copy; {{currentYear}} </strong> - <strong>All rights reserved</strong>
    </div>
    <div class="py-3">
        <strong>The system is designed and developed by <br><span>
            <a href="https://ega.go.tz" target="_blank" title="e-Government Authority">
                e - Government Authority
            </a></span>
        </strong>
    </div>
</div>