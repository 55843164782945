import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Mail } from './mails.model';
import * as MailActions from './mails.actions';

export const mailsFeatureKey = 'mail';

export interface State extends EntityState<Mail> {
  // additional entities state properties
 
}

export const adapter: EntityAdapter<Mail> = createEntityAdapter<Mail>({
  selectId: (mail: Mail) => mail.messageId
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(MailActions.addMail,
    (state, action) => adapter.addOne(action.mail, state)
  ),
  on(MailActions.upsertMail,
    (state, action) => adapter.upsertOne(action.mail, state)
  ),
  on(MailActions.addMails,
    (state, action) => adapter.addMany(action.mails, state)
  ),
  on(MailActions.upsertMails,
    (state, action) => adapter.upsertMany(action.mails, state)
  ),
  on(MailActions.updateMail,
    (state, action) => adapter.updateOne(action.mail, state)
  ),
  on(MailActions.updateMails,
    (state, action) => adapter.updateMany(action.mails, state)
  ),
  on(MailActions.deleteMail,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(MailActions.deleteMails,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(MailActions.loadMails,
    (state, action) => adapter.setAll(action.mails, state)
  ),
  on(MailActions.clearMails,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
