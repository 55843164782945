import { DatePipe } from '@angular/common';
import { Component, ContentChild, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { SearchFieldsDtoInput, SearchOperationType, PageableParam, initializedPageableParameter, SearchState, SearchDataDtoInput, InputSearchState, PageableResponse } from 'src/app/interfaces/global.interface';
import { AnimationService } from 'src/app/services/animation.service';
import { AuthService } from 'src/app/services/auth.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { SettingsService } from 'src/app/services/settings.service';
import { StorageService } from 'src/app/services/storage.service';
import { AllDetailsComponentComponent } from 'src/app/shared/all-details-component/all-details-component.component';
import { countOccurrences } from 'src/app/shared/functions/functions';
import { valueIncludes } from 'src/app/shared/utils/functions';
import { SearchCombinationType } from 'src/app/store/entities/page/page.model';

@Component({
  selector: 'app-client-provider-hybrid',
  templateUrl: './client-provider-hybrid.component.html',
  styleUrls: ['./client-provider-hybrid.component.scss']
})
export class ClientProviderHybridComponent implements OnInit {
  @Input() tableData: any  = new BehaviorSubject([]);
  @Input() classList = '';
  @Input() allTableData = [];
  @Input() isPageable = false;
  @Input() columnHeader;
  @Input() hasActionsColumn = true;
  @Input() showSearchInput = true;
  @Input() animation = "move-left";
  @Input() dataType = "Data";
  @Input() noData
  @Input() icon = "item";

  @Input() query;
  @Input() queryVariables;
  @Input() mapFunction: (item: any) => {} = (item: any) => ({...item})
  @Input() searchKeys = [];
  @Input() pageSizeOptions: number[] = [10, 50, 100, 250];
  @Input() pageSize: number = 10;
  @Input() searchFields: SearchFieldsDtoInput[];
  @Input() searchOperationType = SearchOperationType.Like;
  @Input() searchDelayTime: number = 2000;
  @Output() pageable = new EventEmitter<PageableParam>();

  @Input() searchStates:SearchState [] = [];
  @Input() alwaysShowFilter:boolean = false;
  @Input() searchStatesValues:SearchState [] = [];
  @Input() additionalQueryParameter = undefined;
  selectedSearchKeys = [];

  @Input() searchDataDtoInput: SearchDataDtoInput = {
    pageableParam: initializedPageableParameter,
    searchCombinationType: SearchCombinationType.Or,
  };

  objectKeys = Object.keys;
  listData: MatTableDataSource<any>;
  searchKey: string;
  previousSelectedKey = null;

  actionColumn: boolean;
  pageIndex: number = 0;
  dataLength: number;
  @Output() onGetDataLength = new EventEmitter();
  @Output() onGetData = new EventEmitter();

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ContentChild(TemplateRef, {static: true}) actions: TemplateRef<any>;

  currentSort = {sortBy: 'id', sortDirection: 'DESC'};
  currentSearch: SearchFieldsDtoInput [] = [];
  hasTouchedSearchInput = false;
  pageParam: any = initializedPageableParameter;
  timerRunning: boolean;

  trigger = 0;
  triggerTimer;

  subscriptions: Subscription = new Subscription();

  constructor(
    private settings: SettingsService,
    private animationService: AnimationService,
    private storage: StorageService,
    private apollo: Apollo,
    private auth: AuthService,
    private pagenationService: PaginationService
    ) {
      
  }
  

  ngOnChanges(changes: SimpleChanges): void {
    this.loadData();
  }


  ngOnInit() {
   this.loadData();
  }


  loadFromPreviousPageEvent(pageDetails){
    let prevTableEvent:PageEvent = {
      length: pageDetails.pagination.totalElements,
      pageIndex: pageDetails.number,
      pageSize: pageDetails.size,
      previousPageIndex: pageDetails.number > 0 ? (pageDetails.number-1) : 0,
    };

    this.handlePage(prevTableEvent);
  }


  loadData(){
    this.actionColumn = this.hasActionsColumn;
    //set search fields // start with previous search data
    this.prepareSearchFields();
    let pageDetails;

    if(!!this.query){
      try{pageDetails = this.storage.getItem(this.getQueryKeyFromQuery(this.query));} 
      catch(e){console.log('SSD Err')}
      this.tableData = new BehaviorSubject([]);
      if(!!pageDetails?.size) this.loadFromPreviousPageEvent(pageDetails); 
      // else this.setTableDataFromQuery(this.query);
    }

    this.subscriptions.add(
      this.tableData?.subscribe((data) => {
        this.listData = new MatTableDataSource(data);
        this.listData.sort = this.sort;
        this.dataLength = data?.length;
        if(!data?.length) return;

        let pageDetailsKey = !!this.query ? this.getQueryKeyFromQuery(this.query) : 'pageDetails';
        try { pageDetails = this.storage.getItem(pageDetailsKey); }
        catch(e){console.log('SSD Err')}
       
        if(!this.isPageable) {
          if(this.paginator.pageSize*(this.paginator.pageIndex+1) < this.dataLength) this.paginator.pageIndex = pageDetails?.pageIndex ?? this.pageIndex;
          this.paginator.pageSize = pageDetails?.pageSize ?? this.pageSize;
          this.listData.paginator = this.paginator;
          //sort // filter as before
        }
       
        if(this.isPageable) {
          this.dataLength = pageDetails?.pagination?.totalElements ?? this.dataLength;
          this.pageSize = pageDetails?.pagination?.size ?? this.pageSize;
          this.dataLength = this.pagenationService.getPage().totalElements;
        }

        this.onGetDataLength.emit(this.dataLength);
        this.onGetData.emit(data);

      })
    );
  }


  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  
  prepareSearchFields(){
    //if Keys provided, use the And operator // since we now force user to select a field to search
    if(this.searchKeys?.length) {
      this.searchFields = this.searchKeys.map(k => ({fieldName:k, searchType:this.searchOperationType}));
      this.setSelectedSearchKeys();
    }

    let activeStates:SearchState[];

    // set the already predifined values else use loaded from storage
    if(this.searchStatesValues?.length) {
      this.searchStates = this.searchStatesValues?.map(x => ({...x,active:true}));
      activeStates = this.searchStatesValues;
    }
    
    else if(this.searchStates?.length && Array.isArray( activeStates = this.storage.getItem('activeSearchStates'))) {
      // get search states from storage // filter the ones actually present in the provide searchStates 
      activeStates = activeStates?.filter(state => valueIncludes(state.key,this.searchStates.map(s=>s.key)));
      // assign values to provided states using the states loaded from storage
      this.searchStates?.forEach(s => activeStates?.find(s_ => (s_.key === s.key) && (s.value = s_.value) && (s.active = true)));
      // if previous states found // mark the search input as touched to make the filters visible on the view
      if((activeStates = this.searchStates?.filter(s => s.active))) this.hasTouchedSearchInput = true;
    }

    //get user typed key from storage
    let userSearchState = <InputSearchState>this.storage.getItem('userSearchState'), includeActiveStates, filterInactive;

    if(userSearchState && userSearchState?.value){
      this.selectedSearchKeys = userSearchState?.keys?.filter(key => this.searchFields?.map(f=>f.fieldName)?.includes(key));
      this.searchKey = userSearchState.value;
      this.resetCurrentSearch(includeActiveStates = false);
    }

    if(activeStates?.length) {
       activeStates.forEach(state => this.updateCurrentSearch(state.key,state.value, filterInactive = !this.searchKey));
    }
 
    // update the searchDto to include values assigned
    this.searchDataDtoInput = {
      ...this.searchDataDtoInput, 
      pageableParam:{
        ...this.searchDataDtoInput.pageableParam, 
        searchFields:this.currentSearch 
      }
    }
  }


  resetCurrentSearch(includeActiveStates = true){
    // assign search string to the searchFields // set current search to searchFields
    if(this.searchKey) this.currentSearch = this.searchFields.map((field) => ({...field, fieldValue: this.searchKey}));
    //if there are selected keys // filter only the fields using the selected keys 
    if(this.searchKey && this.selectedSearchKeys) this.currentSearch = this.currentSearch.filter(f => valueIncludes(f.fieldName, this.selectedSearchKeys))
    // update the searchDto to include values in the active search states 
    if(includeActiveStates) this.searchStates.filter(state => !!state.value).forEach(state => this.updateCurrentSearch(state.key,state.value,false));
  }



  searchCustomState(key,value){
    this.hasTouchedSearchInput = true;
    let activeStates:SearchState[],s:SearchState, filterInactive, includeActiveStates;
    if(!(s=this.searchStates.find(s_ => s_.key === key))) return;
    // if the provided value is the current state's value (clicked twice) reset the value // else assign the new value
    (value === s?.value) ? (s.value = undefined) : (s.value = value);
    //if not pageable, there can only be one active search state (clicke one)
    if(!this.isPageable && !this.allTableData.length) this.searchStates.forEach(s => (s.key !==key) && (s.value = undefined));
    // mark active states
    (activeStates = this.searchStates.filter(state => !!state.value || state.value === false ))?.forEach(state => state.active = true);
    // record states with values in storage for reusing when view is refreshed
    if(activeStates.length) this.storage.setItem('activeSearchStates', activeStates);
    // if user's input is present, reset currentSearch without including searchStates for now
    if(this.searchKey) this.resetCurrentSearch(includeActiveStates = false);
    // upate current search with active states // if we've run reset don't filterInactive
    activeStates?.forEach(state => this.updateCurrentSearch(state.key, state.value, filterInactive = !!!this.searchKey ));
   
    // proceed to query search if pageable or can search local // else just use native filter
    if(this.isPageable || this.allTableData.length) this.handlePage();
    else this.listData.filter = (s?.value)?.toString()?.trim()?.toLowerCase();
  }


  searchAllTableDataLocally(currentSearch: SearchFieldsDtoInput []){
    let resultSet = [...this.allTableData];
    let searchFields = [...currentSearch]
    if(!this.hasActiveStates()) return this.tableData.next(resultSet);

    searchFields.forEach(f => resultSet = resultSet.filter(r => {
      if(typeof f.fieldValue === "number") return r[f.fieldName] === f.fieldValue
      if(f.searchType === SearchOperationType.NotEqual) return !valueIncludes(r[f.fieldName],f.fieldValue);
      else return valueIncludes(r[f.fieldName],f.fieldValue);
    }));

    this.tableData.next(resultSet);
  }


  hasActiveStates(){
    return !!this.searchStates?.find(state => !!state.value || state.value === false);
  }


  updateCurrentSearch(key,value, filterInactive = true){
    let state:SearchState;
    // remove inactive states;
    if(filterInactive) this.currentSearch = this.currentSearch.filter(f => (state = this.searchStates?.find(state => state.key === f.fieldName)) ? state.value : true);
    // remove the selected search field from current search fields
    if(filterInactive) this.currentSearch = this.currentSearch.filter(s => s.fieldName !== key );
    // add the field back with the new values
    this.currentSearch.push({fieldName:key, searchType: state?.searchType ??  SearchOperationType.Like, fieldValue:value});
    // since we are using like to search, we need to add search fields that will exclude similar results to almost similar search values; e.g ICE vs CE
    let otherSimilarValues = this.searchStates?.find(s => s.key === key)?.values?.filter(v => (v !== value) && valueIncludes(v,value));
    otherSimilarValues?.forEach(sv => this.currentSearch.push({fieldName:key, searchType: SearchOperationType.NotEqual, fieldValue:sv}));
  }


  clearSearchCustomState(){
    this.searchStates.forEach(state => (state.value = undefined) || (state.active = false));
    this.storage.setItem('activeSearchStates',[]);
    let includeActiveStates;
    this.resetCurrentSearch(includeActiveStates = false);

    if(this.isPageable || this.allTableData.length) this.handlePage();
    else this.listData.filter = ''.trim().toLowerCase();
  }


  setSelectedSearchKeys(loadData = false){
    let hasSelected = this.selectedSearchKeys.length;
    if(hasSelected) this.previousSelectedKey = [...this.selectedSearchKeys].pop();
    if(!hasSelected && !this.previousSelectedKey) this.selectedSearchKeys = [ ...this.searchKeys].slice(0,1);
    if(!hasSelected && this.previousSelectedKey) this.selectedSearchKeys =  [this.previousSelectedKey];
    if(this.searchKey && loadData) this.delayedFilter(this.searchKey);
  }


  sortChange(e) {
    this.currentSort = {sortBy: e.active, sortDirection: e.direction};
    this.handlePage();
  }


  filterTable() {
    let includeActiveStates;
    this.hasTouchedSearchInput = true;
    // filter static table
    this.listData.filter = this.searchKey?.trim()?.toLowerCase();
    // reset the current Search using the input // include the states too 
    this.resetCurrentSearch(includeActiveStates = true);

    // user search state for onload / reload usage;
    let userSearchState:InputSearchState = {
      value:this.searchKey, 
      keys:this.selectedSearchKeys
    }

    if(this.selectedSearchKeys.length) this.storage.setItem('userSearchState',userSearchState);
    this.handlePage();
  }
  

  delayedFilter(event) {
    const filterValue = (typeof event === 'string') ? event : (event.target as HTMLInputElement).value;
    if(!this.isPageable) return this.listData.filter = filterValue?.trim()?.toLowerCase();
    if(this.timerRunning) return;

    this.timerRunning = true;
    let filter = () => {
      this.pageParam = {...this.pageParam,first: 0};
      this.paginator?.firstPage();
      this.filterTable();
      this.timerRunning = false;
    }

    let timer = setTimeout(filter,this.searchDelayTime);
  }


  onSearchClear() {
    this.searchKey = '';
    this.currentSearch = [];
    this.filterTable();
  }


  handlePage(e?: PageEvent) {
    this.pageIndex = e?.pageIndex ?? 0;

    if(!this.isPageable) {
      this.allTableData.length && this.searchAllTableDataLocally(this.currentSearch);
      return e && this.storage.setItem('pageDetails',{...e});
    } 
    
    //if pageable, end here
    this.pageParam = {
      ...this.pageParam,
      ...this.currentSort,
      first: e?.pageIndex ?? 0, //practically this should be named page
      size: e?.pageSize ?? this.pageSize,
      searchFields: this.currentSearch,
    };

    let canNotSearch = (!this.searchKey && this.pageParam.searchFields && !this.hasActiveStates());
    if(canNotSearch || !this.currentSearch.length) delete this.pageParam.searchFields;
    if(!this.query) this.pageable.emit(this.pageParam);

    if(this.query){
      this.searchDataDtoInput = {...this.searchDataDtoInput, pageableParam:this.pageParam};
      // this.setTableDataFromQuery(this.query);
    }
  }


  getObjectValue(tableData: any, keyword: string) {
    let value = tableData;
    const keyArray = keyword.split('.');
    if (!(keyArray?.length > 0)) return value ?? 'N/A'; 

    keyArray?.forEach((key) => {
      if (value == null) return;

      if (Object?.keys(value)?.findIndex(k => k === key) > -1) {
        value = value[key];
        if(value && countOccurrences(value, ":") === 2 )  value =  new DatePipe("en-GB").transform(value, "dd - MMM - y"); 
        else if(value && countOccurrences(value, "-") === 2 && typeof value.split('-')[0] === "number") value =  new DatePipe("en-GB").transform(value, "dd - MMM - y"); 
        else if(value === null || value === undefined) value = "N/A";
      } 
    });

    return value ?? 'N/A';
  }


  viewDetails(data){
    if(this.triggerTimer) clearTimeout(this.triggerTimer);
    this.triggerTimer = setTimeout(()=>{this.trigger=0; },500);
    if((++this.trigger < 2)) return false;

    this.settings.openDialogModal({
      component: AllDetailsComponentComponent,
      panelClass: [...this.animationService.modalSlideInPanel(),'full-screen-dialog'],
      width: "80%",
      height: '80%',
      data: {data:data,showHeader:true,animation:"move-up",showFieldsInGroups:true},
    });
    
    this.trigger = 0;
  }


  async setTableDataFromQuery(query){
    this.auth.checkForPagination = false;
    let variables =  {};

    // use the provided search operation type for each field
    if(this.searchStatesValues && this.searchDataDtoInput?.pageableParam?.searchFields ){
      this.searchDataDtoInput.pageableParam.searchFields = this.searchDataDtoInput.pageableParam.searchFields.map( field => {
        let f = this.searchStatesValues.find(f => f.key === field.fieldName);
        if(f) field.searchType = f.searchType ?? SearchOperationType.Like;
        return field;
      })
    }

    if(this.currentSearch.length > 1 && this.searchKeys.length) {
      this.searchDataDtoInput.searchCombinationType =  SearchCombinationType.And;
    }

    let keys:string[] = this.getQueryVarKeysFromQuery(query), index;
    
    //auto add the pagedData input and additional parameters when available
    if(keys.length && !this.queryVariables){
      if((index = keys?.findIndex(k => valueIncludes(k,'searchData'))) > -1) variables[keys[index]] = this.searchDataDtoInput;
      if(this.additionalQueryParameter) variables = {...variables, ...this.additionalQueryParameter};
    }
    //stricktly use the ones provided as input
    else if(this.queryVariables) variables = {...this.queryVariables};
    
    let response$ = this.apollo.query({ 
      query: query, 
      variables: variables, 
      fetchPolicy: 'network-only'
      
    }).pipe(map(({ data }: any) => {
      this.auth.checkForPagination = true;
      return data;
     }));

    let response = await response$.toPromise()
    let returnedData = this.getDataIfPageable(response);

    if(!this.isPageable) {
      this.allTableData = returnedData;
      this.searchAllTableDataLocally(this.currentSearch);
    }

    else this.tableData.next(returnedData);
  }
  
  
  getDataIfPageable(data){
    if(!data) return [];
    let fields:{key:string,value:any}[] = [];
    let result:PageableResponse<any>,response:any;
    let pageDetails:any = {};
    Object.keys(data)?.forEach(key => fields.push({key: key, value: data[key]}));

    //get data list
    if(!(response = fields[0]?.value)) return [];
    else if(Array.isArray(response?.content)) result = response;
    else if(Array.isArray(response?.data?.content)) result = response.data;
    else if(Array.isArray(response?.data)) result = {content: response.data};
    else if(Array.isArray(response?.dataList)) result = {content: response.dataList};
    else if(Array.isArray(response)) result = {content: response};

    this.isPageable = !!result?.pagination || !!result?.first;
    if(!result?.content) return this.getDataFromPreviousExecution(fields[0].key);

    pageDetails = {query:fields[0].key, ...result}
    let content = [...pageDetails?.content];
    delete pageDetails?.content;
    
    this.storage.setItem(pageDetails.query, pageDetails);
    return content?.map(item => this.mapFunction? this.mapFunction(item) : item);
  }


  getDataFromPreviousExecution(pageDetailsKey){ 
    let pageDetails = this.storage.getItem(pageDetailsKey);
    if(!pageDetails) pageDetails = this.storage.getItem('pageDetails');
    if(!pageDetails) return [];

    pageDetails.pagination.totalElements = 0;
    this.storage.setItem(pageDetailsKey, pageDetails);
    return [];
  }


  getQueryVarKeysFromQuery(query){
    let key = query?.definitions[0]?.variableDefinitions[0]?.variable?.name?.value;
    let keys = query?.definitions[0]?.variableDefinitions?.map(x => x?.variable?.name?.value);
    return keys ?? ['searchDataDto'];
  }


  getQueryKeyFromQuery(query:any){
    if(!query) return 'pageDetails';
    let key = query?.definitions[0]?.name?.value;
    return key ?? 'pageDetails';
  }

}
