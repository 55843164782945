import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { HelpdeskEmailNotification } from './helpdesk-email-notification.model';
import * as HelpdeskEmailNotificationActions from './helpdesk-email-notification.actions';

export const helpdeskEmailNotificationsFeatureKey = 'helpdeskEmailNotifications';

export interface State extends EntityState<HelpdeskEmailNotification> {
  // additional entities state properties
}

export const adapter: EntityAdapter<HelpdeskEmailNotification> = createEntityAdapter<HelpdeskEmailNotification>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(HelpdeskEmailNotificationActions.addHelpdeskEmailNotification,
    (state, action) => adapter.addOne(action.helpdeskEmailNotification, state)
  ),
  on(HelpdeskEmailNotificationActions.upsertHelpdeskEmailNotification,
    (state, action) => adapter.upsertOne(action.helpdeskEmailNotification, state)
  ),
  on(HelpdeskEmailNotificationActions.addHelpdeskEmailNotifications,
    (state, action) => adapter.addMany(action.helpdeskEmailNotifications, state)
  ),
  on(HelpdeskEmailNotificationActions.upsertHelpdeskEmailNotifications,
    (state, action) => adapter.upsertMany(action.helpdeskEmailNotifications, state)
  ),
  on(HelpdeskEmailNotificationActions.updateHelpdeskEmailNotification,
    (state, action) => adapter.updateOne(action.helpdeskEmailNotification, state)
  ),
  on(HelpdeskEmailNotificationActions.updateHelpdeskEmailNotifications,
    (state, action) => adapter.updateMany(action.helpdeskEmailNotifications, state)
  ),
  on(HelpdeskEmailNotificationActions.deleteHelpdeskEmailNotification,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(HelpdeskEmailNotificationActions.deleteHelpdeskEmailNotifications,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(HelpdeskEmailNotificationActions.loadHelpdeskEmailNotifications,
    (state, action) => adapter.setAll(action.helpdeskEmailNotifications, state)
  ),
  on(HelpdeskEmailNotificationActions.clearHelpdeskEmailNotifications,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
