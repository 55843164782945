import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { RosterGroups } from './roster-groups.model';
import { PageableParam } from 'src/app/interfaces/global.interface';

export const loadRosterGroupss = createAction(
  '[RosterGroups/API] Load RosterGroupss', 
  props<{ rosterGroupss: RosterGroups[] }>()
);

export const addRosterGroups = createAction(
  '[RosterGroups/API] Add RosterGroups',
  props<{ rosterGroups: RosterGroups }>()
);

export const upsertRosterGroups = createAction(
  '[RosterGroups/API] Upsert RosterGroups',
  props<{ rosterGroups: RosterGroups }>()
);

export const addRosterGroupss = createAction(
  '[RosterGroups/API] Add RosterGroupss',
  props<{ rosterGroupss: RosterGroups[] }>()
);

export const upsertRosterGroupss = createAction(
  '[RosterGroups/API] Upsert RosterGroupss',
  props<{ rosterGroupss: RosterGroups[] }>()
);

export const updateRosterGroups = createAction(
  '[RosterGroups/API] Update RosterGroups',
  props<{ rosterGroups: Update<RosterGroups> }>()
);

export const updateRosterGroupss = createAction(
  '[RosterGroups/API] Update RosterGroupss',
  props<{ rosterGroupss: Update<RosterGroups>[] }>()
);

export const deleteRosterGroups = createAction(
  '[RosterGroups/API] Delete RosterGroups',
  props<{ id: string }>()
);

export const deleteRosterGroupss = createAction(
  '[RosterGroups/API] Delete RosterGroupss',
  props<{ ids: string[] }>()
);

export const clearRosterGroupss = createAction(
  '[RosterGroups/API] Clear RosterGroupss'
);




export const removeServiceGroup = createAction(
  '[Roster/API] Remove ServiceGroup',
  props<{ groupUuid: string, id: number }>()
);


export const createUpdateServiceGroup =  createAction(
  '[Roster/API] Register Srvice Group',
  props<{ groupDto: RosterGroups[] }>()
);



export const getAllServiceGroups = createAction(
  '[Roster/API] List Service Groups',
  props<{ pageableParam?: PageableParam }>()
);
