import gql from 'graphql-tag';
import { basePageableGraphqlField } from '../../base.graphql';

export const rootCauseFields = `
id
uuid
title
description
`;

export const CREATE_INCIDENT_ROOT_CAUSE = gql`
  mutation addUpdateRootCause($rootCause: RootCausesDtoInput){
    addUpdateRootCause(rootCause: $rootCause){
      code
      data{
        ${rootCauseFields}
      }
      message
    }
  }
`;

export const GET_ALL_INCIDENT_ROOT_CAUSES = gql`
  query getAllInstitutionRootCauses($pageparam: PageableParamInput){
    getAllInstitutionRootCauses(pageparam: $pageparam){
      content{
        ${rootCauseFields}
      }
      ${basePageableGraphqlField}
    }
  }
`;

export const REMOVE_ROOT_CAUSE = gql`
  mutation activateDeactivateRootCause($rootCauseUuid: String) {
    activateDeactivateRootCause(rootCauseUuid: $rootCauseUuid){
      code
      data{
        ${rootCauseFields}
      }
      message
    }
  }
`;
