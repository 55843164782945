import {createSelector} from '@ngrx/store';
import {AppState} from './../../../reducers/index';
import * as fromRoleReducer from './role.reducer';
import {Role} from './role.model';

export const currentState = (state: AppState) => (state[fromRoleReducer.rolesFeatureKey]);

export const selectAllRoles = createSelector(currentState, fromRoleReducer.selectAll);

export const selectTotalRoles = createSelector(currentState, fromRoleReducer.selectTotal);

export const selectModifiedRoles = createSelector(selectAllRoles, (roles) => {
    return roles.map((role) => {
        return {
            ...role,
            modififiedGlobal: role?.global ? 'YES' : 'NO'
        };
    });
});

export const selectRoleById = (params: { id: number }) => createSelector(
    selectModifiedRoles,
    (roles: Role[]) => {
        return roles.find((role) => role.id === params.id);
    }
);

export const selectRoleByInstitutionUuid = (params: { uuid: string }) => createSelector(
    selectModifiedRoles,
    (roles: Role[]) => {
        return roles.filter((role) => role?.institution?.uuid === params.uuid);
    }
);
