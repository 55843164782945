import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import { switchMap, map } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification.service';
import { ResponseCodeService } from 'src/app/services/response-code.service';
import { AppState } from '../../reducers';
import { getProblemsByDepartmentCode } from '../problem/problem.actions';
import * as fromActions from './incident-assignment.actions';
import * as fromGraphql from './incident-assignment.graphql';


@Injectable()
export class IncidentAssignmentEffects {
  assignIncident$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.assignIncident),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: fromGraphql.ASSIGN_INCIDENT,
        variables: {
          IncidentAssignmentDto: action?.IncidentAssignmentDto,
        },
      }).pipe(
        this.notificationService.catchError('Problem occurred while assigning incident'),
        map(({ data }: any) => {
          if (data) {   
            let result: any = data?.assignIncident;
            if (result?.code == 9000) {            
              this.store.dispatch(fromActions.loadIncidentAssignments({ incidentAssignments: result?.dataList}));
              this.notificationService.successMessage('You have succesfult Operation successful');
            } else {
              this.notificationService.errorMessage(this.responseCodesService.getCodeDescription(result?.code)[0]?.description);
            }
          }
        })
      );
    })
  ), {dispatch: false});

  constructor(
    private actions$: Actions,
    private apollo: Apollo,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private responseCodesService: ResponseCodeService
  ) {
  }

}
