import {Injectable} from '@angular/core';
import {ResponseCodeService} from '../../../../services/response-code.service';
import {AppState} from '../../../reducers/index';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {Apollo} from 'apollo-angular';
import {NotificationService} from 'src/app/services/notification.service';
import * as fromActions from './resource-criticality.actions';
import * as fromGraphql from './resource-criticality.graphql';
import {map, switchMap} from 'rxjs/operators';
import {PaginationService} from '../../../../services/pagination.service';


@Injectable()
export class ResourceCriticalityEffects {
    // List All Branchs
    listMyInstitutionAllResourceCriticalitys$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.listMyInstitutionAllResourceCriticalitys),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.GET_MY_INSTITUTION_ALL_RESOURCE_CRITICALITYS,
                variables: {
                    pageparam: action.pageableParam
                },
                fetchPolicy: 'network-only',
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching Branches'),
                map(({data}: any) => {
                    if (data) {
                        const res = data.getAllResourceCriticality;
                        this.paginationService.setPage(res);
                        this.store.dispatch(fromActions.loadResourceCriticalitys({resourceCriticalitys: res?.content}));
                    }
                })
            );
        })
    ), {dispatch: false});

    listMyInstitutionAllResourceCriticalitysForOptions$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.listMyInstitutionAllResourceCriticalitysForOptions),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.GET_MY_INSTITUTION_ALL_RESOURCE_CRITICALITYS_FOR_OPTIONS,
                variables: {
                    pageparam: action.pageableParam
                },
                fetchPolicy: 'network-only',
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching Branches'),
                map(({data}: any) => {
                    if (data) {
                        const res = data.getAllResourceCriticality;
                        this.paginationService.setPage(res);
                        this.store.dispatch(fromActions.loadResourceCriticalitys({resourceCriticalitys: res?.content}));
                    }
                })
            );
        })
    ), {dispatch: false});


    activateResourceCriticality$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.activateResourceCriticality),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.ACTIVATE_AND_DEACTIVATE_RESOURCE_CRITICALITY,
                variables: {
                    severityUuid: action.id,

                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while updating Resource Criticality details'),
                map(({data}: any) => {

                    if (data) {

                        this.notificationService.successMessage('Resource Criticality details Activated Successfully');
                        if (data.activateDeactivateResourceCriticality.code === 9000) {
                            this.store.dispatch(fromActions.upsertResourceCriticality({resourceCriticality: data.activateDeactivateResourceCriticality.data}));
                            this.notificationService.successMessage('Resource Criticality details Activated Successfully');
                        } else {
                            this.notificationService.errorMessage('Failed to Activate Resource Criticality detals');
                        }
                    }
                })
            );
        })
    ), {dispatch: false});


    // Create/Save Branch
    createResourceCriticality$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.createResourceCriticality),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.SAVE_UPDATE_RESOURCE_CRITICALITY,
                variables: {
                    severity: action.resourceCriticality
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while saving Resource Category'),
                map(({data}: any) => {
                    if (data) {
                        if (data.addUpdateResourceCriticality.code === 9000) {
                            this.store.dispatch(fromActions.upsertResourceCriticality({resourceCriticality: data.addUpdateResourceCriticality.data}));
                            if (action.resourceCriticality?.id) {
                                this.notificationService.successMessage('Edited Successfully');
                            } else {
                                this.notificationService.successMessage('Created Successfully');
                            }
                        } else {
                            const error = this.responseCodesService.getCodeDescription(data.addUpdateResourceCriticality.code);
                            this.notificationService.errorMessage(error[0]?.description);
                        }
                    }
                })
            );
        })
    ), {dispatch: false});


    // Detele Branch
    removeResourceCriticality$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.removeResourceCriticality),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.DELETE_RESOURCE_CRITICALITY,
                variables: {
                    severityUuid: action.uuid,
                    id: action.id
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while deleting Resource Criticality'),
                map(({data}: any) => {
                    if (data) {
                        if (data.deleteResourceCriticality.code === 9000) {
                            // this.store.dispatch(fromActions.deleteBranch({ id: action.id }));
                            this.store.dispatch(
                                fromActions.deleteResourceCriticality({id: action.id})
                            );
                            // this.store.dispatch(fromActions.loadResourceCriticalitys({ resourceCriticalitys:data.getAllResourceCriticality.content }));

                            this.notificationService.successMessage('Deleted Successfully');
                        } else {
                            this.notificationService.errorMessage(data.deleteResourceCriticality);
                        }
                    }
                })
            );
        })
    ), {dispatch: false});


    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private store: Store<AppState>,
        private notificationService: NotificationService,
        private paginationService: PaginationService,
        private responseCodesService: ResponseCodeService
    ) {
    }


}
