import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { CalltrackerSupport, CallTrackerSupportDtoInput } from './calltracker-support.model';

export const loadCalltrackerSupports = createAction(
  '[CalltrackerSupport/API] Load CalltrackerSupports',
  props<{ calltrackerSupports: CalltrackerSupport[] }>()
);

export const addCalltrackerSupport = createAction(
  '[CalltrackerSupport/API] Add CalltrackerSupport',
  props<{ calltrackerSupport: CalltrackerSupport }>()
);

export const upsertCalltrackerSupport = createAction(
  '[CalltrackerSupport/API] Upsert CalltrackerSupport',
  props<{ calltrackerSupport: CalltrackerSupport }>()
);

export const addCalltrackerSupports = createAction(
  '[CalltrackerSupport/API] Add CalltrackerSupports',
  props<{ calltrackerSupports: CalltrackerSupport[] }>()
);

export const upsertCalltrackerSupports = createAction(
  '[CalltrackerSupport/API] Upsert CalltrackerSupports',
  props<{ calltrackerSupports: CalltrackerSupport[] }>()
);

export const updateCalltrackerSupport = createAction(
  '[CalltrackerSupport/API] Update CalltrackerSupport',
  props<{ calltrackerSupport: Update<CalltrackerSupport> }>()
);

export const updateCalltrackerSupports = createAction(
  '[CalltrackerSupport/API] Update CalltrackerSupports',
  props<{ calltrackerSupports: Update<CalltrackerSupport>[] }>()
);

export const deleteCalltrackerSupport = createAction(
  '[CalltrackerSupport/API] Delete CalltrackerSupport',
  props<{ id: number }>()
);

export const deleteCalltrackerSupports = createAction(
  '[CalltrackerSupport/API] Delete CalltrackerSupports',
  props<{ ids: string[] }>()
);

export const clearCalltrackerSupports = createAction(
  '[CalltrackerSupport/API] Clear CalltrackerSupports'
);

//////// APIs: //////////

// saveCallTracker
export const saveCallTrackerSupport = createAction(
  '[CalltrackerSupport/API] Save Calltracker Support',
  props<{ dto: CallTrackerSupportDtoInput }>()
);

// getCallTrackerSupportList
export const getCallTrackerSupportList = createAction(
  '[CalltrackerSupport/API] Get All CallTracker Support List'
);

// deleteCallTrackerSupport
export const removeCallSupport = createAction(
  '[CalltrackerSupport/API] remove Calltracker Support',
  props<{ trackUud: string, id: number }>()
);
