
<div class="attachment">
    <div class="photo" *ngIf="showPhoto">
          <ng-container *ngIf="attachmentStr$ | withLoading | async as data">
            <ng-template [ngIf]="data?.value">
              <img width="120px" height="120px" [src]="data?.value  | safeHtml" alt="Photo">
            </ng-template>
            <ng-template [ngIf]="data?.error">
              <img width="120px" height="120px" src= 'assets/img/placeholder.png' alt="Photo">
                <h6 style="color: red;">Failed to load {{ data?.error }}</h6></ng-template>
            <ng-template [ngIf]="data?.loading">
              <img width="120px" height="120px" src= 'assets/img/placeholder.png' alt="Loading Photo...">
              <mat-progress-bar  mode="query"></mat-progress-bar>
            </ng-template>
          </ng-container>
    </div>
    <div class="attach-button">
   <!-- <a style="color: #1A405D; font-weight: 600; cursor: pointer" (click)="showAttachment()">View Attached Attachment</a> -->
   <!-- <br> -->
    <button  style="width:100%; height: 45px; margin-top: 5px"  mat-raised-button color="default" (click)="inputFile.click()"
              title="Upload associated file" type="button" class="attach-button">
                <mat-icon>attach_file</mat-icon>
            <span *ngIf="fileUploadedName; else weHaveFile"> {{ fileUploadedName }}</span>
            <ng-template #weHaveFile> {{ field?.label }}</ng-template>
          </button>
          <input hidden mat-raised-button color="default" #inputFile (change)="onFileSelected($event)"
          [accept]="accept" type="file" />
    </div>
  </div>