import {  Directive, EventEmitter, Input, OnChanges, OnDestroy, Output, ViewContainerRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FieldConfig, FieldType } from '../field.interface';
import { InputComponent } from '../input/input.component';
import { ButtonComponent } from '../button/button.component';
import { SelectComponent } from '../select/select.component';
import { DateComponent } from '../date/date.component';
import { RadiobuttonComponent } from '../radiobutton/radiobutton.component';
import { CheckboxComponent } from '../checkbox/checkbox.component';
import { Subject } from 'rxjs';
import { AttachmentComponent } from '../attachment/attachment.component';
import { TextareaComponent } from '../textarea/textarea.component';
import { first, takeUntil } from 'rxjs/operators';
import { TimeComponent } from '../time/time.component';
import { PaginatedSelectComponent } from '../paginated-select/paginated-select.component';
import { GroupSelectComponent } from '../group-select/group-select.component';
import { TextareaWithEditor } from '../description-editor/description-editor';

const componentMapper = {
  input: InputComponent,
  button: ButtonComponent,
  select: SelectComponent,
  paginatedselect: PaginatedSelectComponent,
  groupselect: GroupSelectComponent,
  attachment: AttachmentComponent,
  date: DateComponent,
  time: TimeComponent,
  radiobutton: RadiobuttonComponent,
  checkbox: CheckboxComponent,
  textarea: TextareaComponent,
  editor: TextareaWithEditor
};
@Directive({
  selector: '[appDynamicField]',
})
export class DynamicFieldDirective implements OnChanges, OnDestroy {
  @Input() field?: FieldConfig;
  @Input() group?: UntypedFormGroup;
  @Input() optionData: any;
  // @Input() visible = true;
  @Output() fieldValue = new EventEmitter();
  @Output() cancelForm = new EventEmitter();
  componentRef: any;
  private destroy$: Subject<void> = new Subject<void>();
  constructor(
    private container: ViewContainerRef
  ) { }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.componentRef?.destroy();
  }
  ngOnChanges() {
    if (this.field?.type === FieldType.button) {
      this.field = { ... this.field, key: 'button' }
    }
    if (this.field?.type) {
      this.clear();
      if (this.field.key) {
        

        this.field.optionData = this.optionData;
        this.componentRef = this.container.createComponent(componentMapper[this.field.type]);
        if (this.componentRef.instance) {
          this.componentRef.instance.field = this.field;
          this.componentRef.instance.group = this.group;
          if (this.componentRef.instance.fieldValue) {
            this.componentRef.instance.fieldValue.pipe(takeUntil(this.destroy$)).subscribe((data: any) => {
              this.fieldValue.emit(data);
            });
          }
        }
      }
      if (this.field.type === FieldType.button) {
        this.componentRef.instance.cancelForm.pipe(takeUntil(this.destroy$)).subscribe(() => {
          this.cancelForm.emit();
        });
      }
    }
  }

  clear() {
    this.container.clear();
  }

}
