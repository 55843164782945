import gql from 'graphql-tag';

export const calltrackerSupportFields = `
  id
  active
  createdAt
  createdBy
  deleted
  name
  description
  uuid
`;

export const SAVE_CALL_TRACKER_SUPPORT = gql`
  mutation createUpdateCallSupportProvided( $dto: CallSupportProvidedDtoInput){
    createUpdateCallSupportProvided(callSupportProvided: $dto){
      code
      message
      data{
        ${calltrackerSupportFields}
      }
    }
  }
`;

export const GET_CALL_TRACKER_SUPPORT_LIST = gql`
query getAllCallSupportedReported{
  getAllCallSupportedReported{
    code
    message
    dataList{
      ${calltrackerSupportFields}
    }
  }
}
`;

export const DELETE_CALL_TRACKER_SUPPORT = gql`
  mutation deleteCallSupportProvided( $trackUuid: String ){
    deleteCallSupportProvided( uuid: $trackUuid ){
      code
      message
      data{
        ${calltrackerSupportFields}
      }
    }
  }
`;

