import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Permission } from './permission.model';
import * as PermissionActions from './permission.actions';

export const permissionsFeatureKey = 'permissions';

export interface State extends EntityState<Permission> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Permission> = createEntityAdapter<Permission>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(PermissionActions.addPermission,
    (state, action) => adapter.addOne(action.permission, state)
  ),
  on(PermissionActions.upsertPermission,
    (state, action) => adapter.upsertOne(action.permission, state)
  ),
  on(PermissionActions.addPermissions,
    (state, action) => adapter.addMany(action.permissions, state)
  ),
  on(PermissionActions.upsertPermissions,
    (state, action) => adapter.upsertMany(action.permissions, state)
  ),
  on(PermissionActions.updatePermission,
    (state, action) => adapter.updateOne(action.permission, state)
  ),
  on(PermissionActions.updatePermissions,
    (state, action) => adapter.updateMany(action.permissions, state)
  ),
  on(PermissionActions.deletePermission,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(PermissionActions.deletePermissions,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(PermissionActions.loadPermissions,
    (state, action) => adapter.setAll(action.permissions, state)
  ),
  on(PermissionActions.clearPermissions,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
