import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { incidentTransactionListByIncident } from 'src/app/store/entities/incident-trail/incident-trail.actions';
import { IncidentTrail } from 'src/app/store/entities/incident-trail/incident-trail.model';
import { selectModifiedIncidentTrails } from 'src/app/store/entities/incident-trail/incident-trail.selectors';
import { Incident } from 'src/app/store/entities/incident/incident.model';
import { AppState } from 'src/app/store/reducers';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-view-trail',
  templateUrl: './view-trail.component.html',
  styleUrls: ['./view-trail.component.scss']
})
export class ViewTrailComponent implements OnInit {

  title: string;
  incident: Incident;
  incidentTrail$: Observable<IncidentTrail[]>;

  constructor(
    private store: Store<AppState>,
    private dialogRef: MatDialogRef<ViewTrailComponent>,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    this.incident = data;
    this.store.dispatch(incidentTransactionListByIncident({incidentId: this.incident?.id}));
  }

  ngOnInit(): void {
    this.incidentTrail$ = this.store.pipe(select(selectModifiedIncidentTrails));
  }

  closeThisModal() {
    this.dialogRef.close();
  }

}
