import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';

import {Branch} from './branch.model';
import {PageableParam} from '../../../../interfaces/global.interface';

export const loadBranchs = createAction(
    '[Branch/API] Load Branchs',
    props<{ branchs: Branch[] }>()
);

export const addBranch = createAction(
    '[Branch/API] Add Branch',
    props<{ branch: Branch }>()
);

export const upsertBranch = createAction(
    '[Branch/API] Upsert Branch',
    props<{ branch: Branch }>()
);

export const addBranchs = createAction(
    '[Branch/API] Add Branchs',
    props<{ branchs: Branch[] }>()
);

export const upsertBranchs = createAction(
    '[Branch/API] Upsert Branchs',
    props<{ branchs: Branch[] }>()
);

export const updateBranch = createAction(
    '[Branch/API] Update Branch',
    props<{ branch: Update<Branch> }>()
);

export const updateBranchs = createAction(
    '[Branch/API] Update Branchs',
    props<{ branchs: Update<Branch>[] }>()
);

export const deleteBranch = createAction(
    '[Branch/API] Delete Branch',
    props<{ id: number }>()
);

export const deleteBranchs = createAction(
    '[Branch/API] Delete Branchs',
    props<{ ids: number[] }>()
);

export const clearBranchs = createAction(
    '[Branch/API] Clear Branchs'
);

// Effect Actions
export const listMyInstitutionAllBranchs = createAction(
    '[Branch/API] List My Institution All Branchs',
);
export const listMyInstitutionAllBranchsForList = createAction(
    '[Branch/API] List My Institution All Branchs For List',
);

export const listAllBranchs = createAction(
    '[Branch/API] List All Branchs',
    props<{ institution: { id: number } }>()
);

export const modifyBranch = createAction(
    '[Branch/API] Modify Branch Details',
    props<{ branch: Branch }>()
);

export const getBranchById = createAction(
    '[Branch/API] Get Branch By Id',
    props<{ id: number }>()
);

export const getBranchByCode = createAction(
    '[Branch/API] Get Branch By Code',
    props<{ branchCode: string }>()
);

export const createBranch = createAction(
    '[Branch/API] Create Branch',
    props<{ branch: Branch }>()
);

export const removeBranch = createAction(
    '[Branch/API] Remove Branch By Id',
    props<{ id: number }>()
);

export const getDefaultBranch = createAction(
    '[User/API] Get Default Branch'
);

export const findBranchesByInstitution = createAction(
    '[Branch/API] List All Branchs By InstitutionUuid',
    props<{ institutionUuid: string }>()
);

export const getAllAdministrativeAreasByType = createAction(
    '[AdministrativeArea/API] get AdministrativeArea By TYPE',
    props<{ areaType: string, pageableParam: PageableParam }>()
);
