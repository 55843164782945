
  <ng-container ngProjectAs="table">
<ng-container *ngIf="user$| async as user">
  <div class="row user-details">
    <div class="col left-part">
      <div class="col-md-12">
        <p class="name">{{ user[0]?.fullName | titlecase}} </p>
        <p class="mrn"> {{ user[0]?.designation?.designationName }}</p>
        <mat-chip-list aria-label="Fish selection">
          <!-- <mat-chip *ngIf="user[0]?.accountNonExpired">Not Expired</mat-chip>-->
          <mat-chip *ngIf="user[0]?.accountNonLocked">Not Locked</mat-chip>
          <mat-chip *ngIf="user[0]?.credentialsNonExpired">Credentials Not Expired</mat-chip>
          <mat-chip>{{ user[0]?.active ? 'Active Account' : 'Not active'}}</mat-chip>

        </mat-chip-list>
        <p class="expire">Password will expire on: {{user[0]?.passwordExpirationDate| formatDate}} </p>
      </div>
    <hr>
      <div class="col-md-12 the-details">
        <div class="row container-fluid">
          <div class="col-md-4">
            <h5 mat-line style="font-weight: 700; color: #333c; margin-bottom: 0px;">Check Number</h5>
            <p mat-line style="font-weight: 600;">{{ user[0]?.checkNumber }}</p>
          </div>
          <div class="col-md-4">
            <h5 mat-line style="font-weight: 700; color: #333c; margin-bottom: 0px;">Institution</h5>
            <p mat-line style="font-weight: 600;">{{ user[0]?.institution?.name }}</p>
          </div>
      <div class="col-md-4">
        <h5 mat-line style="font-weight: 700; color: #333c; margin-bottom: 0px;">Email</h5>
        <p mat-line style="font-weight: 600;">{{ user[0]?.email }}</p>
      </div>
      <div class="col-md-4">
        <h5 mat-line style="font-weight: 700; color: #333c; margin-bottom: 0px;">Department</h5>
        <p mat-line style="font-weight: 600;">{{ user[0]?.department?.departmentName}}</p>
      </div>
      <div class="col-md-4">
        <h5 mat-line style="font-weight: 700; color: #333c; margin-bottom: 0px;">Branch</h5>
        <p mat-line style="font-weight: 600;">{{ user[0]?.branch?.branchName | titlecase}}</p>
      </div>
    

      <div class="col-md-4">
        <h5 mat-line style="font-weight: 700; color: #333c; margin-bottom: 0px;">Phone Number</h5>
        <p mat-line style="font-weight: 600;">{{ user[0]?.phone }}</p>
      </div>
  
      <div class="col-md-4" >
        <h5 mat-line style="font-weight: 700; color: #333c; margin-bottom: 0px;">Last Login</h5>
        <p mat-line style="font-weight: 600; font-size: 10px;">{{ user[0]?.lastLogin  | date: 'MMM d, y, HH:mm:ss'}}</p>
      </div>
    
    </div>

    
      </div>
    
    </div>
    <div class="col right-part">
      <div class="col-md-12">
        <div class="container-fluid">
          <mat-accordion>
 
            <mat-expansion-panel  >
              <mat-expansion-panel-header>
                <mat-panel-title style="font-weight: 600; color: #5a5a5a;">
                 Roles
                </mat-panel-title>
            
              </mat-expansion-panel-header>
              <h5 *ngIf="!user[0]?.rolesList">No user</h5>
              <table class="table table-hovered" style="font-size: 10px" 
              *ngIf="user[0]?.rolesList && user[0]?.rolesList.length>0">
              <tr>
                <th>Role</th>
              </tr>
                <tr *ngFor="let item of user[0]?.rolesList">
                  <td style="cursor: pointer;" >{{item.displayName |replace:'_':' ' | titlecase }} </td>
                </tr>
              </table>
            </mat-expansion-panel>

          </mat-accordion>
        </div>
      </div>
    </div>
  </div>

  
</ng-container>
  </ng-container>