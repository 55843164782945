import { PageableParam } from './../../../interfaces/global.interface';
import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { ChangeManagement } from './change-management.model';

export const loadChangeManagements = createAction(
  '[ChangeManagement/API] Load ChangeManagements',
  props<{ changeManagements: ChangeManagement[] }>()
);

export const addChangeManagement = createAction(
  '[ChangeManagement/API] Add ChangeManagement',
  props<{ changeManagement: ChangeManagement }>()
);

export const upsertChangeManagement = createAction(
  '[ChangeManagement/API] Upsert ChangeManagement',
  props<{ changeManagement: ChangeManagement }>()
);

export const addChangeManagements = createAction(
  '[ChangeManagement/API] Add ChangeManagements',
  props<{ changeManagements: ChangeManagement[] }>()
);

export const upsertChangeManagements = createAction(
  '[ChangeManagement/API] Upsert ChangeManagements',
  props<{ changeManagements: ChangeManagement[] }>()
);

export const updateChangeManagement = createAction(
  '[ChangeManagement/API] Update ChangeManagement',
  props<{ changeManagement: Update<ChangeManagement> }>()
);

export const updateChangeManagements = createAction(
  '[ChangeManagement/API] Update ChangeManagements',
  props<{ changeManagements: Update<ChangeManagement>[] }>()
);

export const deleteChangeManagement = createAction(
  '[ChangeManagement/API] Delete ChangeManagement',
  props<{ id: number }>()
);

export const deleteChangeManagements = createAction(
  '[ChangeManagement/API] Delete ChangeManagements',
  props<{ ids: number[] }>()
);

export const clearChangeManagements = createAction(
  '[ChangeManagement/API] Clear ChangeManagements'
);

////////////////////  APIs: ////////////////////////

//  Create new change request
export const saveChangeManagement = createAction(
  '[ChangeManagement/API] Save ChangeManagement',
  props<{ HelpdeskChangeRequestDto: ChangeManagement }>()
);

// UPDATE_CHANGE_REQUEST
export const updateChangeRequest = createAction(
  '[ChangeManagement/API] Update Change Request',
  props<{
    requestId: number ;

    HelpdeskChangeRequestDto:{
    fullName: string,
    email: string,
    attachments: {
      filePath: string,
      attachmentTitle: string,
      data: string,
    }
    changeDate: string,
    affectedServices: string,
    phoneNumber: string,
    rollbackPlan: string,
    submittedDate: string,
    completionDate: string,
    description: string,
    requestType: string
  }
  }>()
);

// getChangeRequestByStage
export const getHelpdeskChangeRequestByStage = createAction(
  '[ChangeManagement/API] List Change Request By Stage',
  props<{ stage: string }>()
);

// Find Change Request By Request Type
export const findChangeRequestByRequestType = createAction(
  '[ChangeManagement/API] Find Change Request By Request Types',
  props<{ requestType: string }>()
);

// Find Change Request By Request Stage and Type
export const getChangeRequestByStageAndType = createAction(
  '[ChangeManagement/API] List Change Request By Stage And Type',
  props<{ stage: string; requestType: string }>()
);

// SUBMIT_CHANGE_REQUEST
export const submitChangeRequest = createAction(
  '[ChangeManagement/API] Submit Change Request',
  props<{ requestId: number }>()
);

// MANAGER_REVIEW_CHANGE_REQUEST
export const managerReviewChangeRequest = createAction(
  '[ChangeManagement/API] Manager Review Change Request',
  props<{
    reviewDto: {
      comment: string,
      reviewDecission: string
    };
    requestId: number;
    HelpdeskChangeRequestDto: {
      fullName: string,
      email: string,
      attachments: {
        filePath: string,
        attachmentTitle: string,
        data: string,
      }
      changeDate: string,
      affectedServices: string,
      phoneNumber: string,
      rollbackPlan: string,
      submittedDate: string,
      completionDate: string,
      description: string,
      requestType: string
    }
  }>()
);

// DIRECTOR_REVIEW_CHANGE_REQUEST
export const directorReviewChangeRequest = createAction(
  '[ChangeManagement/API] Director Review Change Request',
  props<{ reviewDto: { comment: string, reviewDecission: string }; requestId: number }>()
);

// ASSIGN_CHANGE_REQUEST_TO_EXECUTOR
export const assignChangeRequestToExecutor = createAction(
  '[ChangeManagement/API] Assign Change Request To Executor',
  props<{
    requestId: number;
    comment: string;
    assignees: number[];
    repititiveFrequency: string;
    priority: string;
    repetitive: boolean;
    taskLeader: number;
    endDate: string;
    startDate: string;

  }>()
);

// FIND_CHANGE_REQUEST_BY_EXEMPTED_STAGE
export const findChangeRequestsStageNotIn = createAction(
  '[ChangeManagement/API] Find Change Requests Stage Not In',
  props<{ stage: string[] }>()
);

// EXECUTE_CHANGE_REQUEST
export const executeChangeRequest = createAction(
  '[ChangeManagement/API] Execute Change Request',
  props<{
    requestId: number;
    executionDto: {
      executorChangeStatus: string;
      solution: string;
      attachments: {
        attachmentTitle: string;
        filePath: string;
        data: string;
      }[];
      changes: string;
    }
   }>()
);

// SEND_FEEDBACK_TO_EXECUTOR
export const sendFeedbackToRequester = createAction(
  '[ChangeManagement/API] Send Feedback To Requester',
  props<{ requestId: number; description: string; changeStatus: string }>()
);

// CLOSE_CHANGE_REQUEST
export const closeChangeRequest = createAction(
  '[ChangeManagement/API] Close Change Request',
  props<{ requestId: number; remarks: string; changeStatus: string }>()
);

// READ_ATTACHMENT
export const readAttachment = createAction(
  '[ChangeManagement/API] Read Request Attachment',
  props<{ attachmentType: string; filePath: string; }>()
);
