import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ApprovalStage } from './approval-stage.model';
import * as ApprovalStageActions from './approval-stage.actions';

export const approvalStagesFeatureKey = 'approvalStages';

export interface State extends EntityState<ApprovalStage> {
  // additional entities state properties
}

export const adapter: EntityAdapter<ApprovalStage> = createEntityAdapter<ApprovalStage>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(ApprovalStageActions.addApprovalStage,
    (state, action) => adapter.addOne(action.approvalStage, state)
  ),
  on(ApprovalStageActions.upsertApprovalStage,
    (state, action) => adapter.upsertOne(action.approvalStage, state)
  ),
  on(ApprovalStageActions.addApprovalStages,
    (state, action) => adapter.addMany(action.approvalStages, state)
  ),
  on(ApprovalStageActions.upsertApprovalStages,
    (state, action) => adapter.upsertMany(action.approvalStages, state)
  ),
  on(ApprovalStageActions.updateApprovalStage,
    (state, action) => adapter.updateOne(action.approvalStage, state)
  ),
  on(ApprovalStageActions.updateApprovalStages,
    (state, action) => adapter.updateMany(action.approvalStages, state)
  ),
  on(ApprovalStageActions.deleteApprovalStage,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(ApprovalStageActions.deleteApprovalStages,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(ApprovalStageActions.loadApprovalStages,
    (state, action) => adapter.setAll(action.approvalStages, state)
  ),
  on(ApprovalStageActions.clearApprovalStages,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
