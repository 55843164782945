import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Designation } from './designation.model';

export const loadDesignations = createAction(
  '[Designation/API] Load Designations',
  props<{ designations: Designation[] }>()
);

export const addDesignation = createAction(
  '[Designation/API] Add Designation',
  props<{ designation: Designation }>()
);

export const upsertDesignation = createAction(
  '[Designation/API] Upsert Designation',
  props<{ designation: Designation }>()
);

export const addDesignations = createAction(
  '[Designation/API] Add Designations',
  props<{ designations: Designation[] }>()
);

export const upsertDesignations = createAction(
  '[Designation/API] Upsert Designations',
  props<{ designations: Designation[] }>()
);

export const updateDesignation = createAction(
  '[Designation/API] Update Designation',
  props<{ designation: Update<Designation> }>()
);

export const updateDesignations = createAction(
  '[Designation/API] Update Designations',
  props<{ designations: Update<Designation>[] }>()
);

export const deleteDesignation = createAction(
  '[Designation/API] Delete Designation',
  props<{ id: number }>()
);

export const deleteDesignations = createAction(
  '[Designation/API] Delete Designations',
  props<{ ids: number[] }>()
);

export const clearDesignations = createAction(
  '[Designation/API] Clear Designations'
);

// Effects Actions 
export const getInstitutionDesignations = createAction(
  '[Designation/API] Get Institution Designations'
);


export const getDesignationsByInstitution = createAction(
  '[Designation/API] list All Designation By Institution ID',
  props<{ institution: {id: number} }>()
);

export const createDesignation = createAction(
  '[Designation/API] Create Designation',
  props<{ designationDto: Designation }>()
);

export const modifyDesignation = createAction(
  '[Designation/API] Modify Designation Details',
  props<{ designationDto: Designation }>()
);

export const removeDesignation = createAction(
  '[Designation/API] Remove Designation',
  props<{ id: string, _id?: number }>()
);
