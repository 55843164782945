
import gql from 'graphql-tag';

export const priorityFields = `
  active
  createdAt
  createdBy
  deleted
  description
  id
  uuid
  title
  timePeriod
  periodType
`;

export const CREATE_INSTITUTION_PRIORITY = gql`
  mutation addUpdatePriority( $priority: PriorityDtoInput){
    addUpdatePriority( severity: $priority ){
      code
      data{
        ${priorityFields}
      }
      message
    }
  }
`;

export const LIST_ALL_INSTITUTION_PRIORITYS = gql`
  query getAllInstitutionPriorities( $pageparam: PageableParamInput){
    getAllInstitutionPriorities( pageparam: $pageparam ){
      content{
        ${priorityFields}
      }
      size
      first
      totalPages
      size
      totalElements
      numberOfElements
    }
  }
`;

export const DELETE_INSTITUTION_PRIORITY = gql`
  mutation deletePriority( $priorityUuid: String ){
    deletePriority( severityUuid: $priorityUuid ){
      code
      data{
        ${priorityFields}
      }
      message
    }
  }
`;

