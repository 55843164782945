import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent implements OnInit {

  public lineChartData: ChartDataSets[] = [
    { data: [65, 59, 60, 81, 56, 55, 40], label: 'Series A' },
    { data: [80, 59, 75, 81, 60, 55, 10], label: 'Series B' },
    { data: [62, 59, 75, 31, 88, 55, 70], label: 'Series C' },
  ];
  public lineChartLabels: Label[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  public lineChartOptions  = {
    responsive: true,
    
    scales: {
      xAxes: [{
          gridLines: {
              color: "rgba(0, 0, 0, 0)",
          }
      },],
      
      yAxes: [{
          gridLines: {
              color: "rgba(0, 0, 0, 0)",
          }   
      }],
      elements: {
        line: {
                fill: false
        }
    },
  }
  };
  public lineChartColors: Color[] = [
    {
      borderColor: '#94a3b8',
      // backgroundColor: '',
    },
    {
      borderColor: '#0e7490',
      // backgroundColor: '',
    },
    {
      borderColor: '#9f1239',
      // backgroundColor: '',
    },
  ];
  
  public lineChartLegend = true;
  // public lineChartFill = false;
  public lineChartType :ChartType= 'line';
  // public barChartType: ChartType = 'bar';

  public lineChartPlugins = [];

  constructor() { }

  ngOnInit(): void {
  }

}
