import { ResponseCodeService } from './../../../../services/response-code.service';
import { NotificationService } from './../../../../services/notification.service';
import { AppState } from './../../../reducers/index';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import * as fromActions from './permission.actions';
import * as fromGraphql from './permission.graphql';
import { map, switchMap } from 'rxjs/operators';


@Injectable()
export class PermissionEffects {

  // Get All Permissions
  getPermissions$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.getAllPermissions),
    switchMap((action) => {
      return this.apollo.query({
        query: fromGraphql.GET_ALL_PERMISSIONS,
        fetchPolicy: 'network-only',
      }).pipe(
        this.notificationService.catchError('Problem occurred while fetching Permissions'),
        map(({ data }: any) => {
          if (data) {
            this.store.dispatch(fromActions.loadPermissions({ permissions: data.getAllPermissions }));
          }
        })
      );
    })
  ), {dispatch: false});

  // getAllPermissionsGrouped
  getAllPermissionsGrouped$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.getAllPermissionsGrouped),
    switchMap((action) => {
      return this.apollo.query({
        query: fromGraphql.GET_ALL_PERMISSIONS_GROUPED,
        fetchPolicy: 'network-only',
      }).pipe(
        this.notificationService.catchError('Problem occurred while fetching Permissions'),
        map(({ data }: any) => {
          if (data) {
            this.store.dispatch(fromActions.loadPermissions({ permissions: data.getAllPermissionsGrouped.permissions }));
          }
        })
      );
    })
  ), {dispatch: false});

  // Assign Permissions
  assignPermission$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.assignPermission),
    switchMap((action) => {
       
      return this.apollo.mutate({
        mutation: fromGraphql.ASSIGN_PERMISSIONS,
        variables: {
          permissions: action.permissions,
          roleId: action.roleId
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while assigning permissions'),
        map(({data}: any) => {
          if(data){
            const response = this.responseCodesService?.getCodeDescription(data.assignPermission.code);
            this.notificationService.successMessage('Operation successful: '+data.assignPermission.code);
          } else {
            this.notificationService.errorMessage('Operation failed: '+data.assignPermission.message);
          }
        })
      );
    })
  ), {dispatch: false});

  constructor(
    private actions$: Actions,
    private apollo: Apollo,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private responseCodesService: ResponseCodeService
  ) {
  }

}
