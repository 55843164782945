import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { KbWorkflow } from './kb-workflow.model';
import * as KbWorkflowActions from './kb-workflow.actions';

export const kbWorkflowsFeatureKey = 'kbWorkflows';

export interface State extends EntityState<KbWorkflow> {
  // additional entities state properties
}

export const adapter: EntityAdapter<KbWorkflow> = createEntityAdapter<KbWorkflow>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(KbWorkflowActions.addKbWorkflow,
    (state, action) => adapter.addOne(action.kbWorkflow, state)
  ),
  on(KbWorkflowActions.upsertKbWorkflow,
    (state, action) => adapter.upsertOne(action.kbWorkflow, state)
  ),
  on(KbWorkflowActions.addKbWorkflows,
    (state, action) => adapter.addMany(action.kbWorkflows, state)
  ),
  on(KbWorkflowActions.upsertKbWorkflows,
    (state, action) => adapter.upsertMany(action.kbWorkflows, state)
  ),
  on(KbWorkflowActions.updateKbWorkflow,
    (state, action) => adapter.updateOne(action.kbWorkflow, state)
  ),
  on(KbWorkflowActions.updateKbWorkflows,
    (state, action) => adapter.updateMany(action.kbWorkflows, state)
  ),
  on(KbWorkflowActions.deleteKbWorkflow,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(KbWorkflowActions.deleteKbWorkflows,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(KbWorkflowActions.loadKbWorkflows,
    (state, action) => adapter.setAll(action.kbWorkflows, state)
  ),
  on(KbWorkflowActions.clearKbWorkflows,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
