import { Injectable } from '@angular/core';
import { ResponseCodeService } from '../../../../services/response-code.service';
import { AppState } from '../../../reducers/index';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import { NotificationService } from 'src/app/services/notification.service';
import * as fromActions from './request-priority.actions';
import * as fromGraphql from './request-priority.graphql';
import { map, switchMap } from 'rxjs/operators';



@Injectable()
export class RequestPriorityEffects {
 // List All Branchs 
 listMyInstitutionAllRequestPrioritys$ = createEffect(() => this.actions$.pipe(
  ofType(fromActions.listMyInstitutionAllRequestPrioritys),
  switchMap((action) => {
    return this.apollo.query({
      query: fromGraphql.GET_MY_INSTITUTION_ALL_REQUEST_PRIORITYS,
      // variables: {
      //   institutionId:  action.institution.id
      // },
      fetchPolicy: 'network-only',
    }).pipe(
      this.notificationService.catchError('Problem occurred while fetching Branches'),
      map(({ data }: any) => {
        if (data) {
          this.store.dispatch(fromActions.loadRequestPrioritys({ requestPrioritys: data.getAllInstitutionChangeRequestPriority.content }));
        }
      })
    );
  })
), {dispatch: false});




 // Create/Save Request Priority
 createRequestPriority$ = createEffect(() => this.actions$.pipe(
  ofType(fromActions.createRequestPriority),
  switchMap((action) => {
    return this.apollo.mutate({
      mutation: fromGraphql.SAVE_UPDATE_REQUEST_PRIORITY,
        variables: {
          priority: action.requestPriority
        }
    }).pipe(
      this.notificationService.catchError('Problem occurred while saving Request Priority'),
      map(({ data }: any) => {
        if (data) {
          if (data.createUpdateChangeRequestPriority.code === 9000) {
            this.store.dispatch(fromActions.upsertRequestPriority({ requestPriority: data.createUpdateChangeRequestPriority.data }));
            if (action.requestPriority?.id) {
              this.notificationService.successMessage('Edited Successfully');
            } else {
              this.notificationService.successMessage('Created Successfully');
            }
          } else {
            const error = this.responseCodesService.getCodeDescription(data.createUpdateChangeRequestPriority.code);
            this.notificationService.errorMessage(error[0]?.description);
          }
        }
      })
    );
  })
), {dispatch: false});



  
  // Detele Branch
  removeRequestPriority$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.removeRequestPriority),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: fromGraphql.DELETE_REQUEST_PRIORITY,
        variables: {
          id: action.id
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while deleting department'),
        map(({ data }: any) => {
          if (data) {
            if (data.deleteChangeRequestPriority.code === 9000) {
              // this.store.dispatch(fromActions.deleteBranch({ id: action.id }));
              this.store.dispatch(fromActions.deleteRequestPriority({ id: action.id}));
              // this.store.dispatch(fromActions.upsertResourcePriority({ resourcePriority: data.deleteResourcePriority.data }));

              this.notificationService.successMessage('Deleted Successfully');
            } else {
              this.notificationService.errorMessage(data.deleteChangeRequestPriority);
            }
          }
        })
      );
    })
  ), {dispatch: false});



  constructor(
    private actions$: Actions,
    private apollo: Apollo,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private responseCodesService: ResponseCodeService
  ) {
  }


}
