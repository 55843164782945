import { Injectable } from '@angular/core';
import { ResponseCodeService } from '../../../../services/response-code.service';
import { AppState } from '../../../reducers/index';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import { NotificationService } from 'src/app/services/notification.service';
import * as fromActions from './category.actions';
import * as fromGraphql from './category.graphql';
import { map, switchMap } from 'rxjs/operators';



@Injectable()
export class CategoryEffects {
 // List All Institution Category 
 listOfInstitutionCategory$ = createEffect(() => this.actions$.pipe(
  ofType(fromActions.listOfInstitutionAllCategorys),
  switchMap((action) => {
    return this.apollo.query({
      query: fromGraphql.LIST_OF_INSTITUTION_CATEGORIES,
      // variables: {
      //   institutionId:  action.institution.id
      // },
      fetchPolicy: 'network-only',
    }).pipe(
      this.notificationService.catchError('Problem occurred while fetching Categories'),
      map(({ data }: any) => {
        if (data) {
          this.store.dispatch(fromActions.loadCategorys({ categorys: data.listOfInstitutionCategories }));
        }
      })
    );
  })
), {dispatch: false});

  constructor(
    private actions$: Actions,
    private apollo: Apollo,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private responseCodesService: ResponseCodeService
  ) {
  }


}
