import gql from 'graphql-tag';


export const onlinefields = `
active
deleted
description
id
institution {
    id
    code
    name

}
ipAddresses
name
services {
    id
    code
    description
}
url
`;


export const GET_MY_INSTITUTION_ONLINE_RESOURCES = gql`
    query getMyInstitutionOnlineResources{
        getMyInstitutionOnlineResources{
            code
            dataList{
                ${onlinefields}
            }
        }
    }
`;
export const GET_ONLINE_RESOURCE_BY_ID = gql`
    query getOnlineResourceById($id: Long){
        getOnlineResourceById(id: $id){
            code
            data{
                ${onlinefields}
            }
        }
    }
`;

export const SAVE_ONLINE_RESOURCE = gql`
    mutation saveOnlineResource($onlineResource: OnlineResourcDtoInput){
        saveOnlineResource(onlineResource: $onlineResource) {
            code
            data {
                ${onlinefields}
            }
        }
    }
`;

export const DELETE_ONLINE_RESOURCE = gql`
    mutation deleteOnlineResource($onlineResourceId: Long!) {
        deleteOnlineResource(onlineResourceId: $onlineResourceId)
    }
`;

