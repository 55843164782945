import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { SolutionStep } from './solution-step.model';
import { PageableParam } from 'src/app/interfaces/global.interface';

export const loadSolutionSteps = createAction(
  '[SolutionStep/API] Load SolutionSteps',
  props<{ solutionSteps: SolutionStep[] }>()
);

export const addSolutionStep = createAction(
  '[SolutionStep/API] Add SolutionStep',
  props<{ solutionStep: any }>()
);

export const upsertSolutionStep = createAction(
  '[SolutionStep/API] Upsert SolutionStep',
  props<{ solutionStep: SolutionStep }>()
);

export const addSolutionSteps = createAction(
  '[SolutionStep/API] Add SolutionSteps',
  props<{ solutionSteps: SolutionStep[] }>()
);

export const upsertSolutionSteps = createAction(
  '[SolutionStep/API] Upsert SolutionSteps',
  props<{ solutionSteps: SolutionStep[] }>()
);

export const updateSolutionStep = createAction(
  '[SolutionStep/API] Update SolutionStep',
  props<{ solutionStep: Update<SolutionStep> }>()
);

export const updateSolutionSteps = createAction(
  '[SolutionStep/API] Update SolutionSteps',
  props<{ solutionSteps: Update<SolutionStep>[] }>()
);

export const deleteSolutionStep = createAction(
  '[SolutionStep/API] Delete SolutionStep',
  props<{ id: string }>()
);

export const deleteSolutionSteps = createAction(
  '[SolutionStep/API] Delete SolutionSteps',
  props<{ ids: string[] }>()
);

export const clearSolutionSteps = createAction(
  '[SolutionStep/API] Clear SolutionSteps'
);


////////////////////  APIs: ////////////////////////

// getSolutionByIncident
export const getAllSolutionStepsByIncident = createAction(
  '[Knowledgebase/API] get solutions Pageable by incident',
  props<{ incidentUuid: string, pageable?: PageableParam }>()
);

export const addUpdateKnowledgeBaseQuestionSolutionStep = createAction(
  '[Knowledgebase/API] add incident solution',
  props<{ incidentSolutionStep: any }>()
);

export const deleteKnowledgeBaseQuestionSolutionStep = createAction(
  '[Knowledgebase/API] delete incident solution',
  props<{ incidentSolutionStepUuid: any }>()
);
