import { Validators } from '@angular/forms';
import { FieldConfig, FieldType } from 'src/app/shared/components/dynamic-forms-components/field.interface';

export const fields: FieldConfig[] = [
    {
        type: FieldType.input,
        label: 'First name',
        key: 'firstName',
        validations: [{message: 'First Name is Required', validator: Validators.required, name: 'required'}],
        rowClass: 'col6'
    },
    {
        type: FieldType.input,
        label: 'Middle name',
        key: 'middleName',
        validations: [{message: 'Middle Name is Required', validator: Validators.required, name: 'required'}],
        rowClass: 'col6'
    },
    {
        type: FieldType.input,
        label: 'Last name',
        key: 'lastName',
        validations: [{message: 'Last Name is Required', validator: Validators.required, name: 'required'}],
        rowClass: 'col6'
    },
    
    {
        type: FieldType.input,
        label: 'Phone',
        key: 'phone',
        validations: [{message: 'Phone is Required', validator: Validators.required,
        name: 'required'}],
        rowClass: 'col6'
    },
 
    {
        type: FieldType.input,
        label: 'Email',
        key: 'email',
        validations: [{message: 'Department Code is Required', validator: Validators.required, name: 'required'}],
        rowClass: 'col6'
    },
    {
        type: FieldType.input,
        label: 'Check Number',
        key: 'checkNumber',
        validations: [{message: 'Check Number is Required', validator: Validators.required, name: 'required'}],
        rowClass: 'col6'
    },
    
    {
        type: FieldType.button,
        label: 'Save',
        rowClass: 'col12'
    },
];

