import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-client-content',
  templateUrl: './client-content.component.html',
  styleUrls: ['./client-content.component.scss']
})
export class ClientContentComponent implements OnInit {
  currentYear: number;
  constructor() { }

  ngOnInit(): void {
  
    this.currentYear = new Date().getFullYear();
  }

}
