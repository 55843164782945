import { Validators } from '@angular/forms';
import { FieldConfig, FieldType } from 'src/app/shared/components/dynamic-forms-components/field.interface';
import { GET_INSTITUTION_LIST_FOR_PAGINATED_SELECT } from 'src/app/store/entities/settings/institution/institution.graphql';
import { LIST_ALL_SERVICES_FEW_FIELD_BY_INSTITUTION } from 'src/app/store/entities/settings/service/service.graphql';
import { ServiceSearchFields } from 'src/app/store/entities/settings/service/service.model';


export const serviceFiels: FieldConfig[] = [
   
    {
        type: FieldType.paginatedselect,
        label: 'Select Service',
        key: 'service',
        query: LIST_ALL_SERVICES_FEW_FIELD_BY_INSTITUTION,
        queryKey: 'getInstitutionServicesByInstitutionUuid',
        useObservable: true,
        optionName: 'name',
        optionValue: 'uuid',
        validations: [{message: 'Service is Required', validator: Validators.required, name: 'required'}],
        rowClass: 'col12',
        searchFields: ['name','code'],
    }
];

export const fields: FieldConfig[] = [
   
    {
        type: FieldType.paginatedselect,
        label: 'Select Customer',
        key: 'client',
        query: GET_INSTITUTION_LIST_FOR_PAGINATED_SELECT,
        queryKey: 'listOfInstitutions',
        useObservable: true,
        optionName: 'name',
        optionValue: 'uuid',
        validations: [{message: 'Customer is Required', validator: Validators.required, name: 'required'}],
        rowClass: 'col6',
        searchFields: ['name','voteCode', 'gispCode', 'email', 'internalIncidentInitialCode'],
    },
    {
        type: FieldType.input,
        label: 'Customer Code',
        key: 'code',
        inputType: 'text',
        rowClass: 'col6',
        validations: [{message: 'This field is equired', validator: Validators.required, name: 'required'}]
    },
    {
        type: FieldType.button,
        label: 'Add',
        showWhen: '{(code)(!=)(null)}',
        rowClass: 'col6'
    },
];
