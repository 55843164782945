import gql from 'graphql-tag';

export const DELETE_SERVICE_GROUP = gql`
mutation deleteServiceGroup ($groupUuid: String){
  deleteServiceGroup(groupUuid: $groupUuid){
    code
    message    
    data{
      id
      uuid
    }
  
  }
}
`;


export const CREATE_UPDATE_SERVICE_GROUP = gql`
mutation  createUpdateServiceGroup ($groupDto: ServiceGroupDtoInput){
  createUpdateServiceGroup(groupDto: $groupDto){
    message
    code
    data{
      id
      uuid
      name
      descriptions
      institutionServices{
        id
        uuid
        name
      }
    }
    
  }
}
`;

export const GET_ALL_SERVICE_GROUP = gql`
query getAllServiceGroups ($pageParam: PageableParamInput){
  getAllServiceGroups(pageParam: $pageParam){
   content{
    id
    uuid
    name    
    descriptions
    institutionServices{
      id
      uuid
      name
    }
  }
    first
    last
    size
  }
}
`;








