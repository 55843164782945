import gql from 'graphql-tag';
import { institutionFields } from '../institution/institution.graphql';
import { userFields } from '../user/user.graphql';

export const designationFields = `
        active
        createdAt
        createdBy
        deleted
        description
        designationCode
        designationName
        id
        institution {
            ${institutionFields}
        }
        updatedAt
        updatedBy
        userList {
            ${userFields}
        }
        uuid
`;


export const GET_ALL_DESIGNATIONS_BY_INSTITUTION = gql`
    query getAllDesignationsByInstitution($institutionId:Long){
      getAllDesignationsByInstitution(institutionId:$institutionId){
        ${designationFields}
      }
    }
`;


export const INSTITUTION_DESIGNATIONS = gql`
    query myInstitutionDesignations{
        myInstitutionDesignations{
            ${designationFields}
        }
    }
`;



export const CREATE_DESIGNATION = gql`
    mutation createDesignation($designation: DesignationDtoInput){
        createDesignation(designation:$designation){
        code
        status
        message
        data{
            ${designationFields}
        }
        dataList{
            ${designationFields}
        }
        }
    }
`;

export const UPDATE_DESIGNATION = gql`
mutation updateDesignation($id:Long,$designation:DesignationDtoInput){
    updateDesignation(id:$id,designation:$designation){
      message
      code
      error
      data{
        ${designationFields}
      }
    }
  }
`;


export const REMOVE_DESIGNATION = gql`
        mutation removeDesignation($id: String){
            deleteDesignation(id:$id){
            code
            message
            status
            }
        }

`;
