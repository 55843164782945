import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  @Input() message?: string;
  @Input() progressMode?: 'determinate' | 'indeterminate' = 'indeterminate';
  @Input() progressValue?: number = 0;
  itTakesLongTime = false;
  @Input() withCard = true;
  constructor() { }

  ngOnInit() {
    setTimeout(() => {

    }, 30000);
  }

}
