import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {AdministrativeArea} from './administrative-area.model';
import {PageableParam} from 'src/app/interfaces/global.interface';

export const loadAdministrativeAreas = createAction(
    '[AdministrativeArea/API] Load AdministrativeAreas',
    props<{ administrativeAreas: AdministrativeArea[] }>()
);

export const addAdministrativeArea = createAction(
    '[AdministrativeArea/API] Add AdministrativeArea',
    props<{ administrativeArea: AdministrativeArea }>()
);

export const upsertAdministrativeArea = createAction(
    '[AdministrativeArea/API] Upsert AdministrativeArea',
    props<{ administrativeArea: AdministrativeArea }>()
);

export const addAdministrativeAreas = createAction(
    '[AdministrativeArea/API] Add AdministrativeAreas',
    props<{ administrativeAreas: AdministrativeArea[] }>()
);

export const upsertAdministrativeAreas = createAction(
    '[AdministrativeArea/API] Upsert AdministrativeAreas',
    props<{ administrativeAreas: AdministrativeArea[] }>()
);

export const updateAdministrativeArea = createAction(
    '[AdministrativeArea/API] Update AdministrativeArea',
    props<{ administrativeArea: Update<AdministrativeArea> }>()
);

export const updateAdministrativeAreas = createAction(
    '[AdministrativeArea/API] Update AdministrativeAreas',
    props<{ administrativeAreas: Update<AdministrativeArea>[] }>()
);

export const deleteAdministrativeArea = createAction(
    '[AdministrativeArea/API] Delete AdministrativeArea',
    props<{ id: number }>()
);

export const deleteAdministrativeAreas = createAction(
    '[AdministrativeArea/API] Delete AdministrativeAreas',
    props<{ ids: number[] }>()
);

export const clearAdministrativeAreas = createAction(
    '[AdministrativeArea/API] Clear AdministrativeAreas'
);

export const listAllAdministrativeAreas = createAction(
    '[AdministrativeArea/API] List All AdministrativeAreas',
    props<{ pageableParam?: PageableParam, fewFields?: boolean }>()
);
export const modifyAdministrativeArea = createAction(
    '[AdministrativeArea/API] Modify AdministrativeArea Details',
    props<{ id: number; administrativeArea: AdministrativeArea }>()
);

export const getAdministrativeAreaById = createAction(
    '[AdministrativeArea/API] Get AdministrativeArea By Id',
    props<{ id: number }>()
);

export const getAdministrativeAreaByCode = createAction(
    '[AdministrativeArea/API] Get AdministrativeArea By Code',
    props<{ AdministrativeAreaCode: string }>()
);

export const createAdministrativeArea = createAction(
    '[AdministrativeArea/API] Create AdministrativeArea',
    props<{ administrativeArea: AdministrativeArea }>()
);

export const removeAdministrativeArea = createAction(
    '[AdministrativeArea/API] Remove AdministrativeArea By Id',
    props<{ id: number, uuid: string }>()
);

export const getDefaultAdministrativeArea = createAction(
    '[AdministrativeArea/API] Get Default AdministrativeArea'
);

export const activateDeactivateAdministrativeArea = createAction(
    '[AdministrativeArea/API] Activate Deactivatge AdministrativeArea',
    props<{ uuid: string }>()
);
