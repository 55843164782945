import gql from 'graphql-tag';

export const resourceCriticalitysFields = `
active
createdAt
createdBy
deleted
description
id
institutionId
institutionUuid
title
updatedAt
updatedBy
uuid
`;
// resourceList: [Resource]

// resources




export const GET_MY_INSTITUTION_ALL_RESOURCE_CRITICALITYS = gql`
query getAllResourceCriticality($pageparam: PageableParamInput){
  getAllResourceCriticality(pageparam:$pageparam){
    content{
     ${resourceCriticalitysFields}
    }
    first
    hasContent
    hasNext
    hasPrevious
    last
    nextOrLastPageable{
      pageSize
      pageNumber
    }
    nextPageable{
      pageSize
      pageNumber
    }
    number
    numberOfElements
    pageable{
      pageSize
      pageNumber
    }
    previousPageable{
      pageSize
      pageNumber
    }
    previousOrFirstPageable{
      pageSize
      pageNumber
    }
    size
    totalElements
    totalPages
    
  }
}
`;
export const GET_MY_INSTITUTION_ALL_RESOURCE_CRITICALITYS_FOR_OPTIONS = gql`
query getAllResourceCriticality($pageparam: PageableParamInput){
  getAllResourceCriticality(pageparam:$pageparam){
    content{
      id
      uuid
      title
    }
  }
}
`;

export const ACTIVATE_AND_DEACTIVATE_RESOURCE_CRITICALITY = gql`
mutation activateDeactivateResourceCriticality($severityUuid: String){
  activateDeactivateResourceCriticality(severityUuid:$severityUuid){
    code
    data{
      ${resourceCriticalitysFields}
    }
    dataList{
      ${resourceCriticalitysFields}
    }
    message
    status
  }
}
`;

export const SAVE_UPDATE_RESOURCE_CRITICALITY = gql`
mutation addUpdateResourceCriticality($severity: ResourceCriticalityDtoInput){
  addUpdateResourceCriticality(severity:$severity){
    code
    data{
      ${resourceCriticalitysFields}
    }
    dataList{
      ${resourceCriticalitysFields}
    }
    message
    status
  }
}
`;


export const DELETE_RESOURCE_CRITICALITY = gql`
mutation deleteResourceCriticality($severityUuid: String){
  deleteResourceCriticality(severityUuid:$severityUuid){
    code
    message
    status
    data{
      ${resourceCriticalitysFields}
    }
    dataList{
      ${resourceCriticalitysFields}
    }
  }
}
`;




