import gql from 'graphql-tag';
import { roleFields } from '../role/role.graphql';

export const permissionsFields = `
    id
    active
    description
    displayName
    name
    serviceName
    createdAt
    deleted
    roleList{
      id
      name
    }
`;

export const GET_PERMISSIONS = gql`
    query getPermissions{
        getPermissions{
            ${permissionsFields}
        }
    }
`;

export const GET_ALL_PERMISSIONS = gql`
    query getAllPermissions{
        getAllPermissions{
          ${permissionsFields}
        }
    }
`;

export const GET_ALL_PERMISSIONS_GROUPED = gql`
    query getAllPermissionsGrouped{
      getAllPermissionsGrouped{
        groupName
        permissions{
          ${permissionsFields}
        }
      }
    }
`;

export const GET_PERMISSIONS_BY_ROLE_ID = gql`
    query getPermissionsByRoleId($roleId: Long) {
    getPermissionsByRoleId(roleId: $roleId) {
        id{
          permissionId
          roleId
        }
        permission{
          ${permissionsFields}
        }
        role{
          id
          name
          displayName
        }
    }
}
`;

export const ASSIGN_PERMISSIONS = gql`
  mutation assignPermission($permissions:[Long], $roleId:Long ){
    assignPermission(permissions: $permissions, roleId: $roleId){
      code
      data{
        ${roleFields}
      }
      status
    }
  }
`;
