import {Injectable} from '@angular/core';
import {ResponseCodeService} from './../../../../services/response-code.service';
import {AppState} from './../../../reducers/index';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {Apollo} from 'apollo-angular';
import {NotificationService} from 'src/app/services/notification.service';
import * as fromActions from './branch.actions';
import * as fromGraphql from './branch.graphql';
import {map, switchMap} from 'rxjs/operators';
import {GET_ADMINISTRATIVE_AREAS_BY_TYPE} from '../administrative-area/administrative-area.graphql';


@Injectable()
export class BranchEffects {
    // List All Branchs
    listMyINSTITUTIONAllBranchs$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.listMyInstitutionAllBranchs),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.GET_MY_INSTITUTION_ALL_BRANCHES,
                fetchPolicy: 'network-only',
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching Branches'),
                map(({data}: any) => {
                    if (data) {
                        this.store.dispatch(fromActions.loadBranchs({branchs: data.getMyInstBranches}));
                    }
                })
            );
        })
    ), {dispatch: false});

    listMyInstitutionAllBranchesForList$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.listMyInstitutionAllBranchsForList),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.GET_MY_INSTITUTION_ALL_BRANCHES_FOR_LIST,
                fetchPolicy: 'network-only',
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching Branches'),
                map(({data}: any) => {
                    if (data) {
                        this.store.dispatch(fromActions.loadBranchs({branchs: data.getMyInstBranches}));
                    }
                })
            );
        })
    ), {dispatch: false});


    listAllBranchs$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.listAllBranchs),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.GET_ALL_BRANCHES,
                variables: {
                    institutionId: action.institution.id
                },
                fetchPolicy: 'network-only',
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching Branches'),
                map(({data}: any) => {
                    if (data) {
                        this.store.dispatch(fromActions.loadBranchs({branchs: data.getBranchesByInstitution}));
                    }
                })
            );
        })
    ), {dispatch: false});


    // Create/Save Branch
    createBranch$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.createBranch),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.SAVE_UPDATE_BRANCH,
                variables: {
                    branch: action.branch
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while saving Branch'),
                map(({data}: any) => {
                    if (data) {
                        if (data.createUpdateBranch.code === 9000) {
                            this.store.dispatch(fromActions.upsertBranch({branch: data.createUpdateBranch.data}));
                            if (action.branch?.id) {
                                this.notificationService.successMessage('Edited Successfully');
                            } else {
                                this.notificationService.successMessage('Created Successfully');
                            }
                        } else {
                            const error = this.responseCodesService.getCodeDescription(data.createUpdateBranch.code);
                            this.notificationService.errorMessage(error[0]?.description);
                        }
                    }
                })
            );
        })
    ), {dispatch: false});

    // Update Branch
    modifyBranch$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.modifyBranch),
        switchMap((action) => {

            return this.apollo.mutate({
                mutation: fromGraphql.SAVE_UPDATE_BRANCH,
                variables: {
                    branch: action.branch
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while updating user'),
                map(({data}: any) => {
                    if (data) {

                        if (data.createUpdateBranch.code === 9000) {
                            this.store.dispatch(fromActions.upsertBranch({branch: data.createUpdateBranch.data}));
                            this.notificationService.successMessage('User Updated Successfully');
                        } else {
                            const error = this.responseCodesService.getCodeDescription(data.createUpdateBranch.code);
                            this.notificationService.errorMessage(error[0]?.description);
                        }
                    }
                })
            );
        })
    ), {dispatch: false});


    // Detele Branch
    removeBranch$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.removeBranch),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.DELETE_BRANCH,
                variables: {
                    id: action.id
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while deleting department'),
                map(({data}: any) => {
                    if (data) {
                        if (data.deleteBranch.code === 9000) {
                            // this.store.dispatch(fromActions.deleteBranch({ id: action.id }));
                            this.store.dispatch(
                                fromActions.deleteBranch({id: action.id})
                            );
                            this.notificationService.successMessage('Deleted Successfully');
                        } else {
                            this.notificationService.errorMessage(data.deleteBranch);
                        }
                    }
                })
            );
        })
    ), {dispatch: false});


    findBranchesByInstitution$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.findBranchesByInstitution),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.FIND_BRANCHES_BY_INSTITUTION_UUID,
                variables: {
                    institutionUuid: action.institutionUuid
                },
                fetchPolicy: 'network-only',
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching Branches'),
                map(({data}: any) => {
                    if (data) {
                        this.store.dispatch(fromActions.loadBranchs({branchs: data.findBranchesByInstitution}));
                    }
                })
            );
        })
    ), {dispatch: false});

// List All Administrative Area
    listAllAdministrativeAreasByType$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getAllAdministrativeAreasByType),
        switchMap((action) => {
            return this.apollo.query({
                query: GET_ADMINISTRATIVE_AREAS_BY_TYPE,
                variables: {
                    type: action.areaType,
                    pageableParam: action?.pageableParam
                },
                fetchPolicy: 'network-only',
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching Administrative Areas '),
                map(({data}: any) => {
                    if (data) {
                        this.store.dispatch(fromActions.loadBranchs({branchs: data.administrativeAreaByType?.content}));
                    }
                })
            );
        })
    ), {dispatch: false});


    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private store: Store<AppState>,
        private notificationService: NotificationService,
        private responseCodesService: ResponseCodeService
    ) {
    }


}
