import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Role } from './role.model';
import { PageableParam } from 'src/app/interfaces/global.interface';

export const loadRoles = createAction(
  '[Role/API] Load Roles',
  props<{ roles: Role[] }>()
);

export const addRole = createAction(
  '[Role/API] Add Role',
  props<{ role: Role }>()
);

export const upsertRole = createAction(
  '[Role/API] Upsert Role',
  props<{ role: Role }>()
);

export const addRoles = createAction(
  '[Role/API] Add Roles',
  props<{ roles: Role[] }>()
);

export const upsertRoles = createAction(
  '[Role/API] Upsert Roles',
  props<{ roles: Role[] }>()
);

export const updateRole = createAction(
  '[Role/API] Update Role',
  props<{ role: Update<Role> }>()
);

export const updateRoles = createAction(
  '[Role/API] Update Roles',
  props<{ roles: Update<Role>[] }>()
);

export const deleteRole = createAction(
  '[Role/API] Delete Role',
  props<{ id: number }>()
);

export const deleteRoles = createAction(
  '[Role/API] Delete Roles',
  props<{ ids: number[] }>()
);

export const clearRoles = createAction(
  '[Role/API] Clear Roles'
);

// Effect Actions
export const listAllRolesPageable = createAction(
  '[Role/API] List All Roles by institution',
  props<{ institutionUuid?: string, pageableParam: PageableParam }>()
);
export const listAllRoles = createAction(
  '[Role/API] List All Roles',
);

export const saveRole = createAction(
  '[Role/API] Save Roles',
  props<{ roleDto: Role }>()
);

export const removeRole = createAction(
  '[Role/API] Remove Role',
  props<{ roleUuid: string; roleId?: number }>()
);

export const getRoleByID = createAction(
  '[Role/API] Get Role By Id',
  props<{ roleId: number }>()
  );
  
  export const copyRoles = createAction(
    '[Role/API] copy Roles',
    props<{ institutionUuid: string; roleDto?: any }>()
  );
