import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAuthUserReducer from './auth-user.reducer';
import { AuthUser } from './auth-user.model';
import { AppState } from '../../reducers';

export const currentState = (state: AppState) => (state[fromAuthUserReducer.authUsersFeatureKey]);

export const selectAllAuthUsers = createSelector(currentState, fromAuthUserReducer.selectAll);

export const selectModifiedAuthUsers = createSelector(selectAllAuthUsers, (activities) => {
  return activities.map((activity) => {
    return {
      ...activity
    };
  });
});
