import {ResponseCodeService} from './../../../../services/response-code.service';
import {NotificationService} from './../../../../services/notification.service';
import {AppState} from './../../../reducers/index';
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {Apollo} from 'apollo-angular';
import * as fromActions from './institution.actions';
import * as fromGraphql from './institution.graphql';
import {map, switchMap} from 'rxjs/operators';
import {PaginationService} from 'src/app/services/pagination.service';
import {initializedPageableParameter} from 'src/app/interfaces/global.interface';
import {getMyNonEmployeeServiceProvidersForList} from './institution.actions';
import {GET_MY_NON_EMPLOYEE_SERVICE_PROVIDERS_FOR_LIST} from './institution.graphql';


@Injectable()
export class InstitutionEffects {

    // All Institution List
    getInstitutionList$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getInstitutionList),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.INSTITUTION_LITS,
                variables: {
                    pageParam: action?.pageaParam ? action.pageaParam : initializedPageableParameter,
                },
                fetchPolicy: 'cache-first'
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching Institutions'),
                map(({data}: any) => {
                    if (data) {
                        const result = data?.listOfInstitutions;
                        if (result?.content?.length > 0) {
                            this.paginationService.setPage(result);
                            this.store.dispatch(fromActions.loadInstitutions({institutions: data?.listOfInstitutions?.content}));
                        }
                    }
                })
            );
        })
    ), {dispatch: false});

    getInstitutionListForOptions$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getInstitutionListForOptions),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.INSTITUTION_LIST_FOR_OPTION,
                variables: {
                    pageParam: action?.pageaParam,
                },
                fetchPolicy: 'cache-first'
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching Institutions'),
                map(({data}: any) => {
                    if (data) {
                        const result = data?.listOfInstitutions;
                        this.store.dispatch(fromActions.loadInstitutions({institutions: data?.listOfInstitutions?.content}));
                    }
                })
            );
        })
    ), {dispatch: false});


    // Available service provider
    availableServiceProvader$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.availableServiceProvader),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.AVAILABLE_SERVICE_PROVIDER_INSTITUTION,
                variables: {
                    pageParam: action?.pageaParam ? action.pageaParam : initializedPageableParameter,
                },
                fetchPolicy: 'network-only'
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching Institutions'),
                map(({data}: any) => {
                    if (data) {
                        const result = data?.getAllServiceProviderInstitutionsIrrespectly;
                        if (result?.content?.length > 0) {
                            this.paginationService.setPage(result);
                            this.store.dispatch(fromActions.loadInstitutions({institutions: data.getAllServiceProviderInstitutionsIrrespectly.content}));
                        }
                        // this.store.dispatch(fromActions.loadInstitutions({ institutions: data.getAllServiceProviderInstitutionsIrrespectly.content }));
                    }
                })
            );
        })
    ), {dispatch: false});

    getMyServiceSubscribersByConfirmedStatus$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getMyServiceSubscribersByConfirmedStatus),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.GET_MY_SERVICE_SUBSCRIBER_BY_CONFIMED_STATUS,
                variables: {
                    confirmed: action?.confirmed,
                    pageParam: action?.pageaParam ? action.pageaParam : initializedPageableParameter,
                },
                fetchPolicy: 'network-only'
            }).pipe(
                map(({data}: any) => {
                    if (data) {
                        const result = data?.getMyServiceSubscribersByConfirmedStatus;
                        this.paginationService.setPage(result);
                        this.store.dispatch(fromActions.loadInstitutions({institutions: data.getMyServiceSubscribersByConfirmedStatus.content}));
                    }
                })
            );
        })
    ), {dispatch: false});


    getSubScribersList$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getSubScribersList),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.CLIENT_SUBSCRIBED_FOR_SERVICES_IN_MY_INSTITUTION,
                variables: {
                    pageParam: action?.pageaParam ? action.pageaParam : initializedPageableParameter,
                },
                fetchPolicy: 'network-only'
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching Institutions'),
                map(({data}: any) => {
                    if (data) {
                        const result = data?.clientsSubscribedForServicesInMyInst;
                        if (result?.content?.length > 0) {
                            this.paginationService.setPage(result);
                            this.store.dispatch(fromActions.loadInstitutions({institutions: data.clientsSubscribedForServicesInMyInst.content}));
                        }
                        // this.store.dispatch(fromActions.loadInstitutions({ institutions: data.clientsSubscribedForServicesInMyInst.content }));
                    }
                })
            );
        })
    ), {dispatch: false});

    getSubScribersByServiceUuid$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getSubScribersByServiceId),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.CLIENT_SUBSCRIBED_FOR_SERVICES_IN_MY_INSTITUTION_BY_SERVICE_ID,
                variables: {
                    serviceId: action.serviceId,
                    pageParam: action?.pageaParam ? action.pageaParam : initializedPageableParameter,
                },
                fetchPolicy: 'network-only'
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching Institutions'),
                map(({data}: any) => {
                    if (data) {
                        const result = data?.getAllClientsSubscribedForService;
                        this.paginationService.setPage(result);
                        this.store.dispatch(fromActions.loadInstitutions({
                            institutions:
                            data.getAllClientsSubscribedForService.content
                        }));

                    }
                })
            );
        })
    ), {dispatch: false});


    getAllServiceProviders$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getAllServiceProviders),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.MY_INSTITUTION_SERVICE_PROVIDERS,
                variables: {
                    pageParam: action?.pageaParam ? action.pageaParam : initializedPageableParameter,
                },
                fetchPolicy: 'network-only'
            }).pipe(
                map(({data}: any) => {
                    if (data) {

                        const result = data?.getAllServiceProviders;
                        this.paginationService.setPage(result);
                        this.store.dispatch(fromActions.loadInstitutions({institutions: data.getAllServiceProviders?.content}));

                    }
                })
            );
        })
    ), {dispatch: false});


    // Create insstitution
    Createinstitution$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.CreateInstitutionProfile),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.CREATE_OR_UPDATE_INTITUTIONS,
                variables: {
                    institution: action?.institution
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while updating Institution details'),
                map(({data}: any) => {
                    if (data) {
                        const response = this.responseCodesService?.getCodeDescription(data.createUpdateInstitutions?.code);
                        if (data.createUpdateInstitutions.code === 9000) {
                            this.store.dispatch(fromActions.upsertInstitution({institution: data.createUpdateInstitutions?.data}));
                            if (action.institution.uuid) {
                                this.notificationService.successMessage('Institution edited successful');
                            } else {
                                this.notificationService.successMessage('Institution registered successful');
                            }
                        } else {
                            let result = data.createUpdateInstitutions;
                            let message = result?.message ? result.message : 'Failed to save record ';
                            this.notificationService.errorMessage(message + result?.code);
                        }
                    }
                })
            );
        })
    ), {dispatch: false});

    //  // Edit Institution
    editinstitution$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.updateInstitutionDetails),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.CREATE_OR_UPDATE_INTITUTIONS,
                variables: {
                    institution: action?.institution

                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while updating Institution details'),
                map(({data}: any) => {

                    if (data) {

                        this.notificationService.successMessage('Institution details Updated Successfully');

                        //
                        if (data?.createUpdateInstitutions?.code === 9000) {
                            this.store.dispatch(fromActions.upsertInstitution({institution: data?.createUpdateInstitutions?.data}));
                            this.notificationService.successMessage('Institution details Updated Successfully');
                        } else {
                            this.notificationService.errorMessage('Failed to edit updating Institution detals');
                        }
                    }
                })
            );
        })
    ), {dispatch: false});

    activateInstitution$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.activateInstitution),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.DEACTIVATE_INSTITUTION_DETAILS,
                variables: {
                    id: action.id,
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while updating Institution details'),
                map(({data}: any) => {

                    if (data) {

                        this.notificationService.successMessage('Institution details Activated Successfully');
                        if (data?.deactivateInstitution?.code === 9000) {
                            this.store.dispatch(fromActions.upsertInstitution({institution: data?.deactivateInstitution?.data}));
                            this.notificationService.successMessage('Institution details Activated Successfully');
                        } else {
                            this.notificationService.errorMessage('Failed to Activate Institution detals');
                        }
                    }
                })
            );
        })
    ), {dispatch: false});


    deactivateInstitution$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.deactivateInstitution),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.DEACTIVATE_INSTITUTION_DETAILS,
                variables: {
                    id: action.id,

                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while updating Institution details'),
                map(({data}: any) => {

                    if (data) {

                        this.notificationService.successMessage('Institution details Deactivated Successfully');
                        if (data?.deactivateInstitution?.code === 9000) {
                            this.store.dispatch(fromActions.upsertInstitution({institution: data?.deactivateInstitution?.data}));
                            this.notificationService.successMessage('Institution details Deactivated Successfully');
                        } else {
                            this.notificationService.errorMessage('Failed to Deactivate Institution detals');
                        }
                    }
                })
            );
        })
    ), {dispatch: false});


    delateInstitution$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.deleteInstitutionDetails),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.DELETE_INSTITUTION_DETAILS,
                variables: {
                    id: action.id,
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while updating Institution details'),
                map(({data}: any) => {

                    if (data) {

                        this.notificationService.successMessage('Institution details Delete Successfully');
                        if (data?.deleteInstitution?.code === 9000) {
                            this.store.dispatch(fromActions.upsertInstitution({institution: data?.deleteInstitution?.data}));
                            this.notificationService.successMessage('Institution details Delete Successfully');
                        } else {
                            this.notificationService.errorMessage('Failed to Delete Institution detals');
                        }
                    }
                })
            );
        })
    ), {dispatch: false});


    confirmSubscription$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.confirmSubscription),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.CONFIRM_SUBSCRIPTION,
                variables: {
                    clientId: action.clientId,
                }
            }).pipe(
                map(({data}: any) => {

                    if (data) {

                        if (data?.confirmSubscription?.code === 9000) {
                            this.store.dispatch(fromActions.deleteInstitution({id: action.clientId + ''}));
                            // this.store.dispatch(fromActions.upsertInstitution({institution: data.confirmSubscription.data}));
                            this.notificationService.successMessage('Confirmed Successfully');
                        } else {
                            this.notificationService.errorMessage('Failed to Recovered Institution detals');
                        }
                    }
                })
            );
        })
    ), {dispatch: false});

    unblockBlockedInstitution$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.unblockBlockedInstitution),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.DELETE_INSTITUTION_DETAILS,
                variables: {
                    id: action.id,

                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while Recovering Institution details'),
                map(({data}: any) => {

                    if (data) {

                        this.notificationService.successMessage('Institution details Recovered Successfully');
                        if (data.deleteInstitution.code === 9000) {
                            this.store.dispatch(fromActions.upsertInstitution({institution: data.deleteInstitution.data}));
                            this.notificationService.successMessage('Institution details Recovered Successfully');
                        } else {
                            this.notificationService.errorMessage('Failed to Recovered Institution detals');
                        }
                    }
                })
            );
        })
    ), {dispatch: false});


    syncInstitutionToGISP$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.sycInstitutionToGISP),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.SYNC_INSTITUTION_TO_GISP,
                // variables: {
                //   id: action.id,

                // }
            }).pipe(
                this.notificationService.catchError('Problem occurred while Sync Institution details to Gisp'),
                map(({data}: any) => {

                    if (data) {

                        this.notificationService.successMessage('Institution details Sync Successfully');
                        if (data.syncInstitutionFromGisp.code === 9000) {
                            // this.store.dispatch(fromActions.upsertInstitution({ institution: data.syncInstitutionFromGisp.data }));
                            this.notificationService.successMessage('Institution details Sync Successfully');
                        } else {
                            this.notificationService.errorMessage('Failed to Sync Institution detals');
                        }
                    }
                })
            );
        })
    ), {dispatch: false});


    getMyInstitutionById$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getMyInstitutionById),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.GET_MY_INSTITUTION_BY_ID,
                variables: {
                    id: action?.id
                },
                fetchPolicy: 'network-only'
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching My Institution'),
                map(({data}: any) => {
                    if (data) {
                        this.store.dispatch(fromActions.loadInstitutions({institutions: [data.getInstitutionById.data]}));
                    }
                })
            );
        })
    ), {dispatch: false});

    getInstitutionByUuid = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getInstitutionByUuid),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.GET_BY_INSTITUTION_UUID,
                variables: {
                    uuid: action?.uuid
                },
                fetchPolicy: 'network-only'
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching Institution'),
                map(({data}: any) => {
                    if (data) {
                        const result = data?.getInstitutionByUuid;
                        if (result?.code === 9000) {
                            this.store.dispatch(fromActions.upsertInstitution({institution: result?.data}));
                        } else {
                            this.notificationService.handleErrorMessage(result);
                        }
                    }
                })
            );
        })
    ), {dispatch: false});


    // setIncidentHandlingMode sent by
    setIncidentHandlingMode$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.setIncidentHandlingMode),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.SEND_INCIDENT_HANDLING_MODE,
                variables: {
                    handlingMethod: action?.setIncidentHandlingMode,
                }
            }).pipe(
                map(({data}: any) => {
                    if (data) {
                        let result = data?.setIncidentHandlingMode;
                        if (result?.code === 9000) {
                            this.notificationService.successMessage('Incident Hnadling Mode set Succesfully ');
                            this.store.dispatch(fromActions.upsertInstitution({institution: result?.data}));
                        } else {
                            this.handleResponse(result);
                        }
                    }
                })
            );
        })
    ), {dispatch: false});

    deleteClientFromMyServiceSubscription$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.deleteClientFromMyServiceSubscription),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.DELETE_SUBSCRIPTION_CLIENT_CLIENT_FROM_MYINSTITUTION,
                variables: {
                    clientId: action.id,
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while updating Subscription Details details'),
                map(({data}: any) => {
                    if (data) {
                        if (data.deleteClientFromMyInstitution.code === 9000) {
                            // this.store.dispatch(fromActions.upsertInstitution({ institution: data.deleteClientFromMyInstitution }));
                            this.notificationService.successMessage('Client unsubscribed Successfully');
                        } else {
                            this.notificationService.errorMessage('Failed to unsubscribed Client details');
                        }
                    }
                })
            );
        })
    ), {dispatch: false});


// All Institution List
    getAllInstitutionClientsAvailable$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getAllInstitutionClientsAvailable),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.GET_CLIENTS_INSTITUTION,
                variables: {
                    pageParam: action?.pageaParam ? action.pageaParam : initializedPageableParameter,
                },
                fetchPolicy: 'network-only'
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching Institutions Clients'),
                map(({data}: any) => {
                    if (data) {
                        const result = data?.getAllClients;
                        if (result?.content.length > 0) {
                            this.paginationService.setPage(result);
                            this.store.dispatch(fromActions.loadInstitutions({institutions: data?.getAllClients?.content}));
                        }
                    }
                })
            );
        })
    ), {dispatch: false});

    getAllInstitutionFewFields$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getAllInstitutionFewFields),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.GET_INSTITUTION_LIST_FOR_PAGINATED_SELECT,
                variables: {
                    pageParam: action?.pageaParam ? action.pageaParam : initializedPageableParameter,
                },
                fetchPolicy: 'network-only'
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching Institutions Clients'),
                map(({data}: any) => {
                    if (data) {
                        const result = data?.listOfInstitutions;
                        if (result?.content?.length > 0) {
                            this.paginationService.setPage(result);
                            this.store.dispatch(fromActions.loadInstitutions({institutions: data?.listOfInstitutions?.content}));
                        }
                    }
                })
            );
        })
    ), {dispatch: false});


    getSubScribersListFewFields$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getSubScribersListFewFields),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.CLIENT_SUBSCRIBED_FOR_SERVICES_IN_MY_INSTITUTION_FEW_FIELDS,
                variables: {
                    pageParam: action?.pageaParam ? action.pageaParam : initializedPageableParameter,
                },
                fetchPolicy: 'network-only'
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching Institutions'),
                map(({data}: any) => {
                    if (data) {
                        const result = data?.clientsSubscribedForServicesInMyInst;
                        if (result?.content?.length > 0) {
                            this.paginationService.setPage(result);
                            this.store.dispatch(fromActions.loadInstitutions({institutions: data.clientsSubscribedForServicesInMyInst.content}));
                        }
                        // this.store.dispatch(fromActions.loadInstitutions({ institutions: data.clientsSubscribedForServicesInMyInst.content }));
                    }
                })
            );
        })
    ), {dispatch: false});


    getAllServiceProvidersFewFields$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getAllServiceProvidersFewFields),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.MY_INSTITUTION_SERVICE_PROVIDERS_FEW_FIELDS,
                variables: {
                    pageParam: action?.pageaParam ? action.pageaParam : initializedPageableParameter,
                },
                fetchPolicy: 'network-only'
            }).pipe(
                map(({data}: any) => {
                    if (data) {

                        const result = data?.getAllServiceProviders;
                        this.paginationService.setPage(result);
                        this.store.dispatch(fromActions.loadInstitutions({institutions: data.getAllServiceProviders?.content}));

                    }
                })
            );
        })
    ), {dispatch: false});


    getMyNonEmployeeServiceProviders$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getMyNonEmployeeServiceProviders),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.GET_MY_NON_EMPLOYEE_SERVICE_PROVIDERS,
                variables: {
                    pageParam: action?.pageaParam ? action.pageaParam : initializedPageableParameter,
                },
                fetchPolicy: 'network-only'
            }).pipe(
                map(({data}: any) => {
                    if (data) {
                        const result = data?.getMyServiceProviders;
                        this.paginationService.setPage(result);
                        this.store.dispatch(fromActions.loadInstitutions({institutions: data.getMyServiceProviders?.content}));

                    }
                })
            );
        })
    ), {dispatch: false});
    getMyNonEmployeeServiceProvidersForList$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getMyNonEmployeeServiceProvidersForList),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.GET_MY_NON_EMPLOYEE_SERVICE_PROVIDERS_FOR_LIST,
                variables: {
                    pageParam: action?.pageaParam,
                },
                fetchPolicy: 'network-only'
            }).pipe(
                map(({data}: any) => {
                    if (data) {
                        const result = data?.getMyServiceProviders;
                        this.paginationService.setPage(result);
                        this.store.dispatch(fromActions.loadInstitutions({institutions: data.getMyServiceProviders?.content}));

                    }
                })
            );
        })
    ), {dispatch: false});

    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private store: Store<AppState>,
        private notificationService: NotificationService,
        private responseCodesService: ResponseCodeService,
        private paginationService: PaginationService,
        private responseCodeService: ResponseCodeService,
    ) {
    }

    handleResponse(data) {
        if (data.code === 9000) {
            this.store.dispatch(fromActions.updateInstitution({institution: data.data}));
            return this.notificationService.successMessage('Attachment saved successful');
        } else {
            return this.handleError(data);
        }
    }

    handleError(data) {
        const responseCode = this.responseCodeService.getCodeDescription(data.code);
        const message = responseCode[0].code + ' : ' + responseCode[0].description;
        return this.notificationService.errorMessage(message);
    }

}
