import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Manufacture } from './manufacture.model';
import * as ManufactureActions from './manufacture.actions';

export const ManufacturesFeatureKey = 'Manufactures';

export interface State extends EntityState<Manufacture> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Manufacture> = createEntityAdapter<Manufacture>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(ManufactureActions.addManufacture,
    (state, action) => adapter.addOne(action.manufacture, state)
  ),
  on(ManufactureActions.upsertManufacture,
    (state, action) => adapter.upsertOne(action.manufacture, state)
  ),
  on(ManufactureActions.addManufactures,
    (state, action) => adapter.addMany(action.manufactures, state)
  ),
  on(ManufactureActions.upsertManufactures,
    (state, action) => adapter.upsertMany(action.manufactures, state)
  ),
  on(ManufactureActions.updateManufacture,
    (state, action) => adapter.updateOne(action.manufacture, state)
  ),
  on(ManufactureActions.updateManufactures,
    (state, action) => adapter.updateMany(action.manufactures, state)
  ),
  on(ManufactureActions.deleteManufacture,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(ManufactureActions.deleteManufactures,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(ManufactureActions.loadManufactures,
    (state, action) => adapter.setAll(action.manufactures, state)
  ),
  on(ManufactureActions.clearManufactures,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
