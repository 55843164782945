import {ValidationErrors} from '@angular/forms';
import {DateFilterFn} from '@angular/material/datepicker';
import {Observable} from 'rxjs';
import {Input} from "@angular/core";

export interface Validator {
  name: string;
  validator: ValidationErrors;
  message: string;
}

export interface Option {
  value: any;
  name: string;
  disabled?: boolean;
  loading?: boolean;
}


// export interface ControlCondition { key: any; value: any; }
type HH = '00'|'01'|'02'|'03'|'04'|'05'|'06'|'07'|'08'|'09'|'10'|'11'|'12'|'13'|'14'|'15'|'16'|'17'|'18'|'19'|'20'|'21'|'22'|'23';
type MM = '00'|'01'|'02'|'03'|'04'|'05'|'06'|'07'|'08'|'09'|'10'|'11'|'12'|'13'|'14'|'15'|'16'|'17'|'18'|'19'|'20'|'21'|'22'|'23'|'24'|'25'|'26'|'27'|'28'|'29'|'30'|'31'|'32'|'33'|'34'|'35'|'36'|'37'|'38'|'39'|'40'|'41'|'42'|'43'|'44'|'45'|'46'|'47'|'48'|'49'|'50'|'51'|'52'|'53'|'54'|'55'|'56'|'57'|'58'|'59';

type Time = `${HH}:${MM}`
export interface BaseConfig {
  label?: string;
  isLoading?: boolean;
  placeholder?: string;
  key?: string;
  otherKey?: string;
  hint?: string;
  icon?: string;
  showCounter?: boolean;
  allowedLength?: number;
  additionalVariables?: { [id: string]: any };
  /** Used when type used is of input */
  inputType?:
    | 'email'
    | 'number'
    | 'password'
    | 'tel'
    | 'text'
    | 'time'
    | 'url'
    | 'hidden'
    | 'datetime-local';
  options?: Observable<Option[]> | Option[];
  collections?: any;
  type?: FieldType;
  value?: any;
  maxTime?: Time;
  minTime?: Time;
  /** When Field is not used in a form group, 'required' can be used directly */
  required?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  visible?: boolean;
  minDate?: Date;
  maxDate?: Date;
  minInput?: Time | number;
  maxInput?: Time | number;
  pickerDateFilters?: DateFilterFn<Date>;
  multiple?: boolean;
  showWhen?: string;
  showPhoto?: boolean;
  hideRequiredMarker?: boolean;
  rowClass?: string;
  showOtherOption?: boolean;
  validations?: Validator[];
  acceptType?: ('.pdf' | '.xls' | '.png' | '.jpg')[];
  /** @deprecated The filterValueKey is deprecated, soon will be removed. Use emitted fieldData */
  filterValueKey?: string;
  /** @deprecated The filterKey is deprecated, soon will be removed. Use emitted fieldData */
  filterKey?: string;
  dateFormat?: string;
  dateFormatValue?: boolean;
  showCancel?: boolean;
  /** @deprecated The useObservable is deprecated, soon will be  removed */
  useObservable?: boolean;
  buttonColor?: 'primary' | 'accent' | 'warn';
  buttonConfirmFirst?: boolean;
  buttonConfirmText?: boolean;
  hintDanger?: boolean;
  virtualScroll?: boolean;
  searchFields?: string[];
  sortField?: string;
  /** @deprecated The queryKey is deprecated, it is no longer used */
  queryKey?: string;
  query?: any;
  /** Key for display name  */
  optionName?: string;
  /** Key for option id  */
  optionValue?: string;
  /** Option network fetch policy  */
  fetchPolicy?:any;
  /** Mandatory Filter Input */
  dateRangeFirstKey?:string;
  dateRangeFirstLabel?:string;
  dateRangeLastLabel?:string;
  dateRangeLastKey?:string;
  mustHaveFilters?: {
    fieldName: string;
    value1?: string|boolean;
    operation:
      | 'BTN'
      | 'EQ'
      | 'GT'
      | 'GTE'
      | 'ILK'
      | 'IN'
      | 'LK'
      | 'LT'
      | 'LTE'
      | 'ME'
      | 'MS'
      | 'NE'
      | 'NIN';
    value2?: string;
    /** Used with IN and NIN operation */
    inValues?: string[];
  }[];
  optionData?: { value: any; loading: boolean; error?: any };
  /** Preventing server data from concatinating with existing data */
  preventConcat?: boolean;
  /** Display dynamic pipe
   * For each pipe use its corresponding pipe arguments as they are defined in DynamicPipe class
   */
  dynamicPipes?: {
    pipe:
      | 'financialYear'
      | 'numberWithComma'
      | 'replace'
      | 'date'
      | 'titleCase'
      | 'upperCase'
      | 'defaultCase';
    firstArgs?: any;
    secondArgs?: any;
    thirdArgs?: any;
  }[];
  /** Used to order fields */
  order?: number;
  /** number of items added per page */
  pageSize?: number;

  /** Table Column to be used in  */
  tableColumns?: Array<{
    name: string;
    label: string;
    type?: string;
    info?: string;
    case?: 'titlecase' | 'uppercase' | 'lowercase';
  }>;

  /** Map Function to map items from server */
  mapFunction?: (item: any) => {};

  noSearchFields?: string[];

  removeUnderscore?: boolean;

  /** Custom Paginated Variables  */
  customVariables?: { [key: string]: any };

  /** Key used to check duplicate in array , used to disable select options  */
  duplicateCheckKey?: string;
}

interface OtherTypeQuestions extends BaseConfig {
  type:
    | FieldType.input
    | FieldType.textarea
    | FieldType.date
    | FieldType.checkbox
    | FieldType.time24
    | FieldType.time
    | FieldType.editor;
  key: string;
}

interface FormButton extends BaseConfig {
  type: FieldType.button;
  key?: string;
}

interface QuestionAttachment extends BaseConfig {
  type: FieldType.attachment;
  acceptType: ('.pdf' | '.xls' | '.png' | '.jpg')[];
  key: string;
}

interface QuestionDropDownAndRadioButton extends BaseConfig {
  type: FieldType.select | FieldType.radiobutton;
  options: Observable<any[]> | any[];
  key: string;
}

interface QuestionGroupDropDown extends BaseConfig {
  type: FieldType.groupselect;
  groupOptions: Observable<{ groupItems: [] }[]>;
  key: string;
}

interface QuestionPaginatedDropdown extends BaseConfig {
  type: FieldType.paginatedselect;
  key: string;
  searchFields: string[];
  sortField: string;
  query: any;
  optionName: string;
  optionValue: string;
}

interface QuestionPhoneInputDropdown extends BaseConfig {
  type: FieldType.phoneInput;
  key: string;
  searchFields: string[];
  sortField: string;
  query: any;
  optionName: string;
  optionValue: string;
}

interface QuestionPaginatedDropdownOut extends BaseConfig {
  key: string;
}

interface QuestionInput extends BaseConfig {
  type: FieldType.input;
  inputType:
    | 'email'
    | 'number'
    | 'password'
    | 'tel'
    | 'text'
    | 'time'
    | 'url'
    | 'hidden';
  key: string;
}

export enum FieldType {
  input = 'input',

  textarea = 'textarea',

  radiobutton = 'radiobutton',

  select = 'select',

  paginatedselect = 'paginatedselect',

  phoneInput = 'phoneInput',

  groupselect = 'groupselect',

  date = 'date',

  time = 'time',

  time24 = 'time24',

  button = 'button',

  checkbox = 'checkbox',

  attachment = 'attachment',

  number = 'number',
  
  editor = 'editor',
}

export type FieldConfig =
  | QuestionPaginatedDropdownOut
  | QuestionPhoneInputDropdown
  | OtherTypeQuestions
  | QuestionAttachment
  | QuestionInput
  | FormButton
  | QuestionPaginatedDropdown
  | QuestionGroupDropDown
  | QuestionDropDownAndRadioButton;

export interface PreviousDocument {
  fieldKey: string;
  title?: string;
  attachmentUuid: string;
  format: string
}
