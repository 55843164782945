import { Component, OnInit, Input, ViewChild, ContentChild, TemplateRef, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnInit, OnDestroy {

  @Input() tableData: any;
  @Input() columnHeader;
  @Input() hasActionsColumn = true;
  @Input() isObservable = true;
  objectKeys = Object.keys;
  listData: MatTableDataSource<any>;
  searchKey: string;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ContentChild(TemplateRef, {static : true}) actions: TemplateRef<any>;
  subscriptions: Subscription = new Subscription();
  actionColumn: boolean;

  constructor() {

  }

  ngOnInit() {
    this.actionColumn = this.hasActionsColumn;

    if (this.isObservable) {
      this.subscriptions.add(
        this.tableData?.subscribe(data => {
          this.listData = new MatTableDataSource(data);
          this.listData.sort = this.sort;
          this.listData.paginator = this.paginator;
        })
      );
    }
    else {
      this.listData = new MatTableDataSource(this.tableData);
      this.listData.sort = this.sort;
      this.listData.paginator = this.paginator;
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  filterTable() {
    this.listData.filter = this.searchKey.trim().toLowerCase();
  }

  onSearchClear() {
    this.searchKey = '';
    this.filterTable();
  }

}
