import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ApprovalLinkage } from './approval-linkage.model';
import * as ApprovalLinkageActions from './approval-linkage.actions';

export const approvalLinkagesFeatureKey = 'approvalLinkages';

export interface State extends EntityState<ApprovalLinkage> {
  // additional entities state properties
}

export const adapter: EntityAdapter<ApprovalLinkage> = createEntityAdapter<ApprovalLinkage>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(ApprovalLinkageActions.addApprovalLinkage,
    (state, action) => adapter.addOne(action.approvalLinkage, state)
  ),
  on(ApprovalLinkageActions.upsertApprovalLinkage,
    (state, action) => adapter.upsertOne(action.approvalLinkage, state)
  ),
  on(ApprovalLinkageActions.addApprovalLinkages,
    (state, action) => adapter.addMany(action.approvalLinkages, state)
  ),
  on(ApprovalLinkageActions.upsertApprovalLinkages,
    (state, action) => adapter.upsertMany(action.approvalLinkages, state)
  ),
  on(ApprovalLinkageActions.updateApprovalLinkage,
    (state, action) => adapter.updateOne(action.approvalLinkage, state)
  ),
  on(ApprovalLinkageActions.updateApprovalLinkages,
    (state, action) => adapter.updateMany(action.approvalLinkages, state)
  ),
  on(ApprovalLinkageActions.deleteApprovalLinkage,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(ApprovalLinkageActions.deleteApprovalLinkages,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(ApprovalLinkageActions.loadApprovalLinkages,
    (state, action) => adapter.setAll(action.approvalLinkages, state)
  ),
  on(ApprovalLinkageActions.clearApprovalLinkages,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
