export interface TableColumn {
    keyword: string;
    visible?: boolean;
    title?: string;
    type?: TableColumnType;
    condition?: any;
    dateFormat?: string;
    sortable?: boolean;
    isActionColumn?: boolean;
    columnClass?: string[];
    theadCellClass?: string | string[];
    tbodyCellClass?: string | string[];
}

export enum TableColumnType {
    index = 'index',

    string = 'string',

    html = 'html',

    number = 'number',

    textarea = 'textarea',

    radiobutton = 'radiobutton',

    select = 'select',

    date = 'date',

    dateRange = 'dateRange',

    button = 'button',

    inputCheckbox = 'inputCheckbox',

    inputAttachment = 'inputAttachment',

    inputText = 'inputText',

    inputNumber = 'inputNumber',

}


export const initialPageableParam = {size: 10, first: 0, sortBy: 'id', sortDirection: 'DESC'};
export const initialPageableParamForList = {size: 100000000, first: 0, sortBy: 'id', sortDirection: 'DESC'};
