import { Component, OnInit, Input, ViewChild, ContentChild, TemplateRef, OnDestroy, EventEmitter, Output } from '@angular/core';
import { fromEvent, merge, Observable, of, Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { PaginationService } from 'src/app/services/pagination.service';
import { SettingsService } from 'src/app/services/settings.service';
import { initializedPageableParameter, PageableParam, SearchFieldsDtoInput } from 'src/app/interfaces/global.interface';
@Component({
  selector: 'app-paginated-data-table',
  templateUrl: './paginated-data-table.component.html',
  styleUrls: ['./paginated-data-table.component.scss']
})
export class PaginatedDataTableComponent implements OnInit, OnDestroy {

  @Input() tableData: any;
  @Input() isPageable = false;
  @Input() columnHeader;
  @Input() hasActionsColumn = true;
  objectKeys = Object.keys;
  listData: MatTableDataSource<any>;
  searchKey: string;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ContentChild(TemplateRef, {static : true}) actions: TemplateRef<any>;
  subscriptions: Subscription = new Subscription();
  actionColumn: boolean;

  pageSize:number;
  dataLength:number;
  pageSizeOptions:number[];

  @Input() searchFields: SearchFieldsDtoInput[];
  @Input() searchDelayTime: number = 2000;
  
  @Output() pageable = new EventEmitter<PageableParam>();

  currentSort = {sortBy:"id", sortDirection:'DESC'}
  currentSearch;
  pageParam:any = initializedPageableParameter;

  constructor(
    private  paginationService: PaginationService,
    private settingsService:SettingsService,
  ) {

  }

  
  ngOnInit() {
    this.actionColumn = this.hasActionsColumn;
    this.subscriptions.add(
      this.tableData.subscribe(data => {
        this.listData = new MatTableDataSource(data);
        this.listData.sort = this.sort;
        
        if(!this.isPageable) this.listData.paginator = this.paginator;
  
        else{
          let page = this.paginationService.getPage();
          if(page) {
            this.dataLength = page.totalElements;
            this.pageSize = page.size;
          } 
        }
      
        this.pageSize = 10;
        this.pageSizeOptions = [10, 50, 100, 250]
      })
    );

  }


  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
  
    
  sortChange(e) {
    this.currentSort = { sortBy:e.active, sortDirection:e.direction}
    this.handlePage();
  }


  filterTable() {
    this.listData.filter = this.searchKey.trim().toLowerCase();
    if(this.searchKey) this.currentSearch = this.searchFields.map(field => {
      return {...field, fieldValue:this.searchKey}
    })
    this.handlePage()
  }

  
  timerRunning: boolean;
  delayedFilter(){
    if(this.timerRunning) return;
    this.timerRunning = true;

    let timer = setTimeout(()=>{
      this.filterTable();
      this.timerRunning = false;
    },this.isPageable? this.searchDelayTime:0);
  }


  onSearchClear() {
    this.searchKey = '';
    this.filterTable();
  }


   handlePage(e?: PageEvent) {
    if(!this.isPageable) return

     if(e){
      this.pageParam =  {
        ...this.pageParam, 
        first: e.pageIndex,  //practically this should be named page
        size: e.pageSize,
      }
     }

     this.pageParam = {...this.pageParam, ...this.currentSort, searchFields: this.currentSearch}
     if(!this.searchKey && this.pageParam.searchFields || !this.currentSearch) delete this.pageParam.searchFields;
     
     this.pageable.emit(this.pageParam)
   }



}
