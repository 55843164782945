import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FieldConfig } from '../field.interface';
@Component({
  selector: 'app-radiobutton',
  template: `



<ng-container *ngIf="field?.options">
<ng-container *ngIf="field?.options | withLoading | async as data">
<fieldset *ngFor="let item of data?.value || []">
    <label for="{{item.name}}_name">{{item.name}}</label>
    <input id="{{item.name}}_name" type="radio" [formControlName]="field?.key??''" value="{{item.value}}">
</fieldset>
</ng-container>
</ng-container>
`,
  // host: {'class': 'col-md-6'},
  // styles: [
  //   '.dynamic-radio-group { display: flex; flex-direction: column;  margin: 15px 0; } .dynamic-radio-button {    margin: 5px;  }'
  // ]
})
export class RadiobuttonComponent implements OnInit {
  @Input() field?: FieldConfig;
  @Input() group: UntypedFormGroup = new UntypedFormGroup({});
  // @HostBinding('class') rowClass = 'col-md-6';
  constructor() { }
  ngOnInit() {
    //  this.rowClass = this.field.rowClass === 'col12' ? 'col-md-12' : 'col-md-6';
  }
}
