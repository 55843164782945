import {Component, Input, OnInit} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {select, Store} from '@ngrx/store';
import {NotificationService} from 'src/app/services/notification.service';
import {Observable, Subscription} from 'rxjs';
import {AppState} from 'src/app/store/reducers';
import {ClientIdentification, IdentificationSearchFields} from 'src/app/store/entities/client-identification/client-identification.model';
import {initializedPageableParameter, PageableParam} from 'src/app/interfaces/global.interface';
import {selectModifiedClientIdentifications} from 'src/app/store/entities/client-identification/client-identification.selectors';
import {
    clearClientIdentifications,
    findInstitutionClientsLocalCodes
} from 'src/app/store/entities/client-identification/client-identification.actions';
import {StorageService} from 'src/app/services/storage.service';
import {ClientIdentificationFormComponent} from './client-identification-form/client-identification-form.component';
import {ActivatedRoute} from '@angular/router';
import {InstitutionPipe} from 'src/app/shared/pipes/institution.pipe';
import {Institution} from '../../../../store/entities/settings/institution/institution.model';
import {UploadingClientsComponent} from './uploading-clients/uploading-clients.component';

@Component({
    selector: 'app-client-identification',
    templateUrl: './client-identification.component.html',
    styleUrls: ['./client-identification.component.scss'],
    providers: [InstitutionPipe]
})
export class ClientIdentificationComponent implements OnInit {
    title: string;
    clientIdentifications$: Observable<ClientIdentification[]>;
    tableColumns: any;
    pageable: PageableParam = initializedPageableParameter;
    searchFields = IdentificationSearchFields;
    institutionUuid: any;
    @Input() institution: Institution;

    searchedValue: string;
    subscription = new Subscription();
    userType: any;
    myInstitutionUid: string;

    constructor(
        private store: Store<AppState>,
        private dialog: MatDialog,
        private notificationService: NotificationService,
        private storageService: StorageService,
        private route: ActivatedRoute,
        private institutionPipe: InstitutionPipe,
    ) {
        this.store.dispatch(clearClientIdentifications());
        this.institutionUuid = route.snapshot.paramMap.get('institutionUuid');
        this.myInstitutionUid = storageService.getItem('institutionUuid');
        this.userType = storageService.getItem('userType');

        this.loadData();
    }

    ngOnInit(): void {
        if (this.institutionUuid || this.institution) {
            this.title = 'Customer List for: ';
            if (this.institution) {
                this.title = this.title + ' ' + (this.institution?.name?.length > 50 ?
                    this.institution?.name?.substring(0, 49) + ' ...' : this.institution?.name);
                this.institutionUuid = this.institution?.uuid;
            } else {
                const institutionDetails$ = this.institutionPipe.transform(this.institutionUuid);
                this.subscription.add(
                    institutionDetails$.subscribe((data) => {
                        this.title = this.title + ' ' + (data.length > 50 ? data?.substring(0, 49) + ' ...' : data);
                    })
                );
            }

        } else {
            this.title = 'My Customer List';
            this.institutionUuid = this.storageService.getItem('institutionUuid');
        }

        this.tableColumns = {
            no: 'SN',
            clientName: 'Client Name',
            localClientCode: 'Identification Code',
            serviceName: 'Service',
            action: 'Actions'
        };
        this.clientIdentifications$ = this.store.pipe(select(selectModifiedClientIdentifications));
    }

    loadMore(pageable: PageableParam) {
        this.pageable = pageable;
        this.loadData();
    }

    searchedData(data: string) {
        this.searchedValue = data;
        this.loadData();
    }

    loadData() {
        this.store.dispatch(findInstitutionClientsLocalCodes({
            pageable: this.pageable,
            searchTerm: this.searchedValue,
            institutionUuid: this.institutionUuid ?? this.institution?.uuid
        }));
    }

    addClientIdentifications() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = '70%';
        dialogConfig.data = {otherOptions: {pageable: this.pageable, institutionUuid: this.institutionUuid ?? this.institution?.uuid}};
        this.dialog.open(ClientIdentificationFormComponent, dialogConfig);
    }

    editIdetification(clientIdentification: ClientIdentification) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = '70%';
        dialogConfig.data = {
            otherOptions: {pageable: this.pageable, institutionUuid: this.institutionUuid ?? this.institution?.uuid},
            clientIdentification
        };
        this.dialog.open(ClientIdentificationFormComponent, dialogConfig);
    }

    deleteIdentification(identification: ClientIdentification) {
        const message = 'Are you sure?';
        this.notificationService.confirmation(message, 'Delete').then(
            data => {
                if (data.value) {
                    // this.store.dispatch(removeAdministrativeArea({ id: areaId?.id,uuid:areaId?.uuid }));
                }
            }
        );
    }

    upload() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = '80%';
        dialogConfig.data = this.institution;
        this.dialog.open(UploadingClientsComponent, dialogConfig);
    }

}
