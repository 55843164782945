<div class="content-nav">
    <!-- <a *ngxPermissionsOnly="['ROLE_MANAGE_INCIDENT_SETTINGS','ROLE_INSTITUTION_MANAGEMENT']" class="nav-buttons" routerLink="/settings" routerLinkActive="active-menu">
        <mat-icon>settings</mat-icon>
        <span>Settings</span>
    </a> -->
    <a class="nav-buttons" routerLink="/aptitude-test" routerLinkActive="active-menu" *ngxPermissionsOnly="['ROLE_VIEW_APTITUDE_TEST_DASHBOARD']">
        <mat-icon>assignment</mat-icon>
        <span>Aptitude Test</span>
    </a>
    <a class="nav-buttons" routerLink="/institution" routerLinkActive="active-menu" *ngxPermissionsOnly="['ROLE_INSTITUTION_MANAGEMENT']">
        <mat-icon svgIcon="osat_institution"></mat-icon>
        <span>Institution</span>
    </a>
    <a class="nav-buttons" routerLink="/settings" routerLinkActive="active-menu" *ngxPermissionsOnly="['ROLE_VIEW_SETTINGS_DASHBOARD']">
        <mat-icon>settings</mat-icon>
        <span>Settings</span>
    </a>
    <a class="nav-buttons" routerLink="/reports" routerLinkActive="active-menu" *ngxPermissionsOnly="['ROLE_VIEW_REPORTS_DASHBOARD']">
        <mat-icon svgIcon="osat_chart"></mat-icon>
        <span>Reports</span>
    </a>

    <a class="nav-buttons" routerLink="/knowledge-base" routerLinkActive="active-menu" *ngxPermissionsOnly="['ROLE_VIEW_REPORTS_DASHBOARD']">
        <mat-icon svgIcon="notes"></mat-icon>
        <span>Knowledge Base</span>
    </a>
</div>
<div class="content-holder">
    <div class="content">
        <router-outlet></router-outlet>

        <!-- <ng-container *ngxPermissionsOnly="['ROLE_WORK_ON_OUTGOING_INCIDENTS', 'ROLE_WORK_ON_ALL_INCIDENTS']"> -->
            <!-- <button *ngIf="inIncidentModule" class="icon-btn add-btn report-incident" (click)="openIndentForm(incidentReportingType.external)">
                <div class="add-icon"></div>
                <div class="btn-txt">Report External</div>
            </button> -->

            <!-- <button class="reportIncident btn_animate" (click)="openIndentForm()" aria-label="Report an Issue">
        <span><mat-icon>edit</mat-icon></span> Report Issue
      </button> -->
        <!-- </ng-container> -->

        <div class="version">
            &copy; {{ currentYear }} - OSAT 
        </div>
    </div>
</div>
