import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { RequestCategory } from './request-category.model';
import { PageableParam } from 'src/app/interfaces/global.interface';

export const loadRequestCategorys = createAction(
  '[RequestCategory/API] Load RequestCategorys',
  props<{ requestCategorys: RequestCategory[] }>()
);

export const addRequestCategory = createAction(
  '[RequestCategory/API] Add RequestCategory',
  props<{ requestCategory: RequestCategory }>()
);

export const upsertRequestCategory = createAction(
  '[RequestCategory/API] Upsert RequestCategory',
  props<{ requestCategory: RequestCategory }>()
);

export const addRequestCategorys = createAction(
  '[RequestCategory/API] Add RequestCategorys',
  props<{ requestCategorys: RequestCategory[] }>()
);

export const upsertRequestCategorys = createAction(
  '[RequestCategory/API] Upsert RequestCategorys',
  props<{ requestCategorys: RequestCategory[] }>()
);

export const updateRequestCategory = createAction(
  '[RequestCategory/API] Update RequestCategory',
  props<{ requestCategory: Update<RequestCategory> }>()
);

export const updateRequestCategorys = createAction(
  '[RequestCategory/API] Update RequestCategorys',
  props<{ requestCategorys: Update<RequestCategory>[] }>()
);

export const deleteRequestCategory = createAction(
  '[RequestCategory/API] Delete RequestCategory',
  props<{ id: number }>()
);

export const deleteRequestCategorys = createAction(
  '[RequestCategory/API] Delete RequestCategorys',
  props<{ ids: number[] }>()
);

export const clearRequestCategorys = createAction(
  '[RequestCategory/API] Clear RequestCategorys'
);

// Effect Actions
export const listMyInstitutionAllRequestCategorys = createAction(
  '[RequestCategory/API] List My Institution All RequestCategorys',
  props<{ pageableParam?: PageableParam }>()
);

export const activateRequestCategory = createAction(
  '[RequestCategory/API] Activate RequestCategory',
  props<{ id: string}>()
);

export const modifyRequestCategory = createAction(
  '[RequestCategory/API] Modify RequestCategory Details',
  props<{ requestCategory: RequestCategory }>()
);


export const createRequestCategory = createAction(
  '[RequestCategory/API] Create RequestCategory',
  props<{ requestCategory: RequestCategory }>()
);

export const removeRequestCategory = createAction(
  '[RequestCategory/API] Remove RequestCategory By Id',
  props<{ id: number, uuid:string }>()
);

// export const getDefaultRequestCategory = createAction(
//   '[User/API] Get Default RequestCategory'
// );
