import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { EmailSenderDtoInput, Mail } from './mails.model';

export const loadMails = createAction(
  '[Mail/API] Load Mails', 
  props<{ mails: Mail[] }>()
);

export const addMail = createAction(
  '[Mail/API] Add Mail',
  props<{ mail: Mail }>()
);

export const upsertMail = createAction(
  '[Mail/API] Upsert Mail',
  props<{ mail: Mail }>()
);

export const addMails = createAction(
  '[Mail/API] Add Mails',
  props<{ mails: Mail[] }>()
);

export const upsertMails = createAction(
  '[Mail/API] Upsert Mails',
  props<{ mails: Mail[] }>()
);

export const updateMail = createAction(
  '[Mail/API] Update Mail',
  props<{ mail: Update<Mail> }>()
);

export const updateMails = createAction(
  '[Mail/API] Update Mails',
  props<{ mails: Update<Mail>[] }>()
);

export const deleteMail = createAction(
  '[Mail/API] Delete Mail',
  props<{ id: string }>()
);

export const deleteMails = createAction(
  '[Mail/API] Delete Mails',
  props<{ ids: string[] }>()
);

export const clearMails = createAction(
  '[Mail/API] Clear Mails'
);


// CUSTOM
export const addUpdateMail = createAction(
  '[Mail/API] Add iMails',
  // props<{ mailConfigDto: mailConfigDto }>()
);



export const syncMails = createAction(
  '[Mail/API] Sync Insitution Mails',
  props<{ 
    password: Mail["password"],
    mailFolder: Mail["mailFolder"],
    token: Mail["token"],
    mailUsernameUuid:Mail['mailUsernameUuid']
  }>()

);


export const readMail = createAction(
  '[Mail/API] readMail Insitution Mails',
  props<{ 
    mailFolder: Mail["mailFolder"],
    messageId: Mail["messageId"],
    token: Mail["token"],
    mailUsernameUuid:Mail["mailUsernameUuid"]
  }>()

);

export const sendIncidentMail = createAction(
  '[Mail/API] send Incident Mail',
  props<{ 
    emailParams:any
  }>()

);