import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ResourceCriticality } from './resource-criticality.model';
import * as ResourceCriticalityActions from './resource-criticality.actions';

export const ResourceCriticalitysFeatureKey = 'ResourceCriticalitys';

export interface State extends EntityState<ResourceCriticality> {
  // additional entities state properties
}

export const adapter: EntityAdapter<ResourceCriticality> = createEntityAdapter<ResourceCriticality>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(ResourceCriticalityActions.addResourceCriticality,
    (state, action) => adapter.addOne(action.resourceCriticality, state)
  ),
  on(ResourceCriticalityActions.upsertResourceCriticality,
    (state, action) => adapter.upsertOne(action.resourceCriticality, state)
  ),
  on(ResourceCriticalityActions.addResourceCriticalitys,
    (state, action) => adapter.addMany(action.resourceCriticalitys, state)
  ),
  on(ResourceCriticalityActions.upsertResourceCriticalitys,
    (state, action) => adapter.upsertMany(action.resourceCriticalitys, state)
  ),
  on(ResourceCriticalityActions.updateResourceCriticality,
    (state, action) => adapter.updateOne(action.resourceCriticality, state)
  ),
  on(ResourceCriticalityActions.updateResourceCriticalitys,
    (state, action) => adapter.updateMany(action.resourceCriticalitys, state)
  ),
  on(ResourceCriticalityActions.deleteResourceCriticality,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(ResourceCriticalityActions.deleteResourceCriticalitys,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(ResourceCriticalityActions.loadResourceCriticalitys,
    (state, action) => adapter.setAll(action.resourceCriticalitys, state)
  ),
  on(ResourceCriticalityActions.clearResourceCriticalitys,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
