import {Component, ContentChild, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {SettingsService} from 'src/app/services/settings.service';
import {CLICK_RESET_TIME, SECRECT_CLICK_THRESHOLD} from 'src/app/shared/constants/constants';
import {AllDetailsComponentComponent} from 'src/app/shared/all-details-component/all-details-component.component';
import {PaginationService} from 'src/app/services/pagination.service';
import {initializedPageableParameter, PageableParam, SearchFieldsDtoInput} from 'src/app/interfaces/global.interface';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
    selector: 'app-data-table-hybrid',
    templateUrl: './data-table-hybrid.component.html',
    styleUrls: ['./data-table-hybrid.component.scss']
})
export class DataTableHybridComponent implements OnInit, OnDestroy {
    @Input() tableData: any;
    @Input() isPageable = false;
    @Input() columnHeader;
    @Input() hasActionsColumn = true;
    objectKeys = Object.keys;
    listData: MatTableDataSource<any>;
    searchKey: string;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ContentChild(TemplateRef, {static: true}) actions: TemplateRef<any>;
    subscriptions: Subscription = new Subscription();
    actionColumn: boolean;

    pageSize: number;
    dataLength: number;
    pageSizeOptions: number[];

    @Input() searchFields: SearchFieldsDtoInput[];
    @Input() searchDelayTime = 2000;

    @Output() pageable = new EventEmitter<PageableParam>();
    @Output() searchedData = new EventEmitter<string>();

    currentSort = {sortBy: 'id', sortDirection: 'DESC'};
    currentSearch = null;
    pageParam: any = initializedPageableParameter;
    timerRunning: boolean;
    trigger = 0;
    triggerTimer;
    tableColumns: any[];
    selectedColumn: string[];

    recreatedColumnHeader: any;

    pageIndex = 0;
    @Output() selectionChange = new EventEmitter<any>();
    @Input() preSelectedData: BehaviorSubject<any[]>;
    selection = new SelectionModel<any>(true, []);
    @Input() hasCheckBoxColumn = false;


    constructor(
        private paginationService: PaginationService,
        private settingsService: SettingsService,
    ) {

    }

    ngOnInit() {
        this.actionColumn = this.hasActionsColumn;

        this.subscriptions.add(
            this.tableData.subscribe(data => {
                this.listData = new MatTableDataSource(data);
                this.listData.sort = this.sort;

                if (!this.isPageable) {
                    this.listData.paginator = this.paginator;
                } else {
                    const page = this.paginationService.getPage();

                    if (page) {
                        this.dataLength = page.totalElements;
                        this.pageSize = page.size;
                    }
                }

                this.pageSize = 10;
                this.pageSizeOptions = [10, 50, 100, 250];
            })
        );

        const key = Object.keys(this.columnHeader);
        const value = Object.values(this.columnHeader);
        this.tableColumns = key.map((element, index) => {
            return {
                key: element,
                value: value[index],
                visible: !(element.includes('action') || element.includes('no')),
                isActionColumn: element.includes('action'),
                priority: (index < 6 || (index + 1 === key.length))
            };
        });

        this.selectedColumn = this.tableColumns.map(dt => {
            if (dt.priority) {
                return dt.key;
            }
        });

        this.toggleTableColumnsVisibility(this.selectedColumn);


        this.subscriptions.add(
            this.preSelectedData?.subscribe(selectedDt => {
                if (selectedDt) {
                    selectedDt?.forEach(d => {
                        this.selection.toggle(d);
                    });
                }
            })
        );

        this.subscriptions.add(
            this.selection.changed.subscribe((selectedRow) => {
                if (selectedRow) {
                    const action = selectedRow?.removed.length === 0 ? 'ADD' : 'REMOVE';
                    this.selectionChange.emit({
                        action,
                        selected: action === 'ADD' ? selectedRow.added[0] : selectedRow.removed[0]
                    });
                }
            })
        );
    }

    toggleTableColumnsVisibility(data: any[]) {
        const difference = Object.entries(this.columnHeader).filter(d => data.includes(d[0]));
        this.recreatedColumnHeader = difference.reduce((obj, [key, value]) => {
            obj[key] = value;
            return obj;
        }, {});
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    sortChange(e) {
        if (e.active == 'modifiedStatus') {
            e.active = 'incidentStatus';
        }
        if (e.active == 'incidentCategory') {
            e.active = 'category';
        }
        if (e.active == 'age') {
            e.active = 'dateSubmitted';
        }
        this.currentSort = {sortBy: e.active, sortDirection: e.direction};
        this.handlePage();
    }

    filterTable() {
        this.searchedData.emit(this.searchKey.trim().toLowerCase());
        this.listData.filter = this.searchKey.trim().toLowerCase();
        if (this.searchKey) {
            this.currentSearch = this.searchFields.map(field => {
                return {...field, fieldValue: this.searchKey};
            });
        }
        this.handlePage(null, true);
    }

    delayedFilter() {
        if (this.timerRunning) {
            return;
        }
        this.timerRunning = true;

        const timer = setTimeout(() => {
            this.filterTable();
            this.timerRunning = false;
        }, this.isPageable ? this.searchDelayTime : 0);
    }

    onSearchClear() {
        this.searchKey = '';
        this.filterTable();
    }

    handlePage(e?: PageEvent, fromSearch ?: boolean) {
        if (!this.isPageable) {
            return;
        }
        if (e) {
            this.pageIndex = e?.pageIndex;
            this.pageParam = {
                ...this.pageParam,
                first: e.pageIndex,  // practically this should be named page
                size: e.pageSize,
            };
        }

        if (fromSearch) {
            this.pageParam = {
                ...this.pageParam,
                first: 0,  // practically this should be named page
            };
            this.pageIndex = 0;
        }

        this.pageParam = {...this.pageParam, ...this.currentSort, searchFields: this.currentSearch};
        if (!this.searchKey && this.pageParam.searchFields || !this.currentSearch) {
            delete this.pageParam.searchFields;
        }

        this.pageable.emit(this.pageParam);
    }

    viewDetails(data) {
        if (++this.trigger < SECRECT_CLICK_THRESHOLD) {
            return false;
        }
        if (!this.triggerTimer) {
            this.triggerTimer = setInterval(() => {
                this.trigger = 0;
            }, CLICK_RESET_TIME);
        }

        this.settingsService.openDialogModal({
            component: AllDetailsComponentComponent,
            width: '70%',
            panelClass: ['full-screen-dialog'],
            height: '100%',
            position: {top: '0', right: '0'},
            // width: '70%',
            // height: '90%',
            data,
        });
    }
    
}
