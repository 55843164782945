import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { IncidentAssignment, IncidentAssignmentDtoInput } from './incident-assignment.model';

export const loadIncidentAssignments = createAction(
  '[IncidentAssignment/API] Load IncidentAssignments',
  props<{ incidentAssignments: IncidentAssignment[] }>()
);

export const addIncidentAssignment = createAction(
  '[IncidentAssignment/API] Add IncidentAssignment',
  props<{ incidentAssignment: IncidentAssignment }>()
);

export const upsertIncidentAssignment = createAction(
  '[IncidentAssignment/API] Upsert IncidentAssignment',
  props<{ incidentAssignment: IncidentAssignment }>()
);

export const addIncidentAssignments = createAction(
  '[IncidentAssignment/API] Add IncidentAssignments',
  props<{ incidentAssignments: IncidentAssignment[] }>()
);

export const upsertIncidentAssignments = createAction(
  '[IncidentAssignment/API] Upsert IncidentAssignments',
  props<{ incidentAssignments: IncidentAssignment[] }>()
);

export const updateIncidentAssignment = createAction(
  '[IncidentAssignment/API] Update IncidentAssignment',
  props<{ incidentAssignment: Update<IncidentAssignment> }>()
);

export const updateIncidentAssignments = createAction(
  '[IncidentAssignment/API] Update IncidentAssignments',
  props<{ incidentAssignments: Update<IncidentAssignment>[] }>()
);

export const deleteIncidentAssignment = createAction(
  '[IncidentAssignment/API] Delete IncidentAssignment',
  props<{ id: string }>()
);

export const deleteIncidentAssignments = createAction(
  '[IncidentAssignment/API] Delete IncidentAssignments',
  props<{ ids: string[] }>()
);

export const clearIncidentAssignments = createAction(
  '[IncidentAssignment/API] Clear IncidentAssignments'
);

///////////// API /////////////////

// assignIncident
export const assignIncident = createAction(
  '[IncidentAssignment/API] Assign Incident',
  props<{ IncidentAssignmentDto: IncidentAssignmentDtoInput}>()
);



