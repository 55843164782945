import gql from 'graphql-tag';

export const manufucturesFields = `
active
createdAt
createdBy
deleted
id
institutionId
name
updatedAt
updatedBy
uuid
`;

// resources




export const GET_MY_INSTITUTION_ALL_MANUFUCTURES = gql`
query getAllInstitutionManufactures($pageParam: PageableParamInput){
  getAllInstitutionManufactures(pageParam:$pageParam){
    content{
     ${manufucturesFields}
    }
    first
    hasContent
    hasNext
    hasPrevious
    last
    nextOrLastPageable{
      pageSize
      pageNumber
    }
    nextPageable{
      pageSize
      pageNumber
    }
    number
    numberOfElements
    pageable{
      pageSize
      pageNumber
    }
    previousPageable{
      pageSize
      pageNumber
    }
    previousOrFirstPageable{
      pageSize
      pageNumber
    }
    size
    totalElements
    totalPages
    
  }
}
`;


export const GET_MY_INSTITUTION_ALL_MANUFUCTURES_FOR_LIST = gql`
query getAllInstitutionManufactures($pageParam: PageableParamInput){
  getAllInstitutionManufactures(pageParam:$pageParam){
    content{
      id
      uuid
      name
    }
  }
}
`;



export const ACTIVATE_AND_DEACTIVATE_MANUFUCTURE = gql`
mutation activateDeactivateManufacture($id: Long){
  activateDeactivateManufacture(id:$id){
    code
    data{
      ${manufucturesFields}
    }
    dataList{
      ${manufucturesFields}
    }
    message
    status
  }
}
`;

export const SAVE_UPDATE_MANUFUCTURE = gql`
mutation createUpdateManufacture($manufacture: ManufactureDtoInput){
  createUpdateManufacture(manufacture:$manufacture){
    code
    data{
      ${manufucturesFields}
    }
    dataList{
      ${manufucturesFields}
    }
    message
    status
  }
}
`;


export const DELETE_MANUFUCTURE = gql`
mutation deleteManufacture($id: Long){
  deleteManufacture(id:$id){
    code
    message
    status
  }
}
`;




