
<!-- HEADER START HERE -->
  <mat-toolbar class="toolbar">

    <div>
      <!-- <span class="ega"><img src="/assets/images/ega.png" alt="" style="width: 65px;"></span> -->
      <span *ngIf="token" class="institution-name">{{ institutionName }}</span>
    </div>


    <!-- right-panel -->
    <div class="right-panel">
      <!-- <button *ngIf="token" routerLink="/landing" class="notifications">
        <mat-icon>home</mat-icon>
      </button> -->
      <!-- <ng-container *ngxPermissionsOnly="['ROLE_READ_SEVERITY','ROLE_INCIDENT_TYPE_VIEW','ROLE_HELPDESK_INTERNAL_SERVICE_VIEW']">
          <a *ngIf="token" class="settings" routerLink="/settings">
              <mat-icon>settings</mat-icon> Settings
          </a>
      </ng-container>

      <ng-container>
        <a *ngIf="token" class="settings" routerLink="/settings">
            <mat-icon>settings</mat-icon> Settings
        </a>
      </ng-container> -->
      
      <!-- <ng-container *ngIf="incommingTickets$ | async as notifications">             
        <button *ngIf="token" class="notifications"  mat-icon-button [matMenuTriggerFor]="notification">
          <mat-icon matBadge="{{notifications[0]?.incommingStatuses?.submitted || 0 | number}}">notifications</mat-icon>
        </button>         
      </ng-container>  -->
      
        <div class="user-name" *ngIf="user$ | async as user">{{user.firstName}} {{user.middleName}} {{user.lastName}}</div>
        
        <button *ngIf="token" mat-icon-button [matMenuTriggerFor]="userMenu" class="btn-logout">
          <mat-icon>keyboard_arrow_down</mat-icon>
        </button>
        
    </div>
    <!-- end of right-panel -->
  </mat-toolbar>
<!-- HEADER END HERE -->

<!-- <mat-sidenav-container class="sidenav-container">
    <mat-sidenav-content>


    </mat-sidenav-content>
</mat-sidenav-container> -->

<mat-menu #userMenu="matMenu">
    <!-- <button mat-menu-item routerLink="/self-service">
    <mat-icon>person</mat-icon>
    Profile
  </button> -->
    <mat-divider></mat-divider>
    <button (click)="openChangePasswordDialog()" mat-menu-item>
      <mat-icon svgIcon="osat_password_lock"></mat-icon>
        <span>Change Password</span>
    </button>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="logout()">
    <mat-icon svgIcon="osat_logout">power_settings_new</mat-icon> Log Out
  </button>
</mat-menu>

<!-- <mat-menu #notification="matMenu">
 <app-incident-notification></app-incident-notification>
</mat-menu> -->
