<app-page-title [title]="title" [useBackButton]="!institutionInput"></app-page-title>

<!-- TABLE -->
<div class="row">
    <div class="col-md-12">
        <app-data-table-hybrid (pageable)="loadMore($event)" [columnHeader]="tableColumns" [isPageable]="true"
                               [searchFields]="searchFields" [tableData]="roles$" class="last-col-narrow">
            <ng-template #actions let-data="data">
                <div class="action-icons actions-center">
                    <button *ngxPermissionsOnly="['ROLE_ROLE_COPY_TO_OTHER_INSTITUTION']" [matMenuTriggerFor]="menu"
                            class="bn-color"
                            mat-mini-fab matTooltip="Manage Role"
                            matTooltipPosition="above">
                        <mat-icon>more_vert</mat-icon>
                    </button>

                    <mat-menu #menu="matMenu" class="sub-links">
                        <!-- user can edit or assign perms to roles created by his instituion -->
                        <ng-container *ngIf="data?.institution?.id == institutionId || defaultInstitution">
                            <!--                            <ng-container *ngIf="serviceUserType == 'CLIENT'">-->
                            <button *ngxPermissionsOnly="['ROLE_ROLE_CREATE']" class="icon-holder text-primary"
                                    mat-menu-item
                                    routerLink="/institution/institution_list/{{ encoder(data.id) }}/permissions">
                                <mat-icon>rule</mat-icon>
                                Set Permissions
                            </button>

                            <button (click)="editRole(data)" *ngxPermissionsOnly="['ROLE_ROLE_EDIT']"
                                    class="icon-holder text-info"
                                    mat-menu-item>
                                <mat-icon>edit</mat-icon>
                                Edit
                            </button>
                            <button (click)="deleteRole(data)" *ngxPermissionsOnly="['ROLE_ROLE_DELETE']"
                                    class="icon-holder text-danger"
                                    mat-menu-item>
                                <mat-icon>delete_forever</mat-icon>
                                Delete
                            </button>
                        </ng-container>
                    </mat-menu>
                </div>
            </ng-template>
        </app-data-table-hybrid>
    </div>
</div>
<!-- END OF TABLE -->
