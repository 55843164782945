import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-secondary-button',
  templateUrl: './secondary-button.component.html',
  styleUrls: ['./secondary-button.component.scss']
})
export class SecondaryButtonComponent implements OnInit {
  
  @Input() title: string;
  @Input() icon: string;
  @Output() onClick: EventEmitter<any> = new EventEmitter;
  constructor() { }

  ngOnInit(): void {
  }

  handleClick(): void {
    this.onClick.emit();
  }

}
