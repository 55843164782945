
import gql from 'graphql-tag';

export const serviceTypeFields = `
  active
  createdAt
  createdBy
  deleted
  description
  id
  name
  updatedAt
  updatedBy
  uuid
`;

export const LIST_ALL_SERVICE_TYPES = gql`
query getAllInstitutionServiceTypes{
  getAllInstitutionServiceTypes{
    ${serviceTypeFields}
  }
}
`;

export const LIST_ALL_PAGED_SERVICE_TYPES = gql`
query getAllServiceTypesPageable($pageParam: PageableParamInput){
  getAllServiceTypesPageable(pageParam: $pageParam){
    content{${serviceTypeFields}}
    first
    last
    number
    numberOfElements
    pageable{
      pageNumber
      pageSize
    }
    size
    totalElements
    totalPages
  }
}
`;

export const CREATE_SERVICE_TYPES = gql`
mutation createUpdateServiceType($serviceType: ServiceTypeDtoInput){
  createUpdateServiceType(serviceType: $serviceType){
    code
    data{${serviceTypeFields}}
  }
}
`;

export const DEACTIVATE_SERVICE_TYPES = gql`
  mutation deactivateServiceType($serviceTypeUuid: String){
    deactivateServiceType(serviceTypeUuid: $serviceTypeUuid){
      code
      data{${serviceTypeFields}}
    }
  }
`;





