import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import { AppState } from '../../reducers';
import * as fromActions from './conversation.actions';
import * as fromGraphql from './conversation.graphql';
import { map, switchMap } from 'rxjs/operators';
import {NotificationService} from '../../../services/notification.service';
import {ResponseCodeService} from '../../../services/response-code.service';
import { PaginationService } from 'src/app/services/pagination.service';


@Injectable()
export class ConversationEffects {

  // addUpdateConversation
  addUpdateConversation = createEffect(() => this.actions$.pipe(
    ofType(fromActions.addUpdateConversation),
    switchMap((action) => {
       
      return this.apollo.mutate({
        mutation: fromGraphql.CREATE_CONVERSATION,
        variables: {
          conversation: action.conversation
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while submitting comment'),
        map(({ data }: any) => {
          if (data) {
            if (data.addUpdateConversation.code === 9000) {
                this.notificationService.successMessage('Comment added Successful');
                this.store.dispatch(fromActions.upsertConversation({ conversation: data.addUpdateConversation.data }));
            } else {
              this.notificationService.errorMessage('Operation failed: '+ data.addUpdateConversation.message, data.addUpdateConversation.code);
            }
          }
        })
      );
    })
  ), { dispatch: false });

  // getAllConversations
  getAllConversations$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.getAllConversations),
    switchMap((action) => {
      return this.apollo.query({
        query: fromGraphql.GET_ALL_CONVERSATION,
        fetchPolicy: 'network-only',
        variables: {
          pageparam: action.pageparam
        }
      }).pipe(
        this.notificationService.catchError('Problem while fetching Incidents'),
        map(({ data }: any) => {
          if (data) {
            const result = data?.getAllConversations;
            if (result?.content?.length > 0) {
              this.paginationService.setPage(result);
            }
            this.store.dispatch(fromActions.loadConversations({ conversations: data.getAllConversations.content }));
          }
        })
      );
    })
  ), { dispatch: false });

  // getConversationByUuid
  getConversationByUuid$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.getConversationByUuid),
    switchMap((action) => {
      return this.apollo.query({
        query: fromGraphql.GET_CONVERSATION_BY_UUID,
        fetchPolicy: 'network-only',
        variables: {
          conversationUuid: action.conversationUuid
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while fetching Incidents'),
        map(({ data }: any) => {
          if (data) {
            if (data.getConversationByUuid) {
              this.store.dispatch(fromActions.upsertConversation({ conversation: data.getConversationByUuid.data }));
            } else {
              // const error = this.responseCodesService.getCodeDescription(data.getInternalIncidentsByStage.code);
              this.notificationService.errorMessage('Failed to get records');
            }
          }
        })
      );
    })
  ), { dispatch: false });

  // getAllConversationsByIncident
  getAllConversationsByIncident$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.getAllConversationsByIncident),
    switchMap((action) => {
      return this.apollo.query({
        query: fromGraphql.GET_ALL_CONVERSATIONS_BY_INCIDENT,
        fetchPolicy: 'network-only',
        variables: {
          incidentUuid: action.incidentUuid,
          pageparam: action.pageparam
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while fetching Incidents'),
        map(({ data }: any) => {
          if (data) {
            if (data.getAllConversationsByIncident) {
              const result = data?.getAllConversationsByIncident;
              if (result?.content?.length > 0) {
                this.paginationService.setPage(result);
              }
              this.store.dispatch(fromActions.loadConversations({ conversations: data.getAllConversationsByIncident.content }));
            } else {
              // const error = this.responseCodesService.getCodeDescription(data.getInternalIncidentsByStage.code);
              this.notificationService.errorMessage('Failed to get records');
            }
          }
        })
      );
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private apollo: Apollo,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private responseCodesService: ResponseCodeService,
    private router: Router,
    private paginationService: PaginationService,
  ) {
  }

  // handleResponse(data) {
  //   if (data.code === 9000) {
  //     this.store.dispatch(fromActions.upsertIncident({ incident: data.data }));
  //     return this.notificationService.successMessage('Action completed Successful');
  //   } else {
  //     return this.handleError(data);
  //   }
  // }

  // handleError(data) {
  //   const responseCode = this.responseCodeService.getCodeDescription(data.code);
  //   const message = responseCode[0].code + ' : ' + responseCode[0].description;
  //   return this.notificationService.errorMessage(message);
  // }

}
