import gql from 'graphql-tag';

export const requestSeveritysFields = `
      active
      createdAt
      createdBy
      deleted
      description
      id
      severityCode
      title
      updatedAt
      updatedBy
      uuid
`;

// resources




export const GET_MY_INSTITUTION_ALL_REQUEST_SEVERITYS = gql`
query getAllInstitutionApprovalWorkflows($pageparam: PageableParamInput){
  getAllInstitutionApprovalWorkflows(pageparam:$pageparam){
    content{
        ${requestSeveritysFields} 
    }
    first
    last
    number
    numberOfElements
    pageable{
        pageNumber
        pageSize
    }
    size
    totalElements
    totalPages
  }
}
`;


export const SAVE_UPDATE_REQUEST_SEVERITY = gql`
mutation addNewApprovalWorkflow($changeRequestSeverity: ChangeRequestSeverityDtoInput){
  addNewApprovalWorkflow(changeRequestSeverity:$changeRequestSeverity){
    code
    status
    data{
      ${requestSeveritysFields}
    }
    dataList{
      ${requestSeveritysFields}
    }
    message
  }
}
`;


export const DELETE_REQUEST_SEVERITY = gql`
mutation deleteApprovalWorkflow($changeRequestSeverityUuid: String){
  deleteApprovalWorkflow(changeRequestSeverityUuid:$changeRequestSeverityUuid){
    code
    status
    data{
      ${requestSeveritysFields}
    }
    dataList{
      ${requestSeveritysFields}
    }
    message
  }
}
`;

