<div *ngIf="notifications$ | async as notifications" class="row notification-header">

    <!-- FOR SERVICE PROVIDERS ONLY STARTS -->
    <ng-container *ngxPermissionsOnly="['ROLE_HELPDESK_SERVICE_PROVIDER']">

        <ng-container *ngIf="incommingTickets$ | async as incommingTickets">
            <div *ngxPermissionsOnly="['ROLE_WORK_AS_DEPT_SUPERVISOR','ROLE_WORK_AS_SECOND_LEVEL',
            'ROLE_WORK_AS_FIRST_LEVEL', 'ROLE_WORK_AS_HD_DEPT_SUPERVISOR'
            ]" [ngClass]="viewedFrom == 'dashboard' ? 'new-header' : ''" class="col-sm-6 header">
                <h4> Assigned Tickets</h4>
                <div class="row sub-count-header">
                    <div class="col-sm-12">
                        <div [ngClass]="viewedFrom == 'dashboard' ? 'new-not-box' : ''"
                             class="notication-box"
                             routerLink="/incidents/notifications/assigned-incidents/assignedASSIGNED">
                            <span>Assigned</span>
                            <span class="count">{{incommingTickets[0]?.myAssignedStatuses?.assigned || 0 | number}}</span>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div [ngClass]="viewedFrom == 'dashboard' ? 'new-not-box' : ''"
                             class="notication-box"
                             routerLink="/incidents/notifications/assigned-incidents/assignedSUBMITTED">
                            <span>Open</span>
                            <span class="count">{{incommingTickets[0]?.myAssignedStatuses?.open || 0 | number}}</span>
                        </div>
                    </div>
                    <!-- <div class="col-sm-12">
                  <div routerLink="/incidents/notifications/assigned-incidents/ASSIGNED" class="notication-box" [ngClass]="viewedFrom == 'dashboard' ? 'new-not-box' : ''">
                      <span>Dept Assigned</span>
                      <span class="count">{{incommingTickets[0]?.myAssignedStatuses?.secondLevelSubmitted || 0 | number}}</span>
                  </div>
              </div> -->
                    <div class="col-sm-12">
                        <div [ngClass]="viewedFrom == 'dashboard' ? 'new-not-box' : ''"
                             class="notication-box"
                             routerLink="/incidents/notifications/assigned-incidents/assignedRESOLVED">
                            <span>Resolved</span>
                            <span class="count">{{incommingTickets[0]?.myAssignedStatuses?.resolved || 0 | number}}</span>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div [ngClass]="viewedFrom == 'dashboard' ? 'new-not-box' : ''"
                             class="notication-box"
                             routerLink="/incidents/notifications/assigned-incidents/assignedCLOSED">
                            <span>Closed</span>
                            <span class="count">{{incommingTickets[0]?.myAssignedStatuses?.closed || 0 | number}}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngxPermissionsOnly="['ROLE_WORK_ON_OUTGOING_INCIDENTS', 'ROLE_WORK_ON_ALL_INCIDENTS']"
                 [ngClass]="viewedFrom == 'dashboard' ? 'new-header' : ''"
                 class="col-sm-6 header">
                <h4>Incoming Tickets</h4>
                <div class="row sub-count-header">
                    <div class="col-sm-12">
                        <div [ngClass]="viewedFrom == 'dashboard' ? 'new-not-box' : ''"
                             class="notication-box"
                             routerLink="/incidents/tickets/notifications/incomming/incomingASSIGNED">
                            <span>Assigned</span>
                            <span class="count">{{incommingTickets[0]?.incommingStatuses?.assigned || 0 | number}}</span>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div [ngClass]="viewedFrom == 'dashboard' ? 'new-not-box' : ''"
                             class="notication-box"
                             routerLink="/incidents/tickets/notifications/incomming/incomingSUBMITTED">
                            <span>Open</span>
                            <span class="count">{{incommingTickets[0]?.incommingStatuses?.open || 0 | number}}</span>
                        </div>
                    </div>
                    <!-- <div class="col-sm-12">
                  <div routerLink="/incidents/tickets/notifications/incomming/incomingASSIGNED" class="notication-box" [ngClass]="viewedFrom == 'dashboard' ? 'new-not-box' : ''">
                      <span>Dept Assigned</span>
                      <span class="count">{{incommingTickets[0]?.incommingStatuses?.resolved || 0 | number}}</span>
                  </div>
              </div> -->
                    <div class="col-sm-12">
                        <div [ngClass]="viewedFrom == 'dashboard' ? 'new-not-box' : ''"
                             class="notication-box"
                             routerLink="/incidents/tickets/notifications/incomming/incomingRESOLVED">
                            <span>Resolved</span>
                            <span class="count">{{incommingTickets[0]?.incommingStatuses?.resolved || 0 | number}}</span>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div [ngClass]="viewedFrom == 'dashboard' ? 'new-not-box' : ''"
                             class="notication-box"
                             routerLink="/incidents/tickets/notifications/incomming/incomingCLOSED">
                            <span>Closed</span>
                            <span class="count">{{incommingTickets[0]?.incommingStatuses?.closed || 0 | number}}</span>
                        </div>
                    </div>
                </div>
            </div>


            <div *ngxPermissionsOnly="['ROLE_WORK_ON_OUTGOING_INCIDENTS', 'ROLE_WORK_ON_ALL_INCIDENTS']"
                 [ngClass]="viewedFrom == 'dashboard' ? 'new-header' : ''"
                 class="col-sm-6 header">

                <ng-container *ngIf="outgoingTickets$ | async as outgoingTickets">
                    <h4>Outgoing Tickets</h4>
                    <div class="row sub-count-header">

                        <div class="col-sm-12">
                            <div [ngClass]="viewedFrom == 'dashboard' ? 'new-not-box' : ''" class="notication-box"
                                 routerLink="/incidents/notifications/outgoing-tickets/outDRAFT">
                                <span>Draft</span>
                                <span class="count">{{outgoingTickets[0]?.outgoingStatuses?.draft || 0 | number}}</span>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div [ngClass]="viewedFrom == 'dashboard' ? 'new-not-box' : ''"
                                 class="notication-box"
                                 routerLink="/incidents/notifications/outgoing-tickets/outSUBMITTED">
                                <span>Open</span>
                                <span class="count">{{outgoingTickets[0]?.outgoingStatuses?.open || 0 | number}}</span>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div [ngClass]="viewedFrom == 'dashboard' ? 'new-not-box' : ''"
                                 class="notication-box"
                                 routerLink="/incidents/notifications/outgoing-tickets/outRESOLVED">
                                <span>Resolved</span>
                                <span class="count">{{outgoingTickets[0]?.outgoingStatuses?.resolved || 0 | number}}</span>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div [ngClass]="viewedFrom == 'dashboard' ? 'new-not-box' : ''" class="notication-box"
                                 routerLink="/incidents/notifications/outgoing-tickets/outCLOSED">
                                <span>Closed</span>
                                <span class="count">{{outgoingTickets[0]?.outgoingStatuses?.closed || 0 | number}}</span>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>

        <div *ngxPermissionsOnly="['ROLE_LIST_SECTION_INCIDENT', 'ROLE_WORK_ON_ALL_INCIDENTS']"
             [ngClass]="viewedFrom == 'dashboard' ? 'new-header' : ''"
             class="col-sm-6 header">
            <ng-container *ngIf="departmentTickets$ |async as departmentTickets">
                <h4>Department Tickets</h4>
                <div class="row sub-count-header">

                    <div class="col-sm-12">
                        <div routerLink="/incidents/directorate-tickets/notifications/tickets/deptINCOMMING" class="notication-box" [ngClass]="viewedFrom == 'dashboard' ? 'new-not-box' : ''">
                            <span>Incomming </span>
                            <span class="count">{{departmentTickets[0]?.departmentStatus?.submitted || 0 | number}}</span>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div routerLink="/incidents/directorate-tickets/notifications/tickets/deptSUBMITTED" class="notication-box" [ngClass]="viewedFrom == 'dashboard' ? 'new-not-box' : ''">
                            <span>Open</span>
                            <span class="count">{{departmentTickets[0]?.departmentStatus?.open || 0 | number}}</span>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div routerLink="/incidents/directorate-tickets/notifications/tickets/deptASSIGNED" class="notication-box" [ngClass]="viewedFrom == 'dashboard' ? 'new-not-box' : ''">
                            <span>Assigned</span>
                            <span class="count">{{departmentTickets[0]?.departmentStatus?.assigned || 0 | number}}</span>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div routerLink="/incidents/directorate-tickets/notifications/tickets/deptRESOLVED" class="notication-box" [ngClass]="viewedFrom == 'dashboard' ? 'new-not-box' : ''">
                            <span>Resolved</span>
                            <span class="count">{{departmentTickets[0]?.departmentStatus?.resolved || 0 | number}}</span>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
        <div *ngxPermissionsOnly="['ROLE_WORK_AS_DEPT_SUPERVISOR', 'ROLE_WORK_AS_SECOND_LEVEL', 'ROLE_VIEW_DEPARTMENT_INCIDENT']"
             [ngClass]="viewedFrom == 'dashboard' ? 'new-header' : ''"
             class="col-sm-6 header">
            <ng-container *ngIf="sectionTickets$ |async as sectionTickets">
                <h4>Section Tickets</h4>
                <div class="row sub-count-header">

                    <div class="col-sm-12">
                        <div routerLink="/incidents/section/notifications/tickets/secINCOMMING" class="notication-box" [ngClass]="viewedFrom == 'dashboard' ? 'new-not-box' : ''">
                            <span>Incomming </span>
                            <span class="count">{{sectionTickets[0]?.sectionStatuses?.submitted || 0 | number}}</span>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div routerLink="/incidents/section/notifications/tickets/secSUBMITTED" class="notication-box" [ngClass]="viewedFrom == 'dashboard' ? 'new-not-box' : ''">
                            <span>Open</span>
                            <span class="count">{{sectionTickets[0]?.sectionStatuses?.open || 0 | number}}</span>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div routerLink="/incidents/section/notifications/tickets/secASSIGNED" class="notication-box" [ngClass]="viewedFrom == 'dashboard' ? 'new-not-box' : ''">
                            <span>Assigned</span>
                            <span class="count">{{sectionTickets[0]?.sectionStatuses?.assigned || 0 | number}}</span>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div routerLink="/incidents/section/notifications/tickets/secRESOLVED" class="notication-box" [ngClass]="viewedFrom == 'dashboard' ? 'new-not-box' : ''">
                            <span>Resolved</span>
                            <span class="count">{{sectionTickets[0]?.sectionStatuses?.resolved || 0 | number}}</span>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>

        <div *ngxPermissionsOnly="['ROLE_WORK_ON_INTERNAL_INCIDENTS', 'ROLE_WORK_ON_ALL_INCIDENTS']"
             [ngClass]="viewedFrom == 'dashboard' ? 'new-header' : ''"
             class="col-sm-6 header">
            <ng-container *ngIf="internalTickets$ | async as internalTickets ">
                <h4> Internal Tickets</h4>
                <div class="row sub-count-header">
                    <div class="col-sm-12">
                        <div [ngClass]="viewedFrom == 'dashboard' ? 'new-not-box' : ''" class="notication-box"
                             routerLink="/incidents/notifications/internal/internalASSIGNED">
                            <span>Assigned</span>
                            <span class="count">{{internalTickets[0]?.internalStatuses?.assigned || 0 | number}}</span>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div [ngClass]="viewedFrom == 'dashboard' ? 'new-not-box' : ''" class="notication-box"
                             routerLink="/incidents/notifications/internal/internalSUBMITTED">
                            <span>Open</span>
                            <span class="count">{{internalTickets[0]?.internalStatuses?.open || 0 | number}}</span>
                        </div>
                    </div>
                    <!-- <div class="col-sm-12">
                  <div routerLink="/incidents/notifications/internal/internalASSIGNED" class="notication-box" [ngClass]="viewedFrom == 'dashboard' ? 'new-not-box' : ''">
                      <span>Dept Assigned</span>
                      <span class="count">{{internalTickets[0]?.internalStatuses?.resolved || 0 | number}}</span>
                  </div>
              </div> -->
                    <div class="col-sm-12">
                        <div [ngClass]="viewedFrom == 'dashboard' ? 'new-not-box' : ''" class="notication-box"
                             routerLink="/incidents/notifications/internal/internalRESOLVED">
                            <span>Resolved</span>
                            <span class="count">{{internalTickets[0]?.internalStatuses?.resolved || 0 | number}}</span>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div [ngClass]="viewedFrom == 'dashboard' ? 'new-not-box' : ''" class="notication-box"
                             routerLink="/incidents/notifications/internal/internalCLOSED">
                            <span>Closed</span>
                            <span class="count">{{internalTickets[0]?.internalStatuses?.closed || 0 | number}}</span>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>

        <div [ngClass]="viewedFrom == 'dashboard' ? 'new-header' : ''" class="col-sm-6 header">
            <!-- individual reported tickes -->
            <ng-container *ngIf="myIndividualTickets$ | async as myIndividualTickets">
                <h4>My Reported Tickets</h4>
                <div class="row sub-count-header">
                    <div class="col-sm-12">
                        <div [ngClass]="viewedFrom == 'dashboard' ? 'new-not-box' : ''"
                             class="notication-box"
                             routerLink="/incidents/notifications/myreported-incident/individualDRAFT">
                            <span>Draft</span>
                            <span class="count">{{myIndividualTickets[0]?.myReportedStatuses?.draft || 0 | number}}</span>
                        </div>
                    </div>

                    <div class="col-sm-12">
                        <div [ngClass]="viewedFrom == 'dashboard' ? 'new-not-box' : ''"
                             class="notication-box"
                             routerLink="/incidents/notifications/myreported-incident/individualSUBMITTED">
                            <span>Open</span>
                            <span class="count">{{myIndividualTickets[0]?.myReportedStatuses?.open || 0 | number}}</span>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div [ngClass]="viewedFrom == 'dashboard' ? 'new-not-box' : ''"
                             class="notication-box"
                             routerLink="/incidents/notifications/myreported-incident/individualRESOLVED">
                            <span>Resolved</span>
                            <span class="count">{{myIndividualTickets[0]?.myReportedStatuses?.resolved || 0 | number}}</span>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div [ngClass]="viewedFrom == 'dashboard' ? 'new-not-box' : ''"
                             class="notication-box"
                             routerLink="/incidents/notifications/myreported-incident/individualCLOSED">
                            <span>Closed</span>
                            <span class="count">{{myIndividualTickets[0]?.myReportedStatuses?.closed || 0 | number}}</span>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>

    </ng-container>
    <!-- FOR SERVICE PROVIDERS ONLY STARTS -->


    <!-- FOR CLIENTS ONLY STARTS -->
    <ng-container *ngxPermissionsExcept="['ROLE_HELPDESK_SERVICE_PROVIDER']">
        <ng-container *ngIf="outgoingTickets$ | async as outgoingTickets">
            <div [ngClass]="viewedFrom == 'dashboard' ? 'new-header' : ''" class="col-sm-12 header">
                <h4>Outgoing Tickets</h4>
                <div class="row sub-count-header">
                    <div class="col-sm-12">
                        <div [ngClass]="viewedFrom == 'dashboard' ? 'new-not-box' : ''" class="notication-box"
                             routerLink="/individual/notifications/reported_issues/DRAFT">
                            <span>Draft</span>
                            <span class="count">{{outgoingTickets[0]?.outgoingStatuses?.draft || 0 | number}}</span>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div [ngClass]="viewedFrom == 'dashboard' ? 'new-not-box' : ''" class="notication-box"
                             routerLink="/individual/notifications/reported_issues/SUBMITTED">
                            <span>Open</span>
                            <span class="count">{{outgoingTickets[0]?.outgoingStatuses?.open || 0 | number}}</span>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div [ngClass]="viewedFrom == 'dashboard' ? 'new-not-box' : ''" class="notication-box"
                             routerLink="/individual/notifications/reported_issues/RESOLVED">
                            <span>Resolved</span>
                            <span class="count">{{outgoingTickets[0]?.outgoingStatuses?.resolved || 0 | number}}</span>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div [ngClass]="viewedFrom == 'dashboard' ? 'new-not-box' : ''" class="notication-box"
                             routerLink="/individual/notifications/reported_issues/CLOSED">
                            <span>Closed</span>
                            <span class="count">{{outgoingTickets[0]?.outgoingStatuses?.closed || 0 | number}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
    <!-- FOR CLIENTS ONLY ENDS -->
</div>
