<div class="row">
  <div class="col-sm-12">
    <div class="incident-reporting">
      <div class="reportDiv" [@columSize]="column_size == 'smaller' ? 'zero' : column_size">
        <ng-content select="firstColumn"></ng-content>
      </div>
      <div class="reportDiv" [@columSize]="column_size == 'large' ? 'lastlarge' : column_size=='smaller' ? 'lastlarge': column_size">
        <ng-content select="secondColumn"></ng-content>
      </div>
      <div class="reportDiv" [@columSize]="column_size == 'smaller' ? 'lastlarge' : column_size == 'small' ? column_size  : 'zero'">
        <ng-content select="thirdColumn"></ng-content>
      </div>
      <!-- <div class="reportDiv" [@columSize]="column_size == 'smaller' ? 'last' : 'zero'">
        <ng-content select="fourthColumn"></ng-content>
      </div> -->
    </div>
  </div>
</div>
