<div class="holder" *ngIf="incident && incidentResponses">  
    <fieldset>
      <ul class="{{ incident ? 'comment-with-number': '' }}" *ngIf="incidentResponses?.length > 0; else nothingAdded">
        <li class="clearfix" *ngFor="let appro of (incidentResponses || [])">
          <blockquote
            [ngClass]="'quoted'">
            <strong>{{ appro?.incidentStatus | removeUnderScore }} </strong> 
            <br />
            <small> 
              
            <span *ngIf="appro.incidentStatus">
              <ng-container *ngIf="incident?.ownerInstitution?.id === institutionId"> {{ appro?.createdBy | userId | async }} </ng-container>
              On </span>
              {{ appro.createdAt | date: "fullDate" }}
              {{ appro.createdAt | date: "shortTime" }}
            </small>
   
            <br />
            <p class="title" *ngIf="appro?.incidentStatus == 'RESOLVED' && appro?.title">{{appro?.title | removeUnderScore | titlecase}}</p>
            <p class="read" *ngIf="(appro?.responseType=='FirstLevel' || appro?.responseType=='SecondLevel') 
                && appro?.seen 
                && incident?.ownerInstitution?.id === institutionId">
              (First read by  {{appro?.updatedBy | userId |async}} on {{ appro?.updatedAt | date: "fullDate" }} {{ appro?.updatedAt | date: "shortTime" }})
            </p>
          </blockquote>
          
          <cite [innerHTML]="appro?.description | safeHtml "></cite>
           <br>
        </li>
      </ul>
      
      <ng-template #nothingAdded>
        <div class="no-comments"> 
              <span> NO ADDED RECORDS</span>
        </div>
      </ng-template>
    </fieldset>
  </div>
  