import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { IncidentRootCause } from './incident-root-cause.model';
import { PageableParam } from 'src/app/interfaces/global.interface';

export const loadIncidentRootCauses = createAction(
  '[IncidentRootCause/API] Load IncidentRootCauses', 
  props<{ incidentRootCauses: IncidentRootCause[] }>()
);

export const addIncidentRootCause = createAction(
  '[IncidentRootCause/API] Add IncidentRootCause',
  props<{ incidentRootCause: any }>()
);

export const upsertIncidentRootCause = createAction(
  '[IncidentRootCause/API] Upsert IncidentRootCause',
  props<{ incidentRootCause: IncidentRootCause }>()
);

export const addIncidentRootCauses = createAction(
  '[IncidentRootCause/API] Add IncidentRootCauses',
  props<{ incidentRootCauses: IncidentRootCause[] }>()
);

export const upsertIncidentRootCauses = createAction(
  '[IncidentRootCause/API] Upsert IncidentRootCauses',
  props<{ incidentRootCauses: IncidentRootCause[] }>()
);

export const updateIncidentRootCause = createAction(
  '[IncidentRootCause/API] Update IncidentRootCause',
  props<{ incidentRootCause: Update<IncidentRootCause> }>()
);

export const updateIncidentRootCauses = createAction(
  '[IncidentRootCause/API] Update IncidentRootCauses',
  props<{ incidentRootCauses: Update<IncidentRootCause>[] }>()
);

export const deleteIncidentRootCause = createAction(
  '[IncidentRootCause/API] Delete IncidentRootCause',
  props<{ id: number }>()
);

export const deleteIncidentRootCauses = createAction(
  '[IncidentRootCause/API] Delete IncidentRootCauses',
  props<{ ids: string[] }>()
);

export const clearIncidentRootCauses = createAction(
  '[IncidentRootCause/API] Clear IncidentRootCauses'
);

export const removeIncidentRootCause = createAction(
  '[IncidentRootCause/API] Remove IncidentRootCause',
  props<{ incidentRootCause: IncidentRootCause }>()
);

export const getIncidentRootCauses = createAction(
  '[IncidentRootCause/API] get Incident RootCauses',
  props<{  pageparam: PageableParam }>()
);
