import { initializedPageableParameter, PageableParam } from 'src/app/interfaces/global.interface';
import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Conversation, ConversationDtoInput } from './conversation.model';

export const loadConversations = createAction(
  '[Conversation/API] Load Conversations',
  props<{ conversations: Conversation[] }>()
);

export const addConversation = createAction(
  '[Conversation/API] Add Conversation',
  props<{ conversation: Conversation }>()
);

export const upsertConversation = createAction(
  '[Conversation/API] Upsert Conversation',
  props<{ conversation: Conversation }>()
);

export const addConversations = createAction(
  '[Conversation/API] Add Conversations',
  props<{ conversations: Conversation[] }>()
);

export const upsertConversations = createAction(
  '[Conversation/API] Upsert Conversations',
  props<{ conversations: Conversation[] }>()
);

export const updateConversation = createAction(
  '[Conversation/API] Update Conversation',
  props<{ conversation: Update<Conversation> }>()
);

export const updateConversations = createAction(
  '[Conversation/API] Update Conversations',
  props<{ conversations: Update<Conversation>[] }>()
);

export const deleteConversation = createAction(
  '[Conversation/API] Delete Conversation',
  props<{ id: string }>()
);

export const deleteConversations = createAction(
  '[Conversation/API] Delete Conversations',
  props<{ ids: string[] }>()
);

export const clearConversations = createAction(
  '[Conversation/API] Clear Conversations'
);

///////// A - P - I ///////////

export const addUpdateConversation = createAction(
  '[Conversation/API] addUpdate Incident Response',
  props<{ conversation: ConversationDtoInput }>()
);

export const getAllConversations = createAction(
  '[Conversation/API] get All Incident Responses',
  props<{ pageparam: PageableParam }>()
);

export const getConversationByUserUuid = createAction(
  '[Conversation/API] Get Incident Response By UserUuid',
  props<{ userUuid: string; pageparam: PageableParam }>()
);

export const getConversationByUuid = createAction(
  '[Conversation/API] get Incident Response ByUuid',
  props<{ conversationUuid: string }>()
);

export const getAllConversationsByIncident = createAction(
  '[Conversation/API] get All Incident Responses By Incident',
  props<{ incidentUuid: string; pageparam: PageableParam  }>()
);

