import gql from 'graphql-tag';
import { branchFields } from '../branch/branch.graphql';
import { institutionFields } from '../institution/institution.graphql';
import { userFields } from '../user/user.graphql';

export const departmentFields = `
    id
    active
    deleted
    departmentCode
    departmentName    
    departmentTrackingCode    
    institution{
      id
      uuid
      name
    }
    type
    uuid
    createdAt
    parentDepartment{
      id
      departmentName
      uuid
    }
`;
export const DEPARTMENTS_BY_INSTITUTION_ID = gql`
    query getDepartmentsByInstitution($institutionId: String){
      getDepartmentsByInstitution(institutionId: $institutionId){
        ${departmentFields}
      }
    }
`;

export const GET_ALL_DEPARTMENTS = gql`
    query getAllDepartments{
      getAllDepartments{
        ${departmentFields}
      }
    }
`;
export const GET_ALL_DEPARTMENTS_FOR_OPTION = gql`
    query getAllDepartments{
      getAllDepartments{
          id
          uuid
          departmentName
          departmentCode
      }
    }
`;

export const GET_DEPARTMENT_BY_ID = gql`
    query departmentById($id: Long){
        departmentById(id: $id){
            code
            data{
                ${departmentFields}
            }
        }
    }
`;


export const GET_DEPARTMENT_BY_UUID = gql`
    query getDepartmentByUuid($departmentUid: String){
        getDepartmentByUuid(uuid: $departmentUid){
            code
            data{
              id       
              uuid       
              departmentName       
              subDepartments{
                id
                uuid
                departmentName
              }
            }
        }
    }
`;

export const GET_DEPARTMENT_BY_CODE = gql`
  query getDepartmentByCode($departmentCode: String){
    getDepartmentByCode(departmentCode: $departmentCode){
      code
      data{
        ${departmentFields}
      }
    }
  }
`;

export const GET_DEFAULT_DEPARTMENT = gql`
  query getDefaultDepartment{
    getDefaultDepartment{
      id
      departmentCode
      departmentName
      institution{
        id
        name
      }
    }
  }
`;

export const CREATE_DEPARTMENT = gql`
    mutation addNewDepartment($department: DepartmentDtoInput){
      addNewDepartment(department: $department) {
        code
        message
        data {
          ${departmentFields}
        }
      }
    }
`;

// export const UPDATE_DEPARTMENT = gql`
//     mutation updateDepartment($id:Long,$department: DepartmentDtoInput) {
//         updateDepartment(id:$id,department:$department){
//           code
//           error
//           message
//           data{
//             ${departmentFields}
//           }
//         }
//     }
// `;


export const DELETE_DEPARTMENT = gql`
    mutation deleteDepartment($id: String) {
      deleteDepartment(id: $id){
      code
      message
      status
    }
  }
`;

export const DEPARTMENT_BY_UID_FEW_FIELDS = gql `
query getDepartmentByUuid($departmentUuid: String){
  getDepartmentByUuid(uuid: $departmentUuid){
    code
    data{
      id
      uuid
      departmentName
    }
  }
}
`;

// export const ASSIGN_USER_TO_DEPARTMENT = gql`
//     mutation assignUserToDepartment($userIds: [Long], $departmentId: Long) {
//         assignUserToDepartment(userIds: $userIds, departmentId: $departmentId){
//             code
//             dataList{
//                 id
//                 active
//                 deleted
//                 department{
//                     ${departmentFields}
//                 }
//                 headOfUnit
//                 user{
//                     ${userFields}
//                 }
//             }
//         }
//     }
// `;
