<app-page-title [title]="title" [useBackButton]="!institutionInput"></app-page-title>
<!-- TABLE -->
<div class="row">
    <div class="col-md-12">
        <app-data-table [columnHeader]="tableColumns" [tableData]="services$" class="last-col-narrow">
            <ng-template #actions let-data="data">
                <div class="action-icons actions-center">
                    <button (click)="unsubscribeClientFromService(data)" class="bn-color" mat-mini-fab
                            matTooltip="Unsubscribe Service" matTooltipPosition="above">
                        <mat-icon>block</mat-icon>
                    </button>
                </div>
            </ng-template>
        </app-data-table>
    </div>
</div>
<!-- END OF TABLE -->
