import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { ResourceType } from './resource-type.model';
import { PageableParam } from 'src/app/interfaces/global.interface';

export const loadResourceTypes = createAction(
  '[ResourceType/API] Load ResourceTypes',
  props<{ resourceTypes: ResourceType[] }>()
);

export const addResourceType = createAction(
  '[ResourceType/API] Add ResourceType',
  props<{ resourceType: ResourceType }>()
);

export const upsertResourceType = createAction(
  '[ResourceType/API] Upsert ResourceType',
  props<{ resourceType: ResourceType }>()
);

export const addResourceTypes = createAction(
  '[ResourceType/API] Add ResourceTypes',
  props<{ resourceTypes: ResourceType[] }>()
);

export const upsertResourceTypes = createAction(
  '[ResourceType/API] Upsert ResourceTypes',
  props<{ resourceTypes: ResourceType[] }>()
);

export const updateResourceType = createAction(
  '[ResourceType/API] Update ResourceType',
  props<{ resourceType: Update<ResourceType> }>()
);

export const updateResourceTypes = createAction(
  '[ResourceType/API] Update ResourceTypes',
  props<{ resourceTypes: Update<ResourceType>[] }>()
);

export const deleteResourceType = createAction(
  '[ResourceType/API] Delete ResourceType',
  props<{ id: number }>()
);

export const deleteResourceTypes = createAction(
  '[ResourceType/API] Delete ResourceTypes',
  props<{ ids: number[] }>()
);

export const clearResourceTypes = createAction(
  '[ResourceType/API] Clear ResourceTypes'
);

// Effect Actions
export const listMyInstitutionAllResourceTypes = createAction(
  '[ResourceType/API] List My Institution All ResourceTypes',
  props<{ pageableParam?: PageableParam }>()
);
export const listMyInstitutionAllResourceTypesForOption = createAction(
  '[ResourceType/API] List My Institution All ResourceTypes For Option',
  props<{ pageableParam?: PageableParam }>()
);

export const activateResourceType = createAction(
  '[ResourceType/API] Activate ResourceType',
  props<{ id: string}>()
);

export const modifyResourceType = createAction(
  '[ResourceType/API] Modify ResourceType Details',
  props<{ resourceType: ResourceType }>()
);


export const createResourceType = createAction(
  '[ResourceType/API] Create ResourceType',
  props<{ resourceType: ResourceType }>()
);

export const removeResourceType = createAction(
  '[ResourceType/API] Remove ResourceType By Id',
  props<{ uuid: string,id: number }>()
);

// export const getDefaultResourceType = createAction(
//   '[User/API] Get Default ResourceType'
// );
