
import gql from 'graphql-tag';
import { ApprovalStageFields } from '../approval-stage/approval-stage.graphql';
import { requestSeveritysFields } from '../request-severity/request-severity.graphql';

export const linkageApprovalStageFields = `
active
approvalStage{
  ${ApprovalStageFields}
}
approvalWorkflow{
  ${requestSeveritysFields}
}
createdAt
createdBy
deleted
id
parentApprovalStage{
  ${ApprovalStageFields}
}
updatedAt
updatedBy
uuid
`;

export const LIST_ALL_APPROVAL_STAGEL_INKAGES_BY_APPROVAL_STAGE = gql`
query getAllChangeRequestApprovalLinkageByApprovalStage($pageParam: PageableParamInput,$stageId: Long){
  getAllChangeRequestApprovalLinkageByApprovalStage(stageId: $stageId, pageParam:$pageParam){
      content{
          ${linkageApprovalStageFields}
      }
      first
      last
      number
      numberOfElements
      pageable{
          pageNumber
          pageSize
      }
      size
      totalElements
      totalPages
    }
  }
`;


// query getChangeRequestLinkageListByWorkFlow($uuid: String){
//   getChangeRequestLinkageListByWorkFlow(uuid:$uuid){
//     code
//     data{
//     }
//     dataList{
//     }
//     message
//     status
//   }
// }


export const LIST_ALL_APPROVAL_STAGEL_INKAGES_BY_WORKFLOW_UUID = gql`
query getAllChangeRequestApprovalLinkageBySeverity($severityId: Long,$pageParam: PageableParamInput){
  getAllChangeRequestApprovalLinkageBySeverity(severityId: $severityId, pageParam:$pageParam){
      content{
          ${linkageApprovalStageFields}
      }
      first
      last
      number
      numberOfElements
      pageable{
          pageNumber
          pageSize
      }
      size
      totalElements
      totalPages
    }
  }
`;




export const GET_APPROVAL_STAGEL_INKAGE_BY_UUID = gql`
  query getChangeRequestApprovalLinkageByUuid($uuid: String){
    getChangeRequestApprovalLinkageByUuid (uuid: $uuid){
        code
        data{
         ${linkageApprovalStageFields}
        }
    }
  }
`;



export const CREATE_APPROVAL_STAGEL_INKAGE = gql`
mutation createUpdateLinkage($linkageDto: ChangeRequestLinkageDtoInput){
  createUpdateLinkage( linkageDto: $linkageDto ){
        code
        data{
            ${linkageApprovalStageFields}
        }
    }
}
`;


export const DELETE_APPROVAL_STAGEL_INKAGE = gql`
mutation deleteChangeRequestLinkage($uuid: String){
  deleteChangeRequestLinkage(uuid: $uuid ){
        code
        data {
          ${linkageApprovalStageFields}
        }
    }
}
`;