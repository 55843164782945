import gql from 'graphql-tag';
import { administrativeAreaFields } from '../administrative-area/administrative-area.graphql';
import { departmentFields } from '../department/department.graphql';
import { institutionFields } from '../institution/institution.graphql';
import { userFields } from '../user/user.graphql';

export const branchFields = `
active
administrativeArea{
  ${administrativeAreaFields}
}
branchCode
branchName
id
trackingBranchCode
institution{
  ${institutionFields}
}
location
users{
  ${userFields}
}
uuid
`;





export const GET_MY_INSTITUTION_ALL_BRANCHES = gql`
query getMyInstBranches{
  getMyInstBranches{
    ${branchFields}
  }
}
`;
export const GET_MY_INSTITUTION_ALL_BRANCHES_FOR_LIST = gql`
query getMyInstBranches{
  getMyInstBranches{
    id
    uuid
    branchCode
    branchName
  }
}
`;


export const GET_ALL_BRANCHES = gql`
    query getBranchesByInstitution($institutionId:Long){
      getBranchesByInstitution(institutionId:$institutionId){
          ${branchFields}
      }
    }
`;

export const SAVE_UPDATE_BRANCH = gql`
mutation createUpdateBranch($branch: BranchDtoInput){
  createUpdateBranch(branch:$branch){
    code
    message
    status
    data{
      ${branchFields}
    }
    dataList{
      ${branchFields}
    }
  }
}
`;

// export const UPDATE_BRANCH = gql`
// mutation createUpdateBranch($branch:BranchDtoInput){
//   createUpdateBranch(branch:$branch){
//     code
//     message
//     status
//     dataList{
//       ${branchFields}
//     }
//     data{
//       ${branchFields}
//     }
//   }
// }
// `;

export const DELETE_BRANCH = gql`
mutation deleteBranch($id: Long){
  deleteBranch(id:$id){
    code
    message
    status
    
  }
}
`;


export const FIND_BRANCHES_BY_INSTITUTION_UUID = gql`
    query findBranchesByInstitution($institutionUuid: String){
      findBranchesByInstitution(institutionUuid:$institutionUuid){
          ${branchFields}
      }
    }
`;




