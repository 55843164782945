/*
 * @Author: your name
 * @Date: 2022-04-01 15:13:05
 * @LastEditTime: 2022-04-01 18:15:21
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /research-helpdesk-ui/src/app/store/entities/calltracker/calltracker.effects.ts
 */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import { map, switchMap } from 'rxjs/operators';
import { initializedPageableParameter } from 'src/app/interfaces/global.interface';
import { NotificationService } from 'src/app/services/notification.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { ResponseCodeService } from 'src/app/services/response-code.service';
import { AppState } from '../../reducers';
import * as fromActions from './calltracker.actions';
import * as fromGraphql from './calltracker.graphql';



@Injectable()
export class CalltrackerEffects {

  // saveProblem
  saveProblem$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.saveCallTracker),
    switchMap((action) => {
       
      return this.apollo.mutate({
        mutation: fromGraphql.SAVE_CALL_TRACKER,
        variables: {
          dto: action.dto,
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while saving record'),
        map(({ data }: any) => {
          if (data) {
            let result = data.createUpdateCallRegistry;
            if (result?.code === 9000) {
              this.store.dispatch(fromActions.upsertCalltracker({ calltracker: result?.data }));
              if(action.dto.uuid){
                this.notificationService.successMessage('Edited successfully')
              } else {
                this.notificationService.successMessage('Created Successfully');
              }
            } else {
              const error =  this.responseCodesService?.getCodeDescription(result?.code);
              this.notificationService.errorMessage( result.message ? result?.message :  error[0].description + result?.code);
            }
          }
        })
      );
    })
  ), {dispatch: false});

  // removeCalltracker
  removeCalltracker$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.removeCalltracker),
    switchMap((action) => {
       
      return this.apollo.mutate({
        mutation: fromGraphql.DELETE_CALL_TRACKER,
        variables: {
          registryUuid: action.registryUuid,
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while deleting call tracker'),
        map(({ data }: any) => {
          if (data) {
            if (data.deleteCallRegistry.code === 9000) {
              this.store.dispatch(fromActions.deleteCalltracker({ id: action.id }));
              this.notificationService.successMessage('Deleted Successfully');
            } else {
              this.notificationService.errorMessage(data?.deleteCallRegistry?.message + data.deleteCallRegistry.code);
            }
          }
        })
      );
    })
  ), {dispatch: false});

  getCallTrackerListByInstitutionPageable$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.getCallTrackerListByInstitutionPageable),
    switchMap((action) => {
      return this.apollo.query({
        query: fromGraphql.GET_CALL_TRACKER_LIST_BY_INSTITUTION_PAGEABLE,
        variables: {
          institutionUuid: action?.institutionUuid,
          pageparam: action?.pageable ? action.pageable : initializedPageableParameter,
        },
        fetchPolicy: 'network-only',
      }).pipe(
        this.notificationService.catchError('Problem occurred while listing call track'),
        map(({ data }: any) => {
          if (data) {
            let result = data?.allCallRegistriesByInstitution;
            this.paginationService.setPage(result);
            this.store.dispatch(fromActions.loadCalltrackers({ calltrackers: result?.content || [] }));
          } else {
            this.notificationService.errorMessage('Failed to fetch records');
          }
        })
      );
    })
  ), {dispatch: false});


  getCallTrackerListByDepartmentPageable$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.getCallTrackerListByDepartmentPageable),
    switchMap((action) => {
      return this.apollo.query({
        query: fromGraphql.GET_CALL_TRACKER_LIST_DEPARTMENT_PAGEABLE,
        variables: {
          departmentToUuid: action?.departmentUuid,
          pageparam: action?.pageable ? action.pageable : initializedPageableParameter,
        },
        fetchPolicy: 'network-only',
      }).pipe(
        this.notificationService.catchError('Problem occurred while listing call track'),
        map(({ data }: any) => {
          if (data) {
            let result = data?.allCallRegistriesByDepartmentTo;
            this.paginationService.setPage(result);
            this.store.dispatch(fromActions.loadCalltrackers({ calltrackers: result?.content || [] }));
          } else {
            this.notificationService.errorMessage('Failed to fetch records');
          }
        })
      );
    })
  ), {dispatch: false});

  
  
  
  

  getCallTrackerListByBranchPageable$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.getCallTrackerListByBranchPageable),
    switchMap((action) => {
      return this.apollo.query({
        query: fromGraphql.GET_CALL_TRACKER_LIST_BRANCH_PAGEABLE,
        variables: {
          branchUuid: action?.branchUuid,
          pageparam: action?.pageable ? action.pageable : initializedPageableParameter,
        },
        fetchPolicy: 'network-only',
      }).pipe(
        this.notificationService.catchError('Problem occurred while listing call track'),
        map(({ data }: any) => {
          if (data) {
            let result = data?.allCallRegistriesByBranchTo;
            this.paginationService.setPage(result);
            this.store.dispatch(fromActions.loadCalltrackers({ calltrackers: result?.content || [] }));
          } else {
            this.notificationService.errorMessage('Failed to fetch records');
          }
        })
      );
    })
  ), {dispatch: false});


  constructor(
    private actions$: Actions,
    private router: Router,
    private apollo: Apollo,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private responseCodesService: ResponseCodeService,
    private paginationService: PaginationService
  ) {
  }




  handleResponse(data) {
    if (data.code === 9000) {
      this.store.dispatch(fromActions.upsertCalltracker({ calltracker: data.data }));
      return this.notificationService.successMessage('Action completed Successful');
    } else {
      return this.handleError(data);
    }
  }

  handleError(data) {
    const responseCode = this.responseCodesService.getCodeDescription(data.code);
    const message = responseCode[0].code + ' : ' + responseCode[0].description;
    return this.notificationService.errorMessage(message);
  }
}
