import { NotificationService } from 'src/app/services/notification.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import * as fromActions from './helpdesk-email-notification.actions';
import * as fromGraphql from './helpdesk-email-notification.graphql';
import { map, switchMap } from 'rxjs/operators';
import { AppState } from '../../reducers';
import { ResponseCodeService } from 'src/app/services/response-code.service';


@Injectable()
export class HelpdeskEmailNotificationEffects {

  // listHelpdeskEmailNotifications
  listHelpdeskEmailNotifications$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.listHelpdeskEmailNotifications),
    switchMap((action) => {
      return this.apollo.query({
        query: fromGraphql.LIST_EMAIL_NOTIFICATION,
        fetchPolicy: 'network-only',
      }).pipe(
        this.notificationService.catchError('Problem occurred while fetching email notifications Types'),
        map(({ data }: any) => {
          if (data.listHelpdeskEmailNotifications) {
            this.store.dispatch(fromActions.loadHelpdeskEmailNotifications({ helpdeskEmailNotifications: data.listHelpdeskEmailNotifications }));
          }
        })
      );
    })
  ), {dispatch: false});

  // Create / updateHelpdeskEmailNotification
  updateHelpdeskEmailNotification$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.editHelpdeskEmailNotification),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: fromGraphql.UPDATE_EMAIL_NOTIFICATION,
        variables: {
          id: action.id,
          content: action.content
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while saving email notification'),
        map(({ data }: any) => {
          if (data) {
            if (data.updateHelpdeskEmailNotification.code === 9000) {
              this.store.dispatch(fromActions.upsertHelpdeskEmailNotification({ helpdeskEmailNotification: data.updateHelpdeskEmailNotification.data }));
              if (action.id) {
                this.notificationService.successMessage('Edited Successfully');
              } else {
                this.notificationService.successMessage('Created Successfully');
              }
            } else {
              const error = this.responseCodesService.getCodeDescription(data.updateHelpdeskEmailNotification.code);
              this.notificationService.errorMessage(error[0]?.description);
            }
          }
        })
      );
    })
  ), {dispatch: false});

  // Delete Incident Type
  // removeIncidentType$ = createEffect(() => this.actions$.pipe(
  //   ofType(fromActions.removeIncidentType),
  //   switchMap((action) => {
  //     return this.apollo.mutate({
  //       mutation: fromGraphql.DELETE_INCIDENT_TYPE,
  //       variables: {
  //         incidentTypeId: action.incidentTypeId
  //       }
  //     }).pipe(
  //       this.notificationService.catchError('Problem occurred while deleting incident type'),
  //       map(({ data }: any) => {
  //         if (data) {
  //           if (data.deleteSecurityIncidentType === 'DELETED') {
  //             this.store.dispatch(fromActions.deleteIncidentType({ id: action.incidentTypeId }));
  //             this.notificationService.successMessage('Deleted Successfully');
  //           } else {
  //             this.notificationService.errorMessage(data.deleteRole);
  //           }
  //         }
  //       })
  //     );
  //   })
  // ), {dispatch: false});

  // Get Incident Type By Id
  // getIncidentTypeById$ = createEffect(() => this.actions$.pipe(
  //   ofType(fromActions.getIncidentTypeById),
  //   switchMap((action) => {
  //     return this.apollo.query({
  //       query: fromGraphql.GET_INCIDENT_TYPE_BY_ID,
  //       fetchPolicy: 'network-only',
  //       variables: {
  //         incidentTypeId: action.incidentTypeId
  //       }
  //     }).pipe(
  //       this.notificationService.catchError('Problem occurred while fetching Incident Type'),
  //       map(({ data }: any) => {
  //         if (data) {
  //           if (data.getSecurityIncidentTypeById.code === 9000) {
  //             this.store.dispatch(fromActions.upsertIncidentType({ incidentType: data.getSecurityIncidentTypeById.data }));
  //           } else {
  //             const error = this.responseCodesService.getCodeDescription(data.getSecurityIncidentTypeById.code);
  //             this.notificationService.errorMessage(error[0]?.description);
  //           }
  //         }
  //       })
  //     );
  //   })
  // ), {dispatch: false});

  constructor(
    private actions$: Actions,
    private apollo: Apollo,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private responseCodesService: ResponseCodeService
  ) {
  }

}
