import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { CalltrackerSupport } from './calltracker-support.model';
import * as CalltrackerSupportActions from './calltracker-support.actions';

export const calltrackerSupportsFeatureKey = 'calltrackerSupports';

export interface State extends EntityState<CalltrackerSupport> {
  // additional entities state properties
}

export const adapter: EntityAdapter<CalltrackerSupport> = createEntityAdapter<CalltrackerSupport>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(CalltrackerSupportActions.addCalltrackerSupport,
    (state, action) => adapter.addOne(action.calltrackerSupport, state)
  ),
  on(CalltrackerSupportActions.upsertCalltrackerSupport,
    (state, action) => adapter.upsertOne(action.calltrackerSupport, state)
  ),
  on(CalltrackerSupportActions.addCalltrackerSupports,
    (state, action) => adapter.addMany(action.calltrackerSupports, state)
  ),
  on(CalltrackerSupportActions.upsertCalltrackerSupports,
    (state, action) => adapter.upsertMany(action.calltrackerSupports, state)
  ),
  on(CalltrackerSupportActions.updateCalltrackerSupport,
    (state, action) => adapter.updateOne(action.calltrackerSupport, state)
  ),
  on(CalltrackerSupportActions.updateCalltrackerSupports,
    (state, action) => adapter.updateMany(action.calltrackerSupports, state)
  ),
  on(CalltrackerSupportActions.deleteCalltrackerSupport,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(CalltrackerSupportActions.deleteCalltrackerSupports,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(CalltrackerSupportActions.loadCalltrackerSupports,
    (state, action) => adapter.setAll(action.calltrackerSupports, state)
  ),
  on(CalltrackerSupportActions.clearCalltrackerSupports,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
