import { AfterViewInit, Component, EventEmitter, Inject, OnInit, ViewChild, ElementRef, OnDestroy, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { fromEvent, merge, Observable, of, Subject } from 'rxjs';
import { filter, debounceTime, distinctUntilChanged, switchMap, tap, startWith, mapTo, map, takeUntil, first } from 'rxjs/operators';
import { ApolloQueryResult } from '@apollo/client/core';
import { SettingsService } from 'src/app/services/settings.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { fadeIn } from 'src/app/shared/animations/router-animation';
import { Apollo } from 'apollo-angular';

export interface PaginatedSelectTableCDialogData {
  title?: string;
  query: any;
  optionValue?: string;
  fields?: any[];
  filters?: any[];
  tableList?: any[];
  isPaginated?: boolean;
  multiple?: boolean;
  tableColumns: Array<{ name: string; label: string, type?: string, info?: string, case?: 'titlecase' | 'uppercase' | 'lowercase' }>;
  mapFunction?: (item: any) => {};
  noSearchFields?: string[];
  mustHaveFilters?: any[];
  queryKey?: string;
  customMainFilters?: any;
  initialSelection?: any[];
  useAsForm?: boolean;
  hideExport?: boolean;
  apolloNamespace?: string;
}


@Component({
  selector: 'app-paginated-select-table',
  templateUrl: 'paginated-select-table.component.html',
  styleUrls: ['paginated-select-table.component.scss'],
  animations: [fadeIn]
})
export class PaginatedSelectTableComponent implements OnInit, OnDestroy, AfterViewInit {
  resultLength: number = 0;
  searchQuery = '';
  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource<any>();
  selection: any  = new SelectionModel(this.data.multiple?? true, []);
  private destroy$: Subject<void> = new Subject<void>();
  @ViewChild('input') input?: ElementRef;
  @ViewChild(MatPaginator, { static: true }) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;
  pageSize = 10;
  loading = false;
  queryKey = 'items';
  hideExport = true;
  useAsForm = true;
  doSearch = false;
  tempData: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PaginatedSelectTableCDialogData,
    public dialogRef: MatDialogRef<PaginatedSelectTableComponent>,
    private apollo: Apollo,
    private settings: SettingsService
  ) {
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  async search() {
    this.loading = true;
    const data = await this.getDataFromSource(this.paginator?.pageIndex??0 + 1, this.paginator?.pageSize??0).pipe(first()).toPromise();
    this.prepareDataAndColumns(data);
    this.loading = false;
  }


  _handleKeydown(event: KeyboardEvent) {
    if (this.data.isPaginated) {
      // Prevent propagation for all alphanumeric characters in order to avoid selection issues
      if ((event.key !== undefined) && ((event.code === 'Space') || ((event.code === 'Home' || event.code === 'end')))) {
        event.stopPropagation();
      }

      if (event.key && event.code === 'Enter') {
        this.doSearch = true;
        this.search();

      }

      if (event.code === 'Escape' && this.searchQuery) {
        this.searchQuery = '';
        event.stopPropagation();
      }
    }
  }

  _handleKeyup(event: KeyboardEvent) {
    if (this.data.isPaginated) {
      if (event.code === 'Backspace' && this.searchQuery.length === 0) {
        if (this.tempData?.rows?.length > 0) {
          this.prepareDataAndColumns(this.tempData);
        }
        else {
          this.search();
        }
      }
    }
  }

  ngOnInit(): void {
    this.displayedColumns = this.data.tableColumns?.map(col => col.name);

    this.useAsForm = this.settings.isNullOrUndefined(this.data.useAsForm) ? true : this.data.useAsForm as boolean;
    this.hideExport = this.settings.isNullOrUndefined(this.data.hideExport) ? true : this.data.hideExport as boolean;
    if (this.data.multiple) {
      this.displayedColumns.push('select');
    }
    if (!this.data.isPaginated) {
      const mappedValues = this.data.mapFunction ? this.data.tableList?.map(i => (this.data.mapFunction as ((item: any) => {}))(i)) : this.data.tableList;
      this.dataSource = new MatTableDataSource(mappedValues);
    }
  }

  ngAfterViewInit() {
    if (!this.data.isPaginated) {
      fromEvent(this.input?.nativeElement, 'keyup')
        .pipe(
          filter(Boolean),
          debounceTime(300),
          distinctUntilChanged(),
          tap((text) => {
            if (this.searchQuery.length === 0) {
              this.loading = true;
            }
          }),
          switchMap(() => {
            if (this.data.isPaginated && this.searchQuery.length === 0) {
              return this.getDataFromSource(this.paginator?.pageIndex??0 + 1, this.paginator?.pageSize??0);
            } else {
              this.applyFilter();
              this.loading = false;
              return of(null);
            }
          }),
          takeUntil(this.destroy$)
        ).subscribe((i: any) => {
          if (this.searchQuery.length === 0) {
            this.prepareDataAndColumns(i);
          }
        });
    }


    setTimeout(() => {
      merge(this.sort ? this.sort.sortChange : 0, this.paginator?.page ?? 0)
        .pipe(
          startWith({}),
          tap(i => this.loading = true),
          switchMap(() => {
            if (this.data.isPaginated) {
              return this.getDataFromSource(this.paginator?.pageIndex??0 + 1, this.paginator?.pageSize??0);
            } else {
              this.loading = false;
              return of({});
            }
          }),
          takeUntil(this.destroy$)
        ).subscribe(
          (i: any) => { if (this.data.isPaginated) { this.prepareDataAndColumns(i); } }
        );
    }, 0);

  }

  applyFilter() {
    const filterValue = this.input?.nativeElement.value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  querySearchFields(): any[] {
    const fields: any[] = [];
    this.data.tableColumns.forEach(column => {
      const value: any = {
        fieldName: column.name,
        isSearchable: true,
        operation: 'LK',
        searchValue: this.input?.nativeElement.value
      };
      if (this.data.noSearchFields?.indexOf(value.fieldName) === -1) {
        fields.push(value);
      }
    });
    return fields;
  }

  prepareFields() {
    const fields = this.data.fields ? this.data.fields : this.querySearchFields();
    return fields?.map(field => {
      field.searchValue = this.input?.nativeElement.value;
      if (this.sort?.active && this.sort.direction) {
        field.isSortable = true;
        field.orderDirection = this.sort.direction.toUpperCase();
      }
      return field;
    }) || [];

  }

  getDataFromSource(page: number, size: number): Observable<any> {
    try {
      this.queryKey = this.data.queryKey ? this.data.queryKey : this.queryKey;
      const input = {
        page,
        size,
        fields: this.prepareFields(),
        // mustHaveFilters: this.data.mustHaveFilters
      };
      if (this.data.customMainFilters) {
        const newParam = this.data.customMainFilters;
        const data = this.apollo.query(
          {
            query: this.data?.query,
            variables: { input, ...newParam },
          }
          )
          .pipe(map((response: ApolloQueryResult<any>) => {
            return response.data[this.queryKey];
          }));
        return data;
      } else {
        const data = this.apollo.query(
          {
            query: this.data.query,
            variables: { input },
          })
          .pipe(map((response: ApolloQueryResult<any>) => response.data[this.queryKey]));
        return data;
      }
    } catch (error) {
      console.error(error);
      this.loading = false;
      return of();
    }
  }

  prepareDataAndColumns(response: any) {
    if (!this.doSearch) {
      this.tempData = response;
    } else {
      this.doSearch = false;
    }
    try {
      const dataValues: any[] = response && response.rows ? response.rows : [];
      const mappedValues = this.data.mapFunction ? dataValues.map(i => (this.data.mapFunction as ((item: any) => {}))(i)) : dataValues;
      this.dataSource = new MatTableDataSource(mappedValues);
      this.resultLength = this.searchQuery ? response.recordsFilteredCount || response?.totalRecords : response?.totalRecords;
      this.loading = false;
      if (this.selection.hasValue()) {
        const tableSelection = this.selection.selected.map((item: any) => item[this.data.optionValue || '']);
        this.data.initialSelection = (this.data.initialSelection || []).concat(tableSelection.filter((item: any) => (this.data.initialSelection || []).indexOf(item[this.data.optionValue || '']) === -1));
      }
      this.dataSource.data.forEach(row => {
        if (this.data.initialSelection?.some(item => item + '' === row[this.data.optionValue || ''] + '')) {
          this.selection.select(row);
        }
      });
    } catch (e) {
      console.error(e);
    }
  }

  selectRow(row: any) {
    if (!this.data.multiple && this.useAsForm) {
      this.dialogRef.close(row);
    }
  }

  downloadToCsv() {
    // this.settings.downloadToCsv({ tableList: this.dataSource.data, tableColumns: this.data.tableColumns, title: this.data.title });
  }

  close(row = null) {
    const data = row || this.selection.selected;
    this.dialogRef.close(data);
  }

}
