<!-- If we're loading -->
<mat-form-field *ngIf="field?.optionData?.loading" @fadeIn appearance="outline">
    <mat-label>Loading {{field?.label}} ...</mat-label>
    <input matInput disabled>
    <mat-spinner matSuffix [diameter]="18" style="float: right; margin-left: 8px"></mat-spinner>
</mat-form-field>

<mat-form-field *ngIf="!field?.optionData?.loading" @fadeIn class="demo-full-width margin-top"
    [hideRequiredMarker]="field?.hideRequiredMarker" [formGroup]="group" appearance="outline">
    <mat-label>{{field?.label}}</mat-label>
    <mat-select #infiniteScrollSelect gInfiniteScroll (infiniteScroll)="getNextBatch()"
        [required]="field?.key && group?.get(field?.key!)?.errors !== null && group?.get(field?.key!)?.errors?.['required']"
        [placeholder]="field?.placeholder || field?.label || ''" [formControlName]="field?.key?? null"
        (selectionChange)="fieldChange($event)" [multiple]="field?.multiple">
        <mat-option>
            <ng-template [ngIf]="!field?.multiple">
                <!--disableScrollToActiveOnOptionsChanged should be set to true-->
                <app-select-search [formControl]="searchCtrl" [disableScrollToActiveOnOptionsChanged]="true"
                    [noEntriesFoundLabel]="noEntriesFoundLabel">
                </app-select-search>
            </ng-template>
            <ng-template [ngIf]="field?.multiple">
                <app-select-search [formControl]="searchCtrl" [noEntriesFoundLabel]="noEntriesFoundLabel"
                    [showToggleAllCheckbox]="true"
                    [toggleAllCheckboxIndeterminate]="field?.key!=null && group.controls[field?.key!]?.value?.length > 0 && group.controls[field?.key!]?.value?.length < field?.optionData?.value?.length"
                    [toggleAllCheckboxChecked]="group.controls[field?.key!]?.value?.length === field?.optionData?.value?.length"
                    (toggleAll)="toggleSelectAll($event)">
                </app-select-search>
            </ng-template>
        </mat-option>

        <span *ngIf="!field?.removeUnderscore; else removeUnderScore">
            <mat-optgroup *ngFor="let group of (filteredBatchedData$ | async) || []">
                <mat-option *ngFor="let item of group.groupItems | search: field?.filterKey || '': filterVal" [value]="item.value"
                    [disabled]="item.disabled" [ngClass]="{'danger': item.danger}">{{item.name}}
                </mat-option>
            </mat-optgroup>
        </span>
        <ng-template #removeUnderScore>
            <mat-optgroup *ngFor="let group of (filteredBatchedData$ | async) || []">
                <mat-option *ngFor="let item of group.groupItems  || [] | search: field?.filterKey ||'': filterVal"
                    [value]="item.value" [disabled]="item.disabled" [ngClass]="{'danger': item.danger}">{{ item.name |
                    replace: '_' : ' '}}
                </mat-option>
            </mat-optgroup>
        </ng-template>
    </mat-select>

    <mat-hint><strong>{{field?.hint}}</strong> </mat-hint>
    <ng-container *ngFor="let validation of field?.validations;" ngProjectAs="mat-error">
        <mat-error *ngIf="field?.key && group.controls[field?.key!]?.hasError(validation.name)">{{validation.message}}</mat-error>
    </ng-container>
</mat-form-field>
