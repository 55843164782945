import {Apollo} from 'apollo-angular';import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';import * as fromAttachmentActions from './attachment.actions';
import * as fromAttachmentGraphql from './attachment.graphql';
import { Attachment } from './attachment.model';
import { NotificationService } from 'src/app/services/notification.service';
import { ResponseCodeService } from 'src/app/services/response-code.service';


@Injectable()
export class AttachmentEffects {
  
  listAllAttachmentByIncidentId = createEffect(() => this.actions$.pipe(
    ofType(fromAttachmentActions.listAllAttachmentByIncidentId),
    switchMap((action) => {

      return this.apollo.query({
        query: fromAttachmentGraphql.LIST_ATTACHMENTS_BY_UUID,
        fetchPolicy: 'network-only',
        variables: { incidentUid: action.incidentUId }
      }).pipe(
        this.notificationService.catchError('Problem occurred while getting attachments'),
        map(({ data }) => {
          if (data) {            
            const attachments = data.attachmentsByIncidentUuid as Attachment[];
            return this.store.dispatch(fromAttachmentActions.loadAttachments({ attachments }));
          } else {
            return this.store.dispatch(fromAttachmentActions.loadAttachments({ attachments: [] }))
          }
        })
      );
    })
  ), { dispatch: false });
  
  attachmentsByConversationUuid = createEffect(() => this.actions$.pipe(
    ofType(fromAttachmentActions.attachmentsByConversationUuid),
    switchMap((action) => {

      return this.apollo.query({
        query: fromAttachmentGraphql.LIST_ATTACHMENTS_BY_CONVERSATION_UUID,
        fetchPolicy: 'network-only',
        variables: { conversationUuid: action.conversationUuid }
      }).pipe(
        this.notificationService.catchError('Problem occurred while getting attachments'),
        map(({ data }) => {
          if (data) {            
            const attachments = data.attachmentsByConversationUuid as Attachment[];
            return this.store.dispatch(fromAttachmentActions.loadAttachments({ attachments }));
          } else {
            return this.store.dispatch(fromAttachmentActions.loadAttachments({ attachments: [] }))
          }
        })
      );
    })
  ), { dispatch: false });


  
  uploadAttachment = createEffect(() => this.actions$.pipe(
    ofType(fromAttachmentActions.uploadAttachment),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: fromAttachmentGraphql.UPLOAD_ATTACHMENT,
        variables: {
          attachment: action.attachment
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while uploading attachment'),
        map(({ data }: any) => {
          if (data) {
            if (data.addIncidentAttachment.code === 9000) {
              this.store.dispatch(fromAttachmentActions.upsertAttachment({ attachment: data.addIncidentAttachment.data }));
              this.notificationService.successMessage('Uploaded Successful');
            } else {
              return this.handleResponse(data?.addIncidentAttachment);}
          }
        })
      );
    })
  ), { dispatch: false });
  
  
  deleteAttachment = createEffect(() => this.actions$.pipe(
    ofType(fromAttachmentActions.removeAttachment),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: fromAttachmentGraphql.DELETE_ATTACHMENT,
        variables: {
          attachmentUuid: action.attachmentUid
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while deleting attachment'),
        map(({ data }: any) => {
          if (data) {
             
            
            if (data?.deleteAttachment?.code === 9000) {
              this.store.dispatch(fromAttachmentActions.deleteAttachment({ id: action.attachmentId }));
              return this.notificationService.successMessage('Attachment deleted successfully');
            } else {
              return this.notificationService.errorMessage('Attachment not deleted');
            }
          }
        })
      );
    })
  ), { dispatch: false });


  uploadInstitutionLogo = createEffect(() => this.actions$.pipe(
    ofType(fromAttachmentActions.uploadInstitutionLogo),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: fromAttachmentGraphql.CREATE_UPDATE_INSTITUTION_LOGO,
        variables: {
          institutionLogo: action.logoFile
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while uploading Logo'),
        map(({ data }: any) => {
          if (data) {
            if (data.updateInstitutionLogo.code === 9000) {
              this.store.dispatch(fromAttachmentActions.upsertAttachment({ attachment: data.updateInstitutionLogo.data }));
              this.notificationService.successMessage('Uploaded Successful');
            } else {
              return this.handleResponse(data?.updateInstitutionLogo);}
          }
        })
      );
    })
  ), { dispatch: false });
  

  constructor(
    private actions$: Actions,
    private apollo: Apollo,
    private store: Store<AppState>,
    private responseCodeService: ResponseCodeService,
    private notificationService: NotificationService
  ) {
  }


  handleResponse(data) {
    if (data.code === 9000) {
      this.store.dispatch(fromAttachmentActions.upsertAttachment({ attachment: data.data }));
      return this.notificationService.successMessage('Attachment saved successful');
    } else {
      return this.handleError(data);
    }
  }

  handleError(data) {
    const responseCode = this.responseCodeService.getCodeDescription(data.code);
    const message = responseCode[0].code + ' : ' + responseCode[0].description;
    return this.notificationService.errorMessage(message);
  }
}
