import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import {Roster, RosterChange } from './roster.model';
import { User } from '../settings/user/user.model';
import { PageableParam } from 'src/app/interfaces/global.interface';

export const loadRosters = createAction(
  '[Roster/API] Load Rosters',
  props<{ rosters: Roster[] }>()
);

export const addRoster = createAction(
  '[Roster/API] Add Roster',
  props<{ roster: Roster }>()
);

export const upsertRoster = createAction(
  '[Roster/API] Upsert Roster',
  props<{ roster: Roster }>()
);

export const addRosters = createAction(
  '[Roster/API] Add Rosters',
  props<{ rosters: Roster[] }>()
);

export const upsertRosters = createAction(
  '[Roster/API] Upsert Rosters',
  props<{ rosters: Roster[] }>()
);

export const updateRoster = createAction(
  '[Roster/API] Update Roster',
  props<{ roster: Update<Roster> }>()
);

export const updateRosters = createAction(
  '[Roster/API] Update Rosters',
  props<{ rosters: Update<Roster>[] }>()
);

export const deleteRoster = createAction(
  '[Roster/API] Delete Roster',
  props<{ id: number }>()
);


export const removeRoster = createAction(
    '[Roster/API] Remove Roster',
    props<{ id: number }>()
);



export const deleteRosters = createAction(
  '[Roster/API] Delete Rosters',
  props<{ ids: string[] }>()
);

export const clearRosters = createAction(
  '[Roster/API] Clear Rosters'
);


// Effect Actions
export const listDepartmentRosters = createAction(
  '[Roster/API] List Department Rosters',
  props<{ pageaParam?: PageableParam }>()
);


export const addManyRosterMembers =  createAction(
  '[Roster/API] Add Many Roster Members',
  props<{ rosterChanges: RosterChange[] }>()
);

export const loadUsers = createAction(
  '[User/API] Load Users',
  props<{ users: User[] }>()
);


export const getMyRosterList = createAction(
  '[User/API] get my roster',
  props<{ pageaParam?: PageableParam, active: boolean }>()
);


