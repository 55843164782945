import { ResponseCodeService } from './../../../../services/response-code.service';
import { AppState } from './../../../reducers/index';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import { NotificationService } from 'src/app/services/notification.service';
import * as fromActions from './department.actions';
import * as fromGraphql from './department.graphql';
import { map, switchMap } from 'rxjs/operators';
import {listAllDepartmentsForOptions} from './department.actions';
import {GET_ALL_DEPARTMENTS_FOR_OPTION} from './department.graphql';


@Injectable()
export class DepartmentEffects {

  // listAllDepartmentsByInstitution
  listAllDepartmentsByInstitution$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.listAllDepartmentsByInstitution),
    switchMap((action) => {
      return this.apollo.query({
        query: fromGraphql.DEPARTMENTS_BY_INSTITUTION_ID,
        variables: {
          institutionId:  action.institution.id
        },
        fetchPolicy: 'network-only',
      }).pipe(
        this.notificationService.catchError('Problem occurred while fetching Departments'),
        map(({ data }: any) => {
          if (data) {
            this.store.dispatch(fromActions.loadDepartments({ departments: data.getDepartmentsByInstitution }));
          }
        })
      );
    })
  ), {dispatch: false});

  listAllDepartments$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.listAllDepartments),
    switchMap((action) => {
      return this.apollo.query({
        query: fromGraphql.GET_ALL_DEPARTMENTS,
        fetchPolicy: 'network-only',
      }).pipe(
        this.notificationService.catchError('Problem occurred while fetching Departments'),
        map(({ data }: any) => {
          if (data) {
            this.store.dispatch(fromActions.loadDepartments({ departments: data.getAllDepartments }));
          }
        })
      );
    })
  ), {dispatch: false});

    listAllDepartmentsForOptions$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.listAllDepartmentsForOptions),
    switchMap((action) => {
      return this.apollo.query({
        query: fromGraphql.GET_ALL_DEPARTMENTS_FOR_OPTION,
        fetchPolicy: 'network-only',
      }).pipe(
        this.notificationService.catchError('Problem occurred while fetching Departments'),
        map(({ data }: any) => {
          if (data) {
            this.store.dispatch(fromActions.loadDepartments({ departments: data.getAllDepartments }));
          }
        })
      );
    })
  ), {dispatch: false});

  // Get Department By Id 
  getDepartmentById$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.getDepartmentById),
    switchMap((action) => {
      return this.apollo.query({
        query: fromGraphql.GET_DEPARTMENT_BY_ID,
        fetchPolicy: 'network-only',
        variables: {
          id: action.id
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while fetching Department'),
        map(({ data }: any) => {
          if (data) {
            if (data.departmentById.code === 9000) {
              this.store.dispatch(fromActions.upsertDepartment({ department: data.departmentById.data }));
            } else {
              const error = this.responseCodesService.getCodeDescription(data.departmentById.code);
              this.notificationService.errorMessage(error[0]?.description);
            }
          }
        })
      );
    })
  ), {dispatch: false});

  // Get Department By Code
  getDepartmentByCode$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.getDepartmentByCode),
    switchMap((action) => {
      return this.apollo.query({
        query: fromGraphql.GET_DEPARTMENT_BY_CODE,
        fetchPolicy: 'network-only',
        variables: {
          departmentCode: action.departmentCode,
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while fetching Department'),
        map(({ data }: any) => {
          if (data) {
            if (data.getDepartmentByCode.code === 9000) {
              this.store.dispatch(fromActions.upsertDepartment({ department: data.getDepartmentByCode.data }));
            } else {
              const error = this.responseCodesService.getCodeDescription(data.getDepartmentByCode.code);
              this.notificationService.errorMessage(error[0]?.description);
            }
          }
        })
      );
    })
  ), {dispatch: false});

  // Get Department default
  getDefaultDepartment$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.getDefaultDepartment),
    switchMap((action) => {
      return this.apollo.query({
        query: fromGraphql.GET_DEFAULT_DEPARTMENT,
        fetchPolicy: 'network-only',
      }).pipe(
        this.notificationService.catchError('Problem occurred while fetching Department'),
        map(({ data }: any) => {
          if (data) {
            if (data.getDefaultDepartment) {
              this.store.dispatch(fromActions.loadDepartments({ departments: [data.getDefaultDepartment] }));
            }
            // else {
            //   this.notificationService.errorMessage('error[0].description');
            // }
          }
        })
      );
    })
  ), {dispatch: false});

  // Create/Save Department
  createDepartment$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.createDepartment),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: fromGraphql.CREATE_DEPARTMENT,
        variables: {
          department: action.department
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while saving department'),
        map(({ data }: any) => {
          if (data) {
            if (data.addNewDepartment.code === 9000) {
              this.store.dispatch(fromActions.upsertDepartment({ department: data.addNewDepartment.data }));
              if (action.department?.uuid) {
                this.notificationService.successMessage('Edited Successfully');
              } else {
                this.notificationService.successMessage('Created Successfully');
              }
            } else {
              const error = this.responseCodesService.getCodeDescription(data.addNewDepartment.code);
              this.notificationService.errorMessage(error[0]?.description);
            }
          }
        })
      );
    })
  ), {dispatch: false});

  
  // Detele Department
  removeDepartment$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.removeDepartment),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: fromGraphql.DELETE_DEPARTMENT,
        variables: {
          id: action.id
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while deleting department'),
        map(({ data }: any) => {
          if (data) {
            if (data.deleteDepartment.code === 9000) {
              this.store.dispatch(fromActions.deleteDepartment({ id: action._id }));
              this.notificationService.successMessage('Deleted Successfully');
            } else {
              this.notificationService.errorMessage(data.deleteRole);
            }
          }
        })
      );
    })
  ), {dispatch: false});

  // Get Department By Id 
  getDepartmentByUuid$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.getDepartmentByUuid),
    switchMap((action) => {
      return this.apollo.query({
        query: fromGraphql.GET_DEPARTMENT_BY_UUID,
        fetchPolicy: 'cache-first',
        variables: {
          departmentUid: action.departmentUid
        }
      }).pipe(
        this.notificationService.catchError('Problem occurred while getting Department'),
        map(({ data }: any) => {
          if (data) {
            if (data.getDepartmentByUuid?.code === 9000) {
              this.store.dispatch(fromActions.upsertDepartment({ department: data.getDepartmentByUuid.data }));
            } else {
              const error = this.responseCodesService.getCodeDescription(data.getDepartmentByUuid?.code);
              this.notificationService.errorMessage(error[0]?.description);
            }
          }
        })
      );
    })
  ), {dispatch: false});

  constructor(
    private actions$: Actions,
    private apollo: Apollo,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private responseCodesService: ResponseCodeService
  ) {
  }

}
