import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AnimationService {
  constructor(private router: Router) {
  }

  modalInAddPanel() {
    return ['animated__modal', 'animate__animated', 'animate__zoomIn'];
  }

  modalSlideInPanel() {
    return ['animated__modal', 'animate__animated', 'animate__slideInUp'];
  }

  modalInEditPanel() {
    return ['animated__modal', 'animate__animated', 'animate__fadeInUp', 'animate__faster'];
  }

  modalOutPanel() {
    document
      .getElementsByClassName('animated__modal')[0]
      .classList.remove('animate__zoomIn');
    document
      .getElementsByClassName('animated__modal')[0]
      .classList.remove('animate__fadeInUp');
    document
      .getElementsByClassName('animated__modal')[0]
      .classList.remove('animate__faster');


    document
      .getElementsByClassName('animated__modal')[0]
      //.classList.add('animate__fadeOutBottomLeft');
      .classList.add('animate__zoomOut');

    return true;
  }
}

