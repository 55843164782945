import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import {map, switchMap} from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { SettingsService } from 'src/app/services/settings.service';
import { BaseResponse } from 'src/app/store/base.entity';
import { AppState } from 'src/app/store/reducers';
import * as fromActions from './test.actions';
import * as fromGraphql from './test.graphql';



@Injectable()
export class TestEffects {

  getMyQuestionAnswersByAptitudeTest = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.getMyQuestionAnswersByAptitudeTest2),
        switchMap((action) => {
          return this.apollo
            .query({
              query: fromGraphql.GET_MY_QUESTION_ANSWERS_BY_APTITUDE_TEST,
              fetchPolicy: "network-only",
              variables: {
                aptitudeTestUid: action.aptitudeTestUid
              }
            })
            .pipe(
              map(({data}: any) => {
                let result: BaseResponse = Object.values(data)[0];
                if(result?.code === 9000){
                  this.store.dispatch(
                    fromActions.loadTests({
                      tests: result?.dataList,
                    })
                  );
                  
                }
              })
            );
        }),
        this.notificationService.catchError(
          'Problem occurred getting Aptitude Test'
        )
      ),
    {dispatch: false}
  );


  constructor(private actions$: Actions,
    private apollo: Apollo,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private paginationService: PaginationService,
    private settingsService: SettingsService,
    private router: Router
  ) {}
}
