import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Severity, SeverityDtoInput } from './severity.model';
import { PageableParam } from 'src/app/interfaces/global.interface';

export const loadSeveritys = createAction(
  '[Severity/API] Load Severitys',
  props<{ severitys: Severity[] }>()
);

export const addSeverity = createAction(
  '[Severity/API] Add Severity',
  props<{ severity: Severity }>()
);

export const upsertSeverity = createAction(
  '[Severity/API] Upsert Severity',
  props<{ severity: Severity }>()
);

export const addSeveritys = createAction(
  '[Severity/API] Add Severitys',
  props<{ severitys: Severity[] }>()
);

export const upsertSeveritys = createAction(
  '[Severity/API] Upsert Severitys',
  props<{ severitys: Severity[] }>()
);

export const updateSeverity = createAction(
  '[Severity/API] Update Severity',
  props<{ severity: Update<Severity> }>()
);

export const updateSeveritys = createAction(
  '[Severity/API] Update Severitys',
  props<{ severitys: Update<Severity>[] }>()
);

export const deleteSeverity = createAction(
  '[Severity/API] Delete Severity',
  props<{ id: string }>()
);

export const deleteSeveritys = createAction(
  '[Severity/API] Delete Severitys',
  props<{ ids: string[] }>()
);

export const clearSeveritys = createAction(
  '[Severity/API] Clear Severitys'
);

// Effect Actions
export const getAllInstitutionSeverities = createAction(
  '[Severity/API] List All Severitys',
  props<{ pageableParam?: PageableParam }>()
);

export const getSeverityById = createAction(
  '[Severity/API] Get Severity By Id',
  props<{ severityId: string }>()
);

export const addUpdateSeverity = createAction(
  '[Severity/API] Save Severity',
  props<{ severity: SeverityDtoInput }>()
);

export const modifySeverity = createAction(
  '[Severity/API] Modify Severity',
  props<{ severityDto: Severity, severityId: string }>()
);

export const removeSeverity = createAction(
  '[Severity/API] Remove Severity By Id',
  props<{ severityId: string }>()

);

export const getSeveritiesByInstitution = createAction(
  '[Severity/API] get Severities By Institution',
  props<{ pageparam?: PageableParam; institutionUuid: string }>()
);
