import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { AptitudeTestCandidate } from './aptitude-test-candidate.model';
import * as AptitudeTestCandidateActions from './aptitude-test-candidate.actions';

export const aptitudeTestCandidatesFeatureKey = 'aptitudeTestCandidates';

export interface State extends EntityState<AptitudeTestCandidate> {
  // additional entities state properties
}

export const adapter: EntityAdapter<AptitudeTestCandidate> = createEntityAdapter<AptitudeTestCandidate>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export const reducer = createReducer(
  initialState,
  on(AptitudeTestCandidateActions.addAptitudeTestCandidate,
    (state, action) => adapter.addOne(action.aptitudeTestCandidate, state)
  ),
  on(AptitudeTestCandidateActions.upsertAptitudeTestCandidate,
    (state, action) => adapter.upsertOne(action.aptitudeTestCandidate, state)
  ),
  on(AptitudeTestCandidateActions.addAptitudeTestCandidates,
    (state, action) => adapter.addMany(action.aptitudeTestCandidates, state)
  ),
  on(AptitudeTestCandidateActions.upsertAptitudeTestCandidates,
    (state, action) => adapter.upsertMany(action.aptitudeTestCandidates, state)
  ),
  on(AptitudeTestCandidateActions.updateAptitudeTestCandidate,
    (state, action) => adapter.updateOne(action.aptitudeTestCandidate, state)
  ),
  on(AptitudeTestCandidateActions.updateAptitudeTestCandidates,
    (state, action) => adapter.updateMany(action.aptitudeTestCandidates, state)
  ),
  on(AptitudeTestCandidateActions.deleteAptitudeTestCandidate,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(AptitudeTestCandidateActions.deleteAptitudeTestCandidates,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(AptitudeTestCandidateActions.loadAptitudeTestCandidates,
    (state, action) => adapter.setAll(action.aptitudeTestCandidates, state)
  ),
  on(AptitudeTestCandidateActions.clearAptitudeTestCandidates,
    state => adapter.removeAll(state)
  ),
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
