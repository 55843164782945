import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { UserAptitudeTestResult } from './user-aptitude-test-result.model';

export const loadUserAptitudeTestResults = createAction(
  '[UserAptitudeTestResult/API] Load UserAptitudeTestResults', 
  props<{ userAptitudeTestResults: UserAptitudeTestResult[] }>()
);

export const addUserAptitudeTestResult = createAction(
  '[UserAptitudeTestResult/API] Add UserAptitudeTestResult',
  props<{ userAptitudeTestResult: UserAptitudeTestResult }>()
);

export const upsertUserAptitudeTestResult = createAction(
  '[UserAptitudeTestResult/API] Upsert UserAptitudeTestResult',
  props<{ userAptitudeTestResult: UserAptitudeTestResult }>()
);

export const addUserAptitudeTestResults = createAction(
  '[UserAptitudeTestResult/API] Add UserAptitudeTestResults',
  props<{ userAptitudeTestResults: UserAptitudeTestResult[] }>()
);

export const upsertUserAptitudeTestResults = createAction(
  '[UserAptitudeTestResult/API] Upsert UserAptitudeTestResults',
  props<{ userAptitudeTestResults: UserAptitudeTestResult[] }>()
);

export const updateUserAptitudeTestResult = createAction(
  '[UserAptitudeTestResult/API] Update UserAptitudeTestResult',
  props<{ userAptitudeTestResult: Update<UserAptitudeTestResult> }>()
);

export const updateUserAptitudeTestResults = createAction(
  '[UserAptitudeTestResult/API] Update UserAptitudeTestResults',
  props<{ userAptitudeTestResults: Update<UserAptitudeTestResult>[] }>()
);

export const deleteUserAptitudeTestResult = createAction(
  '[UserAptitudeTestResult/API] Delete UserAptitudeTestResult',
  props<{ id: string }>()
);

export const deleteUserAptitudeTestResults = createAction(
  '[UserAptitudeTestResult/API] Delete UserAptitudeTestResults',
  props<{ ids: string[] }>()
);

export const clearUserAptitudeTestResults = createAction(
  '[UserAptitudeTestResult/API] Clear UserAptitudeTestResults'
);

// =============== CUSTOM ACTIONS =========================

export const getAllAptitudeTestResults = createAction(
  '[UserAptitudeTestResult/API] Get All UserAptitudeTestResults',
  props<{ aptitudeTestUuid: string, pageParam: any }>()
);

export const getMyAptitudeTestResults = createAction(
  '[UserAptitudeTestResult/API] Get My UserAptitudeTestResults',
  props<{ aptitudeTestUuid: string }>()
);

export const submitMyAptitudeTest = createAction(
  '[AptitudeTest/API] Submit My Aptitude Test',
  props<{ aptitudeTestUuid: string }>()
);