import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import { map, switchMap } from 'rxjs/operators';
import { initialPageableParam } from 'src/app/interfaces/table.interface';
import { NotificationService } from 'src/app/services/notification.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { ResponseCodeService } from 'src/app/services/response-code.service';
import { AppState } from '../../reducers';
import * as fromActions from './ldap-configuration.actions';
import * as fromGraphql from './ldap-configuration.graphql';


@Injectable()
export class LdapConfigurationEffects {


// addLdapConfiguration
addLdapConfiguration = createEffect(() => this.actions$.pipe(
  ofType(fromActions.addLdapConfiguration),
  switchMap((action) => {     
    return this.apollo.mutate({
      mutation: fromGraphql.CREATE_LDAP_CONFIGURATION,
      variables: {
        ldapConfigDto: action.ldapConfiguration
      }
    }).pipe(
      this.notificationService.catchError('Problem occurred while adding LDAP configuration'),
      map(({ data }: any) => {
        if (data) {
          let result: any = data?.addUpdateLdapConfig
          if (result?.code === 9000) {
            this.store.dispatch(fromActions.upsertLdapConfiguration({ ldapConfiguration: result?.data }));
            if(action.ldapConfiguration?.uuid){
              this.notificationService.successMessage('LDAP configuration edited successfullty');
            }else{
              this.notificationService.successMessage('LDAP configuration added successfully');
            }
          } else {
            return this.handleError(result);
          }
        }
      })
    );
  })
), { dispatch: false });

// getLdapConfigurations
getLdapConfigurations$ = createEffect(() => this.actions$.pipe(
  ofType(fromActions.getLdapConfigurations),
  switchMap((action) => {
    return this.apollo.query({
      query: fromGraphql.GET_ALL_LDAP_CONFIGURATIONS,
      fetchPolicy: 'network-only',
      variables: {
        pageparam: action?.pageparam ? initialPageableParam : action?.pageparam
      }
    }).pipe(
      this.notificationService.catchError('Problem while fetching LDAP configurations'),
      map(({ data }: any) => {
        if (data) {
          const result = data?.getAllLdapConfig;
          if (result?.content?.length > 0) {
            this.paginationService.setPage(result);
          }
          this.store.dispatch(fromActions.loadLdapConfigurations({ ldapConfigurations: (result?.content || []) }));
        }
      })
    );
  })
), { dispatch: false });

// removeLdapConfiguration
removeLdapConfiguration$ = createEffect(() => this.actions$.pipe(
  ofType(fromActions.removeLdapConfiguration),
  switchMap((action) => {
    return this.apollo.mutate({
      mutation: fromGraphql.REMOVE_LDAP_CONFIGURATION,
      variables: {
        uuid: action.ldapConfiguration.uuid
      }
    }).pipe(
      this.notificationService.catchError('Problem occurred while deleting incident LDAP configuration'),
      map(({ data }: any) => {
        if (data) {
          let result: any = data?.activateDeactivateRootCause
          if (result?.code == 9000) {
            this.store.dispatch(fromActions.deleteLdapConfiguration({ id: action?.ldapConfiguration?.id }));
            this.notificationService.successMessage('LDAP configuration deleted successfully');
          } else {
            return this.handleError(result);
          }
        }
      })
    );
  })
), { dispatch: false });


constructor(
  private actions$: Actions,
  private apollo: Apollo,
  private store: Store<AppState>,
  private notificationService: NotificationService,
  private responseCodesService: ResponseCodeService,
  private router: Router,
  private paginationService: PaginationService,
) {
}

handleError(data) {
  const responseCode = this.responseCodesService.getCodeDescription(data.code);
  const message = responseCode[0].code + ' : ' + responseCode[0].description;
  return this.notificationService.errorMessage(message);
}

}
