import { Component, OnInit, HostBinding, Input, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { FieldConfig } from '../field.interface';
@Component({
  selector: 'app-description-editor',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
<div class="demo-full-width" [formGroup]="group" appearance="outline">
<mat-label> {{ field.label }} </mat-label>
<!-- <textarea rows="4" cols="4" matInput [formControlName]="field.key"
[placeholder]="field.label" ></textarea> -->
<angular-editor [placeholder]="field.label"  [formControlName]="field.key" [config]="config"></angular-editor>
<ng-container *ngFor="let validation of field.validations;" ngProjectAs="mat-error">
<mat-error *ngIf="group.get(field.key).hasError(validation.name)">{{validation.message}}</mat-error>
</ng-container>
</div>
`,
  // host: {'class': 'col-md-6'},
  styles: []
})
export class TextareaWithEditor implements OnInit {
  field: FieldConfig;
  @Input() group: UntypedFormGroup = new UntypedFormGroup({});
  // @HostBinding('class') rowClass = 'col-md-6';
  constructor() { }
  ngOnInit() {


    //  this.rowClass = this.field.rowClass === 'large' ? 'col-md-12' : 'col-md-6';
  }

  // tslint:disable-next-line:member-ordering
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ]
  };
}
