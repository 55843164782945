
<div class="login-holder">
    <div class="container d-flex justify-content-center text-center vh-100">
        <div class="col-lg-9 m-auto">
            <div class="row nembo-heading">
                <div class="col-lg-2">
                    <!-- <img alt="emblem" class="emblem xs-hide w-75" src="/assets/images/egas.png" title="Emblem"> -->
                </div>
                <div class="col-lg-8 title-text">
                    <div>

                        <!-- <img alt="emblem" class="emblem xs-hide w-8" src="/assets/images/egas.png" title="Emblem"> -->

                    </div>

                    <div> Government Helpdesk System</div>
                </div>
                <div class="col-lg-2">
                    <!-- <img alt="eGA Logo" class="w-100 xs-hide" src="/assets/images/ega.png" title="eGA Logo"> -->
                </div>
            </div>

            <div class="shadow-container">
                <div class="login-box">
                    <div class="display-box xs-hide">
                        <img src="../../../../assets/images/support1.png" alt="Support">
                    </div>

                    <div class="login-form pr-3">
                        <div *ngIf="!loginView" class="logo-image">
                            <h1 class="display1"> Login</h1>
                            <!-- Login Form -->
                            <form (ngSubmit)="login(staffLoginForm.value)" [formGroup]="staffLoginForm" autocomplete="off" novalidate>
                                <div class="row">
                                    <mat-form-field appearance="outline" class="material-form col-md-12">
                                        <mat-label>Email</mat-label>
                                        <input formControlName="username" matInput placeholder="Username">
                                        <mat-icon matSuffix>email</mat-icon>
                                        <mat-error>
                                            <span *ngIf="!staffLoginForm.get('username').valid && staffLoginForm.get('username').touched">
                                                Email is required filed
                                            </span>
                                        </mat-error>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline" class="material-form col-md-12">
                                        <mat-label>Password</mat-label>
                                        <input formControlName="password" matInput placeholder="Password" [type]="hide ? 'password' : 'text'">
                                        <a mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                        </a>
                                        <!-- type="password">
                                        <mat-icon matSuffix>lock</mat-icon> -->
                                        <mat-error>
                                            <span *ngIf="!staffLoginForm.get('password').valid && staffLoginForm.get('password').touched">
                                                Password is required filed
                                            </span>
                                        </mat-error>
                                    </mat-form-field>
                                    <!-- <mat-form-field appearance="outline">
                                        <mat-label>Enter your password</mat-label>
                                        <input matInput [type]="hide ? 'password' : 'text'">
                                        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                          <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                        </button>
                                    </mat-form-field> -->
                                </div>

                                <div class="row"> 
                                    <div class="col-md-12">
                                        <span *ngIf="loading">
                                            <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
                                        </span>
                                    </div>
                                    <div class="col-md-12 d-flex px-0">
                                        <div (click)="changeView()" class="float-left forgotPassword text-capitalize cursor-pointer order-xs-2 col-xl-7 text-left text-xs-center col-xs-12">
                                            <i>forgot your password?</i>
                                        </div>
                                        <div class="float-lg-right col-lg-5 order-xs-1 col-xs-12">
                                            <button [disabled]="!staffLoginForm.valid || loading" class="custom-btn green-btn w-100" type="submit">
                                                Login
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </form>
                            <!-- End of Login Form -->
                        </div>

                        <div *ngIf="loginView" class="logo-image">
                            <h1 class="display1"> Forgot Password?</h1>
                            <!-- forgot password Form -->
                            <form (ngSubmit)="forgetPassword(resetPasswordForm.value)" [formGroup]="resetPasswordForm" autocomplete="off" novalidate>
                                <div class="row">
                                    <mat-form-field appearance="outline" class="material-form col-md-12">
                                        <mat-label>Enter your email</mat-label>
                                        <input formControlName="email" matInput placeholder="Enter Your E-mail Address" type="email">
                                        <mat-icon matSuffix>email</mat-icon>
                                        <mat-error>
                                            <span *ngIf="!resetPasswordForm.get('email').valid && resetPasswordForm.get('email').touched">
                                                Email is required filed
                                            </span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <span *ngIf="loading">
                                            <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
                                        </span>
                                    </div>
                                    <div class="col-md-12 d-flex px-0">
                                        <div (click)="changeView()" class="float-left text-capitalize backToLogin cursor-pointer order-xs-2 col-xl-7 text-left text-xs-center col-xs-12">
                                            <i>Back to Login page</i>
                                        </div>
                                        <div class="float-lg-right col-lg-5 order-xs-1 col-xs-12">
                                            <button [disabled]="!resetPasswordForm.valid || loading" class="green-btn custom-btn w-100" type="submit">
                                                Send Reset Link
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </form>
                            <!-- End of forgot password Form -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="ega xs-hide">
        <div>
            <strong>&copy; {{currentYear}} </strong> - <strong>e - Government Authority</strong>
        </div>
        <div>
            <strong>The system is designed and developed by </strong>
            <a href="http://ega.go.tz" target="_blank" title="e-Government Authority">
                <img alt="eGA" src="/assets/images/ega.png">
            </a>
        </div>
    </div>
</div>