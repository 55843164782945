import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as SolutionStepActions from './solution-step.actions';
import { SolutionStep } from './solution-step.model';

export const solutionStepsFeatureKey = 'solutionStep';

export interface State extends EntityState<SolutionStep> {
  // additional entities state properties
}

export const adapter: EntityAdapter<SolutionStep> = createEntityAdapter<SolutionStep>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(SolutionStepActions.upsertSolutionStep,
    (state, action) => adapter.upsertOne(action.solutionStep, state)
  ),
  on(SolutionStepActions.addSolutionSteps,
    (state, action) => adapter.addMany(action.solutionSteps, state)
  ),
  on(SolutionStepActions.upsertSolutionSteps,
    (state, action) => adapter.upsertMany(action.solutionSteps, state)
  ),
  on(SolutionStepActions.updateSolutionStep,
    (state, action) => adapter.updateOne(action.solutionStep, state)
  ),
  on(SolutionStepActions.updateSolutionSteps,
    (state, action) => adapter.updateMany(action.solutionSteps, state)
  ),
  on(SolutionStepActions.deleteSolutionStep,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(SolutionStepActions.deleteSolutionSteps,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(SolutionStepActions.loadSolutionSteps,
    (state, action) => adapter.setAll(action.solutionSteps, state)
  ),
  on(SolutionStepActions.clearSolutionSteps,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
