import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {Observable, Subscription} from 'rxjs';
import {InstitutionPipe} from 'src/app/shared/pipes/institution.pipe';
import {
    clearServices,
    deleteMyServiceSubscriptionFromMyClientServiceSUbscription,
    servicesSubscribedInMyInstByClient
} from 'src/app/store/entities/settings/service/service.actions';
import {Service} from 'src/app/store/entities/settings/service/service.model';
import {selectModifiedServices} from 'src/app/store/entities/settings/service/service.selectors';
import {AppState} from 'src/app/store/reducers';
import {NotificationService} from 'src/app/services/notification.service';
import {Institution} from '../../../../store/entities/settings/institution/institution.model';
import {StorageService} from '../../../../services/storage.service';

@Component({
    selector: 'app-service-subscribed',
    templateUrl: './service-subscribed.component.html',
    styleUrls: ['./service-subscribed.component.scss'],
    providers: [InstitutionPipe]

})
export class ServiceSubscribedComponent implements OnInit, OnDestroy {
    title: string;
    tableColumns: any;
    subscriptions = new Subscription();
    services$: Observable<Service[]>;
    institutionUuid: string;
    @Input() institutionInput: Institution;

    userType: any;
    myInstitutionUid: string;

    constructor(
        private store: Store<AppState>,
        private route: ActivatedRoute,
        private notificationService: NotificationService,
        private institutionPipe: InstitutionPipe,
        private storageService: StorageService
    ) {
        this.myInstitutionUid = storageService.getItem('institutionUuid');
        this.userType = storageService.getItem('userType');
    }


    ngOnInit(): void {
        this.store.dispatch(clearServices());
        this.loadData();
        this.title = 'Subscribed Services By: ';
        // const institutionDetails$ = this.institutionPipe.transform(this.institution_uuid );

        const institutionDetails$ = this.institutionPipe.transform(this.institutionUuid);
        this.subscriptions.add(
            institutionDetails$.subscribe((data) => {
                this.title = this.title + '  ' +
                    (data.length > 50 ? data?.substring(0, 49) + ' ...' : data);
            })
        );

        this.tableColumns = {
            no: 'SN',
            name: 'Service',
            serviceType: 'Category',
            isActive: 'Status',
            action: 'Actions'
        };
        this.services$ = this.store.pipe(select(selectModifiedServices));

    }

    loadData() {
        this.institutionUuid = this.route?.snapshot?.paramMap.get('institutionID') ?? this.institutionInput?.uuid;
        this.store.dispatch(servicesSubscribedInMyInstByClient({instutionUuid: this.institutionUuid, option: 'MANY'}));
    }

    // deleteMyServiceSubscriptionFromMyClientServiceSUbscription
    unsubscribeClientFromService(data) {
        this.notificationService.confirmation('Are you Sure you want to Delete ?').then(i => {
            if (i.isConfirmed) {
                this.store.dispatch(deleteMyServiceSubscriptionFromMyClientServiceSUbscription({
                    institutionUuid: this.institutionUuid,
                    serviceUuid: data.uuid,
                    id: data.id
                }));

            }
        });

    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }


}
