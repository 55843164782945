import gql from 'graphql-tag';
import {basePageableGraphqlField} from '../../base.graphql';

export const activeRosterFields = `
                rosterTimes{
                    startDateTime
                    endDateTime
                    users{
                        username
                        firstName
                        lastName
                        fullName
                        phone
                    }
                    id
                }
                id
                startDate
                endDate
                createdAt
                createdBy
                rosterNumber
                title
                active
`;



export const MY_INSTITUTION_ROSTER_LIST = gql`
    query myInstRosterListCurrent($pageParam: PageableParamInput){
        myInstRosterListCurrent(pageParam:$pageParam){
            content{
                ${activeRosterFields}
            }
            ${basePageableGraphqlField}
        }
    }
`;


export const CREATE_NEW_ROASTER = gql`
    mutation createNewRoster($roster:RosterDtoInput){
        createNewRoster(roster: $roster) {
            code
            data {
                ${activeRosterFields}
            }
            message
            status
        }
    }
`;

export const DELETE_ROSTER = gql`
    mutation deleteRoster($id:Long){
        deleteRoster(id:$id){
            message
            code
            status
        }
    }
`;


export const MY_ROSTER_LIST = gql`
query myRosterList($pageParam: PageableParamInput, $active: Boolean){
    myRosterList(pageParam:$pageParam, active: $active){
        content{
            ${activeRosterFields}
        }
        ${basePageableGraphqlField}
    }
}
`;


export const MY_ROSTER_LIST_FEW_FILEDS = gql`
query myRosterList($pageParam: PageableParamInput, $active: Boolean){
    myRosterList(pageParam:$pageParam, active: $active){
        content{
            id
            startDate
            endDate
            createdAt
            createdBy
            rosterNumber
            title
            active
        }
        ${basePageableGraphqlField}
    }
}
`;










