import gql from 'graphql-tag';
import { basePageableGraphqlField } from '../../base.graphql';

export const incidentResponseFields = `
id
active
createdAt
updatedAt
createdBy
updatedBy
deleted
title
incidentStatus
responseDescription
isPublic
userUuid
uuid
holdTimeInDays
responseType
seen
`;

export const CREATE_INCIDENT_RESPONSE = gql`
  mutation addUpdateIncidentResponse($incidentResponse: IncidentResponseDtoInput){
    addUpdateIncidentResponse(incidentResponse: $incidentResponse){
      code
      data{
        ${incidentResponseFields}
      }
      message
    }
  }
`;

export const GET_ALL_INCIDENT_RESPONSES_BY_INCIDENT = gql`
  query getAllIncidentResponsesByIncident($incidentUuid: String, $pageparam: PageableParamInput){
    getAllIncidentResponsesByIncident(incidentUuid: $incidentUuid, pageparam: $pageparam){
      content{
        ${incidentResponseFields}
      }
      ${basePageableGraphqlField}
    }
  }
`;

export const GET_INCIDENT_RESPONSE_BY_UUID = gql`
  query getIncidentResponseByUuid($incidentResponseUuid: String) {
    getIncidentResponseByUuid(incidentResponseUuid: $incidentResponseUuid){
      code
      data{
        ${incidentResponseFields}
      }
      message
    }
  }
`;

export const GET_INCIDENT_RESPONSE_BY_ID = gql`
  query getIncidentResponseById($incidentResponseId: Long) {
    getIncidentResponseById(incidentResponseId: $incidentResponseId){
      code
      data{
        ${incidentResponseFields}
      }
      message
    }
  }
`;

export const GET_INCIDENT_RESPONSE_BY_USER = gql`
  query getIncidentResponseByUserUuid($userUuid: String, $pageparam: PageableParamInput){
    getIncidentResponseByUserUuid(userUuid: $userUuid, pageparam: $pageparam){
      ${incidentResponseFields}
    }
  }
`;

export const GET_ALL_PAGED_INCIDENT_RESPONSES = gql`
  query getAllIncidentResponses($pageparam: PageableParamInput){
    getAllIncidentResponses(pageparam: $pageparam){
      ${incidentResponseFields}
    }
  }
`;

export const UPDATE_INCIDENT_RESPONSE_TO_SEEN = gql`
  mutation updateIncidentResponseToSeen($incidentResponseUuid: String){
    updateIncidentResponseToSeen(incidentResponseUuid: $incidentResponseUuid){
      code
      message
      status
      data{
        ${incidentResponseFields}
      }
    }
  }
`;
