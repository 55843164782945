<div class="portal-page pg-container">
    <div class="bg-warninggggg w-100 d-flex justify-content-between align-items-center" >
      <div class="start-links d-flex justify-content-start align-items-center">
      </div>
    <div class="end-links d-flex justify-content-end">
    
      <div class="card" tabindex="0">
        <span class="infoicon">
          <mat-icon svgIcon="question"></mat-icon>
        </span>
        
        <div class="contact">
          <h3 class="contact-title">Need Assistance ?
          </h3>
          <p class="contact-content">Please contact your Service Provider's ICT Support team </p>
          <div class="more-info">
              For more information call us: +255 764 292 299, +255 763 292 299
          </div>
      </div>        
      </div>
    </div>
  </div>

  <div class="col page-content d-flex align-items-center justify-content-center fade-on" style="--position: 6"  >

    <div class="custom-shape-divider-bottom-1674201436">
      <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M741,116.23C291,117.43,0,27.57,0,6V120H1200V6C1200,27.93,1186.4,119.83,741,116.23Z" class="shape-fill"></path>
      </svg>
    </div>

    <div class="container scale-up" style="--position: 4">
      <div class="content-heading text-center">
        <div class="img-holder" >
          <img alt="emblem" class="emblem" src="../../../../assets/images/support1.png" />
        </div>
        <div class="faded-slight tex system-name text-large text-bold">
          {{ title }}
        </div>
      </div>
      <div class="max-width-420 text-white mb-3 test">
        <div class="row move-up" style="--order: 1.5" *ngIf="isLogin" >
          <form (ngSubmit)="login(staffLoginForm.value)" [formGroup]="staffLoginForm" autocomplete="off" novalidate >
            <div class="row">
              <mat-form-field appearance="outline" class="fill-white text-dark material-form col-md-12" >
                <mat-label class="text-dark faded">Email</mat-label>
                <input formControlName="username" class="bg-none rounded-slight" matInput placeholder="Username" />
                <mat-icon matSuffix class="top--5">email</mat-icon>
                <mat-error>
                  <span *ngIf="!staffLoginForm.get('username').valid && staffLoginForm.get('username').touched" > Email is required</span>
                </mat-error>
              </mat-form-field>

              <mat-form-field appearance="outline" color="white" class="fill-white text-dark material-form col-md-12 move-up has-hover-hide-show-stacked" style="--order: 2" >
                <mat-label class="text-dark faded">Password</mat-label>
                <input formControlName="password" class="rounded-slight" matInput placeholder="Password" type="password" [type]="showPassword ? 'text' : 'password'" />
                <div matSuffix class="position-relative stack top--5" (click)="togglePasswordVisibility()" >
                  <mat-icon class="faded hover-show">{{showPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                </div>
                <mat-error>
                  <span *ngIf="!staffLoginForm.get('password').valid && staffLoginForm.get('password').touched" >Password is required</span>
                </mat-error>
              </mat-form-field>
            </div>

            <div class="row login-row">
              <div class="col-md-6 d-flex justify-content-start">
                <div class="text-button forgotPassword" matRipple (click)="toggleLogin()">
                  <i>Forgot your password?</i>
                </div>
              </div>
              <div class="col-md-6 d-flex justify-content-end">
                <button matRipple mat-button [disabled]="!staffLoginForm.valid || loading" class="px-3 mx-3 bg-ocean-blue text-info rounded-medium py-0 loginbtn green-btn" type="submit">
                  Log in
                </button>
              </div>
            </div>
          </form>
        </div>
        <div class="row content-item" style="--order: 1.5" *ngIf="!isLogin" >
          <form (ngSubmit)="forgetPassword(forgotPasswordForm.value)" [formGroup]="forgotPasswordForm" autocomplete="off" novalidate >
            <div class="row">
              <mat-form-field appearance="outline" class="fill-white text-dark material-form col-md-12" >
                <mat-label class="text-dark faded">Email</mat-label>
                <input formControlName="email" class="bg-none rounded-slight" matInput placeholder="Email" />
                <mat-icon matSuffix class="top--5" >email</mat-icon>
                <mat-error>
                  <span *ngIf="!forgotPasswordForm.get('email').valid && forgotPasswordForm.get('email').touched">Email is required</span>
                </mat-error>
              </mat-form-field>
            </div>

            <div class="row justify-content-between login-row forgotView" style="--order: 2.5" >
              <div class="col-md-6 d-flex ">
                <div class="text-button text ReturntoLogin" matRipple (click)="toggleLogin()" >
                  <i>Return to Login</i>
                </div>
              </div>
              <div class="col-md-6 d-flex text-right justify-content-end" >
                <button matRipple mat-button [disabled]="!forgotPasswordForm.valid || loading" class="px-3 mx-3 blue-btn custom-blue-btn" type="submit" >
                  Submit
                </button>
              </div>
            </div>
            <div class="height:50px; width:100%"></div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <span *ngIf="sessionExpired" class="session-expired">Session Timed Out</span>
</div>

  <div class="ega xs-hide">
    <div class="d-none d-md-block">
      <strong>&copy; {{ currentYear }} </strong>
      <strong>Government Helpdesk System</strong>
    </div>
    <div>
      <strong class="d-none d-md-block">
        The system is designed and developed by
      </strong>
      <a
        href="http://ega.go.tz"
        target="_blank"
        title="e-Government Authority"
      >
        <span class="d-md-none position-relative text-white" style="top: 2px !important">
          <strong class="pr-2">&copy; {{ currentYear }} </strong> | &nbsp;
        </span>
        <img alt="eGA" src="/assets/images/ega.png" />
      </a>
    </div>
  </div>
