import { gql } from "apollo-angular";

export const GENERATE_EXAMINATION_REPORT = gql`
    query generateExaminationReport($reportFormat:ReportFormatEnum){
        generateExaminationReport(reportFormat:$reportFormat){
            code
            message
            status
            data
        }
    }
`;

export const GENERATE_INSTITUTION_REPORT = gql`
    query generateInstitutionReport($reportFormat:ReportFormatEnum){
        generateInstitutionReport(reportFormat:$reportFormat){
            code
            message
            data
        }
    }
`;

export const GENERATE_INSTITUTION_EXAMINATION_REPORT = gql`
    query generateInstitutionExaminationReport($endDate:LocalDate,$reportFormat:ReportFormatEnum,$startDate:LocalDate){
        generateInstitutionExaminationReport(endDate:$endDate,reportFormat:$reportFormat,startDate:$startDate){
            code
            message
            status
            data
        }
    }
`;
