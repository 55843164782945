import * as moment from "moment";

export function calculateAge(birthday) {
  // birthday is a date
  try {
    const birthDate = new Date(birthday);
    const ageDifMs = Date.now() - birthDate.getTime();
    const ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  } catch (e) {
    return 0;
  }
}

export const todayDate = new Date();
export const maxBirthDate = moment().subtract(18, "years").format();
export const momentDateToday = moment().format();

export function getLastDateOfMonth(year, month): number {
  const d = new Date(year, month - 1);

  return moment(d).daysInMonth();
}
