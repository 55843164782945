<div class="portal-page" *ngIf="tokenValid" >
    <div class="login-container">
        <div class="login-title">
            <div class="logo">
                <img alt="emblem" class="emblem" src="../../../../assets/images/support1.png"/>
            </div>
            <div class="titles">
                <h2>{{title}}</h2>
            </div>
        </div>
        <div class="login-content">
            <div class="row m-0">
                <div class="col-md-8 offset-md-2">
                    <div class="login-header">
                        <h3>Create A New Password</h3>
                    </div>
                </div>
            </div>
            <div class="row m-0">
                <div class="col-md-6 border-left">
                    <div class="login-right">
                        <form (ngSubmit)="submitCreatePassword()" [formGroup]="changePasswordForm">

                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Password</mat-label>
                                            <input autocomplete="off" formControlName="newPassword" matInput
                                                   placeholder="Password" type="password">
                                            <mat-icon matSuffix>lock</mat-icon>
                                            <mat-hint>Password must be 8 characters minimum</mat-hint>
                                            <mat-error>
                                            <span *ngIf="!changePasswordForm.get('newPassword').valid && changePasswordForm.get('newPassword').touched">
                                            New Password is required filed
                                          </span>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-12">
                                        <app-password-strength-bar (strength)="getStrength($event)"
                                                                   [barLabel]="barLabel"
                                                                   [passwordToCheck]="changePasswordForm.get('newPassword').value"></app-password-strength-bar>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Confirm Password</mat-label>
                                            <input autocomplete="off" formControlName="confirmPassword" matInput
                                                   placeholder="Confirm your password" type="password">
                                            <mat-icon matSuffix>lock</mat-icon>
                                            <mat-error>
                                            <span *ngIf="!changePasswordForm.get('confirmPassword').valid && changePasswordForm.get('confirmPassword').touched">
                                              Confirm is required filed
                                          </span>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-8">
                                    <div class="forgot-button">
                                        <button [disabled]="loading" [routerLink]="['','login']" mat-button> Go to login
                                            page
                                        </button>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="forgot-button">
                                        <button [disabled]=" !changePasswordForm.valid ||loading ||
                                    changePasswordForm.get('newPassword').value !== changePasswordForm.get('confirmPassword').value"
                                                class="btn-block" mat-raised-button type="submit">Submit
                                        </button>
                                        <!-- !changePasswordForm.valid || loading || password_strength !== 'Good' || -->
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-md-6 password_policy border-right">
                    <div class="policy-heading">
                        Good Password Policy
                    </div>
                    <div class="">
                        <p class="txt-md">
                            A good password must be 8 or more characters in length, must contain 1 or more uppercase
                            characters, must contain 1 or more lowercase characters, must contain 1 or more special
                            characters, must no be numerical or letter sequence eg: <i>1234567</i>,
                            <i>QWERTY</i> e.tc. <br><br> Special characters include: ,./$%^&?@#;):~"!*"+
                        </p>
                    </div>
                </div>
            </div>
            <div *ngIf="loading" class="row">
            </div>
            <!--            <div class="row m-0">-->
            <!--                <div class="login-footer">-->
            <!--                    <p style="font-size: 14px; font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;-->
            <!--                font-weight: 400;">For any Technical inquiry, Please contact Our ICT Support Team at :-->
            <!--                        egov.helpdesk@ega.go.tz.</p>-->
            <!--                </div>-->
            <!--            </div>-->
        </div>
    </div>

    <div class="custom-shape-divider-bottom-1674226228">
        <svg data-name="Layer 1" preserveAspectRatio="none" viewBox="0 0 1200 120" xmlns="http://www.w3.org/2000/svg">
            <path class="shape-fill"
                  d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
                  opacity=".25"></path>
            <path class="shape-fill"
                  d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
                  opacity=".5"></path>
            <path class="shape-fill"
                  d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"></path>
        </svg>
    </div>
</div>

<div class="ega xs-hide">
    <div class="d-none d-md-block">
        <strong>&copy; {{ currentYear }} </strong>
        <strong>Government Helpdesk System</strong>
    </div>
    <div>
        <strong class="d-none d-md-block">
            The system is designed and developed by
        </strong>
        <a
                href="http://ega.go.tz"
                target="_blank"
                title="e-Government Authority"
        >
        <span class="d-md-none position-relative text-white" style="top: 2px !important">
          <strong class="pr-2">&copy; {{ currentYear }} </strong> | &nbsp;
        </span>
            <img alt="eGA" src="/assets/images/ega.png"/>
        </a>
    </div>
</div>

