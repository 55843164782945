import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {tap} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {AppState} from 'src/app/store/reducers';
import {clearPageables, insertPage, insertPageTotalElement, loadPageables} from './pageable.actions';


@Injectable()
export class PageableEffects {

  insertPageable$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(insertPageTotalElement),
        tap((action) => {
          this.store.dispatch(clearPageables());
          this.store.dispatch(loadPageables({ pageables: [ { id: 1,  totalElements: action.totalElement } ] }));
        })
      ),
    { dispatch: false }
  );

  insertPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(insertPage),
        tap((action) => {
          this.store.dispatch(clearPageables());
          this.store.dispatch(loadPageables({
            pageables: [
            {
              id: 1,
              totalElements: action.page?.totalElements,
              totalPages: action.page?.totalPages,
              first: action.page?.first,
              hasContent: action.page?.hasContent,
              last: action.page?.last,
              hasNext: action.page?.hasNext
            }
            ] }));
        })
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private store: Store<AppState>) {}

}
