import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { IncidentNotification } from './notification.model';
import * as IncidentNotificationActions from './notification.actions';

export const incidentNotificationsFeatureKey = 'incidentNotifications';

export interface State extends EntityState<IncidentNotification> {
  // additional entities state properties
}

export const adapter: EntityAdapter<IncidentNotification> = createEntityAdapter<IncidentNotification>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(IncidentNotificationActions.addIncidentNotification,
    (state, action) => adapter.addOne(action.incidentNotification, state)
  ),
  on(IncidentNotificationActions.upsertIncidentNotification,
    (state, action) => adapter.upsertOne(action.incidentNotification, state)
  ),
  on(IncidentNotificationActions.addIncidentNotifications,
    (state, action) => adapter.addMany(action.incidentNotifications, state)
  ),
  on(IncidentNotificationActions.upsertIncidentNotifications,
    (state, action) => adapter.upsertMany(action.incidentNotifications, state)
  ),
  on(IncidentNotificationActions.updateIncidentNotification,
    (state, action) => adapter.updateOne(action.incidentNotification, state)
  ),
  on(IncidentNotificationActions.updateIncidentNotifications,
    (state, action) => adapter.updateMany(action.incidentNotifications, state)
  ),
  on(IncidentNotificationActions.deleteIncidentNotification,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(IncidentNotificationActions.deleteIncidentNotifications,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(IncidentNotificationActions.loadIncidentNotifications,
    (state, action) => adapter.setAll(action.incidentNotifications, state)
  ),
  on(IncidentNotificationActions.clearIncidentNotifications,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
