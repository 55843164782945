
import gql from 'graphql-tag';

export const allStatusFields = `
closed
open
draft
assigned
firstLevelSubmitted
incidentCategoryName
secondLevelSubmitted
resolved
submitted
assignedToDept
onHold

`;
// SUBMITTED,
// ASSIGNED,
// ADDED_NOTE,
// ADDED_PROGRESS,
// ADDED_ROOT_CAUSE,
// REASSIGNED,
// UNASSIGNED,
// CATEGORIZED,
// REOPENED,
// ROLLBACK_RESOLVED

export const outGoingStatusFields = `
closed
open
draft
assigned
incidentCategoryName
resolved 
submitted
`;

export const LIST_ALL_NOTIFICATION_FOR_ACTION_TAKERS_SERVICE_PROVIDERS = gql` 
query getNotifications($incidentOrigin : IncidentOrigin ){
    getAllIncidentsByStatusStatistics(incidentOrigin: $incidentOrigin ){
    code
    data{
      ${allStatusFields}
    }
  }
  
  getMyAssignedIncidentsStatistics{
  code
  data{
    ${allStatusFields}
  }
 }
 
}
`;

export const GET_ALL_MY_INCIDENT_NOTIFICATIONS = gql` 
query getMyIncidentNotifications {  
  getMyInstIncidentsOutStatistics{
  code
  data{
    ${outGoingStatusFields}
  }
 }
}
`;

export const LIST_ALL_OUTGOING_NOTIFICATIONS = gql` 
query getClientNotifications{
  getMyInstIncidentsOutByStatusStatistics{
    code
    data{
    ${outGoingStatusFields}
    }
  }
}
`;
export const LIST_ALL_OUTGOING_NOTIFICATIONS_FOR_INDIVIDUALS = gql` 
query getMyIndividualIncidentsOutByStatusStatistics{
    getMyIndividualIncidentsOutByStatusStatistics{
    code
    data{
    ${outGoingStatusFields}
    }
  }
}
`;

export const GET_SECTION_NOTIFICATIONS = gql` 
query getMyInstDeptIncidentsByStatusStatistics {
  getMyInstDeptIncidentsByStatusStatistics {
    code
    data{
    ${outGoingStatusFields}
    }
  }
  
}
`;

export const GET_INTERNAL_INCIDENT_NOTIFICATIONS = gql` 
query getInternalNotifications($incidentOrigin : IncidentOrigin ){
  getAllIncidentsByStatusStatistics(incidentOrigin: $incidentOrigin ){
    code
    data{
    ${outGoingStatusFields}
    }
   }
  }
`;
export const GET_DIRECTORATE_ASSIGNED = gql` 
query getDirectorateAssigned{
  getMyInstSectionIncidents(incidentStatus: [ASSIGNED]){
   totalElements
  }
}`

export const GET_DIRECTORATE_OPEN = gql` 
query getDirectorateOpen{
  getMyInstSectionIncidents(incidentStatus: [SUBMITTED, CATEGORIZED, REOPENED, ADDED_NOTE, ADDED_PROGRESS, ADDED_ROOT_CAUSE, ASSIGNED, REASSIGNED, UNASSIGNED]){
   totalElements
  }
}
`;
export const GET_DIRECTORATE_CLOSED = gql` 
query getDirectorateClosed{
  getMyInstSectionIncidents(incidentStatus: [CLOSED]){
   totalElements
  }
}`
export const GET_DIRECTORATE_INCOMMING = gql` 
query getDirectorateIncomming{
  getMyInstSectionIncidents(incidentStatus: [SUBMITTED]){
   totalElements
  }
}`


export const GET_DEPARTMENT_NOTIFICATIONS = gql` 
query getDeptIncidentsByStatusStatistics($departmentUuid: String) {
  getDeptIncidentsByStatusStatistics(departmentUid: $departmentUuid) {
    code
    data{
    ${outGoingStatusFields}
    }
  }
  
}
`;
