import gql from 'graphql-tag';

export const changeManagementFields = `
  id
  affectedServices
  assignments{
    assignedBy
    assignees{
      assignedId
      assignee{
        id
        name
        email
      }
    }
    comment
  }
  attachmentType
  attachments{
    active
    helpdeskAttachable{
      attachmentType
      id
    }
    attachmentTitle
    createdAt
    createdBy
    deleted
    filePath
    updatedAt
    updatedBy
  }
  changeDate
  helpdeskChangeRequestExecution{
    attachmentType
    attachments{
      active
      helpdeskAttachable{
        attachmentType
        id
      }
      attachmentTitle
      createdAt
      createdBy
      deleted
      filePath
      updatedAt
      updatedBy
    }
    changes
    executorChangeStatus
    solution
  }
  completionDate
  currentAssignment{
    assignedBy
    assignees{
      assignedId
      assignee{
        id
        name
        email
      }
    }
    comment
  }
  currentProcess{
    fromStage
    toStage
  }
  description
  email
  fullName
  managerChangeDescription
  managerChangeStatus
  phoneNumber
  processes{
    fromStage
    toStage
  }
  requestType
  requesterChangeDescription
  requesterChangeStatus
  reviews{
    actor
    comment
    reviewDecission
  }
  rollbackPlan
  submittedDate
  managerDetails{
    id
    name
  }
`;

export const changeRequestFields = `
  id
  affectedServices
  assignments{
    assignedBy
    comment
    assignees{
      assignedId
      createdAt
      createdBy
      id
      isLeader
      updatedAt
      updatedBy
      assignee{
        id
        name
        email
      }
    }
    createdAt
    createdBy
    updatedAt
  }
  institutionCode
  institution{
    id
    name
    clientCode
  }
  attachmentType
  attachments{
    active
    helpdeskAttachable{
      attachmentType
      id
    }
    attachmentTitle
    createdAt
    createdBy
    deleted
    filePath
    updatedAt
    updatedBy
  }
  changeDate
  helpdeskChangeRequestExecution{
    active
    attachmentType
    attachments{
      active
      helpdeskAttachable{
        attachmentType
        id
      }
      attachmentTitle
      createdAt
      createdBy
      deleted
      filePath
      updatedAt
      updatedBy
    }
    changes
    createdAt
    createdBy
    executorChangeStatus
    id
    solution
    updatedAt
  }
  completionDate
  currentAssignment{
    assignedBy
    comment
    assignees{
      assignedId
      createdAt
      createdBy
      id
      isLeader
      updatedAt
      updatedBy
      assignee{
        id
        name
        email
      }
    }
    createdAt
    createdBy
    updatedAt
  }
  currentProcess{
    fromStage
    toStage
  }
  description
  email
  fullName
  managerChangeDescription
  managerChangeStatus
  phoneNumber
  processes{
    fromStage
    toStage
  }
  requestType
  requesterChangeDescription
  requesterChangeStatus
  reviews{
    id
    actor
    comment
    reviewDecission
    active
    createdAt
    createdBy
    deleted
    updatedAt
  }
  rollbackPlan
  submittedDate
`;


export const CREATE_CHANGE_REQUEST = gql`
  mutation saveHelpdeskChangeRequest( $HelpdeskChangeRequestDto: HelpdeskChangeRequestDtoInput ){
    saveHelpdeskChangeRequest(HelpdeskChangeRequestDto: $HelpdeskChangeRequestDto) {
      code
      data {
        ${changeRequestFields}
      }
      status
    }
  }
`;

// variables: {
//   requestId: 
//   HelpdeskChangeRequestDto: 
// }

export const UPDATE_CHANGE_REQUEST = gql`
  mutation updateHelpdeskChangeRequest( $requestId: Long, $changeRequestDto: HelpdeskChangeRequestDtoInput ){
    updateHelpdeskChangeRequest( requestId: $requestId, changeRequestDto: $changeRequestDto){
      code
      data{
        ${changeManagementFields}
      }
    }
  }
`;

export const GET_CHANGE_REQUEST_BY_STAGE = gql`
  query getHelpdeskChangeRequestByStage($stage: HelpdeskChangeRequestStage ){
    getHelpdeskChangeRequestByStage(stage: $stage){
      ${changeRequestFields}
    }
  }
`;

export const FIND_CHANGE_REQUEST_BY_EXEMPTED_STAGE = gql`
  query findHelpdeskChangeRequestsStageNotIn($stages: [HelpdeskChangeRequestStage] ){
    findHelpdeskChangeRequestsStageNotIn(stages: $stages){
      ${changeRequestFields}
    }
  }
`;

export const FIND_CHANGE_REQUEST_BY_REQUEST_TYPE = gql`
  query findHelpdeskChangeRequestByRequestType($requestType: RequestType ){
    findHelpdeskChangeRequestByRequestType(requestType: $requestType){
      ${changeRequestFields}
    }
  }
`;

export const GET_CHANGE_REQUEST_BY_STAGE_AND_TYPE = gql`
  query getHelpdeskChangeRequestByStageAndType($stage: HelpdeskChangeRequestStage, $type: RequestType){
    getHelpdeskChangeRequestByStageAndType(stage: $stage, type: $type ){
      ${changeRequestFields}
    }
  }
`;

export const SUBMIT_CHANGE_REQUEST = gql`
  mutation submitHelpdeskChangeRequest( $requestId: Long ){
    submitHelpdeskChangeRequest( requestId: $requestId ){
      code
      data{
        ${changeManagementFields}
      }
      status
    }
  }
`;

export const MANAGER_REVIEW_CHANGE_REQUEST = gql`
  mutation managerReviewHelpdeskChangeRequest( $reviewDto: ReviewDtoInput, $requestId: Long, $HelpdeskChangeRequestDto: HelpdeskChangeRequestDtoInput ){
    managerReviewHelpdeskChangeRequest( reviewDto : $reviewDto, requestId : $requestId, HelpdeskChangeRequestDto : $HelpdeskChangeRequestDto ){
      code
      data{
        ${changeManagementFields}
      }
      status
    }
  }
`;

export const DIRECTOR_REVIEW_CHANGE_REQUEST = gql`
  mutation directorReviewHelpdeskChangeRequest( $reviewDto: ReviewDtoInput, $requestId: Long ){
    directorReviewHelpdeskChangeRequest( reviewDto : $reviewDto, requestId : $requestId ){
      code
      data{
        ${changeManagementFields}
      }
      status
    }
  }
`;

export const ASSIGN_CHANGE_REQUEST_TO_EXECUTOR = gql`
  mutation assignHelpdeskChangeRequestToExecutor( $executorAssignmentDto: ExecutorAssignmentDtoInput){
    assignHelpdeskChangeRequestToExecutor( executorAssignmentDto : $executorAssignmentDto ){
      code
      data{
        ${changeManagementFields}
      }
      status
    }
  }
`;

export const EXECUTE_CHANGE_REQUEST = gql`
  mutation executeHelpdeskChangeRequest( $requestId: Long, $executionDto: ChangeRequestExecutionDtoInput){
    executeHelpdeskChangeRequest( requestId : $requestId, executionDto : $executionDto  ){
      code
      data{
        ${changeManagementFields}
      }
      status
    }
  }
`;

export const SEND_FEEDBACK_TO_EXECUTOR = gql`
  mutation sendHelpdeskFeedbackToRequester( $requestId: Long, $description: String, $changeStatus: ChangeStatus){
    sendHelpdeskFeedbackToRequester( requestId : $requestId, description : $description, changeStatus : $changeStatus  ){
      code
      data{
        ${changeManagementFields}
      }
      status
    }
  }
`;

export const CLOSE_CHANGE_REQUEST = gql`
  mutation closeHelpdeskChangeRequest( $requestId: Long, $remarks: String, $changeStatus: ChangeStatus){
    closeHelpdeskChangeRequest( requestId : $requestId, remarks : $remarks, changeStatus : $changeStatus  ){
      code
      data{
        ${changeManagementFields}
      }
      status
    }
  }
`;

export const COUNTS_CHANGE_REQUEST_BY_TYPE = gql`
  query countHelpdeskChangeRequestByType{
    major: countHelpdeskChangeRequestByType( type: MAJOR ),
    minor: countHelpdeskChangeRequestByType( type: MINOR ),
    routine: countHelpdeskChangeRequestByType( type: ROUTINE ),
    emergence: countHelpdeskChangeRequestByType( type: EMERGENT )
  }
`;

export const COUNTS_CHANGE_REQUEST_BY_STAGE = gql`
query countChangeHelpdeskRequestsInStage{
  closed: countHelpdeskChangeRequestsInStage( stage: CLOSED ),
}
`;

export const READ_ATTACHMENT = gql`
  query readHelpdeskAttachment( $attachmentType: HelpdeskAttachmentType, $filePath: String){
    readHelpdeskAttachment( attachmentType : $attachmentType, filePath : $filePath ){
      code
      data {
        data
        filePath
      }
    }
  }
`;

// export const LIST_ALL_CHANGE_REQUEST = gql`
//   query allIncidentList{
//     allIncidentList{
//       ${changeManagementFields}
//     }
//   }
// `;

// export const GET_CHANGE_REQUEST_BY_ID = gql`
//   query departmentById($id: Long){
//     departmentById(id: $id){
//       code
//       data{
//         ${changeManagementFields}
//       }
//     }
//   }
// `;

// export const GET_CHANGE_REQUEST_BY_STAGE_AND_TYPE = gql`
//   query getChangeRequestByStageAndType($id: Long){
//     getChangeRequestByStageAndType(id: $id){
//       code
//       data{
//         ${changeManagementFields}
//       }
//     }
//   }
// `;

// export const FIND_CHANGE_REQUEST_BY_REQUEST_TYPE = gql`
//   query findChangeRequestByRequestType($id: Long){
//     findChangeRequestByRequestType(id: $id){
//       code
//       data{
//         ${changeManagementFields}
//       }
//     }
//   }
// `;

// export const FIND_CHANGE_REQUEST_BY_REQUEST_AND_ACTOR = gql`
//   query findReviewsByChangeRequestAndActor($id: Long){
//     findReviewsByChangeRequestAndActor(id: $id){
//       code
//       data{
//         ${changeManagementFields}
//       }
//     }
//   }
// `;

// export const ASSIGN_CHANGE_REQUEST_TO_EXECUTOR = gql`
//   query assignChangeRequestToExecutor($id: Long){
//     assignChangeRequestToExecutor(id: $id){
//       code
//       data{
//         ${changeManagementFields}
//       }
//     }
//   }
// `;

