<mat-card>
  <mat-card-content>
<!--    <div *ngIf="data.file && (data.format === 'png' || data.format === 'jpeg')">-->
<!--      <img src="data:image/{{data.format}};base64,{{data.file}}" style="width: 100%;height: 500px; margin-top: 20px" alt="">-->
<!--    </div>-->
    <div *ngIf="data.file">
<!--      <ngx-extended-pdf-viewer [src]="base64"-->
<!--                               [showZoomButtons]="true"-->
<!--                               [useBrowserLocale]="true"-->
<!--      >-->
<!--      </ngx-extended-pdf-viewer>-->
<!--      <ng2-pdfjs-viewer pdfSrc="data:application/pdf;base64,{{data.file}}"></ng2-pdfjs-viewer>-->
<!--      <ngx-extended-pdf-viewer base64Src="data:application/pdf;base64,{{data.file}}" height="80vh"></ngx-extended-pdf-viewer>-->
      <div class="row" style="height: 40px;">
        <div class="container-fluid">
<!--          <button class="fa-pull-left" mat-icon-button (click)="downloadFile()">-->
<!--            <mat-icon style="color: white">get_app</mat-icon>-->
<!--          </button>-->
          &nbsp;
          <button class="fa-pull-right" mat-button (click)="closeDialog()">
            <mat-icon>close</mat-icon>
<!--            <span> Close</span>-->
          </button>
        </div>
      </div>
<!--      <pdf-viewer src="data:application/pdf;base64,{{data.file}}"></pdf-viewer>-->
      <div style="height: 85vh">
        <object [data]="base64 | safeResource" width="100%" height="100%" title="HELLO TANZANIA">
        </object>
      </div>
    </div>

    <div *ngIf="!(data.file)"> No Attachment</div>
  </mat-card-content>
</mat-card>

