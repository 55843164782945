import { Injectable } from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { SettingsService } from 'src/app/services/settings.service';
import { FieldConfig, FieldType, Validator } from './field.interface';

@Injectable({ providedIn: 'root' })
export class DynamicFormService {

  constructor(private formBuilder: FormBuilder, private settings: SettingsService) { }

  selectedKeyValue: { [key: string]: any } = {};

  isVisible(field: FieldConfig, form?: UntypedFormGroup, option?: any): boolean {


    if (option) {
      this.selectedKeyValue[field.key as string] = option;
    }
    const conditions: { [key: string]: any } = {};
    let visible = true;
    const allVisibleResults: boolean[] = []; // true , false
    let joiningValues: any[] = []; //  }&&{ for && , }||{ for ||
    if (field.showWhen) {
      let showWhen: string[] = [];
      const showWhenWithoutSpace = field.showWhen.replace(/\s/g, ''); // removing all whitespace
      showWhen = showWhenWithoutSpace.match(/({([^}]*)})/g) || []; // get string inside {}
      joiningValues = showWhenWithoutSpace.match(/\}(.*?)\{/g) || [];  // get string inside }{
      let count = 0;

      showWhen.forEach((item: string) => {
        conditions[count] = item.match(/\((.*?)\)/g); // get string inside ()

        conditions[count] = conditions[count].map((el: string) =>
          el.replace('(', '').replace(')', '') // Removing ()
        );
        const conditionObjectArr = conditions[count][0].split('.'); // check if key contains '.' meaning its a object

        let controlValue = null;

        if (conditionObjectArr.length === 1) {
          controlValue = form?.get(conditionObjectArr[0])?.value;
        } else if (conditionObjectArr.length === 2 && Object.keys(this.selectedKeyValue).length > 0) {
          controlValue = this.selectedKeyValue[conditionObjectArr[0]][conditionObjectArr[1]];
        }

        const matchControlValue: string =
          conditions[count][2] === 'true'
            ? true
            : conditions[count][2] === 'false'
              ? false
              : conditions[count][2] === 'null'
                ? null
                : conditions[count][2];


        visible = this.isValid(controlValue, matchControlValue, conditions[count][1]); // Checking for Conditions Validity

        allVisibleResults.push(visible);
        count++;
      });
      let joinValCount = 0;
      joiningValues.forEach(joinValue => {
        if (joinValue === '}&&{') {
          visible = allVisibleResults[joinValCount] && allVisibleResults[joinValCount + 1];
        }
        if (joinValue === '}||{') {
          visible = allVisibleResults[joinValCount] || allVisibleResults[joinValCount + 1];
        }
        joinValCount = joinValCount + 2;
      });
    }
    return visible;
  }

  isValid(controlValue: any[] | any, matchControlValue: string, relationalOperatorName: string) {
    const isMultiple = Array.isArray(controlValue);
    let isValid = false;
    switch (relationalOperatorName) {
      case '==':
        isValid = isMultiple ? controlValue?.includes(matchControlValue) : controlValue === matchControlValue;
        break;
      case '!=':
        isValid = isMultiple ? !controlValue?.includes(matchControlValue) : controlValue !== matchControlValue;
        break;
      case '>':
        isValid = parseFloat(controlValue) > parseFloat(matchControlValue);
        break;
      case '<':
        isValid = parseFloat(controlValue) < parseFloat(matchControlValue);
        break;
      case '>=':
        isValid = parseFloat(controlValue) >= parseFloat(matchControlValue);
        break;
      case '<=':
        isValid = parseFloat(controlValue) <= parseFloat(matchControlValue);
        break;
    }


    return isValid;
  }



  // Creating Form Control and Assigning Initial Values
  createControl(fields: FieldConfig[], formData: any) {
    const group: any = {};
    fields.forEach((field) => {
      if (field.type === 'button') {
        return;
      }
      const key = field.key;
      let initialValue = formData ? formData[key] : this.settings.isNullOrUndefined(field.value) ? null : field.value;
      if (field.type === FieldType.checkbox) {
        initialValue = initialValue || false;
      } else if (field.inputType === 'number') {
        initialValue = initialValue || 0;
      }
      group[key] = new UntypedFormControl({ value: initialValue, disabled: field.disabled || false }, this.bindValidations(field.validations || []));
    });
    return new UntypedFormGroup(group);
  }

  bindValidations(validations: any[]) {
    if (validations.length > 0) {
      const validList: any[] = [];
      validations.forEach((valid) => {
        validList.push(valid.validator);
      });
      return Validators.compose(validList);
    }
    return null;
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      control?.markAsTouched({ onlySelf: true });
    });
  }

  onSubmit(event: Event, form?: UntypedFormGroup, fields?: FieldConfig[]) {
    event.preventDefault();
    event.stopPropagation();
    if (form) {
      if (form?.valid) {
        const data = form.value;
        fields?.forEach(field => {
          if (field.type === FieldType.date) {
            data[field.key] = this.settings.formatDate(data[field.key], field.dateFormat);
          }
        });
        return data;
      } else {
        this.validateAllFormFields(form);
      }
    }
  }
}
