import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output, forwardRef, OnChanges } from '@angular/core';
import { AbstractControl,UntypedFormGroup, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { FieldConfig } from '../field.interface';
@Component({
  selector: 'app-date',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateComponent),
      multi: true
    }
  ],
  template: `
    <ng-container *ngIf="field">
<mat-form-field *ngIf="visible" class="demo-full-width margin-top" [formGroup]="group" appearance="outline">
<mat-label> {{ field.label }} </mat-label>
<input
  (dateChange)="fieldChange($event.value)"
  [required]="field.key && group?.get(field.key)?.errors !== null && group?.get(field.key)?.errors?.['required']"
  matInput [matDatepicker]="picker"
  [formControlName]="field.key||null"
  [placeholder]="field.label || ''"
  [min]="field.minDate || null"
  [max]="field.maxDate || null"
  readonly>
<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
<mat-datepicker #picker></mat-datepicker>
<mat-hint align="start"><strong>{{field.hint}}</strong> </mat-hint>
<ng-container *ngFor="let validation of field.validations;" ngProjectAs="mat-error">
<mat-error *ngIf="field.key && group?.get(field.key)?.hasError(validation.name)">{{validation.message}}</mat-error>
</ng-container>
</mat-form-field>
    </ng-container>
`,
  // host: {'class': 'col-md-6'},
  styles: []
})
export class DateComponent implements OnInit, OnChanges {
  @Input() field?: FieldConfig;
  group: UntypedFormGroup = new UntypedFormGroup({});
  @Output() fieldValue = new EventEmitter();
  visible = true;
  value: any;
  disabled = false;
  onChange: any = () => { };
  onTouched: any = () => { };


  settingValue($event: any): void {
    this.value = $event;
    this.onChange(this.value);
  }
  // @HostBinding('class') rowClass = 'col-md-6';
  constructor() {

  }

  writeValue(value: any): void {
    if (value !== undefined) {
      this.fieldChange(value);
    }
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  ngOnInit() {

    //
    this.group.addControl(this.field?.key || 'id', new UntypedFormControl('', []));
    //  this.rowClass = this.field.rowClass === 'col12' ? 'col-md-12' : 'col-md-6';
  }

  ngOnChanges() {
    if (this.field?.disabled) {
      if (this.field.key) this.group.controls[this.field.key].disable();
    }
  }
  fieldChange(value: any) {
    this.settingValue(value);
    this.fieldValue.emit({ value, field: this.field, object: {} });
  }
  // addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
  //   this.events.push(`${type}: ${event.value}`);
  // }
}
