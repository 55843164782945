import {createSelector} from '@ngrx/store';
import {AppState} from './../../reducers/index';
import * as fromRosterReducer from './roster.reducer';
import {Roster} from './roster.model';
import {DatePipe} from '@angular/common';
import {UserInfoPipe} from '../../../shared/pipes/user-by-id.pipe';
import {Apollo} from 'apollo-angular';

export const currentState = (state: AppState) => (state[fromRosterReducer.rostersFeatureKey]);

export const selectAllRosters = createSelector(currentState, fromRosterReducer.selectAll);

export const selectTotalRosters = createSelector(currentState, fromRosterReducer.selectTotal);

export const selectModifiedRosters = createSelector(selectAllRosters, (rosters: Roster[]) => {
    return rosters.map((roster) => {
        let officers = '';
        roster?.officers?.forEach(offc => {

            officers = officers + offc.fullName + ', ';
        });
        return {
            ...roster,
            rosterid: roster?.id,
            totalUsers: roster?.officers?.length,
            departmentName: roster?.department?.departmentName,
            startDateFormatted: new DatePipe('en-GB').transform(roster?.startDate, 'dd MMM yyyy HH:mm'),
            endDateFormatted: new DatePipe('en-GB').transform(roster?.endDate, 'dd MMM yyyy HH:mm'),
            modCreatedAt: new DatePipe('en-GB').transform(roster?.createdAt, 'dd MMM yyyy HH:mm'),
            rosterNo: roster?.rosterNumber,
            officerNames: officers, 
        };
    });
});

export const selectRosterById = (params: { id: number }) => createSelector(
    selectModifiedRosters,
    (rosters: Roster[]) => {
        return rosters.find((roster) => roster.id === params.id);
    }
);

