import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { RosterGroups } from './roster-groups.model';
import * as RosterGroupsActions from './roster-groups.actions';

export const rosterGroupsesFeatureKey = 'rosterGroupses';

export interface State extends EntityState<RosterGroups> {
  // additional entities state properties
}

export const adapter: EntityAdapter<RosterGroups> = createEntityAdapter<RosterGroups>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(RosterGroupsActions.addRosterGroups,
    (state, action) => adapter.addOne(action.rosterGroups, state)
  ),
  on(RosterGroupsActions.upsertRosterGroups,
    (state, action) => adapter.upsertOne(action.rosterGroups, state)
  ),
  on(RosterGroupsActions.addRosterGroupss,
    (state, action) => adapter.addMany(action.rosterGroupss, state)
  ),
  on(RosterGroupsActions.upsertRosterGroupss,
    (state, action) => adapter.upsertMany(action.rosterGroupss, state)
  ),
  on(RosterGroupsActions.updateRosterGroups,
    (state, action) => adapter.updateOne(action.rosterGroups, state)
  ),
  on(RosterGroupsActions.updateRosterGroupss,
    (state, action) => adapter.updateMany(action.rosterGroupss, state)
  ),
  on(RosterGroupsActions.deleteRosterGroups,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(RosterGroupsActions.deleteRosterGroupss,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(RosterGroupsActions.loadRosterGroupss,
    (state, action) => adapter.setAll(action.rosterGroupss, state)
  ),
  on(RosterGroupsActions.clearRosterGroupss,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
