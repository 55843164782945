<app-modal-header
  (closeModal)="closeModal($event)"
  [title]="title"
></app-modal-header>

<ng-container *ngIf="dialogType === 'newUser' || dialogType === 'editUser'">
  <form
    (ngSubmit)="submitForm(userAccountForm.value)"
    [formGroup]="userAccountForm"
    autocomplete="off"
    novalidate
  >
    <mat-dialog-content>
      <div class="row">
        <div class="col-md-4">
          <mat-form-field appearance="outline" class="material-form">
            <mat-label>First Name</mat-label>
            <input
              formControlName="firstName"
              matInput
              placeholder="First Name"
            />
            <mat-error>
              <span
                *ngIf="
                  !userAccountForm.get('firstName').valid &&
                  userAccountForm.get('firstName').touched
                "
              >
                First Name is Required
              </span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field appearance="outline" class="material-form">
            <mat-label>Middle Name</mat-label>
            <input
              formControlName="middleName"
              matInput
              [required]="false"
              placeholder="Middle Name"
            />
            <!-- <mat-error>
                            <span *ngIf="!userAccountForm.get('middleName').valid && userAccountForm.get('middleName').touched">

                            </span>
                        </mat-error> -->
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field appearance="outline" class="material-form">
            <mat-label>Last Name</mat-label>
            <input
              formControlName="lastName"
              matInput
              placeholder="Last Name"
            />
            <mat-error>
              <span
                *ngIf="
                  !userAccountForm.get('lastName').valid &&
                  userAccountForm.get('lastName').touched
                "
              >
                Last Name is Required
              </span>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="currentTabIndex === 0" class="row">
        <div class="col-md-6">
          <mat-form-field appearance="outline" class="material-form">
            <mat-label>Designation</mat-label>
            <mat-select formControlName="designationId">
              <mat-option
                *ngFor="let des of designations$ | async"
                [value]="des.id"
              >
                {{ des.designationName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field appearance="outline" class="material-form">
            <mat-label>Department</mat-label>
            <mat-select formControlName="departmentId">
              <mat-option
                *ngFor="let dep of department$ | async"
                [value]="dep.id"
              >
                {{ dep.departmentName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="row d-flex flex-row">
            <div *ngIf="countryLists" class="col-md-4">
              <mat-form-field appearance="outline" class="material-form">
                <mat-label>Country</mat-label>
                <mat-select
                  (selectionChange)="resetPhoneNumber($event)"
                  formControlName="countryCode"
                >
                  <mat-option
                    *ngFor="let country of countryLists"
                    value="{{ country.code }}"
                    >{{ country.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-8">
              <mat-form-field appearance="outline" class="material-form">
                <mat-label>Phone Number</mat-label>
                <div class="d-flex align-items-baseline">
                  <span matPrefix>{{ countryCode }} &nbsp;</span>
                  <input
                    (input)="formatPhoneNumber($event)"
                    digitOnly
                    formControlName="phone"
                    matInput
                    type="number"
                  />
                </div>

                <mat-error>
                  <span
                    *ngIf="
                      !userAccountForm.get('phone').valid &&
                      userAccountForm.get('phone').touched &&
                      userAccountForm.get('phone').errors?.required
                    "
                  >
                    Phone Number is Required
                  </span>

                  <span
                    *ngIf="
                      !userAccountForm.get('phone').valid &&
                      userAccountForm.get('phone').hasError('minlength')
                    "
                  >
                    Phone number should have minimum of 12 digits
                  </span>
                  <span
                    *ngIf="
                      !userAccountForm.get('phone').valid &&
                      userAccountForm.get('phone').hasError('maxlength')
                    "
                  >
                    Phone number should have minimum of 13 digits
                  </span>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <mat-form-field appearance="outline" class="material-form">
            <mat-label>Email Address</mat-label>
            <input
              formControlName="email"
              matInput
              placeholder="Email Address"
            />
            <mat-error>
              <span
                *ngIf="
                  !userAccountForm.get('email').valid &&
                  userAccountForm.get('email').touched
                "
              >
                Email Address is Required
              </span>
              <span
                *ngIf="
                  !userAccountForm.get('email').valid &&
                  userAccountForm.get('email').touched
                "
              >
                Enter a valid email address
              </span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field appearance="outline" class="material-form">
            <mat-label>Check-Number</mat-label>
            <input
              formControlName="checkNumber"
              matInput
              placeholder="Chek Number"
            />
            <mat-error>
              <span
                *ngIf="
                  !userAccountForm.get('checkNumber').valid &&
                  userAccountForm.get('checkNumber').touched &&
                  !userAccountForm.get('checkNumber').hasError('minlength')
                "
              >
                Chek-Number is Required
              </span>
              <small
                *ngIf="userAccountForm.get('checkNumber').hasError('minlength')"
                class="text-danger"
              >
                The minimum length for this field is
                {{
                  userAccountForm.get("checkNumber").errors.minlength
                    .requiredLength
                }}
                characters.
              </small>
            </mat-error>
          </mat-form-field>
        </div>
        <!-- <div class="col-md-4">
                    <mat-form-field appearance="outline" class="material-form">
                        <mat-label>Branch</mat-label>
                        <mat-select formControlName="branchUuid">
                            <mat-option *ngFor="let bra of branch$ | async" [value]="bra.uuid">
                                {{bra.branchName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div> -->

        <div class="col-md-12">
            <mat-form-field appearance="outline" class="material-form">
                <mat-label>Occupation</mat-label>
                <mat-select formControlName="occupationId">
                    <mat-option *ngFor="let group of tascoGroups$ | async" [value]="group.uuid">
                        {{group.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

      </div>
    </mat-dialog-content>
    <div class="row">
      <div class="col-md-12">
        <div class="save-right">
          <button
            [disabled]="!userAccountForm.valid"
            class="custom-btn green-btn"
            mat-raised-button
            type="submit"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</ng-container>

<ng-container *ngIf="dialogType === 'importUser'">
  <div class="row">
    <div class="col-md-12">
      <form
        (ngSubmit)="uploadUserFile()"
        [formGroup]="importForm"
        autocomplete="off"
        novalidate
      >
        <div class="row">
          <div class="col-md-6">
            <mat-form-field appearance="outline" class="material-form">
              <mat-label>User type</mat-label>
              <mat-select
                (selectionChange)="selectedUserType($event.value)"
                formControlName="type"
              >
                <mat-option *ngFor="let svc of types" value="{{ svc }}">{{
                  svc
                }}</mat-option>
              </mat-select>
              <mat-error>
                <span
                  *ngIf="
                    !importForm.get('type').valid &&
                    importForm.get('type').touched
                  "
                >
                  This field is required.
                </span>
              </mat-error>
            </mat-form-field>
          </div>
          <ng-container *ngIf="importForm.value.type == 'Client'">
            <div *ngIf="services$ | async as services" class="col-md-6">
              <mat-form-field appearance="outline" class="material-form">
                <mat-label>Select Service</mat-label>
                <mat-select formControlName="serviceUuids" multiple>
                  <app-mat-select-search
                    formControlName="searchValue"
                  ></app-mat-select-search>
                  <mat-option
                    *ngFor="
                      let svc of services
                        | search
                          : 'name,code'
                          : importForm.get('searchValue').value
                    "
                    [value]="svc?.uuid"
                    >{{ svc.name }}</mat-option
                  >
                </mat-select>
                <mat-error>
                  <span
                    *ngIf="
                      !importForm.get('serviceUuids').valid &&
                      importForm.get('serviceUuids').touched
                    "
                  >
                    This field is required.
                  </span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field appearance="outline" class="material-form">
                <mat-label>Batch number</mat-label>
                <input
                  formControlName="batchNumber"
                  matInput
                  placeholder="Batch number"
                />
                <mat-error>
                  <span
                    *ngIf="
                      !importForm.get('batchNumber').valid &&
                      importForm.get('batchNumber').touched
                    "
                  >
                    Batch number is Required
                  </span>
                </mat-error>
              </mat-form-field>
            </div>
          </ng-container>
          <div class="col-md-6">
            <input
              #fileInput
              (change)="onFileSelected($event)"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              style="display: none"
              type="file"
            />
            <button
              (click)="fileInput.click()"
              class="mb-2"
              color="primary"
              style="color: #a7a6a6"
              mat-raised-button
              type="button"
            >
              <i class="material-icons">attach_file</i>
              Attach Excel File
            </button>
            <br />
            <mat-hint
              *ngIf="fileName"
              class="my-2 py-3"
              style="font-size: 12px; font-style: italic"
              >{{ fileName }} - {{ fileSize }}</mat-hint
            >
          </div>
        </div>

        <div class="my-2 row">
          <div class="col-md-12">
            <div class="save-right">
              <button
                [disabled]="!importForm.valid"
                class="custom-btn green-btn"
                mat-raised-button
                type="submit"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div *ngIf="UserFromOtherSystemDto.length > 0" class="col-md-12">
      <app-user-import-table
        [tableData]="UserFromOtherSystemDto"
      ></app-user-import-table>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="dialogType === 'importUserReview'">
  <div class="row">
    <div class="col-md-12">
      <mat-card>
        <mat-selection-list
          #shoes
          [multiple]="false"
          style="
            overflow-y: scroll;
            font-size: 14px;
            font-family: Roboto, 'Helvetica Neue', sans-serif;
          "
        >
          <mat-list-option *ngFor="let shoe of failedAccounts" [value]="shoe">
            {{ shoe.emailOrId }}
          </mat-list-option>
        </mat-selection-list>
      </mat-card>
    </div>
  </div>
</ng-container>
