import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../../reducers/index';
import * as fromClientIdentificationReducer from './client-identification.reducer';
import { ClientIdentification } from './client-identification.model';

export const currentState = (state: AppState) => (state[fromClientIdentificationReducer.clientIdentificationFeatureKey]);

export const selectAllClientIdentifications = createSelector(currentState, fromClientIdentificationReducer.selectAll);

export const selectTotalClientIdentifications = createSelector(currentState, fromClientIdentificationReducer.selectTotal);

export const selectModifiedClientIdentifications = createSelector(selectAllClientIdentifications, (clients) => {
  return clients.map((client: any) => {
    return {
      ...client,
      serviceName: client?.institutionService !=null ?client?.institutionService.name : 'All',
      clientName:client?.clientInstitution?.name
    };
  });
});

