import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {FieldConfig} from '../field.interface';

@Component({
  selector: 'app-date-range',
  template: `
    <mat-form-field *ngIf="visible" class="example-form-field" [formGroup]="group" appearance="outline">
      <mat-label>{{ field?.label }} </mat-label>
      <mat-date-range-input [rangePicker]="dateRangePicker">
        <input matStartDate (dateChange)="setStart($event)"
               [formControlName]="field?.dateRangeFirstKey?? null"
               [placeholder]="field?.dateRangeFirstLabel" readonly>
        <input matEndDate (dateChange)="setEnd($event)"
               [formControlName]="field?.dateRangeLastKey?? null"
               [placeholder]="field?.dateRangeLastLabel" readonly>
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="dateRangePicker"></mat-datepicker-toggle>
      <mat-date-range-picker #dateRangePicker></mat-date-range-picker>
      <mat-hint align="start"><strong>{{field?.hint}}</strong></mat-hint>
      <ng-container *ngFor="let validation of field?.validations;" ngProjectAs="mat-error">
        <mat-error *ngIf="field?.dateRangeFirstKey && group.get(field?.dateRangeFirstKey!)?.hasError(validation.name)">{{validation.message}}</mat-error>
        <mat-error *ngIf="field?.dateRangeLastKey && group.get(field?.dateRangeLastKey!)?.hasError(validation.name)">{{validation.message}}</mat-error>
      </ng-container>
    </mat-form-field>
  `,
  // host: {'class': 'col-md-6'},
  styles: []
})
export class DateRangeComponent implements OnInit {
 @Input() field?: FieldConfig;
 @Input() group: UntypedFormGroup = new UntypedFormGroup({});
  visible = true;
  startDateValue: any;
  endDateValue: any;
  // @HostBinding('class') rowClass = 'col-md-6';
  constructor() {
  }
  ngOnInit() {
  }

  setStart($event: any) {
    this.startDateValue = $event;
  }
  setEnd($event: any) {
    this.endDateValue = $event;
  }
}
