import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { TascoIsceGroup } from './tasco-isce-group.model';
import * as TascoIsceGroupActions from './tasco-isce-group.actions';

export const tascoIsceGroupsFeatureKey = 'tascoIsceGroups';

export interface State extends EntityState<TascoIsceGroup> {
  // additional entities state properties
}

export const adapter: EntityAdapter<TascoIsceGroup> = createEntityAdapter<TascoIsceGroup>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export const reducer = createReducer(
  initialState,
  on(TascoIsceGroupActions.addTascoIsceGroup,
    (state, action) => adapter.addOne(action.tascoIsceGroup, state)
  ),
  on(TascoIsceGroupActions.upsertTascoIsceGroup,
    (state, action) => adapter.upsertOne(action.tascoIsceGroup, state)
  ),
  on(TascoIsceGroupActions.addTascoIsceGroups,
    (state, action) => adapter.addMany(action.tascoIsceGroups, state)
  ),
  on(TascoIsceGroupActions.upsertTascoIsceGroups,
    (state, action) => adapter.upsertMany(action.tascoIsceGroups, state)
  ),
  on(TascoIsceGroupActions.updateTascoIsceGroup,
    (state, action) => adapter.updateOne(action.tascoIsceGroup, state)
  ),
  on(TascoIsceGroupActions.updateTascoIsceGroups,
    (state, action) => adapter.updateMany(action.tascoIsceGroups, state)
  ),
  on(TascoIsceGroupActions.deleteTascoIsceGroup,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(TascoIsceGroupActions.deleteTascoIsceGroups,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(TascoIsceGroupActions.loadTascoIsceGroups,
    (state, action) => adapter.setAll(action.tascoIsceGroups, state)
  ),
  on(TascoIsceGroupActions.clearTascoIsceGroups,
    state => adapter.removeAll(state)
  ),
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
