<div class="row">

  <div class="col-md-3">
    <ng-container *ngIf="pageSizeOptions?.length > 0">

      <div class="col-md-12">
<!--        <span class="mat-paginator-page-size-label"> Items per page: </span>-->

        <mat-form-field class="mat-paginator-page-size-select mr-2 mat-primary mat-form-field-appearance-legacy">
          <mat-label>Size</mat-label>
          <mat-select>
            <mat-option (onSelectionChange)="setPageSize(pageOption)" *ngFor="let pageOption of pageSizeOptions"  [value]="pageOption">
              {{pageOption | number}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="mat-paginator-page-size-select mat-primary mat-form-field-appearance-legacy">
          <mat-label>Go to page</mat-label>
            <input (keyup)="setPage(currentPage)" [(ngModel)]="currentPage" matInput max="{{totalPage}}" min="0" type="number" >
        </mat-form-field>
      </div>

    </ng-container>
  </div>

  <div class="col-md-9">
    <nav aria-label="">
      <ul *ngIf="pagination.length > 0" class="pagination justify-content-end">
        <ng-container *ngFor="let page of pagination; let i = index">
          <li class="page-item px-1">

            <button (click)="setPage(page - 1)" *ngIf="page !== 0" [disabled]="page === '...'" class="page-link min-fab-white {{ (page - 1) === currentPage? 'active': ''}}" mat-mini-fab>
              <ng-container *ngIf="page === '...'">  ...  </ng-container>
              <ng-container *ngIf="page !== '...'">
                <ng-container *ngIf="page - 1  === 0">  1 </ng-container>
                <ng-container *ngIf="page - 1  !== 0">   {{page }}  </ng-container>
<!--                <ng-container *ngIf="page - 1  === 0">  <mat-icon>home</mat-icon> </ng-container>-->
<!--                <ng-container *ngIf="page - 1  !== 0">   {{page - 1}}  </ng-container>-->
              </ng-container>
            </button>

          </li>

        </ng-container>
      </ul>
    </nav>
  </div>
</div>

