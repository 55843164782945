import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { AdministrativeArea } from './administrative-area.model';
import * as AdministrativeAreaActions from './administrative-area.actions';

export const administrativeAreasFeatureKey = 'administrativeAreas';

export interface State extends EntityState<AdministrativeArea> {
  // additional entities state properties
}

export const adapter: EntityAdapter<AdministrativeArea> = createEntityAdapter<AdministrativeArea>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(AdministrativeAreaActions.addAdministrativeArea,
    (state, action) => adapter.addOne(action.administrativeArea, state)
  ),
  on(AdministrativeAreaActions.upsertAdministrativeArea,
    (state, action) => adapter.upsertOne(action.administrativeArea, state)
  ),
  on(AdministrativeAreaActions.addAdministrativeAreas,
    (state, action) => adapter.addMany(action.administrativeAreas, state)
  ),
  on(AdministrativeAreaActions.upsertAdministrativeAreas,
    (state, action) => adapter.upsertMany(action.administrativeAreas, state)
  ),
  on(AdministrativeAreaActions.updateAdministrativeArea,
    (state, action) => adapter.updateOne(action.administrativeArea, state)
  ),
  on(AdministrativeAreaActions.updateAdministrativeAreas,
    (state, action) => adapter.updateMany(action.administrativeAreas, state)
  ),
  on(AdministrativeAreaActions.deleteAdministrativeArea,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(AdministrativeAreaActions.deleteAdministrativeAreas,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(AdministrativeAreaActions.loadAdministrativeAreas,
    (state, action) => adapter.setAll(action.administrativeAreas, state)
  ),
  on(AdministrativeAreaActions.clearAdministrativeAreas,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
