import gql from "graphql-tag";

export const severityFields = `
    id
    title
    uuid
    active
    createdAt
    createdBy
    deleted
    description
    incidentList{
        id
        incidentNo
        uuid
    }
    institutionId
    institutionUuid
    updatedAt
    updatedBy
`;

export const LIST_ALL_SEVERITIES_PAGEABLE = gql`
    query getAllInstitutionSeverities( $pageparam: PageableParamInput){
        getAllInstitutionSeverities( pageparam: $pageparam){
            content{${severityFields}}
            first
            last
            number
            numberOfElements
            pageable{
              pageNumber
              pageSize
            }
            size
            totalElements
            totalPages
        }
    }
`;

export const GET_SEVERITY_BY_INSTITUTION = gql`
    query getSeveritiesByInstitution($pageparam: PageableParamInput, $institutionUuid: String){
        getSeveritiesByInstitution(pageparam: $pageparam, institutionUuid: $institutionUuid){
            content{${severityFields}}
            first
            last
            number
            numberOfElements
            pageable{
              pageNumber
              pageSize
            }
            size
            totalElements
            totalPages
        }
    }
`;

export const SAVE_SEVERITY = gql`
    mutation addUpdateSeverity($severity: SeverityDtoInput){
        addUpdateSeverity(severity: $severity){
            code
            data{
                ${severityFields}
            }
        }
    }
`;

export const UPDATE_SEVERITY = gql`
    mutation updateSeverityById($severityDto: SeverityDtoInput, $severityId: Long!) {
        updateSeverityById(severityDto: $severityDto, severityId: $severityId) {
            code
            data{
                ${severityFields}
            }
        }
    }
`;

export const DELETE_SEVERITY = gql`
  mutation deleteSeverityById($severityId: Long!) {
    deleteSeverityById(severityId: $severityId)
  }
`;

export const SELECT_ALL_SEVERITY_BY_INSTITUTION = gql`
  query getSeveritiesByInstitution($pageparam: PageableParamInput, $institutionUuid: String) {
    getSeveritiesByInstitution(pageparam: $pageparam, institutionUuid: $institutionUuid) {
      content {
        title
        uuid
      }
    }
  }
`;
