
import gql from 'graphql-tag';
import { institutionFields } from '../institution/institution.graphql';
import { userInfoFields } from '../user/user.graphql';

export const ApprovalStageFields = `
active
createdAt
createdBy
deleted
description
id
institution{
    ${institutionFields}
}
responsibleUsers{
    ${userInfoFields}
}

title
updatedAt
updatedBy
uuid
`;
// userApprovalStages

export const LIST_ALL_APPROVAL_STAGES_BY_INSTITUTION_ID = gql`
query getAllInstitutionApprovalStageByInstitution($institutionId: Long, $pageParam: PageableParamInput){
    getAllInstitutionApprovalStageByInstitution(institutionId: $institutionId, pageParam:$pageParam){
      content{
          ${ApprovalStageFields}
      }
      first
      last
      number
      numberOfElements
      pageable{
          pageNumber
          pageSize
      }
      size
      totalElements
      totalPages
    }
  }
`;



export const LIST_ALL_APPROVAL_STAGES = gql`
query getAllInstitutionApprovalStage($pageParam: PageableParamInput){
    getAllInstitutionApprovalStage(pageParam:$pageParam){
      content{
          ${ApprovalStageFields}
      }
      first
      last
      number
      numberOfElements
      pageable{
          pageNumber
          pageSize
      }
      size
      totalElements
      totalPages
    }
  }
`;


export const GET_APPROVAL_STAGE_BY_ID = gql`
  query getApprovalStageByUuid( $uuid: String ){
    getApprovalStageByUuid (uuid: $uuid){
        code
        data{
         ${ApprovalStageFields}
        }
    }
  }
`;

export const CREATE_APPROVAL_STAGE = gql`
mutation createUpdateApprovalStage( $approvalStage: ApprovalStageDtoInput ){
    createUpdateApprovalStage( approvalStage: $approvalStage ){
        code
        data{
            ${ApprovalStageFields}
        }
    }
}
`;

export const ACTIVATE_DEACTIVATE_APPROVAL_STAGE = gql`
mutation activateDeactivateApprovalStage($id: Long ){
    activateDeactivateApprovalStage(id: $id ){
        code
        data{
            ${ApprovalStageFields}
        }
    }
}
`;

export const DELETE_APPROVAL_STAGE = gql`
mutation deleteApprovalStage($uuid: String ){
    deleteApprovalStage(uuid: $uuid ){
        code
        data {
            ${ApprovalStageFields}
        }
    }
}
`;

export const CREATE_UPDATE_APPROVAL_STAGE_USERS = gql`
mutation addUserToApprovalStage($stageUuid: String, $userUuid: String){
    addUserToApprovalStage(stageUuid:$stageUuid,userUuid:$userUuid){
      code
      data{
        ${ApprovalStageFields}
      }
      dataList{
        ${ApprovalStageFields}
      }
      message
      status
    }
  }
`;


  
  export const DELETE_APPROVAL_STAGE_USERS = gql`
  mutation removeUserFromApprovalStage($stageUuid: String,$userUuid: String,$userToReplaceUuid: String){
    removeUserFromApprovalStage(stageUuid:$stageUuid,userUuid:$userUuid,userToReplaceUuid:$userToReplaceUuid){
        ${ApprovalStageFields}
    }
  }
`;
  
  