import gql from 'graphql-tag';

export const incidentTrailFields = `
  id
  activity
  assignedToEmployeeId
  user{
    id
    departmentCode
    departmentId
    departmentName
    designationId
    designationName
    email
    employeeId
    name
  }
  employeeDetailId
  conversation{
    id
    category
    comment
    createdAt
    userId
    user{
      id
      firstName
      middleName
      lastName
      email
      checkNumber
    }
  }
  createdAt
  description
  incident{
    id
    incidentNo
    description
  }
  problem{
    id
    description
  }
  response{
    id
    responseDescription
  }
`;

export const INCIDENT_TRANSACTION_BY_ID = gql`
query incidentTransactionListByIncident($incidentId: Long){
  incidentTransactionListByIncident(incidentId: $incidentId) {
    ${incidentTrailFields}
  }
}
`;

