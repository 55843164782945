import gql from 'graphql-tag';
import { basePageableGraphqlField } from '../../base.graphql';

export const rootCauseFields = `
id
uuid
title
description
`;

export const CREATE_INCIDENT_RESOLUTION = gql`
  mutation addUpdateIncidentResolution($rootCause: IncidentResolutionDtoInput){
    addUpdateIncidentResolution(rootCause: $rootCause){
      code
      data{
        ${rootCauseFields}
      }
      message
    }
  }
`;

export const GET_ALL_INCIDENT_RESOLUTIONS = gql`
  query getAllInstitutionIncidentResolutions($pageparam: PageableParamInput){
    getAllInstitutionIncidentResolutions(pageparam: $pageparam){
      content{
        ${rootCauseFields}
      }
      ${basePageableGraphqlField}
    }
  }
`;

export const REMOVE_INCIDENT_RESOLUTION = gql`
  mutation activateDeactivateIncidentResolution($resolutionUuid: String) {
    activateDeactivateIncidentResolution(resolutionUuid: $resolutionUuid){
      code
      data{
        ${rootCauseFields}
      }
      message
    }
  }
`;
