import { Router } from '@angular/router';
import { ResponseCodeService } from '../../../services/response-code.service';
import { NotificationService } from 'src/app/services/notification.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import { AppState } from '../../reducers';
import * as fromActions from './incident.actions';
import { clearIncidents } from './incident.actions';
import * as fromGraphql from './incident.graphql';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { initializedPageableParameter, specialInitializedPageableParameter } from 'src/app/interfaces/global.interface';
import { PaginationService } from 'src/app/services/pagination.service';
import { getAllIncidentResponsesByIncident } from '../incident-response/incident-response.actions';
import { selectModifiedIncidents } from './incident.selectors';
import { GET_ALL_PARENT_TICKETS_BY_STATUS, GET_OUT_MY_INDIVIDUAL_NCIDENTS_BY_STATUS } from './incident.graphql';


@Injectable()
export class IncidentEffects {

    // Incidents sent by my institution)
    incidenteSentByMe$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.incidenteSentByMe),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.INCIDENT_SENT_BY_ME,
                fetchPolicy: 'network-only',
                variables: {
                    incidentOrigin: action.incidentOrigin,
                    incidentStatus: action?.incidentStatus,
                    pageparam: action?.pageable ? action.pageable : initializedPageableParameter,
                }
            }).pipe(
                this.notificationService.catchError('Problem occured while listing incidents'),
                map(({ data }: any) => {
                    if (data) {
                        let result = data?.getMyInstIncidentsOut;
                        this.paginationService.setPage(result);
                        this.store.dispatch(fromActions.loadIncidents({ incidents: data?.getMyInstIncidentsOut?.content }));
                    }
                })
            );
        })
    ), { dispatch: false });

    // getMyInstIncidentsOutByStatus by (my institution)
    getMyInstIncidentsOutByStatus$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getMyInstIncidentsOutByStatus),
        switchMap((action) => {
            this.store.dispatch(clearIncidents());
            return this.apollo.query({
                query: fromGraphql.GET_OUT_IINCIDENTS_BY_STATUS,
                fetchPolicy: 'network-only',
                variables: {
                    pageparam: action?.pageable ? action.pageable : initializedPageableParameter,
                    incidentStatus: action.incidentStatus
                }
            }).pipe(
                map(({ data }: any) => {
                    if (data) {
                        let result = data?.getMyInstIncidentsOutByStatus;
                        this.paginationService.setPage(result);
                        this.store.dispatch(fromActions.loadIncidents({ incidents: data.getMyInstIncidentsOutByStatus?.content }));
                    }
                })
            );
        })
    ), { dispatch: false });

    // getMyIndividualIncidentsOut by (my institution)
    getMyIndividualIncidentsOut$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getMyIndividualIncidentsOut),
        switchMap((action) => {
            this.store.dispatch(clearIncidents());
            return this.apollo.query({
                query: fromGraphql.GET_OUT_MY_INDIVIDUAL_NCIDENTS_BY_STATUS,
                fetchPolicy: 'network-only',
                variables: {
                    pageparam: action?.pageable ? action.pageable : initializedPageableParameter,
                    incidentStatus: action.incidentStatus,
                    incidentOrigin: action?.incidentOrigin
                }
            }).pipe(
                map(({ data }: any) => {
                    if (data) {
                        let result = data?.getMyIndividualIncidentsOut;
                        this.paginationService.setPage(result);
                        this.store.dispatch(fromActions.loadIncidents({ incidents: result?.content }));
                    }
                })
            );
        })
    ), { dispatch: false });


    // List All Incidents
    allIncidentList$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.allIncidentList),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.GET_ALL_INCIDENTS_LIST,
                fetchPolicy: 'network-only',
                variables: {
                    pageparam: action?.pageable ? action.pageable : initializedPageableParameter,
                }
            }).pipe(
                this.notificationService.catchError('Problem while fetching Incidents'),
                map(({ data }: any) => {
                    if (data) {
                        this.store.dispatch(fromActions.loadIncidents({ incidents: data.getAllIncidents?.content }));
                    }
                })
            );
        })
    ), { dispatch: false });


    // List All Incidents By Status
    listIncidentsByStatus$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.listIncidentsByStatus),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.LIST_INCIDENTS_BY_STATUS,
                fetchPolicy: 'network-only',
                variables: {
                    status: action.status,
                    pageable: action?.pageable ? action.pageable : { size: 10, first: 0, sortBy: 'id', sortDirection: 'DESC' },
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching Incidents by Status'),
                map(({ data }: any) => {
                    if (data) {
                        this.store.dispatch(fromActions.upsertIncidents({ incidents: data.incidentByStatusList?.content }));
                        this.store.dispatch(fromActions.loadPageable({
                            pageable: {
                                id: 1,
                                totalPages: data.incidentByStatusList?.totalPages,
                                totalElements: data.incidentByStatusList?.totalElements,
                                numberOfElements: data.incidentByStatusList?.numberOfElements,
                                count: data.incidentByStatusList?.size
                            }
                        }));
                    }
                })
            );
        })
    ), { dispatch: false });

    // List All Incidents By Status and Institution
    listIncidentsByStateAndInstitution$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.listIncidentsByStateAndInstitution),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.LIST_INCIDENTS_BY_STATE_AND_INSTITUTION,
                fetchPolicy: 'network-only',
                variables: {
                    state: action.state,
                    pageable: action?.pageable ? action.pageable : { size: 10, first: 0, sortBy: 'id', sortDirection: 'DESC' },
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching Incidents'),
                map(({ data }: any) => {
                    if (data) {
                        this.store.dispatch(fromActions.loadIncidents({ incidents: data.incidentByStateListInst?.content }));
                    }
                })
            );
        })
    ), { dispatch: false });


    // Save Incident
    sendIncidence$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.sendIncidence),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.REPORT_INCIDENCE,
                variables: {
                    incidence: action.IncidentDtoInput
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while submitting incident'),
                map(({ data }: any) => {
                    if (data) {
                        const response = this.responseCodesService?.getCodeDescription(data.createUpdateIncident.code);
                        let result: any = data.createUpdateIncident;
                        if (result?.code === 9000) {
                            if (action.IncidentDtoInput.uuid) {
                                this.notificationService.successMessageWithRedirect('Issue is updated successfully ', action.redirectUrl);
                                this.store.dispatch(fromActions.getIncidentByUid({ uuid: action.IncidentDtoInput.uuid }));
                            } else {
                                this.notificationService.successMessageWithRedirect('Issue is registered successfully', action.redirectUrl);
                                this.store.dispatch(fromActions.upsertIncident({ incident: result?.data }));
                            }
                        } else {
                            this.notificationService.determineSnackbar(response[0]);
                        }
                    }
                })
            );
        })
    ), { dispatch: false });

    // Delete Incident
    removeIncident$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.removeIncident),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.DELETE_INCIDENT,
                fetchPolicy: 'network-only',
                variables: {
                    incidentId: action.incidentId
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while deleting an Incident'),
                map(({ data }: any) => {
                    if (data) {
                        if (data.deleteIncident.code === 9000) {
                            this.store.dispatch(fromActions.deleteIncident({ id: data.deleteIncident.data.id }));
                            this.notificationService.successMessage('Deleted Successfully');
                        } else {
                            const error = this.responseCodesService.getCodeDescription(data.deleteIncident.code);
                            this.notificationService.errorMessage(error[0]?.description);
                        }
                    }
                })
            );
        })
    ), { dispatch: false });


    // selfAssignIncident
    selfAssignIncident$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.selfAssignIncident),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.SELF_ASSIGN_INCIDENT,
                variables: {
                    IncidentAssignmentDto: action.IncidentAssignmentDto,
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred during execution'),
                map(({ data }: any) => {
                    if (data) {
                        if (data.selfAssignIncident.code === 9000) {
                            this.notificationService.successMessage('Assigned Successfully ');
                        } else {
                            this.notificationService.errorMessage(data.selfAssignIncident.message + ': ' + data?.selfAssignIncident?.code);
                        }
                    }
                })
            );
        })
    ), { dispatch: false });


    // getMyAssignedIncidents
    getMyAssignedIncidents$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getMyAssignedIncidents),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.GET_ASSIGNED_TICKETS,
                fetchPolicy: 'network-only',
                variables: {
                    getAssignedToDept: action?.getAssignedToDept,
                    pageparam: action?.pageable ? action.pageable : initializedPageableParameter,
                    incidentStatus: action?.incidentStatus,
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching my assigned incidents'),
                map(({ data }: any) => {
                    if (data) {
                        const result = data?.getMyAssignedIncidents;
                        this.paginationService.setPage(result);
                        this.store.dispatch(fromActions.loadIncidents({ incidents: data?.getMyAssignedIncidents?.content || [] }));
                    }
                })
            );
        })
    ), { dispatch: false });

    // getAllIncidentsByStatus
    getAllIncidentsByStatus$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getAllIncidentsByStatus),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.GET_TICKETS_BY_STATUS,
                fetchPolicy: 'network-only',
                variables: {
                    pageparam: action?.pageable ? action.pageable : initializedPageableParameter,
                    incidentOrigin: action.incidentOrigin,
                    incidentStatus: action?.incidentStatus,
                    reportedOnBehalf: action?.reportedOnBehalf
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching incidents'),
                map(({ data }: any) => {
                    const result = data?.getAllIncidentsByStatus;
                    if (result?.content.length > 0) {
                        this.paginationService.setPage(result);
                        this.store.dispatch(fromActions.loadIncidents({ incidents: data.getAllIncidentsByStatus?.content }));
                    }
                })
            );
        })
    ), { dispatch: false });
    
    getAllUnMergedIncidentsByStatus$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getAllUnMergedIncidentsByStatus),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.GET_ALL_UNMERGED_TICKETS_BY_STATUS,
                fetchPolicy: 'network-only',
                variables: {
                    pageparam: action?.pageable ? action.pageable : initializedPageableParameter,
                    incidentOrigin: action.incidentOrigin,
                    incidentStatus: action?.incidentStatus,
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching incidents'),
                map(({ data }: any) => {
                    const result = data?.getAllUnMergedIncidentsByStatus;
                    this.paginationService.setPage(result);
                    this.store.dispatch(fromActions.loadIncidents({ incidents: result?.content }));
                })
            );
        })
    ), { dispatch: false });

    getAllParentIncidentsByStatus$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getAllParentIncidentsByStatus),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.GET_ALL_PARENT_TICKETS_BY_STATUS,
                fetchPolicy: 'network-only',
                variables: {
                    pageparam: action?.pageable ? action.pageable : initializedPageableParameter,
                    incidentOrigin: action.incidentOrigin,
                    incidentStatus: action?.incidentStatus,
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching incidents'),
                map(({ data }: any) => {
                    const result = data?.getAllParentIncidentsByStatus;
                    this.paginationService.setPage(result);
                    this.store.dispatch(fromActions.loadIncidents({ incidents: result?.content }));
                })
            );
        })
    ), { dispatch: false });
    unMergeIncident$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.unMergeIncident),
        withLatestFrom(this.store.select(selectModifiedIncidents)),
        switchMap(([action, preIncidents]) => {
            return this.apollo.mutate({
                mutation: fromGraphql.UN_MERGE_INCIDENT,
                fetchPolicy: 'network-only',
                variables: {
                    incidentUuid: action?.incidentUuid,
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while un-merging incident'),
                map(({ data }: any) => {
                    const result = data?.unMergeIncident;
                    if (result?.code === 9000) {
                        const preIncidentsCopy = [...preIncidents];
                        let selectedParent = preIncidentsCopy?.find(dt => dt?.uuid === action?.parentIncidentUuid);
                        let copyAllMergedIncidents = [...selectedParent?.children];
                        copyAllMergedIncidents = copyAllMergedIncidents.filter(dt => dt?.uuid !== action?.incidentUuid);
                        selectedParent = {
                            ...selectedParent,
                            children: copyAllMergedIncidents
                        };
                        const resolved = [selectedParent, result?.data];
                        this.store.dispatch(fromActions.upsertIncidents({ incidents: resolved }));
                    } else {
                        this.notificationService.handleErrorMessage(result);
                    }
                })
            );
        })
    ), { dispatch: false });

    // getAllIncidentsReportedByOnBehalfAndByStatus
    getAllIncidentsReportedByOnBehalfAndByStatus$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getAllIncidentsReportedByOnBehalfAndByStatus),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.GET_TICKETS_BY_STATUS_AND_REPORTED_ONBEHALF,
                fetchPolicy: 'network-only',
                variables: {
                    reportedOnBehalf: action.reportedOnBehalf,
                    pageparam: action?.pageable ? action.pageable : initializedPageableParameter,
                    incidentStatus: action?.incidentStatus,
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching incidents'),
                map(({ data }: any) => {
                    const result = data?.getAllIncidentsByStatus;
                    if (result?.content.length > 0) {
                        this.paginationService.setPage(result);
                        this.store.dispatch(fromActions.loadIncidents({ incidents: data.getAllIncidentsByStatus?.content }));
                    }
                })
            );
        })
    ), { dispatch: false });

    // getMyInstDeptIncidentsByStatus
    getMyInstDeptIncidentsByStatus$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getMyInstDeptIncidentsByStatus),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.GET_DEPARTMMENT_TICKETS_BY_STATUS,
                fetchPolicy: 'network-only',
                variables: {
                    pageparam: action?.pageable ? action.pageable : initializedPageableParameter,
                    incidentStatus: action?.incidentStatus
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching my assigned incidents'),
                map(({ data }: any) => {

                    const result = data?.getMyInstDeptIncidentsByStatus;
                    if (result?.content?.length > 0) {
                        this.paginationService.setPage(result);
                        this.store.dispatch(fromActions.loadIncidents({ incidents: data?.getMyInstDeptIncidentsByStatus?.content }));
                    }
                })
            );
        })
    ), { dispatch: false });

    // getMyInstSectionIncidents
    getMyInstSectionIncidents$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getMyInstSectionIncidents),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.GET_MY_INST_SECTION_INCIDENTS,
                fetchPolicy: 'network-only',
                variables: {
                    pageparam: action?.pageable ? action.pageable : initializedPageableParameter,
                    incidentStatus: action?.incidentStatus
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching my assigned incidents'),
                map(({ data }: any) => {
                    const result = data?.getMyInstSectionIncidents;
                    if (result?.content?.length > 0) {
                        this.paginationService.setPage(result);
                        this.store.dispatch(fromActions.loadIncidents({ incidents: data?.getMyInstSectionIncidents?.content }));
                    }
                })
            );
        })
    ), { dispatch: false });



    // Recategorize
    incidentCategorization$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.incidentCategorization),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.RECATEGORIZE_INCIDENT,
                variables: {
                    incidentRecategorize: action.incidentCategorizationDto
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while recategorizing an Incident'),
                map(({ data }: any) => {
                    if (data) {
                        if (data.incidentRecategorization.code === 9000) {
                            this.store.dispatch(fromActions.clearIncidents());
                            this.store.dispatch(fromActions.upsertIncident({ incident: data.incidentRecategorization.data }));
                            this.notificationService.successMessage('Issue Recategorized Successfully ');
                        } else {
                            const error = this.responseCodesService.getCodeDescription(data.incidentRecategorization.code);
                            this.notificationService.errorMessage(error[0].description + data.incidentRecategorization.code);
                        }
                    }
                })
            );
        })
    ), { dispatch: false });
    // Recategorize
    multipleIncidentCategorization$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.multipleIncidentCategorization),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.RECATEGORIZE_MULTIPLE_INCIDENTS,
                variables: {
                    incidentRecategorize: action.incidentCategorizationDto
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while recategorizing Incidents'),
                map(({ data }: any) => {
                    if (data) {
                        if (data.multipleIncidentsRecategorization.code === 9000) {
                            this.store.dispatch(fromActions.upsertIncidents({ incidents: data.multipleIncidentsRecategorization.dataList }));
                            this.notificationService.successMessage('Incidents Recategorized Successfully ');
                        } else {
                            const error = this.responseCodesService.getCodeDescription(data.multipleIncidentsRecategorization.code);
                            this.notificationService.errorMessage(error[0].description + data.multipleIncidentsRecategorization.code);
                        }
                    }
                })
            );
        })
    ), { dispatch: false });

    // Incidents sent by
    getIncidentByUid$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getIncidentByUid),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.GET_INCIDENT_BY_UID,
                fetchPolicy: 'network-only',
                variables: {
                    uuid: action?.uuid,
                }
            }).pipe(
                map(({ data }: any) => {
                    if (data) {
                        let result = data?.findIncidentByUuid;
                        if (result?.code === 9000) {
                            this.store.dispatch(fromActions.upsertIncident({ incident: result?.data }));
                        } else {
                            this.handleResponse(result);
                        }
                    }
                })
            );
        })
    ), { dispatch: false });


    queryAllIncidentReportedByMe$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.queryAllIncidentReportedByMe),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.GET_INCIDENT_REPORTED_BY_ME,
                fetchPolicy: 'network-only',
                variables: {
                    incidentStatus: action.incidentStatus,
                    pageparam: action?.pageable ? action.pageable : initializedPageableParameter,
                }
            }).pipe(
                map(({ data }: any) => {
                    if (data) {
                        let result = data?.getMyReportedIncidentsOutByStatus;
                        this.paginationService.setPage(result);
                        this.store.dispatch(fromActions.loadIncidents({ incidents: result?.content }));
                    }
                })
            );
        })
    ), { dispatch: false });

    // confirm resulotion Incident
    confirmIncidentResolved$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.confirmIncidentResolved),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.CONFRIM_INCIDENT_RESOLVED,
                variables: {
                    confirm: action.confirm,
                    description: action.description,
                    incidentUuid: action.incidentUuid
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while actiong on resolution'),
                map(({ data }: any) => {
                    if (data) {
                        let result: any = data.confirmIncidentClosingByUuid;
                        if (result?.code === 9000) {
                            this.store.dispatch(fromActions.clearIncidents());
                            this.notificationService.successMessage('Resolution confirmed successfully ');
                            this.store.dispatch(fromActions.getIncidentByUid({ uuid: action.incidentUuid }));
                            this.store.dispatch(getAllIncidentResponsesByIncident({
                                incidentUuid: action.incidentUuid,
                                pageparam: specialInitializedPageableParameter
                            }));
                        } else {
                            const error = this.responseCodesService?.getCodeDescription(result?.code);
                            this.notificationService.errorMessage(result.message ? result?.message : error[0].description + result?.code);
                        }
                    }
                })
            );
        })
    ), { dispatch: false });

    // updateIncidentParentByUuid
    updateIncidentParentByUuid$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.updateIncidentParentByUuid),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: fromGraphql.MERGE_INCIDENT_TO_PARENT,
                variables: {
                    incidentUuids: action.incidentUuids,
                    parentTicketNo: action.parentTicketNo,
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while submitting incident'),
                map(({ data }: any) => {
                    if (data) {
                        const result = data.updateIncidentParentByUuid;
                        if (result?.code === 9000) {
                            this.store.dispatch(fromActions.upsertIncident({ incident: result?.data }));
                            this.notificationService.successMessage('Merged successfully ');
                        } else {
                            this.notificationService.determineSnackbar(data);
                        }
                    }
                })
            );
        })
    ), { dispatch: false });

    incidentDetailedReports$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.incidentDetailedReports),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.INCIDENT_DETAILED_REPORT,
                fetchPolicy: 'network-only',
                variables: {
                    fromDate: action?.fromDate,
                    categoryUuid: action?.categoryUuid,
                    incidentOrigins: action?.incidentOrigins,
                    exportDocType: action?.exportDocType,
                    toDate: action?.toDate,
                    institutionUuid: action?.institutionUuid,
                    incidentStatus: action?.incidentStatus,
                }
            }).pipe(
                this.notificationService.catchError('Problem occured while listing incidents'),
                map(({ data }: any) => {
                    if (data) {
                        let result = data?.getIncidentDetailedReport;
                        if (result?.code === 9000) {
                            this.store.dispatch(fromActions.loadIncidents({ incidents: data?.getIncidentDetailedReport?.data }));
                        } else {
                            this.notificationService.determineSnackbar(data);
                        }
                    }
                })
            );
        })
    ), { dispatch: false });

    // getAllIncidentsByExecutiveOfficers
    getAllIncidentsByExecutiveOfficers$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.getAllIncidentsByExecutiveOfficers),
        switchMap((action) => {
            return this.apollo.query({
                query: fromGraphql.GET_ALL_TICKETS_FOR_EXECUTIVE_OFFICERS,
                fetchPolicy: 'network-only',
                variables: {
                    pageparam: action?.pageable ? action.pageable : initializedPageableParameter,
                    incidentOrigin: action.incidentOrigin,
                    incidentStatus: action?.incidentStatus,
                    branchUuid: action?.branchUuid,
                    departmentUuid: action?.departmentUuid
                }
            }).pipe(
                this.notificationService.catchError('Problem occurred while fetching incidents'),
                map(({ data }: any) => {
                    const result = data?.getAllIncidentsByExecutiveOfficers;
                    if (result?.content.length > 0) {
                        this.paginationService.setPage(result);
                        this.store.dispatch(fromActions.loadIncidents({ incidents: data.getAllIncidentsByExecutiveOfficers?.content }));
                    }
                })
            );
        })
    ), { dispatch: false });
    
    

    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private store: Store<AppState>,
        private notificationService: NotificationService,
        private responseCodesService: ResponseCodeService,
        private router: Router,
        private responseCodeService: ResponseCodeService,
        private paginationService: PaginationService,
    ) {
    }

    handleResponse(data) {
        if (data.code === 9000) {
            this.store.dispatch(fromActions.upsertIncident({ incident: data.data }));
            return this.notificationService.successMessage('Action completed Successful');
        } else {
            return this.handleError(data);
        }
    }

    handleError(data) {
        const responseCode = this.responseCodeService.getCodeDescription(data.code);
        const message = responseCode[0].code + ' : ' + responseCode[0].description;
        return this.notificationService.errorMessage(message);
    }

}
