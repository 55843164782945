import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeUnderScore'
})
export class RemoveUnderScorePipe implements PipeTransform {
  // result: string;

  transform(value: any, ...args: any[]): any {
    // remove under scores and turn to title case
    
    if(value){
      const str = value?.toString()?.replace(/_/g, ' ');
      if(value == 'REOPENED') return 'RE-OPENED'
      return str;
    } else {
        return value;
    }
  }

titleCase(str) {
    str = str.toLowerCase().split(' ')
      .map(function (word) {
        return (word.charAt(0).toUpperCase() + word.slice(1));
      });

    return str.join(' ');
  }
}





