import { Component, Input, OnInit } from '@angular/core';
import { StorageService } from 'src/app/services/storage.service';
import { IncidentResponse } from 'src/app/store/entities/incident-response/incident-response.model';
import { Incident } from 'src/app/store/entities/incident/incident.model';

@Component({
  selector: 'app-comment-display',
  templateUrl: './comment-display.component.html',
  styleUrls: ['./comment-display.component.scss']
})
export class CommentDisplayComponent implements OnInit {
  @Input() incidentResponses: IncidentResponse[];
  @Input() incident: Incident;
  @Input() accessedFrom: string;
  institutionId: number;
  
  constructor( private storageService: StorageService) {
      this.institutionId = +storageService.getItem('institutionId');
  }

  ngOnInit(): void {
  }

}
